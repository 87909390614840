import React from 'react';

export default function () {
    return (
        <div>
            <h2>Silhouette Report</h2>
            <p>
                This report allows you to see planning data on multiple levels of a silhouette along with category and
                division. The report can be filtered by Partner, Category, Division, Silhouette, Types of Order, Gender, Age,
                Family, Model and Segment.
            </p>
            <h3>Summaries</h3>
            <p>
                In order to get to this report, <code>CLICK</code> on <strong>Silhouette</strong> under the{' '}
                <strong>Reports</strong> tab. In this view, you can find one report which provides an overview of the total
                revenue, sales units, sales asp and presentation stocks per division for the selected silhouette. The summary
                shows the data for this and last year with their difference and the percentage of increase/decrease for this
                year. A percentage of the total revenue can be found in the summary and last year&apos;s data is included for
                obtaining more insights.
            </p>

            <h3>Calculations</h3>

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Value</th>
                        <th scope="col">Formula</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">% TTL REVENUE</th>
                        <td>revenue / total revenue</td>
                    </tr>
                    <tr>
                        <th scope="row">% TTL SALES UNITS</th>
                        <td>sales units / total sales units</td>
                    </tr>
                    <tr>
                        <th scope="row">SALES ASP</th>
                        <td>sales units / sell through</td>
                    </tr>
                    <tr>
                        <th scope="row">% TTL PRESENTATION STOCKS</th>
                        <td>presentation stocks / total presentation stocks</td>
                    </tr>
                    <tr />
                </tbody>
            </table>
            <p>
                <em>
                    NOTE:
                    <ul>
                        <li>This report is available for NDS and DIGITAL</li>
                    </ul>
                </em>
            </p>
        </div>
    );
}
