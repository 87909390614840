import React, { CSSProperties, ReactElement } from 'react';
import Select, { OptionsType, Props, StylesConfig } from 'react-select';
import { mergeWith } from 'lodash';
import { compose } from 'redux';
import { inputBorderColor, primaryColor } from '../core-globals.scss';

// The styling setup here follows the recommended approach from https://react-select.com/styles
// I ran into two issues while using SASS:
// - https://github.com/JedWatson/react-select/issues/3481
// - https://github.com/JedWatson/react-select/issues/3248
// Because css/classnames is clearly marked as "not the recommended approach" I used JS styling instead.
const defaultCustomStyles: StylesConfig = {
    control: (provided: CSSProperties) => ({
        ...provided,
        borderRadius: 0,
        borderColor: inputBorderColor,
        minHeight: 'auto',
    }),
    singleValue: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        transition: 'opacity 200ms',
        color: primaryColor,
    }),
    valueContainer: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        padding: '0 2px',
    }),
    indicatorSeparator: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        margin: '0',
    }),
    dropdownIndicator: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        padding: '1px',
        cursor: 'pointer',
    }),
    clearIndicator: (provided: CSSProperties): CSSProperties => ({
        ...provided,
        padding: '1px',
        cursor: 'pointer',
    }),
    menu: (provided: CSSProperties) => ({
        ...provided,
        margin: 0,
        borderRadius: 0,
        top: 'auto',
        // Same as @nike/nr-vamp-xl-select-field
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    }),
};

// This SelectOptionType is due to a limitation/unresolved issue, see:
// https://github.com/JedWatson/react-select/issues/2902
export interface SelectOptionType {
    label: string;
    value: string;
}

interface CustomProps {
    customStyles?: StylesConfig;
}

function ReactSelect({ options, value, onChange, customStyles = {}, ...props }: Props & CustomProps): ReactElement {
    const opts = options as OptionsType<SelectOptionType>;
    const styles = mergeWith(customStyles, defaultCustomStyles, (objValue, srcValue) =>
        objValue ? compose(objValue, srcValue) : srcValue
    );
    return (
        <Select styles={styles} value={value} options={opts} onChange={onChange} classNamePrefix="react-select" {...props} />
    );
}

export default ReactSelect;
