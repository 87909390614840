/* eslint-disable @typescript-eslint/no-explicit-any */
import { familyModelRowsConfig } from 'buyplan-common';

/*
 * Each migration step will take one version as input and return the next version as output.
 * (The key `3` means that it is the step which migrates from V1 to V2.)
 *
 * Migrations only run for users that have older versioned data. For new browser sessions
 * no migrations are run.
 *
 */
export const userMigrations = {
    2: (state: any) =>
        // If it's an unknown format we can't rely on it and reset storeNumberOrder to empty. We did our best.
        ({
            ...state,
            settings: {
                ...state.settings,
                visibleFamilyReportColumnKeys: familyModelRowsConfig.map(({ key }) => key),
            },
        }),
};

/*
 * This is the current version and should match the latest version above.
 */
export const USER_PERSIST_VERSION = 2;
