import { get, omit } from 'lodash';
import { DropdownOption, LoadingStatus } from 'buyplan-common';
import {
    GET_OVERVIEW_COLUMNS_SUCCESS,
    GET_OVERVIEW_COLUMNS_FAILURE,
    GET_OVERVIEW_METADATA_SUCCESS,
    GET_OVERVIEW_COLUMNS,
    GET_OVERVIEW_METADATA_FAILURE,
    GET_OVERVIEW_METADATA,
    GET_OVERVIEW_ITEMS,
    SET_OVERVIEW_FILTER,
    SET_OVERVIEW_FILTER_FROM_QUERY,
    CLEAR_ALL_OVERVIEW_FILTERS,
    CLEAR_OVERVIEW_FILTER,
    GET_OVERVIEW_ITEMS_FAILURE,
    GET_OVERVIEW_ITEMS_SUCCESS,
} from '../actions/actionTypes';

export interface OverviewColumn {
    name: string;
    label: string;
    type: string;
}

interface OverviewItem {
    id: number;
    SoldTo: string;
    ShipTo: string;
    Material: string;
    Size: string;
    OrderType: string;
    IsStaged: boolean;
    PartnerName: string;
    StoreName: string;
    StoreNumber: string;
    Country: string;
    Category: string;
    Division: string;
    ProductDescription: string;
    RRP: string | null;
    ListCost: number | null;
    PlannerSubmitted: string;
    DateSubmitted: string;
    QuantityLoadIn: number;
    QuantityReplen: number;
    Username: string;
    FileId: number;
    // These fields come from the BE
    // eslint-disable-next-line camelcase
    Requested_Delivery_Date: string;
    // eslint-disable-next-line camelcase
    Futr_AO: string;
    // eslint-disable-next-line camelcase
    Owner_group: string;
    // eslint-disable-next-line camelcase
    calculated_quantity: number;
    // eslint-disable-next-line camelcase
    Purchase_Order_Number: string;
    // eslint-disable-next-line camelcase
    Contract_Number: number | null;
    // eslint-disable-next-line camelcase
    Contact_Name: string | null;
    // eslint-disable-next-line camelcase
    Carton_ID: string | null;
}

interface OverviewMetadata {
    db: string;
    excel: string;
    label: string;
    filter: boolean;
    genericFilter: boolean;
    type: string;
    showOnOverview: boolean;
    filterType: string;
    filterData: DropdownOption[];
    field: string;
}

export type FilterValue = string[];

export interface OverviewState {
    pages: number;
    rows: number;
    previousPage: number;
    nextPage: number;
    pageNr: number;
    metaData: OverviewMetadata[];
    items: OverviewItem[];
    columns: OverviewColumn[];
    activeFilters: { [key: string]: FilterValue };
    editingColumn?: OverviewColumn;
    loadingStatus: {
        metaData: LoadingStatus;
        columns: LoadingStatus;
        items: LoadingStatus;
        metaDataErrorMessage: string;
        itemsErrorMessage: string;
    };
}

export const initialState: OverviewState = {
    items: [],
    metaData: [],
    columns: [],
    pages: 0,
    rows: 0,
    previousPage: 0,
    nextPage: 0,
    activeFilters: {},
    editingColumn: undefined,
    pageNr: 0,
    loadingStatus: {
        metaData: LoadingStatus.initial,
        columns: LoadingStatus.initial,
        items: LoadingStatus.initial,
        metaDataErrorMessage: '',
        itemsErrorMessage: '',
    },
};

// Actions for overview still need to be defined in TS
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/default-param-last
export default (state: OverviewState = initialState, action: any): OverviewState => {
    switch (action.type) {
        case SET_OVERVIEW_FILTER:
            return {
                ...state,
                activeFilters: { ...state.activeFilters, ...action.filter },
            };
        case SET_OVERVIEW_FILTER_FROM_QUERY:
            return {
                ...state,
                activeFilters: action.filter,
            };
        case CLEAR_ALL_OVERVIEW_FILTERS:
            return {
                ...state,
                activeFilters: initialState.activeFilters,
            };
        case CLEAR_OVERVIEW_FILTER:
            return {
                ...state,
                activeFilters: omit(state.activeFilters, action.columnName),
            };
        case GET_OVERVIEW_ITEMS:
            return {
                ...state,
                loadingStatus: { ...state.loadingStatus, items: LoadingStatus.loading },
            };
        case GET_OVERVIEW_ITEMS_FAILURE:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    items: LoadingStatus.error,
                    itemsErrorMessage: get(action, 'response.errorMessage', 'error'),
                },
            };
        case GET_OVERVIEW_ITEMS_SUCCESS:
            return {
                ...state,
                items: action.response.items,
                nextPage: action.response.nextPage,
                previousPage: action.response.previousPage,
                rows: action.response.rows,
                pages: action.response.pages,
                pageNr: action.response.currentPage,
                loadingStatus: {
                    ...state.loadingStatus,
                    items: LoadingStatus.success,
                    itemsErrorMessage: '',
                },
            };
        case GET_OVERVIEW_METADATA:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    metaData: LoadingStatus.loading,
                },
            };
        case GET_OVERVIEW_METADATA_FAILURE:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    metaData: LoadingStatus.error,
                    metaDataErrorMessage: get(action, 'response.errorMessage', 'error'),
                },
            };
        case GET_OVERVIEW_METADATA_SUCCESS:
            return {
                ...state,
                metaData: action.response,
                loadingStatus: {
                    ...state.loadingStatus,
                    metaData: LoadingStatus.success,
                    metaDataErrorMessage: '',
                },
            };
        case GET_OVERVIEW_COLUMNS:
            return { ...state, loadingStatus: { ...state.loadingStatus, columns: LoadingStatus.loading } };
        case GET_OVERVIEW_COLUMNS_FAILURE:
            return {
                ...state,
                columns: [],
                loadingStatus: {
                    ...state.loadingStatus,
                    columns: LoadingStatus.error,
                },
            };
        case GET_OVERVIEW_COLUMNS_SUCCESS:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    columns: LoadingStatus.success,
                },
                columns: action.response,
            };
        default:
            return state;
    }
};
