"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.familyModelRows = exports.familyModelRowsConfig = void 0;
const formatters_1 = require("../helpers/formatters");
exports.familyModelRowsConfig = [
    // GENERAL
    {
        key: 'family',
        label: 'Family',
        type: 'fixed',
        isGroupStart: true,
        groupStartHeader: 'Family',
        displayOnlyIn: 'family',
        width: 260,
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'model',
        label: 'Model',
        type: 'fixed',
        width: 260,
        isGroupStart: true,
        groupKey: 'filters',
        groupStartHeader: 'Model',
        displayOnlyIn: 'model',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'family',
        label: 'Family',
        type: 'scrollable',
        isGroupStart: true,
        groupKey: 'filters',
        groupStartHeader: 'Family',
        displayOnlyIn: 'model',
        width: 260,
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'category',
        label: 'Category',
        width: 205,
        type: 'scrollable',
        groupKey: 'filters',
        isGroupStart: true,
        groupStartHeader: 'Category',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'division',
        label: 'Division',
        width: 130,
        type: 'scrollable',
        groupKey: 'filters',
        isGroupStart: true,
        groupStartHeader: 'Division',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'gender',
        label: 'Gender',
        width: 130,
        type: 'scrollable',
        groupKey: 'filters',
        isGroupStart: true,
        groupStartHeader: 'Gender',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'age',
        label: 'Age',
        width: 130,
        type: 'scrollable',
        isGroupStart: true,
        groupKey: 'filters',
        groupStartHeader: 'Age',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    // REVENUE
    {
        key: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Revenue',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSalesNetRevenueSum',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesNetRevenueSum',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'salesRetailValueUSDGrowthPercentage',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 100,
        type: 'scrollable',
    },
    {
        key: 'salesRetailValueUSDGrowthPercentageLLY',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 100,
        type: 'scrollable',
    },
    {
        key: 'salesRetailValueUSDGrowth',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'salesRetailValueUSDGrowthLLY',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
        width: 110,
        type: 'scrollable',
    },
    // TOTAL REVENUE
    {
        key: 'salesRetailValueUSDPercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: '% Total Revenue',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSalesRetailValueUSDPercentageOfTotal',
        groupKey: 'salesRetailValueUSDPercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesRetailValueUSDPercentageOfTotal',
        groupKey: 'salesRetailValueUSDPercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
        width: 70,
        type: 'scrollable',
    },
    // PRODUCTIVITY
    {
        key: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Productivity',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'productivityGrowthPercentage',
        groupKey: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 90,
        type: 'scrollable',
    },
    {
        key: 'lastYearProductivity',
        groupKey: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
        width: 80,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearProductivity',
        groupKey: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
        width: 80,
        type: 'scrollable',
    },
    // OPTION COUNTS
    {
        key: 'optionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Option Counts',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'optionCountsGrowthPercentage',
        groupKey: 'optionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 90,
        type: 'scrollable',
    },
    {
        key: 'lastYearOptionCounts',
        groupKey: 'optionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 60,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearOptionCounts',
        groupKey: 'optionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 60,
        type: 'scrollable',
    },
    // SEASONAL OPTION COUNTS
    {
        key: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Seasonal Option Counts',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'seasonalOptionCountsGrowthPercentage',
        groupKey: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 90,
        type: 'scrollable',
    },
    {
        key: 'lastYearSeasonalOptionCounts',
        groupKey: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 60,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSeasonalOptionCounts',
        groupKey: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 60,
        type: 'scrollable',
    },
    // TRADED OPTION COUNTS
    {
        key: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Traded Option Counts',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'tradedOptionCountsGrowthPercentage',
        groupKey: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 90,
        type: 'scrollable',
    },
    {
        key: 'lastYearTradedOptionCounts',
        groupKey: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 60,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearTradedOptionCounts',
        groupKey: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 60,
        type: 'scrollable',
    },
    // SALES UNITS
    {
        key: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sales Units',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSalesUnitsSum',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesUnitsSum',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthPercentage',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 95,
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthPercentageLLY',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 95,
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowth',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
        width: 115,
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthLLY',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
        width: 115,
        type: 'scrollable',
    },
    // SALES ASP (AVERAGE SELLING PRICE)
    {
        key: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sales ASP',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearAverageSellingPrice',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LY',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearAverageSellingPrice',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LLY',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'averageSellingPriceGrowthPercentage',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 95,
        type: 'scrollable',
    },
    {
        key: 'averageSellingPriceGrowthPercentageLLY',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 95,
        type: 'scrollable',
    },
    // ST (SELL THROUGH)
    {
        key: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        getTotalsValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sell Through %',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSellThrough',
        groupKey: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        getTotalsValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSellThrough',
        groupKey: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        getTotalsValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
        width: 70,
        type: 'scrollable',
    },
    // BOP
    {
        key: 'beginningOfPeriodUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'BOP',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    // Open To Buy Units
    {
        key: 'openToBuyUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'OTB Units',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'aaOpenToBuyUnitsSum',
        groupKey: 'openToBuyUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AA',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'afOpenToBuyUnitsSum',
        groupKey: 'openToBuyUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AF',
        width: 70,
        type: 'scrollable',
    },
    // BUY WHOLESALE VALUE $
    {
        key: 'buyWholesaleValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Buy Wholesale Value $',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'aaBuyWholesaleValueUSDSum',
        groupKey: 'buyWholesaleValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AA',
        width: 100,
        type: 'scrollable',
    },
    {
        key: 'afBuyWholesaleValueUSDSum',
        groupKey: 'buyWholesaleValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AF',
        width: 100,
        type: 'scrollable',
    },
    // Presentation Stocks
    {
        key: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Presentation Stocks',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastYearInvestUnitsSum',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 75,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearInvestUnitsSum',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 75,
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowthPercentage',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 95,
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowthLLY',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 95,
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowth',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowthPercentageLLY',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
        width: 110,
        type: 'scrollable',
    },
    // Margin USD
    {
        key: 'marginUSDSum',
        isGroupStart: true,
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Margin $',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastYearMarginUSDSum',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearMarginUSDSum',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'marginUSDVariance',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'marginUSDVarianceLLY',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
        width: 110,
        type: 'scrollable',
    },
    // Margin Percentage
    {
        key: 'marginPercentage',
        isGroupStart: true,
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY',
        groupStartHeader: 'Margin %',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastYearMarginPercentage',
        groupKey: 'marginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearMarginPercentage',
        groupKey: 'marginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // Allocation units
    {
        key: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        displayOnlyIn: 'model',
        groupStartHeader: 'Allocation Units',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'aaAllocationUnits',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AA',
        displayOnlyIn: 'model',
        width: 60,
        type: 'scrollable',
    },
    {
        key: 'afQSAllocationUnits',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AF/QS',
        displayOnlyIn: 'model',
        width: 70,
        type: 'scrollable',
    },
    {
        key: 'allocationVsOpenToBuyGrowthPercentage',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY Allocation VS Open To Buy % Δ',
        displayOnlyIn: 'model',
        width: 210,
        type: 'scrollable',
    },
    {
        key: 'allocationVsOpenToBuyGrowth',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY Allocation VS Open To Buy Units Δ',
        displayOnlyIn: 'model',
        width: 230,
        type: 'scrollable',
    },
];
const familyModelRows = (channelId, reportType) => {
    let familyModelRowsFiltered = exports.familyModelRowsConfig;
    if (channelId) {
        familyModelRowsFiltered = familyModelRowsFiltered.filter((rowConfig) => !rowConfig.applyToChannels || rowConfig.applyToChannels.includes(channelId));
    }
    if (reportType) {
        familyModelRowsFiltered = familyModelRowsFiltered.filter((rowConfig) => rowConfig.displayOnlyIn === undefined || rowConfig.displayOnlyIn === reportType);
    }
    return familyModelRowsFiltered;
};
exports.familyModelRows = familyModelRows;
