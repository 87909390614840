import React from 'react';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import { channels } from 'buyplan-common';
import Dropdown from '../Dropdown/Dropdown';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import useUserProfile from '../../selectors/useUserProfile';

function ApplicationSelection() {
    const history = useHistory();
    const { pathname } = useLocation();
    const profile = useUserProfile();
    const isNDSAdmin = useIsChannelAdmin(channels.nds.id);

    if (!profile) {
        return null;
    }

    // Assemble the options, if there is only 1 there is no need for the application selector to appear
    const options = [{ label: 'Buyplan', value: 'buyplan' }];
    if (isNDSAdmin) {
        options.push({ label: 'Mancala', value: 'mancala' });
    }
    if (options.length === 1) {
        return null;
    }

    const isBuyplan = matchPath(pathname, { path: '/buyplan' }) !== null;

    return (
        <Dropdown
            options={options}
            disabled={false}
            id="application-selector"
            onChange={(value) => {
                if (value === 'mancala') {
                    history.push('/import');
                } else {
                    history.push('/buyplan');
                }
            }}
            value={isBuyplan ? 'buyplan' : 'mancala'}
            disableUnderline
        />
    );
}

export default ApplicationSelection;
