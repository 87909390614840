import React, { useState } from 'react';
import { round } from 'lodash';
import { MarginImpactRecord } from 'buyplan-common';
import './MarginImpactRow.scss';
import { updateMarginImpact as updateMarginImpactService } from '../../services/marginImpactsService';
import { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';
import PercentageInput from '../PercentageInput/PercentageInput';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import useUserSettings from '../../selectors/useUserSettings';

interface Props {
    marginImpactRecord: MarginImpactRecord;
    isBuyplanActive: boolean;
}

function MarginImpactRow({ marginImpactRecord, isBuyplanActive }: Props) {
    const [marginImpact, setMarginImpact] = useState(marginImpactRecord.marginImpact);
    const [ukSalesPercentage, setUkSalesPercentage] = useState(marginImpactRecord.ukSalesPercentage);
    const { activeChannelId } = useUserSettings();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);

    const onMarginImpactChangeHandler = (inputValue: number) => {
        const marginImpactInput = inputValue || 0;
        setMarginImpact(marginImpactInput);
    };

    const onUKPercentageChangeHandler = (inputValue: number) => {
        const ukSalesPercentageInput = inputValue || 0;
        setUkSalesPercentage(ukSalesPercentageInput);
    };

    const onBlurHandler = async () => {
        await updateMarginImpactService(marginImpactRecord.id, marginImpact, ukSalesPercentage);
    };

    return (
        <tr className="MarginImpactRow" data-e2e={`margin-impact-row-${marginImpactRecord.id}`}>
            <td>{marginImpactRecord.category}</td>
            <td>{marginImpactRecord.division}</td>
            <td>{marginImpactRecord.gender}</td>
            <td className="MarginImpactRow MarginImpactRow--minimal-padding">
                {isChannelAdmin && !isBuyplanActive ? (
                    <PercentageInput
                        value={marginImpact}
                        onChange={onMarginImpactChangeHandler}
                        clearBehaviour={ClearBehaviour.SET_NULL}
                        onBlur={onBlurHandler}
                        className="MarginImpactRow__input"
                        minimum={-100}
                        maximum={100}
                    />
                ) : (
                    `${round(marginImpactRecord.marginImpact * 100, 1)}%`
                )}
            </td>
            <td className="MarginImpactRow MarginImpactRow--minimal-padding">
                {isChannelAdmin && !isBuyplanActive ? (
                    <PercentageInput
                        value={ukSalesPercentage}
                        onChange={onUKPercentageChangeHandler}
                        clearBehaviour={ClearBehaviour.SET_NULL}
                        onBlur={onBlurHandler}
                        className="MarginImpactRow__input"
                        minimum={0}
                        maximum={100}
                    />
                ) : (
                    `${round(marginImpactRecord.ukSalesPercentage * 100, 1)}%`
                )}
            </td>
        </tr>
    );
}

export default MarginImpactRow;
