export const gender = [
    { value: 'MENS', label: 'MENS' },
    { value: 'WOMENS', label: 'WOMENS' },
    { value: 'UNISEX', label: 'UNISEX' },
    { value: 'GIRLS', label: 'GIRLS' },
    { value: 'BOYS', label: 'BOYS' },
    { value: 'KIDS UNISEX', label: 'KIDS UNISEX' },
];

export const allocated = [
    { value: 'YES', label: 'YES' },
    { value: 'NO', label: 'NO' },
];
