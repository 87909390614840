import React from 'react';
import { Link } from 'react-router-dom';
import emptyAssortmentView from './images/ManageAssortmentMainFilters.png';
import assortmentSelection from './images/AssortmentSelection.png';
import assortmentFiltering from './images/AssortmentFiltering.png';
import AssortmentLocking from './images/AssortmentLocking.png';

export default function () {
    return (
        <div className="assortment">
            <h2>Managing Assortment</h2>
            <h3>Overview</h3>
            <p>
                Within the assortment we want to make sure we can do all we can to promote accuracy and speed. When dealing
                with the assortment we have multiple features that will help give you comfort in your choices such as
                material filtering and real time option count feedback.
            </p>
            <p>
                Within the manage assortment view you must select{' '}
                <strong>at least one partner, category and division</strong> to have your assortment populate. From there you
                can start editing store/material combinations. If you have permission for only one partner, category or
                division, that one will automatically be selected for you.
                <img src={emptyAssortmentView} alt="No main filters selected" />
            </p>
            <h3>Selecting Your Assortment</h3>
            <p>
                When selecting your assortment you will see a list of materials, their detailed information in columns, as
                well as the stores that were within the option counts file tied to the partner you selected.
            </p>
            <p>
                Within the store columns you will notice blank and checked boxes that are correlated to a specific material.
                If the box is <strong>checked</strong> that given material has been chosen to exist within the Buyplan. If
                the box is <strong>not checked</strong> then that material has not been selected for the given store to be
                purchased. Below you can find a visualization of the selection:
                <img src={assortmentSelection} alt="Assortment selection" />
            </p>
            <h3>Tracking Manual Adjustments</h3>
            <p>
                Tracking manual changes can be difficult when dealing with a lot of stores and materials. To help with that
                we have given each material/store that has been manually changed within the assortment a color:
            </p>
            <ol>
                <li>Blue - To highlight changes made by Nike planners</li>
                <li>
                    Red - To highlight changes made by external partners. Note: This option will only be available if you
                    work with external parters via the Buyplan Partner Portal
                </li>
            </ol>
            <p>
                If you look at the above photo you can see how it appears when you manually assort a material that was not
                originally going to a store as well as a material that was originally assorted but has been removed manually.
            </p>
            <h3>Filtering Your Assortment</h3>
            <p>
                For a given Partner/Category/Division combination there are still many materials that could be involved
                within an assortment. To help solve this issue we have built in a filters that can be found near the column
                name.
                <img src={assortmentFiltering} alt="Assortment filtering" />
            </p>
            <h3>Hide/Show Columns and Stores Ordering</h3>
            <p>
                Please see the <Link to="/buyplan/documentation/user-settings">User Settings section</Link> for more
                information about hiding/showing columns and ordering stores within the Assortment view.
            </p>
            <h3>Locking Material Assortment for Partner Portal</h3>
            <p>
                If the selected partner has access to the Buyplan Partner Portal, the lock column will be visible to Nike
                planners on the Buyplan View Assortment screen. This button can be used by Nike planners to &quot;lock&quot;
                the material from further partner changes to the assortment.
                <img src={AssortmentLocking} alt="Assortment locking" />
                Partners with access to the Buyplan Partner Portal may make changes to the assortment by assorting or
                un-assorting materials to stores. Once an agreement is reached between the Nike planner and partner about
                assortment, the Nike planner can click on the lock button so the material becomes &quot;locked&quot; for all
                stores for the selected partner. NOTE: Locking applies to all partner stores, even if some stores are not
                visible due to filtering. Once a material is locked, the partner will not be able to make further changes to
                that material&apos;s assortment. However, the Nike planner can still make changes.
            </p>
        </div>
    );
}
