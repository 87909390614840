import React, { ReactNode } from 'react';
import './Stepper.scss';

interface Props {
    indicator: ReactNode;
    title?: string;
    children?: ReactNode | ReactNode[];
    'data-e2e'?: string;
}

function Stepper({ children }: { children: ReactNode | ReactNode[] }) {
    return <ul className="stepper">{children}</ul>;
}

function Step({ children = null, title = '', indicator, 'data-e2e': dataE2E }: Props) {
    return (
        <li className="step" data-e2e={dataE2E}>
            <div className="step__header">
                <span className="step__indicator">{indicator}</span>
                <span className="step__title" data-e2e="step-title">
                    {title}
                </span>
            </div>
            <div className="step__content">{children}</div>
        </li>
    );
}

export { Stepper, Step };
