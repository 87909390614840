import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../Logo/Logo';
import homeOpening from '../../assets/img/home_opening2.jpg';
import './Home.scss';

function PublicHome() {
    return (
        <div className="Home">
            <Container>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <h2 className="Home__header">DOCUMENTATION</h2>
                        <div className="Home__logos">
                            <div className="Home__logo">
                                <Logo app="mancala" to="/mancala/documentation" black={false} />
                            </div>
                            <div className="Home__logo">
                                <Logo app="buyplan" to="/buyplan/documentation" black={false} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img src={homeOpening} className="Home__opening" alt="Welcome" />
        </div>
    );
}

export default PublicHome;
