import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHistory } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ValueType } from 'react-select';
import { Store, round, CurrencyType } from 'buyplan-common';
import { putStoreTarget, SalesTarget, StoreSalesTarget } from '../../services/salesTargetService';
import { ExchangeRate } from '../../services/exchangeRatesService';
import { patchStore } from '../../services/storeService';
import { INPUT_MAX_VALUE } from '../../constants/appConfig';
import EnhancedNumberFormat from '../EnhancedNumberFormat/EnhancedNumberFormat';
import Loader from '../Loader/Loader';
import ReactSelect, { SelectOptionType } from '../ReactSelect/ReactSelect';
import './TargetsRow.scss';

interface Props {
    store: Store;
    currencyType: CurrencyType;
    salesTarget?: StoreSalesTarget;
    exchangeRates?: ExchangeRate[];
    like4likeStores?: SelectOptionType[];
    isEditable: boolean;
}

interface State extends SalesTarget {
    loading: boolean;
    like4likeStoreId: string | null;
    currentTarget?: StoreSalesTarget;
}

function calculateLocal(exchangeRate: ExchangeRate, usdValue: number | null) {
    return usdValue !== null ? round(2, exchangeRate.rate * usdValue) : null;
}

function calculateUSD(exchangeRate: ExchangeRate, localValue: number | null) {
    return localValue !== null ? round(2, localValue / exchangeRate.rate) : null;
}

class StoreRow extends Component<Props, State> {
    static defaultProps = {
        salesTarget: null,
    };

    constructor(props: Props) {
        super(props);
        const { store, salesTarget } = props;
        this.state = {
            currentTarget: salesTarget,
            like4likeStoreId: store.like4likeStoreId ?? null,
            loading: false,
            localCurrency: store.currency,
            openToBuyTargetLocal: salesTarget?.openToBuyTargetLocal ?? null,
            openToBuyTargetUSD: salesTarget?.openToBuyTargetUSD ?? null,
            retailSalesTargetLocal: salesTarget?.retailSalesTargetLocal ?? null,
            retailSalesTargetUSD: salesTarget?.retailSalesTargetUSD ?? null,
        };
    }

    handleChangeRetailSalesTarget = (retailSalesTargetValue: number | null) => {
        if (this.props.currencyType === CurrencyType.USD) {
            this.setState({ retailSalesTargetUSD: retailSalesTargetValue }, this.recalculateLocalTargets);
        } else {
            this.setState({ retailSalesTargetLocal: retailSalesTargetValue }, this.recalculateUSDTargets);
        }
    };

    handleChangeOpenToBuyTarget = (openToBuyTargetValue: number | null) => {
        if (this.props.currencyType === CurrencyType.USD) {
            this.setState({ openToBuyTargetUSD: openToBuyTargetValue }, this.recalculateLocalTargets);
        } else {
            this.setState({ openToBuyTargetLocal: openToBuyTargetValue }, this.recalculateUSDTargets);
        }
    };

    handleChangeL4LStore = (selectedValue: ValueType<SelectOptionType>) => {
        this.setState({ like4likeStoreId: (selectedValue as SelectOptionType)?.value ?? null }, this.updateStore);
    };

    getStoreTarget = () => ({
        localCurrency: this.state.localCurrency,
        retailSalesTargetLocal: this.state.retailSalesTargetLocal,
        retailSalesTargetUSD: this.state.retailSalesTargetUSD,
        openToBuyTargetLocal: this.state.openToBuyTargetLocal,
        openToBuyTargetUSD: this.state.openToBuyTargetUSD,
    });

    getExchangeRate = () => {
        const { localCurrency } = this.state;
        const { exchangeRates } = this.props;
        if (localCurrency && exchangeRates) {
            return exchangeRates.find(({ currency }) => currency === localCurrency);
        }
        return null;
    };

    updateStoreTarget = async () => {
        const { salesTarget } = this.props;
        if (salesTarget) {
            try {
                this.setState({ loading: true });
                const storeTarget = this.getStoreTarget();
                const { data: currentTarget } = await putStoreTarget(salesTarget.id, storeTarget);
                this.setState({ currentTarget });
            } finally {
                this.setState({ loading: false });
            }
        }
    };

    updateStore = async () => {
        try {
            this.setState({ loading: true });
            await patchStore(this.props.store.id, {
                like4likeStoreId: this.state.like4likeStoreId,
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    recalculateLocalTargets = () => {
        const exchangeRate = this.getExchangeRate();
        if (exchangeRate) {
            const { retailSalesTargetUSD, openToBuyTargetUSD } = this.state;
            this.setState({
                retailSalesTargetLocal: calculateLocal(exchangeRate, retailSalesTargetUSD),
                openToBuyTargetLocal: calculateLocal(exchangeRate, openToBuyTargetUSD),
            });
        }
    };

    recalculateUSDTargets = () => {
        const exchangeRate = this.getExchangeRate();
        if (exchangeRate) {
            const { retailSalesTargetLocal, openToBuyTargetLocal } = this.state;
            this.setState({
                retailSalesTargetUSD: calculateUSD(exchangeRate, retailSalesTargetLocal),
                openToBuyTargetUSD: calculateUSD(exchangeRate, openToBuyTargetLocal),
            });
        }
    };

    isValid = () => {
        const { currentTarget, like4likeStoreId } = this.state;
        if (!currentTarget) {
            return false;
        }
        return (
            currentTarget.localCurrency.length > 0 &&
            currentTarget.retailSalesTargetLocal !== null &&
            currentTarget.retailSalesTargetUSD !== null &&
            currentTarget.openToBuyTargetLocal !== null &&
            currentTarget.openToBuyTargetUSD !== null &&
            (this.props.store.hasHindsightData || like4likeStoreId !== null)
        );
    };

    render() {
        const { store, currencyType, like4likeStores } = this.props;
        const {
            like4likeStoreId,
            loading,
            localCurrency,
            retailSalesTargetUSD,
            retailSalesTargetLocal,
            openToBuyTargetUSD,
            openToBuyTargetLocal,
        } = this.state;

        const currencySymbol = currencyType === CurrencyType.LOCAL ? localCurrency : CurrencyType.USD;
        const retailSalesTarget = currencyType === CurrencyType.LOCAL ? retailSalesTargetLocal : retailSalesTargetUSD;
        const openToBuyTarget = currencyType === CurrencyType.LOCAL ? openToBuyTargetLocal : openToBuyTargetUSD;

        return (
            <tr className="TargetsRow StoreRow" data-e2e={`store-target-${store.number}`}>
                <td className="TargetsRow__partner">{store.partner}</td>
                <td className="TargetsRow__store">{store.number}</td>
                <td className="TargetsRow__name">
                    <span title={`${store.name} - ${store.number}`}>{store.name}</span>
                </td>
                <td className="TargetsRow TargetsRow__retail TargetsRow--minimal-padding">
                    <EnhancedNumberFormat
                        step={1}
                        minimum={0}
                        maximum={INPUT_MAX_VALUE}
                        decimalScale={2}
                        name="retailSalesTarget"
                        value={retailSalesTarget}
                        className="TargetsRow__input TargetsRow__retailSalesTarget"
                        onChange={this.handleChangeRetailSalesTarget}
                        onBlur={this.updateStoreTarget}
                        disabled={!this.props.isEditable}
                    />
                    <span>{currencySymbol}</span>
                </td>

                <td className="TargetsRow TargetsRow__otb TargetsRow--minimal-padding">
                    <EnhancedNumberFormat
                        step={1}
                        minimum={0}
                        maximum={INPUT_MAX_VALUE}
                        decimalScale={2}
                        name="openToBuyTarget"
                        value={openToBuyTarget}
                        className="TargetsRow__input TargetsRow__openToBuyTarget"
                        onChange={this.handleChangeOpenToBuyTarget}
                        onBlur={this.updateStoreTarget}
                        disabled={!this.props.isEditable}
                    />
                    <span>{currencySymbol}</span>
                </td>

                <td className="TargetsRow TargetsRow__like4like TargetsRow--minimal-padding">
                    <div className="TargetsRow__like4like--select">
                        <ReactSelect
                            options={like4likeStores}
                            isClearable
                            value={like4likeStores?.find((elm) => elm.value === like4likeStoreId) ?? null}
                            onChange={this.handleChangeL4LStore}
                            noOptionsMessage={() => 'No stores with hindsight data'}
                            disabled={!this.props.isEditable}
                        />
                    </div>
                    <span title="Contains Hindsight Data" className="TargetsRow__like4like--history">
                        {store.hasHindsightData && <FontAwesomeIcon icon={faHistory as IconProp} />}
                    </span>
                </td>

                <td className="TargetsRow TargetsRow__status">
                    {loading && <Loader width={16} />}
                    {!loading && this.isValid() && <FontAwesomeIcon icon={faCheck as IconProp} />}
                </td>
            </tr>
        );
    }
}

export default StoreRow;
