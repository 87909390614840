import { flatten, flow, size, toArray } from 'lodash';
import { FilterValue, MainFilters, ReportFilters, SubFilters } from 'buyplan-common';
import { InvalidInputValues } from '../constants/appConfig';

type ActiveFiltersType =
    | SubFilters
    | {
          [key: string]: FilterValue;
      };

// Necessary to let TS accept the parameters of the returned function of flow
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pipe = flow<any, any, any, any>(toArray, flatten, size);

export const getTotalLenghtOfAllArraysWithinObject = (activeFilters: ActiveFiltersType) => pipe(activeFilters);

// Checks the selected main filters and returns the missing required filters to load report
export const getMissingMainFilterKeys = (
    mainFilters: MainFilters,
    requiredFilters = [ReportFilters.partner, ReportFilters.category, ReportFilters.division]
) => requiredFilters.filter((property: string) => !mainFilters || mainFilters[property as keyof MainFilters] === undefined);

// Checks activeFilters object to determine the total number of subfilters applied
export const getAppliedFiltersCount = (activeSubFilters: SubFilters | undefined) => {
    if (!activeSubFilters || !Object.keys(activeSubFilters).length) return 0;
    let count = 0;
    Object.keys(activeSubFilters).forEach((filter) => {
        if (activeSubFilters[filter] && !!activeSubFilters[filter]?.length) {
            count += activeSubFilters[filter]?.length || 0;
        }
        return count;
    });
    return count;
};

// Check if a value is invalid if present in the list of invalid values (added in constants)
export const isInvalidInputValue = (value: string | number | null | undefined) => {
    const valueAsString = value?.toString().toLowerCase();
    const isInValid = InvalidInputValues.find((el) => el.toString().toLowerCase() === valueAsString);
    return isInValid !== undefined;
};

export const sanitizeS3FileName = (fileName: string): string =>
    fileName
        .trim()
        .normalize('NFKD')
        .replace(/[^\x20-\x7F]/g, '')
        .replace(/[&$@=;:+,^%`"~#[\]{}<>*?/\\|]/g, '_')
        .replace(/_+/g, '');
