import React from 'react';
import cn from 'classnames';
import { StoreLevelBreakdownReport, storeLevelRows, RowConfig } from 'buyplan-common';
import StoreLevelRow from './StoreLevelRow';
import './StoreLevelTable.scss';

interface Props {
    report: StoreLevelBreakdownReport[];
    tableId: string;
    syncTableHorizontalScrolls: (e: React.UIEvent<HTMLElement>) => void;
    channelId: number;
}

function StoreLevelTable({ report, tableId, syncTableHorizontalScrolls, channelId }: Props) {
    const totals = report[0]; // First element of the StoreLevelBreakdownReport[] is totals.
    const reportWithoutTotals = report.slice(1, report.length);

    return (
        <div className="StoreLevelTable__container" id={`storeTable-${tableId}`} onScroll={syncTableHorizontalScrolls}>
            <table cellPadding={0} cellSpacing={0} className="StoreLevelTable table">
                <thead>
                    <tr className="StoreLevelTable__header">
                        <th className="StoreLevelTable__header" colSpan={1} style={{ width: '100px' }}>
                            Division
                        </th>
                        {storeLevelRows(channelId).map((rowConfig: RowConfig) => {
                            const { groupStartHeader, headerColSpan, headerColWidth } = rowConfig;

                            if (groupStartHeader) {
                                return (
                                    <th
                                        key={`store-totals-${rowConfig.key}`}
                                        className="StoreLevelTable__header-data"
                                        colSpan={headerColSpan}
                                        style={{ width: `${headerColWidth}px` }}
                                    >
                                        {groupStartHeader}
                                    </th>
                                );
                            }

                            return null;
                        })}
                    </tr>
                    <tr className="StoreLevelTable__subheader">
                        <th />
                        {storeLevelRows(channelId).map((rowConfig: RowConfig) => (
                            <th key={rowConfig.key}>{rowConfig.label}</th>
                        ))}
                    </tr>
                    <tr className="StoreLevelTable__subheader-totals">
                        <th />
                        {storeLevelRows(channelId).map((rowConfig: RowConfig) => {
                            const { key } = rowConfig;
                            const totalValue = totals[key as keyof typeof totals] as number | null;
                            return (
                                <th
                                    key={key}
                                    className={cn({
                                        'StoreLevelTable__subheader-totals--negative': (totalValue ?? 0) < 0,
                                    })}
                                >
                                    {rowConfig.getValue(totalValue, false)}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {reportWithoutTotals.map((row) => (
                        <StoreLevelRow key={`${row.division}`} row={row} channelId={channelId} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default StoreLevelTable;
