import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BuyplanFiles, channels } from 'buyplan-common';
import { isEqual } from 'lodash';
import { useSelector } from '../../store/reducers';
import PageWarning from '../PageWarning/PageWarning';
import useCanEditBuyPlan from '../../helpers/useCanEditBuyPlan';
import { useRequestFunction } from '../../api/useRequest';
import { getBuyPlanFiles } from '../../services/buyplanService';
import usePrevious from '../../helpers/usePrevious';
import Loader from '../Loader/Loader';
import StoreTargets from './StoreTargets';
import './SalesTargets.scss';
import AttributedSalesTargets from './AttributedSalesTargets';
import SalesTargetsTemplateButton from './SalesTargetsTemplateButton';

const renderMessage = (activeChannelId: number, files?: BuyplanFiles) => {
    if (activeChannelId !== channels.digital.id && files && !files.optionCountsFiles.length) {
        return (
            <p>
                Please upload an <Link to="/buyplan/manage-assortment/import">Option Counts file</Link>.
            </p>
        );
    }
    if (files && !files.hindsightFiles.length) {
        return (
            <p>
                Please upload a Sales Targets file after uploading a <Link to="/buyplan/import-files">Hindsight file</Link>.
            </p>
        );
    }
    return (
        <div style={{ marginTop: '15px' }}>
            <SalesTargetsTemplateButton />
            <p>
                Upload a Sales Targets file <Link to="/buyplan/import-files">here</Link>.
            </p>
        </div>
    );
};

function SalesTargets() {
    const { activeChannelId } = useSelector(({ user }) => user.settings);
    const previousActiveChannelId = usePrevious(activeChannelId);
    const [canEditBuyPlanLoadingStatus, canEditBuyPlanErrorStatus, canEditBuyPlan] = useCanEditBuyPlan();
    const [loading, error, files, fetchBuyplanFiles] = useRequestFunction(getBuyPlanFiles);

    useEffect(() => {
        const shouldRefresh = !isEqual(previousActiveChannelId, activeChannelId);
        if (shouldRefresh) {
            fetchBuyplanFiles();
        }
    }, [fetchBuyplanFiles, previousActiveChannelId, activeChannelId]);

    return (
        <Container className="SalesTargets">
            {loading && <Loader />}
            {error && (
                <div className="error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}
            {files && files.salesTargetFiles.length > 0 ? (
                <Row>
                    <Col>
                        <div style={{ display: 'inline-flex', marginTop: '15px' }}>
                            <h1 style={{ marginRight: '20px' }}>SALES TARGETS </h1>
                            {canEditBuyPlan && (
                                <span className="SalesTargets__disclaimer alert alert-danger">
                                    Be careful: if work on the Buyplan has already begun, changing the sales targets will
                                    affect buy ratios
                                </span>
                            )}
                        </div>
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <h3 data-e2e="sales-target-store-tab">STORE</h3>
                                </Tab>
                                <Tab>
                                    <h3 data-e2e="sales-target-cat-div-tab">SEGMENT/CATEGORY/DIVISION/CONSUMER</h3>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <StoreTargets
                                    loadingCanEditBuyPlan={canEditBuyPlanLoadingStatus}
                                    errorCanEditBuyPlan={canEditBuyPlanErrorStatus}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AttributedSalesTargets
                                    loadingCanEditBuyPlan={canEditBuyPlanLoadingStatus}
                                    errorCanEditBuyPlan={canEditBuyPlanErrorStatus}
                                />
                            </TabPanel>
                        </Tabs>
                    </Col>
                </Row>
            ) : (
                <PageWarning>
                    <h1>SALES TARGETS</h1>
                    <h2>Not yet available</h2>
                    {renderMessage(activeChannelId, files)}
                </PageWarning>
            )}
        </Container>
    );
}

export default SalesTargets;
