import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearFilter, getOverviewItems, getOverviewMetaData } from '../../actions/overview';
import * as overviewService from '../../services/overviewService';
import { useSelector } from '../../store/reducers';
import Warning from '../Warning/Warning';
import Modal from '../Modal/Modal';
import UpdateOrderlinesForm, { UpdateOrderlinesColumn } from './UpdateOrderlinesForm';
import './UpdateOrderlines.scss';

interface Props {
    editingColumn?: UpdateOrderlinesColumn;
    onClose: () => void;
}

function UpdateOrderlines({ editingColumn, onClose }: Props) {
    const dispatch = useDispatch();
    const { rows, activeFilters } = useSelector(({ overview }) => overview);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<string>();

    const handleSubmit = async (value: Date | string | boolean) => {
        if (!editingColumn) {
            return;
        }

        setUpdating(true);
        try {
            await overviewService.updateOrderlines(editingColumn.name, value, activeFilters);
            await Promise.all([dispatch(clearFilter(editingColumn.name)), dispatch(getOverviewMetaData())]);
            onClose();
            dispatch(getOverviewItems());
        } catch (err: unknown) {
            const er = err as Error;
            setError(er.message);
        } finally {
            setUpdating(false);
        }
    };

    const { name, label, value } = editingColumn ?? {};
    const showFilterWarning = name && Object.keys(activeFilters).includes(name);

    return (
        <Modal
            isOpen={!!editingColumn}
            closeAction={onClose}
            title={`Update '${label}'`}
            subtitle={` for (${rows}) ${rows > 1 ? 'records' : 'record'}`}
        >
            {!!editingColumn && (
                <div className="update-orderlines">
                    {showFilterWarning && (
                        <Warning>
                            Please keep in mind that you are updating a field that is part of the current filter. This could
                            result in disappearing orderlines in the overview.
                        </Warning>
                    )}
                    <UpdateOrderlinesForm
                        value={value}
                        editingColumn={editingColumn}
                        updating={updating}
                        error={error}
                        onSubmit={handleSubmit}
                        onClose={onClose}
                    />
                </div>
            )}
        </Modal>
    );
}

export default UpdateOrderlines;
