import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faRotate, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';
import { Material } from 'buyplan-common';
import { syncPricesByMaterial } from '../../services/pricesService';
import './SyncMaterialPrices.scss';

type SyncMaterialPricesProps = {
    materialCode: string;
    onChange?: (attributes: Partial<Material>) => void;
};

function SyncMaterialPrices({ materialCode, onChange }: SyncMaterialPricesProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);

        try {
            const result = await syncPricesByMaterial(materialCode);
            if (!!result && !!result.data) {
                onChange?.({
                    netherlandsRetailInclVat: result.data.netherlandsRetailInclVat,
                    netherlandsWholesale: result.data.netherlandsWholesale,
                    retailGBPInclVat: result.data.retailGBPInclVat,
                    wholesaleGBP: result.data.wholesaleGBP,
                });
                setIsLoading(false);
                setIsSuccess(true);
                // Reset success state after 3 seconds
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
        } catch (err) {
            setIsError(true);
            setIsLoading(false);
            // Reset error state after 5 seconds
            setTimeout(() => {
                setIsError(false);
            }, 5000);
        }
    };

    let icon = faRotate;

    if (isLoading) {
        icon = faSpinner;
    } else if (isSuccess) {
        icon = faCheck;
    } else if (isError) {
        icon = faExclamationTriangle;
    }

    return (
        <button
            tabIndex={0}
            type="button"
            title={`Get latest prices for ${materialCode}`}
            onClick={handleClick}
            disabled={isLoading || isSuccess || isError}
            className="SyncMaterialPrices__button"
        >
            <FontAwesomeIcon
                icon={icon as IconProp}
                spin={isLoading}
                className={cn('SyncMaterialPrices__icon', {
                    'SyncMaterialPrices__icon--loading': isLoading,
                    'SyncMaterialPrices__icon--error': isError,
                    'SyncMaterialPrices__icon--success': isSuccess,
                })}
            />
        </button>
    );
}

export default SyncMaterialPrices;
