import moment, { DurationInputArg1 } from 'moment';
import { Season, CreateSeasonData } from 'buyplan-common';

export const formatStringToDate = (date?: string) => (date ? moment(date, moment.ISO_8601).toDate() : null);
export const formatDateToString = (date: Date | string) => moment(date, moment.ISO_8601).format('YYYY-MM-DD');
export const formatStringToDateAndAdd = (date: string, add: DurationInputArg1) =>
    moment(date, moment.ISO_8601).add(add).toDate();

export const suggestNewSeasonName = (currentSeasonName: string) => {
    if (!currentSeasonName) {
        return '';
    }
    const validSeasons = ['SP', 'SU', 'FA', 'HO'];

    const currentSeason = currentSeasonName.substr(0, 2);
    const currentSeasonIndex = validSeasons.indexOf(currentSeason);
    if (currentSeasonIndex < 0) {
        return '';
    }

    const currentYear = currentSeasonName.substr(2, 2);
    if (Number.isNaN(+currentYear)) {
        return '';
    }

    if (currentSeasonIndex >= validSeasons.length - 1) {
        return `${validSeasons[0]}${+currentYear + 1}`;
    }

    return `${validSeasons[currentSeasonIndex + 1]}${currentYear}`;
};

export const suggestNewSeason = (currentSeason: Pick<Season, 'endDate' | 'name'>): CreateSeasonData => {
    const { endDate, name } = currentSeason;

    return {
        name: suggestNewSeasonName(name),
        startDate: formatDateToString(formatStringToDateAndAdd(endDate, { days: 1 })),
        flow2StartDate: formatDateToString(formatStringToDateAndAdd(endDate, { months: 1, days: 1 })),
        flow3StartDate: formatDateToString(formatStringToDateAndAdd(endDate, { months: 2, days: 1 })),
        endDate: formatDateToString(formatStringToDateAndAdd(endDate, { months: 3 })),
    };
};
