import { BuyPlanStore } from 'buyplan-common';
import React, { useCallback } from 'react';
import { NumberFormatProps } from 'react-number-format';
import PercentageInput from '../PercentageInput/PercentageInput';
import { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';

interface Props {
    store: BuyPlanStore;
    onChange(store: BuyPlanStore): void;
    name: 'ukSalesPercentage' | 'marginImpact';
}

type InputProps = Omit<NumberFormatProps, 'onChange'>;

function BuyPlanUKSalesMarginImpactInput({ onChange, store, name, ...props }: InputProps & Props) {
    const value = store[name];

    // useCallback prevents caret position to be incorrectly updated in react-number-format
    const onChangeUKSalesMarginImpact = useCallback(
        (newValue: number) => onChange({ ...store, [name]: newValue }),
        [store, onChange, name]
    );

    return (
        <PercentageInput
            {...props}
            name={name}
            clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
            minimum={name === 'marginImpact' ? -100 : 0}
            maximum={100}
            value={value}
            onChange={onChangeUKSalesMarginImpact}
        />
    );
}

export default BuyPlanUKSalesMarginImpactInput;
