import React from 'react';
import { shape, number, objectOf } from 'prop-types';

export interface OptionCountSummary {
    categories: {
        [key: string]: {
            footwear?: {
                perfectStores: number;
                totalStores: number;
                variance: number;
            };
            apparel?: {
                perfectStores: number;
                totalStores: number;
                variance: number;
            };
            equipment?: {
                perfectStores: number;
                totalStores: number;
                variance: number;
            };
        };
    };
}

function OptionCountsSummary({ categories }: OptionCountSummary) {
    return (
        <table className="table table-bordered">
            <thead className="thead-light">
                <tr>
                    <th>&nbsp;</th>
                    <th>Footwear</th>
                    <th>Apparel</th>
                    <th>Equipment</th>
                </tr>
            </thead>
            <tbody>
                {/* Todo : Add proper support for undefined values here */}
                {Object.entries(categories).map(([category, { footwear, apparel, equipment }]) => (
                    <tr key={category}>
                        <th>{category}</th>
                        <td>
                            {footwear ? `${footwear.perfectStores}/${footwear.totalStores} (${footwear.variance}%)` : ''}
                        </td>
                        <td>{apparel ? `${apparel.perfectStores}/${apparel.totalStores} (${apparel.variance}%)` : ''}</td>
                        <td>
                            {equipment ? `${equipment.perfectStores}/${equipment.totalStores} (${equipment.variance}%)` : ''}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

OptionCountsSummary.propTypes = {
    categories: objectOf(
        shape({
            footwear: shape({ perfectStores: number, totalStores: number, variance: number }),
            apparel: shape({ perfectStores: number, totalStores: number, variance: number }),
            equipment: shape({ perfectStores: number, totalStores: number, variance: number }),
        })
    ),
};

export default OptionCountsSummary;
