"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assortmentViewColumns = void 0;
const formatters_1 = require("../helpers/formatters");
const labels_1 = require("../helpers/labels");
const buyplanRowsConfig_1 = require("./buyplanRowsConfig");
const assortmentColumns = [
    {
        key: 'toggleStores',
        width: 80,
        type: 'fixed',
        isFixed: true,
        label: '',
    },
    {
        key: 'materialCode',
        width: 140,
        isFixed: true,
        type: 'fixed',
        sortable: true,
        filterable: true,
        editable: {
            bulk: false,
            individual: true,
        },
    },
    { key: 'description', width: 250, type: 'fixed', sortable: true, filterable: true },
    {
        key: 'colorDescription',
        width: 170,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        editable: {
            bulk: false,
            individual: true,
        },
    },
    { key: 'brand', width: 250, type: 'scrollable', sortable: true, filterable: true },
    { key: 'category', width: 150, type: 'scrollable', sortable: true },
    {
        key: 'consumer',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: buyplanRowsConfig_1.buyplanColumnLabels.consumer,
    },
    {
        key: 'emeaDimension',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    { key: 'division', width: 120, type: 'scrollable', sortable: true },
    { key: 'segment', width: 145, type: 'scrollable', sortable: true, filterable: true, label: buyplanRowsConfig_1.buyplanColumnLabels.segment },
    { key: 'age', width: 100, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'gender',
        width: 115,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    { key: 'franchise', width: 250, type: 'scrollable', sortable: true, filterable: true },
    { key: 'merchClass', width: 250, type: 'scrollable', sortable: true, filterable: true },
    { key: 'consumerUse', width: 250, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'silhouette',
        width: 130,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    { key: 'emeaSilo', width: 250, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'emeaSubFranchise',
        width: 250,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: buyplanRowsConfig_1.buyplanColumnLabels.emeaSubFranchise,
    },
    {
        key: 'emeaFamily',
        width: 120,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    { key: 'emeaCollectionName', width: 250, type: 'scrollable', sortable: true, filterable: true },
    { key: 'silhouetteType', width: 250, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'thirdParty',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: buyplanRowsConfig_1.buyplanColumnLabels.thirdParty,
    },
    { key: 'fieldsOfPlay', width: 250, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'features',
        width: 250,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: buyplanRowsConfig_1.buyplanColumnLabels.features,
    },
    { key: 'emeaFootballPlates', width: 250, type: 'scrollable', sortable: true, filterable: true },
    { key: 'emeaBusinessModel', width: 250, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'family',
        width: 120,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    {
        key: 'model',
        width: 140,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    { key: 'dimension', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'gphCategory', width: 150, type: 'scrollable', sortable: true, filterable: true },
    { key: 'extendedSizing', width: 130, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'vat',
        width: 100,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    {
        key: 'ukVat',
        width: 110,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    {
        key: 'wholesaleGBP',
        width: 95,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.wholesaleGBP, 'GBP', false),
        type: 'scrollable',
        sortable: true,
    },
    {
        key: 'retailGBPInclVat',
        width: 75,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.retailGBPInclVat, 'GBP', false),
        type: 'scrollable',
        sortable: true,
    },
    {
        key: 'netherlandsWholesale',
        width: 95,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.netherlandsWholesale, 'EUR', false),
        type: 'scrollable',
        sortable: true,
    },
    {
        key: 'netherlandsRetailInclVat',
        width: 100,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.netherlandsRetailInclVat, 'EUR', false),
        type: 'scrollable',
        sortable: true,
    },
    { key: 'firstOfferDate', width: 135, type: 'scrollable', sortable: true, filterable: true },
    { key: 'carryInFromPreviousSeason', width: 190, type: 'scrollable', sortable: true, filterable: true },
    { key: 'carryoverToNextSeason', width: 160, type: 'scrollable', sortable: true, filterable: true },
    { key: 'minimumCredibleAssortment', width: 130, type: 'scrollable', sortable: true, filterable: true },
    { key: 'digitalStyleRank', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'lifecycleManagement', width: 145, type: 'scrollable', sortable: true, filterable: true },
    { key: 'launchFlag', width: 120, type: 'scrollable', sortable: true, filterable: true },
    { key: 'launchDate', width: 120, type: 'scrollable', sortable: true, filterable: true },
    { key: 'gel', width: 80, type: 'scrollable', sortable: true, filterable: true },
    { key: 'core', width: 110, type: 'scrollable', sortable: true, filterable: true },
    { key: 'membership', width: 130, type: 'scrollable', sortable: true, filterable: true },
    { key: 'membershipLaunchDate', width: 150, type: 'scrollable', sortable: true, filterable: true },
    { key: 'sneakrsOrUnlaced', width: 130, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'pack',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        editable: {
            bulk: false,
            individual: true,
        },
    },
    { key: 'keyInitiatives', width: 120, type: 'scrollable', sortable: true, filterable: true },
    { key: 'brandArchitecture', width: 155, type: 'scrollable', sortable: true, filterable: true },
    { key: 'seasonalCategoryStories', width: 164, type: 'scrollable', sortable: true, filterable: true },
    { key: 'zeroStarStories', width: 130, type: 'scrollable', sortable: true, filterable: true },
    { key: 'localSportingMoment', width: 150, type: 'scrollable', sortable: true, filterable: true },
    { key: 'keyLookStyle', width: 120, type: 'scrollable', sortable: true, filterable: true },
    { key: 'mindset', width: 150, type: 'scrollable', sortable: true, filterable: true },
    {
        key: 'allocated',
        width: 120,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        sortable: true,
        filterable: true,
    },
    { key: 'typeOfOrder', width: 130, type: 'scrollable', sortable: true, filterable: true },
    { key: 'grade', width: 80, type: 'scrollable', sortable: true, filterable: true },
    { key: 'styleRank', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'colourRank', width: 130, type: 'scrollable', sortable: true, filterable: true },
    { key: 'startWeek', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'endWeek', width: 90, type: 'scrollable', sortable: true, filterable: true },
    { key: 'digitalStartWeek', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'digitalEndWeek', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'weekOfCampaign', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'storesFlowRange', width: 140, type: 'scrollable', sortable: true, filterable: true },
    { key: 'digitalFlowRange', width: 140, type: 'scrollable', sortable: true, filterable: true },
    { key: 'buyComments', width: 110, type: 'scrollable', sortable: true, filterable: true },
    { key: 'heatLevel', width: 100, type: 'scrollable', sortable: true, filterable: true },
    { key: 'distributionIntent', width: 150, type: 'scrollable', sortable: true, filterable: true },
    { key: 'assortedStoresCount', width: 130, type: 'scrollable' },
    { key: 'selectAll', width: 48, type: 'scrollable', isFixed: true },
    { key: 'lock', width: 48, type: 'scrollable', isFixed: true },
];
exports.assortmentViewColumns = assortmentColumns.map((column) => {
    var _a;
    return ({
        ...column,
        label: (_a = column.label) !== null && _a !== void 0 ? _a : (0, labels_1.getLabel)(column.key),
    });
});
