import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import assortment from '../reducers/assortment';
import buyplan from '../reducers/buyplan';
import globalError from '../reducers/globalError';
import overview from '../reducers/overview';
import season from '../reducers/season';
import stores from '../reducers/stores';
import user from '../reducers/user';
import impersonation from '../reducers/impersonation';
import notification from '../reducers/notification';
import { userMigrations, USER_PERSIST_VERSION } from './migrations/userMigrations';

const userPersistConfig = {
    key: 'user',
    storage,
    whitelist: ['settings'],
    version: USER_PERSIST_VERSION,
    migrate: createMigrate(userMigrations),
};

const rootReducer = combineReducers({
    assortment,
    buyplan,
    globalError,
    overview,
    season,
    stores,
    user: persistReducer(userPersistConfig, user),
    impersonation,
    notification,
});

export { rootReducer };

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
