import React, { memo, useEffect, useMemo } from 'react';
import { has, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { getBuyPlanMetaData } from '../../actions/buyplan';
import { useSelector } from '../../store/reducers';
import usePrevious from '../../helpers/usePrevious';
import { combineWords } from '../../helpers/language';
import { getMarginImpacts } from '../../services/marginImpactsService';
import { useRequestFunction } from '../../api/useRequest';
import useActiveFilters from '../../selectors/useActiveFilters';
import MainFilters from '../Filters/MainFilters/MainFilters';
import Loader from '../Loader/Loader';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { PageView } from '../../constants/appConfig';
import useMetaData from '../../selectors/useMetaData';
import MarginImpactTable from './MarginImpactTable';
import './MarginImpact.scss';

function MarginImpact() {
    const { activeChannelId: channelId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.marginImpact);
    const { metaData, isLoading } = useMetaData(PageView.marginImpact);
    const { category } = mainFilters;

    const dispatch = useDispatch();
    const previousMainFilters = usePrevious(mainFilters);
    const previousChannelId = usePrevious(channelId);

    const missingFiltersKeys = useMemo(
        () => ['category', 'division'].filter((property) => !has(mainFilters, property)),
        [mainFilters]
    );

    const [loading, error, marginImpactsData, fetchMarginImpacts] = useRequestFunction(getMarginImpacts);

    useEffect(() => {
        dispatch(getBuyPlanMetaData(channelId, false, ['category', 'division'], PageView.marginImpact));
    }, [dispatch, channelId]);
    useEffect(() => {
        const shouldRefresh =
            missingFiltersKeys.length === 0 &&
            (!mainFilters || !isEqual(previousMainFilters, mainFilters) || !isEqual(previousChannelId, channelId));
        if (shouldRefresh) {
            fetchMarginImpacts(mainFilters);
        }
    }, [channelId, fetchMarginImpacts, mainFilters, previousMainFilters, previousChannelId, missingFiltersKeys]);

    return (
        <div className="MarginImpact">
            <h1>MARGIN IMPACT</h1>
            {!isLoading && metaData && (
                <div className="MarginImpact-filters">
                    <MainFilters
                        filters={metaData.mainFilters}
                        activeMainFilters={mainFilters}
                        activeChannelId={channelId}
                        // If there is no category, we don't let the user select the division
                        disabledFilterKeys={!category ? ['division'] : undefined}
                        view={PageView.marginImpact}
                    />
                </div>
            )}
            {missingFiltersKeys.length > 0 ? (
                <div className="MarginImpact__missingFilters">
                    Please select a filter for {combineWords(missingFiltersKeys)}
                </div>
            ) : (
                <>
                    {error && <ErrorMessage error={error} />}
                    {loading && !marginImpactsData && <Loader />}
                    {marginImpactsData && (
                        <div className="MarginImpact__table-container">
                            <div className="MarginImpact__table">
                                {marginImpactsData.length === 0 ? (
                                    <div className="MarginImpact__noResults">No data found, try changing filters</div>
                                ) : (
                                    <MarginImpactTable marginImpacts={marginImpactsData} />
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default memo(MarginImpact);
