"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.viewBuyplanColumns = exports.buyplanColumnLabels = exports.lastYearColumnKeys = exports.lastYearColumns = exports.favoriteStoreColumns = exports.subcolumnLabels = void 0;
const formatters_1 = require("../helpers/formatters");
const labels_1 = require("../helpers/labels");
exports.subcolumnLabels = {
    sellThrough: 'ST%',
    openToBuyUnits: 'OTB',
    lastYearStoresMaterials: 'LY STORES',
    lastYearReferenceType: '',
};
const favoriteStoreColumnsWithoutLabels = [
    {
        key: 'rateOfSale',
        width: 70,
        alignRight: true,
    },
    {
        key: 'sellThrough',
        label: exports.subcolumnLabels.sellThrough,
        width: 70,
        alignRight: true,
    },
    {
        key: 'openToBuyUnits',
        label: exports.subcolumnLabels.openToBuyUnits,
        width: 70,
        alignRight: true,
    },
];
exports.favoriteStoreColumns = favoriteStoreColumnsWithoutLabels.map((column) => {
    var _a;
    return ({
        ...column,
        label: (_a = column.label) !== null && _a !== void 0 ? _a : (0, labels_1.getLabel)(column.key),
    });
});
const lastYearColumnsWithoutLabels = [
    {
        key: 'lastYearRateOfSale',
        width: 65,
    },
    {
        key: 'lastYearWeeksOnSale',
        width: 65,
    },
    {
        key: 'lastYearSellThrough',
        width: 65,
        filterable: true,
    },
    {
        key: 'lastYearMarginPercentage',
        width: 65,
    },
    {
        key: 'lastYearSalesUnits',
        width: 87,
    },
    {
        key: 'lastYearStoresMaterials',
        label: exports.subcolumnLabels.lastYearStoresMaterials,
        width: 85,
    },
    {
        key: 'lastYearReferenceType',
        label: exports.subcolumnLabels.lastYearReferenceType,
        width: 32,
    },
    {
        key: 'lastYearStyle',
        width: 350,
    },
    {
        key: 'lastYearColor',
        width: 215,
    },
];
exports.lastYearColumns = lastYearColumnsWithoutLabels.map((column) => {
    var _a;
    return ({
        ...column,
        label: (_a = column.label) !== null && _a !== void 0 ? _a : (0, labels_1.getLabel)(column.key),
    });
});
exports.lastYearColumnKeys = lastYearColumnsWithoutLabels.map((col) => col.key);
exports.buyplanColumnLabels = {
    aaOpenToBuyUnits: 'AA OTB Units',
    afOpenToBuyUnits: 'AF OTB Units',
    aur: 'AUR',
    beginningOfPeriodUnits: 'BOP',
    buyWholesale: 'Buy Wholesale Value USD',
    consumer: 'CC Consumer',
    emeaSubFranchise: 'EMEA Sub-Franchise',
    features: 'Features (Sustainability Tier)',
    image: '',
    lastYear: 'Last Year Data',
    localRetailUSD: 'Retail USD',
    localRetailUSDInclVat: 'Retail USD Incl. VAT',
    localWholesaleUSD: 'Wholesale USD',
    marginPercentage: 'Margin %',
    netherlandsRetailInclVat: 'Retail Euro incl. VAT',
    notes: 'Notes',
    openToBuyUnits: 'OTB Units',
    retailGBPInclVat: 'Retail GBP incl. VAT',
    reviewedStatus: 'Reviewed',
    salesRetail: 'Sales Retail Value USD',
    salesUnits: 'Sales Units',
    segment: 'CC Segment',
    thirdParty: '3rd Party Vendor (Licensee)',
    toggleStores: '',
    ukSalesPercentage: 'UK Sales %',
};
const buyplanViewColumns = {
    toggleStores: {
        key: 'toggleStores',
        width: 80,
        type: 'fixed',
        isFixed: true,
        label: exports.buyplanColumnLabels.toggleStores,
    },
    notes: {
        key: 'notes',
        width: 50,
        type: 'fixed',
        isFixed: true,
        label: exports.buyplanColumnLabels.notes,
    },
    image: {
        key: 'image',
        width: 35,
        type: 'fixed',
        isFixed: true,
        label: exports.buyplanColumnLabels.image,
    },
    materialCode: {
        key: 'materialCode',
        width: 130,
        isFixed: true,
        type: 'fixed',
        sortable: true,
        filterable: true,
    },
    storeNumber: {
        key: 'storeNumber',
    },
    storeName: {
        key: 'storeName',
    },
    description: {
        key: 'description',
        width: 250,
        type: 'fixed',
        sortable: true,
        filterable: true,
    },
    colorDescription: {
        key: 'colorDescription',
        width: 170,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    brand: {
        key: 'brand',
        width: 65,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    category: {
        key: 'category',
        width: 150,
        type: 'scrollable',
        sortable: true,
    },
    consumer: {
        key: 'consumer',
        width: 125,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: exports.buyplanColumnLabels.consumer,
    },
    emeaDimension: {
        key: 'emeaDimension',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    division: {
        key: 'division',
        width: 120,
        type: 'scrollable',
        sortable: true,
    },
    segment: {
        key: 'segment',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: exports.buyplanColumnLabels.segment,
    },
    age: {
        key: 'age',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    gender: {
        key: 'gender',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    franchise: {
        key: 'franchise',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    merchClass: {
        key: 'merchClass',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    consumerUse: {
        key: 'consumerUse',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    silhouette: {
        key: 'silhouette',
        width: 115,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    emeaSilo: {
        key: 'emeaSilo',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    emeaSubFranchise: {
        key: 'emeaSubFranchise',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: exports.buyplanColumnLabels.emeaSubFranchise,
    },
    emeaFamily: {
        key: 'emeaFamily',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    emeaCollectionName: {
        key: 'emeaCollectionName',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    silhouetteType: {
        key: 'silhouetteType',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    thirdParty: {
        key: 'thirdParty',
        width: 170,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: exports.buyplanColumnLabels.thirdParty,
    },
    fieldsOfPlay: {
        key: 'fieldsOfPlay',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    features: {
        key: 'features',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
        label: exports.buyplanColumnLabels.features,
    },
    emeaFootballPlates: {
        key: 'emeaFootballPlates',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    emeaBusinessModel: {
        key: 'emeaBusinessModel',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    family: {
        key: 'family',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    model: {
        key: 'model',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    dimension: {
        key: 'dimension',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    gphCategory: {
        key: 'gphCategory',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    minimumCredibleAssortment: {
        key: 'minimumCredibleAssortment',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    digitalStyleRank: {
        key: 'digitalStyleRank',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    lifecycleManagement: {
        key: 'lifecycleManagement',
        width: 145,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    grade: {
        key: 'grade',
        width: 80,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    style: {
        key: 'style',
        width: 75,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    color: {
        key: 'color',
        width: 80,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    styleRank: {
        key: 'styleRank',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    colourRank: {
        key: 'colourRank',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    startWeek: {
        key: 'startWeek',
        width: 90,
        type: 'scrollable',
        filterable: true,
    },
    endWeek: {
        key: 'endWeek',
        width: 90,
        type: 'scrollable',
        filterable: true,
    },
    digitalStartWeek: {
        key: 'digitalStartWeek',
        width: 100,
        type: 'scrollable',
        filterable: true,
    },
    digitalEndWeek: {
        key: 'digitalEndWeek',
        width: 100,
        type: 'scrollable',
        filterable: true,
    },
    weekOfCampaign: {
        key: 'weekOfCampaign',
        width: 130,
        type: 'scrollable',
        filterable: true,
    },
    storesFlowRange: {
        key: 'storesFlowRange',
        width: 110,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    digitalFlowRange: {
        key: 'digitalFlowRange',
        width: 110,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    buyComments: {
        key: 'buyComments',
        width: 110,
        type: 'scrollable',
        sortable: true,
    },
    typeOfOrder: {
        key: 'typeOfOrder',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    extendedSizing: {
        key: 'extendedSizing',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    vat: {
        key: 'vat',
        width: 90,
        renderValue: (aggregatedMaterial) => aggregatedMaterial.vat ? (0, formatters_1.formatPercentage)(aggregatedMaterial.vat) : '',
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    ukVat: {
        key: 'ukVat',
        width: 100,
        renderValue: (aggregatedMaterial) => aggregatedMaterial.ukVat ? (0, formatters_1.formatPercentage)(aggregatedMaterial.ukVat) : '',
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    firstOfferDate: {
        key: 'firstOfferDate',
        width: 135,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    carryInFromPreviousSeason: {
        key: 'carryInFromPreviousSeason',
        width: 190,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    carryoverToNextSeason: {
        key: 'carryoverToNextSeason',
        width: 160,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    launchFlag: {
        key: 'launchFlag',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    launchDate: {
        key: 'launchDate',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    core: {
        key: 'core',
        width: 110,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    gel: {
        key: 'gel',
        width: 80,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    membership: {
        key: 'membership',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    membershipLaunchDate: {
        key: 'membershipLaunchDate',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    sneakrsOrUnlaced: {
        key: 'sneakrsOrUnlaced',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    pack: {
        key: 'pack',
        width: 110,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    brandArchitecture: {
        key: 'brandArchitecture',
        width: 155,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    seasonalCategoryStories: {
        key: 'seasonalCategoryStories',
        width: 164,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    zeroStarStories: {
        key: 'zeroStarStories',
        width: 130,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    localSportingMoment: {
        key: 'localSportingMoment',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    keyLookStyle: {
        key: 'keyLookStyle',
        width: 120,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    mindset: {
        key: 'mindset',
        width: 150,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    allocated: {
        key: 'allocated',
        width: 100,
        type: 'scrollable',
        sortable: true,
        filterable: true,
    },
    retailGBPInclVat: {
        key: 'retailGBPInclVat',
        label: exports.buyplanColumnLabels.retailGBPInclVat,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.retailGBPInclVat, 'GBP', false),
        width: 75,
        alignRight: true,
        type: 'scrollable',
        sortable: true,
    },
    wholesaleGBP: {
        key: 'wholesaleGBP',
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.wholesaleGBP, 'GBP', false),
        width: 95,
        alignRight: true,
        type: 'scrollable',
        sortable: true,
    },
    netherlandsRetailInclVat: {
        key: 'netherlandsRetailInclVat',
        label: exports.buyplanColumnLabels.netherlandsRetailInclVat,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatCurrencyOrDash)(aggregatedMaterial.netherlandsRetailInclVat, 'EUR', false),
        width: 100,
        alignRight: true,
        type: 'scrollable',
        sortable: true,
    },
    localRetailUSDInclVat: {
        key: 'localRetailUSDInclVat',
        label: exports.buyplanColumnLabels.localRetailUSDInclVat,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatUSDOrDash)(aggregatedMaterial.localRetailUSDInclVat, false, false),
        width: 100,
        alignRight: true,
        type: 'scrollable',
        sortable: true,
    },
    localWholesaleUSD: {
        key: 'localWholesaleUSD',
        label: exports.buyplanColumnLabels.localWholesaleUSD,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatUSDOrDash)(aggregatedMaterial.localWholesaleUSD, false, false),
        width: 95,
        alignRight: true,
        type: 'scrollable',
        sortable: true,
    },
    localRetailUSD: {
        key: 'localRetailUSD',
        label: exports.buyplanColumnLabels.localRetailUSD,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatUSDOrDash)(aggregatedMaterial.localRetailUSD, false, false),
        width: 75,
        alignRight: true,
        type: 'scrollable',
        sortable: true,
    },
    aur: {
        key: 'aur',
        label: exports.buyplanColumnLabels.aur,
        renderValue: (aggregatedMaterial) => (0, formatters_1.formatUSDOrDash)(aggregatedMaterial.aur, false, false),
        width: 95,
        alignRight: true,
        type: 'scrollable',
        sortable: false,
    },
    marginPercentage: {
        key: 'marginPercentage',
        label: exports.buyplanColumnLabels.marginPercentage,
        renderValue: () => null,
        width: 70,
        alignRight: true,
        type: 'scrollable',
        sortable: false,
    },
    heatLevel: {
        key: 'heatLevel',
        width: 100,
        type: 'scrollable',
        sortable: true,
    },
    distributionIntent: {
        key: 'distributionIntent',
        width: 150,
        type: 'scrollable',
        sortable: true,
    },
    noSeasonalBuy: {
        key: 'noSeasonalBuy',
        width: 100,
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    reviewedStatus: {
        key: 'reviewedStatus',
        width: 100,
        type: 'scrollable',
        isFixed: true,
        label: exports.buyplanColumnLabels.reviewedStatus,
        filterable: true,
    },
    partner: {
        key: 'partner',
        isFixed: true,
        width: 120,
        type: 'scrollable',
    },
    stores: {
        key: 'stores',
        isFixed: true,
        width: 350,
        alignRight: false,
        filterable: true,
        type: 'scrollable',
    },
    beginningOfPeriodUnits: {
        key: 'beginningOfPeriodUnits',
        label: exports.buyplanColumnLabels.beginningOfPeriodUnits,
        width: 70,
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    marginImpact: {
        key: 'marginImpact',
        width: 70,
        editable: {
            bulk: true,
            individual: false,
        },
        alignRight: true,
        type: 'scrollable',
        sortable: false,
        isFixed: true,
        renderValue: () => null,
    },
    ukSalesPercentage: {
        key: 'ukSalesPercentage',
        label: exports.buyplanColumnLabels.ukSalesPercentage,
        width: 70,
        type: 'scrollable',
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        renderValue: () => null,
    },
    rateOfSale: {
        key: 'rateOfSale',
        width: 70,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    weeksOnSale: {
        key: 'weeksOnSale',
        width: 70,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    eop: {
        key: 'eop',
        width: 70,
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    sellThrough: {
        key: 'sellThrough',
        width: 70,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    afPercentage: {
        key: 'afPercentage',
        width: 70,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    presentationStocks: {
        key: 'presentationStocks',
        width: 150,
        isFixed: true,
        editable: {
            bulk: true,
            individual: false,
        },
        alignRight: true,
        type: 'scrollable',
    },
    openToBuyUnits: {
        key: 'openToBuyUnits',
        label: exports.buyplanColumnLabels.openToBuyUnits,
        width: 110,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    aaOpenToBuyUnits: {
        key: 'aaOpenToBuyUnits',
        label: exports.buyplanColumnLabels.aaOpenToBuyUnits,
        width: 110,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    afOpenToBuyUnits: {
        key: 'afOpenToBuyUnits',
        label: exports.buyplanColumnLabels.afOpenToBuyUnits,
        width: 110,
        editable: {
            bulk: true,
            individual: false,
        },
        isFixed: true,
        alignRight: true,
        type: 'scrollable',
    },
    salesUnits: {
        key: 'salesUnits',
        label: exports.buyplanColumnLabels.salesUnits,
        width: 110,
        alignRight: true,
        type: 'scrollable',
    },
    buyWholesale: {
        key: 'buyWholesale',
        label: exports.buyplanColumnLabels.buyWholesale,
        width: 150,
        alignRight: true,
        type: 'scrollable',
        renderValue: (aggregatedMaterial) => {
            const { stores } = aggregatedMaterial;
            const storesBuyWholesaleUSD = stores.reduce((acc, store) => {
                const { localWholesaleUSD, openToBuyUnits } = store;
                return openToBuyUnits === null || localWholesaleUSD === null
                    ? acc
                    : acc + openToBuyUnits * localWholesaleUSD;
            }, 0);
            return storesBuyWholesaleUSD === 0 ? '-' : (0, formatters_1.formatUSD)(storesBuyWholesaleUSD);
        },
    },
    salesRetail: {
        key: 'salesRetail',
        label: exports.buyplanColumnLabels.salesRetail,
        width: 150,
        alignRight: true,
        type: 'scrollable',
        renderValue: (aggregatedMaterial) => {
            const { stores } = aggregatedMaterial;
            const storesSalesRetailUSD = stores.reduce((acc, store) => {
                const { sales, aur, weeksOnSale } = store;
                return weeksOnSale === null || sales === null || aur === null ? acc : acc + sales * aur;
            }, 0);
            return storesSalesRetailUSD === 0 ? '-' : (0, formatters_1.formatUSD)(storesSalesRetailUSD);
        },
    },
    favoriteStore: {
        key: 'favoriteStore',
        alignRight: true,
        childColumns: exports.favoriteStoreColumns,
        type: 'scrollable',
    },
    lastYear: {
        key: 'lastYear',
        label: exports.buyplanColumnLabels.lastYear,
        childColumns: exports.lastYearColumns,
        type: 'scrollable',
    },
};
exports.viewBuyplanColumns = Object.values(buyplanViewColumns).map((column) => {
    var _a;
    return ({
        ...column,
        label: (_a = column.label) !== null && _a !== void 0 ? _a : (0, labels_1.getLabel)(column.key),
    });
});
