import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ProcessIdentifiers, ProcessLoadStatus, ProcessStatus } from 'buyplan-common';
import moment from 'moment';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import './SyncPrices.scss';
import { syncPrices } from '../../services/pricesService';
import { getProcessStatus } from '../../services/processesService';
import useUserSettings from '../../selectors/useUserSettings';

function SyncPrices() {
    const [syncLoading, setSyncLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [syncStatus, setSyncStatus] = useState<ProcessStatus>();
    const { activeChannelId } = useUserSettings();

    const processError = !!errorMessage || syncStatus?.status === ProcessLoadStatus.error;
    const processComplete = syncStatus?.status === ProcessLoadStatus.complete;

    const getStatus = useCallback(async () => {
        try {
            const status = await getProcessStatus(ProcessIdentifiers.pullExternalPrices);
            if (!status) return;
            setSyncStatus(status?.data);
        } catch (e: unknown) {
            const err = e as Error;
            setErrorMessage(err.message || 'The prices status is unknown');
            setSyncLoading(false);
        }
    }, []);

    useEffect(() => {
        getStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeChannelId]);

    useEffect(() => {
        if (processError || processComplete) {
            setSyncLoading(false);
        }
        if (syncStatus?.status === ProcessLoadStatus.progress && !syncLoading) {
            setSyncLoading(true);
        }
    }, [processComplete, processError, syncLoading, syncStatus?.status]);

    useEffect(() => {
        let refreshStatus: NodeJS.Timeout;
        if (syncLoading) {
            refreshStatus = setInterval(() => {
                // check for status update every 10 seconds if a sync was run
                getStatus();
            }, 10000);
        }
        return () => clearInterval(refreshStatus);
    }, [getStatus, syncLoading]);

    const handlePricesSync = async () => {
        if (errorMessage) setErrorMessage('');
        try {
            await syncPrices();
            getStatus();
        } catch (err: unknown) {
            const error = err as Error;
            setErrorMessage(error.message || 'There was an error retrieving images');
            setSyncLoading(false);
        }
    };

    return (
        <div className="SyncPrices">
            <Button disabled={syncLoading} className="Button__inverted" onClick={handlePricesSync}>
                Get Latest Prices {syncLoading && <Loader width={16} />}
            </Button>
            <div className="SyncPrices__statusContainer">
                {!syncLoading && (
                    <>
                        <div
                            className={cn('SyncPrices__status', {
                                'SyncPrices__status--success': processComplete,
                                'SyncPrices__status--error': processError,
                            })}
                        >
                            {processComplete && (
                                <>
                                    <FontAwesomeIcon icon={faCheck as IconProp} /> Prices Successfully retrieved
                                </>
                            )}
                            {processError && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                    {syncStatus?.status === ProcessLoadStatus.error
                                        ? 'There was an error retrieving prices'
                                        : errorMessage || 'There was an error'}
                                </>
                            )}
                        </div>
                        <div className="SyncPrices__syncDate">
                            {syncStatus?.syncDate
                                ? `Last retrieved ${moment(syncStatus?.syncDate).calendar()}`
                                : 'Prices have not been retrieved'}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SyncPrices;
