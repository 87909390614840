import React, { memo } from 'react';
import cn from 'classnames';
import {
    SilhouetteBreakdownReportTotals,
    SilhouetteRowConfig,
    SilhouetteBreakdownReport,
    silhouetteReportRows,
} from 'buyplan-common';
import SilhouetteReportRow from './SilhouetteReportRow';
import './SilhouetteReportTable.scss';

interface Props {
    results: { report: SilhouetteBreakdownReport[]; totals: SilhouetteBreakdownReportTotals };
    channelId: number;
}

function SilhouetteReportTable({ results, channelId }: Props) {
    const { report, totals } = results;

    return (
        <div className="SilhouetteReportTable__container">
            <table cellPadding={0} cellSpacing={0} className="SilhouetteReportTable table">
                <colgroup>
                    <col className="SilhouetteReportTable__columns-revenue" />
                    <col className="SilhouetteReportTable__columns-totalRevenue" />
                    <col className="SilhouetteReportTable__columns-seasonalOptionCount" />
                    <col className="SilhouetteReportTable__columns-tradedOptionCount" />
                    <col className="SilhouetteReportTable__columns-salesUnits" />
                    <col className="SilhouetteReportTable__columns-salesASP" />
                    <col className="SilhouetteReportTable__columns-investUnits" />
                    <col className="SilhouetteReportTable__columns-allocationUnits" />
                    <col className="SilhouetteReportTable__columns-presentationStocks" />
                    <col className="SilhouetteReportTable__columns-buyWholesaleValue" />
                    <col className="SilhouetteReportTable__columns-bop" />
                </colgroup>
                <thead>
                    <tr className="SilhouetteReportTable__header">
                        {silhouetteReportRows(channelId).map((rowConfig: SilhouetteRowConfig) => {
                            const { isGroupStart, noDivider, groupStartHeader, headerColSpan } = rowConfig;
                            if (isGroupStart) {
                                return (
                                    <th
                                        key={`${groupStartHeader}`}
                                        className={cn({
                                            'FamilyModelTable__header-data__divider': !noDivider,
                                        })}
                                        colSpan={headerColSpan}
                                    >
                                        {groupStartHeader}
                                    </th>
                                );
                            }
                            return null;
                        })}
                    </tr>
                    <tr className="SilhouetteReportTable__subheader">
                        {silhouetteReportRows(channelId).map((rowConfig: SilhouetteRowConfig) => {
                            const headerLabel = rowConfig.groupStartHeader === rowConfig.label ? '' : rowConfig.label;

                            return <th key={`${rowConfig.key}-${rowConfig.label.replace(' ', '')}`}>{headerLabel}</th>;
                        })}
                    </tr>
                    <tr className="SilhouetteReportTable__subheader-totals">
                        {silhouetteReportRows(channelId).map((rowConfig: SilhouetteRowConfig) => {
                            const { key } = rowConfig;
                            const value = totals[key as keyof typeof totals] as number | null;
                            return (
                                <th
                                    key={`${rowConfig.key}-${rowConfig.label.replace(' ', '')}`}
                                    className={cn({
                                        'SilhouetteReportTable__subheader-totals--negative': (value ?? 0) < 0,
                                    })}
                                >
                                    {rowConfig.getTotalsValue
                                        ? rowConfig.getTotalsValue(value, false)
                                        : rowConfig.getValue(value, false)}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {report.map((row) => (
                        <SilhouetteReportRow key={`silhouette-report-${row.category}`} row={row} channelId={channelId} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default memo(SilhouetteReportTable);
