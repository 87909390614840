import React from 'react';
import { MarginImpactRecord } from 'buyplan-common';
import './MarginImpactTable.scss';
import useCanEditBuyPlan from '../../helpers/useCanEditBuyPlan';
import MarginImpactRow from './MarginImpactRow';

interface Props {
    marginImpacts: MarginImpactRecord[];
}

function MarginImpactTable({ marginImpacts }: Props) {
    const [, , canEditBuyPlan] = useCanEditBuyPlan();
    return (
        <table cellPadding={0} cellSpacing={0} className="MarginImpactTable table table-striped table-bordered">
            <thead>
                <tr>
                    <th className="MarginImpactTable__header-category">Category</th>
                    <th className="MarginImpactTable__header-division">Division</th>
                    <th className="MarginImpactTable__header-gender">Gender</th>
                    <th className="MarginImpactTable__header-margin-impact">Margin Impact</th>
                    <th className="MarginImpactTable__header-uk-percentage">UK Sales Percentage</th>
                </tr>
            </thead>
            <tbody>
                {marginImpacts.map((marginImpact) => (
                    <MarginImpactRow
                        key={marginImpact.id}
                        marginImpactRecord={marginImpact}
                        isBuyplanActive={!!canEditBuyPlan}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default MarginImpactTable;
