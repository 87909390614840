import React from 'react';
import qs from 'qs';
import cn from 'classnames';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from '../../store/reducers';
import './SalesTargetsTemplateButton.scss';

function SalesTargetsTemplateButton() {
    const { activeChannelId, activeSeasonId } = useSelector(({ user }) => user.settings);
    const { accessToken } = useSelector(({ user }) => user);

    return (
        <a
            href={`${process.env.REACT_APP_API_ENDPOINT}/buyplan/sales-targets/export-targets/?${qs.stringify({
                activeSeason: activeSeasonId,
                channelId: activeChannelId,
                Authorization: `Bearer ${accessToken}`,
            })}`}
            className={cn('Button', 'SalesTargetsTemplateButton')}
            download
        >
            <FontAwesomeIcon size="lg" className="SalesTargetsTemplateButton__icon" icon={faDownload as IconProp} />
            Sales Targets Template File
        </a>
    );
}

export default SalesTargetsTemplateButton;
