"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyGrowth = exports.weightedMeanBy = exports.subtractWithNullDefault = exports.divideWithNullDefault = exports.calculateGrowth = exports.round = exports.average = exports.sumNullBy = exports.sumNull = exports.sumBy = exports.sum = void 0;
/**
 * For use in array.reduce(). Null-values are counted as 0.
 */
const sum = (a, b) => (a !== null && a !== void 0 ? a : 0) + (b !== null && b !== void 0 ? b : 0);
exports.sum = sum;
/**
 * For use in array.reduce(). Null-values are counted as 0.
 */
const sumBy = (collection, key) => collection.map((item) => item[key]).reduce(exports.sum, 0);
exports.sumBy = sumBy;
/**
 * For use in array.reduce(). Returns null if all values are null, otherwise treats null values as 0
 */
const sumNull = (a, b) => (a === null && b === null ? null : (0, exports.sum)(a, b));
exports.sumNull = sumNull;
/**
 * For use in array.reduce(). Returns null if all values are null, otherwise treats null values as 0
 */
const sumNullBy = (collection, key) => collection.map((item) => item[key]).reduce(exports.sumNull);
exports.sumNullBy = sumNullBy;
/**
 * For use in array.reduce()
 */
const average = (total, current, index, array) => {
    if (index === array.length - 1) {
        return (total + (current !== null && current !== void 0 ? current : 0)) / array.length;
    }
    return total + (current !== null && current !== void 0 ? current : 0);
};
exports.average = average;
const round = (numberOfDigits, numberToRound) => {
    const multiplier = 10 ** numberOfDigits;
    return Math.round(numberToRound * multiplier) / multiplier;
};
exports.round = round;
/**
 * Calculates the growth %. Special cases are:
 * - dividend === null && divisor === null yields null
 * - dividend !== null && divisor === null yields null
 * - dividend === null && divisor !== null yields -100%
 */
const calculateGrowth = (dividend, divisor) => {
    if (divisor === 0 || divisor === null) {
        return null;
    }
    if (dividend === null) {
        return -1;
    }
    return dividend / divisor - 1;
};
exports.calculateGrowth = calculateGrowth;
/**
 * Divides two numbers. Returns null if divisor is null or 0. This prevents a division by zero yielding NaN.
 */
const divideWithNullDefault = (dividend, divisor) => {
    if (divisor === null || divisor === 0) {
        return null;
    }
    return (dividend !== null && dividend !== void 0 ? dividend : 0) / divisor;
};
exports.divideWithNullDefault = divideWithNullDefault;
/**
 * Subtracts two numbers. Return null if both values ore null.
 */
const subtractWithNullDefault = (a, b) => {
    if (a === null && b === null) {
        return null;
    }
    return (a !== null && a !== void 0 ? a : 0) - (b !== null && b !== void 0 ? b : 0);
};
exports.subtractWithNullDefault = subtractWithNullDefault;
const weightedMeanBy = (collection, meanKey, recordCountKey) => {
    var _a;
    let totalValue = 0;
    let totalCount = 0;
    for (const item of collection) {
        if (item[recordCountKey] !== null && item[recordCountKey] !== undefined) {
            const recordCount = item[recordCountKey];
            const mean = (_a = item[meanKey]) !== null && _a !== void 0 ? _a : 0;
            totalCount += recordCount;
            totalValue += mean * recordCount;
        }
    }
    if (totalCount === 0) {
        return null;
    }
    return totalValue / totalCount;
};
exports.weightedMeanBy = weightedMeanBy;
const applyGrowth = (start, percentage) => Math.round(start * (1 + percentage) * 100) / 100;
exports.applyGrowth = applyGrowth;
