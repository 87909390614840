import { connect } from 'react-redux';
import { setActiveMainFilters } from '../../../actions/user';
import { UserState } from '../../../reducers/user';
import OptionCounts from './OptionCounts';

const mapDispatchToProps = {
    setActiveMainFilters,
};

const mapStateToProps = ({
    user: {
        settings: { activeChannelId },
    },
}: {
    user: UserState;
}) => ({
    channelId: activeChannelId,
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionCounts);
