import React, { useEffect, useState } from 'react';
import { CreateSeasonData, EditSeasonData, LoadingStatus } from 'buyplan-common';
import TextField from '@mui/material/TextField';
import { MobileDatePicker as DatePicker } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './SeasonForm.scss';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    formatStringToDateAndAdd,
    formatStringToDate,
    formatDateToString,
    suggestNewSeason,
} from '../NewSeason/season-helper';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { useSelector } from '../../store/reducers';
import { editSeason, newSeason } from '../../actions/season';

export enum SeasonFormType {
    new = 'new',
    edit = 'edit',
}

type SeasonLoadingStatus = {
    status: LoadingStatus;
    error: string | undefined;
};

export interface SeasonFormProps {
    type: SeasonFormType;
}

function SeasonForm(props: SeasonFormProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { type } = props;
    const [defaultValues, setDefaultValues] = useState({} as CreateSeasonData);
    const [seasonLoadingStatus, setSeasonLoadingStatus] = useState({} as SeasonLoadingStatus);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isHistoricSeason, setIsHistoricSeason] = useState(false);
    const { currentSeason, loadingStatus } = useSelector((state) => state.season);
    const { settings } = useSelector((state) => state.user);
    const { activeSeasonId } = settings;
    const { name, startDate, flow2StartDate, flow3StartDate, endDate } = defaultValues;

    useEffect(() => {
        if (type === SeasonFormType.edit) {
            setDefaultValues({
                name: currentSeason?.name || '',
                startDate: currentSeason?.startDate || '',
                flow2StartDate: currentSeason?.flow2StartDate || '',
                flow3StartDate: currentSeason?.flow3StartDate || '',
                endDate: currentSeason?.endDate || '',
            } as EditSeasonData);
        } else if (type === SeasonFormType.new) {
            const suggestedSeason = currentSeason && suggestNewSeason(currentSeason);
            if (isSubmitting) return;
            setDefaultValues({
                name: suggestedSeason?.name || '',
                startDate: suggestedSeason?.startDate || '',
                flow2StartDate: suggestedSeason?.flow2StartDate || '',
                flow3StartDate: suggestedSeason?.flow3StartDate || '',
                endDate: suggestedSeason?.endDate || '',
            } as CreateSeasonData);
        }
    }, [currentSeason, isSubmitting, type]);

    useEffect(() => {
        if (type === SeasonFormType.edit) {
            setSeasonLoadingStatus({
                status: loadingStatus.editSeason,
                error: loadingStatus.editSeasonErrorMessage,
            });
        } else if (type === SeasonFormType.new) {
            setSeasonLoadingStatus({
                status: loadingStatus.newSeason,
                error: loadingStatus.newSeasonErrorMessage,
            });
        }
    }, [loadingStatus, type]);

    useEffect(() => {
        if (type === SeasonFormType.edit && currentSeason && currentSeason.id !== activeSeasonId) {
            setIsHistoricSeason(true);
        }
    }, [activeSeasonId, currentSeason, type]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setDefaultValues({
            ...defaultValues,
            [target.name]: target.value,
        } as CreateSeasonData);
    };

    const handleDateChange = (fieldName: string, date: Date) => {
        setDefaultValues({
            ...defaultValues,
            [fieldName]: formatDateToString(date),
        } as CreateSeasonData);
    };

    const handleSubmit = async (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        if (type === SeasonFormType.edit) {
            await dispatch(
                editSeason({
                    id: activeSeasonId,
                    name,
                    startDate: formatDateToString(startDate),
                    flow2StartDate: formatDateToString(flow2StartDate),
                    flow3StartDate: formatDateToString(flow3StartDate),
                    endDate: formatDateToString(endDate),
                })
            );
        } else if (type === SeasonFormType.new) {
            try {
                // This flag prevents new data from being loaded as defaultValues when a new season is created
                setIsSubmitting(true);
                await dispatch(
                    newSeason({
                        name,
                        startDate: formatDateToString(startDate),
                        flow2StartDate: formatDateToString(flow2StartDate),
                        flow3StartDate: formatDateToString(flow3StartDate),
                        endDate: formatDateToString(endDate),
                    })
                );
                setTimeout(() => {
                    history.push('/buyplan');
                    window.location.reload();
                }, 2000);
            } catch (err) {
                // Leave it to the global error
            }
        }
    };
    return (
        <div className="SeasonForm">
            <form className="SeasonForm__form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={name || ''}
                    name="name"
                    id="name"
                    placeholder="e.g. HO21"
                    pattern="[A-Z]{2}[0-9]{2}"
                    onChange={handleInputChange}
                    disabled={isHistoricSeason}
                />
                <label htmlFor="name">Season name</label>

                <div className="input">
                    <DatePicker<Date>
                        InputProps={{ id: 'startDate' }}
                        disableCloseOnSelect={false}
                        value={startDate ? formatStringToDate(startDate) : null}
                        onChange={(date: Date | null) => (date ? handleDateChange('startDate', date) : null)}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        disabled={isHistoricSeason}
                    />
                </div>
                <label htmlFor="startDate">Season start date</label>

                <div className="input">
                    <DatePicker<Date>
                        InputProps={{ id: 'startFlow2' }}
                        disableCloseOnSelect={false}
                        value={formatStringToDate(flow2StartDate)}
                        minDate={startDate ? formatStringToDateAndAdd(startDate, { days: 1 }) : undefined}
                        onChange={(date: Date | null) => (date ? handleDateChange('flow2StartDate', date) : null)}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        disabled={isHistoricSeason}
                    />
                </div>
                <label htmlFor="startFlow2">Start flow 2</label>
                <div className="input">
                    <DatePicker<Date>
                        InputProps={{ id: 'startFlow3' }}
                        disableCloseOnSelect={false}
                        value={formatStringToDate(flow3StartDate)}
                        minDate={flow2StartDate ? formatStringToDateAndAdd(flow2StartDate, { days: 1 }) : undefined}
                        onChange={(date: Date | null) => (date ? handleDateChange('flow3StartDate', date) : null)}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        disabled={isHistoricSeason}
                    />
                </div>
                <label htmlFor="startFlow3">Start flow 3</label>

                <div className="input">
                    <DatePicker<Date>
                        InputProps={{ id: 'endDate' }}
                        disableCloseOnSelect={false}
                        value={formatStringToDate(endDate)}
                        minDate={flow3StartDate ? formatStringToDateAndAdd(flow3StartDate, { days: 1 }) : undefined}
                        onChange={(date: Date | null) => (date ? handleDateChange('endDate', date) : null)}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        disabled={isHistoricSeason}
                    />
                </div>
                <label htmlFor="endDate">Season end date</label>

                <div className="input">
                    <ConfirmButton
                        onClick={handleSubmit}
                        loading={seasonLoadingStatus.status === LoadingStatus.loading || isSubmitting}
                        label={type === SeasonFormType.edit ? 'Edit season' : 'Start new season'}
                        confirmLabel={type === SeasonFormType.edit ? 'Really edit season?' : 'Really start a new season?'}
                        disabled={seasonLoadingStatus.status === LoadingStatus.loading || isHistoricSeason}
                        data-cy="seasonform-submit"
                    />
                </div>

                {isHistoricSeason && (
                    <div className="SeasonForm__error seasonError">
                        <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> Historic seasons cannot be edited
                    </div>
                )}

                {seasonLoadingStatus.status === LoadingStatus.error && (
                    <div className="SeasonForm__error output">
                        <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {seasonLoadingStatus.error}
                    </div>
                )}

                {seasonLoadingStatus.status === LoadingStatus.success && (
                    <div className="SeasonForm__success output">
                        <FontAwesomeIcon icon={faCheckCircle as IconProp} /> Your change has been successful!
                    </div>
                )}
            </form>
        </div>
    );
}

export default SeasonForm;
