import React, { ChangeEvent } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider } from '@material-ui/core/styles';
import { DropdownOption } from 'buyplan-common';
import { materialDropdownTheme } from '../../helpers/theme';
import { isInvalidInputValue } from '../../helpers/utils';

export interface DropdownProps {
    id?: string;
    options: DropdownOption[];
    onChange(value: string | number): void;
    value: string | number;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    disableUnderline?: boolean;
}

function Dropdown({ onChange, value, options, label, id, disabled, placeholder, disableUnderline }: DropdownProps) {
    const handleChange = (
        event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        onChange(event.target.value as string | number);
    };
    if (value) {
        const valueAsString = value.toString().toLowerCase();
        const valueExists = options.find((el) => el.value.toString().toLowerCase() === valueAsString);
        if (valueExists === undefined) {
            options.push({
                label: value.toString(),
                value,
            });
        }
    }
    return (
        <ThemeProvider theme={materialDropdownTheme}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                placeholder={placeholder}
                id={id}
                value={value || ''}
                onChange={handleChange}
                disabled={disabled}
                IconComponent={ExpandMoreIcon}
                disableUnderline={disableUnderline}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                title={`${value}`}
                error={isInvalidInputValue(value)}
            >
                {options.map((option) => (
                    <MenuItem key={option.label} value={option.value} disabled={option.disabled}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </ThemeProvider>
    );
}

export default Dropdown;
