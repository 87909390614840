import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getChannelAudience, ChannelAudience } from 'buyplan-common';
import SeasonForm, { SeasonFormType } from '../SeasonForm/SeasonForm';
import { useSelector } from '../../store/reducers';

function EditSeason() {
    const { settings } = useSelector((state) => state.user);
    const channelAudience = getChannelAudience(settings.activeChannelId);
    const titleMessage = () => {
        if (channelAudience === ChannelAudience.ndsDigital) {
            return 'EDIT SEASON: NDS / DIGITAL';
        }
        if (channelAudience === ChannelAudience.nvs) {
            return 'EDIT SEASON: NVS';
        }
        return 'EDIT SEASON';
    };

    return (
        <Container>
            <Row>
                <Col xs={{ span: 8, offset: 2 }}>
                    <h1>{titleMessage()}</h1>
                    <SeasonForm type={SeasonFormType.edit} />
                </Col>
            </Row>
        </Container>
    );
}

export default EditSeason;
