"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLabel = exports.camelCaseToSpaced = void 0;
const labelsMap = {
    consumer: 'CC Consumer',
    segment: 'CC Segment',
    emeaSubFranchise: 'EMEA Sub-Franchise',
    emeaBusinessModel: 'EMEA Business Model',
    emeaCollectionName: 'EMEA Collection Name',
    emeaDimension: 'EMEA Dimension',
    emeaFamily: 'EMEA Family',
    emeaFootballPlates: 'EMEA Football Plates',
    emeaSilo: 'EMEA Silo',
    gphCategory: 'GPH Category',
    thirdParty: '3rd Party Vendor (Licensee)',
    features: 'Features (Sustainability Tier)',
    storesFlowRange: 'Stores Flow',
    digitalFlowRange: 'Digital Flow',
    aa: 'AA',
    vat: 'VAT %',
    ukVat: 'UK VAT %',
    brandArchitecture: 'EMEA Brand Architecture',
    seasonalCategoryStories: 'Seasonal Category Stories',
    retailGBPInclVat: 'Retail GBP Incl. VAT',
    wholesaleGBP: 'Wholesale GBP',
    netherlandsRetail: 'Retail Price Euro',
    netherlandsRetailInclVat: 'Retail Euro Incl. VAT',
    localRetailUSD: 'Retail Price USD',
    netherlandsWholesale: 'Wholesale Euro',
    localWholesaleUSD: 'Wholesale USD',
    rateOfSale: 'ROS',
    weeksOnSale: 'WOS',
    eop: 'EOP',
    sellThrough: 'ST%',
    afPercentage: 'AF%',
    buyRetail: 'Buy Retail Value USD',
    buyWholesale: 'Buy WHSL Value USD',
    lastYearRateOfSale: 'LY ROS',
    lastYearWeeksOnSale: 'LY WOS',
    lastYearSellThrough: 'LY ST%',
    lastYearMarginPercentage: 'LY MARGIN %',
    lastYearSalesUnits: 'LY SALES UNITS',
    lastYearStyle: 'LY Style',
    lastYearColor: 'LY Color',
    lastYearMaterialCode: 'LY Material Code',
    lastYearDescription: 'LY Description',
    lastYearColorDescription: 'LY Color Description',
    lastYearMatchReason: 'LY Match Reason',
    lastYearMaterialCount: 'LY Material Count',
    lastYearTotalUnits: 'LY Total Units',
    localRetailInclVat: 'Retail Price incl. VAT Euro',
    selectAll: 'ALL CLUSTERS',
};
const camelCaseToSpaced = (text) => text.charAt(0).toUpperCase() + text.slice(1).replace(/([a-z0-9])([A-Z])/g, '$1 $2');
exports.camelCaseToSpaced = camelCaseToSpaced;
const getLabel = (key) => { var _a; return (_a = labelsMap[key]) !== null && _a !== void 0 ? _a : (0, exports.camelCaseToSpaced)(key); };
exports.getLabel = getLabel;
