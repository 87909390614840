import React, { memo, useMemo, useEffect, useState } from 'react';
import cn from 'classnames';
import qs from 'qs';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StoreLevelBreakdownReport, StoreStoreLevelBreakdownReportData } from 'buyplan-common';
import usePrevious from '../../../helpers/usePrevious';
import { syncTableHorizontalScrolls } from '../../../helpers/tableTools';
import { useSelector } from '../../../store/reducers';
import { useRequestFunction } from '../../../api/useRequest';
import { getStoreLevelReport } from '../../../services/buyPlanReportsService';
import { getBuyPlanMetaData } from '../../../actions/buyplan';
import { combineWords } from '../../../helpers/language';
import useActiveFilters from '../../../selectors/useActiveFilters';
import useMetaData from '../../../selectors/useMetaData';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
import MainFilters from '../../Filters/MainFilters/MainFilters';
import { PageView } from '../../../constants/appConfig';
import { getMissingMainFilterKeys } from '../../../helpers/utils';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { shouldUpdateBuyPlanMeta } from '../../../helpers/filters';
import StoreLevelTable from './StoreLevelTable';
import './StoreLevelReport.scss';

let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

interface ReportProps {
    missingFiltersKeys: string[];
    error?: Error;
    loading: boolean;
    storeLevelReportTotal: StoreLevelBreakdownReport[] | null;
    storeLevelReportStores: StoreStoreLevelBreakdownReportData[] | null;
    channelId: number;
    filtersHaveChanged: boolean;
}

const renderReport = ({
    missingFiltersKeys,
    error,
    loading,
    storeLevelReportTotal,
    storeLevelReportStores,
    channelId,
    filtersHaveChanged,
}: ReportProps) => {
    if (missingFiltersKeys.length > 0) {
        return (
            <div className="StoreLevelReport--error-message">
                Please select a filter for {combineWords(missingFiltersKeys)}
            </div>
        );
    }
    if (loading) return <Loader />;
    if (error) {
        return (
            <div className="StoreLevelReport--error-message">
                <ErrorMessage error={error} />
            </div>
        );
    }
    if (!storeLevelReportTotal || !storeLevelReportTotal.length) {
        if (!storeLevelReportTotal) return <div className="StoreLevelReport--error-message">Click to load report data.</div>;
        return <div className="StoreLevelReport--error-message">No report data found, try changing filters</div>;
    }
    if (filtersHaveChanged) return <div className="StoreLevelReport--error-message">Click to load report data.</div>;
    return (
        <div className="StoreLevelReport--table-container">
            {storeLevelReportStores && storeLevelReportStores.length > 0 && (
                <div className="StoreLevelReport--table-stores">
                    {storeLevelReportStores.map((store) => (
                        <div key={store.storeNumber} className="StoreLevelReport--table-stores--store">
                            <h2>{`${store.storeNumber} - ${store.storeName}`}</h2>
                            <StoreLevelTable
                                report={store.reportData}
                                tableId={store.storeNumber}
                                syncTableHorizontalScrolls={syncTableHorizontalScrolls}
                                channelId={channelId}
                            />
                        </div>
                    ))}
                    <p className="StoreLevelReport--table-stores__end">END OF STORE LIST, SCROLL UP</p>
                </div>
            )}
        </div>
    );
};

function StoreLevelReport() {
    const { activeChannelId: channelId, activeSeasonId } = useSelector(({ user }) => user.settings);
    const { metaData, isLoading } = useMetaData(PageView.storeLevelReport);
    const { mainFilters } = useActiveFilters(PageView.storeLevelReport);
    const { accessToken } = useSelector(({ user }) => user);
    const { partner, category } = mainFilters;
    const previousPartner = usePrevious(partner);
    const previousChannelId = usePrevious(channelId);
    const previousCategory = usePrevious(category);
    const previousMainFilters = usePrevious(mainFilters);
    const [isButtonPristine, setIsButtonPristine] = useState(true);
    const missingFiltersKeys = getMissingMainFilterKeys(
        mainFilters,
        reportsFiltersConfig[PageView.storeLevelReport].requiredFilters
    );

    const [loading, error, reportData, fetchStoreLevel] = useRequestFunction(getStoreLevelReport);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getBuyPlanMetaData(
                channelId,
                false,
                reportsFiltersConfig[PageView.storeLevelReport].allFilters,
                PageView.storeLevelReport
            )
        );
    }, [dispatch, channelId]);

    useEffect(() => {
        // If the required filters (partner / category) change, refresh the data in the other filters
        if (!partner || !category) return;
        if (shouldUpdateBuyPlanMeta([partner, previousPartner], [category, previousCategory])) {
            dispatch(
                getBuyPlanMetaData(
                    channelId,
                    false,
                    reportsFiltersConfig[PageView.storeLevelReport].allFilters,
                    PageView.storeLevelReport
                )
            );
        }
    }, [dispatch, channelId, partner, category, previousPartner, previousChannelId, previousCategory]);

    useEffect(() => {
        if (missingFiltersKeys.length === 0 && !isEqual(previousMainFilters, mainFilters)) {
            resetAbortController();
            setIsButtonPristine(true);
        }
    }, [mainFilters, previousMainFilters, fetchStoreLevel, missingFiltersKeys, channelId, metaData, reportData]);

    const { storeLevelReportTotal, storeLevelReportStores } = useMemo(() => {
        if (!reportData) {
            return { storeLevelReportTotal: null, storeLevelReportStores: null };
        }
        return reportData;
    }, [reportData]);

    let disabledFilterKeys: string[] = [];

    // Disable supplementary filters if required filters are missing
    if (!partner || !category) {
        disabledFilterKeys = [...reportsFiltersConfig[PageView.storeLevelReport].supplementaryFilters];
    }

    // If filters are missing or if there are no results
    const enableDownload = missingFiltersKeys.length === 0 || (!loading && !!storeLevelReportTotal?.length);

    const loadReport = () => {
        resetAbortController();
        setIsButtonPristine(false);
        fetchStoreLevel(mainFilters, abortController.signal);
    };
    const isMetaLoading = isLoading && isEmpty(metaData);
    return (
        <div className={cn('StoreLevelReport', { 'StoreLevelReport--loading': isLoading || loading })}>
            <div className="StoreLevelReport--top-section">
                <h1>STORE LEVEL REPORT</h1>
                {!isMetaLoading && (
                    <div
                        className={cn({
                            'StoreLevelReport--filter-container': !!storeLevelReportTotal?.length && !isButtonPristine,
                        })}
                    >
                        <MainFilters
                            filters={metaData.mainFilters}
                            activeMainFilters={mainFilters}
                            activeChannelId={channelId}
                            disabledFilterKeys={disabledFilterKeys}
                            placeholder="Select"
                            view={PageView.storeLevelReport}
                            isFiltersLoading={isLoading}
                        />
                        <Button
                            className="Button StoreLevelReport--load-report-button"
                            disabled={!isButtonPristine || isLoading || missingFiltersKeys.length > 0}
                            onClick={loadReport}
                        >
                            LOAD REPORT
                        </Button>
                    </div>
                )}
                {missingFiltersKeys.length === 0 &&
                    storeLevelReportTotal &&
                    storeLevelReportTotal.length > 0 &&
                    !isButtonPristine && (
                        <>
                            <a
                                href={`${
                                    process.env.REACT_APP_API_ENDPOINT
                                }/buyplan/reports/store-level-report-export/?${qs.stringify({
                                    filter: mainFilters,
                                    activeSeason: activeSeasonId,
                                    channelId,
                                    Authorization: `Bearer ${accessToken}`,
                                })}`}
                                className={cn('Button', 'StoreLevelReport__download-button', {
                                    'StoreLevelReport__download-button--disabled': !enableDownload,
                                })}
                                download
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    className="StoreLevelReport__download-button-icon"
                                    icon={faDownload as IconProp}
                                />
                                Download report in Excel
                            </a>
                            <div className="StoreLevelReport--table-totals">
                                {storeLevelReportTotal.length !== 0 && !isButtonPristine && (
                                    <>
                                        <h2>TOTALS</h2>
                                        <StoreLevelTable
                                            report={storeLevelReportTotal}
                                            tableId="totals"
                                            syncTableHorizontalScrolls={syncTableHorizontalScrolls}
                                            channelId={channelId}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
            </div>
            {renderReport({
                missingFiltersKeys,
                error,
                loading: isLoading || loading,
                storeLevelReportTotal,
                storeLevelReportStores,
                channelId,
                filtersHaveChanged: isButtonPristine,
            })}
        </div>
    );
}

export default memo(StoreLevelReport);
