import React from 'react';
import cn from 'classnames';
import { SilhouetteBreakdownReport, SilhouetteRowConfig, silhouetteReportRows } from 'buyplan-common';
import './SilhouetteReportRow.scss';

interface Props {
    row: SilhouetteBreakdownReport;
    channelId: number;
}

function SilhouetteReportRow({ row, channelId }: Props) {
    return (
        <tr className="SilhouetteReportRow">
            {silhouetteReportRows(channelId).map((configRow: SilhouetteRowConfig) => {
                const { key, noDivider, isGroupStart, groupStartHeader } = configRow;
                const rowValue = row[key as keyof SilhouetteBreakdownReport] as number | null;
                const rowFormattedValue = configRow.getValue(rowValue, false);
                return (
                    <td
                        key={`${groupStartHeader}-${key}`}
                        className={cn({
                            SilhouetteReportRow__divider: isGroupStart && !noDivider,
                            'SilhouetteReportRow--negative': (rowValue ?? 0) < 0,
                        })}
                    >
                        {rowFormattedValue}
                    </td>
                );
            })}
        </tr>
    );
}

export default SilhouetteReportRow;
