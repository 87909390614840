import React, { useState, useEffect } from 'react';
import { orderBy, xor, flatten, concat } from 'lodash';
import { useDispatch } from 'react-redux';
import CheckBox from '../CheckBox/CheckBox';
import { setVisibleColumns, toggleVisibleColumn } from '../../actions/user';
import { VisibleColumnSetting } from '../../reducers/user';
import './SettingsReportColumns.scss';
import useUserSettings from '../../selectors/useUserSettings';

interface Props {
    columnGroups: ColumnsGroup[];
    columnSetting: VisibleColumnSetting;
}

interface Column {
    key: string;
    label: string;
}

export interface ColumnsGroup {
    groupLabel: string;
    groupKey: string;
    columns: Column[];
}

function SettingsDisplayColumns({ columnGroups, columnSetting }: Props) {
    const dispatch = useDispatch();
    const settings = useUserSettings();

    const [allColumns, setAllColumns] = useState([] as Column[]);
    useEffect(() => {
        const newAllColumns = flatten(columnGroups.map(({ columns }) => columns)) ?? [];
        setAllColumns(newAllColumns);
    }, [columnGroups]);

    const [allColumnsSelected, setAllColumnsSelected] = useState(false);
    const [visibleSettingsColumns, setVisibleSettingsColumns] = useState([] as Column[]);
    useEffect(() => {
        const isAllColumnsSelected = allColumns.every(({ key }) => settings[columnSetting].includes(key));
        const newVisibleColumns = allColumns.filter(({ key }) => settings[columnSetting].includes(key));

        setAllColumnsSelected(isAllColumnsSelected);
        setVisibleSettingsColumns(newVisibleColumns);
    }, [allColumns, settings, columnSetting]);

    const handleToggleVisibleColumn = (key: string) => dispatch(toggleVisibleColumn(columnSetting, key));
    const handleToggleAllVisibleColumns = (enableAll: boolean) => {
        const newColumns = (enableAll ? allColumns : []).map(({ key }) => key);
        dispatch(setVisibleColumns(columnSetting, newColumns));
    };

    const handleToggleGroupVisibleColumns = (selectedGroupKey: string, isChecked: boolean) => {
        const group = columnGroups.find(({ groupKey }) => groupKey === selectedGroupKey);
        const groupColumns = group ? group.columns : [];

        const newColumns = isChecked
            ? concat(visibleSettingsColumns, groupColumns).map(({ key }) => key)
            : xor(visibleSettingsColumns, groupColumns).map(({ key }) => key);

        dispatch(setVisibleColumns(columnSetting, newColumns));
    };

    return (
        <div className="SettingsReportColumns">
            <CheckBox
                id="settings-all-columns"
                label={allColumnsSelected ? `Deselect all columns` : 'Select all columns'}
                onChange={() => handleToggleAllVisibleColumns(!allColumnsSelected)}
                isChecked={allColumnsSelected}
            />
            <hr />
            <div className="SettingsReportColumns__groups">
                {columnGroups.map(({ columns, groupLabel, groupKey }) => {
                    const isGroupChecked = columns.every(({ key }) => settings[columnSetting].includes(key));
                    return (
                        <div key={groupLabel} className="SettingsReportColumns__group">
                            <CheckBox
                                id={`settings-group-${groupKey}`}
                                label={groupLabel}
                                onChange={() => handleToggleGroupVisibleColumns(groupKey, !isGroupChecked)}
                                isChecked={isGroupChecked}
                                className="SettingsReportColumns__groupName"
                            />
                            <ul className="SettingsReportColumns__groupList">
                                {orderBy(columns, 'label').map(({ key, label }) => (
                                    <li key={key}>
                                        <CheckBox
                                            label={label}
                                            onChange={() => handleToggleVisibleColumn(key)}
                                            isChecked={settings[columnSetting].includes(key)}
                                            id={`settings-${key}`}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SettingsDisplayColumns;
