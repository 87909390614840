"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssortedBy = void 0;
/*
 - PLANNER is being set when assortment is done by Nike planner using Buyplan
 - PARTNER is being set when assortment is done by external partner using Buyplan Partner portal
*/
var AssortedBy;
(function (AssortedBy) {
    AssortedBy["planner"] = "PLANNER";
    AssortedBy["partner"] = "PARTNER";
})(AssortedBy || (exports.AssortedBy = AssortedBy = {}));
