import { UserProfile } from 'buyplan-common';
import { request } from '../api/api';

export const getImpersonatedUserData = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user_impersonate/v1`,
    };
    return request<{ data: { impersonatedUser: UserProfile } }>(requestOptions);
};

export const impersonateUser = async (email: string) =>
    request<{ data: { impersonatedUser: UserProfile } }>(
        {
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/user_impersonate/v1`,
            data: {
                email,
            },
        },
        true
    );

export const deactivateUserImpersonation = async (suppressGlobalError = true) =>
    request<null>(
        {
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/user_impersonate/deactivate/v1`,
        },
        suppressGlobalError
    );
