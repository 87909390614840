'use strict';
import '@babel/polyfill';
import { flatten } from 'lodash';

if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    require('promise/lib/rejection-tracking').enable();
    window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
    require('raf').polyfill(global);
}

// Array.prototype.find & Array.prototype.findIndex ES6 polyfill  ES6 polyfill
require('jspolyfill-array.prototype.find');
require('jspolyfill-array.prototype.findIndex');

// Array.from ES6 polyfill
if (!Array.from) {
    Array.from = require('array-from');
}

if (!Array.prototype.flatMap) {
    Array.prototype.flatMap = function(callback) {
        return flatten([].concat(this).map(callback));
    };
}
// ES6-compliant shim for Number.isNaN - the global isNaN returns false positives.
Number.isNaN = require('is-nan');
