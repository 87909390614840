import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent } from 'react';
import cn from 'classnames';
import './IconButton.scss';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
    onClick: (ev: MouseEvent) => void;
    className?: string;
    icon: IconProp;
    title?: string;
}

function IconButton({ className, icon, onClick, title }: Props) {
    if (title) {
        return (
            <Tooltip tooltip={title}>
                <button type="button" onClick={onClick} className={cn('IconButton', className)}>
                    <FontAwesomeIcon icon={icon} />
                </button>
            </Tooltip>
        );
    }

    return (
        <button type="button" onClick={onClick} className={cn('IconButton', className)}>
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}

export default IconButton;
