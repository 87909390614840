"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceSource = exports.CurrencyType = void 0;
var CurrencyType;
(function (CurrencyType) {
    CurrencyType["USD"] = "USD";
    CurrencyType["LOCAL"] = "local";
})(CurrencyType || (exports.CurrencyType = CurrencyType = {}));
var PriceSource;
(function (PriceSource) {
    PriceSource["api"] = "API";
    PriceSource["file"] = "FILE";
})(PriceSource || (exports.PriceSource = PriceSource = {}));
