import {
    ProcessIdentifiers,
    ExchangeRatesOverride,
    ExchangeRatesOverrideUpdate,
    ExchangeRatesOverrideInsert,
    ExchangeRateStatus,
} from 'buyplan-common';
import { request } from '../api/api';

export type ExchangeRate = {
    id: string;
    plannedInRetailYear: string;
    currency: string;
    rate: number;
};

export type ExchangeRateInsert = Pick<ExchangeRate, 'plannedInRetailYear' | 'currency'> & { USDConversionRate: number };
export type ExchangeRateUpdate = Pick<ExchangeRate, 'currency' | 'id'> & {
    USDConversionRate: number;
};

export type ExchangeRateSnapshot = {
    lastSnapshotDate: string;
    plannedInRetailYear: string;
};

export const getExchangeRates = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/exchange-rates`,
    };

    return request<{ data: { exchangeRates: ExchangeRate[]; exchangeRatesOverride: ExchangeRatesOverride } }>(
        requestOptions,
        true
    );
};

export const getIsUpdateAllowedForExchangeRates = (plannedInRetailYear: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/exchange-rates/${plannedInRetailYear}/is-update-allowed`,
    };

    return request<{
        data: ExchangeRateStatus;
    }>(requestOptions, true);
};

export const getLatestSnapshotData = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/exchange-rates/snapshot`,
    };

    return request<{
        data: ExchangeRateSnapshot;
    }>(requestOptions, true);
};

export const setExchangeRates = (
    exchangeRates: ExchangeRateInsert[],
    exchangeRatesOverride: ExchangeRatesOverrideInsert
) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/exchange-rates`,
        data: { exchangeRates, exchangeRatesOverride },
    };

    return request(requestOptions, true);
};

export const updateExchangeRates = (
    exchangeRates: ExchangeRateUpdate[],
    exchangeRatesOverride: ExchangeRatesOverrideUpdate,
    plannedInRetailYear: string
) => {
    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/exchange-rates`,
        queryParams: { plannedInRetailYear },
        data: { exchangeRates, exchangeRatesOverride },
    };

    return request(requestOptions, true);
};

export const processFXRates = (operation: ProcessIdentifiers) => {
    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/exchange-rates/process-fx-rates/v1`,
        data: { operation },
    };

    return request(requestOptions, true);
};
