import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ProcessIdentifiers, ProcessLoadStatus } from 'buyplan-common';
import moment from 'moment';
import qs from 'qs';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { getExchangeRates, getIsUpdateAllowedForExchangeRates, processFXRates } from '../../services/exchangeRatesService';
import { useRequest, useRequestFunction } from '../../api/useRequest';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import Tooltip, { TooltipType } from '../Tooltip/Tooltip';
import './ExchangeRates.scss';
import { useSelector } from '../../store/reducers';
import { getLatestSnapshot, updateFxRatesProcessStatus } from '../../actions/notifications';
import { useFxRatesStatus } from './helpers';

function ExchangeRatesView() {
    const { activeChannelId, activeSeasonId } = useSelector(({ user }) => user.settings);
    const { accessToken } = useSelector(({ user }) => user);
    const { snapshotData } = useSelector(({ notification }) => notification.processLoadStatus.fxRates);
    const dispatch = useDispatch();
    const isSuperUser = useIsSuperUser();

    const [exchangeRatesLoading, exchangeRatesError, exchangeRatesData] = useRequest(getExchangeRates);
    const [{ plannedInRetailYear }] = exchangeRatesData?.exchangeRates || [{}];
    const [isUpdateAllowedLoading, isUpdateAllowedError, exchangeRatesStatus, fetchIsUpdateAllowedForExchangeRates] =
        useRequestFunction(getIsUpdateAllowedForExchangeRates);
    const fxProcessStatus = useFxRatesStatus();
    const [permissions, setUpdatePermissions] = useState({
        canCreateNewPry: false,
        canEdit: false,
        canApply: false,
        canRollback: false,
    });
    const [isRollbackRunning, setIsRollbackRunning] = useState(false);

    useEffect(() => {
        if (!plannedInRetailYear) return;
        fetchIsUpdateAllowedForExchangeRates(plannedInRetailYear);
    }, [fetchIsUpdateAllowedForExchangeRates, plannedInRetailYear]);

    useEffect(() => {
        dispatch(getLatestSnapshot());
    }, [dispatch]);

    useEffect(() => {
        if (!exchangeRatesStatus) return;
        setUpdatePermissions({
            canCreateNewPry: exchangeRatesStatus.isAddingFXRatesAllowed,
            canEdit: exchangeRatesStatus.isEditingFXRatesAllowed,
            canApply: exchangeRatesStatus.isApplyingFXRatesAllowed,
            canRollback: exchangeRatesStatus.isRollbackFXRatesAllowed,
        });
    }, [exchangeRatesStatus]);

    useEffect(() => {
        // When rollback completes we clear the status of the processLoadStatus for fxRates in Redux
        // This in combination with the local isRollbackRunning flag signals that the rollback is complete
        switch (fxProcessStatus) {
            case ProcessLoadStatus.progress:
                setUpdatePermissions({
                    canCreateNewPry: false,
                    canEdit: false,
                    canApply: false,
                    canRollback: false,
                });
                break;
            case ProcessLoadStatus.complete:
                setUpdatePermissions({
                    canCreateNewPry: true,
                    canEdit: false,
                    canApply: false,
                    canRollback: true,
                });
                break;
            case ProcessLoadStatus.error:
                setUpdatePermissions({
                    canCreateNewPry: false,
                    canEdit: false,
                    canApply: false,
                    canRollback: true,
                });
                break;
            default:
                if (!fxProcessStatus && isRollbackRunning) {
                    setUpdatePermissions({
                        canCreateNewPry: false,
                        canEdit: true,
                        canApply: true,
                        canRollback: false,
                    });
                    setIsRollbackRunning(false);
                    dispatch(getLatestSnapshot());
                }
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fxProcessStatus]);

    const handleApplyFXRatesClick = useCallback(() => {
        dispatch(updateFxRatesProcessStatus(ProcessLoadStatus.progress));
        processFXRates(ProcessIdentifiers.applyExchangeRatesForPlanAndHindsight);
    }, [dispatch]);

    const handleRollbackFXRatesClick = useCallback(() => {
        setIsRollbackRunning(true);
        dispatch(updateFxRatesProcessStatus(ProcessLoadStatus.progress));
        processFXRates(ProcessIdentifiers.rollbackDBFromSnapshot);
    }, [dispatch]);

    return (
        <div className="ExchangeRates">
            <h1>
                Exchange rates for {plannedInRetailYear}
                {exchangeRatesLoading && <Loader width={16} />}
            </h1>
            {exchangeRatesError && (
                <div className="ExchangeRates__error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {exchangeRatesError.message}
                </div>
            )}
            {isUpdateAllowedError && (
                <div className="ExchangeRates__error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {isUpdateAllowedError.message}
                </div>
            )}
            {exchangeRatesData && exchangeRatesData.exchangeRates.length && (
                <Container>
                    <Row className="ExchangeRates__header">
                        <Col>Currency</Col>
                        <Col>Rate</Col>
                    </Row>
                    {exchangeRatesData.exchangeRates.map(({ currency, rate, id }) => (
                        <Row key={id} className="ExchangeRates__rate">
                            <Col className="ExchangeRates__label">{currency}</Col>
                            <Col>{rate}</Col>
                        </Row>
                    ))}
                    <Row className="ExchangeRates__hindsightValueContainer">
                        <Col className="ExchangeRates__label">Hindsight Value Adjustment (Digital)</Col>
                        <Col>{exchangeRatesData.exchangeRatesOverride?.fxRateAdjustment || 'No override set'}</Col>
                    </Row>
                </Container>
            )}
            {!isSuperUser || isUpdateAllowedLoading ? null : (
                <Container>
                    <Row className="ExchangeRates__buttonRow">
                        <div className="ExchangeRates__buttonContainer">
                            {permissions.canCreateNewPry ? (
                                <Link to="/buyplan/exchange-rates/add" className="Button ExchangeRates__add">
                                    New PRY
                                </Link>
                            ) : (
                                <Button className="ExchangeRates__add" disabled>
                                    New PRY
                                </Button>
                            )}
                            {permissions.canEdit ? (
                                <Link to="/buyplan/exchange-rates/edit" className="Button ExchangeRates__edit">
                                    Edit
                                </Link>
                            ) : (
                                <Tooltip
                                    type={TooltipType.error}
                                    placement="right"
                                    tooltip="Exchange rates already applied and can't be edited"
                                >
                                    <Button className="ExchangeRates__edit" disabled>
                                        Edit
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                        <div className="ExchangeRates__buttonContainer">
                            <div>
                                <Button
                                    className="ExchangeRates__apply"
                                    size="m"
                                    disabled={!permissions.canApply}
                                    onClick={handleApplyFXRatesClick}
                                >
                                    Apply FX-rates
                                </Button>
                                <Button
                                    size="m"
                                    className="ExchangeRates__rollback"
                                    onClick={handleRollbackFXRatesClick}
                                    disabled={!permissions.canRollback}
                                >
                                    Rollback FX-rates
                                </Button>
                            </div>
                            <div className="ExchangeRates__snapshotData">
                                {!snapshotData || !snapshotData?.lastSnapshotDate ? null : (
                                    <div className="ExchangeRates__label">
                                        LATEST SNAPSHOT: {moment(snapshotData?.lastSnapshotDate).format('YYYY-MM-DD HH:mm')},{' '}
                                        {snapshotData?.plannedInRetailYear}
                                    </div>
                                )}
                            </div>
                            <div className="ExchangeRates__report">
                                <a
                                    href={`${
                                        process.env.REACT_APP_API_ENDPOINT
                                    }/exchange-rates/${plannedInRetailYear}/report?${qs.stringify({
                                        activeSeason: activeSeasonId,
                                        channelId: activeChannelId,
                                        Authorization: `Bearer ${accessToken}`,
                                    })}`}
                                    className={cn('Button', { Button__disabled: !permissions.canRollback })}
                                    download
                                >
                                    Download report
                                </a>
                            </div>
                        </div>
                    </Row>
                </Container>
            )}
        </div>
    );
}

export default ExchangeRatesView;
