import React from 'react';

export default function () {
    return (
        <div>
            <h2>Top 15 Styles</h2>
            <p>
                This report allows you to see planning data on multiple levels in order to ensure that all important aspects
                of a plan are covered.
            </p>
            <h3>Summaries</h3>
            <p>
                In order to get to this report, <code>CLICK</code> on <strong>Top 15 Styles</strong> under the{' '}
                <strong>Reports</strong> tab. In this view, you have two different visuals - this year&apos;s top styles
                report and last year&apos;s top styles report. These reports show only the 15 materials which have the
                highest revenue for selected partners/categories/divisions. If no filter is selected, it shows the 15
                materials with the highest revenue for all partners/categories/divisions. The filter is applied to both
                reports simultaneously and can be filtered by Partner, Category, Division, Types of Order, Gender, Age,
                Silhouette, Model, Segment and Family. Both reports have a summary that sums the top 5, top 10, top 15, and
                the total result. Last year&apos;s variance in percentage is presented in the summary of this year&apos;s
                report.
            </p>

            <h3>Calculations</h3>

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Value</th>
                        <th scope="col">Formula</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">% TTL REVENUE</th>
                        <td>revenue / total revenue</td>
                    </tr>
                    <tr>
                        <th scope="row">% TTL SALES UNITS</th>
                        <td>sales units / total sales units</td>
                    </tr>
                    <tr>
                        <th scope="row">SALES ASP</th>
                        <td>sales units / sell through</td>
                    </tr>
                    <tr>
                        <th scope="row">% TTL PRESENTATION STOCKS</th>
                        <td>presentation stocks / total presentation stocks</td>
                    </tr>
                    <tr />
                </tbody>
            </table>
            <p>
                <em>
                    NOTE:
                    <ul>
                        <li>This report is available for NDS and DIGITAL</li>
                    </ul>
                </em>
            </p>
        </div>
    );
}
