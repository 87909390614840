import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckLoading, faAngleDoubleRight, faListAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PurchaseOrder, SoldTo, Summary, triggerSubmit, getSummary } from '../../services/submitToPegaService';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import './SubmitToPega.scss';

function SubmitToPega() {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [summary, setSummary] = useState({} as Summary);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchSummary = async () => {
        if (errorMessage) setErrorMessage('');
        setLoading(true);
        try {
            const summaryToSet = await getSummary();
            setSummary(summaryToSet);
        } catch (e: unknown) {
            const err = e as Error;
            setErrorMessage(err.message || 'There was an error fetching the summary');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSendClick = async () => {
        if (errorMessage) setErrorMessage('');
        setSubmitting(true);
        // eslint-disable-next-line no-alert
        const result = window.confirm('Are you sure you want to export all orders?');
        if (result) {
            try {
                const response = await triggerSubmit();
                setSummary(response.summary);
            } catch (e: unknown) {
                const err = e as Error;
                setErrorMessage(err.message || 'There was an error submitting the summary');
            } finally {
                setSubmitting(false);
            }
        }
    };

    return (
        <ContentWrapper>
            <Container>
                <Row>
                    <Col sm={{ span: 12 }}>
                        <div className="submitToPega__view">
                            <h1>EXPORT STAGED ORDERS {loading && <Loader width={16} />}</h1>
                            <div className="button-wrapper">
                                <Button onClick={handleSendClick} className="submitToPega__button">
                                    Submit to PEGA&nbsp;
                                    <FontAwesomeIcon icon={faAngleDoubleRight as IconProp} />
                                    &nbsp;
                                    <FontAwesomeIcon icon={faTruckLoading as IconProp} />
                                    {submitting && <Loader width={12} />}
                                </Button>
                            </div>
                        </div>
                        {errorMessage && (
                            <p className="submitToPega__error">
                                <FontAwesomeIcon icon={faExclamationCircle as IconProp} /> {errorMessage}
                            </p>
                        )}
                        {summary ? (
                            <>
                                {summary.purchaseOrders && (
                                    <div className="submitToPega__summary submitToPega__purchase-orders">
                                        <h3>
                                            <FontAwesomeIcon icon={faListAlt as IconProp} size="xs" /> PURCHASE ORDER NUMBERS
                                        </h3>
                                        <ul className="summary">
                                            {summary.purchaseOrders.map((purchaseOrder: PurchaseOrder) => (
                                                <li key={`po_${purchaseOrder.purchaseOrderNumber}`} className="summary-line">
                                                    <div className="summary-label">{purchaseOrder.purchaseOrderNumber}</div>
                                                    <div className="summary-quantity">{purchaseOrder.quantity}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {summary.soldTo && (
                                    <div className="submitToPega__summary submitToPega__sold-to">
                                        <h3>
                                            <FontAwesomeIcon icon={faListAlt as IconProp} size="xs" /> SOLD TO
                                        </h3>
                                        <ul className="summary">
                                            {summary.soldTo.map((soldTo: SoldTo) => (
                                                <li key={`st_${soldTo.soldToNumber}`} className="summary-line">
                                                    <div className="summary-label">{soldTo.soldToNumber}</div>
                                                    <div className="summary-quantity">{soldTo.quantity}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {summary.meta && (
                                    <div className="submitToPega__summary submitToPega__meta">
                                        <h3>
                                            <FontAwesomeIcon icon={faListAlt as IconProp} size="xs" /> TOTAL
                                        </h3>
                                        <ul className="summary">
                                            <li className="summary-line">
                                                <div className="summary-label">QUANTITY</div>
                                                <div className="summary-quantity">{summary.meta.total}</div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </>
                        ) : (
                            <Loader width={24} />
                        )}
                    </Col>
                </Row>
            </Container>
        </ContentWrapper>
    );
}

export default SubmitToPega;
