import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { UserProfile } from 'buyplan-common';
import { getUserProfile } from '../../services/usersService';
import { setAccessToken, loginUser } from '../../actions/user';
import { useSelector } from '../../store/reducers';
import Loader from '../Loader/Loader';
import { getImpersonatedUserData } from '../../services/impersonationService';
import { startImpersonatingUser } from '../../actions/impersonation';
import { handleGlobalError } from '../../actions/globalError';
import './Authorizer.scss';

const publicUrls = ['/mancala/documentation', '/buyplan/documentation'];

export interface AuthorizerProps {
    children: ReactElement;
}

interface JWTClaims {
    groups: string[];
}

const validateNoChannelAccess = (user: UserProfile) => {
    const noChannelAccess: string[] = [];
    for (const channel of user.channels) {
        const { partner, category, division } = channel;
        if (!partner || !category || !division) {
            noChannelAccess.push(channel.name);
        }
    }
    return noChannelAccess;
};

function Authorizer({ children }: AuthorizerProps): ReactElement | null {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const profile = useSelector((state) => state.user.profile);
    const [apiError, setApiError] = useState<Error>();
    const [hasMissingChannelAccess, setHasMissingChannelAccess] = useState<boolean>();
    const [jwtData, setJwtData] = useState<JWTClaims>();
    const { authState, authService } = useOktaAuth();
    const [impersonation] = useCookies(['BuyplanImpersonationStatus']);
    const onAuthStateChange = useCallback(
        async ({ accessToken }) => {
            if (accessToken) {
                const jwtObject = jwtDecode(accessToken) as JWTClaims;
                setJwtData(jwtObject);
                dispatch(setAccessToken(accessToken));
                try {
                    const { data } = await getUserProfile();
                    const noChannelAccess = validateNoChannelAccess(data);
                    if (noChannelAccess.length > 0) {
                        setHasMissingChannelAccess(true);
                        dispatch(
                            handleGlobalError(
                                `User not assigned with Partner/Category/Division for channels: ${noChannelAccess.join(
                                    ', '
                                )}. Please contact channel admin(s) to assign to your profile.`
                            )
                        );
                    }
                    dispatch(loginUser(data));
                    if (impersonation.BuyplanImpersonationStatus) {
                        const { data: responseData } = await getImpersonatedUserData();
                        dispatch(startImpersonatingUser(responseData.impersonatedUser));
                    }
                } catch (err: unknown) {
                    const error = err as Error;
                    setApiError(error);
                }
            }
        },
        [dispatch, impersonation]
    );

    useEffect(() => {
        // Listen to authStateChange change event so that we update the access token in redux
        // on both initial login and auto-renewal
        authService.on('authStateChange', onAuthStateChange);
        authService.updateAuthState();
    }, [authService, onAuthStateChange]);

    if (authState.isPending) {
        return <Loader />;
    }

    // We haven't finished logging in yet; return the Okta callback page and a dummy root, but nothing else
    if (profile === undefined && !apiError) {
        return (
            <Switch>
                <Route path="/implicit/callback" component={LoginCallback} />
                <SecureRoute path="/" component={() => null} />
            </Switch>
        );
    }

    const errorMessage = () => {
        const hasADGroup =
            jwtData?.groups !== undefined && jwtData.groups.some((adGroup) => adGroup.toLowerCase().includes('buyplan'));

        if (apiError && apiError.message.includes('conflicting Buyplan AD groups')) {
            return (
                <>
                    <h2>YOU HAVE CONFLICTING BUYPLAN AD GROUPS ASSIGNED TO YOUR PROFILE</h2>
                    <h3>
                        PLEASE CONSULT{' '}
                        <a href="https://idlocker.nike.com" target="_blank" rel="noreferrer">
                            ID LOCKER
                        </a>{' '}
                        AND REMOVE ANY BUYPLAN AD GROUP(S) THAT ARE NOT NECESSARY FOR YOUR ACCESS REQUIREMENTS.
                    </h3>
                    <h3>
                        FOR MORE DETAILS ABOUT AD GROUPS FOR BUYPLAN, SEE{' '}
                        <a
                            href="https://confluence.nike.com/display/EHQSD/User+Access+Management+for+Buyplan"
                            target="_blank"
                            rel="noreferrer"
                        >
                            CONFLUENCE
                        </a>
                        . FOR ADDITIONAL SUPPORT, PLEASE CONTACT A BUYPLAN FRONT RUNNER
                    </h3>
                </>
            );
        }
        if (hasADGroup && hasMissingChannelAccess) {
            return (
                <>
                    <h2>THANK YOU FOR LOGGING INTO BUYPLAN. A PROFILE HAS NOW BEEN CREATED FOR YOU.</h2>
                    <h3>
                        PLEASE CONTACT A CHANNEL ADMIN TO ASSIGN THE APPROPRIATE PARTNER(S), CATEGORY(IES), AND DIVISION(S)
                        TO YOUR PROFILE.
                    </h3>
                </>
            );
        }
        if (!hasADGroup) {
            return (
                <>
                    <h2>YOU DO NOT HAVE ACCESS TO THIS APPLICATION</h2>
                    <h3>
                        PLEASE FOLLOW THE STEPS OUTLINED IN{' '}
                        <a
                            href="https://confluence.nike.com/display/EHQSD/User+Access+Management+for+Buyplan"
                            target="_blank"
                            rel="noreferrer"
                        >
                            CONFLUENCE
                        </a>{' '}
                        TO REQUEST ACCESS TO BUYPLAN AND/OR MANCALA.
                    </h3>
                </>
            );
        }
        return (
            <>
                <h2>THERE WAS AN ERROR LOGGING YOU IN</h2>
                {apiError && (
                    <div className="Authorizer__error-block">
                        <pre>{apiError.message ?? apiError}</pre>
                    </div>
                )}
            </>
        );
    };

    // If user is not logged in and not on a public url, redirect to the homepage and show an error.
    const isAuthorized = !!profile;
    const isOnPublicUrl = publicUrls.some((url) => pathname.startsWith(url));
    if (!isAuthorized && !isOnPublicUrl) {
        if (pathname !== '/') {
            history.push('/');
            return null;
        }

        return (
            <div className="Authorizer">
                <h1>BUYPLAN &amp; MANCALA</h1>
                {errorMessage()}
            </div>
        );
    }

    // At this point either the user is authorized or on a public url
    return children;
}

export default Authorizer;
