/* eslint-disable @typescript-eslint/no-non-null-assertion */
import fileSaver from 'file-saver';
import { get } from 'lodash';
import { Store } from 'redux';
import qs from 'qs';
import { handleGlobalError } from '../actions/globalError';

export type OnProgress = (progress: number, event?: ProgressEvent<XMLHttpRequestEventTarget>) => void;

let store: Store | null = null;

export function setStore(s: Store) {
    store = s;
}

function getMethod(method: string) {
    return method || 'GET';
}

function getBody({ data, file }: RequestOptions) {
    if (file) {
        return file;
    }
    return data ? JSON.stringify(data) : undefined;
}

export interface ResponseError extends Error {
    response?: Response;
    meta?: Record<string, unknown>;
}

const checkStatus = async <T>(data: T, response: Response) => {
    if (response.status >= 200 && response.status < 300) {
        return;
    }
    const responseText = await response.text();
    const error = new Error(get(data, 'error.message', responseText || response.statusText)) as ResponseError;
    error.response = response;
    error.meta = get(data, 'error.meta', undefined);
    throw error;
};

export const globalErrorHandler = (err: ResponseError) => {
    const status = get(err, 'response.status', null);
    if (status === 401) {
        // TODO this url currently does not exist, not sure what we should do?
        window.location.href = '/need-authorization';
    } else if (err.name !== 'AbortError') {
        // Abort errors occur when a newer request aborted the previous request; we can safely ignore these
        store!.dispatch(handleGlobalError(err.message));
    }

    throw err; // Rethrow so that the source component can still take action if needed
};

export interface RequestOptions {
    url: string;
    method: string;
    headers?: HeadersInit;
    skipAuth?: boolean;
    signal?: AbortSignal;
    noContentType?: boolean;
    data?: unknown;
    file?: File | FormData;
    queryParams?: Record<string, unknown>;
}

/**
 * When making a request take care to:
 * - define queryParams as request options, not as part of the url
 * - only set suppressGlobalError = true if your component handles the error manually
 */
export async function request<T>(options: RequestOptions, suppressGlobalError = false): Promise<T> {
    const { url, method, skipAuth, signal, noContentType } = options;
    let { queryParams } = options;
    const presetHeaders = {} as { [key: string]: string };
    if (!noContentType) {
        presetHeaders['Content-Type'] = 'application/json';
    }
    if (!skipAuth) {
        presetHeaders.Authorization = `Bearer ${store!.getState().user.accessToken}`;
    }
    const userSettings = store!.getState().user.settings || {};
    const { activeChannelId, activeSeasonId } = userSettings;

    if (activeSeasonId) {
        queryParams = { ...queryParams, activeSeason: activeSeasonId };
    }

    if (activeChannelId) {
        queryParams = { ...queryParams, channelId: activeChannelId };
    }

    const headers = options.headers ? { ...presetHeaders, ...options.headers } : presetHeaders;
    const body = getBody(options);

    const urlWithQuery = queryParams ? `${url}?${qs.stringify(queryParams)}` : url;

    try {
        const response = await fetch(urlWithQuery, {
            method: getMethod(method),
            body,
            headers,
            signal,
            credentials: 'include',
        });

        let data = null;
        const contentType = response.headers.get('Content-Type');
        if (contentType && /application\/json/i.test(contentType)) {
            data = await response.clone().json();
        }
        await checkStatus(data, response);

        return data;
    } catch (err: unknown) {
        if (suppressGlobalError) {
            throw err;
        }
        return globalErrorHandler(err as ResponseError);
    }
}

interface DownloadRequestOptions {
    url: string;
    filename: string;
    queryParams?: Record<string, unknown>;
}

export async function download({ url, filename, queryParams }: DownloadRequestOptions) {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${store!.getState().user.accessToken}`,
    };

    let qp = queryParams;
    const userSettings = store!.getState().user.settings || {};
    const { activeChannelId, activeSeasonId } = userSettings;

    if (activeSeasonId) {
        qp = { ...queryParams, activeSeason: activeSeasonId };
    }

    if (activeChannelId) {
        qp = { ...queryParams, channelId: activeChannelId };
    }

    const urlWithQuery = queryParams ? `${url}?${qs.stringify(qp)}` : url;

    const response = await fetch(urlWithQuery, {
        method: 'GET',
        headers,
    });

    fileSaver.saveAs(await response.blob(), filename);
}
