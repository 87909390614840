import React, { useCallback, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UserRole } from 'buyplan-common';
import { useDispatch } from 'react-redux';
import { useRequest } from '../../api/useRequest';
import Loader from '../Loader/Loader';
import { createPermissionsString } from '../../helpers/permissions';
import { getUsers } from '../../services/usersService';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import Button from '../Button/Button';
import './UserList.scss';
import { deactivateUserImpersonation, impersonateUser } from '../../services/impersonationService';
import { startImpersonatingUser, stopImpersonatingUser } from '../../actions/impersonation';
import useImpersonationStatus from '../../selectors/useImpersonationStatus';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';

function UserList() {
    const dispatch = useDispatch();
    const [loading, error, users] = useRequest(getUsers);
    const isSuperUser = useIsSuperUser();
    const isAdmin = useIsChannelAdmin();
    const { impersonationInProgress, impersonatedProfile } = useImpersonationStatus();
    const [impersonationError, setHasImpersonationError] = useState('');
    const [redirect, setRedirect] = useState('');

    const handleImpersonation = useCallback(
        async (userEmail: string, initiateImpersonation: boolean) => {
            if (impersonationError) {
                setHasImpersonationError('');
            } else if (initiateImpersonation) {
                try {
                    const { data } = await impersonateUser(userEmail);
                    dispatch(startImpersonatingUser(data.impersonatedUser));
                    setRedirect('/buyplan');
                } catch (err: unknown) {
                    setHasImpersonationError('User impersonation failed.');
                }
            } else {
                try {
                    await deactivateUserImpersonation();
                    dispatch(stopImpersonatingUser());
                    setRedirect('/buyplan');
                } catch (err: unknown) {
                    setHasImpersonationError(`Failed to end impersonation for user: ${userEmail}`);
                }
            }
        },
        [dispatch, impersonationError]
    );

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>MANAGE USERS</h1>
                    {loading && <Loader />}
                    {error && (
                        <div className="UserList__error error">
                            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />
                            There was an error fetching the users
                        </div>
                    )}
                    {impersonationError && (
                        <div className="UserList__error error">
                            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />
                            {impersonationError}
                        </div>
                    )}
                    <ul className="UserList">
                        {users &&
                            users.map((user) => {
                                // It can be that the channel role is not defined. In this case the user must log in to verify their role via their AD group.
                                // Impersonation should be disabled until the role is defined.
                                const missingChannelRole = !!user.channels.find((channelObj) => !channelObj.channelRole);
                                return (
                                    <li key={user.email}>
                                        <span className="UserList__email">
                                            {!isAdmin || user.role === UserRole.superUser ? (
                                                user.email
                                            ) : (
                                                <Link to={`/buyplan/manage-users/edit/${user.email}`}>{user.email}</Link>
                                            )}
                                        </span>
                                        <span className="UserList__summary">{createPermissionsString(user)}</span>
                                        <span className="UserList__edit">
                                            <Link to={`/buyplan/manage-users/edit/${user.email}`}>
                                                <Button
                                                    disabled={!isAdmin || user.role === UserRole.superUser} // must be admin to edit, cannot edit super users
                                                    className="Button Button--size-s"
                                                >
                                                    Edit
                                                </Button>
                                            </Link>
                                        </span>
                                        {impersonationInProgress && impersonatedProfile?.email === user.email && (
                                            <span className="UserList__deactivateImpersonation">
                                                <Button
                                                    className="Button Button--size-s"
                                                    onClick={() => handleImpersonation(user.email, false)}
                                                >
                                                    Stop Impersonation
                                                </Button>
                                            </span>
                                        )}
                                        {!impersonationInProgress && isSuperUser && (
                                            <span className="UserList__impersonate">
                                                <Button
                                                    disabled={
                                                        user.role === UserRole.superUser ||
                                                        impersonationInProgress ||
                                                        missingChannelRole
                                                    }
                                                    className="Button Button--size-s"
                                                    onClick={() => handleImpersonation(user.email, true)}
                                                >
                                                    {missingChannelRole ? 'User must log in' : 'Impersonate'}
                                                </Button>
                                            </span>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default UserList;
