import { intersection, mapValues, omitBy, isNil } from 'lodash';
import {
    AllFilters,
    FilteringMetaData,
    FilterOptions,
    isNumberArray,
    isStringArray,
    MainFiltersExtended,
} from 'buyplan-common';

const getValuesFromOptions = (filterOptions?: FilterOptions) => {
    let result: (string | number)[];
    if (filterOptions === undefined || filterOptions.options === undefined) {
        result = [];
    } else {
        const { options } = filterOptions;
        if (isStringArray(options) || isNumberArray(options)) {
            result = options;
        } else {
            result = options.map((option) => option.value);
        }
    }
    return result;
};
export const removeUndefinedFilter = (activeFilters: AllFilters, filter: AllFilters = {}) =>
    omitBy({ ...activeFilters, ...filter }, isNil) as AllFilters;

// Finds that specific filter in the metaData using its field
export const findFilterInMetaData = (metaDataFilters: FilterOptions[], field: string) =>
    (metaDataFilters ?? []).find((metaDataFilter) => metaDataFilter.field === field);

/**
 * Removes filters and filter option values that are not part of the metadata.
 */
export const removeInvalidFilters = (activeFilters: AllFilters = {}, metaDataFilters: FilterOptions[] = []): AllFilters => {
    const newFilters = mapValues(activeFilters, (value, field) => {
        const metaDataFilter = findFilterInMetaData(metaDataFilters, field);
        const availableOptionValues = getValuesFromOptions(metaDataFilter);
        return intersection((value as string[]) ?? [], availableOptionValues);
    });
    // Only returns valid values
    return omitBy(newFilters, (value) => value === undefined || value.length === 0) as AllFilters;
};
/**
 * Removes filter values that no longer exists in the metadata.
 * Does not modify any filters (keys) that do not exist in the metadata.
 */
export const removeInvalidFilterValues = (
    activeFilters: AllFilters = {},
    metaDataFilters: FilterOptions[] = []
): AllFilters =>
    mapValues(activeFilters, (value, field) => {
        const metaDataFilter = findFilterInMetaData(metaDataFilters, field);
        if (!metaDataFilter) {
            return value;
        }

        const availableOptionValues = getValuesFromOptions(metaDataFilter);

        const result = intersection((value as string[]) ?? [], availableOptionValues);
        return result.length === 0 ? undefined : result; // Set to undefined if there's no valid values
    }) as AllFilters;

export const getActiveFiltersDefaultFromMeta = (currentActiveFilters: MainFiltersExtended, metaData: FilteringMetaData) => {
    const activeFilters: MainFiltersExtended = {};

    const availableFilterValues = removeInvalidFilterValues(currentActiveFilters, metaData.mainFilters);

    (['category', 'partner', 'division', 'storeId', 'gender', 'age'] as const).forEach((field) => {
        const availableOptions =
            (findFilterInMetaData(metaData.mainFilters, field)?.options as
                | string[]
                | {
                      label: string;
                      value: string;
                  }[]
                | undefined) ?? [];
        // If there's only one available option for one of the main filters, select that option as active filter
        if (availableOptions && availableOptions.length === 1) {
            if (isStringArray(availableOptions)) {
                activeFilters[field] = availableOptions;
            } else {
                activeFilters[field] = [availableOptions[0].value as string];
            }
        } else if (((availableFilterValues && availableFilterValues[field]?.length) ?? 0) > 0) {
            activeFilters[field] = availableFilterValues[field];
        } else {
            // The filter needs to be set to undefined, otherwise it will be overwritten with the old filter in SET_ACTIVE_MAIN_FILTERS
            activeFilters[field] = undefined;
        }
    });
    return activeFilters;
};
