import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearFilter, getOverviewMetaData } from '../../../actions/overview';
import * as overviewService from '../../../services/overviewService';
import { useSelector } from '../../../store/reducers';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { getTotalLenghtOfAllArraysWithinObject } from '../../../helpers/utils';

function DeleteButton() {
    const dispatch = useDispatch();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const { activeFilters } = useSelector(({ overview }) => overview);
    const activeFilterAmount = useMemo(() => getTotalLenghtOfAllArraysWithinObject(activeFilters), [activeFilters]);

    const handleDeleteByFilter = async () => {
        if (deleteConfirmed) {
            setDeleting(true);
            try {
                await overviewService.deleteByFilter(activeFilters);
                setShowConfirmModal(false);
                await Promise.all([dispatch(clearFilter()), dispatch(getOverviewMetaData())]);
                setShowSuccessModal(true);
            } catch (err) {
                // Handled by global error handler
            } finally {
                setDeleting(false);
            }
        }
        setDeleteConfirmed(!deleteConfirmed);
    };

    return (
        <>
            {activeFilterAmount > 0 && (
                <Button
                    className="Button-fullWidth deleteButton Button__inverted danger"
                    onClick={() => setShowConfirmModal(true)}
                    disabled={deleting}
                >
                    DELETE
                </Button>
            )}

            <Modal
                isOpen={showSuccessModal}
                closeAction={() => setShowSuccessModal(false)}
                title="Deletion completed"
                okAction={() => undefined}
                okButtonText="CLOSE"
                autoCloseAfterAction
            >
                All data based upon the selected filters has been deleted from the database
            </Modal>

            <Modal
                isOpen={showConfirmModal}
                closeAction={() => setShowConfirmModal(false)}
                title="Delete all by selected filter"
                okAction={handleDeleteByFilter}
                okButtonText={deleteConfirmed ? 'REALLY DELETE ?!' : 'DELETE'}
                okButtonType="danger"
                cancelAction={() => setShowConfirmModal(false)}
            >
                Warning: This will delete everything that matches your current filters from the database ! Do you wish to
                continue? This cannot be undone
            </Modal>
        </>
    );
}

export default DeleteButton;
