import { ExportSeason } from 'buyplan-common';
import { request } from '../api/api';

export const getExportSeasons = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/export-seasons`,
    };

    return request<{ data: ExportSeason[] }>(requestOptions);
};
