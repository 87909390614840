import React, { memo } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import { StatusCode, MimeTypes } from 'buyplan-common';
import FileDrop from '../FileDrop/FileDrop';
import Loader from '../Loader/Loader';
import SuccessIcon from '../Icons/SuccessIcon';
import ErrorIcon from '../Icons/ErrorIcon';
import './ImportOrdersDropzone.scss';

const acceptedMimeTypes = MimeTypes.excel;

interface Props {
    loading: boolean;
    fileCount: number;
    historyUploadEnabled: boolean;
    handleDrop(acceptedFiles: File[]): void;
    handleHistoryToggle(): void;
    uploadStatus?: StatusCode;
}

const switchStyles = makeStyles(() => ({
    switch_track: {
        backgroundColor: '#cccccc',
    },
    switch_base: {
        color: '#cccccc',
        '&.Mui-disabled': {
            color: '#e886a9',
        },
        '&.Mui-checked': {
            color: '#3398DB',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#3398DB',
        },
    },
    switch_primary: {
        '&.Mui-checked': {
            color: '#4CAF50',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#4CAF50',
        },
    },
}));

function ImportOrdersDropzone({
    loading,
    uploadStatus,
    handleDrop,
    fileCount,
    historyUploadEnabled,
    handleHistoryToggle,
}: Props) {
    const plural = fileCount > 1;
    const showFileInput = !(uploadStatus || loading);
    const classes = switchStyles();

    const title = `DROP YOUR EXCEL FILE(S) HERE TO START UPLOADING AND ${
        historyUploadEnabled ? 'SKIP STAGING' : 'STAGE FOR EXPORT'
    }`;

    return (
        <div className="import-orders">
            <div className="import-orders__file-drop">
                {uploadStatus === 'COMPLETED' && (
                    <div className="import-orders__feedback import-orders__feedback--success">
                        <SuccessIcon />
                        <h3>{plural ? `${fileCount} FILES HAVE` : 'THE FILE HAS'} BEEN UPLOADED.</h3>
                    </div>
                )}
                {uploadStatus === 'FAILED' && (
                    <div className="import-orders__feedback import-orders__feedback--error">
                        <ErrorIcon />
                        <h3>
                            SOMETHING WENT WRONG WHILE UPLOADING THE {plural ? 'FILES' : 'FILE'}. PLEASE CHECK THE{' '}
                            {plural ? 'FILES' : 'FILE'} AND TRY AGAIN. SEE BELOW FOR MORE DETAILS
                        </h3>
                    </div>
                )}
                {loading && (
                    <div className="import-orders__feedback import-orders__feedback--progress">
                        <Loader />
                        <h3>PLEASE WAIT WHILE {plural ? `${fileCount} FILES ARE` : 'YOUR FILE IS'} BEING UPLOADED</h3>
                    </div>
                )}
                {showFileInput && (
                    <FileDrop
                        title={title}
                        historyUploadEnabled={historyUploadEnabled}
                        acceptedMimeTypes={acceptedMimeTypes}
                        onDrop={handleDrop}
                    />
                )}
            </div>
            <div className="import-orders__mode-picker">
                <FormControlLabel
                    control={
                        <Switch
                            classes={{
                                track: classes.switch_track,
                                switchBase: classes.switch_base,
                                colorPrimary: classes.switch_primary,
                            }}
                            checked={!historyUploadEnabled}
                            onChange={handleHistoryToggle}
                            name="importOrderPicker"
                        />
                    }
                    label="Stage orders for export"
                    labelPlacement="end"
                />
            </div>
        </div>
    );
}

export default memo(ImportOrdersDropzone);
