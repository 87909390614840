import { Store } from 'buyplan-common';
import { request } from '../api/api';

export const getStores = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/stores`,
    };

    return request<{ data: Store[] }>(requestOptions);
};

export const patchStore = (id: string, data: Pick<Store, 'like4likeStoreId'>) => {
    const requestOptions = {
        method: 'PATCH',
        data,
        url: `${process.env.REACT_APP_API_ENDPOINT}/stores/${id}`,
    };

    return request<{ data: Store }>(requestOptions);
};
