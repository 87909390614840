import React, { useState } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import Popover from '@material-ui/core/Popover';
import { SubFilters, FilterOptions, AssortmentColumnConfig, DropdownOption } from 'buyplan-common';
import { useDispatch } from 'react-redux';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { setActiveSubFilters } from '../../actions/user';
import { hasKey } from '../../helpers/typeGuards';
import { getColumnWidth } from '../../helpers/tableTools';
import Filter from '../Filters/Filter/Filter';
import HeaderColumn from '../HeaderColumn/HeaderColumn';
import SettingsButton from '../SettingsButton/SettingsButton';
import './HeaderRow.scss';
import './ViewAssortmentList.scss';
import './AssortmentStoreHeader.scss';
import FilterControl from '../Filters/FilterControl/FilterControl';
import { PageView } from '../../constants/appConfig';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';

const FILTER_TYPES = {
    materialCodeChangedAt: 'singleSelect',
};

interface Props {
    headerType: 'scrollable' | 'fixed';
    columns: AssortmentColumnConfig[];
    subFilters: FilterOptions[] | undefined;
    toggleSettings(): void;
    onSortColumn(columnKey: string, orderDirection: 'ASC' | 'DESC'): void;
    orderColumn: string;
    orderDirection: 'ASC' | 'DESC';
    onEditColumn?(columnKey: string): void;
    activeSubFilters?: SubFilters;
    activeChannelId: number;
    isOpen: boolean;
    onClick: () => void;
}

const getHeaderColumns = (columns: AssortmentColumnConfig[], headerType: string) => {
    if (headerType === 'scrollable') {
        const colsToDisplay = [...columns.filter(({ type }) => type === 'scrollable')];
        return colsToDisplay;
    }
    return columns.filter(({ type }) => type === 'fixed');
};

function HeaderRow({
    headerType,
    onEditColumn,
    onSortColumn,
    toggleSettings,
    columns,
    orderColumn,
    orderDirection,
    activeSubFilters,
    activeChannelId,
    subFilters,
    isOpen,
    onClick,
}: Props) {
    const dispatch = useDispatch();
    const isSuperUser = useIsSuperUser();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);

    const columnsToDisplay = getHeaderColumns(columns, headerType);
    const [filteredColumn, setFilteredColumn] = useState<string | undefined>();
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | undefined>();

    const handlePopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, key: string | undefined) => {
        setFilteredColumn(key);
        setAnchorEl(event?.currentTarget);
    };
    const icon = (isOpen ? faChevronRight : faChevronLeft) as IconProp;

    // Matches the correct filters (also multiple) with the correct column
    const getSubFilterOptions = (subFilterKey: string) =>
        subFilters?.filter(
            (subFilter) =>
                subFilter.field === subFilterKey ||
                // Material code exceptions (additional filters)
                (subFilterKey === 'materialCode' && subFilter.field === 'materialCodeChangedAt')
        );

    return (
        <>
            {columnsToDisplay.map(({ key, label, editable, sortable, filterable }) => {
                const subFilterOptions = getSubFilterOptions(key);
                const activeSubFilter = subFilterOptions && activeSubFilters && activeSubFilters[subFilterOptions[0]?.field];
                const editableForRole = isSuperUser || isChannelAdmin;

                if (key === 'selectAll') {
                    return (
                        <div className="ViewAssortmentList__storeHeader" key="assortment-select-all">
                            <div
                                role="presentation"
                                onClick={onClick}
                                className={cn('assortment-store-header', 'assortment-table-header__header-label')}
                                onFocus={() => undefined}
                                onBlur={() => undefined}
                            >
                                ALL CLUSTERS
                                <FontAwesomeIcon className="assortment-cluster-header__button" icon={icon} color="black" />
                            </div>
                            <div className="ViewAssortmentList__filteredCountsContainer">
                                <div className="ViewAssortmentList__filteredCountsEmpty" />
                            </div>
                        </div>
                    );
                }

                if (key === 'lock') {
                    return (
                        <div className="ViewAssortmentList__storeHeader" key="assortment-lock">
                            <div
                                className={cn('assortment-lock-header', 'assortment-table-header__header-label')}
                                onFocus={() => undefined}
                                onBlur={() => undefined}
                            >
                                LOCK
                            </div>
                            <div className="ViewAssortmentList__filteredCountsContainer">
                                <div className="ViewAssortmentList__filteredCountsEmpty" />
                            </div>
                        </div>
                    );
                }

                return (
                    <HeaderColumn
                        key={`${key}-${kebabCase(label)}`}
                        className={cn(`ViewAssortmentList__${key}`, {
                            'ViewAssortmentList__header-cell': true,
                        })}
                        style={{ width: getColumnWidth(columnsToDisplay, key) }}
                        label={label || ''}
                        onEdit={editableForRole && editable?.bulk ? () => onEditColumn && onEditColumn(key) : undefined}
                        onSort={sortable ? (sortType) => onSortColumn && onSortColumn(key, sortType) : undefined}
                        onFilter={
                            filterable && subFilters
                                ? (event) => {
                                      if (filteredColumn !== key) {
                                          handlePopover(event, key);
                                      } else {
                                          handlePopover(undefined, undefined);
                                      }
                                  }
                                : undefined
                        }
                        isColumnFiltered={activeSubFilter && activeSubFilter.length !== 0}
                        isColumnSorted={orderColumn === key}
                        orderDirection={orderDirection}
                    >
                        {key === 'toggleStores' && (
                            <div className="ViewAssortmentList__header-cell--toggles">
                                <SettingsButton onClick={toggleSettings} />
                                <FilterControl
                                    subFilters={subFilters}
                                    activeSubFilters={activeSubFilters}
                                    activeChannelId={activeChannelId}
                                    view={PageView.assortment}
                                />
                            </div>
                        )}
                        {filterable && (
                            <Popover
                                anchorEl={anchorEl}
                                onClose={() => handlePopover(undefined, undefined)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={filteredColumn === key}
                                className="HeaderRow__subfilter-popover"
                            >
                                <div key={key} className="sub-filters__filter">
                                    {subFilterOptions &&
                                        subFilterOptions.map((subFilterOption) => (
                                            <Filter
                                                key={`${key}-${subFilterOption.field}`}
                                                setFilter={(values: string[]) =>
                                                    dispatch(
                                                        setActiveSubFilters(
                                                            { [subFilterOption.field]: values },
                                                            activeChannelId,
                                                            PageView.assortment
                                                        )
                                                    )
                                                }
                                                activeFilter={
                                                    subFilterOptions &&
                                                    activeSubFilters &&
                                                    activeSubFilters[subFilterOption.field]
                                                }
                                                filterType={
                                                    hasKey(FILTER_TYPES, subFilterOption.field)
                                                        ? FILTER_TYPES[subFilterOption.field]
                                                        : 'multiselect'
                                                }
                                                filterData={subFilterOption.options as DropdownOption[]}
                                                label={subFilterOption.label}
                                                field={subFilterOption.field}
                                                autoCollapse={false}
                                            />
                                        ))}
                                </div>
                            </Popover>
                        )}
                    </HeaderColumn>
                );
            })}
        </>
    );
}

export default HeaderRow;
