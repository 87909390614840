import React from 'react';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import { LastYearMatchReason, LastYearReference } from 'buyplan-common';
import Modal from '../Modal/Modal';

interface Props {
    lastYearMatchReason: LastYearMatchReason | null;
    lastYearMatchReference: LastYearReference | null;
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    onClose: () => void;
    onLoadOptions: (value: string) => void;
    onChangeSelectValue: (
        value: ValueType<{
            label: string;
            value: string | null;
        }>
    ) => void;
}

function LYReferenceEditingModal({
    lastYearMatchReason,
    lastYearMatchReference,
    isOpen,
    onConfirm,
    onCancel,
    onClose,
    onLoadOptions,
    onChangeSelectValue,
}: Props) {
    let currentReferenceText = '';
    if (lastYearMatchReference && lastYearMatchReason) {
        const { materialCode, description, colorDescription, division, category, style, family, model } =
            lastYearMatchReference;

        currentReferenceText = {
            directMatch: `${materialCode}: ${description} - ${colorDescription}`,
            directMatchWithStoreLike4Like: `${materialCode}: ${description} - ${colorDescription}`,
            style: `${style}: ${description}`,
            styleWithStoreLike4Like: `${style}: ${description}`,
            like4likeFile: `${materialCode}: ${description} - ${colorDescription}`,
            like4likeFileWithStoreLike4Like: `${materialCode}: ${description} - ${colorDescription}`,
            like4likeManual: `${materialCode}: ${description} - ${colorDescription}`,
            like4likeManualWithStoreLike4Like: `${materialCode}: ${description} - ${colorDescription}`,
            categoryDivisionFamilyModel: `${category} - ${division} - ${family} - ${model}`,
            categoryDivisionFamilyModelWithStoreLike4Like: `${category} - ${division} - ${family} - ${model}`,
            categoryDivisionFamily: `${category} - ${division} - ${family}`,
            categoryDivisionFamilyWithStoreLike4Like: `${category} - ${division} - ${family}`,
            categoryDivisionModel: `${category} - ${division} - ${model}`,
            categoryDivisionModelWithStoreLike4Like: `${category} - ${division} - ${model}`,
            categoryDivision: `${category} - ${division}`,
            categoryDivisionWithStoreLike4Like: `${category} - ${division}`,
        }[lastYearMatchReason];
    }

    return (
        <Modal
            style={{ content: { overflow: 'visible', minWidth: 485 } }}
            isOpen={isOpen}
            title="Change LY Reference"
            subtitle="The LY Reference for the entire material, it will be reflected in every single store."
            text={`Current reference: ${currentReferenceText}`}
            closeAction={onClose}
            okAction={onConfirm}
            okButtonText="Save"
            cancelAction={onCancel}
            cancelButtonText="Cancel"
            autoCloseAfterAction={true}
        >
            <div className="LastYearData__modal-content">
                <span>New Reference: </span>
                <AsyncSelect
                    autoFocus
                    isClearable
                    backspaceRemovesValue
                    loadOptions={onLoadOptions}
                    defaultOptions={[{ label: '- No Reference -', value: null }]}
                    onChange={onChangeSelectValue}
                    inputProps={{ autoComplete: 'off' }}
                    styles={{
                        container: () => ({
                            flex: 1,
                            marginLeft: '10px',
                        }),
                        option: (provided) => ({
                            ...provided,
                            whiteSpace: 'pre-wrap',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            top: 'auto',
                            width: '77%',
                        }),
                    }}
                />
            </div>
        </Modal>
    );
}

export default LYReferenceEditingModal;
