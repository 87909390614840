import React from 'react';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import UploadIcon from '../Icons/UploadIcon';
import './FileDrop.scss';

interface Props {
    acceptedMimeTypes: string;
    title: string;
    onDrop(acceptedFiles: File[]): void;
    multiple?: boolean;
    historyUploadEnabled?: boolean; // Shows a different icon
}

function FileDrop({ acceptedMimeTypes, onDrop, title, historyUploadEnabled, multiple = true }: Props) {
    return (
        <div className="file-drop">
            <Dropzone accept={acceptedMimeTypes} multiple={multiple} onDrop={onDrop}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                    const rootProps = getRootProps({
                        className: cn('dropzone', { active: isDragActive, reject: isDragReject }),
                    });
                    return (
                        <div {...rootProps}>
                            <input {...getInputProps()} />
                            <span className="uploadimg">
                                <UploadIcon />{' '}
                                {!historyUploadEnabled && (
                                    <span>
                                        <FontAwesomeIcon icon={faAngleDoubleRight as IconProp} size="2x" />
                                        <FontAwesomeIcon icon={faTruckLoading as IconProp} size="5x" />
                                    </span>
                                )}
                            </span>
                            <span className="uploadimg-active">
                                <UploadIcon />
                            </span>
                            <span className="uploadimg-error">
                                <UploadIcon />
                            </span>
                            {title && <h3>{title}</h3>}
                        </div>
                    );
                }}
            </Dropzone>
        </div>
    );
}

export default FileDrop;
