import React from 'react';
import replaceDummyCode from './images/ReplaceDummyCode.png';
import highlightMaterialCode from './images/HighlightMaterialCode.png';

export default function () {
    return (
        <div>
            <h2>Dummy codes</h2>
            <p>
                When a material&apos;s code has <em>not been found</em> through MMX, the buy plan considers the material code
                a <strong>dummy code</strong>. Dummy codes need to be replaced with valid material codes before ordering.
            </p>
            <h3>Replacing Dummy Codes</h3>
            <p>
                In order to replace a Dummy code for a specific material, you can copy and paste the{' '}
                <em>correct material code</em> under the <strong>New code</strong> column and therefore hit the{' '}
                <strong>Find Material</strong> button.
                <img src={replaceDummyCode} alt="Replacing a Dummy Code" />
            </p>
            <p>
                If the code has been found in the system (MMX), then a confirmation message and a{' '}
                <strong>Verify button</strong> will appear. If verified, the replacement will occur and{' '}
                <em>the record won&apos;t be shown in the list anymore</em>.
            </p>
            <p>A dummy code replacement overwrites some properties of the material.</p>
            <p>
                Attributes which are updated due to Dummy code replacement.
                <ul>
                    <li>Material Code</li>
                    <li>Color Description</li>
                    <li>Description</li>
                    <li>Color</li>
                    <li>Silhouette</li>
                    <li>Gender</li>
                    <li>Style</li>
                    <li>Age</li>
                    <li>Product Code</li>
                </ul>
            </p>
            <p>
                <strong>Note:</strong> The changes to `Gender` column may impact the data in Assortment and Buyplan screen
                due to the Margin Impact and Sales Target combination checks for Gender value.
            </p>
            <h3>Bulk Verify</h3>
            <p>
                If you need to trigger a <strong>bulk check</strong> over the Dummy Codes, the{' '}
                <strong>Verify Material Codes</strong> button can trigger a validation between the Dummy Codes and a{' '}
                <em>lately updated system</em>.
            </p>
            <h3>Highlighting Material Codes</h3>
            <p>
                After a <strong>dummy code</strong> is replaced with a <strong>real code</strong>, it is displayed with a
                light blue color in the <em>Manage Assortment</em> and <em>View Buyplan</em> pages.
                <img src={highlightMaterialCode} alt="Highlight Material Code" />
            </p>
        </div>
    );
}
