import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChannelRole, channels } from 'buyplan-common';
import useUserProfile from '../../selectors/useUserProfile';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import useUserSettings from '../../selectors/useUserSettings';
import Navigation from './Navigation';
import AssortmentSubnavigation from './Subnavigation/AssortmentSubnavigation';
import BuyplanSubnavigation from './Subnavigation/BuyplanSubnavigation';
import ReportsSubnavigation from './Subnavigation/ReportsSubnavigation';
import SettingsSubnavigation from './Subnavigation/SettingsSubnavigation';

function BuyplanNavigation() {
    const profile = useUserProfile();
    const { activeChannelId } = useUserSettings();
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    if (!profile) {
        return null;
    }

    return (
        <Navigation>
            <NavLink exact to="/buyplan">
                Home
            </NavLink>
            <span>
                <NavLink to="/buyplan/manage-assortment">Assortment</NavLink>
                {!isReadOnlyUser && <AssortmentSubnavigation />}
            </span>
            <span>
                <NavLink to="/buyplan/view-buy-plan">Buy Plan</NavLink>
                {!isReadOnlyUser && <BuyplanSubnavigation />}
            </span>
            <span>
                {activeChannelId === channels.digital.id ? (
                    <NavLink to="/buyplan/top-styles-report">Reports</NavLink>
                ) : (
                    <NavLink to="/buyplan/option-counts">Reports</NavLink>
                )}
                <ReportsSubnavigation />
            </span>
            <span>
                <NavLink to="/buyplan/exchange-rates">Settings</NavLink>
                {!isReadOnlyUser && <SettingsSubnavigation />}
            </span>
            <NavLink to="/buyplan/documentation">Documentation</NavLink>
        </Navigation>
    );
}

export default BuyplanNavigation;
