import React, { ReactNode } from 'react';
import cn from 'classnames';
import './Toggle.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ToggleItem<T> {
    icon: ReactNode;
    value: T;
    title: string;
}

interface ToggleProps<T> {
    onChange: (value: T) => void;
    value: T;
    items: ToggleItem<T>[];
    disabled?: boolean;
}

function Toggle<T extends string>({ onChange, value, items, disabled = false }: ToggleProps<T>) {
    return (
        <div className="Toggle">
            {items.map(({ title, value: itemValue, icon }) => (
                <OverlayTrigger
                    key={itemValue}
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-${itemValue}`}>{title}</Tooltip>}
                >
                    <button
                        className={cn('Toggle__button', {
                            'Toggle__button--active': itemValue.includes(value),
                        })}
                        type="button"
                        onClick={() => {
                            if (value !== itemValue) {
                                onChange(itemValue);
                            }
                        }}
                        disabled={disabled}
                    >
                        {icon}
                    </button>
                </OverlayTrigger>
            ))}
        </div>
    );
}

export default Toggle;
