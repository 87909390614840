import React, { ComponentProps, MouseEventHandler, ReactNode } from 'react';
import cn from 'classnames';
import './Button.scss';

interface Props extends ComponentProps<'button'> {
    children: ReactNode;
    onClick?: MouseEventHandler;
    className?: string;
    size?: 's' | 'm' | 'l';
    disabled?: boolean;
}

function Button({ className, onClick = () => null, size = 'm', children, disabled = false, ...props }: Props) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={cn(className, 'Button', `Button--size-${size}`)}
            type="button"
            {...props}
        >
            {children}
            <span className="Button__inner" />
        </button>
    );
}

export default Button;
