import React from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { FamilyModelRowConfig, FamilyModelBreakdownReportTotals } from 'buyplan-common';
import { getColumnWidth } from '../../../helpers/tableTools';
import HeaderColumn from '../../HeaderColumn/HeaderColumn';

interface Props {
    headerType: 'scrollable' | 'fixed';
    columns: FamilyModelRowConfig[];
    totals?: FamilyModelBreakdownReportTotals;
}

const getHeaderColumns = (columns: FamilyModelRowConfig[], headerType: string) => {
    if (headerType === 'scrollable') {
        return [...columns.filter(({ type }) => type === 'scrollable')];
    }
    return columns.filter(({ type }) => type === 'fixed');
};

function FamilyModelSubHeaderRow({ headerType, columns, totals }: Props) {
    const columnsToDisplay = getHeaderColumns(columns, headerType);

    return (
        <div className="FamilyModelTable__sub-header-row">
            {columnsToDisplay.map(({ key, label, groupStartHeader, getTotalsValue, getValue }) => {
                const headerLabel = groupStartHeader === label ? '' : label;
                const value = totals && headerLabel ? (totals[key as keyof typeof totals] as number | null) : null;

                return (
                    <div
                        key={`${key}-${kebabCase(headerLabel)}`}
                        style={{ width: getColumnWidth(columnsToDisplay, key) }}
                        className="FamilyModelTable__sub-header-cell"
                    >
                        <HeaderColumn className={cn(`FamilyModelHeader__${key}`)} label={headerLabel} />

                        <div
                            className={cn('FamilyModelTable__sub-header-totals', {
                                'FamilyModelTable__sub-header-totals--negative': (value ?? 0) < 0,
                            })}
                        >
                            {getTotalsValue ? getTotalsValue(value, false) : getValue?.(value, false)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default FamilyModelSubHeaderRow;
