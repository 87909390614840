"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialAttributesConstants = exports.Brand = exports.FlowRange = exports.Segment = exports.Consumer = exports.Age = exports.Gender = exports.YesNo = void 0;
/** Different casing might be present, sometimes within the same field, due to the ingested data */
var YesNo;
(function (YesNo) {
    YesNo["Yes"] = "Yes";
    YesNo["YES"] = "YES";
    YesNo["No"] = "No";
    YesNo["NO"] = "NO";
})(YesNo || (exports.YesNo = YesNo = {}));
var Gender;
(function (Gender) {
    Gender["UNISEX"] = "UNISEX";
    Gender["WOMENS"] = "WOMENS";
    Gender["MENS"] = "MENS";
    Gender["GIRLS"] = "GIRLS";
    Gender["BOYS"] = "BOYS";
    Gender["KIDSUNISEX"] = "KIDS UNISEX";
})(Gender || (exports.Gender = Gender = {}));
var Age;
(function (Age) {
    Age["ADULT"] = "ADULT";
    Age["YOUTH"] = "YOUTH";
    Age["GRADESCHOOL"] = "GRADE SCHOOL";
    Age["PRESCHOOL"] = "PRE SCHOOL";
    Age["TODDLER"] = "TODDLER";
    Age["INFANT"] = "INFANT";
    Age["NEWBORN"] = "NEWBORN";
})(Age || (exports.Age = Age = {}));
var Consumer;
(function (Consumer) {
    Consumer["WOMENS"] = "WOMENS";
    Consumer["MENS"] = "MENS";
    Consumer["KIDS"] = "KIDS";
    Consumer["JORDAN"] = "JORDAN";
})(Consumer || (exports.Consumer = Consumer = {}));
var Segment;
(function (Segment) {
    Segment["LIFESTYLE"] = "LIFESTYLE";
    Segment["PERFORMANCE"] = "PERFORMANCE";
})(Segment || (exports.Segment = Segment = {}));
var FlowRange;
(function (FlowRange) {
    FlowRange["Flow1Flow1"] = "Flow1_Flow1";
    FlowRange["Flow1Flow2"] = "Flow1_Flow2";
    FlowRange["Flow1Flow3"] = "Flow1_Flow3";
    FlowRange["Flow2Flow2"] = "Flow2_Flow2";
    FlowRange["Flow2Flow3"] = "Flow2_Flow3";
    FlowRange["Flow3Flow3"] = "Flow3_Flow3";
})(FlowRange || (exports.FlowRange = FlowRange = {}));
var Brand;
(function (Brand) {
    Brand["NIKE"] = "NIKE";
    Brand["JORDAN"] = "JORDAN";
})(Brand || (exports.Brand = Brand = {}));
var MaterialAttributesConstants;
(function (MaterialAttributesConstants) {
    MaterialAttributesConstants["UNKNOWN"] = "UNKNOWN";
})(MaterialAttributesConstants || (exports.MaterialAttributesConstants = MaterialAttributesConstants = {}));
