"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewRelicEventName = exports.NewRelicEventStatus = void 0;
var NewRelicEventStatus;
(function (NewRelicEventStatus) {
    NewRelicEventStatus["start"] = "start";
    NewRelicEventStatus["end"] = "end";
    NewRelicEventStatus["error"] = "error";
})(NewRelicEventStatus || (exports.NewRelicEventStatus = NewRelicEventStatus = {}));
var NewRelicEventName;
(function (NewRelicEventName) {
    NewRelicEventName["optionCountFileUpload"] = "optionCountFileUpload";
    NewRelicEventName["merchandiseFileUpload"] = "merchandiseFileUpload";
    NewRelicEventName["minimumBuyFileUpload"] = "minimumBuyFileUpload";
    NewRelicEventName["hindsightFileUpload"] = "hindsightFileUpload";
    NewRelicEventName["allocationFileUpload"] = "allocationFileUpload";
    NewRelicEventName["seasonalHindsightFileUpload"] = "seasonalHindsightFileUpload";
    NewRelicEventName["salesTargetsFileUpload"] = "salesTargetsFileUpload";
    NewRelicEventName["assortmentInitialLoad"] = "assortmentInitialLoad";
    NewRelicEventName["applyAssortmentFilters"] = "applyAssortmentFilters";
    NewRelicEventName["buyplanInitialLoad"] = "buyplanInitialLoad";
    NewRelicEventName["applyBuyplanFilters"] = "applyBuyplanFilters";
    NewRelicEventName["loadTopTenReport"] = "loadTopTenReport";
    NewRelicEventName["loadFamilyModelReport"] = "loadFamilyModelReport";
})(NewRelicEventName || (exports.NewRelicEventName = NewRelicEventName = {}));
