import React from 'react';
import { orderBy, xor } from 'lodash';
import { useDispatch } from 'react-redux';
import { ColumnConfig } from 'buyplan-common';
import CheckBox from '../CheckBox/CheckBox';
import { setVisibleColumns, toggleVisibleColumn } from '../../actions/user';
import { VisibleColumnSetting } from '../../reducers/user';
import './SettingsDisplayColumns.scss';
import useUserSettings from '../../selectors/useUserSettings';

interface Props {
    columns: ColumnConfig[];
    columnSetting: VisibleColumnSetting;
}

function SettingsDisplayColumns({ columns, columnSetting }: Props) {
    const dispatch = useDispatch();
    const settings = useUserSettings();
    const filterableColumns = columns.filter(({ isFixed }) => !isFixed);
    const allColumnsSelected = columns?.every(({ key }) => settings[columnSetting].includes(key));

    const handleToggleVisibleColumn = (key: string) => dispatch(toggleVisibleColumn(columnSetting, key));
    const handleToggleAllVisibleColumns = (enableAll: boolean) => {
        const newColumns = (enableAll ? columns : xor(columns, filterableColumns)).map(({ key }) => key);
        dispatch(setVisibleColumns(columnSetting, newColumns));
    };

    return (
        <div className="SettingsDisplayColumns">
            <CheckBox
                id="settings-all-columns"
                label={allColumnsSelected ? `Deselect all columns` : 'Select all columns'}
                onChange={() => handleToggleAllVisibleColumns(!allColumnsSelected)}
                isChecked={allColumnsSelected}
            />
            <hr />
            <ul className="SettingsDisplayColumns__list">
                {orderBy(filterableColumns, 'label').map(({ key, label }) => (
                    <li key={key}>
                        <CheckBox
                            label={label}
                            onChange={() => handleToggleVisibleColumn(key)}
                            isChecked={settings[columnSetting].includes(key)}
                            id={`settings-${key}`}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SettingsDisplayColumns;
