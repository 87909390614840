import { isEqual } from 'lodash';
import {
    SubFilters,
    MainFiltersExtended,
    UserProfile,
    assortmentViewColumns,
    familyModelRowsConfig,
    viewBuyplanColumns,
} from 'buyplan-common';
import {
    SET_ACCESS_TOKEN,
    SET_USER_PROFILE,
    SET_CHANNEL,
    SET_VISIBLE_COLUMNS,
    TOGGLE_VISIBLE_COLUMN,
    GET_STORES_SUCCESS,
    SET_ACTIVE_MAIN_FILTERS,
    SET_ACTIVE_SUB_FILTERS,
    CLEAR_FAVORITE_STORES,
    TOGGLE_FAVORITE_STORE,
    CLEAR_ACTIVE_SUB_FILTERS,
    GET_ASSORTMENT_META_SUCCESS,
    GET_BUYPLAN_META_SUCCESS,
    SET_SEASON,
    REPLACE_USER_IN_ACTION,
    REPLACE_PRINCIPAL_USER,
    GET_SEASON_DATA_SUCCESS,
    CLEAR_EVENT,
    SET_EVENT,
} from '../actions/actionTypes';
import { removeInvalidFilterValues, removeUndefinedFilter } from '../services/filterOptions';
import { UserActions } from '../actions/user';
import { GetStoresSuccessAction } from '../actions/store';
import { getSortedStores } from '../helpers/stores';
import { GetBuyPlanMetaSuccessAction } from '../actions/buyplan';
import { GetAssortmentMetaSuccessAction } from '../actions/assortment';
import { ReplacePrincipalUserAction, ReplaceUserInActionAction } from '../actions/impersonation';
import { GetSeasonDataSuccessAction } from '../actions/season';

export interface ActiveFiltersType {
    mainFilters: MainFiltersExtended;
    subFilters: SubFilters;
}

export interface ChannelSettings {
    channelId: number;
    storeNumberOrder: string[];
    storeNumberOrderBy?: string;
    storeNumberOrderDirection?: 'asc' | 'desc';
    favoriteStores: string[];
}

export interface UserSettings {
    visibleAssortmentColumnKeys: string[];
    visibleBuyPlanColumnKeys: string[];
    visibleFamilyReportColumnKeys: string[];
    channels: ChannelSettings[];
    activeFilters: { [viewPerChannel: string]: ActiveFiltersType };
    activeChannelId: number;
    activeSeasonId: number;
}

export interface InProgressEvents {
    [key: string]: string;
}

// TODO column settings could be combined ATEAM-651
export type VisibleColumnSetting = keyof Pick<
    UserSettings,
    'visibleAssortmentColumnKeys' | 'visibleBuyPlanColumnKeys' | 'visibleFamilyReportColumnKeys'
>;

export interface UserState {
    profile?: UserProfile;
    accessToken?: string;
    settings: UserSettings;
    events: InProgressEvents;
}

export const initialState: UserState = {
    settings: {
        channels: [],
        visibleAssortmentColumnKeys: assortmentViewColumns.map(({ key }) => key),
        visibleBuyPlanColumnKeys: viewBuyplanColumns.map(({ key }) => key),
        visibleFamilyReportColumnKeys: familyModelRowsConfig.map(({ key }) => key),
        activeFilters: {},
        activeChannelId: undefined as number | undefined,
        activeSeasonId: undefined as number | undefined,
    } as UserSettings,
    events: {},
};

type Actions =
    | UserActions
    | GetStoresSuccessAction
    | GetBuyPlanMetaSuccessAction
    | GetAssortmentMetaSuccessAction
    | ReplaceUserInActionAction
    | ReplacePrincipalUserAction
    | GetSeasonDataSuccessAction;

const updateChannelSettings = (
    state: UserState,
    channelId: number,
    updateFn: (channelSettings: ChannelSettings) => ChannelSettings
) => {
    const channelSettings = state.settings.channels;
    const index = channelSettings.findIndex((channel) => channel.channelId === channelId);

    if (index > -1) {
        const updatedSettings = updateFn(channelSettings[index]);

        // To prevent unnecessary re-renders return original state if nothing changed
        if (isEqual(updatedSettings, channelSettings[index])) {
            return state;
        }

        return {
            ...state,
            settings: {
                ...state.settings,
                channels: [...channelSettings.slice(0, index), updatedSettings, ...channelSettings.slice(index + 1)],
            },
        };
    }

    // Create new settings for an unknown channel
    const newChannelSettings = updateFn({
        channelId,
        storeNumberOrder: [],
        favoriteStores: [],
    });
    return {
        ...state,
        settings: {
            ...state.settings,
            channels: [...channelSettings, newChannelSettings],
        },
    };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: UserState = initialState, action: Actions): UserState => {
    switch (action.type) {
        case SET_USER_PROFILE:
            return {
                ...state,
                profile: action.profile,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken,
            };
        case SET_CHANNEL:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activeChannelId: action.channelId,
                },
            };
        case SET_SEASON:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activeSeasonId: action.seasonId,
                },
            };
        case GET_SEASON_DATA_SUCCESS:
            if (action.seasonData && action.seasonData.newActiveSeasonId && action.seasonData.newActiveChannelId) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        activeSeasonId: action.seasonData.newActiveSeasonId,
                        activeChannelId: action.seasonData.newActiveChannelId,
                    },
                };
            }
            if (action.seasonData && action.seasonData.newActiveChannelId) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        activeChannelId: action.seasonData.newActiveChannelId,
                    },
                };
            }
            if (action.seasonData && action.seasonData.newActiveSeasonId) {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        activeSeasonId: action.seasonData.newActiveSeasonId,
                    },
                };
            }
            return state;
        case SET_VISIBLE_COLUMNS:
            return { ...state, settings: { ...state.settings, [action.columnSetting]: action.columns } };
        case TOGGLE_VISIBLE_COLUMN: {
            const enableColumn = state.settings[action.columnSetting].find((key) => key === action.columnKey) !== undefined;
            const updatedColumns = enableColumn
                ? state.settings[action.columnSetting].filter((key) => key !== action.columnKey)
                : [...state.settings[action.columnSetting], action.columnKey];
            return { ...state, settings: { ...state.settings, [action.columnSetting]: updatedColumns } };
        }
        case GET_STORES_SUCCESS:
            return updateChannelSettings(state, action.channelId, (channelSettings: ChannelSettings) => {
                const newStoreNumberOrder = getSortedStores(action.stores, channelSettings.storeNumberOrder);
                return {
                    ...channelSettings,
                    storeNumberOrder: newStoreNumberOrder.map(({ number }) => number),
                };
            });
        case SET_ACTIVE_MAIN_FILTERS: {
            const selector = `${action.channelId}|${action.view}`;
            if (isEqual(state.settings.activeFilters[selector]?.mainFilters, action.filters)) {
                return state;
            }
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activeFilters: {
                        ...state.settings.activeFilters,
                        [selector]: {
                            subFilters: state.settings.activeFilters[selector]?.subFilters ?? {},
                            mainFilters: removeUndefinedFilter({
                                ...state.settings.activeFilters[selector]?.mainFilters,
                                ...action.filters,
                            }),
                        },
                    },
                },
            };
        }
        case SET_ACTIVE_SUB_FILTERS: {
            const selector = `${action.channelId}|${action.view}`;
            if (isEqual(state.settings.activeFilters[selector]?.subFilters, action.filters)) {
                return state;
            }
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activeFilters: {
                        ...state.settings.activeFilters,
                        [selector]: {
                            mainFilters: state.settings.activeFilters[selector]?.mainFilters ?? {},
                            subFilters: removeUndefinedFilter({
                                ...state.settings.activeFilters[selector]?.subFilters,
                                ...action.filters,
                            }),
                        },
                    },
                },
            };
        }
        // Remove active subfilter values that are not part of the metadata to prevent them from reactivating
        // automatically when the values re-appear in later metadata requests. This is desired global behaviour for all
        // screens.
        // However we don't remove unknown filter _fields_; not all screens share the same filter fields and we don't
        // want those unknown fields to automatically disable if you switch between pages.

        case GET_ASSORTMENT_META_SUCCESS:
        case GET_BUYPLAN_META_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activeFilters: {
                        ...state.settings.activeFilters,
                        [`${action.channelId}|${action.view}`]: {
                            // We always remove filter values that are not matching the returned metaData
                            mainFilters: removeInvalidFilterValues(
                                state.settings.activeFilters[`${action.channelId}|${action.view}`]?.mainFilters,
                                action.metaData.mainFilters
                            ),
                            subFilters: removeInvalidFilterValues(
                                state.settings.activeFilters[`${action.channelId}|${action.view}`]?.subFilters,
                                action.metaData.subFilters
                            ),
                        },
                    },
                },
            };
        case CLEAR_ACTIVE_SUB_FILTERS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    activeFilters: {
                        ...state.settings.activeFilters,
                        [`${action.channelId}|${action.view}`]: {
                            ...state.settings.activeFilters[`${action.channelId}|${action.view}`],
                            subFilters: {},
                        },
                    },
                },
            };
        case TOGGLE_FAVORITE_STORE:
            return updateChannelSettings(state, action.channelId, (channelSettings: ChannelSettings) => {
                const isFavorite = channelSettings.favoriteStores.includes(action.storeId);
                return {
                    ...channelSettings,
                    favoriteStores: isFavorite
                        ? channelSettings.favoriteStores.filter((storeId) => storeId !== action.storeId)
                        : [...channelSettings.favoriteStores, action.storeId],
                };
            });
        case CLEAR_FAVORITE_STORES:
            return updateChannelSettings(state, action.channelId, (channelSettings: ChannelSettings) => ({
                ...channelSettings,
                favoriteStores: [],
            }));
        // During impersonation, we need to swap the user profile with the profile of the user we are impersonating
        // The original user is stored in impersonation reducer until it is time to swap back
        case REPLACE_USER_IN_ACTION:
            return {
                ...state,
                profile: action.profile,
                settings: {
                    ...initialState.settings,
                    activeChannelId: action.profile.channels[0].id,
                    activeSeasonId: state.settings.activeSeasonId,
                },
            };
        case REPLACE_PRINCIPAL_USER:
            return {
                ...state,
                profile: action.profile,
                settings: action.settings,
            };
        case SET_EVENT: {
            const { key, uuid } = action;
            return {
                ...state,
                events: {
                    ...state.events,
                    [key]: uuid,
                },
            };
        }
        case CLEAR_EVENT: {
            const { key } = action;
            const updatedEvents = { ...state.events };
            delete updatedEvents[key];
            return {
                ...state,
                events: updatedEvents,
            };
        }
        default:
            return state;
    }
};
