import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
    channels,
    FamilyModelBreakdownReport,
    formatUSDOrDash,
    formatNumberOrDash,
    formatPercentageOrDash,
    ModelDashboardListItem,
} from 'buyplan-common';
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as services from '../../../services/buyPlanReportsService';
import IconButton from '../../IconButton/IconButton';
import Loader from '../../Loader/Loader';
import { useSelector } from '../../../store/reducers';
import useActiveFilters from '../../../selectors/useActiveFilters';
import { PageView } from '../../../constants/appConfig';
import ModelDashboardMaterialTable from './ModelDashboardMaterialTable';

import './ModelDashboard.scss';

interface Props {
    modelData: FamilyModelBreakdownReport;
}

function ModelDashboard({ modelData }: Props) {
    const {
        model,
        category,
        division,
        gender,
        age,
        salesUnitsSum,
        lastYearSalesUnitsSum,
        lastLastYearSalesUnitsSum,
        salesRetailValueUSDSum,
        lastYearSalesNetRevenueSum,
        lastLastYearSalesNetRevenueSum,
        sellThrough,
        rowCount,
        productivity,
        lastYearSellThrough,
        lastLastYearSellThrough,
        openToBuyUnitsSum,
        aaOpenToBuyUnitsSum,
        afOpenToBuyUnitsSum,
        presentationStocksSum,
        buyWholesaleValueUSDSum,
        salesRetailValueUSDPercentageOfTotal,
        lastYearSalesRetailValueUSDPercentageOfTotal,
        lastLastYearSalesRetailValueUSDPercentageOfTotal,
        lastYearInvestUnitsSum,
        lastLastYearInvestUnitsSum,
        totalAllocationUnits,
        seasonalOptionCounts,
        lastYearSeasonalOptionCounts,
        lastLastYearSeasonalOptionCounts,
        tradedOptionCounts,
        lastYearTradedOptionCounts,
        lastLastYearTradedOptionCounts,
        seasonalVsTradedOptionCountsGrowthPercentage,
        lastYearSeasonalVsTradedOptionCountsGrowthPercentage,
        lastLastYearSeasonalVsTradedOptionCountsGrowthPercentage,
    } = modelData;

    const [collapsed, setCollapsed] = useState(false);
    const [modelDashboardMaterials, setModelDashboardMaterials] = useState<ModelDashboardListItem[]>([]);
    const [loading, setLoading] = useState(false);

    const { activeChannelId: channelId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.familyModelReport);
    const getModelDashboardMaterials = async () => {
        setLoading(true);
        const result = await services.getModelDashboardMaterials(mainFilters, model);
        setModelDashboardMaterials(result.data);
        setLoading(false);
    };

    useEffect(() => {
        if (collapsed) {
            getModelDashboardMaterials();
        }
        // We don't want the exhaustive depts here, updating when getModelDashboardMaterials updates would loop infinitely
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed, mainFilters, channelId]);

    return (
        <div className="ModelDashboard__model">
            <div className="ModelDashboard__header">
                <h4>
                    {model} | {category} | {division} | {gender} | {age}
                </h4>
            </div>
            <table className="table ModelDashboard__table">
                <thead>
                    <tr>
                        <th style={{ width: '22px' }} />
                        <th scope="col" className="align-center">
                            Sales Units
                        </th>
                        <th scope="col" className="align-center">
                            Sales Retail Value USD
                        </th>
                        <th scope="col" className="align-center">
                            % Total Revenue
                        </th>
                        <th scope="col" className="align-center">
                            Productivity
                        </th>
                        {channelId === channels.digital.id && (
                            <>
                                <th scope="col" className="align-center">
                                    Seasonal
                                </th>
                                <th scope="col" className="align-center">
                                    Traded
                                </th>
                                <th scope="col" className="align-center">
                                    % Seasonal vs Traded
                                </th>
                            </>
                        )}
                        <th scope="col" className="align-center">
                            Sell Through %
                        </th>
                        <th scope="col" className="align-center">
                            OTB Units
                        </th>
                        <th scope="col" className="align-center">
                            Buy Wholesale Value USD
                        </th>
                        <th scope="col" className="align-center">
                            Presentation Stocks
                        </th>
                        <th scope="col" className="align-center">
                            Allocation Units
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="ModelDashboard__row">
                        <td className="align-center">
                            {!collapsed && (
                                <IconButton
                                    className="ModelDashboard__row--collapse-button"
                                    icon={faChevronCircleRight as IconProp}
                                    onClick={() => setCollapsed(true)}
                                    title="Show Materials"
                                />
                            )}
                            {collapsed && (
                                <IconButton
                                    className="ModelDashboard__row--expand-button"
                                    icon={faChevronCircleDown as IconProp}
                                    onClick={() => setCollapsed(false)}
                                    title="Hide Materials"
                                />
                            )}
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">{formatNumberOrDash(salesUnitsSum)}</div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>LY</th>
                                        <th>LLY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatNumberOrDash(lastYearSalesUnitsSum)}</td>
                                        <td>{formatNumberOrDash(lastLastYearSalesUnitsSum)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">{formatUSDOrDash(salesRetailValueUSDSum)}</div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>LY</th>
                                        <th>LLY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatUSDOrDash(lastYearSalesNetRevenueSum || null)}</td>
                                        <td>{formatUSDOrDash(lastLastYearSalesNetRevenueSum || null)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">
                                {formatPercentageOrDash(salesRetailValueUSDPercentageOfTotal)}
                            </div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>LY</th>
                                        <th>LLY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {formatPercentageOrDash(lastYearSalesRetailValueUSDPercentageOfTotal || null)}
                                        </td>
                                        <td>
                                            {formatPercentageOrDash(
                                                lastLastYearSalesRetailValueUSDPercentageOfTotal || null
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">{formatUSDOrDash(productivity)}</div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>OPTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowCount === null ? '-' : formatNumberOrDash(rowCount)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        {channelId === channels.digital.id && (
                            <>
                                <td className="align-center">
                                    <div className="ModelDashboard__row--main-number">
                                        {formatNumberOrDash(seasonalOptionCounts)}
                                    </div>
                                    <table className="ModelDashboard__row--sub-table">
                                        <thead>
                                            <tr>
                                                <th>LY</th>
                                                <th>LLY</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{formatNumberOrDash(lastYearSeasonalOptionCounts)}</td>
                                                <td>{formatNumberOrDash(lastLastYearSeasonalOptionCounts)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className="align-center">
                                    <div className="ModelDashboard__row--main-number">
                                        {formatNumberOrDash(tradedOptionCounts)}
                                    </div>
                                    <table className="ModelDashboard__row--sub-table">
                                        <thead>
                                            <tr>
                                                <th>LY</th>
                                                <th>LLY</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{formatNumberOrDash(lastYearTradedOptionCounts)}</td>
                                                <td>{formatNumberOrDash(lastLastYearTradedOptionCounts)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className="align-center">
                                    <div
                                        className={cn('ModelDashboard__row--main-number', {
                                            'ModelDashboard__row--negative':
                                                (seasonalVsTradedOptionCountsGrowthPercentage ?? 0) < 0,
                                        })}
                                    >
                                        {formatPercentageOrDash(seasonalVsTradedOptionCountsGrowthPercentage)}
                                    </div>
                                    <table className="ModelDashboard__row--sub-table">
                                        <thead>
                                            <tr>
                                                <th>LY % Δ</th>
                                                <th>LLY % Δ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    className={cn({
                                                        'ModelDashboard__row--negative':
                                                            (lastYearSeasonalVsTradedOptionCountsGrowthPercentage ?? 0) < 0,
                                                    })}
                                                >
                                                    {formatPercentageOrDash(
                                                        lastYearSeasonalVsTradedOptionCountsGrowthPercentage
                                                    )}
                                                </td>
                                                <td
                                                    className={cn({
                                                        'ModelDashboard__row--negative':
                                                            (lastLastYearSeasonalVsTradedOptionCountsGrowthPercentage ?? 0) <
                                                            0,
                                                    })}
                                                >
                                                    {formatPercentageOrDash(
                                                        lastLastYearSeasonalVsTradedOptionCountsGrowthPercentage
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </>
                        )}
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">{formatPercentageOrDash(sellThrough)}</div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>LY</th>
                                        <th>LLY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatPercentageOrDash(lastYearSellThrough)}</td>
                                        <td>{formatPercentageOrDash(lastLastYearSellThrough)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">{formatNumberOrDash(openToBuyUnitsSum)}</div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>AA</th>
                                        <th>AF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatNumberOrDash(aaOpenToBuyUnitsSum)}</td>
                                        <td>{formatNumberOrDash(afOpenToBuyUnitsSum)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">
                                {formatUSDOrDash(buyWholesaleValueUSDSum)}
                            </div>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">
                                {formatNumberOrDash(presentationStocksSum)}
                            </div>
                            <table className="ModelDashboard__row--sub-table">
                                <thead>
                                    <tr>
                                        <th>LY</th>
                                        <th>LLY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatNumberOrDash(lastYearInvestUnitsSum)}</td>
                                        <td>{formatNumberOrDash(lastLastYearInvestUnitsSum)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="align-center">
                            <div className="ModelDashboard__row--main-number">
                                {formatNumberOrDash(totalAllocationUnits)}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {collapsed && loading && (
                <div>
                    <Loader width={32} />
                </div>
            )}

            {collapsed && !loading && modelDashboardMaterials.length > 0 && (
                <ModelDashboardMaterialTable modelListData={modelDashboardMaterials} />
            )}
            {collapsed && !loading && modelDashboardMaterials.length === 0 && (
                <div>No materials found for matching criteria </div>
            )}
        </div>
    );
}

export default ModelDashboard;
