import React, { memo } from 'react';
import { MancalaImportFile } from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loader from '../Loader/Loader';
import FileStatusTable from './FileStatusTable';
import './FileStatus.scss';

interface Props {
    loading: boolean;
    items?: MancalaImportFile[];
    errorMessage?: string;
}

function FileStatus({ items, loading, errorMessage }: Props) {
    return (
        <div className="file-status">
            <h1>
                UPLOADED FILES
                {loading && <Loader width={24} className="file-status__loader" />}
            </h1>

            {items && items.length !== 0 && (
                <div className="file-status__import">
                    <FileStatusTable items={items} />
                </div>
            )}
            {items && items.length === 0 && (
                <div className="file-status__import--missing">
                    <FontAwesomeIcon icon={faInbox as IconProp} size="8x" />
                    <h3>No imported files found</h3>
                </div>
            )}
            {errorMessage && (
                <div className="file-status__import--error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} size="8x" />
                    <h3>{errorMessage}</h3>
                </div>
            )}
        </div>
    );
}

export default memo(FileStatus);
