import React from 'react';

export default function () {
    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">PARTNER</th>
                    <th scope="col">CATEGORY</th>
                    <th scope="col">MODEL</th>
                    <th scope="col">GENDER</th>
                    <th scope="col">AGE</th>
                    <th scope="col">STORE NUMBER</th>
                    <th scope="col">MATERIAL CODE</th>
                    <th scope="col">AA UNITS</th>
                    <th scope="col">AF/QS UNITS</th>
                    <th scope="col" className="AllocationsTable__notes" />
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AF1</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>300</td>
                    <td>200</td>
                    <td />
                </tr>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AJ1</td>
                    <td>UNISEX</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>200</td>
                    <td />
                </tr>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AIR MAX</td>
                    <td />
                    <td>TODDLER</td>
                    <td />
                    <td />
                    <td>300</td>
                    <td />
                    <td />
                </tr>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AF1</td>
                    <td />
                    <td />
                    <td>9156</td>
                    <td />
                    <td />
                    <td>20</td>
                    <td>
                        <code>Error: allocation higher in the hierarchy exists</code>
                    </td>
                </tr>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AJ11</td>
                    <td />
                    <td />
                    <td>9156</td>
                    <td />
                    <td />
                    <td>40</td>
                    <td />
                </tr>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AJ11</td>
                    <td />
                    <td />
                    <td />
                    <td>WNSWX-XX1</td>
                    <td />
                    <td>20</td>
                    <td>
                        <code>Error: allocation higher in the hierarchy exists</code>
                    </td>
                </tr>
                <tr>
                    <td>MYSPORTS</td>
                    <td>FOOTWEAR</td>
                    <td>AJ11</td>
                    <td />
                    <td />
                    <td />
                    <td>WNSWX-XX1</td>
                    <td>12</td>
                    <td>20</td>
                    <td>
                        <code>Error: a material can only have allocations on AA or AF/QS</code>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
