import React, { ReactNode } from 'react';
import ReactModal, { Styles } from 'react-modal';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from '../Button/Button';
import './Modal.scss';

const modalStyles: Styles = {
    overlay: {
        zIndex: 9,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const mergeStyles = (style: Styles) => {
    let outerStyle = style;
    if (style && !style.content) {
        outerStyle = { ...style, content: {} };
    }
    const { content, ...restStyles } = modalStyles;
    const { content: outerContent, ...outerRestStyles } = outerStyle;

    return {
        content: { ...content, ...outerContent },
        ...restStyles,
        ...outerRestStyles,
    };
};

export interface ModalProps {
    autoCloseAfterAction?: boolean;
    cancelAction?(): void;
    cancelButtonText?: string;
    children?: ReactNode;
    className?: string;
    closeAction(): void;
    innerComponentClassName?: string;
    isOpen: boolean;
    okAction?(): void;
    okButtonText?: string;
    okButtonType?: string;
    style?: Styles;
    subtitle?: string;
    text?: string;
    title?: string;
    okButtonDisabled?: boolean;
}

function Modal({
    closeAction,
    okAction,
    okButtonText = 'OK',
    okButtonType,
    cancelAction,
    cancelButtonText = 'CANCEL',
    title,
    subtitle = '',
    text,
    children,
    className,
    innerComponentClassName,
    isOpen,
    autoCloseAfterAction,
    style,
    okButtonDisabled,
}: ModalProps) {
    return (
        <ReactModal
            className={className}
            isOpen={isOpen}
            style={mergeStyles(style ?? { content: {} })}
            onRequestClose={closeAction}
            shouldReturnFocusAfterClose={false}
            ariaHideApp={false}
        >
            <div className={cn(innerComponentClassName)}>
                <div className="modal-header">
                    <h1 className="modal-title">{title}</h1>
                    {subtitle && <span className="modal-subtitle">{subtitle}</span>}
                    <span className="modal-close" role="button" tabIndex={0} title="close" onClick={closeAction}>
                        <FontAwesomeIcon icon={faWindowClose as IconProp} className="close-icon" size="lg" />
                    </span>
                </div>
                <div className="modal-body">
                    <span className="modal-text">{text}</span>
                    {children && <div className="modal-children">{children}</div>}
                    {(okAction || cancelAction) && (
                        <div className="modal-button-container">
                            {okAction && (
                                <Button
                                    className={cn('Button-fullWidth okButton', { danger: okButtonType === 'danger' })}
                                    onClick={() => {
                                        okAction();
                                        if (autoCloseAfterAction && closeAction) {
                                            closeAction();
                                        }
                                    }}
                                    disabled={okButtonDisabled}
                                >
                                    {okButtonText}
                                </Button>
                            )}

                            {cancelAction && (
                                <Button
                                    className="Button-fullWidth cancelButton Button__inverted"
                                    onClick={() => {
                                        cancelAction();
                                        if (autoCloseAfterAction && closeAction) {
                                            closeAction();
                                        }
                                    }}
                                >
                                    {cancelButtonText}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </ReactModal>
    );
}

export default Modal;
