import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { DBSnapshotTemplates, FxRatesTemplates, ProcessLoadStatus } from 'buyplan-common';
import { useSelector } from '../../store/reducers';
import {
    getLatestSnapshot,
    resetFxRatesProcessStatus,
    showNotification,
    updateFxRatesProcessStatus,
} from '../../actions/notifications';

// Create a new WebSocket connection with the url and token. The token is used to authenticate the user.
// The share option is set to false to prevent sharing the connection with other components.
function WebSocketConnector() {
    const dispatch = useDispatch();
    // Create a new WebSocket connection with the url and token. The token is used to authenticate the user.
    // The share option is set to false to prevent sharing the connection with other components.
    const accessToken = useSelector((state) => state.user.accessToken);
    const { lastJsonMessage } = useWebSocket(`${process.env.REACT_APP_WS_ENDPOINT}?token=${accessToken}`, {
        share: false,
        shouldReconnect: () => true,
    });
    useEffect(() => {
        if (lastJsonMessage) {
            switch (lastJsonMessage.eventType) {
                case FxRatesTemplates.completed:
                    dispatch(updateFxRatesProcessStatus(ProcessLoadStatus.complete));
                    break;
                case FxRatesTemplates.failed:
                    dispatch(updateFxRatesProcessStatus(ProcessLoadStatus.error));
                    break;
                case DBSnapshotTemplates.rollbackFailed:
                    dispatch(updateFxRatesProcessStatus(ProcessLoadStatus.error));
                    break;
                case DBSnapshotTemplates.rollbackCompleted:
                    dispatch(resetFxRatesProcessStatus());
                    break;
                case DBSnapshotTemplates.completed:
                    dispatch(getLatestSnapshot());
                    break;
                default:
                    break;
            }
            dispatch(showNotification(lastJsonMessage.message));
        }
    }, [dispatch, lastJsonMessage]);

    return null;
}

export default WebSocketConnector;
