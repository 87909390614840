import React from 'react';
import Dot, { DotType } from '../Dot/Dot';
import '../Dot/Dot.scss';

interface Props {
    materialCode: string;
    shouldShow: boolean;
}

const getTooltipText = () => (
    <div>
        Purchasing only your <strong>estimated sales</strong> due to your BOP being greater than or equal to your
        presentation stocks - sales units.
    </div>
);

function StoreOpenToBuyUnitsDot({ materialCode, shouldShow }: Props) {
    if (shouldShow) {
        return (
            <Dot
                data-e2e={`open-to-buy-info-${materialCode}`}
                type={DotType.default}
                tooltip={getTooltipText()}
                placement="right"
            />
        );
    }
    return <div className="dot" style={{ background: 'none' }} />;
}

export default StoreOpenToBuyUnitsDot;
