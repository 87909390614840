import React, { PureComponent } from 'react';
import cn from 'classnames';
import { isEqual, sum, intersection } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { List, CellMeasurer, CellMeasurerCache, ListRowProps } from 'react-virtualized';
import {
    PagingMetadata,
    FilteringMetaData,
    FilterOptions,
    AssortmentColumnConfig,
    assortmentViewColumns,
    AssortmentData,
    AssortmentRecord,
    AssortmentClusterOptionCounts,
    Material,
} from 'buyplan-common';
import { getAppliedFiltersCount, getMissingMainFilterKeys } from '../../helpers/utils';
import { combineWords } from '../../helpers/language';
import { getColumnRangeWidth, getColumnWidth } from '../../helpers/tableTools';
import { ActiveFiltersType, UserState } from '../../reducers/user';
import { PARTNER_PORTAL_PARTNERS } from '../../constants/partnersConfig';
import Loader from '../Loader/Loader';
import InfiniteScrollList from '../InfiniteScrollList/InfiniteScrollList';
import './ViewAssortmentList.scss';
import ClearSubFiltersButton from '../ViewBuyplan/ClearSubFiltersButton';
import { clearActiveSubFilters as clearActiveSubFiltersAction } from '../../actions/user';
import { updateAssortmentMaterial as updateAssortmentMaterialAction } from '../../actions/assortment';
import BulkUpdateModal from '../BulkUpdateModal/BulkUpdateModal';
import { PageView } from '../../constants/appConfig';
import HeaderRow from './HeaderRow';
import ViewAssortmentRow from './ViewAssortmentRow';
import AssortmentSettings from './AssortmentSettings';
import AssortmentClusterHeader from './AssortmentClusterHeader';

const ROW_HEIGHT = 48;

interface Props {
    activeFilters: ActiveFiltersType;
    data: AssortmentData;
    getAssortment(page: number, orderColumn?: string, orderDirection?: 'ASC' | 'DESC'): Promise<void>;
    loading: boolean;
    visibleAssortmentColumnKeys: string[];
    activeChannelId: number;
    // We enhance the type with the (syntetic) field totalFetched to patch its absence from the backend data
    pagination?: PagingMetadata & { totalFetched: number };
    orderBy: {
        columnKey: string;
        direction: 'ASC' | 'DESC';
    };
    subFilters: FilterOptions[] | undefined;
    updateAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    addToAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    updateAssortmentMaterial(materialId: string, material: Material, materialAttributes: Partial<Material>): Promise<void>;
    updateMetaData(): void;
    updateAssortmentCluster({
        channelId,
        isActive,
        materialCode,
        clusterId,
        clusterAssortmentStores,
    }: {
        channelId: number;
        isActive: boolean;
        materialCode: string;
        clusterId: string;
        clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
    }): Promise<void>;
    clearActiveSubFilters(channelId: number, view: PageView): void;
}

type ModalState = {
    isOpen: boolean;
    type: string;
};

interface State {
    isSettingsOpen: boolean;
    modal: ModalState;
    columns: AssortmentColumnConfig[];
    clustersOpenState: { [key: string]: boolean };
}

export class ViewAssortmentList extends PureComponent<Props, State> {
    state = {
        modal: {} as ModalState,
        isSettingsOpen: false,
        columns: [] as AssortmentColumnConfig[],
        clustersOpenState: {} as { [key: string]: boolean },
    };

    scrollableListRef = React.createRef<List>();
    fixedListRef = React.createRef<List>();

    // Each List needs a separate cache
    columnsCache = {
        fixed: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
        scrollable: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
    };

    componentDidMount() {
        this.generateColumnConfig();
        this.generateClustersConfig();
    }

    componentDidUpdate(prevProps: Props) {
        const { visibleAssortmentColumnKeys, activeFilters, orderBy, data } = this.props;
        const hasFilterChanged = !isEqual(prevProps.activeFilters, activeFilters);
        const hasOrderChanged =
            !isEqual(orderBy.direction, prevProps.orderBy.direction) ||
            !isEqual(orderBy.columnKey, prevProps.orderBy.columnKey);
        if (hasFilterChanged || hasOrderChanged) {
            this.columnsCache.fixed.clearAll();
            this.columnsCache.scrollable.clearAll();
            this.generateColumnConfig();
            this.generateClustersConfig(false);
        }
        if (!isEqual(prevProps.visibleAssortmentColumnKeys, visibleAssortmentColumnKeys)) {
            this.generateColumnConfig();
        }
        if (!isEqual(prevProps.data.clusterOptionCounts, data.clusterOptionCounts)) {
            this.generateClustersConfig();
        }
    }

    toggleSettings = () => {
        const { isSettingsOpen } = this.state;
        this.setState({ isSettingsOpen: !isSettingsOpen });
    };

    toggleModal = (modalType?: string) => {
        if (modalType) {
            return this.setState({
                modal: {
                    isOpen: true,
                    type: modalType,
                },
            });
        }
        return this.setState({
            modal: {
                isOpen: true,
                type: '',
            },
        });
    };

    /**
     * Generates a column configuration based on the viewAssortmentColumns config by making the following change:
     * remove columns that the user has hidden in their user settings
     */
    generateColumnConfig = () => {
        const { visibleAssortmentColumnKeys, activeFilters } = this.props;
        const { partner } = activeFilters.mainFilters;
        const showPartnerAssortmentLockColumn = !!intersection(partner, PARTNER_PORTAL_PARTNERS).length;

        const columns = assortmentViewColumns
            .filter(
                (assortmentViewColumn: AssortmentColumnConfig) =>
                    visibleAssortmentColumnKeys.includes(assortmentViewColumn.key) || assortmentViewColumn.isFixed
            )
            .filter((assortmentViewColumn: AssortmentColumnConfig) =>
                assortmentViewColumn.key === 'lock' ? showPartnerAssortmentLockColumn : true
            );

        this.setState({ columns } as { columns: AssortmentColumnConfig[] });
    };

    generateClustersConfig = (keepCurrentState = true) => {
        const { data } = this.props;
        const { clustersOpenState } = this.state;
        const { clusterOptionCounts } = data;

        if (clusterOptionCounts.length === 1) {
            this.setState({ clustersOpenState: { [clusterOptionCounts[0].clusterId]: true } });
            return;
        }

        const openState = clusterOptionCounts.reduce(
            (acc, { clusterId }) => ({
                ...acc,
                [clusterId]: keepCurrentState ? clustersOpenState[clusterId] ?? false : false,
            }),
            {} as { [key: string]: boolean }
        );

        this.setState({ clustersOpenState: openState });
    };

    calculateColumnWidths = (columnType: string) => {
        const { columns, clustersOpenState } = this.state;
        const { data } = this.props;
        const totalColumnWidth = getColumnRangeWidth(columns);
        const clustersWidth = data.clusterOptionCounts.reduce((acc, cur) => {
            const { clusterId, storeOptionCounts } = cur;

            // each cluster column is 48px, plus 48px per store in case cluster is opened
            return clustersOpenState[clusterId] ? acc + 48 + 48 * storeOptionCounts.length : acc + 48;
        }, 48);

        const fixedColumnsWidth =
            sum(columns.filter(({ type }) => type === 'fixed').map(({ key }) => getColumnWidth(columns, key))) + 20;
        const scrollableColumnsWidth = totalColumnWidth - fixedColumnsWidth + 100; // Needs extra buffer for padding and scrollbar

        if (columnType === 'fixed') {
            return fixedColumnsWidth;
        }
        return scrollableColumnsWidth + clustersWidth;
    };

    handleClusterClick = (clusterId: string) => {
        this.setState((state) => ({
            clustersOpenState: { ...state.clustersOpenState, [clusterId]: !state.clustersOpenState[clusterId] },
        }));
    };

    handleAllClusterClick = () => {
        this.setState((state) => {
            const isOpen = !this.isAllClustersOpened();
            return {
                clustersOpenState: Object.entries(state.clustersOpenState).reduce(
                    (clustersOpen, [clusterId, openState]) => ({
                        ...clustersOpen,
                        [clusterId]: clusterId === 'mock_cluster_id' ? openState : isOpen,
                    }),
                    {}
                ),
            };
        });
    };

    isAllClustersOpened = () =>
        Object.entries(this.state.clustersOpenState)
            .filter(([clusterId]) => clusterId !== 'mock_cluster_id')
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .every(([_, isOpen]) => isOpen);

    renderColumns = (listRowProps: ListRowProps, type: 'fixed' | 'scrollable') => {
        const { index, style, key, parent } = listRowProps;
        const { activeChannelId, data, addToAssortment, updateAssortment, updateMetaData, updateAssortmentMaterial } =
            this.props;
        const { columns } = this.state;
        const material = data.materials[index];
        const lockedForPartners = data.materialsLockedForPartnerAssortment.filter(
            ({ materialCode }) => materialCode === material.materialCode
        );

        return (
            <CellMeasurer cache={this.columnsCache[type]} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <ViewAssortmentRow
                    key={key}
                    columnType={type}
                    material={material}
                    clusterOptionCounts={data.clusterOptionCounts}
                    assortmentRecords={data.assortmentRecords}
                    lockedForPartnersAssortment={lockedForPartners}
                    addToAssortment={addToAssortment}
                    updateAssortment={updateAssortment}
                    updateMetaData={updateMetaData}
                    updateAssortmentMaterial={updateAssortmentMaterial}
                    style={style}
                    columns={columns}
                    channelId={activeChannelId}
                    clustersOpenState={this.state.clustersOpenState}
                    updateAssortmentCluster={this.props.updateAssortmentCluster}
                />
            </CellMeasurer>
        );
    };

    render() {
        const {
            data,
            activeFilters,
            activeChannelId,
            loading,
            pagination,
            getAssortment,
            orderBy,
            subFilters,
            clearActiveSubFilters,
        } = this.props;
        const { columns, isSettingsOpen, modal } = this.state;

        const totalActiveFilters = getAppliedFiltersCount(activeFilters.subFilters);
        const missingMainFilterKeys = getMissingMainFilterKeys(activeFilters.mainFilters);
        const noResultsMainFilters = !loading && data.materials.length === 0 && !totalActiveFilters;
        const noResultsSubFiltersApplied = !loading && data.materials.length === 0 && totalActiveFilters > 0;

        if (missingMainFilterKeys.length) {
            return (
                <div className="ViewAssortmentList__noResults">{`Please select a filter for ${combineWords(
                    missingMainFilterKeys
                )}`}</div>
            );
        }

        if (!loading && data.clusterOptionCounts.length === 0) {
            return (
                <div className="ViewAssortmentList__noResults">
                    No store with option count target found for this partner/category/division.
                    <br />
                    Please revise the option count file.
                </div>
            );
        }

        if (noResultsMainFilters) {
            return <div className="ViewAssortmentList__noResults">No materials found for the given filters.</div>;
        }

        const assortmentEditableColumnsKeys = assortmentViewColumns.reduce((arr: string[], item: AssortmentColumnConfig) => {
            if (item.editable?.bulk) {
                arr.push(item.key);
            }
            return arr;
        }, []);

        const headerProps = {
            subFilters,
            onEditColumn: (columnKey: string) => this.toggleModal(columnKey),
            toggleSettings: () => this.toggleSettings(),
            columns,
            onSortColumn: (columnKey: string, direction: 'ASC' | 'DESC') => {
                getAssortment(0, columnKey, direction);
            },
            orderColumn: orderBy.columnKey,
            orderDirection: orderBy.direction,
            activeSubFilters: activeFilters.subFilters,
        };

        return (
            <div
                className={cn('ViewAssortmentList', {
                    'ViewAssortmentList--loading': loading && pagination && pagination.page === 0,
                })}
            >
                <BulkUpdateModal
                    open={assortmentEditableColumnsKeys.includes(modal.type) && modal.isOpen}
                    onClose={() => this.toggleModal()}
                    type={modal.type}
                    activeFilters={activeFilters}
                    channelId={activeChannelId}
                    orderBy={orderBy}
                    view={PageView.assortment}
                    getLatest={getAssortment}
                />
                {isSettingsOpen && <AssortmentSettings onClose={this.toggleSettings} />}
                <div className="ViewAssortmentList__aggregatedMaterials">
                    <InfiniteScrollList
                        loading={loading}
                        pagination={pagination}
                        fetchPage={getAssortment}
                        rows={data.materials}
                    >
                        {({ height, width, onScroll, scrollTop, onRowsRendered }) => (
                            <div className="ViewAssortmentList__container">
                                <div className="ViewAssortmentList__fixed-container">
                                    <div
                                        className="ViewAssortmentList__header"
                                        style={{ width: this.calculateColumnWidths('fixed') }}
                                    >
                                        <HeaderRow
                                            isOpen={this.isAllClustersOpened()}
                                            onClick={this.handleAllClusterClick}
                                            headerType="fixed"
                                            {...headerProps}
                                            activeChannelId={activeChannelId}
                                        />
                                    </div>
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={this.columnsCache.fixed}
                                        width={this.calculateColumnWidths('fixed')}
                                        height={noResultsSubFiltersApplied ? 0 : height}
                                        rowCount={data.materials.length}
                                        rowHeight={this.columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) =>
                                            this.renderColumns(listRowProps, 'fixed')
                                        }
                                        className="ViewAssortmentList__item"
                                        ref={this.fixedListRef}
                                        overscanRowCount={0}
                                        onRowsRendered={onRowsRendered}
                                    />
                                    {loading && <Loader width={45} />}
                                </div>

                                <div
                                    className="ViewAssortmentList__scrollable-container"
                                    style={{ width: width - this.calculateColumnWidths('fixed') }}
                                >
                                    <div
                                        className="ViewAssortmentList__header"
                                        style={{ width: this.calculateColumnWidths('scrollable') }}
                                    >
                                        <HeaderRow
                                            isOpen={this.isAllClustersOpened()}
                                            onClick={this.handleAllClusterClick}
                                            headerType="scrollable"
                                            activeChannelId={activeChannelId}
                                            {...headerProps}
                                        />
                                        {data.clusterOptionCounts.map(
                                            (clusterOptionCount: AssortmentClusterOptionCounts) => (
                                                <div
                                                    key={clusterOptionCount.clusterName}
                                                    className="ViewAssortmentList__clusterHeader"
                                                >
                                                    <AssortmentClusterHeader
                                                        clusterOptionCount={clusterOptionCount}
                                                        activeMainFilters={activeFilters.mainFilters}
                                                        onClick={this.handleClusterClick}
                                                        isOpen={this.state.clustersOpenState[clusterOptionCount.clusterId]}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                    {noResultsSubFiltersApplied && (
                                        <div className="ViewAssortmentList__noMaterials">
                                            No materials found for the given filters.
                                            <br />
                                            {`Try clearing ${totalActiveFilters} column filters:`}
                                            <ClearSubFiltersButton
                                                bodyText="Clear all filters"
                                                fullWidth={false}
                                                totalActiveFilters={totalActiveFilters}
                                                onClick={() => clearActiveSubFilters(activeChannelId, PageView.assortment)}
                                            />
                                        </div>
                                    )}
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={this.columnsCache.scrollable}
                                        width={this.calculateColumnWidths('scrollable')}
                                        height={height}
                                        rowCount={data.materials.length}
                                        rowHeight={this.columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) =>
                                            this.renderColumns(listRowProps, 'scrollable')
                                        }
                                        className="ViewAssortmentList__item"
                                        ref={this.scrollableListRef}
                                        overscanRowCount={0}
                                        onRowsRendered={onRowsRendered}
                                    />
                                    {loading && <Loader width={45} />}
                                </div>
                            </div>
                        )}
                    </InfiniteScrollList>
                </div>
            </div>
        );
    }
}

const mapActionCreators = {
    clearActiveSubFilters: clearActiveSubFiltersAction,
    updateAssortmentMaterial: updateAssortmentMaterialAction,
};

const mapStateToProps = ({
    assortment: { data, metaData },
    user: {
        settings: { activeChannelId, visibleAssortmentColumnKeys, activeFilters },
    },
}: {
    assortment: {
        data: AssortmentData;
        metaData: FilteringMetaData;
    };
    user: UserState;
}) => ({
    data,
    visibleAssortmentColumnKeys,
    activeFilters: {
        mainFilters: activeFilters[`${activeChannelId}|${PageView.assortment}`]?.mainFilters ?? {},
        subFilters: activeFilters[`${activeChannelId}|${PageView.assortment}`]?.subFilters ?? {},
    },
    activeChannelId,
    subFilters: metaData.subFilters,
});

export default compose(connect(mapStateToProps, mapActionCreators))(ViewAssortmentList);
