import React from 'react';
import cn from 'classnames';
import {
    AssortmentRecord,
    AssortmentDetails,
    AssortmentStoreOptionCounts,
    Material,
    canUpdateDigitalBuyplan,
    AssortedBy,
    ChannelRole,
} from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './AssortmentDetailCheckbox.scss';
import { useSelector } from '../../store/reducers';
import useHasChannelRole from '../../selectors/useHasChannelRole';

export interface AssortmentDetailCheckboxProps {
    clusterId: string;
    material: Material;
    record?: AssortmentDetails;
    isMaterialLockedForPartnerAssortment: boolean;
    enabledForPartnerAssortment: boolean;
    storeOptionCount: AssortmentStoreOptionCounts;
    onChange: (record: AssortmentRecord, clusterId: string) => void;
    assortAllLoading: boolean;
    isDisabled: boolean;
}

function AssortmentDetailCheckbox({
    clusterId,
    material,
    storeOptionCount,
    record,
    enabledForPartnerAssortment,
    onChange,
    isMaterialLockedForPartnerAssortment = false,
    isDisabled = false,
    assortAllLoading,
}: AssortmentDetailCheckboxProps) {
    const checked = !!(record && record.isActive);
    const updating = record && record.isUpdating;

    const isValueChanged = record && record.isFromMerchandiseFile !== record.isActive;

    const assortedBy = record && record.assortedBy;
    const id = `${storeOptionCount.number}_${material.materialCode}`;
    const { activeChannelId } = useSelector(({ user }) => user.settings);
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    const shouldDisable = () => {
        const storeNumber = storeOptionCount.number;
        const { typeOfOrder } = material;
        return !canUpdateDigitalBuyplan(activeChannelId, storeNumber, typeOfOrder) || isReadOnlyUser;
    };

    return (
        <div className="AssortmentDetailCheckbox" title={`${material.materialCode} - ${storeOptionCount.name}`}>
            <label
                htmlFor={id}
                className={cn('AssortmentDetailCheckbox__label', {
                    'AssortmentDetailCheckbox__label--isPlannerAssortment':
                        isValueChanged && assortedBy === AssortedBy.planner,
                    'AssortmentDetailCheckbox__label--isPartnerAssortment':
                        isValueChanged && assortedBy === AssortedBy.partner,
                })}
            >
                {enabledForPartnerAssortment && (
                    <FontAwesomeIcon
                        className="AssortmentDetailCheckbox__locked"
                        color="lightgrey"
                        size="sm"
                        icon={(isMaterialLockedForPartnerAssortment ? faLock : faLockOpen) as IconProp}
                    />
                )}
                <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    disabled={updating || shouldDisable() || assortAllLoading || isDisabled}
                    onChange={() =>
                        onChange(
                            {
                                storeId: storeOptionCount.storeId,
                                storeNumber: storeOptionCount.number,
                                materialCode: material.materialCode,
                                isActive: !checked,
                                clusterId,
                            },
                            clusterId
                        )
                    }
                />
                <div className="checkmark" />
            </label>
        </div>
    );
}

export default AssortmentDetailCheckbox;
