import React, { useCallback, useState } from 'react';
import { channels } from 'buyplan-common';
import { matchPath, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useDispatch } from 'react-redux';
import Logo from '../Logo/Logo';
import { stopImpersonatingUser } from '../../actions/impersonation';
import useSeasonSettings from '../../selectors/useSeasonSettings';
import MancalaNavigation from '../Navigation/MancalaNavigation';
import BuyplanNavigation from '../Navigation/BuyplanNavigation';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import useUserProfile from '../../selectors/useUserProfile';
import { deactivateUserImpersonation } from '../../services/impersonationService';
import useImpersonationStatus from '../../selectors/useImpersonationStatus';
import Button from '../Button/Button';
import ApplicationSelection from './ApplicationSelection';
import ChannelSeasonSelection from './ChannelSeasonSelection';
import './Header.scss';

function Header() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const profile = useUserProfile();
    const isNDSAdmin = useIsChannelAdmin(channels.nds.id);
    const isHome = matchPath(pathname, { path: '/', exact: true }) !== null;
    const isMancala = matchPath(pathname, { path: '/buyplan' }) === null;
    const { isHistoricalSeason } = useSeasonSettings();
    const { impersonatedProfile, impersonationInProgress } = useImpersonationStatus();
    const [hasError, setHasError] = useState(false);

    const handleStopImpersonation = useCallback(async () => {
        if (hasError) setHasError(false);
        try {
            await deactivateUserImpersonation(false);
            dispatch(stopImpersonatingUser());
            window.location.reload();
        } catch (err: unknown) {
            // handled by global error
        }
    }, [dispatch, hasError]);

    if (!profile || isHome) {
        return null;
    }

    const isProduction = window.location.hostname === 'buyplan.dsmplanning.nikecloud.com';

    return (
        <header className={cn('header', { 'header__historical-season': isHistoricalSeason })}>
            {!isProduction && (
                <div className="header__environment__warning">
                    <FontAwesomeIcon icon={faAsterisk as IconProp} title="Test Environment" /> TEST ENVIRONMENT
                </div>
            )}
            {impersonationInProgress && (
                <div className="header__impersonation__warning">
                    <span>{`IMPERSONATION IS ACTIVE FOR USER: ${impersonatedProfile?.email}`}</span>
                    <Button size="s" onClick={() => dispatch(handleStopImpersonation())}>
                        Stop Impersonation
                    </Button>
                </div>
            )}
            <div className="header__container">
                <div className="header__logo">
                    <Logo app={isMancala ? 'mancala' : 'buyplan'} hideNameOnSmallScreen />
                    {!isMancala && (
                        <div className="header__dropdown">
                            <ChannelSeasonSelection />
                        </div>
                    )}
                </div>
                <div className={cn('header__navigation', { 'header__navigation--margin-align': !isMancala })}>
                    {isMancala ? <MancalaNavigation /> : <BuyplanNavigation />}
                </div>
                <div className="header__spacer">
                    {isNDSAdmin && (
                        <div className="header__side">
                            <ApplicationSelection />
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
