import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './StepIndicator.scss';

export enum StateIndicator {
    INDICATOR_COMPLETED = 'completed',
    INDICATOR_IDLE = 'idle',
    INDICATOR_WAITING_ON_USER = 'waiting',
    INDICATOR_CUSTOM = 'custom',
}

interface Props {
    percentage?: number;
    totalParts?: number;
    completedParts?: number;
    sqSize?: number;
    state?: StateIndicator;
    customIcon?: IconProp;
}

function StepIndicator({
    state = StateIndicator.INDICATOR_IDLE,
    percentage: providedPercentage,
    sqSize = 64,
    totalParts,
    completedParts,
    customIcon,
}: Props) {
    let percentage = providedPercentage;
    let label;
    let icon;
    let extraClass;

    switch (state) {
        case StateIndicator.INDICATOR_COMPLETED: {
            icon = <FontAwesomeIcon icon={faCheck as IconProp} size="xs" />;
            break;
        }
        case StateIndicator.INDICATOR_WAITING_ON_USER: {
            icon = <FontAwesomeIcon icon={faUserCheck as IconProp} size="xs" />;
            break;
        }
        case StateIndicator.INDICATOR_CUSTOM: {
            icon = <FontAwesomeIcon icon={customIcon as IconProp} size="xs" />;
            break;
        }
        default:
    }
    if (!percentage && totalParts !== undefined && completedParts !== undefined) {
        percentage = Math.round((completedParts / totalParts) * 100);
    }

    const radius = sqSize / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;

    return (
        <svg
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}
            className={cn('StepIndicator', `StepIndicator--state-${state}`, {
                [`StepIndicator--${extraClass}`]: !!extraClass,
            })}
        >
            <circle className="StepIndicator__circle-background" cx={sqSize / 2} cy={sqSize / 2} r={radius} />
            {icon}
            {label && (
                <text className="StepIndicator__circle-text" x="50%" y="50%" dy=".3em" textAnchor="middle">
                    {label}
                </text>
            )}
        </svg>
    );
}

export default StepIndicator;
