import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const FILE_ERROR_LIMIT = 10;

interface Props {
    message: string | string[];
}

function MessageColumn({ message = '' }: Props) {
    const messageArray = Array.isArray(message) ? message : [message];
    const errorsTotal = messageArray.length;
    return (
        <div>
            {messageArray.slice(0, FILE_ERROR_LIMIT).map((item) => (
                <div key={item}>
                    <FontAwesomeIcon icon={faExclamationCircle as IconProp} />
                    {item}
                </div>
            ))}
            {FILE_ERROR_LIMIT && errorsTotal > FILE_ERROR_LIMIT && (
                <div className="more-errors">There are more errors...</div>
            )}
        </div>
    );
}

export default memo(MessageColumn);
