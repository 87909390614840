"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.silhouetteReportRows = exports.silhouetteRowsConfig = void 0;
const formatters_1 = require("../helpers/formatters");
exports.silhouetteRowsConfig = [
    // GENERAL
    {
        key: 'category',
        label: 'Category',
        type: 'fixed',
        isGroupStart: true,
        groupKey: 'filters',
        groupStartHeader: 'Category',
        displayOnlyIn: 'Category',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'division',
        label: 'Division',
        type: 'fixed',
        isGroupStart: true,
        groupKey: 'filters',
        groupStartHeader: 'Division',
        displayOnlyIn: 'Division',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    {
        key: 'silhouette',
        label: 'Silhouette',
        type: 'fixed',
        groupKey: 'filters',
        isGroupStart: true,
        groupStartHeader: 'Silhouette',
        displayOnlyIn: 'silhouette',
        isFixed: true,
        noDivider: true,
        getValue: (value) => value,
    },
    // REVENUE
    {
        key: 'revenue',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        isGroupStart: true,
        noDivider: true,
        label: 'TY',
        groupStartHeader: 'Revenue',
        headerColSpan: 7,
    },
    {
        key: 'lastYearRevenue',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearRevenue',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'revenueGrowthPercentage',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'revenueGrowthPercentageLLY',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'revenueGrowth',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
    },
    {
        key: 'revenueGrowthLLY',
        type: 'scrollable',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
    },
    // TOTAL REVENUE
    {
        key: 'revenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: '% Total Revenue',
        headerColSpan: 3,
        type: 'scrollable',
    },
    {
        key: 'lastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
        type: 'scrollable',
    },
    {
        key: 'lastLastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
        type: 'scrollable',
    },
    // SEASONAL OPTION COUNTS
    {
        key: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Seasonal Option Counts',
        headerColSpan: 4,
        type: 'scrollable',
    },
    {
        key: 'seasonalOptionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        type: 'scrollable',
    },
    {
        key: 'lastYearSeasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSeasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        type: 'scrollable',
    },
    // TRADED OPTION COUNTS
    {
        key: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Traded Option Counts',
        headerColSpan: 4,
        type: 'scrollable',
    },
    {
        key: 'tradedOptionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        type: 'scrollable',
    },
    {
        key: 'lastYearTradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        type: 'scrollable',
    },
    {
        key: 'lastLastYearTradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        type: 'scrollable',
    },
    // SALES UNITS
    {
        key: 'salesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sales Units',
        headerColSpan: 7,
        type: 'scrollable',
    },
    {
        key: 'lastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowth',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
        type: 'scrollable',
    },
    // SALES ASP (AVERAGE SELLING PRICE)
    {
        key: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sales ASP',
        headerColSpan: 5,
        type: 'scrollable',
    },
    {
        key: 'lastYearAverageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LY',
        type: 'scrollable',
    },
    {
        key: 'lastLastYearAverageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LLY',
        type: 'scrollable',
    },
    {
        key: 'averageSellingPriceGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        type: 'scrollable',
    },
    {
        key: 'averageSellingPriceGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        type: 'scrollable',
    },
    // Presentation Stocks
    {
        key: 'presentationStocks',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Presentation Stocks',
        headerColSpan: 7,
        type: 'scrollable',
    },
    {
        key: 'lastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        type: 'scrollable',
    },
    {
        key: 'lastLastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        type: 'scrollable',
    },
    // TODO: This should be changed to presentationStocksGrowthPercentage once we have presentationStocks in LY and LLY
    {
        key: 'investUnitToPresentationStockGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowthLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowth',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
        type: 'scrollable',
    },
    {
        key: 'investUnitToPresentationStockGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
        type: 'scrollable',
    },
    // Allocated Units
    {
        key: 'openToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'OTB Units TY',
        groupStartHeader: 'Allocated Units',
        headerColSpan: 3,
        type: 'scrollable',
    },
    {
        key: 'allocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'Allocation Units TY',
        type: 'scrollable',
    },
    {
        key: 'otbUnitAllocationUnitDifference',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'OTB VS Allocation UNITS Δ',
        type: 'scrollable',
    },
    // Open To Buy Units
    {
        key: 'openToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'OTB Units',
        headerColSpan: 1,
        type: 'scrollable',
    },
    // BUY WHOLESALE VALUE $
    {
        key: 'buyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Buy Wholesale Value $',
        headerColSpan: 1,
        type: 'scrollable',
    },
    // BOP
    {
        key: 'beginningOfPeriodUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        type: 'scrollable',
        label: 'TY',
        groupStartHeader: 'BOP',
        headerColSpan: 1,
    },
];
const silhouetteReportRows = (channelId) => {
    let silhouetteReportRowsFiltered = exports.silhouetteRowsConfig;
    if (channelId) {
        silhouetteReportRowsFiltered = silhouetteReportRowsFiltered.filter((rowConfig) => !rowConfig.applyToChannels || rowConfig.applyToChannels.includes(channelId));
    }
    return silhouetteReportRowsFiltered;
};
exports.silhouetteReportRows = silhouetteReportRows;
