import { UserProfile, ChannelMeta } from 'buyplan-common';
import { request } from '../api/api';

export const getUserProfile = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/profile`,
    };

    return request<{ data: UserProfile }>(requestOptions, true);
};

export const getUser = (email: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/users/${email}`,
    };

    return request<{ data: UserProfile }>(requestOptions, true);
};

export const getUsers = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/users`,
    };

    return request<{ data: UserProfile[] }>(requestOptions, true);
};

export const getUserMeta = (channelToFetch: number) => {
    /* We cannot rely on getChannelId middleware for user meta because
    we may be fetching user meta for channel that is not active (e.g. User Settings page) */
    const queryParams = { channelToFetch };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/users/meta`,
        queryParams,
    };

    return request<{ data: ChannelMeta }>(requestOptions, true);
};

export const editUser = async (user: UserProfile) => {
    const requestOptions = {
        method: 'PUT',
        data: user,
        url: `${process.env.REACT_APP_API_ENDPOINT}/users`,
    };

    await request(requestOptions, true);
};
