import React, { ChangeEvent, KeyboardEvent } from 'react';
import cn from 'classnames';
import './TextInput.scss';

interface TextInputProps {
    value: string;
    onChange(value: string): void;
    onKeyDown?: () => void;
    className?: string;
}

function TextInput({ value, onChange, onKeyDown, className, ...props }: TextInputProps) {
    const handleChange = (
        event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        onChange(event.target.value as string);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            onKeyDown?.();
        }
    };
    return (
        <input
            {...props}
            type="text"
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={value}
            className={cn('TextInput', className)}
        />
    );
}
export default TextInput;
