import React, { forwardRef, useCallback } from 'react';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import { Card, CardActions, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './Notification.scss';

const SuccessfulNotification = forwardRef<HTMLDivElement, CustomContentProps>(({ id, message }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref} role="alert" className="root">
            <Card className="card">
                <CardActions classes={{ root: 'actionRoot' }}>
                    <Typography variant="body2" className="typography">
                        {message}
                    </Typography>
                    <IconButton className="closeButton" size="small" onClick={handleDismiss}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </CardActions>
            </Card>
        </SnackbarContent>
    );
});

SuccessfulNotification.displayName = 'SuccessfulNotification';

export default SuccessfulNotification;
