import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../Modal/Modal';
import { resetGlobalError } from '../../actions/globalError';
import { useSelector } from '../../store/reducers';

function GlobalError() {
    const dispatch = useDispatch();
    const { error, errorMessage } = useSelector((state) => state.globalError);

    return (
        <Modal
            style={{ content: { minWidth: 485 }, overlay: { zIndex: 10 } }}
            isOpen={error}
            title="Something went wrong"
            subtitle={errorMessage}
            text="Please retry your action or reload -
        If you continue to receive this message, please report an incident in ServiceNow"
            closeAction={() => dispatch(resetGlobalError())}
            okAction={() => dispatch(resetGlobalError())}
            okButtonText="Close to retry manually"
            cancelAction={() => window.location.reload()}
            cancelButtonText="Reload page"
            autoCloseAfterAction={true}
        />
    );
}

export default GlobalError;
