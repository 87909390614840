import React from 'react';
import { Store, CurrencyType } from 'buyplan-common';
import { ExchangeRate } from '../../services/exchangeRatesService';
import { AttributedSalesTarget, StoreSalesTarget } from '../../services/salesTargetService';
import useSeasonSettings from '../../selectors/useSeasonSettings';
import Dot, { DotSize } from '../Dot/Dot';
import { SelectOptionType } from '../ReactSelect/ReactSelect';
import TargetsRow from './TargetsRow';
import './TargetsTable.scss';

interface Props {
    targetsType: 'store' | 'segment-category-division-consumer';
    headers: { key: string; label: string }[];
    currencyType: CurrencyType;
    rows: (Store | AttributedSalesTarget)[];
    storeSalesTargets?: StoreSalesTarget[];
    exchangeRates?: ExchangeRate[];
    like4likeStores?: SelectOptionType[];
}

function TargetsTable({
    targetsType,
    headers,
    currencyType,
    rows,
    like4likeStores,
    storeSalesTargets,
    exchangeRates,
}: Props) {
    const { isEditableSeason } = useSeasonSettings();
    return (
        <div className="TargetsTable">
            <table cellPadding={0} cellSpacing={0} className="TargetsTable__table table table-striped table-bordered">
                <thead className="TargetsTable__headers">
                    <tr>
                        {headers.map((header) => {
                            if (header.key === 'like4like') {
                                return (
                                    <th key={header.key} className={`TargetsTable__header-${header.key}`}>
                                        {header.label}
                                        <Dot
                                            tooltip="Select a similar store as a reference point. See the documentation for more info."
                                            placement="right"
                                            size={DotSize.large}
                                        />
                                    </th>
                                );
                            }
                            return (
                                <th key={header.key} className={`TargetsTable__header-${header.key}`}>
                                    {header.label}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row) => (
                        <TargetsRow
                            isEditable={isEditableSeason}
                            key={row.id}
                            targetsType={targetsType}
                            like4likeStores={like4likeStores}
                            item={row}
                            currencyType={currencyType}
                            exchangeRates={exchangeRates}
                            storeSalesTarget={(storeSalesTargets || []).find(
                                (salesTarget) => salesTarget.storeId === row.id
                            )}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TargetsTable;
