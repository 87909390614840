import React, { ReactElement, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { channels } from 'buyplan-common';
import useImpersonationStatus from '../../selectors/useImpersonationStatus';
import useActiveChannelSettings from '../../selectors/useActiveChannelSettings';
import { setChannel } from '../../actions/user';
import './ContentWrapper.scss';

interface Props {
    children: ReactElement;
}

/*
    This component is useful for components that fall under Mancala routes. It will wrap components with a div
    that will provide the appropriate amount of top padding depending on what Headers are rendered.
    Besides, it will set current channel id as NDS, because only NDS planners can access Mancala routes.
*/
function ContentWrapper({ children }: Props) {
    const dispatch = useDispatch();
    const { impersonationInProgress } = useImpersonationStatus();
    const isProduction = window.location.hostname === 'buyplan.dsmplanning.nikecloud.com';
    const { channelId } = useActiveChannelSettings();

    useEffect(() => {
        if (channelId !== channels.nds.id) {
            dispatch(setChannel(channels.nds.id));
        }
    }, [dispatch, channelId]);

    return (
        <div
            className={cn('contentWrapper', {
                'contentWrapper--withImpersonationBanner': impersonationInProgress && isProduction,
                'contentWrapper--withTestEnvBanner': !isProduction && !impersonationInProgress,
                'contentWrapper--bothBanners': !isProduction && impersonationInProgress,
            })}
        >
            {children}
        </div>
    );
}

export default ContentWrapper;
