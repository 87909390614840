import React from 'react';

export default function () {
    return (
        <div>
            <h2>Store Level</h2>
            <p>This report allows you to see the information for each store on multiple levels.</p>
            <h3>Summary</h3>
            <p>
                In order to get to this report, <code>CLICK</code> on <strong>Store Level</strong> under the{' '}
                <strong>Reports</strong> tab. In this view, you can find one report which provides an overview of the total
                revenue, sales units, sales asp, sell through and presentation stocks per division for the selected store(s).
                The summary shows the data for this and last year with their difference and the percentage of
                increase/decrease for this year. A percentage of the total revenue can be found in the summary and last last
                year&apos;s data is included for obtaining more insights.
            </p>
            <p>
                The report can be filtered by Partner, Category, Store, Type of Order, Gender, Age, Launch Flag and Segment.
            </p>
            <p>
                <em>
                    NOTE:
                    <ul>
                        <li>This report is available for NDS and DIGITAL</li>
                    </ul>
                </em>
            </p>
        </div>
    );
}
