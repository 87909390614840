import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { Overlay, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { MainFilters, AssortmentClusterOptionCounts, AssortmentStoreOptionCounts, channels } from 'buyplan-common';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from '../../store/reducers';
import AssortmentStoreHeader from './AssortmentStoreHeader';
import './AssortmentClusterHeader.scss';

export interface AssortmetClusterHeaderProps {
    clusterOptionCount: AssortmentClusterOptionCounts;
    className?: string;
    activeMainFilters: MainFilters;
    onClick: (clusterId: string) => void;
    isOpen: boolean;
}

function AssortmentClusterHeader({
    className,
    activeMainFilters,
    clusterOptionCount: { actualOptions, targetOptions, clusterName, clusterId, storeOptionCounts, filteredOptions },
    onClick,
    isOpen,
}: AssortmetClusterHeaderProps) {
    const { activeChannelId } = useSelector((state) => state.user.settings);
    const { category, division } = activeMainFilters;
    const [showTooltip, setShowTooltip] = useState(false);
    const headerColumnRef = useRef<HTMLDivElement>(null);

    /**
     * Returns a classname that renders the store option target red, green or black.
     *
     * - Green: the actual option count divided by the target option count is within 35% variance
     *   (either above or below).
     * - Red: the actual option count divided by the target option count is more than 35% variance
     *   (either above or below).
     * - Black: more than one category and/or division has been selected, coloring the target
     *   doesn't make any sense. For example: a store has 2 option count targets of 5 for two
     *   different categories; when one category has been selected in the buyplan 10 times it would
     *   perfectly match the total option count target (10/10) while in fact neither individual
     *   option count target is within 35% variance (10/5 and 0/5), both more than 35% off.
     */
    const optionCountVarianceClass = () => {
        if (
            (division && division.length !== 1) ||
            (category && category.length !== 1) ||
            activeChannelId === channels.digital.id
        ) {
            return '';
        }

        const optionCountVariance = (actualOptions / targetOptions) * 100 - 100;
        return Math.abs(optionCountVariance) > 35 ? 'out-of-variance' : 'within-variance';
    };

    const toolTiptext = `${clusterName} has ${actualOptions} assorted option${actualOptions !== 1 ? 's' : ''} ${
        activeChannelId !== channels.digital.id ? `vs. ${targetOptions} target option${targetOptions !== 1 ? 's' : ''}` : ''
    }`;

    const handleClusterClick = () => {
        onClick(clusterId);
    };

    const icon = (isOpen ? faChevronRight : faChevronLeft) as IconProp;
    return (
        <>
            <div
                role="presentation"
                onClick={handleClusterClick}
                ref={headerColumnRef}
                className={cn('assortment-cluster-header', className, { isOpen })}
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
                onFocus={() => undefined}
                onBlur={() => undefined}
            >
                <div className={cn('assortment-cluster-header__container')}>
                    <span className="assortment-cluster-header__option-counts">
                        <span className={optionCountVarianceClass()}>
                            {`${actualOptions}${activeChannelId !== channels.digital.id ? `/${targetOptions}` : ' '}`}{' '}
                        </span>
                    </span>
                    {` | `}
                    <span className="assortment-cluster-header__clusterName">{clusterName}</span>
                    <FontAwesomeIcon className="assortment-cluster-header__button" icon={icon} color="black" />
                </div>
            </div>
            <Overlay target={headerColumnRef} show={showTooltip} placement="bottom">
                {(props: OverlayInjectedProps) => (
                    <Tooltip
                        id="assortment-store-header__tooltip" // required
                        {...props}
                        arrowProps={{
                            ref: props.arrowProps && props.arrowProps.ref,
                            style: { display: 'none' },
                        }}
                    >
                        {toolTiptext}
                    </Tooltip>
                )}
            </Overlay>
            {isOpen && (
                <div className="assortment-cluster-header__stores">
                    {storeOptionCounts.map((storeOptionCount: AssortmentStoreOptionCounts) => (
                        <div
                            key={`${storeOptionCount.name}_${storeOptionCount.number}`}
                            className="ViewAssortmentList__storeHeader"
                        >
                            <div className="ViewAssortmentList__filteredCountsContainer">
                                <div className="ViewAssortmentList__filteredCounts">{storeOptionCount.filteredOptions}</div>
                            </div>
                            <AssortmentStoreHeader
                                key={`${clusterId}-${storeOptionCount.number}`}
                                storeOptionCount={storeOptionCount}
                                activeMainFilters={activeMainFilters}
                                className="assortment-table-header__header-label"
                            />
                        </div>
                    ))}
                </div>
            )}
            <div className="ViewAssortmentList__filteredCountsContainer">
                <div className="ViewAssortmentList__filteredCounts">{filteredOptions}</div>
            </div>
        </>
    );
}

export default AssortmentClusterHeader;
