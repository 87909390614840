import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    row: { value: string | number; original: { id: number } };
    name: string;
    label: string;
    type: string;
    onEdit(editedValue: { id: number; value: string | number; name: string; label: string; type: string }): void;
}

function UpdatableCell({ row, name, label, type, onEdit }: Props) {
    const { original, value } = row;

    const handleClick = () => onEdit({ id: original.id, value, name, label, type });

    return (
        <span
            className="update-cell"
            role="button"
            title={`Update ${label}`}
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    handleClick();
                }
            }}
            onClick={handleClick}
        >
            <span className="update-cell-value">{value}</span>
            <span className="update-cell-button">
                <FontAwesomeIcon icon={faEdit as IconProp} />
            </span>
        </span>
    );
}

export default UpdatableCell;
