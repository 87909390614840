import React from 'react';
import optionCountsClusters from './images/optionCounts/OptionCountsClusters.png';
import optionCountsStores from './images/optionCounts/OptionCountsStore.png';

export default function () {
    return (
        <div className="ViewingOptionCounts">
            <h2>Viewing Option Counts</h2>
            <p>
                When viewing the Assortment Screen, you can use the Option Counts indicators to keep track of how the{' '}
                <b>Assorted Options</b> align with the <b>Target Options</b> based on the applied filters. Below is a helpful
                guide to describe what the different indicators mean.
            </p>
            <h3>Assorted vs. Target Options</h3>
            <p>
                When viewing a single category/division combination, the color of the Option Counts will signal if you have
                assorted enough options to fall within a reasonable range of the target options. When the Assorted Options
                are <u>outside a +/- 35% range</u> compared with the target options, the{' '}
                <span className="ViewingOptionCounts__warning--red">numbers will appear red</span>. If{' '}
                <u>within the +/- 35% range</u>, the{' '}
                <span className="ViewingOptionCounts__warning--green">numbers will appear green</span>.
            </p>
            <h3>Option Counts by Cluster</h3>
            <p>When viewing the Option Count indicators at the cluster-level:</p>
            <ol>
                <li>
                    The number in the grey box is the <b>highest number of assorted options in a store in the cluster</b>{' '}
                    based on both the <b>top-level and column-level filters</b>.
                </li>
                <li>
                    The first number beside the cluster name is the{' '}
                    <b>highest number of assorted options in a store in the cluster</b> based on the <b>top-level filters</b>{' '}
                    only.
                </li>
                <li>
                    The second number beside the cluster name is the{' '}
                    <b>average of target options for all stores in the cluster</b>.
                </li>
            </ol>
            <img width={800} src={optionCountsClusters} alt="Option Counts at the cluster-level" />
            <h3>Option Counts by Store</h3>
            <p>When viewing the Option Count indictors at the store-level:</p>
            <ol>
                <li>
                    The number in the grey box is the <b>assorted options for the store</b> based on both the{' '}
                    <b>top-level and column-level filters</b>.
                </li>
                <li>
                    The first number beside the store name is the <b>assorted options for the store</b> based on the{' '}
                    <b>top-level filters</b> only.
                </li>
                <li>
                    The second number beside the store name is the <b>target options for the store</b>.
                </li>
            </ol>
            <img width={500} src={optionCountsStores} alt="Option Counts at the store-level" />
        </div>
    );
}
