import React from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from './Navigation';

function MancalaNavigation() {
    return (
        <Navigation>
            <NavLink to="/import">Import</NavLink>
            <NavLink to="/overview">Overview</NavLink>
            <NavLink to="/submit-to-pega">Submit to PEGA</NavLink>
            <NavLink to="/send-to-ndf">Send to NDF</NavLink>
            <NavLink to="/export-seasons">Export Seasons</NavLink>
            <NavLink to="/mancala/documentation">Documentation</NavLink>
        </Navigation>
    );
}

export default MancalaNavigation;
