import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { SecureRoute, Security } from '@okta/okta-react';
import GlobalError from '../components/GlobalError/GlobalError';
import Home from '../components/Home/Home';
import Header from '../components/Header/Header';
import Authorizer from '../components/Authorizer/Authorizer';
import ImportOrders from '../components/ImportOrders/ImportOrders';
import OverviewContainer from '../components/Overview/OverviewContainer';
import SubmitToPega from '../components/SubmitToPega/SubmitToPega';
import ExportSeasons from '../components/ExportSeasons/ExportSeasons';
import SendToNDF from '../components/SendToNDF/SendToNDF';
import MancalaDocumentation from '../components/Documentation/MancalaDocumentation/MancalaDocumentation';
import Notifier from '../components/Notifications/Notifier';
import WebSocketConnector from '../components/Notifications/WebSocketConnector';
import BuyplanRoutes from './BuyplanRoutes';
import './Routes.scss';

function Routes() {
    return (
        <BrowserRouter>
            <div className="routes">
                <GlobalError />
                <Security
                    issuer={process.env.REACT_APP_OKTA_ISSUER}
                    clientId={process.env.REACT_APP_OKTA_CLIENTID}
                    redirectUri={`${window.location.origin}/implicit/callback`}
                    response_type="token"
                    pkce={false}
                >
                    <Authorizer>
                        <>
                            <Header />
                            <Notifier />
                            <WebSocketConnector />
                            <Switch>
                                {/* Mancala */}
                                <SecureRoute exact path="/" component={Home} />
                                <SecureRoute exact path="/import" component={ImportOrders} />
                                <SecureRoute exact path="/overview" component={OverviewContainer} />
                                <SecureRoute exact path="/submit-to-pega" component={SubmitToPega} />
                                <SecureRoute exact path="/export-seasons" component={ExportSeasons} />
                                <SecureRoute exact path="/send-to-ndf" component={SendToNDF} />
                                <Route path="/mancala/documentation" component={MancalaDocumentation} />

                                {/* Home and BuyPlan (routes) */}
                                <Route path="/buyplan" component={BuyplanRoutes} />
                                <Redirect to="/" />
                            </Switch>
                        </>
                    </Authorizer>
                </Security>
            </div>
        </BrowserRouter>
    );
}

export default Routes;
