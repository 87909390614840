import React, { ReactNode, ChangeEvent } from 'react';
import cn from 'classnames';
import './Checkbox.scss';

export interface CheckboxProps {
    id: string;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
    isChecked: boolean;
    className?: string;
    label?: ReactNode;
    disabled?: boolean;
}

function CheckBox({ label, onChange, id, isChecked = false, className, disabled = false }: CheckboxProps) {
    return (
        <label htmlFor={id} className={cn('checkbox-container', { 'checkbox-container--disabled': disabled }, className)}>
            <input id={id} type="checkbox" onChange={onChange} checked={isChecked} disabled={disabled} />
            <span className="checkmark" />
            {label && <span className="checklabel">{label}</span>}
        </label>
    );
}

export default CheckBox;
