import React from 'react';
import AFPercentageBulkUpdate from './images/AFPercentage_Bulk_Update.png';

export default function () {
    return (
        <div>
            <h2>AF% (Authorized Futures)</h2>
            <p>
                AF% is used to calculate how much of the buy will be booked on futures. This is important when a material is
                just getting switched to AA and we need to make sure we have enough to cover our loadIn. It is used for NDS
                accounts as they have to book some of their AA products on their contracts.
                <br />
                If the type of order is NBY, QS, or AF, then a default value of AF% is 100%. Otherwise, if the type of order
                is AA, a default value of AF% will be 0%.
            </p>
            <hr />
            <h3>Cluster Level Overrides</h3>
            <p>
                Once the Buyplan is active, any user with the correct channel access rights can update AF % at the cluster
                level in the Buyplan screen. This can be done manually using the AF % input, or it can be done using bulk
                update. If users would like to do a bulk update for either AF % they should use the filters to select which
                clusters to apply changes to, and then activate the Bulk Update modal and perform and override. AF OTB and AA
                OTB values are calculated based on AF % provided and must be whole numbers. Thats why when you change AF %,
                it will be related to AF OTB and AA OTB. In the case when units can&apos;t be whole numbers, AA OTB will be
                rounded up and AF OTB will be rounded down and based on this units you will have new AF %.
            </p>

            <h4>Bulk Update:</h4>
            <p>
                <img src={AFPercentageBulkUpdate} alt="AF % Bulk Update" />
            </p>
        </div>
    );
}
