import React from 'react';
import { BuyPlanAggregatedMaterial, BuyPlanStore, Store, favoriteStoreColumns } from 'buyplan-common';
import { generateCellStyle, getColumnRangeWidth } from '../../helpers/tableTools';
import BuyPlanRateOfSaleInput from '../BuyplanAggregatedMaterialDetail/BuyPlanRateOfSaleInput';
import SellThroughInput from '../SellThroughInput/SellThroughInput';
import './FavoriteStore.scss';
import BuyplanOpenToBuyTooltip from '../BuyplanAggregatedMaterialDetail/BuyplanOpenToBuyTooltip';

interface Props {
    favoriteStore: Store;
    aggregatedMaterial: BuyPlanAggregatedMaterial;
    onStoreChange(store: BuyPlanStore): void;
    onStoreBlur(): void;
    channelId: number;
    onChangeStoreOTB(store: BuyPlanStore): void;
    showMessage(message: string): void;
}

function FavoriteStore({ aggregatedMaterial, favoriteStore, onStoreBlur, onStoreChange }: Props) {
    const aggregatedMaterialStore = aggregatedMaterial.stores.find(({ storeId }) => storeId === favoriteStore.id);

    return (
        <div
            key={`${aggregatedMaterial.id}-${favoriteStore.id}`}
            className="favorite-store"
            style={{ width: getColumnRangeWidth(favoriteStoreColumns) }}
        >
            {aggregatedMaterialStore && (
                <>
                    <span {...generateCellStyle('favorite-store', 'rateOfSale', favoriteStoreColumns)}>
                        <BuyPlanRateOfSaleInput
                            store={aggregatedMaterialStore}
                            onChange={onStoreChange}
                            onBlur={onStoreBlur}
                            tabIndex={-1}
                        />
                    </span>
                    <span {...generateCellStyle('favorite-store', 'sellThrough', favoriteStoreColumns)}>
                        <SellThroughInput
                            value={aggregatedMaterialStore.sellThrough}
                            name="sellThrough"
                            onChange={(value: number) => onStoreChange({ ...aggregatedMaterialStore, sellThrough: value })}
                            onBlur={onStoreBlur}
                            tabIndex={-1}
                        />
                    </span>
                    <span {...generateCellStyle('favorite-store', 'openToBuyUnits', favoriteStoreColumns)}>
                        <BuyplanOpenToBuyTooltip
                            openToBuyUnits={aggregatedMaterialStore.openToBuyUnits}
                            store={aggregatedMaterialStore}
                            aggregatedMaterial={aggregatedMaterial}
                        />
                    </span>
                </>
            )}
        </div>
    );
}

export default FavoriteStore;
