import { useEffect, useMemo, useState } from 'react';

/**
 * The useRequest hook executes a request immediately, returning the loading, error and response data.
 *
 * Use this when you need to fetch data on mount.
 */
export const useRequest = <T>(requestFn: () => Promise<{ data: T }>): [boolean, Error | undefined, T | undefined] => {
    const [data, setData] = useState<T | undefined>();
    const [loading, setLoading] = useState(true); // default true ensures component doesn't render with loading = false on first render
    const [error, setError] = useState<Error | undefined>();

    useEffect(() => {
        const fetchData = async () => {
            setError(undefined);
            try {
                const result = await requestFn();
                setData(result.data);
            } catch (err: unknown) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [requestFn]);

    return [loading, error, data];
};

/**
 * The useRequestFunction returns the loading, error, data and request function.
 *
 * Unlike "useRequest" this does not trigger the request immediately. Use this when you need to fetch data due to some action.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRequestFunction = <T, K extends any[]>(
    requestFn: (...args: K) => Promise<{ data: T }>
): [boolean, Error | undefined, T | undefined, (...args: K) => Promise<void>] => {
    const [data, setData] = useState<T | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>();

    const fetchFunction = useMemo(
        () =>
            async (...args: K) => {
                setError(undefined);
                setLoading(true);
                try {
                    const result = await requestFn(...args);
                    setData(result.data);
                    setLoading(false);
                } catch (err: unknown) {
                    // The AbortError only occurs when another request started and made the current one
                    // obsolete. That is why we should not change state when this happens.
                    const er = err as Error;
                    if (er.name !== 'AbortError') {
                        setError(er);
                        setLoading(false);
                    }
                }
            },
        [requestFn]
    );

    return [loading, error, data, fetchFunction];
};
