/* eslint-disable max-len */
import React from 'react';
import { Switch, Route, Link, NavLink, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Logo from '../../Logo/Logo';
import './MancalaDocumentation.scss';
import ContentWrapper from '../../ContentWrapper/ContentWrapper';
import importStagedFiles from './images/ImportStagedFiles.png';

function Mancala() {
    return (
        <div>
            <Logo app="mancala" to="/mancala/documentation" />
            <h3>Import buyplan(s)</h3>
            <p>
                Mancala lets you <Link to="../import">import</Link> buy plan (excel) files based on{' '}
                <a href="/downloads/BUYPLAN_EXAMPLE.xlsx">this</a> template. The requirements for this file are listed below.
                Extra columns are currently not allowed.
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Excel Header</th>
                        <th scope="col">Type</th>
                        <th scope="col">Column required</th>
                        <th scope="col">Data required</th>
                        <th scope="col">Max length</th>
                        <th scope="col">Format</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">SoldTo</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">ShipTo</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Purchase_Order_Number</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td>
                            <em title="The first 2 characters should be capital followed by 2 numbers">
                                (SU99 DESCRIPTION)
                            </em>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Requested_Delivery_Date</th>
                        <td>Date</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Material</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>10</td>
                        <td>
                            <em
                                title="The first 6 characters should be capital or number,
                                        followed by a dash and 3 numbers"
                            >
                                (AA00BB-123)
                            </em>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Size</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">QUANTITY ORDER TYPE Z022</th>
                        <td>Number</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">QUANTITY ORDER TYPE Z013</th>
                        <td>Number</td>
                        <td>yes</td>
                        <td>yes</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Partner Name</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Store Name</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Store Number</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Country</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Category</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Division</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Product Description</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>255</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">QUANTITY LOAD IN</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>10</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">QUANTITY REPLEN</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>10</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">QUANTITY TOTAL</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">RRP (Recommended Retail Price)</th>
                        <td>Number</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>10</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">List Cost</th>
                        <td>Number</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>10</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Planner Submitted</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Date Submitted</th>
                        <td>Date</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>45</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Futr/AO</th>
                        <td>Text</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>255</td>
                        <td />
                    </tr>
                    <tr>
                        <th scope="row">Owner group</th>
                        <td>Number</td>
                        <td>yes</td>
                        <td>no</td>
                        <td>255</td>
                        <td />
                    </tr>
                </tbody>
            </table>
            <p>
                <strong>Note:</strong> If you activate the toggle <strong>Stage orders for export</strong> and then upload
                the orders, you would see those orders staged to be submitted to Pega. You can verify the orders on the page{' '}
                <Link to="../submit-to-pega">submit to pega</Link> and then Submit them to Pega from that page itself.
            </p>
            <img style={{ width: '100%' }} src={importStagedFiles} alt="Import Staged File" />

            <p>
                Once the orders are submitted to Pega, the orders would then show up on the overview page and not before
                that.
            </p>
            <h2>Overview</h2>
            <p>
                The <Link to="../overview">overview page</Link> gives you insight into the data. You can use the filters on
                left to narrow your selection.
            </p>
            <h3>Update single values</h3>
            <p>
                Data in the &quot;Load in Quantity&quot; and &quot;Replen Quantity&quot; column can be updated by moving your
                cursor over the targeted cell and clicking the <FontAwesomeIcon icon={faEdit as IconProp} /> icon. This will
                automatically update the &quot;Quantity&quot; column since this is a sum of the Load in and Replen values.
            </p>
            <h3>Batch update</h3>
            <p>
                You can edit all items in your current selection at once by clicking the{' '}
                <FontAwesomeIcon icon={faEdit as IconProp} /> icon in the column header. Batch update happen completely
                inside Mancala except for the Quantity column. The quantity column can be batch updated with an excel
                download and upload as long as the selection shows less than 100,000 items. All changes made outside of the
                &quot;QuantityLoadIn&quot; and &quot;QuantityReplen&quot; columns will be ignored.
            </p>
            <h2>Submit to PEGA</h2>
            <p>
                All data that is uploaded while the &quot;Stage orders for export&quot; toggle was enabled will be shown on
                the <Link to="../submit-to-pega">Submit to PEGA</Link> page until it is send to PEGA. When submitting to PEGA
                all orders will be aggregated by Purchase Order Number into 1 contract without store data.
            </p>
            <h2>Exporting data</h2>
            <h3>By season</h3>
            <p>
                You can <Link to="../export-seasons">export</Link> the buy plan for a whole season as CSV so you can use it
                to - for example - create a{' '}
                <a href="https://shipplan.nikecloud.com" rel="noopener noreferrer" target="_blank">
                    shipplan
                </a>
                . This is aggregated at the style/color level, therefore there is no size data included.
            </p>
            <h3>Custom set of items</h3>
            <p>
                In the top left on the <Link to="../overview">overview page</Link> there is a button that lets you export the
                current filtered dataset as a CSV file.
            </p>
            <h2>Send to NDF</h2>
            <p>
                On the <Link to="../send-to-ndf">send to NDF</Link> you can send a season to NDF. This will upload a .csv
                file (for example: <code>20191309.csv</code>).
            </p>
        </div>
    );
}

function Support() {
    return (
        <div>
            <h2>Support</h2>
            <p>
                In case you encounter issues with Mancala or have a support request you can report this in Nike Now by
                selecting
                {` `}
                <code>Mancala (EMEA)</code> as Service. Our support office will find the right knowledge capabilities and/or
                decision makers for the issue and will contact you.
            </p>
            <p>
                <a
                    href="https://niketech.service-now.com/nikenow/?id=nike_incident"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="https://niketech.service-now.com/nikenow/235fc7911311ba80aa617e276144b001.iix"
                        width="200"
                        alt="Nike Now"
                    />
                </a>
                <br />
                If you need help reporting a request you can find a detailed guide{' '}
                <a href="https://nike.box.com/s/nujlcb2tk0mzjs4zt4fl7auebsisuibt">here</a>.
            </p>
        </div>
    );
}

export function MancalaDocumentation() {
    return (
        <ContentWrapper>
            <div className="MancalaDocumentation">
                <Container>
                    <Row>
                        <Col xs={{ span: 8, offset: 4 }}>
                            <h1>DOCUMENTATION</h1>
                        </Col>
                        <Col xs={{ span: 3, offset: 1 }}>
                            <nav className="MancalaDocumentation__navigation">
                                <NavLink to="/mancala/documentation" exact>
                                    <h3>Mancala</h3>
                                </NavLink>
                                <hr />
                                <NavLink to="/mancala/documentation/support">Support</NavLink>
                            </nav>
                        </Col>
                        <Col xs={{ span: 8, offset: 0 }}>
                            <Switch>
                                <Route exact path="/mancala/documentation" component={Mancala} />
                                <Route path="/mancala/documentation/support" component={Support} />
                                <Route component={Mancala} />
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
}

export default withRouter(MancalaDocumentation);
