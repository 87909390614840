import { connect } from 'react-redux';
import { getOverviewColumns, getOverviewItems, setFilterFromQuery } from '../../actions/overview';
import { OverviewState } from '../../reducers/overview';
import withQueryParams from '../../hoc/withQueryParams';
import Overview from './Overview';

const mapActionCreators = {
    getOverviewColumns,
    getOverviewItems,
    setFilter: setFilterFromQuery,
};

const mapStateToProps = ({ overview }: { overview: OverviewState }) => ({
    activeFilters: overview.activeFilters,
    pages: overview.pages,
    itemsLoadingStatus: overview.loadingStatus.items,
    rows: overview.rows,
    items: overview.items,
    columns: overview.columns,
    pageNr: overview.pageNr,
});

export default connect(mapStateToProps, mapActionCreators)(withQueryParams(Overview));
