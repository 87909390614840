import React from 'react';

export default function () {
    return (
        <div>
            <h2>Missing Values</h2>
            <p>
                When doing reporting, it can happen that data is not available. Either because it was missing in the import,
                or it was not yet filled in by the user. This page gives an overview on how to handle these missing values.
            </p>
            <h3>Single Value</h3>
            <p>
                When a value is not used in a calculation and needs to be displayed as-is, a missing value is displayed as a
                dash.
            </p>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Value</th>
                        <th>Displayed as</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>100</td>
                        <td>100</td>
                    </tr>

                    <tr>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr>
                        <td>
                            <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
            <h3>Calculations</h3>

            <p>
                In calculations, a missing value will be treated as a zero. When diving by zero or all values of a
                calculation are missing, the result will be displayed as a dash.
            </p>

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Value</th>
                        <th>Displayed as</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>100 + 100</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>
                            100 + <code>missing</code>
                        </td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>
                            <code>missing</code> + <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>100 - 50</td>
                        <td>50</td>
                    </tr>
                    <tr>
                        <td>
                            100 - <code>missing</code>
                        </td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>
                            <code>missing</code> - <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>10 * 10</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>
                            100 * <code>missing</code>
                        </td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>
                            <code>missing</code> * <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>{' '}
                    <tr>
                        <td>
                            <code>missing</code> - <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>100 / 100</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>100 / 0</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>
                            100 / <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>
                            <code>missing</code> / <code>missing</code>
                        </td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
