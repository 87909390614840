import { useCallback } from 'react';
import { useRequest } from '../api/useRequest';
import { getActiveSeasonStatus } from '../services/activeSeasonStatusService';

export default (): [boolean, Error | undefined, boolean | undefined, boolean | undefined] => {
    const fetchSeasonStatus = useCallback(() => getActiveSeasonStatus(), []);
    const [loading, error, status] = useRequest(fetchSeasonStatus);

    let canEditBuyPlan: boolean | undefined;
    let hasOptionCountFile: boolean | undefined;
    if (status) {
        canEditBuyPlan = !status.hasMissingStoreTargets && status.hasHindsightFile;
        hasOptionCountFile = status.hasOptionCountFile;
    }

    return [loading, error, canEditBuyPlan, hasOptionCountFile];
};
