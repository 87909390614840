"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.launchFlagOptions = void 0;
exports.launchFlagOptions = {
    c: 'C',
    d: 'D',
    l: 'L',
    x: 'X',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    0: '0',
    empty: '',
};
