import { UserProfile } from 'buyplan-common';
import { UserSettings } from '../reducers/user';
import {
    COPY_PRINCIPAL_USER,
    REPLACE_PRINCIPAL_USER,
    REPLACE_USER_IN_ACTION,
    RESET_IMPERSONATION_STATUS,
} from './actionTypes';
import { AnyDispatch, GetRootState } from './types';

export const copyPrincipalUser = (settings: UserSettings, profile: UserProfile) =>
    ({
        type: COPY_PRINCIPAL_USER,
        settings,
        profile,
    } as const);

export type CopyPrincipalUserAction = ReturnType<typeof copyPrincipalUser>;

export const replaceUserInAction = (profile: UserProfile) =>
    ({
        type: REPLACE_USER_IN_ACTION,
        profile,
    } as const);

export type ReplaceUserInActionAction = ReturnType<typeof replaceUserInAction>;

export const replacePrincipalUser = (profile: UserProfile, settings: UserSettings) =>
    ({
        type: REPLACE_PRINCIPAL_USER,
        profile,
        settings,
    } as const);

export type ReplacePrincipalUserAction = ReturnType<typeof replacePrincipalUser>;

export const resetImpersonationStatus = () =>
    ({
        type: RESET_IMPERSONATION_STATUS,
    } as const);

export type ResetImpersonationStatusAction = ReturnType<typeof resetImpersonationStatus>;

export const startImpersonatingUser =
    (userToImpersonate: UserProfile) => (dispatch: AnyDispatch, getState: GetRootState) => {
        const { settings, profile } = getState().user;
        const { impersonationInProgress } = getState().impersonation;

        if (!impersonationInProgress) {
            dispatch(copyPrincipalUser(settings, profile));
            dispatch(replaceUserInAction(userToImpersonate));
        }
    };

export const stopImpersonatingUser = () => (dispatch: AnyDispatch, getState: GetRootState) => {
    const { impersonationInProgress, principalUser } = getState().impersonation;

    if (impersonationInProgress) {
        dispatch(replacePrincipalUser(principalUser.profile, principalUser.settings));
        dispatch({ type: RESET_IMPERSONATION_STATUS });
    }
};

export type ImpersonationActions =
    | CopyPrincipalUserAction
    | ReplaceUserInActionAction
    | ReplacePrincipalUserAction
    | ResetImpersonationStatusAction;
