import React, { ReactElement } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import cn from 'classnames';
import { BuyPlanAggregatedMaterial, BuyPlanStore } from 'buyplan-common';
import './BuyplanBuyTooltip.scss';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { TooltipType } from '../Tooltip/Tooltip';

interface Props {
    aggregatedMaterial: BuyPlanAggregatedMaterial;
    store: BuyPlanStore;
    children: ReactElement;
    type?: TooltipType;
}

/**
 * Displays the minimum buy size curve.
 *
 * Does not utilize /components/Tooltip because both styling and HTML content
 * are not supported in a bootstrap Tooltip; you must use a Popover instead.
 */
function BuyplanBuyTooltip({ children, aggregatedMaterial, store, type = TooltipType.default }: Props) {
    function renderPopoverComponent(props: OverlayInjectedProps) {
        const { minimumBuyMaterial, cGradeMinimumBuyMaterial, allocationUnits } = store;
        const { division, category, grade } = aggregatedMaterial;
        return (
            <Popover
                id="BuyplanBuyTooltip--popover" // required
                className={cn('BuyPlanPresentationStocksTooltip', `BuyPlanPresentationStocksTooltip--${type}`)}
                {...props}
            >
                <Popover.Content>
                    Minimum buy for:
                    <br />- {division}/{category}/{grade}: <strong>{minimumBuyMaterial ?? 'NO MATCH'}</strong>
                    {grade !== 'C' && (
                        <>
                            <br />- {division}/{category}/C: <strong>{cGradeMinimumBuyMaterial ?? 'NO MATCH'}</strong>
                        </>
                    )}
                    <br />- Allocations: {allocationUnits ? <strong>{allocationUnits}</strong> : 'n/a'}
                </Popover.Content>
            </Popover>
        );
    }

    return (
        <OverlayTrigger
            placement="right"
            overlay={(overlayProps: OverlayInjectedProps) => renderPopoverComponent(overlayProps)}
        >
            {children}
        </OverlayTrigger>
    );
}

export default BuyplanBuyTooltip;
