import React, { ReactNode } from 'react';
import './Navigation.scss';

interface Props {
    children: ReactNode;
}

function Navigation({ children }: Props) {
    return <nav className="navigation">{children}</nav>;
}

export default Navigation;
