"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hindsightMaterialSchema = void 0;
const materialTypes_1 = require("../types/materialTypes");
exports.hindsightMaterialSchema = {
    $id: 'https://buyplan.nike.com/hindsightMaterialSchema.json',
    $schema: 'http://json-schema.org/schema#',
    additionalProperties: false,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            format: 'uuid',
        },
        hindsightFileId: {
            type: 'string',
            minLength: 1,
            format: 'uuid',
        },
        materialCode: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        division: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        category: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        family: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        emeaFamily: {
            type: 'string',
            maxLength: 255,
        },
        model: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        description: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        colorDescription: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        style: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        yearType: {
            type: 'number',
            minimum: 0,
            maximum: 1,
        },
        age: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        gender: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        silhouette: {
            type: 'string',
            minLength: 0,
            maxLength: 255,
        },
        typeOfOrder: {
            type: 'string',
            minLength: 1,
            maxLength: 255,
        },
        segment: {
            type: 'string',
            transform: ['toEnumCase'],
            enum: Object.keys(materialTypes_1.Segment),
        },
        launchFlag: {
            type: ['string', 'null'],
            minLength: 0,
            maxLength: 3,
        },
        consumer: {
            type: 'string',
            transform: ['toEnumCase'],
            enum: Object.keys(materialTypes_1.Consumer),
        },
        brand: {
            type: 'string',
            transform: ['toEnumCase'],
            enum: Object.keys(materialTypes_1.Brand),
        },
        franchise: {
            type: 'string',
            maxLength: 255,
        },
        merchClass: {
            type: 'string',
            maxLength: 255,
        },
        consumerUse: {
            type: 'string',
            maxLength: 255,
        },
        emeaDimension: {
            type: 'string',
            maxLength: 255,
        },
        emeaSilo: {
            type: 'string',
            maxLength: 255,
        },
        emeaSubFranchise: {
            type: 'string',
            maxLength: 255,
        },
        emeaCollectionName: {
            type: 'string',
            maxLength: 255,
        },
        silhouetteType: {
            type: 'string',
            maxLength: 255,
        },
        thirdParty: {
            type: 'string',
            maxLength: 255,
        },
        fieldsOfPlay: {
            type: 'string',
            maxLength: 255,
        },
        features: {
            type: 'string',
            maxLength: 255,
        },
        emeaFootballPlates: {
            type: 'string',
            maxLength: 255,
        },
        emeaBusinessModel: {
            type: 'string',
            maxLength: 255,
        },
    },
    required: [
        'id',
        'hindsightFileId',
        'materialCode',
        'division',
        'category',
        'family',
        'model',
        'description',
        'colorDescription',
        'style',
        'yearType',
        'age',
        'gender',
        'silhouette',
        'typeOfOrder',
        'segment',
        'consumer',
        'brand',
    ],
};
