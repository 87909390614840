import { Link } from 'react-router-dom';
import React from 'react';
import planHindsightVariance from './images/PlanHindsightVariance.png';
import newPRY from './images/NewPRY.png';
import startFXRatesProcess from './images/StartFXProcess.png';
import newSnapshot from './images/NewSnapshot.png';
import applyFXRates from './images/ApplyFXRates.png';
import fxCalendar from './images/FXCalendar.png';
import fxComplete from './images/FXComplete.png';
import downloadReport from './images/DownloadReport.png';
import rollback from './images/Rollback.png';
import financeFxRates from './images/FinanceFXRates.png';
import buyplanRates from './images/BuyplanRates.png';

export default function () {
    return (
        <div className="ExchangeRatesDocumentation">
            <h2>Exchange rates</h2>
            <p>
                The exchange rates are also known as the <b>Fiscal Exchange Rates</b> or <b>FX Adjustment</b>.<br />
                The current exchange rates can be found on the: <Link to="/buyplan/exchange-rates">Exchange rates</Link>{' '}
                page.
            </p>
            <h3>Why and when we apply the FX adjustment</h3>
            <p>
                Every year in June, Nike updates to newer exchange rates. For Buyplan, there are two adjustments that are{' '}
                made:
            </p>
            <img src={planHindsightVariance} alt="Plan vs Hindsight and Variance" />
            <p>
                <b>PLAN:</b> The calculation of EUR/GBP/ILS/TRY RRP to USD AUR and Wholesale EURO to Wholesale USD needs
                capture the new FX adjustments.
            </p>
            <p>
                For example, SP25 is planned in May 2024 and utilizes FX24 rates. When the FY25 FX adjustments are announced{' '}
                in June 2024, the PLAN must now use these new rates.
            </p>
            <p>
                <b>HINDSIGHT (DIGITAL only):</b> To capture the correct variances, the HINDSIGHT must be adjusted with the{' '}
                new FX rates. HINDSIGHT is pulled from a reporting system that captures the local currency and converts it to{' '}
                USD using the FX rates from the time the data was pulled.
            </p>
            <p>
                For example, SP24 DIGITAL HINDSIGHT (Sales data) from the UK and the Netherlands are pulled as one sales{' '}
                number. The reporting system calculates local sales to USD using the FX24 rates. When the FY25 FX adjustments{' '}
                need to be applied, they are applied on a <u>combined sales number</u> consisting of multiple countries and{' '}
                local currencies. When the FX adjustment is applied, it must account for the conversion from the previous FX{' '}
                adjustment to the new FX adjustment, and it must also account for the mix of local currencies converted to{' '}
                USD.
            </p>
            <img src={fxCalendar} alt="Calendar of when exchange rates are received and applied" />
            <h3>How to input new exchange rates</h3>
            <p>Finance will provide the new exchange rates in the format shown below.</p>
            <img
                src={financeFxRates}
                alt="Exchange rates received from finance"
                className="ExchangeRatesDocumentation__smallImage"
            />
            <p>
                Once the rates have been provided, they can be entered in Buyplan. This must be done by a super user{' '}
                (Application.Buyplan.Emea.Superuser AD group).
            </p>
            <p>
                To enter the rates, Visit the <Link to="/buyplan/exchange-rates">Exchange rates</Link> page and click the{' '}
                <b>NEW PRY</b> button at the bottom of the page. This will open a form where you can enter the updated rates{' '}
                for the new PRY.
            </p>
            <img src={newPRY} alt="New PRY button" className="ExchangeRatesDocumentation__smallImage" />
            <p>The rates should be entered following the same format provided by finance.</p>
            <img
                src={buyplanRates}
                alt="Entering exchange rates in Buyplan"
                className="ExchangeRatesDocumentation__smallImage"
            />
            <p>
                Once you are confident that the rates you have entered are correct, it is time to apply them. Applying the{' '}
                rates is an intensive process that will involve overriding the prices and hindsight data for all seasons in{' '}
                the system.{' '}
                <b>
                    This process should occur at an agreed upon time with the planners, as the application will be
                    unavailable while the process is underway
                </b>
                . To start the process, click the <b>APPLY FX-RATES</b> button.
            </p>
            <img src={applyFXRates} alt="Apply FX Rates button" className="ExchangeRatesDocumentation__smallImage" />
            <p>
                <b>No updates can be made to the system while the rates are being applied.</b> A snapshot of the database{' '}
                will be taken at the beginning of the process in case you need to rollback the changes for any reason. For{' '}
                example, in case of any errors in the process, the final data, or in case an incorrect FX rate was applied.{' '}
                During the process, the system will display notifications to inform you of the progress.
            </p>
            <img
                src={startFXRatesProcess}
                alt="Notifications that FX Rates process has started"
                className="ExchangeRatesDocumentation__smallImage"
            />
            <img
                src={newSnapshot}
                alt="Buttons disabled and date of updated database snapshot"
                className="ExchangeRatesDocumentation__smallImage"
            />
            <p>
                Once the process is complete, you will receive a notification indicating{' '}
                <b>FX rates have been applied for the year</b> and the buttons on the page will be re-enabled. Click the{' '}
                <b>DOWNLOAD REPORT</b> button to see a full report of all of the processes that were run, and ensure that all{' '}
                of the critical processes were run successfully.
            </p>
            <img
                src={fxComplete}
                alt="Notification that FX Rates process has completed"
                className="ExchangeRatesDocumentation__smallImage"
            />
            <img src={downloadReport} alt="Download Report button" className="ExchangeRatesDocumentation__smallImage" />
            <h3>How to rollback in case of an error</h3>
            <p>
                After the rates have been applied, take some time to review the data in the system to ensure that everything{' '}
                looks correct. Check the updated TY/LY values in the tool and ensure that the values have been updated as you{' '}
                expect according to the new exchange rates.
            </p>
            <p>
                If anything looks incorrect, or in case the report indicates that something went wrong, you can rollback the{' '}
                changes using the <b>ROLLBACK FX-RATES</b> button. This process will revert the data in the system back to{' '}
                the moment before you applied the new exchange rates.
            </p>
            <img src={rollback} alt="Rollback button" className="ExchangeRatesDocumentation__smallImage" />
            <p>After the rollback comples, you can make any necessary changes to the rates and run the process again.</p>
        </div>
    );
}
