import React from 'react';
import './Highlight.scss';

interface Props {
    text: string;
    highlight?: string[];
}

interface TermPart {
    end: number;
    start: number;
}

export const getHighlightedChunks = (highlights: string[], text: string) => {
    let last: TermPart;

    return highlights
        .flatMap((highlightText) => {
            const indexes: TermPart[] = [];
            if (!highlightText) return indexes;

            const regex = new RegExp(highlightText, 'gi');
            const { length } = highlightText;
            let match;

            // eslint-disable-next-line no-cond-assign
            while ((match = regex.exec(text))) {
                indexes.push({ start: match.index, end: match.index + length });
            }

            return indexes;
        })
        .map((highlight) => {
            // Merge overlapping ranges into one long range:
            if (!last || highlight.start > last.end) {
                last = highlight;
                return highlight;
            }

            if (highlight.end > last.end) {
                last.end = highlight.end;
            }

            return null;
        })
        .filter(Boolean);
};

export const getHighlightedText = (chunks: (TermPart | null)[], text: string) => {
    if (!chunks.length) return text;

    const lastItem = chunks[chunks.length - 1];

    const highlighted = chunks.flatMap((chunk, index) => {
        const prevCharacter = chunks[index - 1];
        if (chunk) {
            const prefix = text.substring(prevCharacter?.end || 0, chunk.start);
            return [
                prefix,
                <span className="highlight" key={chunk.start}>
                    {text.substring(chunk.start, chunk.end)}
                </span>,
            ];
        }
        return null;
    });

    return [...highlighted, text.substring(lastItem?.end || 0, text.length)];
};

function Highlighter({ highlight = [], text }: Props) {
    const chunks = getHighlightedChunks(highlight, text);
    return <>{getHighlightedText(chunks, text)}</>;
}

export default Highlighter;
