import { Season } from 'buyplan-common';
import { useSelector } from '../store/reducers';

/**
 * Returns the season data for the active season
 */
const useActiveSeasonData = (): Season | null => {
    const { activeSeasonId } = useSelector(({ user }) => user.settings);
    const { allSeason } = useSelector((state) => state.season);

    if (!allSeason) {
        return null;
    }

    const activeSeasonData = allSeason.find(({ id }) => id === activeSeasonId);

    return activeSeasonData || null;
};

export default useActiveSeasonData;
