import { MancalaImportFile } from 'buyplan-common';
import { v4 } from 'uuid';
import { request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { uploadToStorage } from './uploadFileService';

export const createOrderlinesFileRecord = (addedFileUuid: string, fileName: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orderlinesfiles`,
        data: {
            addedFileUuid,
            fileName,
        },
    };

    return request<{ data: number }>(requestOptions);
};

export const importOrders = (
    destinationKey: string,
    fileId: number,
    fileName: string,
    isStaged = false,
    isDryRun = false
) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orderlines`,
        data: {
            destinationKey,
            fileId,
            fileName,
            isStaged,
            isDryRun,
        },
    };

    return request<MancalaImportFile[]>(requestOptions);
};

export const processOrderlines = async (acceptedFile: File, isStaged = false) => {
    const newFileUuid = v4();
    // Upload to S3 and add a status entry to the database
    const sanitizedFileName = sanitizeS3FileName(acceptedFile.name);
    const uniqueFileName = `${newFileUuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `orderlines-files/${uniqueFileName}`;

    const uploadFile = async () => {
        await uploadToStorage(
            acceptedFile,
            destinationKey,
            acceptedFile.type,
            acceptedFile.name.includes('no_upload') // Skip upload during Cypress tests
        );

        return createOrderlinesFileRecord(newFileUuid, acceptedFile.name);
    };

    const uploadFileResponse = await uploadFile();
    const response = await importOrders(
        destinationKey,
        uploadFileResponse.data,
        acceptedFile.name,
        isStaged,
        acceptedFile.name.includes('no_upload') // Do not wait for successful upload during Cypress tests
    );
    return {
        files: response,
        newFileUuid,
    };
};
