import React, { ReactNode } from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import cn from 'classnames';
import './Tooltip.scss';
import { OverlayInjectedProps, Placement } from 'react-bootstrap/Overlay';

export enum TooltipType {
    default = 'default',
    info = 'info',
    error = 'error',
    warning = 'warning',
    success = 'success',
    active = 'active',
}

interface Tooltip {
    tooltip: ReactNode;
    children: ReactNode;
    placement?: Placement;
    type?: TooltipType;
}

let i = 0;

function renderTooltipComponent(tooltip: ReactNode, type: TooltipType, placement: Placement, props: OverlayInjectedProps) {
    return (
        <BootstrapTooltip id={`tooltip-${i++}`} className={cn(`tooltip--${type}`, `tooltip--${placement}`)} {...props}>
            {tooltip}
        </BootstrapTooltip>
    );
}

function Tooltip({ tooltip, children, type = TooltipType.default, placement = 'bottom', ...otherProps }: Tooltip) {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={(props: OverlayInjectedProps) => renderTooltipComponent(tooltip, type, placement, props)}
            {...otherProps}
        >
            <span>{children}</span>
        </OverlayTrigger>
    );
}

export default Tooltip;
