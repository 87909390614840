import React from 'react';
import { Material } from 'buyplan-common';
import Dot, { DotType } from '../Dot/Dot';
import EditableCell from '../EditableCell/EditableCell';
import './MaterialCode.scss';

interface Props {
    materialCode: string;
    initialMaterialCode: string;
    isLateAdd: boolean;
    disabled?: boolean;
    onChange?: (attributes: Partial<Material>) => void;
}

function MaterialCode({ materialCode, initialMaterialCode, isLateAdd, disabled, onChange }: Props) {
    const isDummyCode = materialCode !== initialMaterialCode;
    return (
        <div className="material-code">
            <EditableCell
                materialCode={materialCode}
                columnKey="materialCode"
                value={materialCode}
                onChange={onChange}
                disabled={disabled}
            >
                {isLateAdd && <Dot tooltip="Is late add" placement="top" type={DotType.success} />}
                {isDummyCode && !isLateAdd && (
                    <Dot tooltip={`Initially ${initialMaterialCode}`} placement="top" type={DotType.info} />
                )}
            </EditableCell>
        </div>
    );
}

export default MaterialCode;
