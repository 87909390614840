import React, { memo } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { MancalaImportFile } from 'buyplan-common';
import StatusColumn, { getStatus } from './Columns/StatusColumn';
import MessageColumn from './Columns/MessageColumn';
import TitleColumn from './Columns/TitleColumn';
import './FileStatusTable.scss';

interface Props {
    items: MancalaImportFile[];
}

function FileStatusTable({ items }: Props) {
    return (
        <TransitionGroup className="file-status-table" component="ul">
            {items.map(({ Id, Status, Message, UploadDate, FileName }) => {
                const { type } = getStatus(Status);

                return (
                    <CSSTransition key={Id} timeout={500} classNames="file-status-table__item-animation">
                        <li className="file-status-table__item">
                            <div className="file-status-table__item-inner">
                                <div className="file-status-table__item-title">
                                    <TitleColumn filename={FileName} date={UploadDate} />
                                </div>
                                <div className="file-status-table__item-status">
                                    <StatusColumn statusCode={Status} />
                                </div>
                                {type === 'error' && Message && (
                                    <div className="file-status-table__item-message">
                                        <MessageColumn message={Message} />
                                    </div>
                                )}
                            </div>
                        </li>
                    </CSSTransition>
                );
            })}
        </TransitionGroup>
    );
}

export default memo(FileStatusTable);
