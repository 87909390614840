import React, { useEffect, useState } from 'react';
import { modelDashboardMaterialRowConfig, ModelDashboardMaterialRowConfig, ModelDashboardListItem } from 'buyplan-common';
import { isEqual } from 'lodash';
import usePrevious from '../../../helpers/usePrevious';
import ModelDashboardMaterialRow from './ModelDashboardMaterialRow';
import './ModelDashboardMaterialTable.scss';

interface Props {
    modelListData: ModelDashboardListItem[];
}

function ModelDashboardMaterialTable({ modelListData }: Props) {
    const previousList = usePrevious(modelListData);

    // Needed to correctly update the table (used as a key)
    const [updateValue, setUpdateValue] = useState(0);

    useEffect(() => {
        if (isEqual(previousList, modelListData)) {
            setUpdateValue(updateValue + 1);
        }
        // We don't want the exhaustive depts here, updating when updateValue updates would loop infinitely
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelListData, previousList]);

    return (
        <div className="model-dashboard-material-table__container">
            <table key={updateValue} cellPadding={0} cellSpacing={0} className="model-dashboard-material-table table">
                <thead>
                    <tr className="model-dashboard-material-table__header">
                        {modelDashboardMaterialRowConfig.map((configRow: ModelDashboardMaterialRowConfig) => {
                            const { key, label } = configRow;
                            return <th key={key}>{label}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {modelListData.map((listItem) => (
                        <ModelDashboardMaterialRow key={`${listItem.materialCode}`} listItem={listItem} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ModelDashboardMaterialTable;
