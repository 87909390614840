import { MaterialLockedForPartnerAssortment, AssortmentClusterOptionCounts, Material, ChannelRole } from 'buyplan-common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { uniq, difference } from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { updateMaterialsLockForPartnerAssortment } from '../../actions/assortment';
import { PARTNER_PORTAL_PARTNERS } from '../../constants/partnersConfig';
import './AssortmentDetailCheckbox.scss';
import useHasChannelRole from '../../selectors/useHasChannelRole';

export interface Props {
    lockedForPartnersAssortment: MaterialLockedForPartnerAssortment[];
    clusterOptionCounts: AssortmentClusterOptionCounts[];
    material: Material;
}

enum State {
    Locked,
    Unlocked,
}

function AssortmentLock({ lockedForPartnersAssortment, clusterOptionCounts, material }: Props) {
    const dispatch = useDispatch();
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);
    const allStoreOptionCounts = clusterOptionCounts.map(({ storeOptionCounts }) => storeOptionCounts).flat();

    const partners = uniq(allStoreOptionCounts.map(({ partner }) => partner)).filter((partner) =>
        PARTNER_PORTAL_PARTNERS.includes(partner)
    );

    const lockedPartners = lockedForPartnersAssortment.filter(({ isLocked }) => isLocked).map(({ partner }) => partner);

    const lockState = difference(partners, lockedPartners).length ? State.Unlocked : State.Locked;

    const handleChange = () => {
        if (!partners.length) return;

        const records = partners.map((partner) => ({
            partner,
            materialCode: material.materialCode,
            isLocked: !(lockState === State.Locked),
        }));
        dispatch(updateMaterialsLockForPartnerAssortment(records));
    };

    let lockIcon: JSX.Element | null;
    if (lockState === State.Locked) {
        lockIcon = <FontAwesomeIcon className="AssortmentLock__allLocked" size="lg" icon={faLock as IconProp} />;
    } else {
        lockIcon = <FontAwesomeIcon className="AssortmentLock__allUnlocked" size="lg" icon={faLockOpen as IconProp} />;
    }
    return (
        <div
            className={cn('AssortmentLock', 'AssortmentLock__lock', {
                'AssortmentLock__lock--disabled': isReadOnlyUser,
            })}
        >
            <button type="button" onClick={handleChange} disabled={isReadOnlyUser}>
                {lockIcon}
            </button>
        </div>
    );
}

export default AssortmentLock;
