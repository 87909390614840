import { UserSettings } from '../reducers/user';
import { useSelector } from '../store/reducers';

/**
 * Returns the settings of the active user
 */
const useUserSettings = (): UserSettings => {
    const { settings } = useSelector(({ user }) => user);

    return settings;
};

export default useUserSettings;
