import { Store, LoadingStatus } from 'buyplan-common';
import { GET_STORES, GET_STORES_FAILURE, GET_STORES_SUCCESS } from '../actions/actionTypes';
import { StoreActions } from '../actions/store';

interface StoresState {
    stores: Store[];
    loadingStatus: LoadingStatus;
}

export const initialState = {
    stores: [],
    loadingStatus: LoadingStatus.initial,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: StoresState = initialState, action: StoreActions): StoresState => {
    switch (action.type) {
        case GET_STORES:
            return { ...state, loadingStatus: LoadingStatus.loading };
        case GET_STORES_SUCCESS:
            return { ...state, stores: action.stores, loadingStatus: LoadingStatus.success };
        case GET_STORES_FAILURE:
            return { ...state, loadingStatus: LoadingStatus.error };
        default:
            return state;
    }
};
