import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { FamilyModelRowConfig } from 'buyplan-common';
import HeaderColumn from '../../HeaderColumn/HeaderColumn';

interface Props {
    scrollableColumnsWidth: number;
    columns: FamilyModelRowConfig[];
    headerType: 'fixed' | 'scrollable';
    allColumns: FamilyModelRowConfig[];
}

const getHeaderColumns = (columns: FamilyModelRowConfig[], headerType: string) =>
    columns.filter(({ type }) => type === headerType);

const filterHeaderColumns = (columns: FamilyModelRowConfig[], visibleColumns: FamilyModelRowConfig[]) =>
    columns.reduce((acc, currentColumn) => {
        const { key: columnKey, isGroupStart, width: currentColumnWidth } = currentColumn;
        if (!isGroupStart) return acc;

        const groupColumns = visibleColumns.filter(({ groupKey }) => groupKey === columnKey);
        const isCurrentColumnVisible = !!visibleColumns.find(({ key }) => key === columnKey);

        if (!groupColumns.length) {
            return isCurrentColumnVisible ? [...acc, currentColumn] : acc;
        }

        const groupWidth = groupColumns.reduce((totalWidth, { width }) => totalWidth + (width ?? 0), 0);
        const totalGroupWidth = isCurrentColumnVisible ? groupWidth + currentColumnWidth : groupWidth;

        return [
            ...acc,
            {
                ...currentColumn,
                width: totalGroupWidth,
            },
        ];
    }, [] as FamilyModelRowConfig[]);

function FamilyModelHeaderRow({ scrollableColumnsWidth, columns, headerType, allColumns }: Props) {
    const [columnsToDisplay, setColumnsToDisplay] = useState([] as FamilyModelRowConfig[]);

    useEffect(() => {
        const headerTypeColumns = getHeaderColumns(allColumns, headerType);
        const filteredColumns = filterHeaderColumns(headerTypeColumns, columns);
        setColumnsToDisplay(filteredColumns);
    }, [allColumns, columns, headerType]);

    return (
        <div style={{ width: scrollableColumnsWidth }}>
            {columnsToDisplay.map((rowConfig: FamilyModelRowConfig) => {
                const { groupStartHeader, key, noDivider, width } = rowConfig;

                return (
                    <HeaderColumn
                        key={`${key}-${kebabCase(groupStartHeader)}`}
                        className={cn('FamilyModelTable__header-data', `FamilyModelTable__header-data_${key}`, {
                            'FamilyModelTable__header-data__divider': !noDivider,
                        })}
                        style={{ width }}
                        label={groupStartHeader || ''}
                    />
                );
            })}
        </div>
    );
}

export default FamilyModelHeaderRow;
