import { MMXMaterialWithImage, DummyCodeMaterial, PagingMetadata, LateAddMaterial, Material } from 'buyplan-common';
import { request } from '../api/api';

export const getLateAdds = (signal: AbortSignal) =>
    request<{ data: LateAddMaterial[] }>(
        {
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/materials/lateadds`,
            signal,
        },
        true
    );

export const deleteMaterial = (materialCode: string) =>
    request<null>(
        {
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_ENDPOINT}/materials/${materialCode}`,
        },
        true
    );

export const getDummyCodes = (page: number, pageSize: number) => {
    const queryParams = { page, pageSize };
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/materials/dummycodes`;

    return request<{ data: DummyCodeMaterial[]; meta: PagingMetadata }>(
        {
            method: 'GET',
            url: endpoint,
            queryParams,
        },
        true
    );
};

export const getNumberOfDummyCodes = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/materials/dummycodes/count`,
    };
    return request(requestOptions, true);
};

export const verifyMaterialCodes = () =>
    request<{ totalUpdated: number }>(
        {
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/materials/verify`,
        },
        true
    );

export const lookupMaterialCode = (materialCode: string) =>
    request<{ data: MMXMaterialWithImage }>(
        {
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/materials/lookup/${encodeURIComponent(materialCode)}`,
        },
        true
    );

export const updateDummyCode = (id: string, data: { materialCode: string }) =>
    request<null>(
        {
            method: 'PATCH',
            url: `${process.env.REACT_APP_API_ENDPOINT}/materials/dummycodes/${id}`,
            data,
        },
        true
    );

export const patch = (id: string, data: Partial<Material>) =>
    request<null>(
        {
            method: 'PATCH',
            url: `${process.env.REACT_APP_API_ENDPOINT}/materials/${id}`,
            data,
        },
        true
    );
