import { round } from 'lodash';
import { TopStylesReportRow, TopStylesReportData, TopStylesReportTotals, sum } from 'buyplan-common';

export interface TopStylesReportTableData {
    rows: TopStylesReportRow[];
    totals: TopStylesReportTotals;
}
export const generateReportRows = (data: TopStylesReportData): TopStylesReportTableData => ({
    rows: data.items.map((row) => ({
        ...row,
        asp: row.unitSales ? row.revenue / row.unitSales : null,
        ttlRevenue: data.totals.revenue ? row.revenue / data.totals.revenue : null,
        ttlUnitSales: data.totals.revenue && data.totals.unitSales ? row.unitSales / data.totals.unitSales : null,
        ttlInvestUnits: data.totals.revenue && data.totals.investUnits ? row.investUnits / data.totals.investUnits : null,
    })),
    totals: data.totals,
});

export const calculateAverageSellingPrice = (results: TopStylesReportTableData, nrOfRows?: number) => {
    // When nrOfRows is undefined, it means all rows should be considered.
    if (nrOfRows === undefined) {
        // When there are no records in the Buy Plan or Hindsight for the current filter, the values for the totals will be null.
        if (results.totals.unitSales === null || results.totals.unitSales === 0 || results.totals.revenue === null) {
            return null;
        }
        return results.totals.revenue / results.totals.unitSales;
    }
    const slicedResults = nrOfRows ? results.rows.slice(0, nrOfRows) : results.rows;
    const revenueTotal = slicedResults.map(({ revenue }) => revenue).reduce(sum, 0);
    const unitSalesTotal = slicedResults.map(({ unitSales }) => unitSales).reduce(sum, 0);
    return unitSalesTotal === 0 ? 0 : round(revenueTotal / unitSalesTotal, 2);
};

export const calculateSellThroughTotal = (results: TopStylesReportTableData, isLastYear: boolean, nrOfRows?: number) => {
    if (nrOfRows === 0 || nrOfRows === undefined) {
        return results.totals.sellThrough ?? 0;
    }
    // get the data needed to calculate sellThrough
    const data = results.rows.map((row) => ({
        salesUnits: row.unitSales ?? 0,
        presentationStocks: row[isLastYear ? 'investUnits' : 'presentationStocks'] ?? 0,
    }));
    const slicedData = nrOfRows ? data.slice(0, nrOfRows) : data;
    // add up the totals for sales units & presentation stocks, then get total ST%
    const totals = slicedData.reduce(
        (prev, curr) => ({
            salesUnits: prev.salesUnits + curr.salesUnits,
            presentationStocks: prev.presentationStocks + curr.presentationStocks,
        }),
        { salesUnits: 0, presentationStocks: 0 } as { salesUnits: number; presentationStocks: number }
    );
    return totals.presentationStocks === 0 ? 0 : totals.salesUnits / totals.presentationStocks;
};

export const calculateTotal = (
    results: TopStylesReportTableData,
    prop: 'unitSales' | 'revenue' | 'investUnits' | 'presentationStocks' | 'openToBuyUnits',
    nrOfRows?: number
) => {
    if (nrOfRows === 0 || nrOfRows === undefined) {
        return results.totals[prop] ?? 0;
    }
    const data = results.rows.map((row) => row[prop] ?? 0);
    const slicedData = nrOfRows ? data.slice(0, nrOfRows) : data;
    return slicedData.reduce(sum, 0);
};

export const calculateTotalPercentage = (
    results: TopStylesReportTableData,
    prop: 'unitSales' | 'revenue' | 'investUnits',
    nrOfRows?: number
) => {
    const sliceTotal = calculateTotal(results, prop, nrOfRows) ?? 0;
    const total = calculateTotal(results, prop) ?? 0;
    return total === 0 ? 0 : sliceTotal / total;
};

export const calculateTotalVariance = (
    results: TopStylesReportTableData,
    previousYear: TopStylesReportTableData,
    prop: 'unitSales' | 'revenue' | 'sellThrough' | 'investUnits',
    nrOfRows?: number
) => {
    const thisYearTotal =
        prop === 'sellThrough'
            ? calculateSellThroughTotal(results, false, nrOfRows) ?? 0
            : calculateTotal(results, prop, nrOfRows) ?? 0;
    const previousYearTotal =
        prop === 'sellThrough'
            ? calculateSellThroughTotal(previousYear, true, nrOfRows) ?? 0
            : calculateTotal(previousYear, prop, nrOfRows) ?? 0;
    let variance = round(((thisYearTotal - previousYearTotal) / previousYearTotal) * 100, 1);

    if (previousYearTotal === 0) {
        variance = 0;
    } else if (thisYearTotal === 0) {
        variance = -100;
    }
    return variance;
};

export const calculateAggregateTotalVariance = (
    results: TopStylesReportTableData,
    previousYear: TopStylesReportTableData,
    prop: 'unitSales' | 'revenue' | 'investUnits',
    nrOfRows?: number
) => {
    const thisYearTotal = calculateTotalPercentage(results, prop, nrOfRows);
    const previousYearTotal = calculateTotalPercentage(previousYear, prop, nrOfRows);
    let variance = round(((thisYearTotal - previousYearTotal) / previousYearTotal) * 100, 1);

    if (previousYearTotal === 0) {
        variance = 0;
    } else if (thisYearTotal === 0) {
        variance = -100;
    }
    return variance;
};

export const calculateAverageSellingPriceVariance = (
    results: TopStylesReportTableData,
    previousYear: TopStylesReportTableData,
    nrOfRows?: number
) => {
    const thisYearTotal = calculateAverageSellingPrice(results, nrOfRows) ?? 0;
    const previousYearTotal = calculateAverageSellingPrice(previousYear, nrOfRows) ?? 0;
    if (previousYearTotal === 0) {
        return 0;
    }
    if (thisYearTotal === 0) {
        return -100;
    }
    return round(((thisYearTotal - previousYearTotal) / previousYearTotal) * 100, 1);
};

export function createReport(
    thisYearData: TopStylesReportData,
    lastYearData: TopStylesReportData,
    lastLastYearData: TopStylesReportData
) {
    const thisYearReportRows = generateReportRows(thisYearData);
    const lastYearReportRows = generateReportRows(lastYearData);
    const lastLastYearReportRows = generateReportRows(lastLastYearData);

    return {
        thisYearReport: thisYearReportRows,
        lastYearReport: lastYearReportRows,
        lastLastYearReport: lastLastYearReportRows,
    };
}
