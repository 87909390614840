"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadingStatus = void 0;
var LoadingStatus;
(function (LoadingStatus) {
    LoadingStatus["initial"] = "INITIAL";
    LoadingStatus["loading"] = "LOADING";
    LoadingStatus["success"] = "SUCCESS";
    LoadingStatus["error"] = "ERROR";
})(LoadingStatus || (exports.LoadingStatus = LoadingStatus = {}));
