import React from 'react';
import { MinimumBuyFileErrors } from 'buyplan-common';
import SourceFileError from './SourceFileError';

interface Props {
    errors: MinimumBuyFileErrors;
}

function MinimumBuyFileErrors({ errors }: Props) {
    const { missingHeaders, missingData, duplicatesInFile, mimimumBuyInvalidNumber } = errors;

    return (
        <>
            {missingHeaders && (
                <SourceFileError
                    title="The following required headers are missing in the uploaded file:"
                    items={missingHeaders}
                    itemType="header"
                />
            )}
            {missingData && (
                <SourceFileError
                    title="The following rows are missing some required fields:"
                    items={missingData.map(
                        (minimumBuy) => `Row ${minimumBuy.rowNumber}: ${minimumBuy.emptyProperties.join(', ')}`
                    )}
                />
            )}
            {duplicatesInFile && (
                <SourceFileError
                    title="This file contains duplicate entries for the following combinations:"
                    items={duplicatesInFile}
                />
            )}
            {mimimumBuyInvalidNumber && (
                <SourceFileError
                    title="The following rows have an invalid value for Partner Confirmed Minimums:"
                    items={mimimumBuyInvalidNumber.map((minimumBuy) => `Row ${minimumBuy.rowNumber}`)}
                />
            )}
        </>
    );
}

export default MinimumBuyFileErrors;
