import React, { ReactNode } from 'react';
import { AssortmentColumnConfig, Material } from 'buyplan-common';
import { identity } from 'lodash';
import { getColumn, generateCellStyle } from '../../helpers/tableTools';
import { hasKey } from '../../helpers/typeGuards';
import EditableCell from '../EditableCell/EditableCell';

interface Props {
    columnKey: string;
    material: Material;
    columns: AssortmentColumnConfig[];
    channelId: number;
    children?: ReactNode[];
    disabled?: boolean;
    onChange?: (attributes: Partial<Material>) => void;
}

function ViewAssortmentCell({ columns, columnKey, material, children, disabled, onChange }: Props) {
    const column = getColumn(columns, columnKey);
    const hasChildren = children && children.some(identity);
    const title = !hasChildren && hasKey(material, columnKey) ? material[columnKey] : '';
    const renderedValue = column.renderValue && column.renderValue(material);
    const value = column.renderValue ? renderedValue : title;

    return (
        <div {...generateCellStyle('ViewAssortmentRow', columnKey, columns)} title={`${title}`}>
            {!hasChildren && (
                <EditableCell
                    materialCode={material.materialCode}
                    columnKey={columnKey}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                />
            )}
            {children}
        </div>
    );
}

export default ViewAssortmentCell;
