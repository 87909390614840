import React from 'react';
import cn from 'classnames';
import { TypeOfOrderBreakdownReport, typeOfOrderRowsConfig } from 'buyplan-common';
import TypeOfOrderReportRow from './TypeOfOrderReportRow';
import './TypeOfOrderReportTable.scss';

interface Props {
    report: TypeOfOrderBreakdownReport[];
    tableId: string;
    syncTableHorizontalScrolls: (e: React.UIEvent<HTMLElement>) => void;
}

function TypeOfOrderReportTable({ report, tableId, syncTableHorizontalScrolls }: Props) {
    const totals = report[0]; // First element of the TypeOfOrderBreakdownReport[] is totals.
    const reportWithoutTotals = report.slice(1, report.length);

    return (
        <div
            className="typeOfOrderReportTable__container"
            id={`typeOfOrder-${tableId}`}
            onScroll={syncTableHorizontalScrolls}
        >
            <table cellPadding={0} cellSpacing={0} className="typeOfOrderReportTable table">
                <thead>
                    <tr className="typeOfOrderReportTable__header">
                        <th
                            key="header-typeOfOrderReport"
                            className="typeOfOrderReportTable__header"
                            colSpan={1}
                            style={{ width: '100px' }}
                        >
                            Type Of Order
                        </th>
                        {typeOfOrderRowsConfig.map((rowConfig) => {
                            const { groupStartHeader, headerColSpan, headerColWidth, key } = rowConfig;

                            if (groupStartHeader) {
                                return (
                                    <th
                                        key={key}
                                        className="typeOfOrderReportTable__header-data"
                                        colSpan={headerColSpan}
                                        style={{ width: `${headerColWidth}px` }}
                                    >
                                        {groupStartHeader}
                                    </th>
                                );
                            }

                            return null;
                        })}
                    </tr>
                    <tr className="typeOfOrderReportTable__subheader">
                        <th />
                        {typeOfOrderRowsConfig.map((rowConfig) => (
                            <th key={rowConfig.key}>{rowConfig.label}</th>
                        ))}
                    </tr>
                    <tr className="typeOfOrderReportTable__subheader-totals">
                        <th />
                        {typeOfOrderRowsConfig.map((rowConfig) => {
                            const { key } = rowConfig;
                            const totalValue = totals[key as keyof typeof totals] as number | null;
                            return (
                                <th
                                    key={key}
                                    className={cn({
                                        'typeOfOrderReportTable__subheader-totals--negative': (totalValue ?? 0) < 0,
                                    })}
                                >
                                    {rowConfig.getValue(totalValue, false)}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {reportWithoutTotals.map((row) => (
                        <TypeOfOrderReportRow key={`${row.typeOfOrder}`} row={row} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TypeOfOrderReportTable;
