import React from 'react';
import { Link } from 'react-router-dom';

export default function () {
    return (
        <div>
            <h2>Prices</h2>
            <p>
                Prices in the Buyplan are pulled dynamically from the{' '}
                <a
                    href="https://confluence.nike.com/pages/viewpage.action?spaceKey=EDAEDP&title=Country+Price"
                    target="_blank"
                    rel="noreferrer"
                >
                    Country Price API
                </a>
                .
            </p>
            <p>
                In case a price is not found by the API (for example, if the material has a Dummy Code, or if the price is
                not yet available for the current season), then Buyplan will default to the prices provided in the
                Merchandise file upload. In this case, <strong>RETAIL PRICE EURO</strong>,{' '}
                <strong>WHOLESALE COST EURO</strong>, <strong>RETAIL PRICE GBP</strong> and{' '}
                <strong>WHOLESALE COST GBP</strong> columns will be referenced. These prices will also be converted to USD
                prices where required using the exchange rates found on the{' '}
                <Link to="/buyplan/exchange-rates">Exchange rates</Link> page.
            </p>
            <p>
                In Buyplan, all EUR prices throughout the application are based on Netherlands EUR prices. USD prices are
                based on Netherlands EUR to USD conversion, with the exception of USD prices for the following NDS partners.
                USD prices for these partners are based on the local currency of the stores associated with that partner:
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Partner</th>
                        <th scope="col">Local Currency</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">FOX WIZEL LTD</th>
                        <td>ILS</td>
                    </tr>
                    <tr>
                        <th scope="row">UPANDRUN</th>
                        <td>RUB</td>
                    </tr>
                    <tr>
                        <th scope="row">BARCIN SPOR</th>
                        <td>TRY</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
