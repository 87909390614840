import React, { useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRequest } from '../../api/useRequest';
import { getPartners } from '../../services/assortmentService';
import { useSelector } from '../../store/reducers';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';
import './ExportAssortment.scss';

function ExportAssortment() {
    const accessToken = useSelector(({ user }) => user.accessToken);
    const channelId = useSelector(({ user }) => user.settings.activeChannelId);

    const fetchPartners = useCallback(() => getPartners(), []);
    const [loading, error, partners] = useRequest(fetchPartners);

    const activeSeasonId = useSelector((state) => state.user.settings.activeSeasonId);

    const createNotAssortedUrl = (partner: string) =>
        // eslint-disable-next-line max-len
        `${process.env.REACT_APP_API_ENDPOINT}/assortment/export/${partner}?activeSeason=${activeSeasonId}&channelId=${channelId}&includeNonAssorted=true&Authorization=Bearer ${accessToken}`;

    const createAssortedUrl = (partner: string) =>
        `${process.env.REACT_APP_API_ENDPOINT}/assortment/export/${partner}?activeSeason=${activeSeasonId}&channelId=${channelId}&Authorization=Bearer ${accessToken}`;

    return (
        <Container>
            <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                    <div className="ExportAssortment">
                        <h1>DOWNLOAD ASSORTMENT {loading && <Loader width={16} />}</h1>
                        <ErrorMessage error={error} />
                        <ul>
                            {partners?.map(({ partner }) => (
                                <li key={partner}>
                                    <h3>{partner}</h3>
                                    <a href={createAssortedUrl(partner)} className="Button" download>
                                        <FontAwesomeIcon icon={faFileDownload as IconProp} />
                                        Download assorted
                                    </a>
                                    <a href={createNotAssortedUrl(partner)} className="Button" download>
                                        <FontAwesomeIcon icon={faFileDownload as IconProp} />
                                        Download everything
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ExportAssortment;
