import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';
import { Material } from 'buyplan-common';
import { DEFAULT_IMAGE, getMaterialImageUrl } from './getMaterialImageUrl';
import './MaterialImage.scss';

interface Props {
    material: Pick<Material, 'materialCode' | 'description'>;
}

function MaterialImage({ material }: Props) {
    const [imageUrl, setImageUrl] = useState(DEFAULT_IMAGE);

    useEffect(() => {
        setImageUrl(getMaterialImageUrl(material));
    }, [material]);

    const handleImageError = () => {
        if (imageUrl !== DEFAULT_IMAGE) {
            // eslint-disable-next-line no-console
            console.error(`Image failed to load: ${imageUrl}`);
            setImageUrl(DEFAULT_IMAGE);
        }
    };

    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Popover id={material.materialCode} className="MaterialImage__popover">
                    <Popover.Content>
                        <img
                            src={imageUrl}
                            loading="lazy"
                            alt={material.description}
                            className={cn({ 'MaterialImage__default-image': imageUrl === DEFAULT_IMAGE })}
                            onError={handleImageError}
                        />
                    </Popover.Content>
                </Popover>
            }
        >
            <img
                src={imageUrl}
                alt={material.description}
                className="MaterialImage__thumbnail"
                loading="lazy"
                width="35"
                height="35"
                onError={handleImageError}
            />
        </OverlayTrigger>
    );
}

export default memo(MaterialImage);
