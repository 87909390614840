import { SourceFile as SourceFileType, SourceFileErrors as SourceFileErrorsType } from 'buyplan-common';

export const addFileUploadAction = (fileId: string, file: File) => ({ type: 'ADD_FILE_UPLOAD' as const, fileId, file });

export const removeFileUploadAction = (fileId: string) => ({ type: 'REMOVE_FILE_UPLOAD' as const, fileId });

export const updateFileProgressAction = (fileId: string, progress: number | undefined) => ({
    type: 'UPDATE_FILE_PROGRESS' as const,
    fileId,
    progress,
});

export const updateFileErrorsAction = (fileId: string, errors: SourceFileErrorsType) => ({
    type: 'UPDATE_FILE_ERRORS' as const,
    fileId,
    errors,
});

type UploadingFileActions =
    | ReturnType<typeof addFileUploadAction>
    | ReturnType<typeof removeFileUploadAction>
    | ReturnType<typeof updateFileProgressAction>
    | ReturnType<typeof updateFileErrorsAction>;

interface UploadingFilesState {
    uploadingFiles: SourceFileType[];
}

export const uploadingFilesReducer = (state: UploadingFilesState, action: UploadingFileActions): UploadingFilesState => {
    switch (action.type) {
        case 'ADD_FILE_UPLOAD':
            return {
                uploadingFiles: [
                    {
                        id: action.fileId,
                        filesize: action.file.size,
                        filename: action.file.name,
                        progress: 0,
                    },
                    ...state.uploadingFiles,
                ],
            };
        case 'REMOVE_FILE_UPLOAD':
            return {
                uploadingFiles: state.uploadingFiles.filter((file) => file.id !== action.fileId),
            };
        case 'UPDATE_FILE_PROGRESS':
            return {
                uploadingFiles: state.uploadingFiles.map((file) =>
                    file.id === action.fileId
                        ? {
                              ...file,
                              progress: action.progress,
                          }
                        : file
                ),
            };
        case 'UPDATE_FILE_ERRORS':
            return {
                uploadingFiles: state.uploadingFiles.map((file) =>
                    file.id === action.fileId
                        ? {
                              ...file,
                              progress: undefined,
                              errors: action.errors,
                          }
                        : file
                ),
            };
        default:
            return state;
    }
};
