import React from 'react';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import './FileDropzone.scss';

interface Props {
    acceptedMimeTypes: string;
    title: React.ReactNode | string;
    onDrop?(acceptedFiles: File[]): void;
    multiple?: boolean;
    isDisabled?: boolean;
}

function FileDropzone({ acceptedMimeTypes, onDrop, title, multiple = false, isDisabled = false }: Props) {
    return (
        <Dropzone accept={acceptedMimeTypes} multiple={multiple} onDrop={onDrop} disabled={isDisabled}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                const rootProps = getRootProps({
                    className: cn('FileDropzone', {
                        'FileDropzone--active': isDragActive,
                        'FileDropzone--reject': isDragReject,
                        'FileDropzone--disabled': isDisabled,
                    }),
                });
                return (
                    <div {...rootProps}>
                        <input {...getInputProps()} />
                        {title && title}
                    </div>
                );
            }}
        </Dropzone>
    );
}

export default FileDropzone;
