import React, { CSSProperties } from 'react';
import cn from 'classnames';
import './Loader.scss';

interface Props {
    width?: number;
    strokeWidth?: number;
    speed?: number;
    style?: CSSProperties;
    className?: string;
}

function Loader({ style = {}, className, width = 120, speed = 1500, strokeWidth = 5, ...props }: Props) {
    // pad the rect so the circle is not cut off
    const size = width * 2.5;

    // radius = 1/2 size of viewbox
    const radius = size / 2;
    const s = `${speed}ms`;

    // NOTE - style can be overwritten with the exception of width/height - use width prop
    return (
        <div
            {...props}
            className={cn('svg-loader', className)}
            style={{
                ...style,
                width,
                height: width,
            }}
        >
            <svg width={width} height={width} viewBox={`0 0 ${size} ${size}`} preserveAspectRatio="xMidYMid">
                <rect x="0" y="0" width={size} height={size} fill="none" />
                <circle
                    cx={`${radius}`}
                    cy={`${radius}`}
                    r={width}
                    strokeDasharray={`${size * 2} ${radius}`}
                    stroke="currentColor"
                    fill="none"
                    strokeWidth={strokeWidth}
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        values={`0 ${radius} ${radius};180 ${radius} ${radius};360 ${radius} ${radius};`}
                        keyTimes="0;0.5;1"
                        dur={s}
                        repeatCount="indefinite"
                        begin="0s"
                    />
                </circle>
            </svg>
        </div>
    );
}

export default Loader;
