import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import { isEqual } from 'lodash';
import cn from 'classnames';
import qs from 'qs';
import { MainFilters, FamilyModelReportType, NewRelicEventName, NewRelicEventStatus } from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from '../../../store/reducers';
import { useRequestFunction } from '../../../api/useRequest';
import usePrevious from '../../../helpers/usePrevious';
import Button from '../../Button/Button';
import {
    getFamilyBreakdownReport,
    getModelBreakdownReport,
    getModelWMaterialsBreakdownReport,
} from '../../../services/buyPlanReportsService';
import { getMissingMainFilterKeys } from '../../../helpers/utils';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { sendCustomNewRelicEvent } from '../../../actions/user';
import { PageView } from '../../../constants/appConfig';
import SettingsButton from '../../SettingsButton/SettingsButton';
import FamilyModelSettings from './FamilyModelSettings';
import FamilyModelTabs from './FamilyModelTabs';
import './FamilyModelTabs.scss';

// Manage a singleton abort controller outside component; we don't want it to cause a re-render
let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

interface Props {
    filters: MainFilters;
    channelId: number;
    showDownloadButton?: boolean;
    showFamily?: boolean;
    showModel?: boolean;
    showModelMaterial?: boolean;
    isButtonDisabled: boolean;
    isMetaLoading: boolean;
}

function FamilyModelTabsContainer({
    filters,
    channelId,
    showDownloadButton = true,
    showFamily = true,
    showModel = true,
    showModelMaterial = true,
    isButtonDisabled,
    isMetaLoading,
}: Props) {
    const dispatch = useDispatch();
    const currentTab = showFamily ? 'family' : 'model';
    const [tab, setTab] = useState<FamilyModelReportType>(currentTab);
    const previousTab = usePrevious(tab);
    const { accessToken } = useSelector(({ user }) => user);
    const activeSeasonId = useSelector((state) => state.user.settings.activeSeasonId);
    const previousMainFilters = usePrevious(filters);
    const [isButtonPristine, setIsButtonPristine] = useState(true);
    const missingFiltersKeys = getMissingMainFilterKeys(
        filters,
        reportsFiltersConfig[PageView.familyModelReport].requiredFilters
    );

    const [loadingFamily, errorFamily, familyReport, fetchFamilyBreakdown] = useRequestFunction(getFamilyBreakdownReport);
    const [loadingModel, errorModel, modelReport, fetchModelBreakdown] = useRequestFunction(getModelBreakdownReport);
    const [loadingModelWithMaterials, errorModelWithMaterials, modelWithMaterialsReport, fetchModelWMaterialsBreakdown] =
        useRequestFunction(getModelWMaterialsBreakdownReport);
    const [isSettingsOpen, setSettingsOpen] = useState(false);

    useEffect(() => {
        if (missingFiltersKeys.length === 0 && !isEqual(previousMainFilters, filters)) {
            setIsButtonPristine(true);
        }
    }, [filters, previousMainFilters, tab, previousTab, missingFiltersKeys, channelId]);

    useEffect(() => {
        // Send a second event to New Relic to mark when the loading has stopped due to an error
        if (errorFamily || errorModel || errorModelWithMaterials) {
            dispatch(sendCustomNewRelicEvent(NewRelicEventName.loadFamilyModelReport, NewRelicEventStatus.error));
        }
    }, [dispatch, errorFamily, errorModel, errorModelWithMaterials]);

    useEffect(() => {
        // Send a second event to New Relic to mark when the report data is available and loading is complete
        if (familyReport !== null && modelReport !== null && modelWithMaterialsReport !== null) {
            dispatch(sendCustomNewRelicEvent(NewRelicEventName.loadFamilyModelReport, NewRelicEventStatus.end));
        }
    }, [dispatch, familyReport, modelReport, modelWithMaterialsReport]);

    const loadReports = () => {
        dispatch(sendCustomNewRelicEvent(NewRelicEventName.loadFamilyModelReport, NewRelicEventStatus.start));
        resetAbortController();
        setIsButtonPristine(false);
        fetchFamilyBreakdown(filters, abortController.signal);
        fetchModelBreakdown(filters, abortController.signal);
        fetchModelWMaterialsBreakdown(filters, abortController.signal);
    };

    // If filters are missing or if there are no results
    const enableDownload =
        missingFiltersKeys.length === 0 &&
        !isButtonPristine &&
        ((!loadingFamily && !!familyReport?.report.length) ||
            (!loadingModel && !!modelReport?.report.length) ||
            (!loadingModelWithMaterials && !!modelWithMaterialsReport?.report.length));

    const toggleSettings = useCallback(() => {
        setSettingsOpen(!isSettingsOpen);
    }, [setSettingsOpen, isSettingsOpen]);

    return (
        <>
            <div className="family-model-tabs--filter-container">
                <Button
                    className="family-model-tabs--load-report-button"
                    disabled={!isButtonPristine || isButtonDisabled || missingFiltersKeys.length > 0}
                    onClick={loadReports}
                >
                    LOAD REPORT
                </Button>
            </div>
            <div className="family-model-tabs--action-bar">
                {showDownloadButton && !isButtonPristine && (
                    <a
                        href={`${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/export/?${qs.stringify({
                            filter: filters,
                            activeSeason: activeSeasonId,
                            channelId,
                            type: 'family',
                            Authorization: `Bearer ${accessToken}`,
                        })}`}
                        className={cn('Button', 'family-model-tabs__download-button', {
                            'family-model-tabs__download-button--disabled': !enableDownload,
                        })}
                        download
                    >
                        <FontAwesomeIcon
                            size="lg"
                            className="family-model-tabs__download-button-icon"
                            icon={faDownload as IconProp}
                        />
                        Download report in Excel
                    </a>
                )}
                <SettingsButton onClick={toggleSettings} />
                {isSettingsOpen && <FamilyModelSettings onClose={toggleSettings} />}
            </div>
            <FamilyModelTabs
                missingFilters={missingFiltersKeys}
                onChangeTab={setTab}
                loadingFamily={loadingFamily}
                loadingModel={loadingModel}
                loadingModelWithMaterials={loadingModelWithMaterials}
                familyReport={familyReport}
                modelReport={modelReport}
                modelWithMaterialsReport={modelWithMaterialsReport}
                errorFamilyReport={errorFamily}
                errorModelReport={errorModel}
                errorModelWithMaterialsReport={errorModelWithMaterials}
                showFamily={showFamily}
                showModel={showModel}
                showModelMaterial={showModelMaterial}
                channelId={channelId}
                filtersHaveChanged={isButtonPristine}
                isMetaLoading={isMetaLoading}
            />
        </>
    );
}

export default memo(FamilyModelTabsContainer);
