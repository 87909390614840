import { round } from 'lodash';
import React from 'react';
import { NumberFormatProps } from 'react-number-format';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';

interface Props {
    value: number;
    onChange(value: number): void;
}

export const MIN_AF_PERCENTAGE = 0;
export const MAX_AF_PERCENTAGE = 100;

function AFPercentageInput({ value, onChange, ...props }: Omit<NumberFormatProps, 'onChange'> & Props) {
    return (
        <EnhancedNumberFormat
            {...props}
            value={round(value * 100, 2)}
            suffix="%"
            decimalScale={1}
            step={1}
            minimum={MIN_AF_PERCENTAGE}
            maximum={MAX_AF_PERCENTAGE}
            clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
            onChange={(newValue) => {
                if (newValue !== null && !Number.isNaN(newValue) && newValue >= 0) {
                    onChange(round(newValue / 100, 2));
                }
            }}
        />
    );
}

export default AFPercentageInput;
