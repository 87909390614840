import React from 'react';
import { NavLink } from 'react-router-dom';
import useSeasonSettings from '../../../selectors/useSeasonSettings';
import useIsChannelAdmin from '../../../selectors/useIsChannelAdmin';
import useUserSettings from '../../../selectors/useUserSettings';
import useUserProfile from '../../../selectors/useUserProfile';
import useIsSuperUser from '../../../selectors/useIsSuperUser';

function AssortmentSubnavigation() {
    const { activeChannelId } = useUserSettings();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);
    const isSuperUser = useIsSuperUser();
    const isLateAddsAndDummyCodeAvailable = isSuperUser || isChannelAdmin;
    const profile = useUserProfile();
    const { isHistoricalSeason } = useSeasonSettings();

    if (!profile) {
        return null;
    }
    return (
        <ul className="buyplan-navigation__assortment-dropdown">
            <li>
                <NavLink to="/buyplan/manage-assortment" exact>
                    <span>Manage assortment</span>
                </NavLink>
            </li>
            {isChannelAdmin && !isHistoricalSeason && (
                <li>
                    <NavLink to="/buyplan/manage-assortment/import" exact>
                        <span>Import assortment</span>
                    </NavLink>
                </li>
            )}
            {isLateAddsAndDummyCodeAvailable && (
                <>
                    <li>
                        <NavLink to="/buyplan/manage-assortment/late-adds">
                            <span>Manage late adds</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/buyplan/manage-assortment/dummy-codes">
                            <span>Replace dummy codes</span>
                        </NavLink>
                    </li>
                </>
            )}
            <li>
                <NavLink to="/buyplan/manage-assortment/viewing-option-counts">
                    <span>Viewing Option counts</span>
                </NavLink>
            </li>
        </ul>
    );
}

export default AssortmentSubnavigation;
