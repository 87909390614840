import React from 'react';
import { NavLink } from 'react-router-dom';
import { channels } from 'buyplan-common';
import { useSelector } from '../../../store/reducers';

function ReportsSubnavigation() {
    const { activeChannelId } = useSelector((state) => state.user.settings);

    return (
        <ul className="buyplan-navigation__reports-dropdown">
            {activeChannelId !== channels.digital.id && (
                <li>
                    <NavLink to="/buyplan/option-counts">Option counts</NavLink>
                </li>
            )}
            <li>
                <NavLink to="/buyplan/family-model">Family / model</NavLink>
            </li>
            <li>
                <NavLink to="/buyplan/category-division">Category / Division</NavLink>
            </li>
            <li>
                <NavLink to="/buyplan/top-styles-report">Top 15 styles</NavLink>
            </li>
            <li>
                <NavLink to="/buyplan/store-level-report">Store level</NavLink>
            </li>
            <li>
                <NavLink to="/buyplan/type-of-order">Type Of Order</NavLink>
            </li>
            <li>
                <NavLink to="/buyplan/silhouette-report">Silhouette</NavLink>
            </li>
        </ul>
    );
}

export default ReportsSubnavigation;
