import { SeasonStatus } from 'buyplan-common';
import { request } from '../api/api';

export const getActiveSeasonStatus = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/status`,
    };

    return request<{ data: SeasonStatus }>(requestOptions);
};
