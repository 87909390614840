import React, { useCallback, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import { faExclamationTriangle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { channels } from 'buyplan-common';
import { getPartners } from '../../services/assortmentService';
import Loader from '../Loader/Loader';
import './ExportBuyPlan.scss';
import { useSelector } from '../../store/reducers';
import { useRequest } from '../../api/useRequest';
import CheckBox from '../CheckBox/CheckBox';

function ExportBuyPlan() {
    const [withImage, setWithImage] = useState(false);
    const accessToken = useSelector(({ user }) => user.accessToken);
    const channelId = useSelector(({ user }) => user.settings.activeChannelId);

    const fetchPartners = useCallback(() => getPartners(), []);
    const [loading, error, partners] = useRequest(fetchPartners);

    const activeSeasonId = useSelector((state) => state.user.settings.activeSeasonId);

    const createMaterialBuyUrl = (partner: string) =>
        `${process.env.REACT_APP_API_ENDPOINT}/buyplan/export-material-buy/${partner}?${qs.stringify({
            activeSeason: activeSeasonId,
            channelId,
            withImage,
            Authorization: `Bearer ${accessToken}`,
        })}`;

    const toggleExportWithImage = () => {
        setWithImage(!withImage);
    };
    return (
        <Container>
            <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                    <div className="ExportBuyPlan">
                        <h1>DOWNLOAD BUY PLAN {loading && <Loader width={16} />}</h1>
                        {error && (
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                            </div>
                        )}
                        <div className="ExportBuyPlan__withImageContainer">
                            {channelId === channels.nvs.id ? null : (
                                <CheckBox
                                    label="Export With Images"
                                    id="export-image-toggle"
                                    isChecked={withImage}
                                    onChange={toggleExportWithImage}
                                />
                            )}
                        </div>
                        <ul>
                            {partners &&
                                partners.map(({ partner }) => (
                                    <li key={partner}>
                                        <h3>{partner}</h3>
                                        <a href={createMaterialBuyUrl(partner)} className="Button" download>
                                            <FontAwesomeIcon icon={faFileDownload as IconProp} />
                                            Download Material/Buy
                                        </a>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default ExportBuyPlan;
