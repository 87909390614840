import React from 'react';

export default function () {
    return (
        <div>
            <h2>Your Option Counts</h2>
            <p>After you upload your option counts file there are a few different ways for you to monitor your progress.</p>
            <h3>Summaries</h3>
            <p>
                To get to the option count summaries, <code>CLICK</code> on <strong>Option Counts</strong> under the{' '}
                <strong>Reports</strong> tab. On this view you have two different visuals to choose from a summary via
                Category/Division or by Highest Variance in Units. Note: This report is only available for business units
                that work with option counts (NDS and NVS).
            </p>
            <p>
                Example: If the Category/Division summary shows 4/10 on the Running Mens/Footwear combination this would mean
                that 4 out of the 10 stores, that have option count target, are receiving 65% of their option count target
                for this category/division combination.
            </p>
            <h3>Real-time tracking</h3>
            <p>
                When filling out your assortment we provide you with instant real-time updates on the current option counts
                for the store/category/division combination.
            </p>
        </div>
    );
}
