import React from 'react';
import { OptionCountsFileErrors as OptionCountsFileErrorsType } from 'buyplan-common';
import SourceFileError from './SourceFileError';

interface Props {
    errors: OptionCountsFileErrorsType;
}

function OptionCountsFileErrors({ errors }: Props) {
    const {
        message,
        optionCountsWithInvalidCountry,
        optionCountsWithMissingExchangeRate,
        optionCountsWithInvalidNumber,
        missingHeaders,
        missingData,
    } = errors;
    return (
        <>
            {message && <SourceFileError title="Error:" items={[message]} />}
            {missingHeaders && (
                <SourceFileError
                    title="The following required headers are missing in the uploaded file:"
                    items={missingHeaders}
                    itemType="header"
                />
            )}
            {missingData && (
                <SourceFileError
                    title="These option count lines are missing some required fields:"
                    items={missingData.map(
                        (optionCount) => `Row ${optionCount.rowNumber}: ${optionCount.emptyProperties.join(', ')}`
                    )}
                    itemType="option counts"
                />
            )}
            {optionCountsWithInvalidNumber && (
                <SourceFileError
                    title="These option count lines have an invalid number in OPTIONS TO BUY:"
                    items={optionCountsWithInvalidNumber.map((optionCount) => `Row ${optionCount.rowNumber}`)}
                    itemType="option counts"
                />
            )}
            {optionCountsWithInvalidCountry && (
                <SourceFileError
                    title="These option count lines have an invalid country:"
                    items={optionCountsWithInvalidCountry.map((optionCount) => `Row ${optionCount.rowNumber}`)}
                    itemType="option counts"
                />
            )}
            {optionCountsWithMissingExchangeRate && (
                <SourceFileError
                    title="These option count lines have a country for which no exchange rate exists:"
                    items={optionCountsWithMissingExchangeRate.map((optionCount) => `Row ${optionCount.rowNumber}`)}
                    itemType="option counts"
                />
            )}
        </>
    );
}

export default OptionCountsFileErrors;
