import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import cn from 'classnames';
import './SettingsButton.scss';

interface Props {
    onClick: () => void;
    className?: string;
}

function SettingsButton({ className, onClick }: Props) {
    return (
        <button type="button" onClick={onClick} className={cn('SettingsButton', className)}>
            <FontAwesomeIcon icon={faCog as IconProp} />
        </button>
    );
}

export default SettingsButton;
