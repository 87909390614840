import React, { ReactNode } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faEdit, faFilter } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './HeaderColumn.scss';
import { ChannelRole } from 'buyplan-common';
import useHasChannelRole from '../../selectors/useHasChannelRole';

interface HeaderColumnProps {
    label: string;
    className?: string;
    onEdit?: () => void;
    onSort?: (orderDirection: 'ASC' | 'DESC') => void;
    onFilter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    isColumnSorted?: boolean;
    isColumnFiltered?: boolean;
    orderDirection?: 'ASC' | 'DESC';
    style?: React.CSSProperties;
    children?: ReactNode;
}

function HeaderColumn({
    children,
    label,
    onEdit,
    onSort,
    onFilter,
    className,
    isColumnSorted,
    isColumnFiltered,
    orderDirection,
    ...props
}: HeaderColumnProps) {
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    const shouldAlignRight = label.length > 15; // Check if the label is large and overflows
    const filterButton = onFilter ? (
        <div
            tabIndex={0}
            className={cn(
                'HeaderColumn__filterable HeaderColumn__button HeaderColumn__button-filter HeaderColumn__button--icon',
                isColumnFiltered && 'HeaderColumn__button-filter--filtered',
                shouldAlignRight && 'HeaderColumn__button-filter--align-right'
            )}
            role="button"
            title={`Filter ${label}`}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                onFilter(e);
            }}
        >
            <FontAwesomeIcon icon={faFilter as IconProp} />
        </div>
    ) : null;

    const editButton =
        onEdit && !isReadOnlyUser ? (
            <div
                tabIndex={0}
                className={cn(
                    'HeaderColumn__editable HeaderColumn__button HeaderColumn__button-edit HeaderColumn__button--icon'
                )}
                role="button"
                title={`Update ${label}`}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        onEdit();
                    }
                }}
                onClick={onEdit}
            >
                <FontAwesomeIcon icon={faEdit as IconProp} />
            </div>
        ) : null;

    const sortButton = onSort ? (
        <div
            tabIndex={0}
            className={cn(
                'HeaderColumn__sortable HeaderColumn__button HeaderColumn__button-sort HeaderColumn__button--icon',
                isColumnSorted && 'HeaderColumn__button--active'
            )}
            role="button"
            title={`Sort ${label}`}
            onClick={() => {
                onSort(isColumnSorted && orderDirection === 'ASC' ? 'DESC' : 'ASC');
            }}
        >
            <FontAwesomeIcon icon={(isColumnSorted && orderDirection === 'DESC' ? faSortDown : faSortUp) as IconProp} />
        </div>
    ) : null;

    const hasButton = onFilter !== undefined || onSort !== undefined || onEdit !== undefined;

    return (
        <div className={cn(className, hasButton && 'HeaderColumn__header')} title={label} {...props}>
            {children}
            <div className="HeaderColumn__wrap">
                {sortButton}
                {label && (
                    <div className="HeaderColumn__label" title={`${label}`}>
                        {label}
                    </div>
                )}
            </div>
            {filterButton}
            {editButton}
        </div>
    );
}

export default HeaderColumn;
