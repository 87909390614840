import { v4 } from 'uuid';
import { ProcessLoadStatus } from 'buyplan-common';
import { ExchangeRateSnapshot, getLatestSnapshotData } from '../services/exchangeRatesService';
import {
    ENQUEUE_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SET_FX_RATES_PROCESS_STATUS,
    RESET_FX_RATES_PROCESS_STATUS,
    GET_LATEST_SNAPSHOT_DATA,
    SET_SNAPSHOT_DATA,
} from './actionTypes';
import { AnyDispatch } from './types';

export const showNotification = (message: string, notificationType = 'info') =>
    ({
        type: ENQUEUE_NOTIFICATION,
        message,
        notificationType,
        key: v4(),
        dismissed: false,
    } as const);

export const closeNotification = (key: string) =>
    ({
        type: CLOSE_NOTIFICATION,
        key,
        dismissed: true,
    } as const);

export const updateFxRatesProcessStatus = (status: ProcessLoadStatus) =>
    ({
        type: SET_FX_RATES_PROCESS_STATUS,
        status,
    } as const);

export const resetFxRatesProcessStatus = () =>
    ({
        type: RESET_FX_RATES_PROCESS_STATUS,
    } as const);

export const setSnapshotData = (snapshotData: ExchangeRateSnapshot) =>
    ({
        type: SET_SNAPSHOT_DATA,
        snapshotData,
    } as const);

export const getLatestSnapshot = () => async (dispatch: AnyDispatch) => {
    try {
        dispatch({ type: GET_LATEST_SNAPSHOT_DATA });
        const response = await getLatestSnapshotData();
        dispatch(setSnapshotData(response.data));
    } catch (err: unknown) {
        // fail silently if there is no response
    }
};

export type ShowNotificationAction = ReturnType<typeof showNotification>;
export type CloseNotificationAction = ReturnType<typeof closeNotification>;
export type UpdateFxRatesProcessAction = ReturnType<typeof updateFxRatesProcessStatus>;
export type ResetFxRatesProcessAction = ReturnType<typeof resetFxRatesProcessStatus>;
export type SetSnapshotDataAction = ReturnType<typeof setSnapshotData>;
export type getLatestSnapshotAction = {
    type: typeof GET_LATEST_SNAPSHOT_DATA;
};

export type NotificationActions =
    | ShowNotificationAction
    | CloseNotificationAction
    | UpdateFxRatesProcessAction
    | ResetFxRatesProcessAction
    | SetSnapshotDataAction
    | getLatestSnapshotAction;
