import React, { CSSProperties, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { delay, kebabCase, isEqual } from 'lodash';
import {
    formatUSDOrDash,
    BuyPlanAggregatedMaterial,
    BuyPlanStore,
    Store,
    canUpdateDigitalBuyplan,
    PartnerMaterialReviewedStatus,
    ChannelRole,
    BuyplanViewColumnConfigFavorite,
    formatPercentage,
} from 'buyplan-common';
import {
    getBuyPlanAggregatedMaterial as getBuyPlanAggregatedMaterialAction,
    updateBuyPlanAggregatedMaterialStores as updateBuyPlanAggregatedMaterialStoresAction,
    removeBuyPlanAggregatedMaterial as removeBuyPlanAggregatedMaterialAction,
    setBuyPlanPartnerReviewedStatus as setBuyPlanPartnerReviewedStatusAction,
    setBuyPlanAggregatedMaterialNotes as setBuyPlanAggregatedMaterialNotesAction,
} from '../../actions/buyplan';
import { updateLastYearReference } from '../../services/materialAggregatedMaterialService';
import {
    distributeBeginningOfPeriodUnits,
    distributeRateOfSale,
    getAggregatedMaterialLevelBuyPlan,
    calculateOTBAggregatedMaterialUnits,
} from '../../helpers/aggregatedMaterial';
import { INPUT_MAX_VALUE } from '../../constants/appConfig';
import Notes from '../Notes/Notes';
import MaterialImage from '../MaterialImage/MaterialImage';
import LastYearData from '../LastYearData/LastYearData';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';
import Tooltip, { TooltipType } from '../Tooltip/Tooltip';
import Dot, { DotSize, DotType } from '../Dot/Dot';
import SellThroughInput from '../SellThroughInput/SellThroughInput';
import AFPercentageInput from '../AFPercentageInput/AFPercentageInput';
import MaterialCode from '../MaterialCode/MaterialCode';
import BuyplanAggregatedMaterialDetail from '../BuyplanAggregatedMaterialDetail/BuyplanAggregatedMaterialDetail';
import './ViewBuyplanAggregatedMaterial.scss';
import CheckBox from '../CheckBox/CheckBox';
import { MAX_RATE_OF_SALE, MIN_RATE_OF_SALE } from '../BuyplanAggregatedMaterialDetail/BuyPlanRateOfSaleInput';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import ViewBuyplanCell from './ViewBuyplanCell';
import AggregatedMaterialOpenToBuyUnitsDot from './AggregatedMaterialOpenToBuyUnitsDot';
import FavoriteStore from './FavoriteStore';

interface Props {
    aggregatedMaterial: BuyPlanAggregatedMaterial;
    isAggregatedMaterialOpen: boolean;
    columnType: 'fixed' | 'scrollable';
    style: CSSProperties;
    columns: BuyplanViewColumnConfigFavorite[];
    channelId: number;
    makeTotalsOutOfDate(): void;
    onToggleAggregatedMaterial(): void;
    updateBuyPlanAggregatedMaterialStores(
        aggregatedMaterial: BuyPlanAggregatedMaterial,
        channelId: number,
        updateReviewedStatus: boolean
    ): void;
    getBuyPlanAggregatedMaterial(materialId: string): void;
    removeBuyPlanAggregatedMaterial(aggregatedMaterialId: string): void;
    setBuyPlanPartnerReviewedStatus(
        materialId: string,
        partnerIds: string[],
        reviewedStatus: PartnerMaterialReviewedStatus
    ): void;
    setBuyPlanAggregatedMaterialNotes(aggregatedMaterialId: string, materialId: string, notes: string): void;
    onRemoveStore(): void;
    showMessage: (message: string) => void;
}

function ViewBuyplanAggregatedMaterial({
    aggregatedMaterial,
    isAggregatedMaterialOpen,
    columnType,
    style,
    columns,
    channelId,
    makeTotalsOutOfDate,
    onToggleAggregatedMaterial,
    updateBuyPlanAggregatedMaterialStores,
    getBuyPlanAggregatedMaterial,
    removeBuyPlanAggregatedMaterial,
    setBuyPlanPartnerReviewedStatus,
    setBuyPlanAggregatedMaterialNotes,
    onRemoveStore,
    showMessage,
}: Props) {
    const [aggregatedMaterialValue, setAggregatedMaterialValue] = useState(aggregatedMaterial);
    const [hasAggregatedMaterialChanged, setHasAggregatedMaterialChanged] = useState(false);
    const [updateReviewedStatus, setAutoUpdateReviewedStatus] = useState(false);
    const [shouldAggregatedMaterialHighlight, setShouldAggregatedMaterialHighlight] = useState(false);
    const [originalAggregatedMaterial, setOriginalAggregatedMaterial] = useState<BuyPlanAggregatedMaterial | undefined>(
        aggregatedMaterialValue
    );
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    /**
     * handleBlur saves the current aggregated material store values (ROS/WOS/ST) to the backend.
     *
     * To update these values make sure to dispatch any changes through `handleBuyPlanAggregatedMaterialChange` first.
     * If no changes were made to the aggregated material nothing happens.
     */
    const handleBlur = async () => {
        setHasAggregatedMaterialChanged(true);
        makeTotalsOutOfDate();
    };

    useEffect(() => {
        setAggregatedMaterialValue(aggregatedMaterial);
    }, [aggregatedMaterial, setAggregatedMaterialValue]);

    useEffect(() => {
        if (hasAggregatedMaterialChanged && !isEqual(originalAggregatedMaterial, aggregatedMaterialValue)) {
            updateBuyPlanAggregatedMaterialStores(aggregatedMaterialValue, channelId, updateReviewedStatus);
            setHasAggregatedMaterialChanged(false);
            setOriginalAggregatedMaterial(aggregatedMaterialValue);
        }
    }, [
        aggregatedMaterialValue,
        updateReviewedStatus,
        hasAggregatedMaterialChanged,
        channelId,
        updateBuyPlanAggregatedMaterialStores,
        originalAggregatedMaterial,
    ]);

    /**
     * This dispatches a redux action to update the aggregated material with new aggregated material and stores values.
     */
    const handleBuyPlanAggregatedMaterialChange = (changedStores: BuyPlanStore[]) => {
        const { typeOfOrder } = aggregatedMaterialValue;
        let stores = aggregatedMaterialValue.stores.map(
            (store: BuyPlanStore) => changedStores.find((changedStore) => changedStore.storeId === store.storeId) || store
        );
        stores = distributeBeginningOfPeriodUnits(stores, aggregatedMaterialValue.beginningOfPeriodUnits);
        const updatedAggregatedMaterialValue = {
            ...aggregatedMaterialValue,
            ...getAggregatedMaterialLevelBuyPlan(stores, typeOfOrder, channelId),
            stores,
        };
        setAggregatedMaterialValue(updatedAggregatedMaterialValue);
    };

    /**
     * An event handler for changing the aggregated material BOP. It updates all store BOP values
     */
    const handleChangeAggregatedMaterialBOP = (inputBeginningOfPeriod: number) => {
        const { typeOfOrder } = aggregatedMaterialValue;
        const newStores = distributeBeginningOfPeriodUnits(aggregatedMaterialValue.stores, inputBeginningOfPeriod);
        const updatedAggregatedMaterial = {
            ...aggregatedMaterialValue,
            ...getAggregatedMaterialLevelBuyPlan(newStores, typeOfOrder, channelId),
            beginningOfPeriodUnits: inputBeginningOfPeriod,
            stores: newStores.map((store) => ({
                ...store,
                ...calculateOTBAggregatedMaterialUnits(store, typeOfOrder, channelId),
            })),
        };

        setAggregatedMaterialValue(updatedAggregatedMaterial);
    };

    /**
     * An event handler for changing the aggregated material ROS. It recalculates the ROS values for all stores within the aggregated material.
     */
    const handleChangeAggregatedMaterialROS = (rateOfSale: number) => {
        let currentAggregatedMaterial = originalAggregatedMaterial;
        if (!currentAggregatedMaterial) {
            // Store previous ROS within an instance variable, that way the user can keep
            // typing and changing without resetting the original aggregated material store ratio.
            setOriginalAggregatedMaterial(aggregatedMaterialValue);
            currentAggregatedMaterial = aggregatedMaterialValue;
        }
        const { typeOfOrder } = currentAggregatedMaterial;
        const aggregatedMaterialWithFilteredStores = {
            ...currentAggregatedMaterial,
            stores: aggregatedMaterialValue.stores.filter(({ storeNumber }: BuyPlanStore) =>
                canUpdateDigitalBuyplan(channelId, storeNumber, typeOfOrder)
            ),
        };
        const newStores = distributeRateOfSale(aggregatedMaterialWithFilteredStores, rateOfSale).map(
            (store: BuyPlanStore) => ({
                ...store,
                ...calculateOTBAggregatedMaterialUnits(store, typeOfOrder, channelId),
            })
        );
        setAutoUpdateReviewedStatus(true);
        handleBuyPlanAggregatedMaterialChange(newStores);
    };

    /**
     * An event handler for changing the aggregated material sell through. It updates all store sell through and ROS values.
     */
    const handleChangeAggregatedMaterialST = (value: number) => {
        const { typeOfOrder } = aggregatedMaterialValue;
        const newStores = aggregatedMaterialValue.stores
            .map((store: BuyPlanStore) => ({ ...store, sellThrough: value }))
            .map((store: BuyPlanStore) => ({
                ...store,
                ...calculateOTBAggregatedMaterialUnits(store, typeOfOrder, channelId),
            }));
        handleBuyPlanAggregatedMaterialChange(newStores);
    };

    /**
     * An event handler for changing the aggregated material AF percentage. It updates all store AF percentage.
     */

    const handleChangeAggregatedMaterialAF = (value: number) => {
        const { typeOfOrder } = aggregatedMaterialValue;
        const newStores = aggregatedMaterialValue.stores.map((store: BuyPlanStore) => {
            const { afOpenToBuyUnits, aaOpenToBuyUnits } = calculateOTBAggregatedMaterialUnits(
                { ...store, afPercentage: value },
                typeOfOrder,
                channelId
            );

            return { ...store, afPercentage: value, aaOpenToBuyUnits, afOpenToBuyUnits };
        });

        handleBuyPlanAggregatedMaterialChange(newStores);
    };

    /**
     * An event handler for changing the aggregated material no seasonal buy flag. It updates all store no seasonal buy flag.
     */
    const handleChangeAggregatedMaterialNoSeasonalBuy = async (value: boolean) => {
        const newStores = aggregatedMaterialValue.stores.map((store: BuyPlanStore) => ({ ...store, noSeasonalBuy: value }));
        handleBuyPlanAggregatedMaterialChange(newStores);

        setHasAggregatedMaterialChanged(true);
        makeTotalsOutOfDate();
    };

    /**
     * An event handler for changing the aggregated material sell through. It updates all store sell through values
     */
    const handleChangeAggregatedMaterialWOS = (value: number) => {
        const { typeOfOrder } = aggregatedMaterialValue;
        const newStores = aggregatedMaterialValue.stores
            .map((store: BuyPlanStore) => ({ ...store, weeksOnSale: value }))
            .map((store: BuyPlanStore) => ({
                ...store,
                ...calculateOTBAggregatedMaterialUnits(store, typeOfOrder, channelId),
            }));

        handleBuyPlanAggregatedMaterialChange(newStores);
    };

    /**
     * An event handler to update a single store within the aggregated material. It will update the aggregated material's totals.
     */
    const handleStoreChange = (updateOnStore: BuyPlanStore) => {
        const { typeOfOrder } = aggregatedMaterialValue;

        handleBuyPlanAggregatedMaterialChange([
            {
                ...updateOnStore,
                ...calculateOTBAggregatedMaterialUnits(updateOnStore, typeOfOrder, channelId),
            },
        ]);
    };

    const handleChangeStoreOTB = (updateOnStore: BuyPlanStore) => {
        handleBuyPlanAggregatedMaterialChange([updateOnStore]);
    };

    const highlightAggregatedMaterialRow = () => {
        setShouldAggregatedMaterialHighlight(true);
        delay(() => setShouldAggregatedMaterialHighlight(false), 500);
    };

    // TODO: rework to use action to update LY match value, not to call api from the component directly
    const onAggregatedMaterialLYReferenceChange = async (
        buyPlanAggregatedMaterialValue: BuyPlanAggregatedMaterial,
        lastYearReferenceManualMaterialCode: string
    ) => {
        await updateLastYearReference(
            buyPlanAggregatedMaterialValue.id,
            buyPlanAggregatedMaterialValue.materialId,
            lastYearReferenceManualMaterialCode
        );

        await getBuyPlanAggregatedMaterial(buyPlanAggregatedMaterialValue.id); // Retrieve updated aggregated material values
        highlightAggregatedMaterialRow();
    };

    const onAggregatedMaterialNotesChange = async (notes: string) => {
        setBuyPlanAggregatedMaterialNotes(aggregatedMaterialValue.id, aggregatedMaterialValue.materialId, notes);
    };

    const visibleColumns = columns.filter(({ type }) => type === columnType);

    const {
        rateOfSale,
        sellThrough,
        afPercentage,
        weeksOnSale,
        presentationStocks,
        openToBuyUnits,
        aaOpenToBuyUnits,
        afOpenToBuyUnits,
        sales,
        beginningOfPeriodUnits,
        noSeasonalBuy,
        endOfPeriodUnits,
        aur,
        marginPercentage,
        retailGBPInclVat,
        filteredStoreCount,
        totalStoreCount,
        totalPartnerCount,
    } = aggregatedMaterialValue;
    return (
        <div
            style={style}
            className={cn('ViewBuyplanAggregatedMaterial', {
                'ViewBuyplanAggregatedMaterial--highlighted': shouldAggregatedMaterialHighlight,
                'ViewBuyplanAggregatedMaterial--opened': isAggregatedMaterialOpen,
            })}
            // TODO aggregatedMaterialValue
            data-e2e={`aggregatedMaterial-${aggregatedMaterial.materialCode}`}
        >
            <div className="ViewBuyplanAggregatedMaterial__aggregatedMaterial">
                {/* Multiple columns may have the key "favoriteStore", to differentiate between them the favoriteStore
                        is added as a prop in the generateColumnConfig process and passed along to FavoriteStore component */}
                {visibleColumns.map(({ key, label, favoriteStore }) => (
                    <ViewBuyplanCell
                        key={`${key}-${kebabCase(label)}`}
                        columnKey={key}
                        data={aggregatedMaterialValue}
                        columns={columns}
                        channelId={channelId}
                    >
                        {key === 'aur' && !retailGBPInclVat && (
                            <Tooltip
                                type={TooltipType.warning}
                                placement="top"
                                tooltip="GBP price (Retail including VAT) was not found for the material. Retail USD price is used for calculation."
                            >
                                <div className="aur-noRetailGBP" style={{ color: 'red' }}>
                                    {formatUSDOrDash(aur, false, false)}
                                </div>
                            </Tooltip>
                        )}
                        {key === 'marginPercentage' && (marginPercentage ? formatPercentage(marginPercentage, true) : '-')}
                        {key === 'toggleStores' && (
                            <button
                                type="button"
                                onClick={onToggleAggregatedMaterial}
                                tabIndex={-1}
                                data-e2e={`toggle-stores-${aggregatedMaterialValue.materialCode}`}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        (isAggregatedMaterialOpen ? faChevronCircleDown : faChevronCircleRight) as IconProp
                                    }
                                />
                            </button>
                        )}
                        {key === 'notes' && (
                            <Notes
                                subtitle={
                                    isReadOnlyUser
                                        ? `Note for the material ${aggregatedMaterialValue.materialCode}`
                                        : `You can save a note for the material ${aggregatedMaterialValue.materialCode}`
                                }
                                bodyText={aggregatedMaterialValue.notes}
                                onSave={onAggregatedMaterialNotesChange}
                            />
                        )}
                        {key === 'image' && <MaterialImage material={aggregatedMaterialValue} />}
                        {key === 'materialCode' && <MaterialCode {...aggregatedMaterialValue} disabled />}
                        {key === 'beginningOfPeriodUnits' && (
                            <EnhancedNumberFormat
                                name="beginningOfPeriod"
                                minimum={0}
                                maximum={INPUT_MAX_VALUE}
                                step={1}
                                clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                                value={beginningOfPeriodUnits === 0 ? null : beginningOfPeriodUnits}
                                onChange={handleChangeAggregatedMaterialBOP}
                                onBlur={handleBlur}
                                decimalScale={0}
                                disabled={isReadOnlyUser}
                            />
                        )}
                        {key === 'stores' && (
                            <div className="totalStores">
                                {filteredStoreCount < totalStoreCount ? (
                                    <>
                                        <Dot
                                            type={DotType.warning}
                                            tooltip="Stores filter is applied for this material.
                                        Any changes made for the buy record will be only applied based on distribution between visible stores"
                                            placement="top"
                                            size={DotSize.large}
                                        />
                                        <div>{`${filteredStoreCount}/${totalStoreCount}`}</div>
                                    </>
                                ) : (
                                    <div>{totalStoreCount}</div>
                                )}
                            </div>
                        )}
                        {key === 'partner' && (
                            <div className="totalPartners">
                                <div>{totalPartnerCount}</div>
                            </div>
                        )}
                        {key === 'noSeasonalBuy' && (
                            <CheckBox
                                id={`noSeasonalBuy_${aggregatedMaterialValue.materialCode}`}
                                onChange={() => handleChangeAggregatedMaterialNoSeasonalBuy(!noSeasonalBuy)}
                                isChecked={noSeasonalBuy}
                                disabled={isReadOnlyUser}
                            />
                        )}

                        {key === 'rateOfSale' && (
                            <EnhancedNumberFormat
                                name="rateOfSale"
                                minimum={MIN_RATE_OF_SALE}
                                maximum={MAX_RATE_OF_SALE}
                                step={0.01}
                                clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                                value={rateOfSale}
                                onChange={handleChangeAggregatedMaterialROS}
                                onBlur={() => {
                                    handleBlur();
                                }}
                                decimalScale={2}
                                disabled={isReadOnlyUser}
                            />
                        )}
                        {key === 'weeksOnSale' && (
                            <EnhancedNumberFormat
                                name="weeksOnSale"
                                minimum={0}
                                maximum={53}
                                step={1}
                                clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                                value={weeksOnSale}
                                decimalScale={0}
                                tabIndex={-1}
                                onChange={handleChangeAggregatedMaterialWOS}
                                onBlur={handleBlur}
                                disabled={isReadOnlyUser}
                            />
                        )}
                        {key === 'eop' && endOfPeriodUnits}
                        {key === 'sellThrough' && (
                            <SellThroughInput
                                value={sellThrough}
                                name="sellThrough"
                                tabIndex={-1}
                                onChange={handleChangeAggregatedMaterialST}
                                onBlur={handleBlur}
                                disabled={isReadOnlyUser}
                            />
                        )}
                        {key === 'afPercentage' && (
                            <AFPercentageInput
                                value={afPercentage}
                                name="afPercentage"
                                store={aggregatedMaterialValue}
                                tabIndex={-1}
                                onChange={handleChangeAggregatedMaterialAF}
                                onBlur={handleBlur}
                                disabled={isReadOnlyUser}
                            />
                        )}
                        {key === 'presentationStocks' && (presentationStocks ?? '-')}
                        {key === 'openToBuyUnits' && (
                            <>
                                {openToBuyUnits ?? '-'}
                                <AggregatedMaterialOpenToBuyUnitsDot aggregatedMaterial={aggregatedMaterialValue} />
                            </>
                        )}
                        {key === 'aaOpenToBuyUnits' && (aaOpenToBuyUnits ?? '-')}
                        {key === 'afOpenToBuyUnits' && (afOpenToBuyUnits ?? '-')}
                        {key === 'salesUnits' && (sales ?? '-')}
                        {key === 'favoriteStore' && (
                            <FavoriteStore
                                aggregatedMaterial={aggregatedMaterialValue}
                                favoriteStore={favoriteStore as Store}
                                onStoreBlur={handleBlur}
                                onStoreChange={handleStoreChange}
                                channelId={channelId}
                                onChangeStoreOTB={handleChangeStoreOTB}
                                showMessage={showMessage}
                            />
                        )}
                        {key === 'lastYear' && (
                            <LastYearData
                                lastYearData={aggregatedMaterialValue}
                                onBuyPlanAggregatedMaterialLastYearReferenceChange={async (lastYearMaterialCode) =>
                                    onAggregatedMaterialLYReferenceChange(
                                        aggregatedMaterialValue,
                                        lastYearMaterialCode as string
                                    )
                                }
                                partnerId={aggregatedMaterialValue.partnerId}
                                channelId={channelId}
                                level="aggregatedMaterial"
                            />
                        )}
                    </ViewBuyplanCell>
                ))}
            </div>
            {isAggregatedMaterialOpen && columnType === 'scrollable' && (
                <BuyplanAggregatedMaterialDetail
                    aggregatedMaterial={aggregatedMaterialValue}
                    onStoreBlur={handleBlur}
                    onStoreChange={handleStoreChange}
                    columns={columns}
                    getBuyPlanAggregatedMaterial={getBuyPlanAggregatedMaterial}
                    removeBuyPlanAggregatedMaterial={removeBuyPlanAggregatedMaterial}
                    onRemoveStore={onRemoveStore}
                    onChangeStoreOTB={handleChangeStoreOTB}
                    showMessage={showMessage}
                    setBuyPlanPartnerReviewedStatus={setBuyPlanPartnerReviewedStatus}
                />
            )}
        </div>
    );
}

const mapActionCreators = {
    getBuyPlanAggregatedMaterial: getBuyPlanAggregatedMaterialAction,
    updateBuyPlanAggregatedMaterialStores: updateBuyPlanAggregatedMaterialStoresAction,
    removeBuyPlanAggregatedMaterial: removeBuyPlanAggregatedMaterialAction,
    setBuyPlanPartnerReviewedStatus: setBuyPlanPartnerReviewedStatusAction,
    setBuyPlanAggregatedMaterialNotes: setBuyPlanAggregatedMaterialNotesAction,
};

export default compose(connect(null, mapActionCreators))(ViewBuyplanAggregatedMaterial);
