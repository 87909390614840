import { ReportFilters } from 'buyplan-common';
import { PageView } from './appConfig';

export interface ReportsFiltersConfig {
    [key: string]: {
        requiredFilters: ReportFilters[];
        supplementaryFilters: ReportFilters[];
        allFilters?: ReportFilters[];
    };
}

export const reportsFiltersConfig: ReportsFiltersConfig = {
    [PageView.familyModelReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category, ReportFilters.division],
        supplementaryFilters: [
            ReportFilters.storeId,
            ReportFilters.typeOfOrder,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
            ReportFilters.silhouette,
            ReportFilters.family,
            ReportFilters.model,
        ],
    },
    [PageView.categoryDivisionReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category],
        supplementaryFilters: [
            ReportFilters.typeOfOrder,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
            ReportFilters.launchFlag,
        ],
    },
    [PageView.topStylesReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category, ReportFilters.division],
        supplementaryFilters: [
            ReportFilters.typeOfOrder,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
            ReportFilters.silhouette,
            ReportFilters.family,
            ReportFilters.model,
        ],
    },
    [PageView.storeLevelReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category],
        supplementaryFilters: [
            ReportFilters.storeId,
            ReportFilters.typeOfOrder,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
            ReportFilters.launchFlag,
        ],
    },
    [PageView.typeOfOrderReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category],
        supplementaryFilters: [
            ReportFilters.storeId,
            ReportFilters.division,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
        ],
    },
    [PageView.silhouetteReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category, ReportFilters.division],
        supplementaryFilters: [
            ReportFilters.silhouette,
            ReportFilters.typeOfOrder,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
            ReportFilters.model,
            ReportFilters.family,
        ],
    },
};

reportsFiltersConfig[PageView.familyModelReport].allFilters = [
    ...reportsFiltersConfig[PageView.familyModelReport].requiredFilters,
    ...reportsFiltersConfig[PageView.familyModelReport].supplementaryFilters,
];

reportsFiltersConfig[PageView.categoryDivisionReport].allFilters = [
    ...reportsFiltersConfig[PageView.categoryDivisionReport].requiredFilters,
    ...reportsFiltersConfig[PageView.categoryDivisionReport].supplementaryFilters,
];

reportsFiltersConfig[PageView.topStylesReport].allFilters = [
    ...reportsFiltersConfig[PageView.topStylesReport].requiredFilters,
    ...reportsFiltersConfig[PageView.topStylesReport].supplementaryFilters,
];

reportsFiltersConfig[PageView.storeLevelReport].allFilters = [
    ...reportsFiltersConfig[PageView.storeLevelReport].requiredFilters,
    ...reportsFiltersConfig[PageView.storeLevelReport].supplementaryFilters,
];

reportsFiltersConfig[PageView.typeOfOrderReport].allFilters = [
    ...reportsFiltersConfig[PageView.typeOfOrderReport].requiredFilters,
    ...reportsFiltersConfig[PageView.typeOfOrderReport].supplementaryFilters,
];

reportsFiltersConfig[PageView.silhouetteReport].allFilters = [
    ...reportsFiltersConfig[PageView.silhouetteReport].requiredFilters,
    ...reportsFiltersConfig[PageView.silhouetteReport].supplementaryFilters,
];
