import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NewRelicEventName, channels, ProcessIdentifiers, ProcessLoadStatus } from 'buyplan-common';
import { Redirect, Link } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCanEditBuyPlan from '../../helpers/useCanEditBuyPlan';
import { getBuyPlanFiles } from '../../services/buyplanService';
import { deleteMerchandiseFile, reuploadMerchandiseFile, uploadMerchandiseFile } from '../../services/assortmentService';
import {
    deleteOptionCountsFile,
    reuploadOptionCountsFile,
    uploadOptionCountsFile,
    convertOptionCountFiles,
} from '../../services/optionCountsService';
import ImportFiles from '../ImportFiles/ImportFiles';
import Loader from '../Loader/Loader';
import { useRequestFunction } from '../../api/useRequest';
import PageWarning from '../PageWarning/PageWarning';
import useSeasonSettings from '../../selectors/useSeasonSettings';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import useUserSettings from '../../selectors/useUserSettings';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import { getProcessStatus, useProcessStatus } from '../../services/processesService';

function ImportAssortmentFiles() {
    const { activeChannelId, activeSeasonId } = useUserSettings();
    const [deleted, setDeleted] = useState(false);
    const isSuperUser = useIsSuperUser();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);
    const { isHistoricalSeason } = useSeasonSettings();
    const userCanImportAssortmentFiles = isSuperUser || isChannelAdmin;

    const [loadingFiles, error, files, fetchBuyplanFiles] = useRequestFunction(getBuyPlanFiles);
    const [loadingStatus, , status, fetchStatus] = useProcessStatus(ProcessIdentifiers.processOptionCountsFile, true);
    const [loadingActions] = useCanEditBuyPlan();
    useEffect(() => {
        fetchBuyplanFiles();
        fetchStatus();
    }, [fetchBuyplanFiles, fetchStatus, activeSeasonId]);
    useEffect(() => {
        if (status?.status === ProcessLoadStatus.deleting) {
            setDeleted(true);
        }
    }, [status]);
    const getOptioCountStatus = () => getProcessStatus(ProcessIdentifiers.processOptionCountsFile, true);
    if (isHistoricalSeason) {
        return <Redirect to="/buyplan" />;
    }

    if (!userCanImportAssortmentFiles) {
        return (
            <PageWarning>
                <h1>IMPORT ASSORTMENT FILES</h1>
                <h2>Not allowed</h2>
                <p>Only certain users can import the Assortment files</p>
            </PageWarning>
        );
    }
    return (
        <Container>
            <h1>IMPORT ASSORTMENT FILES {(loadingFiles || loadingActions) && <Loader width={16} />}</h1>
            {error && (
                <div className="error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}
            {files && (
                <Row>
                    <Col sm={{ span: 12 }}>
                        {activeChannelId !== channels.digital.id && (
                            <div className="ImportOptionCounts">
                                <section>
                                    <h3>Option Count File</h3>
                                    <ImportFiles
                                        files={
                                            // @todo remove condition in HO25 season after fully moved to async file processing
                                            !deleted && !loadingStatus && !status
                                                ? files.optionCountsFiles
                                                : convertOptionCountFiles(status)
                                        }
                                        onDelete={async (id) => {
                                            setDeleted(true);
                                            await deleteOptionCountsFile(id);
                                            await fetchBuyplanFiles();
                                            fetchStatus();
                                        }}
                                        onCancelError={async (id) => {
                                            await deleteOptionCountsFile(id);
                                            fetchStatus();
                                        }}
                                        onUpload={(...args) => uploadOptionCountsFile(...args, getOptioCountStatus)}
                                        onReUpload={(...args) => reuploadOptionCountsFile(...args, getOptioCountStatus)}
                                        onSuccess={() => fetchStatus()}
                                        narrow={true}
                                        eventTypeTracker={NewRelicEventName.optionCountFileUpload}
                                    />
                                </section>
                            </div>
                        )}
                    </Col>
                    <Col sm={{ span: 12 }}>
                        <div className="ImportAssortment">
                            <section>
                                <h3>
                                    Merchandise Files (<Link to="/buyplan/manage-assortment/late-adds">Late adds</Link>)
                                </h3>
                                <ImportFiles
                                    files={files.merchandiseFiles}
                                    onDelete={deleteMerchandiseFile}
                                    onUpload={uploadMerchandiseFile}
                                    onReUpload={reuploadMerchandiseFile}
                                    onSuccess={() => fetchBuyplanFiles()}
                                    multiple={true}
                                    confirmation={(file) =>
                                        file.isModified
                                            ? {
                                                  title: 'Changes Detected',
                                                  subtitle: 'A retail planner has modified materials tied to this file',
                                                  message: 'Do you still wish to override these changes?',
                                              }
                                            : undefined
                                    }
                                    eventTypeTracker={NewRelicEventName.merchandiseFileUpload}
                                />
                            </section>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default ImportAssortmentFiles;
