import { UserRole } from 'buyplan-common';
import { useSelector } from '../store/reducers';

const useIsSuperUser = (): boolean => {
    const { profile } = useSelector(({ user }) => user);

    return UserRole.superUser === profile?.role;
};

export default useIsSuperUser;
