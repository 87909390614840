import React from 'react';
import getTotalsButton from './images/getTotalsButton.gif';
import totalsArrowsRotate from './images/totalsArrowsRotate.gif';

export default function () {
    return (
        <div>
            <h2>Totals on-demand</h2>
            <h3>Totals can be loaded on-demand in two ways:</h3>
            <ul>
                <li>
                    By Get Totals button at the top of View Buyplan page
                    <img style={{ width: '80%' }} src={getTotalsButton} alt="Get Totals button" />
                    <p>Button will be disablled if tatals up to date</p>
                </li>
                <li>
                    By rotate arrows near buyplan totals values in buyplan subheader
                    <img style={{ width: '80%' }} src={totalsArrowsRotate} alt="Get Totals button" />
                    <p>Rotate arrows will be disablled if tatals up to date</p>
                </li>
            </ul>
            <h3>Refresh totals</h3>
            <p>When you changed filters, totals will disappear. You can refresh them in the same ways as in first time.</p>
            <p>
                When you made any changes with <b>BOP</b>, <b>ROS</b>, <b>WOS</b>, <b>ST%</b>, <b>AF%</b> columns, totals
                will become out of date (changed color to grey).You can refresh them in the same ways as in first time.
            </p>
        </div>
    );
}
