import { useSelector } from '../store/reducers';
import { ActiveFiltersType } from '../reducers/user';
import { PageView } from '../constants/appConfig';

/**
 * Returns the active filters for the current channel/view
 */
const useActiveFilters = (view: PageView): ActiveFiltersType => {
    const { settings } = useSelector(({ user }) => user);
    const { activeChannelId, activeFilters } = settings;

    const { mainFilters, subFilters } = activeFilters[`${activeChannelId}|${view}`] ?? { mainFilters: {}, subFilters: {} };

    return { mainFilters, subFilters };
};

export default useActiveFilters;
