import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getExportSeasons } from '../../services/exportSeasonsService';
import { useSelector } from '../../store/reducers';
import { useRequest } from '../../api/useRequest';
import Loader from '../Loader/Loader';
import './ExportSeasons.scss';
import ContentWrapper from '../ContentWrapper/ContentWrapper';

function ExportSeasons() {
    const accessToken = useSelector(({ user }) => user.accessToken);
    const [loading, , seasons] = useRequest(getExportSeasons);
    const channelId = useSelector(({ user }) => user.settings.activeChannelId);

    return (
        <ContentWrapper>
            <Container>
                <Row>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <div className="export-seasons">
                            <h1>EXPORT SEASON {loading && <Loader width={16} />}</h1>
                            <p>
                                Select a season below to export data so a shipplan can be created. Sizes will be left out due
                                to aggregation.
                            </p>
                            {seasons && (
                                <ul className="export-seasons__list">
                                    {seasons.map(({ seasonCode, exportUrl, quantity }) => (
                                        <li key={seasonCode}>
                                            <a
                                                href={`${process.env.REACT_APP_API_ENDPOINT}${exportUrl}?channelId=${channelId}&Authorization=Bearer ${accessToken}`}
                                                className="Button"
                                                download
                                            >
                                                <FontAwesomeIcon icon={faFileDownload as IconProp} />
                                                Download {seasonCode} (total quantity {quantity.toLocaleString()})
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </ContentWrapper>
    );
}

export default ExportSeasons;
