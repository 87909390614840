import React from 'react';
import { modelDashboardMaterialRowConfig, ModelDashboardMaterialRowConfig, ModelDashboardListItem } from 'buyplan-common';
import cn from 'classnames';
import './ModelDashboardMaterialRow.scss';

interface Props {
    listItem: ModelDashboardListItem;
}
function ModelDashboardMaterialRow({ listItem }: Props) {
    return (
        <tr className="model-dashboard-material-row">
            {modelDashboardMaterialRowConfig.map((configRow: ModelDashboardMaterialRowConfig) => {
                const { key, getValue } = configRow;
                const rowValue = listItem[key as keyof ModelDashboardListItem] as number | null;
                const rowFormattedValue = getValue ? getValue(rowValue, false) : rowValue;
                return (
                    <td
                        key={key}
                        className={cn('model-dashboard-material-row__text', {
                            'model-dashboard-material-row--negative': (rowValue ?? 0) < 0,
                        })}
                    >
                        {rowFormattedValue}
                    </td>
                );
            })}
        </tr>
    );
}

export default ModelDashboardMaterialRow;
