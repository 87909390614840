import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface Props {
    children: ReactNode;
}

function PageWarning({ children }: Props) {
    return (
        <Container className="centered alert alert-warning">
            <Row>
                <Col>{children}</Col>
            </Row>
        </Container>
    );
}

export default PageWarning;
