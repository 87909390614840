import React, { PureComponent } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRandom } from '@fortawesome/free-solid-svg-icons';
import { DummyCodeMaterial as DummyCodeMaterialType, MMXMaterialWithImage } from 'buyplan-common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { lookupMaterialCode, updateDummyCode } from '../../services/materialService';
import Loader from '../Loader/Loader';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import MaterialImage from '../MaterialImage/MaterialImage';
import './DummyCodeMaterial.scss';

interface Props {
    material: DummyCodeMaterialType;
    onUpdated?(): void;
}

interface State {
    error?: string;
    isNewMaterialFound?: boolean;
    loading?: boolean;
    newMaterial?: MMXMaterialWithImage;
    newMaterialCode: string;
}

export class DummyCodeMaterial extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            newMaterialCode: '',
        };
    }

    findMaterial = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { newMaterialCode } = this.state;
        try {
            this.setState({ isNewMaterialFound: undefined, newMaterial: undefined, loading: true });
            const response = await lookupMaterialCode(newMaterialCode);
            this.setState({ newMaterial: response.data, loading: false });
        } catch (e) {
            this.setState({ isNewMaterialFound: false, loading: false });
        }
    };

    updateMaterial = async () => {
        const { onUpdated, material } = this.props;
        const { newMaterialCode } = this.state;
        try {
            this.setState({ loading: true, error: undefined });
            await updateDummyCode(material.id, { materialCode: newMaterialCode });
            if (onUpdated) {
                onUpdated();
            }
        } catch (error: unknown) {
            const err = error as Error;
            this.setState({ error: err.message, loading: false });
        }
    };

    render() {
        const { material } = this.props;
        const { newMaterialCode, newMaterial, isNewMaterialFound, error, loading } = this.state;
        const showFeedback = isNewMaterialFound === false || loading || error;

        return (
            <>
                <tr
                    className={cn('DummyCodeMaterial', 'DummyCodeMaterial__original', {
                        'DummyCodeMaterial--active': showFeedback || newMaterial,
                    })}
                >
                    <td className="DummyCodeMaterial__image DummyCodeMaterial--noPadding">
                        <MaterialImage material={material} />
                    </td>
                    <td>{material.materialCode}</td>
                    <td>{material.description}</td>
                    <td className="DummyCodeMaterial__replace DummyCodeMaterial--noPadding">
                        {!newMaterial && (
                            <form onSubmit={this.findMaterial}>
                                <input
                                    size={10}
                                    value={newMaterialCode || ''}
                                    className="input DummyCodeMaterial__input"
                                    onChange={(e) =>
                                        this.setState({
                                            newMaterialCode: e.target.value,
                                            newMaterial: undefined,
                                            error: undefined,
                                            isNewMaterialFound: undefined,
                                        })
                                    }
                                />
                                <button className="Button Button--size-s" type="submit">
                                    Find material
                                </button>
                            </form>
                        )}
                    </td>
                </tr>
                {showFeedback && (
                    <tr className="DummyCodeMaterial__feedback DummyCodeMaterial--active">
                        <td colSpan={4}>
                            {isNewMaterialFound === false && <span>Material {newMaterialCode} not found</span>}
                            {loading && <Loader width={20} />}
                            {error && (
                                <div className="error">
                                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error}
                                </div>
                            )}
                        </td>
                    </tr>
                )}
                {newMaterial && (
                    <tr className="DummyCodeMaterial DummyCodeMaterial__new DummyCodeMaterial--active">
                        <td className="DummyCodeMaterial__image DummyCodeMaterial--noPadding">
                            <MaterialImage material={newMaterial} />
                        </td>
                        <td className="DummyCodeMaterial--overflowVisible">
                            <div className="DummyCodeMaterial__replace-icon">
                                <FontAwesomeIcon rotation={90} icon={faRandom as IconProp} pulse />
                            </div>
                            {newMaterialCode}
                        </td>
                        <td>{newMaterial.description}</td>
                        <td className="DummyCodeMaterial__replace DummyCodeMaterial--noPadding">
                            <button
                                type="button"
                                className="Button Button__inverted Button--size-s"
                                onClick={() =>
                                    this.setState({
                                        newMaterial: undefined,
                                        error: undefined,
                                        isNewMaterialFound: undefined,
                                    })
                                }
                            >
                                Cancel
                            </button>
                            &nbsp; &nbsp;
                            <ConfirmButton
                                label="Replace"
                                confirmLabel="Really?"
                                className="Button Button--size-s"
                                onClick={this.updateMaterial}
                            />
                        </td>
                    </tr>
                )}
            </>
        );
    }
}

export default DummyCodeMaterial;
