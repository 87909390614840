export const DEFAULT_PAGE_SIZE = 50;

export const INPUT_MAX_VALUE = 10000000000;

export enum PageView {
    assortment = 'assortment',
    buyplan = 'buyplan',
    hindsightAttributionMappings = 'hindsightAttributionMappings',
    marginImpact = 'marginImpact',
    familyModelReport = 'familyModelReport',
    categoryDivisionReport = 'categoryDivision',
    topStylesReport = 'topStylesReport',
    storeLevelReport = 'storeLevelReport',
    typeOfOrderReport = 'typeOfOrderReport',
    silhouetteReport = 'silhouetteReport',
}

export const InvalidInputValues = ['UNKNOWN'];
