import React, { PureComponent } from 'react';
import { FilterValue, DropdownOption } from 'buyplan-common';
import moment from 'moment';
import { LocalizationProvider, MobileDatePicker as DatePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { autoCompleteFilter } from '../../../helpers/autocomplete-filter';
import CheckBox from '../../CheckBox/CheckBox';
import ClearSubFiltersButton from '../../ViewBuyplan/ClearSubFiltersButton';
import Highlighter from './Highlighter';
import FilterInput from './FilterInput';
import './Filter.scss';

export const formatStringToDate = (date: Date) => moment(date).toDate();
export const formatDateToString = (date: Date) => moment(date).format('YYYY-MM-DD');
export const VISIBLE_FILTER_THRESHOLD = 7;
export const AUTOCOMPLETE_THRESHOLD = 20;

export const toggleFilter = (values: DropdownOption[], filters: string[] = []) => {
    const updatedFilters = values
        .map((v) => v.value.toString())
        .reduce(
            (acc, curr) => {
                const index = acc.indexOf(curr);
                if (index === -1) {
                    return [...acc, curr];
                }
                return [...acc.slice(0, index), ...acc.slice(index + 1)];
            },
            [...filters]
        );
    return updatedFilters;
};

export const sortActiveFilters =
    (activeFilters: string[] = []) =>
    (a: string, b: string) => {
        const aMatch = activeFilters.includes(a);
        const bMatch = activeFilters.includes(b);
        if ((!aMatch && !bMatch) || (aMatch && bMatch)) {
            return a.localeCompare(b, undefined, { numeric: true });
        }
        return aMatch ? -1 : 1;
    };

const MINIMUM_FILTER_OPTIONS_FOR_CLEAR_ALL = 3;

interface Props {
    field: string;
    filterType: string;
    setFilter(filter: FilterValue): void;
    label?: string;
    filterData?: DropdownOption[];
    activeFilter?: FilterValue;
    autoCollapse?: boolean;
}

interface State {
    expanded: boolean;
    filterText: string;
    filterStart: string | null;
    filterEnd: string | null;
}

class Filter extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: false,
            filterText: '',
            filterStart: null,
            filterEnd: null,
        };
        this.handleFilterSearchSelect = this.handleFilterSearchSelect.bind(this);
        this.handleFilterStart = this.handleFilterStart.bind(this);
        this.handleFilterEnd = this.handleFilterEnd.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { activeFilter = [], autoCollapse = true } = this.props;
        const { filterStart, filterEnd } = this.state;

        if (!autoCollapse) {
            return;
        }

        if (prevProps.activeFilter && activeFilter !== prevProps.activeFilter) {
            if (activeFilter.length === 0) {
                this.setState({
                    filterText: '',
                });
            }
        }

        // If date range exists and either field has been updated, apply filter
        if (filterStart && filterEnd && (prevState.filterStart !== filterStart || prevState.filterEnd !== filterEnd)) {
            this.handleDateChange(filterStart, filterEnd);
        }
    }

    handleExpand = () => {
        this.setState({ expanded: true });
    };

    handleFilterStart(selectedStart: Date) {
        const start = formatDateToString(selectedStart);
        this.setState({ filterStart: start });
    }

    handleFilterEnd(selectedEnd: Date) {
        const end = formatDateToString(selectedEnd);
        this.setState({ filterEnd: end });
    }

    handleDateChange(selectedStart: string, selectedEnd: string) {
        const { setFilter } = this.props;
        if (selectedStart && selectedEnd) {
            // run the query with new filters
            setFilter([selectedStart, selectedEnd]);
            // clear the filters from state
            this.setState({
                filterStart: null,
                filterEnd: null,
            });
        }
    }

    handleCheckboxChange(values: DropdownOption[]) {
        const { setFilter, activeFilter = [] } = this.props;
        // Needs an explicit conversion, because for checkBox the type will only be string
        const stringActiveFilter = activeFilter as unknown as string[];
        const newFilter = toggleFilter(values, stringActiveFilter);
        setFilter(newFilter);
    }

    handleSingleSelectChange(chosenValue: DropdownOption) {
        const { setFilter, activeFilter = [] } = this.props;
        // Needs an explicit conversion, because for singleSelect the type will only be string
        const stringActiveFilter = activeFilter as unknown as string[];
        const val = chosenValue.value.toString();
        if (stringActiveFilter.includes(val)) {
            setFilter([]);
        } else {
            setFilter([val]);
        }
    }

    handleFilterSearchSelect(e: React.KeyboardEvent<HTMLInputElement>) {
        const { filterData = [], activeFilter = [], setFilter } = this.props;
        const { filterText = '' } = this.state;

        // If there are filtered search items, select them when a user presses enter
        if (e.key !== 'Enter' || !filterText.trim().length) return;
        // Needs an explicit conversion, because for input the type will only be string
        const stringActiveFilter = activeFilter as unknown as string[];

        // check the entries against autocomplete data, add to list of filters
        const autoCompletedFilterData = autoCompleteFilter(filterData, filterText);
        const autoCompletedFilterDataValues = autoCompletedFilterData.map((filter) => filter.value as string);
        const dedupedFilters = [...new Set([...autoCompletedFilterDataValues, ...stringActiveFilter])];

        setFilter(dedupedFilters);
        this.setState({ filterText: '' });
    }

    filterData(e: React.ChangeEvent<HTMLInputElement>) {
        const noSpecialChars = e.target.value.replace(/[^A-Za-z0-9-\s,]/g, ''); // allow letters, numbers, spaces, commas and dashes
        this.setState({
            filterText: noSpecialChars,
            expanded: false,
        });
    }

    selectFilterType() {
        const { filterType } = this.props;
        switch (filterType) {
            case 'singleSelect':
                return this.renderSingleSelectCheckBox();
            case 'multiselect':
            case 'boolean':
                return this.renderCheckBox();
            case 'dateRange':
                return this.renderDateRangePicker();
            default:
                return null;
        }
    }

    renderCheckBox() {
        const { field, filterData = [], activeFilter = [] } = this.props;
        const { filterText } = this.state;
        const { expanded } = this.state;
        const showAutoComplete = filterData.length > AUTOCOMPLETE_THRESHOLD;
        const autoCompletedFilterData = autoCompleteFilter(filterData, filterText);
        const displayedFilterCount = expanded
            ? autoCompletedFilterData.length
            : activeFilter.length + VISIBLE_FILTER_THRESHOLD;
        const hiddenFilterCount = autoCompletedFilterData.length - displayedFilterCount;
        // Needs an explicit conversion, because for checkbox the type will only be string
        const stringActiveFilter = activeFilter as unknown as string[];

        const autoCompleteActiveFilters = autoCompletedFilterData.filter((autoCompletedFilter) =>
            stringActiveFilter.includes(autoCompletedFilter.label)
        );

        const autoCompleteFilters = autoCompletedFilterData.filter(
            (autoCompletedFilter) => !stringActiveFilter.includes(autoCompletedFilter.label)
        );

        // Always show active filters on top
        const filters = [...autoCompleteActiveFilters, ...autoCompleteFilters];

        if (filterData.length === 0) {
            return (
                <div className="selects">
                    <p>No filters available</p>
                </div>
            );
        }

        return (
            <div className="selects">
                {showAutoComplete && (
                    <FilterInput
                        onChange={this.filterData}
                        handleKeyboardEvent={this.handleFilterSearchSelect}
                        value={filterText}
                        field={field}
                    />
                )}
                {filters.length === 0 ? (
                    <p>No items found for this search term</p>
                ) : (
                    <>
                        <ul>
                            {filters.slice(0, displayedFilterCount).map((filteritem) => (
                                <li key={`${field}_${filteritem.value}`}>
                                    <CheckBox
                                        label={
                                            <Highlighter
                                                highlight={filterText.split(' ')}
                                                text={filteritem.label || 'none'}
                                            />
                                        }
                                        id={`${field}_${filteritem.value.toString().replace(/\s/g, '_')}`}
                                        isChecked={stringActiveFilter.indexOf(filteritem.value.toString()) > -1}
                                        onChange={() => this.handleCheckboxChange([filteritem])}
                                    />
                                </li>
                            ))}
                        </ul>
                        {hiddenFilterCount > 0 && (
                            <button type="button" onClick={this.handleExpand} className="more-button zero">
                                + show more ({hiddenFilterCount})
                            </button>
                        )}
                    </>
                )}
            </div>
        );
    }

    renderSingleSelectCheckBox() {
        const { field, filterData = [], activeFilter = [] } = this.props;
        const { filterText } = this.state;
        const { expanded } = this.state;

        const showAutoComplete = filterData.length > AUTOCOMPLETE_THRESHOLD;
        const autoCompletedFilterData = autoCompleteFilter(filterData, filterText);
        const hiddenFilterCount = autoCompletedFilterData.length - VISIBLE_FILTER_THRESHOLD;
        const displayedFilterCount = expanded ? autoCompletedFilterData.length : VISIBLE_FILTER_THRESHOLD;

        // Needs an explicit conversion, because for singleSelect the type will only be string
        const stringActiveFilter = activeFilter as unknown as string[];

        if (filterData.length === 0) {
            return (
                <div className="selects singleSelect">
                    <p>No filters available</p>
                </div>
            );
        }

        return (
            <div className="selects singleSelect">
                {showAutoComplete && <FilterInput onChange={this.filterData} value={filterText} field={field} />}
                {autoCompletedFilterData.length === 0 ? (
                    <p>No items found for this search term</p>
                ) : (
                    <>
                        <ul>
                            {autoCompletedFilterData.slice(0, displayedFilterCount).map((filteritem) => (
                                <li key={`${field}_${filteritem.value}`}>
                                    <CheckBox
                                        label={
                                            <Highlighter
                                                highlight={filterText.split(' ')}
                                                text={filteritem.label || 'none'}
                                            />
                                        }
                                        id={`${field}_${filteritem.label.toString().replace(/\s/g, '_')}`}
                                        isChecked={stringActiveFilter.indexOf(filteritem.value as string) > -1}
                                        onChange={() => {
                                            this.handleSingleSelectChange(filteritem);
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                        {hiddenFilterCount > 0 && (
                            <button type="button" onClick={this.handleExpand} className="more-button zero">
                                + show more ({hiddenFilterCount})
                            </button>
                        )}
                    </>
                )}
            </div>
        );
    }

    renderDateRangePicker() {
        const { activeFilter } = this.props;
        // If the filter was cleared, reset the dates to null (otherwise will default to current date)

        const filterToDisplay = !activeFilter || !activeFilter.length ? [null, null] : activeFilter;
        const startDate = filterToDisplay[0] as string | null;
        const endDate = filterToDisplay[1] as string | null;
        const { filterStart, filterEnd } = this.state;

        return (
            <div>
                <p className="labelSubheader">Filter by date range</p>
                <div className="dateRangeContainer">
                    {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    @ts-ignore */}
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <div className="datePickerContainer">
                            <DatePicker<Date>
                                disableCloseOnSelect={false}
                                value={filterStart || startDate}
                                onChange={(date: Date | null) => (date ? this.handleFilterStart(date) : null)}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                )}
                                toolbarTitle="Select start date"
                            />
                        </div>
                        <div className="datePickerContainer">
                            <DatePicker<Date>
                                disableCloseOnSelect={false}
                                value={filterEnd || endDate}
                                onChange={(date: Date | null) => (date ? this.handleFilterEnd(date) : null)}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                )}
                                toolbarTitle="Select end date"
                            />
                        </div>
                    </LocalizationProvider>
                </div>
            </div>
        );
    }

    render() {
        const { label, filterType, activeFilter = [], setFilter, filterData } = this.props;
        const labelMeta =
            (filterType === 'multiselect' || filterType === 'singleSelect') && activeFilter.length > 0
                ? ` (${activeFilter.length})`
                : '';

        return (
            <div className={`filter filter__${label ? label.split(' ').join('') : ''}`}>
                <div className="labelContainer">
                    <div className="label">
                        {label}
                        {labelMeta}
                    </div>
                    {((filterType === 'multiselect' &&
                        filterData &&
                        filterData.length > MINIMUM_FILTER_OPTIONS_FOR_CLEAR_ALL) ||
                        (filterType === 'dateRange' && filterData)) && (
                        <ClearSubFiltersButton
                            totalActiveFilters={activeFilter.length}
                            size="icon"
                            bodyText={`Clear ${label}`}
                            onClick={() => {
                                if (filterType === 'dateRange') {
                                    this.setState({
                                        filterStart: null,
                                        filterEnd: null,
                                    });
                                }
                                setFilter([]);
                            }}
                        />
                    )}
                </div>
                {this.selectFilterType()}
            </div>
        );
    }
}

export default Filter;
