import React from 'react';
import assortmentColumnSelection from './images/userSettings/AssortmentColumnSelection.png';
import buyplanColumnSelection from './images/userSettings/BuyplanColumnSelection.png';
import buyplanFavoriteStoresSettings from './images/userSettings/BuyplanFavoriteStoresSettings.png';
import settingsIcon from './images/userSettings/SettingsIcon.png';

export default function () {
    return (
        <div className="user-settings">
            <h2>User Settings</h2>
            <h3>Overview</h3>
            <p>
                User Settings in the Buy Plan and in the Assortment can be managed by{' '}
                <strong>clicking the Settings icon</strong> at the top left of their respective tables within the view.{' '}
                <br />
                Your settings are <strong>stored between browser sessions</strong> and they will become your default view
                when opening the assortment and the Buyplan. But don&apos;t worry, you can <em>always go back</em> and adjust
                your settings.
                <br />
                <img width={80} src={settingsIcon} alt="Settings Icon" />
            </p>
            <h3>Assortment plan</h3>
            <h5>Hide and Show Columns</h5>
            <p>
                When it comes to the application that you work in, we want to make sure that we give you the best experience
                for all screen sizes. With this in mind, by adjusting your <strong>settings</strong> you can select the
                amount of detail you wish to have on the material when building your assortment by hiding or showing its
                columns.
                <img src={assortmentColumnSelection} alt="Assortment Column Selection" />
            </p>
            <h3>Buyplan</h3>
            <h5>Hide and Show Columns</h5>
            <p>
                By adjusting your <strong>settings</strong> you can select the amount of detail you wish to have when
                managing your buy plan by hiding or showing its columns. A special mention goes to the{' '}
                <strong>Last Year data</strong> and the <strong>list of Favorite Stores</strong> column (group): whenever
                hiding/showing Last Year or Favorite Stores, it will hide/show the <strong>entire group</strong> of columns.
                <br />
                Note: some columns <strong>cannot be hidden</strong> as they are essential to to the creation of the Buyplan.
                <img src={buyplanColumnSelection} alt="Buy Plan Column Selection" />
            </p>
            <h5>Favorite Stores</h5>
            <p>
                Opening <strong>settings</strong> located above the materials, you can click on the second{' '}
                <strong>tab</strong> where it is possible to <strong>add favorite stores</strong> you wish to have
                prioritized in the Buy Plan view. You can click on the star next to the Store information in order to set it
                as a Favorite Store. <br />
                <em>Favorite stores are priotized in the view</em>: they are ordered on top of the list of stores within a
                certain cluster and they are added in the favorite stores columns list in the Buy Plan table.
                <img src={buyplanFavoriteStoresSettings} alt="Buy Plan Favorite Stores Settings" />
            </p>
        </div>
    );
}
