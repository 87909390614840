import { Season, LoadingStatus } from 'buyplan-common';
import {
    GET_SEASON_DATA,
    NEW_SEASON,
    NEW_SEASON_SUCCESS,
    NEW_SEASON_FAILURE,
    EDIT_SEASON,
    EDIT_SEASON_SUCCESS,
    EDIT_SEASON_FAILURE,
    GET_SEASON_DATA_SUCCESS,
} from '../actions/actionTypes';
import { SeasonActions } from '../actions/season';

export interface SeasonState {
    currentSeason?: Season;
    allSeason?: Season[];
    loadingStatus: {
        newSeason: LoadingStatus;
        newSeasonErrorMessage?: string;
        editSeason: LoadingStatus;
        editSeasonErrorMessage?: string;
    };
}

export const initialState: SeasonState = {
    loadingStatus: {
        newSeason: LoadingStatus.initial,
        editSeason: LoadingStatus.initial,
    },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: SeasonState = initialState, action: SeasonActions): SeasonState => {
    switch (action.type) {
        case GET_SEASON_DATA:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    editSeason: LoadingStatus.initial,
                    newSeason: LoadingStatus.initial,
                },
            };
        case GET_SEASON_DATA_SUCCESS:
            return {
                ...state,
                currentSeason: action.seasonData.currentSeason,
                allSeason: action.seasonData.allSeason,
            };
        case NEW_SEASON:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    newSeason: LoadingStatus.loading,
                    newSeasonErrorMessage: undefined,
                },
            };
        case NEW_SEASON_SUCCESS:
            return {
                ...state,
                currentSeason: action.season,
                loadingStatus: {
                    ...state.loadingStatus,
                    newSeason: LoadingStatus.success,
                    newSeasonErrorMessage: undefined,
                },
            };
        case NEW_SEASON_FAILURE:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    newSeason: LoadingStatus.error,
                    newSeasonErrorMessage: action.error,
                },
            };
        case EDIT_SEASON:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    editSeason: LoadingStatus.loading,
                    editSeasonErrorMessage: undefined,
                },
            };
        case EDIT_SEASON_SUCCESS:
            return {
                ...state,
                currentSeason: action.season,
                loadingStatus: {
                    ...state.loadingStatus,
                    editSeason: LoadingStatus.success,
                    editSeasonErrorMessage: undefined,
                },
            };
        case EDIT_SEASON_FAILURE:
            return {
                ...state,
                loadingStatus: {
                    ...state.loadingStatus,
                    editSeason: LoadingStatus.error,
                    editSeasonErrorMessage: action.error,
                },
            };
        default:
            return state;
    }
};
