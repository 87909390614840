import { useSelector } from '../store/reducers';

/**
 * Returns the season settings
 */
const useSeasonSettings = (): { isHistoricalSeason: boolean; isEditableSeason: boolean } => {
    const seasonState = useSelector((state) => state);
    const { currentSeason, allSeason } = seasonState.season;
    const { activeSeasonId } = seasonState.user.settings;

    if (activeSeasonId && currentSeason && allSeason?.length) {
        const editableSeasons = allSeason?.slice(0, 2).map(({ id }) => id);
        return {
            isHistoricalSeason: currentSeason.id !== activeSeasonId,
            isEditableSeason: editableSeasons.includes(activeSeasonId),
        };
    }

    return { isHistoricalSeason: false, isEditableSeason: true };
};

export default useSeasonSettings;
