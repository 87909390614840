import React from 'react';

export function PureSuccessIcon() {
    return (
        <svg
            aria-hidden="true"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="100%"
            height="100%"
        >
            <path
                fill="currentColor"
                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" // eslint-disable-line max-len
            />
        </svg>
    );
}

function SuccessIcon({ size = 100 }) {
    return (
        <div style={{ width: size, display: 'inline-block' }}>
            <PureSuccessIcon />
        </div>
    );
}

// Used for debugging purposes
SuccessIcon.displayName = 'SuccessIcon';

export default SuccessIcon;
