import { MainFiltersExtended, NewRelicEventName, NewRelicEventStatus, SubFilters, UserProfile } from 'buyplan-common';
import { v4 } from 'uuid';
import { PageView } from '../constants/appConfig';
import { VisibleColumnSetting } from '../reducers/user';
import {
    CLEAR_ACTIVE_SUB_FILTERS,
    CLEAR_FAVORITE_STORES,
    SET_ACCESS_TOKEN,
    SET_ACTIVE_MAIN_FILTERS,
    SET_ACTIVE_SUB_FILTERS,
    SET_USER_PROFILE,
    SET_VISIBLE_COLUMNS,
    SET_CHANNEL,
    TOGGLE_FAVORITE_STORE,
    TOGGLE_VISIBLE_COLUMN,
    SET_SEASON,
    SET_EVENT,
    CLEAR_EVENT,
} from './actionTypes';
import { AnyDispatch, GetRootState } from './types';

export const setUserProfile = (profile: UserProfile) =>
    ({
        type: SET_USER_PROFILE,
        profile,
    } as const);

export type SetUserProfileAction = ReturnType<typeof setUserProfile>;

export const setAccessToken = (accessToken: string) =>
    ({
        type: SET_ACCESS_TOKEN,
        accessToken,
    } as const);

export type SetAccessTokenAction = ReturnType<typeof setAccessToken>;

export const setChannel = (channelId: number) =>
    ({
        type: SET_CHANNEL,
        channelId,
    } as const);

export type SetChannelAction = ReturnType<typeof setChannel>;

export const setSeason = (seasonId: number) =>
    ({
        type: SET_SEASON,
        seasonId,
    } as const);

export type SetSeasonAction = ReturnType<typeof setSeason>;

export const loginUser = (profile: UserProfile) => async (dispatch: AnyDispatch, getState: GetRootState) => {
    // Change active channel if user has no active channel yet or when his privileges were revoked.
    // Do this before updating the profile to ensure in the app there is always an activeChannelId.
    const { activeChannelId } = getState().user.settings;
    if (!activeChannelId || !profile.channels.map(({ id }) => id).includes(activeChannelId)) {
        dispatch(setChannel(profile.channels[0].id));
    }

    dispatch(setUserProfile(profile));
};

export const toggleVisibleColumn = (columnSetting: VisibleColumnSetting, columnKey: string) =>
    ({
        type: TOGGLE_VISIBLE_COLUMN,
        columnSetting,
        columnKey,
    } as const);

export type ToggleVisibleColumnAction = ReturnType<typeof toggleVisibleColumn>;

export const setVisibleColumns = (columnSetting: VisibleColumnSetting, columns: readonly string[]) =>
    ({
        type: SET_VISIBLE_COLUMNS,
        columnSetting,
        columns,
    } as const);

export type SetVisibleColumnsAction = ReturnType<typeof setVisibleColumns>;

export const setActiveMainFilters = (filters: MainFiltersExtended, channelId: number, view: PageView) =>
    ({
        type: SET_ACTIVE_MAIN_FILTERS,
        filters,
        channelId,
        view,
    } as const);

export type SetActiveMainFiltersAction = ReturnType<typeof setActiveMainFilters>;

export const setActiveSubFilters = (filters: SubFilters, channelId: number, view: PageView) =>
    ({
        type: SET_ACTIVE_SUB_FILTERS,
        filters,
        channelId,
        view,
    } as const);

export type SetActiveSubFiltersAction = ReturnType<typeof setActiveSubFilters>;

export const clearActiveSubFilters = (channelId: number, view: PageView) =>
    ({
        type: CLEAR_ACTIVE_SUB_FILTERS,
        view,
        channelId,
    } as const);

export type ClearActiveSubFiltersAction = ReturnType<typeof clearActiveSubFilters>;

export const toggleFavoriteStore = (channelId: number, storeId: string) =>
    ({
        type: TOGGLE_FAVORITE_STORE,
        storeId,
        channelId,
    } as const);

export type ToggleFavoriteStoreAction = ReturnType<typeof toggleFavoriteStore>;

export const clearFavoriteStores = (channelId: number) =>
    ({
        type: CLEAR_FAVORITE_STORES,
        channelId,
    } as const);

export type ClearFavoriteStoresAction = ReturnType<typeof clearFavoriteStores>;

export const setEvent = (key: NewRelicEventName, uuid: string) => ({ type: SET_EVENT, key, uuid } as const);

export type SetEventAction = ReturnType<typeof setEvent>;

export const clearEvent = (key: string) => ({ type: CLEAR_EVENT, key } as const);

export type ClearEventAction = ReturnType<typeof clearEvent>;

/*
    This function is used to send custom events to New Relic to track the performance of key flows in the tool.
    It should be called twice - once at the beginning of the flow and once at the end.
    We then use the timestamps on the event pairs to calculate the time it took to complete the flow.
*/
export const sendCustomNewRelicEvent =
    (eventName: NewRelicEventName, eventStatus: NewRelicEventStatus) =>
    async (dispatch: AnyDispatch, getState: GetRootState) => {
        // Only send events in deployment environments: TEST, SANDBOX, PRODUCTION
        if (process.env.NODE_ENV !== 'production') {
            return;
        }
        if (eventStatus === NewRelicEventStatus.start) {
            const uuid = v4();
            window.newrelic.addPageAction(eventName, { uuid });
            dispatch(setEvent(eventName, uuid));
        } else if (eventStatus === NewRelicEventStatus.end || eventStatus === NewRelicEventStatus.error) {
            const { events } = getState().user;
            const uuid = events[eventName];
            if (uuid !== undefined) {
                window.newrelic.addPageAction(eventName, { uuid });
                dispatch(clearEvent(eventName));
            }
        }
    };

export type UserActions =
    | ClearActiveSubFiltersAction
    | ClearFavoriteStoresAction
    | SetAccessTokenAction
    | SetActiveMainFiltersAction
    | SetActiveSubFiltersAction
    | SetUserProfileAction
    | SetVisibleColumnsAction
    | ToggleFavoriteStoreAction
    | ToggleVisibleColumnAction
    | SetChannelAction
    | SetSeasonAction
    | SetEventAction
    | ClearEventAction;
