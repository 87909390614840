import React, { useState } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Popover from '@material-ui/core/Popover';
import {
    BuyPlanAggregatedMaterial,
    SubFilters,
    FilterOptions,
    BuyplanViewColumnConfig,
    lastYearColumns,
    lastYearColumnKeys,
    DropdownOption,
} from 'buyplan-common';
import { useDispatch } from 'react-redux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { setActiveSubFilters } from '../../actions/user';
import { hasKey } from '../../helpers/typeGuards';
import { findColumn, getColumnWidth } from '../../helpers/tableTools';
import Filter from '../Filters/Filter/Filter';
import HeaderColumn from '../HeaderColumn/HeaderColumn';
import SettingsButton from '../SettingsButton/SettingsButton';
import Dot, { DotSize, DotType } from '../Dot/Dot';
import './HeaderRow.scss';
import FilterControl from '../Filters/FilterControl/FilterControl';
import { PageView } from '../../constants/appConfig';

const FILTER_TYPES = {
    materialCodeChangedAt: 'singleSelect',
    lastYearSellThrough: 'singleSelect',
    reviewedStatus: 'singleSelect',
};

interface Props {
    headerType: 'scrollable' | 'fixed';
    aggregatedMaterials: BuyPlanAggregatedMaterial[];
    columns: BuyplanViewColumnConfig[];
    subFilters: FilterOptions[] | undefined;
    toggleSettings(): void;
    onSortColumn(columnKey: string, orderDirection: 'ASC' | 'DESC'): void;
    orderColumn: string;
    orderDirection: 'ASC' | 'DESC';
    onEditColumn?(columnKey: string): void;
    activeSubFilters?: SubFilters;
    activeChannelId: number;
}

const getHeaderColumns = (columns: BuyplanViewColumnConfig[], headerType: string) => {
    if (headerType === 'scrollable') {
        const isLastYearVisible = !!findColumn(columns, 'lastYear');
        const colsToDisplay = [...columns.filter(({ type, key }) => type === 'scrollable' && key !== 'lastYear')];
        return isLastYearVisible ? [...colsToDisplay, ...lastYearColumns] : colsToDisplay;
    }
    return columns.filter(({ type }) => type === 'fixed');
};

function HeaderRow({
    headerType,
    aggregatedMaterials,
    onEditColumn,
    onSortColumn,
    toggleSettings,
    columns,
    orderColumn,
    orderDirection,
    activeSubFilters,
    activeChannelId,
    subFilters,
}: Props) {
    const dispatch = useDispatch();

    const columnsToDisplay = getHeaderColumns(columns, headerType);
    const invalidBuys = aggregatedMaterials.reduce(
        (total: number, aggregatedMaterial: BuyPlanAggregatedMaterial) => total + aggregatedMaterial.minimumBuyTooLow,
        0
    );
    const [filteredColumn, setFilteredColumn] = useState<string | undefined>();
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | undefined>();

    const handlePopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, key: string | undefined) => {
        setFilteredColumn(key);
        setAnchorEl(event?.currentTarget);
    };

    // Matches the correct filters (also multiple) with the correct column
    const getSubFilterOptions = (subFilterKey: string) =>
        subFilters?.filter(
            (subFilter) =>
                subFilter.field === subFilterKey ||
                // Material code exceptions (additional filters)
                (subFilterKey === 'materialCode' && subFilter.field === 'materialCodeChangedAt')
        );

    return (
        <>
            {columnsToDisplay.map(({ key, label, editable, sortable, filterable, alignRight }) => {
                const subFilterOptions = getSubFilterOptions(key);
                const activeSubFilter = subFilterOptions && activeSubFilters && activeSubFilters[subFilterOptions[0]?.field];
                const isLastYearData = lastYearColumnKeys.includes(key);

                return (
                    <HeaderColumn
                        key={`${key}-${kebabCase(label)}`}
                        className={cn(`ViewBuyplanList__${key}`, {
                            'ViewBuyplanList__header-cell': true,
                            'ViewBuyplanList__header-cell--align-right': alignRight,
                            'ViewBuyplanList__header-cell--lastYear': isLastYearData,
                        })}
                        style={{ width: getColumnWidth(columnsToDisplay, key) }}
                        label={label || ''}
                        onEdit={editable?.bulk ? () => onEditColumn && onEditColumn(key) : undefined}
                        onSort={sortable ? (sortType) => onSortColumn && onSortColumn(key, sortType) : undefined}
                        onFilter={
                            filterable && subFilters
                                ? (event) => {
                                      if (filteredColumn !== key) {
                                          handlePopover(event, key);
                                      } else {
                                          handlePopover(undefined, undefined);
                                      }
                                  }
                                : undefined
                        }
                        isColumnFiltered={activeSubFilter && activeSubFilter.length !== 0}
                        isColumnSorted={orderColumn === key}
                        orderDirection={orderDirection}
                    >
                        {key === 'toggleStores' && (
                            <div className="ViewBuyplanList__header-cell--toggles">
                                <SettingsButton onClick={toggleSettings} />
                                <FilterControl
                                    subFilters={subFilters}
                                    activeChannelId={activeChannelId}
                                    activeSubFilters={activeSubFilters}
                                    view={PageView.buyplan}
                                />
                            </div>
                        )}
                        {key === 'favoriteStore' && (
                            <FontAwesomeIcon icon={faStar as IconProp} className="HeaderRow__star" />
                        )}
                        {key === 'presentationStocks' && invalidBuys > 0 && (
                            <Dot
                                type={DotType.error}
                                tooltip={`Minimum buy missed in ${invalidBuys} ${invalidBuys > 1 ? 'stores' : 'store'}`}
                                placement="top"
                                size={DotSize.large}
                                data-e2e="minimum-buy-error"
                            />
                        )}
                        {filterable && (
                            <Popover
                                anchorEl={anchorEl}
                                onClose={() => handlePopover(undefined, undefined)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={filteredColumn === key}
                                className="HeaderRow__subfilter-popover"
                            >
                                <div key={key} className="sub-filters__filter">
                                    {subFilterOptions &&
                                        subFilterOptions.map((subFilterOption) => (
                                            <Filter
                                                key={`${key}-${subFilterOption.field}`}
                                                setFilter={(values: string[]) => {
                                                    dispatch(
                                                        setActiveSubFilters(
                                                            { [subFilterOption.field]: values },
                                                            activeChannelId,
                                                            PageView.buyplan
                                                        )
                                                    );
                                                }}
                                                activeFilter={
                                                    subFilterOptions &&
                                                    activeSubFilters &&
                                                    activeSubFilters[subFilterOption.field]
                                                }
                                                filterType={
                                                    hasKey(FILTER_TYPES, subFilterOption.field)
                                                        ? FILTER_TYPES[subFilterOption.field]
                                                        : 'multiselect'
                                                }
                                                filterData={subFilterOption.options as DropdownOption[]}
                                                label={subFilterOption.label}
                                                field={subFilterOption.field}
                                                autoCollapse={false}
                                            />
                                        ))}
                                </div>
                            </Popover>
                        )}
                    </HeaderColumn>
                );
            })}
        </>
    );
}

export default HeaderRow;
