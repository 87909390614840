import React from 'react';

export function PureUploadIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 32 32"
            enableBackground="new 0 0 32 32"
            xmlSpace="preserve"
            width="100%"
            height="100%"
        >
            <g>
                <g>
                    <path
                        d="M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266    C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h6v-2H5.997    C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7    c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03    c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4H20v2h6c3.312,0,6-2.693,6-6C32,15.735,30.13,13.407,27.586,12.712z" // eslint-disable-line max-len
                        fill="currentColor"
                    />
                    <polygon points="16,13.5 11,19.5 14,19.5 14,27.5 18,27.5 18,19.5 21,19.5" fill="currentColor" />
                </g>
            </g>
        </svg>
    );
}

function UploadIcon({ size = 100 }) {
    return (
        <div style={{ width: size, display: 'inline-block' }}>
            <PureUploadIcon />
        </div>
    );
}

// Used for debugging purposes
UploadIcon.displayName = 'UploadIcon';

export default UploadIcon;
