import { BuyplanViewColumnConfig, ColumnConfig } from 'buyplan-common';
import cn from 'classnames';
import { findIndex, findLastIndex, sum } from 'lodash';

export const findColumn = <T extends ColumnConfig>(columns: T[], columnKey: string) =>
    columns.find(({ key }) => key === columnKey);

/**
 * Returns column or throws an error when not found.
 */
export const getColumn = <T extends ColumnConfig>(columns: T[], columnKey: string) => {
    const column = columns.find(({ key }) => key === columnKey);
    if (column === undefined) {
        throw new Error(`Unable to find column ${columnKey}`);
    }
    return column;
};

/**
 * Calculates the column width. If the column consists of childColumns returns the sum of the child columns. If the column
 * has no width it returns 0.
 */
export const getColumnWidth = <T extends ColumnConfig>(columns: T[], columnKey: string) => {
    const columnConfig = findColumn(columns, columnKey);
    if (!columnConfig) return 0; // requirement from test (can only happen when type checks are disabled)
    if (columnConfig.childColumns) {
        return sum(Object.values(columnConfig.childColumns).map((cfg) => cfg.width));
    }
    return columnConfig.width ?? 0;
};

/**
 * Returns the width from starting column to the specified end column (included).
 *
 * Returns total width when neither start nor end column are defined.
 */
export const getColumnRangeWidth = <T extends ColumnConfig[]>(columns: T, startColumn?: string, endColumn?: string) => {
    const columnKeys = columns.map(({ key }) => key);
    const firstIndex = startColumn ? findIndex(columnKeys, (element) => element === startColumn) : 0;
    const lastIndex = endColumn ? findLastIndex(columnKeys, (element) => element === endColumn) : columnKeys.length;
    return sum(columnKeys.slice(firstIndex, lastIndex + 1).map((key) => getColumnWidth(columns, key)));
};

export const generateCellStyle = (baseClassName: string, columnKey: string, columns: BuyplanViewColumnConfig[]) => {
    const columnConfig = findColumn(columns, columnKey);
    return {
        className: cn(`${baseClassName}__${columnKey}`, `${baseClassName}__cell`, {
            [`${baseClassName}__cell--align-right`]: columnConfig?.alignRight,
        }),
        style: {
            width: getColumnWidth(columns, columnKey),
        },
    };
};

/**
 * Returns the width between two columns (both start and end column are included) even if they are not passed in as visible.
 */
export const getVisibleColumnWidth = <T extends ColumnConfig[]>(
    allColumns: T,
    visibleColumns: T,
    startColumn?: string,
    endColumn?: string
) => {
    let totalWidth = 0;
    let measuring = false;

    for (let i = 0; i < allColumns.length; i++) {
        const { key, width } = allColumns[i];
        if (startColumn === undefined || key === startColumn) {
            measuring = true;
        }

        if (measuring && findColumn(visibleColumns, key)) {
            totalWidth += width ?? 0;
        }

        if (key === endColumn) {
            return totalWidth;
        }
    }
    return totalWidth;
};

export const syncTableHorizontalScrolls = (event: React.UIEvent<HTMLElement>) => {
    const containers = document.getElementsByClassName(event.currentTarget.className);
    [...containers].forEach((element) => {
        if (event.currentTarget.id !== element.id) {
            // eslint-disable-next-line no-param-reassign
            element.scrollLeft = event.currentTarget.scrollLeft;
        }
    });
};
