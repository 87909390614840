import { UserProfile } from 'buyplan-common';
import { COPY_PRINCIPAL_USER, REPLACE_USER_IN_ACTION, RESET_IMPERSONATION_STATUS } from '../actions/actionTypes';
import { ImpersonationActions } from '../actions/impersonation';
import { UserSettings } from './user';

/*
    impersonationInProgress:
        Identifies if a super user is currently impersonating another user
    principalUser:
        While impersonation is in progress, we replace the UserProfile and UserSettings in user reducer with the profile and settings of the impersonated user.
        During that time, we temporarily store the original/principal user profile and settings here.
*/
export interface ImpersonationState {
    impersonationInProgress: boolean;
    principalUser?: {
        profile: UserProfile;
        settings: UserSettings;
    };
}

export const initialState: ImpersonationState = {
    impersonationInProgress: false,
    principalUser: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: ImpersonationState = initialState, action: ImpersonationActions): ImpersonationState => {
    switch (action.type) {
        case COPY_PRINCIPAL_USER:
            return {
                ...state,
                principalUser: {
                    profile: action.profile,
                    settings: action.settings,
                },
            };
        case REPLACE_USER_IN_ACTION:
            return { ...state, impersonationInProgress: true };
        case RESET_IMPERSONATION_STATUS:
            return initialState;
        default:
            return state;
    }
};
