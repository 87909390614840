import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DummyCodeMaterial as DummyCodeMaterialType } from 'buyplan-common';
import { getDummyCodes, verifyMaterialCodes } from '../../services/materialService';
import Loader from '../Loader/Loader';
import Pagination from '../Pagination/Pagination';
import DummyCodeMaterial from './DummyCodeMaterial';
import './DummyCodes.scss';

interface State {
    loading: boolean;
    pagination: {
        pageSize: number;
        currentPage?: number;
        totalRecords?: number;
        totalPages?: number;
    };
    materials?: DummyCodeMaterialType[];
    error?: string;
    totalVerified?: number;
    verificationError?: string;
}

export function ErrorMessage({ error }: { error?: string }) {
    if (!error) return null;

    return (
        <div className="ErrorMessage">
            <FontAwesomeIcon size="lg" icon={faExclamationTriangle as IconProp} />
            <h3 className="ErrorMessage__body">An error occurred. {error}</h3>
        </div>
    );
}

export class DummyCodes extends PureComponent<unknown, State> {
    state: State = {
        loading: false,
        pagination: {
            currentPage: 0,
            pageSize: 50,
        },
    };

    componentDidMount() {
        this.getDummyCodes();
    }

    handleDummyCodeReplaced = async () => {
        await this.getDummyCodes();
    };

    onChangePageHandler = (page: number) => {
        this.setState(
            (state) => ({ pagination: { ...state.pagination, currentPage: page } }),
            () => this.getDummyCodes()
        );
    };

    getDummyCodes = async () => {
        this.setState({ loading: true, error: undefined, materials: undefined });
        const {
            pagination: { currentPage, pageSize },
        } = this.state;
        try {
            const { data: materials, meta } = await getDummyCodes(currentPage ?? 0, pageSize);
            this.setState({
                materials,
                loading: false,
                pagination: {
                    currentPage: meta.page,
                    totalRecords: meta.totalRecords,
                    totalPages: meta.totalPages,
                    pageSize: meta.pageSize,
                },
            });
        } catch (err: unknown) {
            const error = err as Error;
            this.setState({ loading: false, error: error.message });
        }
    };

    verifyMaterialCodes = async () => {
        this.setState({ loading: true, materials: undefined, totalVerified: undefined, verificationError: undefined });

        try {
            const verifyResponse = await verifyMaterialCodes();
            this.setState({ loading: false, totalVerified: verifyResponse.totalUpdated });
        } catch (err) {
            const error = err as Error;
            this.setState({ loading: false, verificationError: error.message });
        }
        await this.getDummyCodes();
    };

    render() {
        const { materials, loading, error, totalVerified, verificationError, pagination } = this.state;
        const { totalRecords, totalPages } = pagination;

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="DummyCodes">
                            <h1>REPLACE DUMMY CODES {loading && <Loader width={16} />}</h1>
                            <div>
                                <button
                                    type="button"
                                    className="Button"
                                    id="verifyMaterialCodesButton"
                                    onClick={this.verifyMaterialCodes}
                                >
                                    Verify Material Codes
                                </button>
                                {(totalVerified || totalVerified === 0) && (
                                    <h3 className="DummyCodes__verified-feedback">Verified: {totalVerified}</h3>
                                )}
                            </div>

                            <ErrorMessage error={verificationError} />

                            {!!totalRecords && (
                                <Pagination
                                    onChangePage={this.onChangePageHandler}
                                    loading={loading}
                                    totalRecords={totalRecords}
                                    totalPages={totalPages}
                                />
                            )}

                            <table cellPadding={0} cellSpacing={0} className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }} />
                                        <th style={{ width: 80 }}>Material</th>
                                        <th>Description</th>
                                        <th style={{ width: 220 }}>New code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {materials && materials.length > 0 ? (
                                        materials.map((material: DummyCodeMaterialType) => (
                                            <DummyCodeMaterial
                                                key={material.id}
                                                material={material}
                                                onUpdated={this.handleDummyCodeReplaced}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>
                                                {loading && <Loader />}
                                                <ErrorMessage error={error} />
                                                {!loading && totalRecords === 0 && <>No dummy codes found</>}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default DummyCodes;
