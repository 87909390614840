import React, { Component } from 'react';
import { Store, CurrencyType } from 'buyplan-common';
import { AttributedSalesTarget, StoreSalesTarget, putAttributedSalesTarget } from '../../services/salesTargetService';
import { ExchangeRate } from '../../services/exchangeRatesService';
import { INPUT_MAX_VALUE } from '../../constants/appConfig';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';
import { SelectOptionType } from '../ReactSelect/ReactSelect';
import StoreRow from './StoreRow';
import './TargetsRow.scss';

interface Props {
    targetsType: 'store' | 'segment-category-division-consumer';
    item: Store | AttributedSalesTarget;
    currencyType: CurrencyType;
    storeSalesTarget?: StoreSalesTarget;
    exchangeRates?: ExchangeRate[];
    like4likeStores?: SelectOptionType[];
    isEditable: boolean;
}

interface State {
    categoryDivisionSalesTargetUSD: number | null | undefined;
}

class TargetsRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { item } = props;
        this.state = {
            categoryDivisionSalesTargetUSD: (item as AttributedSalesTarget)?.retailSalesTargetUSD ?? null,
        };
    }

    handleChangeRetailSalesTarget = (retailSalesTargetValue: number | null) => {
        this.setState({ categoryDivisionSalesTargetUSD: retailSalesTargetValue });
    };

    updateCategoryDivisionTarget = async () => {
        const newTarget = { retailSalesTargetUSD: this.state.categoryDivisionSalesTargetUSD };
        await putAttributedSalesTarget(this.props.item.id, newTarget);
    };

    render() {
        const { isEditable, targetsType, item, currencyType, like4likeStores, storeSalesTarget, exchangeRates } = this.props;
        const { categoryDivisionSalesTargetUSD } = this.state;

        return targetsType === 'store' ? (
            <StoreRow
                isEditable={isEditable}
                exchangeRates={exchangeRates}
                salesTarget={storeSalesTarget}
                store={item as Store}
                currencyType={currencyType}
                like4likeStores={like4likeStores}
            />
        ) : (
            <tr className="TargetsRow">
                <td className="TargetsRow__partner">{item.partner}</td>
                <td className="TargetsRow__segment">{(item as AttributedSalesTarget).segment}</td>
                <td className="TargetsRow__category">{(item as AttributedSalesTarget).category}</td>
                <td className="TargetsRow__division">{(item as AttributedSalesTarget).division}</td>
                <td className="TargetsRow__consumer">{(item as AttributedSalesTarget).consumer}</td>
                <td className="TargetsRow TargetsRow__category-division-target TargetsRow--minimal-padding">
                    <EnhancedNumberFormat
                        step={1}
                        minimum={0}
                        maximum={INPUT_MAX_VALUE}
                        decimalScale={2}
                        name="categoryDivisionSalesTarget"
                        value={categoryDivisionSalesTargetUSD}
                        className="TargetsRow__input TargetsRow__categoryDivisionSalesTarget"
                        onChange={this.handleChangeRetailSalesTarget}
                        onBlur={this.updateCategoryDivisionTarget}
                        clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                        disabled={!isEditable}
                    />

                    <span> {CurrencyType.USD}</span>
                </td>
            </tr>
        );
    }
}

export default TargetsRow;
