import React from 'react';
import { FamilyModelRowConfig, FamilyModelBreakdownReport } from 'buyplan-common';
import cn from 'classnames';
import { getColumn } from '../../../helpers/tableTools';

interface Props {
    columnKey: string;
    nextColumnKey: string;
    data: FamilyModelBreakdownReport;
    columns: FamilyModelRowConfig[];
}

function FamilyModelTableCell({ columns, columnKey, data, nextColumnKey }: Props) {
    const column = getColumn(columns, columnKey);
    const nextColumn = nextColumnKey ? getColumn(columns, nextColumnKey) : ({} as FamilyModelRowConfig);
    const { key, getValue, noDivider: currentNoDivider } = column;
    const { isGroupStart, noDivider } = nextColumn;
    const rowValue = data[key as keyof FamilyModelBreakdownReport] as number | null;
    const formattedValue = getValue ? getValue(rowValue, false) : rowValue;

    return (
        <div
            style={{ width: column.width }}
            className={cn('FamilyModelRow__cell', {
                FamilyModelRow__divider: isGroupStart && !noDivider,
                'FamilyModelRow--align-right': !currentNoDivider,
            })}
        >
            <span
                key={key}
                className={cn('FamilyModelRow__text', {
                    'FamilyModelRow__text--negative': (rowValue ?? 0) < 0,
                })}
            >
                {formattedValue || <em>N/A</em>}
            </span>
        </div>
    );
}

export default FamilyModelTableCell;
