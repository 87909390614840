import { LastYearReference } from 'buyplan-common';

export const renderPercentage = (percentage: number) => `${Math.round(percentage * 100)}%`;

export const renderLastYearStyle = (reference: LastYearReference | null) => {
    const { materialCode, description } = reference ?? {};
    const elements = [materialCode, description].filter((element) => !!element);
    return elements.length > 0 ? elements.join(' ') : '-';
};

export const subFilters = [
    { field: 'materialCode', label: 'Material Code', options: [] },
    {
        field: 'materialCodeChangedAt',
        label: 'Material Code Changed',
        options: [
            { value: 'LAST 24 HOURS', label: 'LAST 24 HOURS' },
            { value: 'LAST 3 DAYS', label: 'LAST 3 DAYS' },
            { value: 'LAST 7 DAYS', label: 'LAST 7 DAYS' },
            { value: 'LAST 14 DAYS', label: 'LAST 14 DAYS' },
            { value: 'THIS SEASON', label: 'THIS SEASON' },
        ],
    },
    {
        field: 'lateAdds',
        label: 'Late Adds',
        options: [
            { value: 'LAST 24 HOURS', label: 'LAST 24 HOURS' },
            { value: 'LAST 3 DAYS', label: 'LAST 3 DAYS' },
            { value: 'LAST 7 DAYS', label: 'LAST 7 DAYS' },
            { value: 'LAST 14 DAYS', label: 'LAST 14 DAYS' },
            { value: 'THIS SEASON', label: 'THIS SEASON' },
        ],
    },
    { field: 'model', label: 'Models', options: [] },
    { field: 'family', label: 'Families', options: [] },
    {
        field: 'options',
        label: 'Options',
        options: [
            { value: 'ASSORTED', label: 'ASSORTED' },
            { value: 'NOT ASSORTED', label: 'NOT ASSORTED' },
        ],
    },
    { field: 'keyInitiatives', label: 'Key Initiatives', options: [] },
    { field: 'gender', label: 'Gender', options: [] },
    { field: 'age', label: 'Age', options: [] },
    { field: 'keyLookStyle', label: 'Key Look Style', options: [] },
    { field: 'allocated', label: 'Allocated', options: [] },
    { field: 'typeOfOrder', label: 'Type Of Order', options: [] },
    { field: 'storesFlowRange', label: 'Stores Flow', options: [] },
    { field: 'digitalFlowRange', label: 'Digital Flow', options: [] },
    { field: 'pack', label: 'Pack', options: [] },
    { field: 'grade', label: 'Grade', options: [] },
    { field: 'styleRank', label: 'Style rank', options: [] },
    { field: 'reviewedStatus', label: 'Reviewed Status', options: [] },
];
