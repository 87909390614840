import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
    HindsightFile,
    HindsightYearType,
    UploadHindsightBuy,
    UploadHindsightMaterial,
    SourceFile as SourceFileType,
    HindsightFileErrors,
    NewRelicEventName,
    NewRelicEventStatus,
} from 'buyplan-common';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { addHindsightBuys, addHindsightMaterials, uploadHindsightFile } from '../../services/hindsightService';
import FileDropzone from '../FileDropzone/FileDropzone';
import SourceFile, { fileTypes } from '../SourceFile/SourceFile';
import { sendCustomNewRelicEvent } from '../../actions/user';
import { parseHindsightFile } from './parseHindsightFile';

interface Props {
    files: HindsightFile[];
    onDelete(uuid: string): void;
    onSuccess(): void;
    isDisabled?: boolean;
}

function ImportHindsightFile({ onSuccess, files, onDelete, isDisabled }: Props) {
    const [processingFile, setProcessingFile] = useState<SourceFileType & { uuid: string }>();
    const dispatch = useDispatch();

    const parseAndUpload = async (uploadFiles: File[]) => {
        if (uploadFiles === null || uploadFiles.length === 0) {
            return;
        }
        dispatch(sendCustomNewRelicEvent(NewRelicEventName.hindsightFileUpload, NewRelicEventStatus.start));

        const currentProcessingFileAttributes = {
            uuid: v4(),
            progress: 10 as number,
            filename: uploadFiles[0].name,
            filesize: uploadFiles[0].size,
            mimetype: 'text/csv',
        };
        setProcessingFile(currentProcessingFileAttributes);

        const increaseProgress = (percentage: number) => {
            currentProcessingFileAttributes.progress += percentage;
            setProcessingFile({ ...currentProcessingFileAttributes });
        };

        try {
            const { materialsLY, materialsLLY, buysLY, buysLLY } = await parseHindsightFile(
                uploadFiles[0],
                currentProcessingFileAttributes.uuid
            );

            const uploadMaterialsAndBuys = async (
                materials: UploadHindsightMaterial[],
                buys: UploadHindsightBuy[],
                yearType: HindsightYearType
            ) => {
                await addHindsightMaterials(materials, currentProcessingFileAttributes.uuid);
                increaseProgress(20);

                await addHindsightBuys({ buys, yearType }, currentProcessingFileAttributes.uuid);
                increaseProgress(20);
            };

            await uploadHindsightFile(uploadFiles[0], currentProcessingFileAttributes.uuid);
            increaseProgress(10);
            await Promise.all([
                uploadMaterialsAndBuys(materialsLY, buysLY, HindsightYearType.LY),
                uploadMaterialsAndBuys(materialsLLY, buysLLY, HindsightYearType.LLY),
            ]);

            await onSuccess();
            dispatch(sendCustomNewRelicEvent(NewRelicEventName.hindsightFileUpload, NewRelicEventStatus.end));
            setProcessingFile(undefined);
        } catch (err: unknown) {
            const error = err as { meta: HindsightFileErrors };
            if (error.meta?.requireRollback) {
                await onDelete(currentProcessingFileAttributes.uuid);
            }
            dispatch(sendCustomNewRelicEvent(NewRelicEventName.hindsightFileUpload, NewRelicEventStatus.error));
            setProcessingFile({
                ...currentProcessingFileAttributes,
                progress: undefined,
                errors: error.meta,
            });
        }
    };

    return (
        <div className="ImportFiles">
            {files?.length > 0 || processingFile ? (
                <TransitionGroup className="ImportFiles__files" component="ul">
                    <CSSTransition
                        key={processingFile ? processingFile.uuid : files[0].uuid}
                        timeout={{
                            enter: 500,
                            exit: 500,
                        }}
                        classNames="ImportFiles__file--animation"
                    >
                        <li>
                            <div className="ImportFiles__file">
                                <SourceFile
                                    file={processingFile || files[0]}
                                    fileType={fileTypes.csv}
                                    onDelete={async (fileId: string) => {
                                        await onDelete(fileId);
                                        await onSuccess();
                                    }}
                                    onReupload={parseAndUpload}
                                    onCancelError={() => {
                                        if (processingFile) {
                                            onDelete(processingFile.uuid);
                                        }
                                        setProcessingFile(undefined);
                                    }}
                                    narrow={true}
                                    isDisabled={isDisabled}
                                />
                            </div>
                        </li>
                    </CSSTransition>
                </TransitionGroup>
            ) : (
                <FileDropzone
                    acceptedMimeTypes={fileTypes.csv.mime}
                    title={
                        <>
                            Drag &#39;n drop files here or <span className="button">browse</span>
                        </>
                    }
                    onDrop={parseAndUpload}
                    multiple={false}
                    isDisabled={isDisabled}
                />
            )}
        </div>
    );
}

export default ImportHindsightFile;
