import { BuyPlanStore, BuyPlanAggregatedMaterial, FilteringMetaData, LoadingStatus } from 'buyplan-common';
import {
    GET_BUYPLAN_AGGREGATED_MATERIAL_SUCCESS,
    GET_BUYPLAN_META,
    GET_BUYPLAN_META_FAILURE,
    GET_BUYPLAN_META_SUCCESS,
    SET_BUYPLAN_AGGREGATED_MATERIALS,
    REMOVE_BUYPLAN_AGGREGATED_MATERIAL,
    UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_SUCCESS,
    SET_PARTNER_REVIEWED_STATUS,
    SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES,
    UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES,
} from '../actions/actionTypes';
import { BuyPlanActions } from '../actions/buyplan';

interface BuyPlanState {
    metaData: {
        [key: string]: { data: FilteringMetaData; loadingStatus: LoadingStatus };
    };
    aggregatedMaterials: BuyPlanAggregatedMaterial[];
}

export const initialState: BuyPlanState = {
    aggregatedMaterials: [],
    metaData: {},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: BuyPlanState = initialState, action: BuyPlanActions): BuyPlanState => {
    switch (action.type) {
        case GET_BUYPLAN_META: {
            const { view, channelId } = action;
            const selector = `${channelId}|${view}`;
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    [selector]: {
                        ...state.metaData?.[selector],
                        loadingStatus: LoadingStatus.loading,
                    },
                },
            };
        }
        case GET_BUYPLAN_META_SUCCESS: {
            const { metaData, channelId, view } = action;
            const selector = `${channelId}|${view}`;
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    [selector]: {
                        data: metaData,
                        loadingStatus: LoadingStatus.success,
                    },
                },
            };
        }
        case GET_BUYPLAN_META_FAILURE: {
            const { channelId, view } = action;
            const selector = `${channelId}|${view}`;
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    [selector]: {
                        ...state.metaData?.[selector],
                        loadingStatus: LoadingStatus.error,
                    },
                },
            };
        }
        case SET_BUYPLAN_AGGREGATED_MATERIALS:
            return {
                ...state,
                aggregatedMaterials:
                    action.page > 0
                        ? [...state.aggregatedMaterials, ...action.aggregatedMaterials]
                        : action.aggregatedMaterials,
            };
        case UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES: {
            const { view, channelId } = action;
            const selector = `${channelId}|${view}`;
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    [selector]: {
                        ...state.metaData?.[selector],
                        loadingStatus: LoadingStatus.loading,
                    },
                },
            };
        }
        case UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_SUCCESS: {
            const { aggregatedMaterial, channelId, view } = action;
            const selector = `${channelId}|${view}`;
            return {
                ...state,
                aggregatedMaterials: state.aggregatedMaterials.map((currentAggregatedMaterial) =>
                    currentAggregatedMaterial.id === aggregatedMaterial.id ? aggregatedMaterial : currentAggregatedMaterial
                ),
                metaData: {
                    ...state.metaData,
                    [selector]: {
                        ...state.metaData?.[selector],
                        loadingStatus: LoadingStatus.success,
                    },
                },
            };
        }
        case REMOVE_BUYPLAN_AGGREGATED_MATERIAL:
            return {
                ...state,
                aggregatedMaterials: state.aggregatedMaterials.filter(
                    (currentAggregatedMaterial) => currentAggregatedMaterial.id !== action.aggregatedMaterialId
                ),
            };
        case GET_BUYPLAN_AGGREGATED_MATERIAL_SUCCESS:
            return {
                ...state,
                aggregatedMaterials: state.aggregatedMaterials.map((currentAggregatedMaterial) =>
                    currentAggregatedMaterial.id === action.aggregatedMaterial.id
                        ? action.aggregatedMaterial
                        : currentAggregatedMaterial
                ),
            };
        case SET_PARTNER_REVIEWED_STATUS: {
            const { materialId, partnerIds, reviewedStatus } = action;

            return {
                ...state,
                aggregatedMaterials: state.aggregatedMaterials.map((currentAggregatedMaterial) =>
                    currentAggregatedMaterial.materialId === materialId
                        ? {
                              ...currentAggregatedMaterial,
                              stores: currentAggregatedMaterial.stores.map((store: BuyPlanStore) =>
                                  partnerIds.includes(store.partnerId)
                                      ? { ...store, partnerReviewStatus: reviewedStatus }
                                      : store
                              ),
                          }
                        : currentAggregatedMaterial
                ),
            };
        }
        case SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES: {
            const { aggregatedMaterialId, notes } = action;
            return {
                ...state,
                aggregatedMaterials: state.aggregatedMaterials.map((currentAggregatedMaterial) =>
                    currentAggregatedMaterial.id === aggregatedMaterialId
                        ? {
                              ...currentAggregatedMaterial,
                              notes,
                          }
                        : currentAggregatedMaterial
                ),
            };
        }
        default:
            return state;
    }
};
