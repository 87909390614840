import React from 'react';

export default function () {
    return (
        <div>
            <h2>Rate Of Sale Calculation Logic</h2>
            <p>
                The Rate Of Sale represents how many <strong>material units per week</strong> will be needed to be sold in
                order in order to hit the sales target.
            </p>
            <h4>Last Year Rate Of Sale</h4>
            <p>
                The Last Year Rate Of Sale is calculated using the hindsight data. Matching for calculating the Rate Of Sale
                happens at different levels: like direct match, cluster like for like, cluster store like for like, etc. You
                can refer to the{' '}
                <a href="./import-buyplan-files" target="_blank" rel="noopener noreferrer">
                    Import Hindsight Files
                </a>{' '}
                page for more information on the pyramid used for matching.
            </p>
            <h4>Expected Growth Percentage</h4>
            <p>
                Growth is calculated at the category, division, gender and segment level. This is the same level where sales
                target are entered.
            </p>
            <p>
                <code>Sales Retail Value Growth = Retail Sales Target USD / Last Year Retail Sales USD</code>
            </p>
        </div>
    );
}
