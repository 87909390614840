import { ProcessStatus, ProcessLoadStatus, OptionCountsFileErrors } from 'buyplan-common';
import { OnProgress, request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { upload } from './uploadFileService';

interface OptionCountsFilters {
    category?: string;
    division?: string;
    partner?: string;
    store?: string;
}

export const uploadOptionCountsFile = async (
    file: File,
    uuid: string,
    onProgress: OnProgress,
    fetchStatus?: () => Promise<{ data: ProcessStatus }>
) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${uuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `optioncounts-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/optioncountsfiles`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
            uuid,
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
        fetchStatus,
    });
};

export const reuploadOptionCountsFile = async (
    file: File,
    uuid: string,
    onProgress: OnProgress,
    fetchStatus?: () => Promise<{ data: ProcessStatus }>
) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${uuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `optioncounts-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/optioncountsfiles/${uuid}`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
        fetchStatus,
    });
};

export const deleteOptionCountsFile = (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/optioncountsfiles/${uuid}`,
    };

    return request(requestOptions);
};

export const getOptionCountsSummary = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/optioncounts/summary`,
    };

    return request(requestOptions, true);
};

export const getOptionCounts = (page: number, filter: OptionCountsFilters) => {
    const queryParams = { page, filter };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/optioncounts`,
        queryParams,
    };

    return request(requestOptions, true);
};

export const getOptionCountFilterData = (partner: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/optioncounts/meta`,
        queryParams: { partner },
    };

    return request(requestOptions, true);
};

export const convertOptionCountFiles = (status?: ProcessStatus) => {
    if (!status) return [];
    const { uuid, filesize, filename, mimetype, error } = JSON.parse((status?.meta as string) || '{}') as {
        uuid: string;
        filesize: number;
        filename: string;
        mimetype: string;
        error: string;
    };
    if (status?.status === ProcessLoadStatus.deleting)
        return [
            {
                uuid,
                filesize,
                filename,
                mimetype,
                deleting: true,
            },
        ];
    if (status?.status === ProcessLoadStatus.error)
        return [
            {
                uuid,
                filesize,
                filename,
                mimetype,
                errors: {
                    system: error,
                } as unknown as OptionCountsFileErrors,
            },
        ];
    if (status?.status === ProcessLoadStatus.progress) return [{ uuid, filesize, filename, mimetype, progress: 100 }];
    return [{ uuid, filesize, filename, mimetype }];
};
