import React from 'react';
import { StoreWithTargetOptions, Store } from 'buyplan-common';
import './SettingsStoreItem.scss';

interface Props {
    store: StoreWithTargetOptions | Store;
}

function SettingsStoreItem({ store }: Props) {
    return (
        <>
            <span className="SettingsStoreItem">{store.number}</span>
            <span className="SettingsStoreItem">-</span>
            <span className="SettingsStoreItem SettingsStoreItem--primary">{store.name}</span>
            <span className="SettingsStoreItem SettingsStoreItem--secondary">
                {`(${store.country}) - ${store.partner}`}
                {'targetOptionsCount' in store ? `${' '}(${store.targetOptionsCount})` : ''}
            </span>
        </>
    );
}

export default SettingsStoreItem;
