import { Material } from 'buyplan-common';
import { store } from '../../store/createStore';

type Props = Pick<Material, 'materialCode'>;

export const DEFAULT_IMAGE = '//images.nike.com/is/image/DotCom/DEFAULT';

/**
 * There are two different image sources:
 * - Image could be possibly uploaded from the excel upload.
 * - Or the image could be pulled from S3 by using the Sync image button on home page.
 * - The service call check if image is in DB or S3 and returns accordingly.
 */
export const getMaterialImageUrl = ({ materialCode }: Props) =>
    `${process.env.REACT_APP_API_ENDPOINT}/materials/${materialCode}/image?channelId=${
        store.getState().user.settings.activeChannelId
    }&Authorization=Bearer ${store.getState().user.accessToken}`;
