import {
    AllFilters,
    BuyPlanAggregatedMaterial,
    FilteringMetaData,
    MainFiltersExtended,
    BuyToUpdate,
    BuyplanFiles,
    SubFilters,
} from 'buyplan-common';
import { omit } from 'lodash';
import { request } from '../api/api';

export const getBuyPlanFiles = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/files`,
    };

    return request<{ data: BuyplanFiles }>(requestOptions, true);
};

export const getBuyPlan = (
    activeFilters: AllFilters,
    page: number,
    pageSize: number,
    orderColumn: string,
    orderDirection: 'ASC' | 'DESC',
    signal: AbortSignal
) => {
    const queryParams = {
        filter: activeFilters,
        page,
        pageSize,
        orderColumn,
        orderDirection,
    };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan`,
        queryParams,
        signal,
    };

    return request<{ data: BuyPlanAggregatedMaterial[]; meta: { page: number; pageSize: number } }>(requestOptions);
};

export const getBuyPlanAggregatedMaterial = (materialId: string, activeFilters: SubFilters) => {
    const queryParams = {
        filter: activeFilters,
    };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/${materialId}`,
        queryParams,
    };

    return request<{ data: BuyPlanAggregatedMaterial }>(requestOptions);
};

export const getMetaData = (
    subFilters: SubFilters,
    signal?: AbortSignal,
    filterTypes = ['partner', 'category', 'division'],
    { category, division, partner }: MainFiltersExtended = {},
    includeSubFilters = true
) => {
    const queryParams = {
        filter: { category, division, partner },
        includeSubFilters,
        subFilters,
        filterTypes,
    };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/meta`,
        signal,
        queryParams,
    };

    return request<{ data: FilteringMetaData }>(requestOptions);
};

export const updateBuyPlanAggregatedMaterialStores = async (
    activeFilters: AllFilters,
    aggregatedMaterialId: string,
    buys: BuyToUpdate[]
) => {
    const queryParams = {
        filter: activeFilters,
    };
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan`,
        queryParams,
        data: {
            aggregatedMaterialId,
            buys,
        },
    };

    return request<{ data: BuyPlanAggregatedMaterial }>(requestOptions);
};

export const updateFilteredMaterials = async (
    activeFilters: AllFilters,
    field: string,
    value: number | string,
    method: string
) => {
    const queryParams = {
        filter: omit(activeFilters, 'storeId'),
    };
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/bulk-update`,
        queryParams,
        data: {
            field,
            value,
            method,
        },
    };

    return request<{ message: string | undefined }>(requestOptions);
};
