import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useSelector } from '../../../store/reducers';
import { getBuyPlanMetaData } from '../../../actions/buyplan';
import usePrevious from '../../../helpers/usePrevious';
import useActiveFilters from '../../../selectors/useActiveFilters';
import useMetaData from '../../../selectors/useMetaData';
import MainFilters from '../../Filters/MainFilters/MainFilters';
import { PageView } from '../../../constants/appConfig';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { shouldUpdateBuyPlanMeta } from '../../../helpers/filters';
import FamilyModelTabsContainer from './FamilyModelTabsContainer';
import './FamilyModelReport.scss';

interface Props {
    showFamily?: boolean;
    showModel?: boolean;
    showModelMaterial?: boolean;
    showDownloadButton?: boolean;
}

function FamilyModelReport({
    showFamily = true,
    showModel = true,
    showModelMaterial = true,
    showDownloadButton = true,
}: Props) {
    const dispatch = useDispatch();
    const { activeChannelId: channelId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.familyModelReport);
    const { metaData, isLoading } = useMetaData(PageView.familyModelReport);
    const { division, category, partner } = mainFilters;
    const previousPartner = usePrevious(partner);
    const previousCategory = usePrevious(category);
    const previousDivision = usePrevious(division);

    useEffect(() => {
        dispatch(
            getBuyPlanMetaData(
                channelId,
                false,
                reportsFiltersConfig[PageView.familyModelReport].allFilters,
                PageView.familyModelReport
            )
        );
    }, [dispatch, channelId]);

    useEffect(() => {
        // If the required filters (partner / category / division) change, refresh the data in the other filters
        if (!partner || !category || !division) return;
        if (
            shouldUpdateBuyPlanMeta([partner, previousPartner], [category, previousCategory], [division, previousDivision])
        ) {
            dispatch(
                getBuyPlanMetaData(
                    channelId,
                    false,
                    reportsFiltersConfig[PageView.familyModelReport].allFilters,
                    PageView.familyModelReport
                )
            );
        }
    }, [dispatch, channelId, category, division, previousCategory, previousDivision, previousPartner, partner]);

    let disabledFilterKeys: string[] = [];

    // Disable supplementary filters if required filters are missing
    if (!partner || !category || !division) {
        disabledFilterKeys = [...reportsFiltersConfig[PageView.familyModelReport].supplementaryFilters];
    }

    const isMetaLoading = isLoading && isEmpty(metaData);

    return (
        <div className="family-model-report">
            <h1>FAMILY / MODEL REPORT</h1>
            {!isMetaLoading && (
                <>
                    <MainFilters
                        filters={metaData.mainFilters}
                        activeMainFilters={mainFilters}
                        activeChannelId={channelId}
                        disabledFilterKeys={disabledFilterKeys}
                        view={PageView.familyModelReport}
                        isFiltersLoading={isLoading}
                    />
                    <FamilyModelTabsContainer
                        showDownloadButton={showDownloadButton}
                        filters={mainFilters}
                        channelId={channelId}
                        showFamily={showFamily}
                        showModel={showModel}
                        showModelMaterial={showModelMaterial}
                        isButtonDisabled={isLoading}
                        isMetaLoading={isLoading}
                    />
                </>
            )}
        </div>
    );
}

export default FamilyModelReport;
