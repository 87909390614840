import { MainFilters, MarginImpactRecord } from 'buyplan-common';
import { request } from '../api/api';

export const getMarginImpacts = (filter: MainFilters) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/margin-impact`,
        queryParams: { filter },
    };

    return request<{ data: MarginImpactRecord[] }>(requestOptions);
};

export const updateMarginImpact = (id: string, marginImpact: number, ukSalesPercentage: number) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/margin-impact/${id}`,
        queryParams: { marginImpact, ukSalesPercentage },
    };

    return request<{ data: MarginImpactRecord }>(requestOptions, true);
};
