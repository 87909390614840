import React from 'react';
import { formatPercentage, formatUSDOrDash, formatNumberOrDash } from 'buyplan-common';
import {
    TopStylesTotalVariance,
    TopStylesAggregateTotalVariance,
    TopStylesAverageSellingPriceVariance,
} from './TopStylesVariance';
import {
    calculateTotal,
    calculateTotalPercentage,
    calculateAverageSellingPrice,
    TopStylesReportTableData,
    calculateSellThroughTotal,
} from './calculations';
import './TopStylesSummary.scss';
import { ReportYearType } from './TopStylesReport';

interface Props {
    summaryHeader: string;
    report: TopStylesReportTableData;
    previousYear?: TopStylesReportTableData;
    totalRows?: number;
    reportYearType: ReportYearType;
}
function TopStylesSummary({ summaryHeader, report, previousYear, totalRows, reportYearType }: Props) {
    return (
        <tr>
            <td />
            <td />
            <td className="topStylesSummary__header">{summaryHeader}</td>
            <td>
                {formatUSDOrDash(calculateTotal(report, 'revenue', totalRows))}{' '}
                {previousYear && (
                    <TopStylesTotalVariance
                        report={report}
                        previousYear={previousYear}
                        rowType="revenue"
                        totalRows={totalRows}
                    />
                )}
            </td>
            <td>
                {formatNumberOrDash(calculateTotal(report, 'unitSales', totalRows))}
                {previousYear && (
                    <TopStylesTotalVariance
                        report={report}
                        previousYear={previousYear}
                        rowType="unitSales"
                        totalRows={totalRows}
                    />
                )}
            </td>
            <td>
                {formatPercentage(calculateTotalPercentage(report, 'revenue', totalRows))}
                {previousYear && (
                    <TopStylesAggregateTotalVariance
                        report={report}
                        previousYear={previousYear}
                        rowType="revenue"
                        totalRows={totalRows}
                    />
                )}
            </td>
            <td>
                {formatPercentage(calculateTotalPercentage(report, 'unitSales', totalRows))}
                {previousYear && (
                    <TopStylesAggregateTotalVariance
                        report={report}
                        previousYear={previousYear}
                        rowType="unitSales"
                        totalRows={totalRows}
                    />
                )}
            </td>
            <td>
                {formatUSDOrDash(calculateAverageSellingPrice(report, totalRows), false)}
                {previousYear && (
                    <TopStylesAverageSellingPriceVariance
                        report={report}
                        previousYear={previousYear}
                        totalRows={totalRows}
                    />
                )}
            </td>
            <td />
            <td />
            <td />
            {reportYearType === ReportYearType.TY && (
                <>
                    <td>{formatNumberOrDash(calculateTotal(report, 'presentationStocks', totalRows))}</td>
                    <td>{formatNumberOrDash(calculateTotal(report, 'openToBuyUnits', totalRows))}</td>
                </>
            )}

            {reportYearType !== ReportYearType.TY && (
                <>
                    <td>
                        {formatNumberOrDash(calculateTotal(report, 'investUnits', totalRows))}
                        {previousYear && (
                            <TopStylesTotalVariance
                                report={report}
                                previousYear={previousYear}
                                rowType="investUnits"
                                totalRows={totalRows}
                            />
                        )}
                    </td>
                    <td>
                        {formatPercentage(calculateTotalPercentage(report, 'investUnits', totalRows))}
                        {previousYear && (
                            <TopStylesAggregateTotalVariance
                                report={report}
                                previousYear={previousYear}
                                rowType="investUnits"
                                totalRows={totalRows}
                            />
                        )}
                    </td>
                </>
            )}

            <td>
                {formatPercentage(calculateSellThroughTotal(report, reportYearType !== ReportYearType.TY, totalRows))}
                {previousYear && (
                    <TopStylesTotalVariance
                        report={report}
                        previousYear={previousYear}
                        rowType="sellThrough"
                        totalRows={totalRows}
                    />
                )}
            </td>
        </tr>
    );
}

export default TopStylesSummary;
