import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { LateAddMaterial as LateAddMaterialType, SourceFile as SourceFileType, SourceFileErrors } from 'buyplan-common';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { uploadMerchandiseFile } from '../../services/assortmentService';
import { getLateAdds } from '../../services/materialService';
import FileDropzone from '../FileDropzone/FileDropzone';
import SourceFile, { fileTypes } from '../SourceFile/SourceFile';
import Loader from '../Loader/Loader';
import LateAddMaterial from './LateAddMaterial';
import './LateAdds.scss';

let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

export interface LateAddsState {
    uploadingFiles: SourceFileType[];
    materials: LateAddMaterialType[];
    loading: boolean;
    error: string | null;
}

export class LateAdds extends PureComponent<unknown, LateAddsState> {
    state: LateAddsState = {
        uploadingFiles: [],
        materials: [],
        loading: false,
        error: null,
    };

    componentDidMount() {
        this.getLateAdds();
    }

    handleDrop = async (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const uuid = uuidv4();
            this.handleUpload(file, uuid);
        });
    };

    handleUpload = async (file: File, uuid: string) => {
        const { uploadingFiles } = this.state;
        const isSameFileInStateWithError = uploadingFiles.find((f) => f.filename === file.name && !!f.errors);
        if (isSameFileInStateWithError && isSameFileInStateWithError.uuid) {
            this.removeFileFromState(isSameFileInStateWithError.uuid);
            this.uploadFile(file, isSameFileInStateWithError.uuid);
            return;
        }
        this.uploadFile(file, uuid);
    };

    getLateAdds = async () => {
        this.setState({ loading: true, error: null });
        resetAbortController();

        try {
            const { data: materials } = await getLateAdds(abortController.signal);
            this.setState({ materials, loading: false });
        } catch (err: unknown) {
            const error = err as Error;
            this.setState({ error: error.message, loading: false });
        }
    };

    removeMaterialFromState = (materialCode: string) => {
        const { materials } = this.state;
        this.setState({ materials: materials.filter((material) => material.materialCode !== materialCode) });
    };

    removeFileFromState = (uuid?: string) => {
        const { uploadingFiles } = this.state;
        if (uuid) {
            this.setState({
                uploadingFiles: uploadingFiles.filter((f) => f.uuid !== uuid),
            });
        }
    };

    uploadFile = async (file: File, uuid: string) => {
        let { uploadingFiles } = this.state;
        const tempFile = {
            uuid,
            filesize: file.size,
            filename: file.name,
            progress: 0,
            ...file,
        };
        this.setState({ uploadingFiles: [tempFile, ...uploadingFiles] });

        const onProgress = (progress: number) => {
            ({ uploadingFiles } = this.state);
            this.setState({
                uploadingFiles: uploadingFiles.map((f) => (f.uuid === uuid ? { ...f, progress } : f)),
            });
        };

        try {
            await uploadMerchandiseFile(file, uuid, onProgress, { isLateAddsFile: true });
        } catch (err: unknown) {
            ({ uploadingFiles } = this.state);

            const validationErrorTypes = ['MerchandiseValidationError', 'OptionCountsValidationError'];
            // const errors = validationErrorTypes.includes((err.meta || {}).type) ? err.meta : { system: err.message };
            let errors: SourceFileErrors;
            if (
                Object.hasOwnProperty.call(err, 'meta') &&
                validationErrorTypes.includes((err as { meta: SourceFileErrors }).meta.type)
            ) {
                errors = (err as { meta: SourceFileErrors }).meta;
            } else {
                errors = { system: (err as { message: string }).message } as SourceFileErrors;
            }
            this.setState({
                uploadingFiles: uploadingFiles.map((f) => (f.uuid === uuid ? { ...f, progress: undefined, errors } : f)),
            });
            return;
        }

        // When done uploading, move file from state to store
        this.removeFileFromState(uuid);
        this.getLateAdds();
    };

    render() {
        const { materials, uploadingFiles, loading, error } = this.state;
        return (
            <div className="LateAdds">
                <Container>
                    <h1>IMPORT LATE ADDS {loading && <Loader width={16} />}</h1>
                    <Row>
                        <Col>
                            <table cellPadding={0} cellSpacing={0} className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }} />
                                        <th>Material</th>
                                        <th>Description</th>
                                        <th>Category</th>
                                        <th>Division</th>
                                        <th style={{ width: 70 }}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {materials.length > 0 ? (
                                        materials.map((material) => (
                                            <LateAddMaterial
                                                key={material.materialCode}
                                                material={material}
                                                onDelete={this.removeMaterialFromState}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>{loading ? <Loader /> : error || 'No late adds found'}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br />
                            <FileDropzone
                                acceptedMimeTypes={fileTypes.excel.mime}
                                title={
                                    <>
                                        Drag &#39;n drop files here or <span className="button">browse</span>
                                    </>
                                }
                                onDrop={this.handleDrop}
                                multiple={true}
                            />
                            {uploadingFiles.length > 0 && (
                                <TransitionGroup className="ImportFiles__files" component="ul">
                                    {uploadingFiles.map((file) => (
                                        <CSSTransition
                                            key={file.uuid}
                                            timeout={{
                                                enter: 500,
                                                exit: 500,
                                            }}
                                            classNames="ImportFiles__file--animation"
                                        >
                                            <li>
                                                <div className="ImportFiles__file">
                                                    <SourceFile
                                                        file={file}
                                                        fileType={fileTypes.excel}
                                                        onCancelError={() => this.removeFileFromState(file.uuid)}
                                                        narrow={false}
                                                    />
                                                </div>
                                            </li>
                                        </CSSTransition>
                                    ))}
                                </TransitionGroup>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default LateAdds;
