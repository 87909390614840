import React from 'react';
import { Link } from 'react-router-dom';
import AFPercentageUpdate from './images/AFPercentageUpdate.png';
import OTBUpdate from './images/OTBUpdate.png';

export default function () {
    return (
        <div>
            <h2>Glossary</h2>

            <h3>AF% (Authorized Futures)</h3>
            <p>
                <p>
                    AF% is used to calculate how much of the buy will be booked on futures. This is important when a material
                    is just getting switched to AA and we need to make sure we have enough to cover our loadIn. It is also
                    used heavily for Wholesale and NDS accounts as they have to book some of their AA products on their
                    contracts.
                </p>
                <p>
                    If the type of order is NBY, QS, or AF, then a default value of AF% is 100%. Otherwise, if the type of
                    order is AA, a default value of AF% will be 0%.
                </p>
                <p>
                    AF% value is reflected in the OTB units value. OTB units will be split between AA OTB Units and AF OTB
                    Units according to AF%. When you make any changes to AF%, AA and AF OTB Units are being re-calculated. In
                    case the entered AF% cannot be set due to OTB calculations (OTB Units should be integers), the AF% will
                    be automatically re-calculated to the closest one and you&apos;ll see the notification.
                </p>
                <p>
                    <img src={AFPercentageUpdate} alt="AF% Update" />
                </p>
            </p>

            <h3>AUR (Average Unit Retail)</h3>
            <p>Average Unit Retail is calculated as below</p>
            <p>
                <code>
                    {' '}
                    AUR = (Netherlands retail price converted to USD x (1 - UK sales percentage) + (GBP retail price
                    including VAT converted to USD / (1 + UK VAT percentage)) x UK sales percentage x GBP exchange rate) x (1
                    + margin impact)
                </code>
            </p>
            <p>
                In the calculation, the UK retail price including VAT is converted to USD using the set{' '}
                <Link to="/buyplan/documentation/exchange-rates">exchange rate</Link>. The UK sales percentage and margin
                impact fields are set at the category/division/gender level before the season starts using the{' '}
                <Link to="/buyplan/documentation/margin-impact">Margin Impact</Link> page. The UK VAT % can be set at the
                material-level in the merchandise file upload. Otherwise, the default is 0.2 (20%).
            </p>

            <h3>Margin %</h3>
            <p>Margin Percentage</p>
            <p>
                <code> Margin $ = AUR - (Wholesale value (USD) * 0.56)) * Sales units</code>
            </p>
            <p>
                <code> Margin % = 100 * (Margin $ / Sales retail value (USD))</code>
            </p>

            <h3>BOP (beginning of period units)</h3>
            <p>Product inventory at the start of the season.</p>

            <h3>Presentation Stocks</h3>
            <p>The presentation stocks are the sales units compensated by the sell through</p>
            <p>
                For a store buy the presentation stocks are ROS * WOS when no seasonal buy is true, otherwise it is{' '}
                <code>(ROS * WOS) / ST</code>.
            </p>
            <p>
                For a cluster buy it is the sum of the store presentation stocks. NB: This can differ from{' '}
                <code>(cluster ROS * cluster WOS) / cluster ST</code>.
            </p>
            <p>
                The AA presentation stocks are the total presentation stocks for materials where the{' '}
                <code>Type Of Order</code> flag is set to <code>AA</code>. And the AF presentation stocks are the total
                presentation stocks for materials where the <code>Type Of Order</code> flag is set to <code>AF</code>.
            </p>

            <h3>Cluster</h3>
            <p>
                A group of stores from a partner that receive a material. A simple example of a cluster is: AB1234-100
                Ardensport 6 stores. A cluster has a <b>ROS</b> (average ROS of the underlying stores), <b>WOS</b> (which is
                displayed as a range if underlying stores are not all the same), <b>ST</b> (also a range) and the{' '}
                <b>total presentation stocks</b>.
            </p>

            <h3>Open To buy Units</h3>
            <p>OTB is a retail planning tool that aims to budget future inventory orders for a specific period.</p>
            <p>
                <p>
                    This is calculated based on the value of BOP and presentation stocks. There is a small difference in its
                    calculation for digital vs other channels. OTB units are being split to AA OTB Units and AF OTB Units
                    that should be the whole numbers.
                </p>
                <p>
                    AA and AF OTB Units split is done according to AF%. If material has NikeByYou type of order, then AF and
                    AA OTB Units are 0. Otherwise, AF OTB Units will be calculated according to AF%
                </p>
                <p>
                    There&apos;re a couple of cases when the system will adjust entered numbers when OTB is being changed:
                    <ul>
                        <li>
                            In case the entered OTB Units is an odd number and AA/AF OTB units cannot be splitted correctly,
                            AA OTB Units will take precedence, so AA OTB is rounded up and AF OTB is rounded down.
                        </li>
                        <li>
                            In case the entered OTB Units number is less than the minimum one (forced by ST%), the value will
                            be adjusted accordinly to the minimum one.
                        </li>
                    </ul>
                </p>
                <p>
                    <img src={OTBUpdate} alt="OTB Update" />
                </p>
            </p>
            <p>If no seasonal buy, open to buy units will be 0.</p>
            <p>
                For <strong>digital</strong>, open to buy units are calculated by subtracting BOP from the presentation
                stocks. This number does not go below zero.
            </p>
            <p>
                <code>Open to buy units = Buy units - BOP</code>
            </p>
            <p>
                For <strong>all other channels</strong>, we first check if the presentation stocks - sales units are less
                than or equal to the BOP. If that is the case, open to buy units should be set to equal the sales units.
                Otherwise, open to buy units are equal to presentation stocks - BOP. Open to Buy Units should never fall
                below 0.
            </p>
            <p>
                <code>If BOP {`>=`} Presentation Stocks - Sales Units, Open to Buy Units = ROS * WOS</code>
                <code>Else, Open to buy units = Presentation stocks - BOP</code>
            </p>
            <p>
                In all the other cases, the open to buy units will be equal to the <strong>presentation stocks</strong> for
                the current season.
            </p>

            <h3>LY vs. LLY</h3>
            <p>
                Within reports you will find references to LY (Last Year Data) and LLY (2 Years Ago Data). These are there so
                that you are able to make more informed decisions while working through your buy plan process.
            </p>
            <h3>PRY (Planned in retail year)</h3>
            <p>Year that exchange rates using for in Buyplan</p>
            <h3>Price calculations</h3>
            <p>To calculate prices, the AUR is used as the RRP (Recommended retail price)</p>
            <p>
                <code>Sales retail value (USD) = Sales units * AUR</code>
            </p>
            <p>
                If WOS is not set, then the value for <strong>Sales Retail Value USD</strong> is displayed as hyphen (-) in
                the buy plan.
            </p>
            <p>
                <code>Buy retail value (USD) = Presentation stocks * AUR</code>
            </p>
            <p>
                <code>Buy wholesale value (USD) = Presentation stocks * Dutch wholesale price (USD)</code>
            </p>

            <h3>ROS (Rate Of Sale)</h3>
            <p>
                The ROS represents how many <b>material units per week</b> will be needed to be sold in order to hit the
                target.
            </p>

            <h3>No Seasonal Buy</h3>
            <p>
                This flag is used to select a material in the buy plan to not have a buy calculated for that material but
                still register the sales. The reason for this is to show sales that will happen within the season with
                product that is already in the distribution centers, because there are categories within Nike that does buys
                for the full year during one season and then distributes the sales of that category among all seasons through
                out the year.
            </p>

            <h3>Sales Units</h3>
            <p>The sales units are the estimate of the amount of sales that will occur within a store.</p>
            <p>
                <code>ROS * WOS</code>
            </p>

            <h3>Seasonal Option Counts</h3>
            <p>
                Seasonal Option Counts are visible in the <strong>Family/Model Report</strong>,{' '}
                <strong>Silhouette Report</strong>, <strong>Store Level Report</strong> and{' '}
                <strong>Category / Division Report</strong> for Digital partners only. Seasonal Option Counts are the total
                number of distinct materials that have one or more sales units.
                <br />
                <br />
                For LY/LLY Seasonal Option Counts, if a material is marked as seasonal in the Hindsight File, then this
                material is considered seasonal and it will be included in the seasonal option count.
            </p>

            <h3>ST (Sell Through)</h3>
            <p>
                The percentage of product (presentation stocks) that the planner expects the retailer to actually sell. The
                default value for this depends on the type of order it is. For AA, NBY, and QS products the ST% will be 100%
                otherwise it will default to 65%.
            </p>

            <h3>Traded Option Counts</h3>
            <p>
                Traded Option Counts are visible in the <strong>Family/Model Report</strong>,{' '}
                <strong>Silhouette Report</strong>, <strong>Store Level Report</strong> and{' '}
                <strong>Category / Division Report</strong> for Digital partners only. Traded Option Count are the total
                number of distinct materials that have one or more sales units.
                <br />
                <br />
                For LY/LLY Traded Option Counts are the total number of distinct materials from either IPP with sales units
                greater than equal to 50 units or NIKE.COM with sales units greater than equal to 50 units.
            </p>

            <h3>WOS (Weeks On Sale)</h3>
            <p>
                The WOS represents how many weeks the material will be on sale.
                <br /> Normally this is either <b>4, 8, or 13</b>. This is based on the 3 standard <b>Flows per Season</b>{' '}
                (Flow1, Flow2, Flow3).
            </p>
            <p>
                <b>IMPORTANT NOTE:</b> <em>the WOS can`&apos;t be set to more than 53 weeks</em>. If you try to do so, the
                number will fallback to the max (53).
            </p>
        </div>
    );
}
