import React, { /* useMemo, */ useCallback, useState } from 'react';
import {
    BuyPlanAggregatedMaterial,
    BuyPlanStore,
    BuyplanViewColumnConfig,
    PartnerMaterialReviewedStatus,
} from 'buyplan-common';
import { useDispatch } from 'react-redux';
import { updateAssortment } from '../../actions/assortment';
import useActiveChannelSettings from '../../selectors/useActiveChannelSettings';
// TODO: re-work the logic when it will be clear how aggregated view + partner grouping works with fav stores
// https://jira.nike.com/browse/ATEAM-2555
// import { sortStoresFavoritesFirst } from '../../helpers/aggregatedMaterial';
import { getColumnRangeWidth } from '../../helpers/tableTools';
import Modal from '../Modal/Modal';
import Warning from '../Warning/Warning';
import BuyplanAggregatedMaterialDetailRow from './BuyplanAggregatedMaterialDetailRow';
import './BuyplanAggregatedMaterialDetail.scss';

interface Props {
    aggregatedMaterial: BuyPlanAggregatedMaterial;
    onStoreBlur(): void;
    onStoreChange(store: BuyPlanStore): void;
    columns: BuyplanViewColumnConfig[];
    getBuyPlanAggregatedMaterial(aggregatedMaterialId: string): void;
    removeBuyPlanAggregatedMaterial(aggregatedMaterialId: string): void;
    onRemoveStore(): void;
    showMessage: (message: string) => void;
    onChangeStoreOTB(store: BuyPlanStore): void;
    setBuyPlanPartnerReviewedStatus(
        materialId: string,
        partnerIds: string[],
        reviewedStatus: PartnerMaterialReviewedStatus
    ): void;
}

function BuyplanAggregatedMaterialDetail({
    aggregatedMaterial,
    onStoreBlur,
    onStoreChange,
    columns,
    getBuyPlanAggregatedMaterial,
    removeBuyPlanAggregatedMaterial,
    onRemoveStore,
    showMessage,
    onChangeStoreOTB,
    setBuyPlanPartnerReviewedStatus,
}: Props) {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [removedStore, setRemovedStore] = useState<
        { storeNumber: string; storeName: string; storeId: string } | undefined
    >();
    const [isLoading, setIsLoading] = useState(false);

    const { channelId, favoriteStores } = useActiveChannelSettings();

    // TODO: should be fixed in next tickets or removed https://jira.nike.com/browse/ATEAM-2555
    // const stores = useMemo(() => sortStoresFavoritesFirst(aggregatedMaterial.stores, favoriteStores), [aggregatedMaterial.stores, favoriteStores]);

    const onClickRemoveStore = (store: { storeNumber: string; storeName: string; storeId: string }) => {
        setRemovedStore(store);
        setIsModalOpen(true);
    };

    const onCancelRemoveStore = () => {
        setIsModalOpen(false);
        setRemovedStore(undefined);
    };

    const onRemoveAssortmentMaterialStore = useCallback(async () => {
        setIsLoading(true);
        if (removedStore) {
            setIsModalOpen(false);
            await Promise.all([
                dispatch(
                    updateAssortment({
                        storeId: removedStore.storeId,
                        storeNumber: removedStore.storeNumber,
                        materialCode: aggregatedMaterial.materialCode,
                        isActive: false,
                    })
                ),
            ]);
            if (aggregatedMaterial.filteredStoreCount > 1) {
                await getBuyPlanAggregatedMaterial(aggregatedMaterial.id);
            } else {
                // When the filteredStoreCount is only 1, we need to remove the entire aggregatedMaterial instead
                await removeBuyPlanAggregatedMaterial(aggregatedMaterial.id);
            }
        }
        onRemoveStore(); // Updates react-virtualized view in the list
        setIsLoading(false);
        setRemovedStore(undefined);
    }, [
        removedStore,
        onRemoveStore,
        dispatch,
        aggregatedMaterial.materialCode,
        aggregatedMaterial.filteredStoreCount,
        aggregatedMaterial.id,
        getBuyPlanAggregatedMaterial,
        removeBuyPlanAggregatedMaterial,
    ]);

    return (
        <>
            <table className="BuyplanAggregatedMaterialDetail" style={{ width: getColumnRangeWidth(columns, 'rateOfSale') }}>
                <tbody>
                    {Object.keys(aggregatedMaterial.groupedStores).map((partnerName) =>
                        aggregatedMaterial.groupedStores?.[partnerName].map(({ storeId }, index) => {
                            const store = aggregatedMaterial.stores.find((value) => value.storeId === storeId);

                            return store ? (
                                <BuyplanAggregatedMaterialDetailRow
                                    key={storeId}
                                    rowNumber={index}
                                    aggregatedMaterial={aggregatedMaterial}
                                    store={store}
                                    isFavorite={favoriteStores.includes(storeId)}
                                    onStoreBlur={onStoreBlur}
                                    onStoreChange={onStoreChange}
                                    columns={columns}
                                    onRemoveStore={onClickRemoveStore}
                                    loadingRemovedStore={isLoading && removedStore?.storeNumber}
                                    channelId={channelId}
                                    showMessage={showMessage}
                                    onChangeStoreOTB={onChangeStoreOTB}
                                    setBuyPlanPartnerReviewedStatus={setBuyPlanPartnerReviewedStatus}
                                />
                            ) : null;
                        })
                    )}
                </tbody>
            </table>
            <Modal
                isOpen={isModalOpen}
                okAction={() => onRemoveAssortmentMaterialStore()}
                cancelAction={onCancelRemoveStore}
                closeAction={onCancelRemoveStore}
                title="Remove store from assortment"
                okButtonText="Remove store"
            >
                <Warning>
                    <div>
                        You are about to remove the{' '}
                        <strong>
                            {removedStore && removedStore.storeName} ({removedStore && removedStore.storeNumber}){' '}
                        </strong>
                        store for the material{' '}
                        <strong>
                            {aggregatedMaterial.materialCode} ({aggregatedMaterial.model})
                        </strong>{' '}
                        from the assortment. <br />
                        <br />
                        <div className="BuyplanAggregatedMaterialDetail__modal-annotation">
                            NOTE: to add any removed store back again into the buy plan, you will have to navigate to the
                            assortment page and mark its corresponding checkbox.
                        </div>
                    </div>
                </Warning>
            </Modal>
        </>
    );
}

export default BuyplanAggregatedMaterialDetail;
