import { UserProfile, ChannelRole, UserRole, channels } from 'buyplan-common';

/**
 * Converts a user profile to a readable summary of its channel permissions
 * */
export const createPermissionsString = (userProfile: UserProfile) => {
    const channelNames = userProfile.channels
        // Do not display wholesale channel until Buyplan supports it
        .filter((channel) => channel.id !== channels.wholesale.id)
        .map((channel) => (channel.channelRole === ChannelRole.admin ? `${channel.name} (Admin)` : channel.name));

    return userProfile.role === UserRole.superUser ? 'Super User' : channelNames.join(', ');
};
