import React, { CSSProperties } from 'react';
import { kebabCase } from 'lodash';
import { FamilyModelBreakdownReport, FamilyModelRowConfig } from 'buyplan-common';
import FamilyModelTableCell from './FamilyModelTableCell';
import './FamilyModelRow.scss';

interface Props {
    row: FamilyModelBreakdownReport;
    columns: FamilyModelRowConfig[];
    columnType: 'fixed' | 'scrollable';
    style?: CSSProperties;
}

function FamilyModelRow({ row, columns, columnType, style }: Props) {
    const visibleColumns = columns.filter(({ type }) => type === columnType);

    return (
        <div style={style} className="FamilyModelRow">
            {visibleColumns.map(({ key, label }, index) => (
                <FamilyModelTableCell
                    key={`${key}-${kebabCase(label)}`}
                    columnKey={key}
                    nextColumnKey={visibleColumns[index + 1]?.key || ''}
                    data={row}
                    columns={columns}
                />
            ))}
        </div>
    );
}

export default FamilyModelRow;
