import React from 'react';
import { Link } from 'react-router-dom';

export default function () {
    return (
        <div>
            <h2>Family/Model Report</h2>
            <p>This report allows you to see the information for each Family and Model.</p>
            <h3>Summary</h3>
            <p>
                In order to get to this report, <code>CLICK</code> on <strong>Family/Model</strong> under the{' '}
                <strong>Reports</strong> page. In this view you would find tha Family, Model and Model w/ Materials reports
                each as their own tab. The Family report is the default report which is loaded initially.
            </p>
            <h3>Family Report</h3>
            <p>
                The report is grouped by all the families found in this year (assortment data) and can be filtered by
                Partner, Category, Division, Stores, Types of Order, Gender, Age, Silhouette, Family, Model and Segment.{' '}
                <br />
                <br />
                From the filters, Partner, Category, Division are mandatory without which you would not be able to see the
                reports. All other fields are optional and the data is filtered as you go on selecting the filter conditions.
            </p>
            <p>
                In this view, you can find an overview of the total revenue, total revenue percentages, Sales ASP,
                Presentation Stocks etc.
                <br />
                Each parameter except Productivity has the comparison with the available hindsight data i.e. LY and LLY. The
                LY and LLY data comes from the hindsight file which can be imported from the{' '}
                <Link to="/buyplan/import-files">Import Buy Plan Files</Link>.
            </p>

            <h3>Model Report</h3>
            <p>
                The report is grouped by all the models found in this year (assortment data) and can be filtered by Partner,
                Category, Division, Stores, Types of Order, Gender, Age, Silhouette and Segment. <br />
                <br />
                From the filters, Partner, Category, Division are mandatory without which you would not be able to see the
                reports. All other fields are optional and the data is filtered as you go on selecting the filter conditions.
            </p>
            <p>
                This view provides an overview of the total revenue, total revenue percentages, Sales ASP, Presentation
                Stocks etc.
                <br />
                Each parameter except Productivity has the comparison with the available hindsight data i.e. LY and LLY. The
                LY and LLY data comes from the hindsight file which can be imported from the{' '}
                <Link to="/buyplan/import-files">Import Buy Plan Files</Link>.
            </p>

            <h3>Model W/ Materials</h3>
            <p>
                The report is grouped by all the model, category, division, gender and age found in this year (assortment
                data) and can be filtered by Partner, Category, Division, Stores, Types of Order, Gender, Age, Silhouette and
                Segment. <br />
                <br />
                From the filters, Partner, Category, Division are mandatory without which you would not be able to see the
                reports. All other fields are optional and the data is filtered as you go on selecting the filter conditions.
            </p>
            <p>
                In this view, you can find an overview of the total revenue, total revenue percentages, Sales retail value
                USD, Presentation Stocks etc.
                <br />
                The report can be futher drilled down to the Materials which are present under those model, category,
                division, gender and age.
            </p>
            <p>
                <em>
                    NOTE:
                    <ul>
                        <li>
                            The Family/Model report can be downloaded using the <strong>DOWNLOAD REPORT IN EXCEL</strong>{' '}
                            button the report page.
                        </li>
                        <li>This report is available for NDS and DIGITAL</li>
                    </ul>
                </em>
            </p>
        </div>
    );
}
