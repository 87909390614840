import {
    FilteringMetaData,
    AssortmentData,
    AssortmentRecord,
    MaterialLockedForPartnerAssortment,
    Material,
} from 'buyplan-common';
import { uniq } from 'lodash';
import * as assortmentService from '../services/assortmentService';
import { getActiveFiltersDefaultFromMeta } from '../services/filterOptions';
import * as materialService from '../services/materialService';
import { PageView } from '../constants/appConfig';
import {
    SET_ASSORTMENT,
    GET_ASSORTMENT_META,
    GET_ASSORTMENT_META_SUCCESS,
    GET_ASSORTMENT_META_FAILURE,
    ADD_ASSORTMENT_RECORD,
    ADD_ASSORTMENT_RECORD_FAILURE,
    ADD_ASSORTMENT_RECORD_SUCCESS,
    UPDATE_ASSORTMENT_RECORD,
    UPDATE_ASSORTMENT_RECORD_FAILURE,
    UPDATE_ASSORTMENT_RECORD_SUCCESS,
    UPDATE_CLUSTERS_ASSORTMENT_RECORDS,
    UPDATE_CLUSTERS_ASSORTMENT_RECORDS_FAILURE,
    UPDATE_CLUSTERS_ASSORTMENT_RECORDS_SUCCESS,
    UPDATE_ASSORTMENT_CLUSTER,
    UPDATE_ASSORTMENT_CLUSTER_SUCCESS,
    UPDATE_ASSORTMENT_CLUSTER_FAILURE,
    UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT,
    UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_FAILURE,
    UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_SUCCESS,
    UPDATE_ASSORTMENT_MATERIAL,
    UPDATE_ASSORTMENT_MATERIAL_SUCCESS,
    UPDATE_ASSORTMENT_MATERIAL_FAILURE,
} from './actionTypes';
import { setActiveMainFilters } from './user';
import { AnyDispatch, GetRootState } from './types';

// SET ASSORTMENT
export const setAssortmentData = (data: AssortmentData, page: number) =>
    ({
        type: SET_ASSORTMENT,
        data,
        page,
    } as const);

export type SetAssortmentDataAction = ReturnType<typeof setAssortmentData>;

// GET ASSORTMENT META
export const getAssortmentMetaSuccess = (metaData: FilteringMetaData, channelId: number) =>
    ({ type: GET_ASSORTMENT_META_SUCCESS, metaData, channelId, view: PageView.assortment } as const);

export type GetAssortmentMetaSuccessAction = ReturnType<typeof getAssortmentMetaSuccess>;

const getAssortmentMetaFailure = (error: Error) => ({ type: GET_ASSORTMENT_META_FAILURE, error } as const);

export type GetAssortmentMetaFailureAction = ReturnType<typeof getAssortmentMetaFailure>;

export const getAssortmentMetaData =
    (channelId: number, includeSubFilters = true, filterTypes = ['partner', 'category', 'division'], signal?: AbortSignal) =>
    async (dispatch: AnyDispatch, getState: GetRootState) => {
        const { activeFilters } = getState().user.settings;
        const { mainFilters } = activeFilters[`${channelId}|${PageView.assortment}`] ?? { mainFilters: {}, subFilters: {} };
        try {
            dispatch({ type: GET_ASSORTMENT_META });
            const { data: metaData } = await assortmentService.getMetaData(
                filterTypes,
                { ...mainFilters },
                includeSubFilters,
                signal
            );
            dispatch(getAssortmentMetaSuccess(metaData, channelId));
            dispatch(
                setActiveMainFilters(getActiveFiltersDefaultFromMeta(mainFilters, metaData), channelId, PageView.assortment)
            );
        } catch (err: unknown) {
            dispatch(getAssortmentMetaFailure(err as Error));
        }
    };
export type GetAssortmentMetaAction = { type: typeof GET_ASSORTMENT_META };

export const updateAssortmentClusterFailure = ({
    error,
    isActive,
    materialCode,
    clusterId,
    clusterAssortmentStores,
}: {
    error: Error;
    isActive: boolean;
    materialCode: string;
    clusterId: string;
    clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
}) =>
    ({
        type: UPDATE_ASSORTMENT_CLUSTER_FAILURE,
        error,
        clusterAssortmentStores,
        clusterId,
        isActive,
        materialCode,
    } as const);

export type UpdateAssortmentClusterFailureAction = ReturnType<typeof updateAssortmentClusterFailure>;

export const updateAssortmentClusterSuccess = ({
    isActive,
    materialCode,
    clusterId,
    clusterAssortmentStores,
}: {
    isActive: boolean;
    materialCode: string;
    clusterId: string;
    clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
}) => ({ type: UPDATE_ASSORTMENT_CLUSTER_SUCCESS, clusterAssortmentStores, clusterId, isActive, materialCode } as const);

export type UpdateAssortmentClusterSuccessAction = ReturnType<typeof updateAssortmentClusterSuccess>;

export const updateAssortmentCluster =
    ({
        channelId,
        isActive,
        materialCode,
        clusterId,
        clusterAssortmentStores,
    }: {
        channelId: number;
        isActive: boolean;
        materialCode: string;
        clusterId: string;
        clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
    }) =>
    async (dispatch: AnyDispatch, getState: GetRootState) => {
        try {
            const { activeFilters } = getState().user.settings;
            const { mainFilters } = activeFilters[`${channelId}|${PageView.assortment}`] ?? {
                mainFilters: {},
                subFilters: {},
            };

            dispatch({ type: UPDATE_ASSORTMENT_CLUSTER, isActive, clusterId, materialCode, clusterAssortmentStores });
            await assortmentService.updateClustersAssortmentRecords(
                { ...mainFilters },
                {
                    isActive,
                    materialCode,
                    clusterIds: [clusterId],
                }
            );
            dispatch(updateAssortmentClusterSuccess({ isActive, materialCode, clusterAssortmentStores, clusterId }));
        } catch (err: unknown) {
            dispatch(
                updateAssortmentClusterFailure({
                    error: err as Error,
                    isActive,
                    materialCode,
                    clusterId,
                    clusterAssortmentStores,
                })
            );
        }
    };

export type UpdateAssortmentClusterAction = {
    type: typeof UPDATE_ASSORTMENT_CLUSTER;
    isActive: boolean;
    materialCode: string;
    clusterId: string;
    clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[];
};

export const updateAssortmentFailure = (error: Error, assortmentRecord: AssortmentRecord, clusterId?: string) =>
    ({ type: UPDATE_ASSORTMENT_RECORD_FAILURE, error, clusterId, assortmentRecord } as const);

export type UpdateAssortmentFailureAction = ReturnType<typeof updateAssortmentFailure>;

export const updateAssortmentSuccess = (assortmentRecord: AssortmentRecord, clusterId?: string) =>
    ({ type: UPDATE_ASSORTMENT_RECORD_SUCCESS, clusterId, assortmentRecord } as const);

export type UpdateAssortmentSuccessAction = ReturnType<typeof updateAssortmentSuccess>;

export const updateAssortment =
    (assortmentRecord: AssortmentRecord, clusterId?: string) => async (dispatch: AnyDispatch) => {
        try {
            dispatch({ type: UPDATE_ASSORTMENT_RECORD, assortmentRecord, clusterId });
            await assortmentService.updateAssortmentRecord(assortmentRecord);
            dispatch(updateAssortmentSuccess(assortmentRecord, clusterId));
        } catch (err: unknown) {
            dispatch(updateAssortmentFailure(err as Error, assortmentRecord, clusterId));
        }
    };

export type UpdateAssortmentAction = {
    type: typeof UPDATE_ASSORTMENT_RECORD;
    assortmentRecord: AssortmentRecord;
    clusterId: string;
};

export const updateClusterAssortmentRecordsFailure = (
    error: Error,
    isActive: boolean,
    materialCode: string,
    clusterAssortmentStores: { storeId: string; clusterId: string; storeNumber: string }[]
) =>
    ({
        type: UPDATE_CLUSTERS_ASSORTMENT_RECORDS_FAILURE,
        error,
        isActive,
        materialCode,
        clusterAssortmentStores,
    } as const);

export type UpdateClusterAssortmentRecordsAction = {
    type: typeof UPDATE_CLUSTERS_ASSORTMENT_RECORDS;
};

export type UpdateClusterAssortmentRecordsFailureAction = ReturnType<typeof updateClusterAssortmentRecordsFailure>;

export const updateClusterAssortmentRecordsSuccess = (
    isActive: boolean,
    materialCode: string,
    clusterAssortmentStores: { storeNumber: string; storeId: string; clusterId: string }[]
) => ({ type: UPDATE_CLUSTERS_ASSORTMENT_RECORDS_SUCCESS, isActive, materialCode, clusterAssortmentStores } as const);

export type UpdateClusterAssortmentRecordsSuccessAction = ReturnType<typeof updateClusterAssortmentRecordsSuccess>;

export const updateClustersAssortmentRecords =
    ({
        channelId,
        isActive,
        materialCode,
        clusterAssortmentStores,
    }: {
        channelId: number;
        isActive: boolean;
        materialCode: string;
        clusterAssortmentStores: { storeNumber: string; storeId: string; clusterId: string }[];
    }) =>
    async (dispatch: AnyDispatch, getState: GetRootState) => {
        try {
            dispatch({ type: UPDATE_CLUSTERS_ASSORTMENT_RECORDS });
            const { activeFilters } = getState().user.settings;
            const { mainFilters } = activeFilters[`${channelId}|${PageView.assortment}`] ?? {
                mainFilters: {},
                subFilters: {},
            };
            // Only update stores that have a cluster assignment
            const clusterAssortmentStoresWithValidCluster = clusterAssortmentStores.filter(
                ({ clusterId }) => clusterId !== 'mock_cluster_id'
            );
            const clusterIds = uniq(clusterAssortmentStoresWithValidCluster.map(({ clusterId }) => clusterId));
            await assortmentService.updateClustersAssortmentRecords(
                { ...mainFilters },
                {
                    isActive,
                    materialCode,
                    clusterIds,
                }
            );

            dispatch(updateClusterAssortmentRecordsSuccess(isActive, materialCode, clusterAssortmentStoresWithValidCluster));
        } catch (err: unknown) {
            dispatch(updateClusterAssortmentRecordsFailure(err as Error, isActive, materialCode, clusterAssortmentStores));
        }
    };

export const updateMaterialsLockForPartnerAssortmentFailure = (
    error: Error,
    materialLockedForPartners: MaterialLockedForPartnerAssortment[]
) => ({ type: UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_FAILURE, error, materialLockedForPartners } as const);

export type UpdateMaterialsLockForPartnerAssortmentFailureAction = ReturnType<
    typeof updateMaterialsLockForPartnerAssortmentFailure
>;
export const updateMaterialsLockForPartnerAssortmentSuccess = (
    materialLockedForPartners: MaterialLockedForPartnerAssortment[]
) => ({ type: UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_SUCCESS, materialLockedForPartners } as const);

export type UpdateMaterialsLockForPartnerAssortmentSuccessAction = ReturnType<
    typeof updateMaterialsLockForPartnerAssortmentSuccess
>;

export const updateMaterialsLockForPartnerAssortment =
    (materialLockedForPartners: MaterialLockedForPartnerAssortment[]) => async (dispatch: AnyDispatch) => {
        try {
            dispatch({ type: UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT, materialLockedForPartners });
            await assortmentService.updateMaterialLockForPartnerAssortment(materialLockedForPartners);
            dispatch(updateMaterialsLockForPartnerAssortmentSuccess(materialLockedForPartners));
        } catch (err: unknown) {
            dispatch(updateMaterialsLockForPartnerAssortmentFailure(err as Error, materialLockedForPartners));
        }
    };

export type UpdateMaterialsLockForPartnerAssortmentAction = {
    type: typeof UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT;
    materialLockedForPartners: MaterialLockedForPartnerAssortment[];
};

export const addToAssortmentRecordFailure = (error: Error, assortmentRecord: AssortmentRecord, clusterId: string) =>
    ({ type: ADD_ASSORTMENT_RECORD_FAILURE, error, assortmentRecord, clusterId } as const);

export type AddAssortmentRecordFailureAction = ReturnType<typeof addToAssortmentRecordFailure>;

export const addToAssortmentRecordSuccess = (assortmentRecord: AssortmentRecord, clusterId: string) =>
    ({ type: ADD_ASSORTMENT_RECORD_SUCCESS, assortmentRecord, clusterId } as const);

export type AddAssortmentRecordSuccessAction = ReturnType<typeof addToAssortmentRecordSuccess>;

export type AddAssortmentRecordAction = {
    type: typeof ADD_ASSORTMENT_RECORD;
    assortmentRecord: AssortmentRecord;
    clusterId: string;
};

export const addToAssortment = (assortmentRecord: AssortmentRecord, clusterId: string) => async (dispatch: AnyDispatch) => {
    try {
        dispatch({ type: ADD_ASSORTMENT_RECORD, assortmentRecord, clusterId });
        await assortmentService.addAssortmentRecord(assortmentRecord);
        dispatch(addToAssortmentRecordSuccess(assortmentRecord, clusterId));
    } catch (err: unknown) {
        dispatch(addToAssortmentRecordFailure(err as Error, assortmentRecord, clusterId));
    }
};

export type UpdateAssortmentMaterialAction = {
    type: typeof UPDATE_ASSORTMENT_MATERIAL;
    materialId: string;
    materialAttributes: Partial<Material>;
};

export const updateAssortmentMaterialFailure = (error: Error, materialId: string, material: Material) =>
    ({ type: UPDATE_ASSORTMENT_MATERIAL_FAILURE, error, materialId, material } as const);

export type UpdateAssortmentMaterialFailureAction = ReturnType<typeof updateAssortmentMaterialFailure>;

export const updateAssortmentMaterialSuccess = (materialId: string, materialAttributes: Partial<Material>) =>
    ({ type: UPDATE_ASSORTMENT_MATERIAL_SUCCESS, materialId, materialAttributes } as const);

export type UpdateAssortmentMaterialSuccessAction = ReturnType<typeof updateAssortmentMaterialSuccess>;

export const updateAssortmentMaterial =
    (materialId: string, material: Material, materialAttributes: Partial<Material>) => async (dispatch: AnyDispatch) => {
        try {
            dispatch({ type: UPDATE_ASSORTMENT_MATERIAL, materialId, materialAttributes });
            await materialService.patch(materialId, materialAttributes);

            dispatch(updateAssortmentMaterialSuccess(materialId, materialAttributes));
        } catch (err: unknown) {
            const materialUpdateError = err as Error;
            dispatch(updateAssortmentMaterialFailure(materialUpdateError, materialId, material));

            throw new Error(materialUpdateError.message);
        }
    };

export type AssortmentActions =
    | SetAssortmentDataAction
    | GetAssortmentMetaAction
    | GetAssortmentMetaSuccessAction
    | GetAssortmentMetaFailureAction
    | AddAssortmentRecordAction
    | AddAssortmentRecordFailureAction
    | AddAssortmentRecordSuccessAction
    | UpdateAssortmentAction
    | UpdateAssortmentFailureAction
    | UpdateAssortmentSuccessAction
    | UpdateClusterAssortmentRecordsAction
    | UpdateClusterAssortmentRecordsFailureAction
    | UpdateClusterAssortmentRecordsSuccessAction
    | UpdateMaterialsLockForPartnerAssortmentAction
    | UpdateMaterialsLockForPartnerAssortmentSuccessAction
    | UpdateMaterialsLockForPartnerAssortmentFailureAction
    | UpdateAssortmentMaterialAction
    | UpdateAssortmentMaterialFailureAction
    | UpdateAssortmentMaterialSuccessAction
    | UpdateAssortmentClusterFailureAction
    | UpdateAssortmentClusterSuccessAction
    | UpdateAssortmentClusterAction;
