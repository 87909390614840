import { UserProfile } from 'buyplan-common';
import { useSelector } from '../store/reducers';

/**
 * Returns the impersonation settings:
 * Status if impersonation is in progress and impersonated user profile
 */
const useImpersonationStatus = (): { impersonationInProgress: boolean; impersonatedProfile?: UserProfile } => {
    const { impersonationInProgress } = useSelector(({ impersonation }) => impersonation);
    const { profile } = useSelector(({ user }) => user);

    return {
        impersonationInProgress,
        impersonatedProfile: impersonationInProgress ? profile : undefined,
    };
};

export default useImpersonationStatus;
