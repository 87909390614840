import React, { memo } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from '../Button/Button';
import Tooltip, { TooltipType } from '../Tooltip/Tooltip';
import './ClearSubFiltersButton.scss';

interface Props {
    totalActiveFilters: number;
    fullWidth?: boolean;
    size?: 'regular' | 'small' | 'icon';
    isBuyPlanSubFilters?: boolean; // special case, position absolute
    bodyText?: string;
    onClick: () => void;
}

function ClearSubFiltersButton({
    totalActiveFilters,
    fullWidth = true,
    size = 'regular',
    isBuyPlanSubFilters = false,
    bodyText = 'Remove all filters',
    onClick,
}: Props) {
    const disabled = !totalActiveFilters;

    if (size === 'icon') {
        return (
            <button
                type="button"
                onClick={() => onClick()}
                className={cn('ClearSubFiltersButton__icon', {
                    'ClearSubFiltersButton__icon--disabled': disabled,
                })}
                disabled={disabled}
            >
                <Tooltip
                    type={disabled ? TooltipType.default : TooltipType.error}
                    placement="top"
                    tooltip={disabled ? 'No filter to clear' : `${bodyText} (${totalActiveFilters})`}
                >
                    <FontAwesomeIcon size="sm" icon={faFilter as IconProp} />
                </Tooltip>
            </button>
        );
    }

    return (
        <Button
            className={cn('ClearSubFiltersButton', 'ClearSubFiltersButton__button', 'Button__inverted', {
                'Button-fullWidth': fullWidth,
                'ClearSubFiltersButton__button--disabled': disabled,
                'ClearSubFiltersButton__button--small': size === 'small',
                'ClearSubFiltersButton__button-buy-plan-sub-filter': isBuyPlanSubFilters,
            })}
            disabled={disabled}
            onClick={() => onClick()}
        >
            {bodyText} ({totalActiveFilters})
        </Button>
    );
}

export default memo(ClearSubFiltersButton);
