import React from 'react';
import cn from 'classnames';
import { TypeOfOrderBreakdownReport, typeOfOrderRowsConfig } from 'buyplan-common';
import './TypeOfOrderReportRow.scss';

interface Props {
    row: TypeOfOrderBreakdownReport;
}

function TypeOfOrderReportRow({ row }: Props) {
    return (
        <tr className="typeOfOrderReportRow">
            <td>{row.typeOfOrder}</td>
            {typeOfOrderRowsConfig.map((rowConfig) => {
                const { key, groupStartHeader } = rowConfig;
                const rowValue = row[key as keyof TypeOfOrderBreakdownReport] as number | null;
                const rowFormattedValue = rowConfig.getValue(rowValue, false);

                return (
                    <td
                        key={key}
                        className={cn({
                            typeOfOrderReportRow__divider: !!groupStartHeader,
                            'typeOfOrderReportRow__value--negative': (rowValue ?? 0) < 0,
                        })}
                    >
                        {rowFormattedValue}
                    </td>
                );
            })}
        </tr>
    );
}

export default TypeOfOrderReportRow;
