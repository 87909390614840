import React, { PureComponent } from 'react';
import cn from 'classnames';
import { isEqual, sum, includes } from 'lodash';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { List, CellMeasurer, CellMeasurerCache, ListRowProps } from 'react-virtualized';
import {
    BuyPlanAggregatedMaterial,
    PagingMetadata,
    PartnerTotal,
    Store,
    BuyPlanTotals,
    FilteringMetaData,
    FilterOptions,
    PartnerTotalsSum,
    LoadingStatus,
    BuyplanViewColumnConfigFavorite,
    viewBuyplanColumns,
    BuyplanViewColumnConfig,
} from 'buyplan-common';
import { getAppliedFiltersCount, getMissingMainFilterKeys } from '../../helpers/utils';
import { combineWords } from '../../helpers/language';
import { getColumnRangeWidth, getColumnWidth } from '../../helpers/tableTools';
import { clearActiveSubFilters as clearActiveSubFiltersAction } from '../../actions/user';
import { ActiveFiltersType, UserState } from '../../reducers/user';
import BuyPlanSettings from '../BuyPlanSettings/BuyPlanSettings';
import Loader from '../Loader/Loader';
import InfiniteScrollList from '../InfiniteScrollList/InfiniteScrollList';
import BulkUpdateModal from '../BulkUpdateModal/BulkUpdateModal';
import { PageView } from '../../constants/appConfig';
import Modal from '../Modal/Modal';
import ViewBuyplanAggregatedMaterial from './ViewBuyplanAggregatedMaterial';
import ClearSubFiltersButton from './ClearSubFiltersButton';
import BuyPlanPartnerTotals from './BuyPlanPartnerTotals';
import SubheaderRow from './SubheaderRow';
import HeaderRow from './HeaderRow';
import './ViewBuyplanList.scss';

const ROW_HEIGHT = 48;

interface Props {
    activeFilters: ActiveFiltersType;
    aggregatedMaterials: BuyPlanAggregatedMaterial[];
    favoriteStores: string[];
    getBuyplan(page: number, orderColumn?: string, orderDirection?: 'ASC' | 'DESC'): Promise<void>;
    getPartnerTotals(): void;
    makeTotalsOutOfDate(): void;
    totalsOpened: boolean;
    totalsLoading: boolean;
    totalsOutOfDate: boolean;
    loading: boolean;
    partnerTotals: PartnerTotal[];
    partnerTotalsSum: PartnerTotalsSum;
    stores: Store[];
    visibleBuyPlanColumnKeys: string[];
    activeChannelId: number;
    // We enhance the type with the (synthetic) field totalFetched to patch its absence from the backend data
    pagination?: PagingMetadata & { totalFetched: number };
    orderBy: {
        columnKey: string;
        direction: 'ASC' | 'DESC';
    };
    buyPlanTotals: BuyPlanTotals;
    subFilters: FilterOptions[] | undefined;
    clearActiveSubFilters(channelId: number, view: PageView): void;
}

type ModalState = {
    isOpen: boolean;
    type: string;
};

interface State {
    modal: ModalState;
    isSettingsOpen: boolean;
    // The columns are manually enhanced with FavoriteStores, in this component
    columns: BuyplanViewColumnConfigFavorite[];
    openAggregatedMaterials: string[];
    userDialog: {
        isOpen: boolean;
        message?: string;
    };
}

export class ViewBuyplanList extends PureComponent<Props, State> {
    state = {
        isSettingsOpen: false,
        modal: {} as ModalState,
        columns: [] as BuyplanViewColumnConfigFavorite[],
        openAggregatedMaterials: [],
        userDialog: {
            isOpen: false,
            message: '',
        },
    };

    scrollableListRef = React.createRef<List>();
    fixedListRef = React.createRef<List>();

    // Each List needs a separate cache
    columnsCache = {
        fixed: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
        scrollable: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
    };

    componentDidMount() {
        this.generateColumnConfig();
    }

    componentDidUpdate(prevProps: Props) {
        const { favoriteStores, visibleBuyPlanColumnKeys, activeFilters, orderBy } = this.props;
        const hasFilterChanged = !isEqual(prevProps.activeFilters, activeFilters);
        const hasOrderChanged =
            !isEqual(orderBy.direction, prevProps.orderBy.direction) ||
            !isEqual(orderBy.columnKey, prevProps.orderBy.columnKey);
        if (hasFilterChanged || hasOrderChanged) {
            this.columnsCache.fixed.clearAll();
            this.columnsCache.scrollable.clearAll();
            this.setState({ openAggregatedMaterials: [] });
            this.generateColumnConfig();
        }
        if (
            !isEqual(prevProps.visibleBuyPlanColumnKeys, visibleBuyPlanColumnKeys) ||
            !isEqual(prevProps.favoriteStores, favoriteStores)
        ) {
            this.generateColumnConfig();
        }
    }

    handleToggleAggregatedMaterial = (aggregatedMaterialIndex: number) => {
        this.setState(
            ({ openAggregatedMaterials = [] }) => {
                const aggregatedMaterialId = this.props.aggregatedMaterials[aggregatedMaterialIndex].id;
                // If already open, remove it from the open aggregated materials list
                if (openAggregatedMaterials.includes(aggregatedMaterialId)) {
                    return {
                        openAggregatedMaterials: openAggregatedMaterials.filter(
                            (openAggregatedMaterialId) => openAggregatedMaterialId !== aggregatedMaterialId
                        ),
                    };
                }
                return { openAggregatedMaterials: [...openAggregatedMaterials, aggregatedMaterialId] };
            },
            () => this.updateListView(aggregatedMaterialIndex)
        );
    };

    toggleUserMessageDialog = (message: string) => {
        const { userDialog } = this.state;
        this.setState({
            userDialog: {
                isOpen: !userDialog.isOpen,
                message,
            },
        });
    };

    closeUserMessageDialog = () => {
        this.setState({
            userDialog: {
                isOpen: false,
                message: '',
            },
        });
    };

    toggleSettings = () => {
        const { isSettingsOpen } = this.state;
        this.setState({ isSettingsOpen: !isSettingsOpen });
    };

    toggleModal = (modalType?: string) => {
        if (modalType) {
            return this.setState({
                modal: {
                    isOpen: true,
                    type: modalType,
                },
            });
        }
        return this.setState({
            modal: {
                isOpen: true,
                type: '',
            },
        });
    };

    // react-virtualized needs to recompute the heights and widths of the table
    updateListView = (aggregatedMaterialIndex: number) => {
        this.columnsCache.fixed.clear(aggregatedMaterialIndex, 0);
        this.columnsCache.scrollable.clear(aggregatedMaterialIndex, 0);
        if (this.scrollableListRef.current) {
            this.scrollableListRef.current.recomputeRowHeights(aggregatedMaterialIndex);
        }
        if (this.fixedListRef.current) {
            this.fixedListRef.current.recomputeRowHeights(aggregatedMaterialIndex);
        }
    };

    /**
     * Generates a column configuration based on the viewBuyplanColumns config by making the following changes:
     *
     * 1) remove columns that the user has hidden in their user settings
     * 2) repeat the "favoriteStore"-column config for all favorite stores of currently selected partners, or remove
     *    the favoriteStore config if there are none.
     */
    generateColumnConfig = () => {
        const {
            visibleBuyPlanColumnKeys,
            activeFilters: { mainFilters },
            favoriteStores,
            stores,
        } = this.props;
        const columns = viewBuyplanColumns.filter(
            (viewBuyplanColumn: BuyplanViewColumnConfig) =>
                visibleBuyPlanColumnKeys.includes(viewBuyplanColumn.key) || viewBuyplanColumn.isFixed
        );
        const favoriteStoreTemplateIndex = columns.findIndex((col: BuyplanViewColumnConfig) => col.key === 'favoriteStore');
        if (favoriteStoreTemplateIndex >= 0) {
            const [favoriteConfig] = columns.splice(favoriteStoreTemplateIndex, 1);
            const partnerFavoriteStores = stores.filter(
                (store) =>
                    favoriteStores.includes(store.id) && mainFilters.partner && mainFilters.partner.includes(store.partner)
            );
            if (partnerFavoriteStores.length > 0) {
                const favoriteStoreColumnConfigs = partnerFavoriteStores.map((favoriteStore) => ({
                    ...favoriteConfig,
                    label: favoriteStore.name,
                    favoriteStore,
                }));
                columns.splice(favoriteStoreTemplateIndex, 0, ...favoriteStoreColumnConfigs);
            }
        }
        this.setState({ columns } as { columns: BuyplanViewColumnConfigFavorite[] });
    };

    calculateColumnWidths = (columnType: string) => {
        const { columns } = this.state;
        const totalColumnWidth = getColumnRangeWidth(columns);

        const fixedColumnsWidth =
            sum(columns.filter(({ type }) => type === 'fixed').map(({ key }) => getColumnWidth(columns, key))) + 20;
        const scrollableColumnsWidth = totalColumnWidth - fixedColumnsWidth + 80; // Needs extra buffer for padding and scrollbar

        if (columnType === 'fixed') {
            return fixedColumnsWidth;
        }
        return scrollableColumnsWidth;
    };

    renderColumns = (listRowProps: ListRowProps, type: 'fixed' | 'scrollable') => {
        const { index, style, key, parent } = listRowProps;
        const { aggregatedMaterials, makeTotalsOutOfDate, activeChannelId } = this.props;
        const { openAggregatedMaterials, columns } = this.state;
        const aggregatedMaterial = aggregatedMaterials[index];

        return (
            <CellMeasurer cache={this.columnsCache[type]} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <ViewBuyplanAggregatedMaterial
                    key={key}
                    columnType={type}
                    aggregatedMaterial={aggregatedMaterial}
                    onToggleAggregatedMaterial={() => this.handleToggleAggregatedMaterial(index)}
                    style={style}
                    isAggregatedMaterialOpen={includes(openAggregatedMaterials, aggregatedMaterial.id)}
                    makeTotalsOutOfDate={makeTotalsOutOfDate}
                    columns={columns}
                    channelId={activeChannelId}
                    onRemoveStore={() => this.updateListView(index)} // If we remove a store, the aggregated material height must be recalculated
                    showMessage={this.toggleUserMessageDialog}
                />
            </CellMeasurer>
        );
    };

    render() {
        const {
            aggregatedMaterials = [],
            activeFilters,
            loading,
            partnerTotals,
            partnerTotalsSum,
            pagination,
            getBuyplan,
            orderBy,
            activeChannelId,
            buyPlanTotals,
            subFilters,
            clearActiveSubFilters,
            getPartnerTotals,
            totalsOpened,
            totalsLoading,
            totalsOutOfDate,
            makeTotalsOutOfDate,
        } = this.props;
        const { columns, isSettingsOpen, modal, userDialog } = this.state;

        const totalActiveFilters = getAppliedFiltersCount(activeFilters.subFilters);

        const missingMainFilterKeys = getMissingMainFilterKeys(activeFilters.mainFilters);

        const noResultsMainFilters = !loading && aggregatedMaterials.length === 0 && !totalActiveFilters;

        const noResultsSubFiltersApplied = !loading && aggregatedMaterials.length === 0 && totalActiveFilters > 0;

        if (missingMainFilterKeys.length) {
            return (
                <div className="ViewBuyplanList__noResults">{`Please select a filter for ${combineWords(
                    missingMainFilterKeys
                )}`}</div>
            );
        }

        if (noResultsMainFilters) {
            return <div className="ViewBuyplanList__noResults">No materials found for the given filters.</div>;
        }

        const headerProps = {
            aggregatedMaterials,
            subFilters,
            onEditColumn: (columnKey: string) => this.toggleModal(columnKey),
            toggleSettings: () => this.toggleSettings(),
            columns,
            onSortColumn: (columnKey: string, direction: 'ASC' | 'DESC') => {
                getBuyplan(0, columnKey, direction);
            },
            orderColumn: orderBy.columnKey,
            orderDirection: orderBy.direction,
            activeSubFilters: activeFilters.subFilters,
        };

        return (
            <div
                className={cn('ViewBuyplanList', {
                    'ViewBuyplanList--loading': loading && pagination && pagination.page === 0,
                })}
            >
                <BuyPlanPartnerTotals
                    getPartnerTotals={getPartnerTotals}
                    partnerTotals={partnerTotals}
                    partnerTotalsSum={partnerTotalsSum}
                    totalsOpened={totalsOpened}
                    totalsLoading={totalsLoading}
                    totalsOutOfDate={totalsOutOfDate}
                />
                <BulkUpdateModal
                    open={
                        [
                            'marginImpact',
                            'ukSalesPercentage',
                            'rateOfSale',
                            'sellThrough',
                            'weeksOnSale',
                            'openToBuyUnits',
                            'afPercentage',
                        ].includes(modal.type) && modal.isOpen
                    }
                    onClose={() => this.toggleModal()}
                    type={modal.type}
                    activeFilters={activeFilters}
                    channelId={activeChannelId}
                    orderBy={orderBy}
                    view={PageView.buyplan}
                    getLatest={getBuyplan}
                    makeTotalsOutOfDate={makeTotalsOutOfDate}
                />
                <Modal
                    isOpen={userDialog.isOpen}
                    style={{ content: { width: 485 } }}
                    title="Aggregated material update"
                    closeAction={this.closeUserMessageDialog}
                    okAction={this.closeUserMessageDialog}
                    okButtonText="OK"
                >
                    <div className="bulkUpdate__message">{userDialog.message}</div>
                    <br />
                    <p>
                        Please check <Link to="/buyplan/documentation/glossary">the documentation page</Link> to get more
                        information about OTB Units and AF% relation.
                    </p>
                </Modal>
                {isSettingsOpen && <BuyPlanSettings onClose={this.toggleSettings} />}
                <div className="ViewBuyplanList__aggregatedMaterials">
                    <InfiniteScrollList
                        loading={loading}
                        pagination={pagination}
                        fetchPage={getBuyplan}
                        rows={aggregatedMaterials}
                    >
                        {({ height, width, onScroll, scrollTop, onRowsRendered }) => (
                            <div className="ViewBuyplanList__container">
                                <div className="ViewBuyplanList__fixed-container">
                                    <div
                                        className="ViewBuyplanList__header"
                                        style={{ width: this.calculateColumnWidths('fixed') }}
                                    >
                                        <HeaderRow activeChannelId={activeChannelId} headerType="fixed" {...headerProps} />
                                    </div>
                                    <div
                                        className="ViewBuyplanList__subheader ViewBuyplanList__subheader--first"
                                        style={{ width: this.calculateColumnWidths('fixed') }}
                                    />
                                    {noResultsSubFiltersApplied && (
                                        <div className="ViewBuyplanList__noResults">
                                            No materials found for the given filters.
                                            <br />
                                            {`Try clearing ${totalActiveFilters} column filters:`}
                                            <ClearSubFiltersButton
                                                bodyText="Clear all filters"
                                                fullWidth={false}
                                                totalActiveFilters={totalActiveFilters}
                                                onClick={() => clearActiveSubFilters(activeChannelId, PageView.buyplan)}
                                            />
                                        </div>
                                    )}
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={this.columnsCache.fixed}
                                        width={this.calculateColumnWidths('fixed')}
                                        height={noResultsSubFiltersApplied ? 0 : height}
                                        rowCount={aggregatedMaterials.length}
                                        // Same rowHeight of the scrollable: it needs to follow the aggregate material collapsed state
                                        rowHeight={this.columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) =>
                                            this.renderColumns(listRowProps, 'fixed')
                                        }
                                        className="ViewBuyplanList__item"
                                        ref={this.fixedListRef}
                                        overscanRowCount={0}
                                        onRowsRendered={onRowsRendered}
                                    />
                                    {loading && <Loader width={45} />}
                                </div>

                                <div
                                    className="ViewBuyplanList__scrollable-container"
                                    style={{ width: width - this.calculateColumnWidths('fixed') }}
                                >
                                    <div
                                        className="ViewBuyplanList__header"
                                        style={{ width: this.calculateColumnWidths('scrollable') }}
                                    >
                                        <HeaderRow
                                            activeChannelId={activeChannelId}
                                            headerType="scrollable"
                                            {...headerProps}
                                        />
                                    </div>
                                    <SubheaderRow
                                        totalsLoading={totalsLoading}
                                        totalsOutOfDate={totalsOutOfDate}
                                        getPartnerTotals={getPartnerTotals}
                                        buyPlanTotals={buyPlanTotals}
                                        scrollableColumnsWidth={this.calculateColumnWidths('scrollable')}
                                        columns={columns}
                                    />
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={this.columnsCache.scrollable}
                                        width={this.calculateColumnWidths('scrollable')}
                                        height={height}
                                        rowCount={aggregatedMaterials.length}
                                        rowHeight={this.columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) =>
                                            this.renderColumns(listRowProps, 'scrollable')
                                        }
                                        className="ViewBuyplanList__item"
                                        ref={this.scrollableListRef}
                                        overscanRowCount={0}
                                        onRowsRendered={onRowsRendered}
                                    />
                                    {loading && <Loader width={45} />}
                                </div>
                            </div>
                        )}
                    </InfiniteScrollList>
                </div>
            </div>
        );
    }
}

const mapActionCreators = {
    clearActiveSubFilters: clearActiveSubFiltersAction,
};

const mapStateToProps = ({
    buyplan: { aggregatedMaterials, metaData },
    user: {
        settings: { activeChannelId, visibleBuyPlanColumnKeys, activeFilters, channels },
    },
}: {
    buyplan: {
        metaData: {
            [key: string]: { data: FilteringMetaData; loadingStatus: LoadingStatus };
        };
        aggregatedMaterials: BuyPlanAggregatedMaterial[];
    };
    user: UserState;
}) => {
    const selector = `${activeChannelId}|${PageView.buyplan}`;
    const { data } = metaData?.[selector] ?? {
        data: {},
        loadingStatus: LoadingStatus.initial,
    };
    return {
        aggregatedMaterials,
        favoriteStores: channels.find(({ channelId }) => channelId === activeChannelId)?.favoriteStores ?? [],
        visibleBuyPlanColumnKeys,
        activeFilters: {
            mainFilters: activeFilters[`${activeChannelId}|${PageView.buyplan}`]?.mainFilters ?? {},
            subFilters: activeFilters[`${activeChannelId}|${PageView.buyplan}`]?.subFilters ?? {},
        },
        activeChannelId,
        subFilters: data.subFilters,
    };
};

export default compose(connect(mapStateToProps, mapActionCreators))(ViewBuyplanList);
