"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChannelAudience = void 0;
const channels_1 = require("../configs/channels");
const seasonTypes_1 = require("../types/seasonTypes");
/*
    There are 3 active channels in Buyplan application: NDS, Digital, NVS.
    Digital and NDS share season & assortment data, so in this way they are part of the same "audience".
    NVS plans separately and has completely different season & assortment data.
*/
const getChannelAudience = (channelId) => {
    if (channelId === channels_1.channels.nds.id || channelId === channels_1.channels.digital.id) {
        return seasonTypes_1.ChannelAudience.ndsDigital;
    }
    if (channelId === channels_1.channels.nvs.id) {
        return seasonTypes_1.ChannelAudience.nvs;
    }
    return undefined;
};
exports.getChannelAudience = getChannelAudience;
