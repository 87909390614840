import React, { useMemo, useReducer } from 'react';
import { v4 } from 'uuid';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { NewRelicEventName, NewRelicEventStatus, SourceFile as SourceFileType } from 'buyplan-common';
import { useDispatch } from 'react-redux';
import { OnProgress } from '../../api/api';
import FileDropzone from '../FileDropzone/FileDropzone';
import SourceFile, { ConfirmationCallback, fileTypes, UploadableFileType } from '../SourceFile/SourceFile';
import './ImportFiles.scss';
import { sendCustomNewRelicEvent } from '../../actions/user';
import {
    addFileUploadAction,
    removeFileUploadAction,
    updateFileErrorsAction,
    updateFileProgressAction,
    uploadingFilesReducer,
} from './uploadingFilesReducer';

type UploadCallbackType = (file: File, id: string, onProgress: OnProgress) => void;

interface Props {
    confirmation?: ConfirmationCallback;
    files?: SourceFileType[];
    fileType?: UploadableFileType;
    multiple?: boolean;
    narrow?: boolean;
    isDisabled?: boolean;
    onDelete: (fileId: string) => void;
    onCancelError?: (fileId: string) => void;
    onReUpload: UploadCallbackType;
    onSuccess: () => void;
    onUpload: UploadCallbackType;
    eventTypeTracker: NewRelicEventName;
}

const getFileId = (file: SourceFileType) => (file.id ? file.id : (file.uuid as string));

function ImportFiles({
    confirmation,
    files = [],
    fileType = fileTypes.excel,
    multiple = false,
    narrow = false,
    isDisabled,
    onDelete,
    onReUpload,
    onSuccess,
    onUpload,
    onCancelError,
    eventTypeTracker,
}: Props) {
    const [{ uploadingFiles }, dispatch] = useReducer(uploadingFilesReducer, { uploadingFiles: [] });
    const dispatchAction = useDispatch();

    const uploadFile = async (file: File, fileId: string, uploadService: UploadCallbackType) => {
        try {
            await uploadService(file, fileId, (progress: number) => {
                dispatch(updateFileProgressAction(fileId, progress));
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            const validationErrorTypes = [
                'MerchandiseValidationError',
                'OptionCountsValidationError',
                'MinimumBuyValidationError',
                'FileValidationError',
                'SalesTargetsValidationError',
            ];
            const errors = validationErrorTypes.includes((err.meta || {}).type) ? err.meta : { system: err.message };
            dispatch(updateFileErrorsAction(fileId, errors));
            dispatchAction(sendCustomNewRelicEvent(eventTypeTracker, NewRelicEventStatus.error));
            return;
        }

        // When done uploading, trigger success handler and remove file from component state
        await onSuccess();
        dispatchAction(sendCustomNewRelicEvent(eventTypeTracker, NewRelicEventStatus.end));
        dispatch(removeFileUploadAction(fileId));
    };

    const handleUpload = async (file: File, fileId: string) => {
        const isSameFileInStateWithError = uploadingFiles.find((f) => f.filename === file.name && !!f.errors);
        if (isSameFileInStateWithError) {
            const matchedFileId = getFileId(isSameFileInStateWithError);
            dispatch(removeFileUploadAction(matchedFileId));
        }
        await uploadFile(file, fileId, onUpload);
    };

    const handleDrop = async (acceptedFiles: File[]) => {
        dispatchAction(sendCustomNewRelicEvent(eventTypeTracker, NewRelicEventStatus.start));
        const filesToUpload = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const file of acceptedFiles) {
            const id = v4();
            filesToUpload.push({ id, uuid: id, file });
            dispatch(addFileUploadAction(id, file));
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const fileToUpload of filesToUpload) {
            await handleUpload(fileToUpload.file, fileToUpload.id);
        }
    };

    const handleReupload = async (file: File, fileId: string) => {
        dispatchAction(sendCustomNewRelicEvent(eventTypeTracker, NewRelicEventStatus.start));
        dispatch(addFileUploadAction(fileId, file));
        // When a new file with validation error (so not stored in DB yet) gets re-uploaded, treat it like a new file
        const isInStore = files.some((f) => getFileId(f) === fileId);
        if (!isInStore) {
            dispatch(removeFileUploadAction(fileId));
            await uploadFile(file, fileId, onUpload);
        } else {
            await uploadFile(file, fileId, onReUpload);
        }
    };

    const handleDelete = async (fileId: string) => {
        await onDelete(fileId);
        await onSuccess();
    };

    /**
     * Returns an array of files being uploaded + any pre-existing files.
     * Deduplicates pre-existing files that are being re-uploaded.
     */
    const allFiles = useMemo(() => {
        const filesMap = new Map();
        [...uploadingFiles, ...files].forEach((file) => {
            // uploadingFiles take priority over normal files and should appear on top, never overwrite them
            const fileId = getFileId(file);
            if (!filesMap.has(fileId)) {
                filesMap.set(fileId, file);
            }
        });
        return Array.from(filesMap.values());
    }, [files, uploadingFiles]);

    return (
        <div className="ImportFiles">
            {(multiple || allFiles.length < 1) && (
                <FileDropzone
                    acceptedMimeTypes={fileType.mime}
                    title={
                        <>
                            Drag &#39;n drop files here or <span className="button">browse</span>
                        </>
                    }
                    onDrop={handleDrop}
                    multiple={multiple}
                    isDisabled={isDisabled}
                />
            )}
            {allFiles.length > 0 && (
                <TransitionGroup className="ImportFiles__files" component="ul">
                    {allFiles.map((file) => (
                        <CSSTransition
                            key={getFileId(file)}
                            timeout={{
                                enter: 500,
                                exit: 500,
                            }}
                            classNames="ImportFiles__file--animation"
                        >
                            <li>
                                <div className="ImportFiles__file">
                                    <SourceFile
                                        file={file}
                                        fileType={fileType}
                                        onCancelError={() => {
                                            dispatch(removeFileUploadAction(file.id));
                                            if (onCancelError) onCancelError(file.uuid || file.id);
                                        }}
                                        onDelete={handleDelete}
                                        onReupload={(acceptedFiles) => handleReupload(acceptedFiles[0], getFileId(file))}
                                        narrow={narrow}
                                        confirmation={confirmation}
                                        isDisabled={isDisabled}
                                    />
                                </div>
                            </li>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            )}
        </div>
    );
}

export default ImportFiles;
