"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidFlowRangesForLaunch = exports.calculateWeek = exports.getStartEndWeekFromFlow = void 0;
const moment = require("moment");
const materialTypes_1 = require("../types/materialTypes");
/*
    This function will take a given flow range and calculate the appropriate start and end week
    Flow1_FlowX - Start week will be 1
    Flow2_FlowX - Start week will be 5
    Flow3_FlowX - Start week will be 9

    FlowX_Flow1 - End week will be 4
    FlowX_Flow2 - End week will be 8
    FlowX_Flow3 - End week will be 13
*/
const getStartEndWeekFromFlow = (flowRange) => {
    let startWeek;
    let endWeek;
    if (flowRange === materialTypes_1.FlowRange.Flow1Flow1) {
        startWeek = 1;
        endWeek = 4;
    }
    else if (flowRange === materialTypes_1.FlowRange.Flow1Flow2) {
        startWeek = 1;
        endWeek = 8;
    }
    else if (flowRange === materialTypes_1.FlowRange.Flow1Flow3) {
        startWeek = 1;
        endWeek = 13;
    }
    else if (flowRange === materialTypes_1.FlowRange.Flow2Flow2) {
        startWeek = 5;
        endWeek = 8;
    }
    else if (flowRange === materialTypes_1.FlowRange.Flow2Flow3) {
        startWeek = 5;
        endWeek = 13;
    }
    else if (flowRange === materialTypes_1.FlowRange.Flow3Flow3) {
        startWeek = 9;
        endWeek = 13;
    }
    else {
        startWeek = null;
        endWeek = null;
    }
    return {
        startWeek,
        endWeek,
    };
};
exports.getStartEndWeekFromFlow = getStartEndWeekFromFlow;
const calculateWeek = (date, season) => {
    let week = 1;
    const { startDate, endDate } = season;
    let weekEndDate = moment(startDate).add(6, 'd');
    const minusWeekStartDate = moment(startDate).subtract(14, 'd'); // Launch date can be before season startdate, check max 2 weeks before
    if (moment(date).isBetween(minusWeekStartDate, weekEndDate, undefined, '[]')) {
        return week;
    }
    do {
        ++week;
        const weekStartDate = moment(weekEndDate).add(1, 'd');
        weekEndDate = moment(weekStartDate).add(6, 'd');
        if (moment(date).isBetween(weekStartDate, weekEndDate, undefined, '[]')) {
            break;
        }
    } while (moment(date).isBetween(startDate, endDate, undefined, '[]'));
    return week;
};
exports.calculateWeek = calculateWeek;
const getValidFlowRangesForLaunch = (launchDate, season, flowOptions) => {
    if (!season)
        return [];
    const startWeek = (0, exports.calculateWeek)(launchDate, season);
    const flowArray = Object.values(flowOptions);
    let validFlowRanges = [];
    if (startWeek < 5) {
        validFlowRanges = flowArray.filter((flow) => flow.startsWith('Flow1'));
    }
    else if (startWeek > 4 && startWeek < 9) {
        validFlowRanges = flowArray.filter((flow) => flow.startsWith('Flow2'));
    }
    else if (startWeek > 8) {
        validFlowRanges = flowArray.filter((flow) => flow.startsWith('Flow3'));
    }
    return validFlowRanges;
};
exports.getValidFlowRangesForLaunch = getValidFlowRangesForLaunch;
