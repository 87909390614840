import React from 'react';
import cn from 'classnames';
import CheckBox from '../CheckBox/CheckBox';
import './PermissionList.scss';

interface Props {
    options: string[];
    onChange(value: string[] | undefined): void;
    title: string;
    active?: string[];
}

const toggleOption = (activeOptions: string[] | undefined, newVal: string) => {
    if (!activeOptions) {
        return [newVal];
    }
    const index = activeOptions.indexOf(newVal);
    // Value is new
    if (index === -1) {
        return [...activeOptions, newVal];
    }
    // Value needs to be taken out
    const newArr = [...activeOptions.slice(0, index), ...activeOptions.slice(index + 1)];
    // If removing the option will empty the array, set to undefined  as [] = all options selected
    return newArr.length ? newArr : undefined;
};

function PermissionList({ options, active, title, onChange }: Props) {
    const allSelected = active?.length === 0;
    return (
        <div className="PermissionList">
            {title && <h3>{title}</h3>}
            <ul>
                <li className="PermissionList__item--all">
                    <CheckBox
                        id={`all-options-${title}`}
                        label={`ACCESS TO ALL ${title}`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            event.currentTarget.checked ? onChange([]) : onChange(undefined)
                        }
                        isChecked={active !== undefined && allSelected}
                    />
                </li>
                {options.map((option) => (
                    <li
                        key={option}
                        className={cn({ 'PermissionList__item--inactive': active !== undefined && allSelected })}
                    >
                        <CheckBox
                            id={`option-${title}-${option}`}
                            label={option}
                            onChange={() => onChange(toggleOption(active, option))}
                            isChecked={(active !== undefined && active.includes(option)) || allSelected}
                            disabled={allSelected}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
export default PermissionList;
