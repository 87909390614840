import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as clipboard from 'clipboard-polyfill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './SourceFileError.scss';

export const ERROR_LIMIT = 30;

interface Props {
    title: string;
    items: string[];
    itemType?: string;
    grid?: boolean;
}

function SourceFileError({ title, items, itemType, grid = false }: Props) {
    const [copied, setCopied] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(
        () => () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        },
        []
    );

    const copyToClipboard = async () => {
        const dt = new clipboard.DT();
        dt.setData('text/plain', items.join(', '));
        dt.setData('text/html', items.map((item) => `<li>${item}</li>`).join(''));
        await clipboard.write(dt);
        setCopied(true);
        timeoutRef.current = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const isLimited = items.length > ERROR_LIMIT;
    return (
        <section className="SourceFileError">
            <h4 className="SourceFileError__title">{title}</h4>
            {items.length > 0 && (
                <ul className={cn({ SourceFileError__grid: grid })}>
                    {items.slice(0, ERROR_LIMIT).map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                    <ol className="SourceFileError__clipboard">
                        <button type="button" onClick={copyToClipboard}>
                            {copied && <FontAwesomeIcon icon={faCheck as IconProp} />} copy to clipboard
                        </button>
                    </ol>
                </ul>
            )}
            {isLimited && (
                <div className="SourceFileError__moreErrors">
                    <span className="error">
                        There are {items.length - ERROR_LIMIT} more {itemType} errors*
                    </span>
                    {` `}
                    <span>(use the &#39;copy to clipboard&#39; button to copy all errors)</span>
                </div>
            )}
        </section>
    );
}

export default SourceFileError;
