import React, { ReactNode } from 'react';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './SettingsModal.scss';

export interface Settings {
    children: ReactNode;
    onClose(): void;
}

function SettingsModal({ children, onClose }: Settings) {
    return (
        <div className="SettingsModal">
            <div className="SettingsModal__overlay">
                <div className="SettingsModal__container">
                    <button className="SettingsModal__close" type="button" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes as IconProp} />
                    </button>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default SettingsModal;
