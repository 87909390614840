import { AllFilters, PartnerTotal, BuyPlanTotals, PartnerTotalsSum } from 'buyplan-common';
import { omit } from 'lodash';
import { request } from '../api/api';

export const getPartnerTotals = (activeFilters: AllFilters) => {
    const queryParams = { filter: omit(activeFilters, 'storeId') };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/partners/totals`,
        queryParams,
    };

    return request<{
        data: { partnerTotals: PartnerTotal[]; buyPlanTotals: BuyPlanTotals; partnerTotalsSum: PartnerTotalsSum };
    }>(requestOptions);
};
