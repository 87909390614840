import { ProcessLoadStatus, UserNotificationType } from 'buyplan-common';
import { NotificationActions } from '../actions/notifications';
import {
    ENQUEUE_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SET_FX_RATES_PROCESS_STATUS,
    RESET_FX_RATES_PROCESS_STATUS,
    SET_SNAPSHOT_DATA,
    GET_LATEST_SNAPSHOT_DATA,
} from '../actions/actionTypes';
import { ExchangeRateSnapshot } from '../services/exchangeRatesService';

export interface NotificationState {
    notifications: UserNotificationType[];
    processLoadStatus: {
        fxRates: {
            status: ProcessLoadStatus | undefined;
            snapshotData?: ExchangeRateSnapshot;
        };
    };
}

export const initialState: NotificationState = {
    notifications: [],
    processLoadStatus: {
        fxRates: {
            status: undefined,
        },
    },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: NotificationState = initialState, notificationAction: NotificationActions) => {
    switch (notificationAction.type) {
        case ENQUEUE_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: notificationAction.key,
                        message: notificationAction.message,
                        dismissed: notificationAction.dismissed,
                        notificationType: notificationAction.notificationType,
                    },
                ],
            };

        case CLOSE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== notificationAction.key),
            };

        case SET_FX_RATES_PROCESS_STATUS:
            return {
                ...state,
                processLoadStatus: {
                    ...state.processLoadStatus,
                    fxRates: {
                        ...state.processLoadStatus.fxRates,
                        status: notificationAction.status,
                    },
                },
            };

        case RESET_FX_RATES_PROCESS_STATUS:
            return {
                ...state,
                processLoadStatus: {
                    ...state.processLoadStatus,
                    fxRates: {
                        ...state.processLoadStatus.fxRates,
                        status: initialState.processLoadStatus.fxRates.status,
                    },
                },
            };
        case GET_LATEST_SNAPSHOT_DATA:
            return {
                ...state,
                processLoadStatus: {
                    ...state.processLoadStatus,
                    fxRates: {
                        ...state.processLoadStatus.fxRates,
                        snapshotData: initialState.processLoadStatus.fxRates.snapshotData,
                    },
                },
            };
        case SET_SNAPSHOT_DATA:
            return {
                ...state,
                processLoadStatus: {
                    ...state.processLoadStatus,
                    fxRates: {
                        ...state.processLoadStatus.fxRates,
                        snapshotData: notificationAction.snapshotData,
                    },
                },
            };

        default:
            return state;
    }
};
