import { BuyPlanAggregatedMaterial, BuyPlanStore } from 'buyplan-common';
import React from 'react';
import { TooltipType } from '../Tooltip/Tooltip';
import BuyplanBuyTooltip from './BuyplanBuyTooltip';

interface Props {
    openToBuyUnits: number | null;
    store: BuyPlanStore;
    aggregatedMaterial: BuyPlanAggregatedMaterial;
}

function BuyplanOpenToBuyTooltip({ openToBuyUnits, store, aggregatedMaterial }: Props) {
    let tooltipType = TooltipType.default;
    if (openToBuyUnits !== null && openToBuyUnits < Math.max(store.minimumBuyMaterial ?? 0, store.allocationUnits ?? 0)) {
        tooltipType = TooltipType.error;
    }

    return (
        <BuyplanBuyTooltip store={store} aggregatedMaterial={aggregatedMaterial} type={tooltipType}>
            <span data-e2e={`otb-info-${store.storeNumber}-${aggregatedMaterial.materialCode}`}>{openToBuyUnits ?? 0}</span>
        </BuyplanBuyTooltip>
    );
}

export default BuyplanOpenToBuyTooltip;
