import { ValidationError } from 'buyplan-common';

// The number of row numbers / row number ranges to show in the error output. This is to prevent crazy long error messages.
const MAX_ROW_NUMBER_RANGES = 5;

export default ({ error, rowNumbers }: ValidationError) => {
    const ranges = [] as string[];
    let previous: number | undefined;
    let start: number | undefined;

    const addRange = (firstRow: number, lastRow: number) => {
        const rangeLength = lastRow - firstRow;
        if (rangeLength > 1) {
            ranges.push(`${firstRow}-${lastRow}`);
        } else {
            if (rangeLength === 1) {
                ranges.push(`${firstRow}`);
            }
            ranges.push(`${lastRow}`);
        }
    };

    rowNumbers?.forEach((rowNumber) => {
        if (previous) {
            if (rowNumber !== previous + 1) {
                addRange(start ?? previous, previous);
                start = rowNumber;
            }
        } else {
            start = rowNumber;
        }
        previous = rowNumber;
    });
    if (previous) addRange(start ?? previous, previous);

    const rowCopy = ranges.length === 1 && !ranges[0].includes('-') ? 'Row' : 'Rows';
    if (ranges.length > MAX_ROW_NUMBER_RANGES) {
        return `${rowCopy} ${ranges.slice(0, MAX_ROW_NUMBER_RANGES).join(',')}...: ${error}`;
    }
    if (ranges.length === 0) {
        return error;
    }
    return `${rowCopy} ${ranges.join(',')}: ${error}`;
};
