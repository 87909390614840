import { useRef, useEffect, RefObject } from 'react';

export const useOutsideClick = <T extends HTMLElement>(callback: () => void): RefObject<T> => {
    const ref = useRef<T>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, { capture: true });

        return () => {
            document.removeEventListener('click', handleClick, { capture: true });
        };
    }, [callback]);

    return ref;
};
