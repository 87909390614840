"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatUSDOrDash = exports.formatCurrencyOrDash = exports.formatCurrency = exports.formatUSD = exports.formatNumberOrDash = exports.formatNumber = exports.formatPercentageOrDash = exports.formatPercentage = exports.formatPlainPercentage = exports.formatPlainNumber = void 0;
const formatPlainNumber = (value, rounding = true) => {
    if (!value) {
        return null;
    }
    return rounding ? Math.round(value) : Number(value.toFixed(2));
};
exports.formatPlainNumber = formatPlainNumber;
const formatPlainPercentage = (value, rounding = false) => {
    if (!value) {
        return null;
    }
    return rounding ? Math.round(value * 100) : Number((value * 100).toFixed(1));
};
exports.formatPlainPercentage = formatPlainPercentage;
const formatPercentage = (value, rounding = false) => new Intl.NumberFormat('en-EN', {
    style: 'percent',
    minimumFractionDigits: rounding ? 0 : 1,
    maximumFractionDigits: rounding ? 0 : 1,
}).format(value !== null && value !== void 0 ? value : 0);
exports.formatPercentage = formatPercentage;
const formatPercentageOrDash = (value, plain = false) => {
    if (plain) {
        return (0, exports.formatPlainPercentage)(value);
    }
    return value === null ? '-' : (0, exports.formatPercentage)(value);
};
exports.formatPercentageOrDash = formatPercentageOrDash;
const formatNumber = (value) => new Intl.NumberFormat('en-EN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
}).format(value);
exports.formatNumber = formatNumber;
const formatNumberOrDash = (value, plain = false) => {
    if (plain) {
        return (0, exports.formatPlainNumber)(value);
    }
    return value === null ? '-' : (0, exports.formatNumber)(value);
};
exports.formatNumberOrDash = formatNumberOrDash;
const formatUSD = (value, rounding = true) => `$${new Intl.NumberFormat('en-US', {
    maximumFractionDigits: rounding ? 0 : 2,
    minimumFractionDigits: rounding ? 0 : 2,
}).format(value)}`;
exports.formatUSD = formatUSD;
const formatCurrency = (value, currency, rounding = true) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: rounding ? 0 : 2,
    minimumFractionDigits: rounding ? 0 : 2,
}).format(value);
exports.formatCurrency = formatCurrency;
const formatCurrencyOrDash = (value, currency, rounding = true) => value === null ? '-' : (0, exports.formatCurrency)(value, currency, rounding);
exports.formatCurrencyOrDash = formatCurrencyOrDash;
const formatUSDOrDash = (value, plain = false, rounding = true) => {
    if (plain) {
        return (0, exports.formatPlainNumber)(value, rounding);
    }
    return value === null ? '-' : (0, exports.formatUSD)(value, rounding);
};
exports.formatUSDOrDash = formatUSDOrDash;
