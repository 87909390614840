import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { MobileDatePicker as DatePicker } from '@mui/lab';
import Button from '../Button/Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';

export const formatStringToDate = (date: Date) => moment(date).toDate();
export const formatDateToString = (date: Date) => moment(date).format('YYYY-MM-DD');

export interface UpdateOrderlinesColumn {
    label: string;
    name: string;
    value?: string;
    type: 'date' | 'text' | 'type' | 'int' | 'decimal';
}

interface Props {
    editingColumn: UpdateOrderlinesColumn;
    updating: boolean;
    error?: string;
    value?: string;
    onClose: () => void;
    onSubmit: (newValue: Date | string) => void;
}

function UpdateOrderlinesForm({ editingColumn, updating, error, onClose, onSubmit, value }: Props) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [newValue, setNewValue] = useState<Date | string | undefined>(value);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const getSubmitButtonText = () => {
        if (updating) {
            return 'Updating';
        }
        if (confirmed) {
            return 'Really update?';
        }
        return 'Update';
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value);
        setConfirmed(false);
    };

    const handleDateChange = (date: Date) => {
        setNewValue(formatDateToString(date));
        setConfirmed(false);
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (confirmed && newValue !== undefined) {
            onSubmit(newValue);
        }
        setConfirmed(!confirmed);
    };

    const { type } = editingColumn;

    return (
        <form onSubmit={handleSubmit}>
            <div className="update-orderlines__form">
                <label htmlFor="newValue">
                    {type === 'text' && (
                        <input
                            ref={inputRef}
                            name="newValue"
                            type={type}
                            value={(newValue as string) || ''}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Stop user from submitting the form too easily
                                }
                            }}
                            onChange={handleInputChange}
                            placeholder="New value"
                        />
                    )}
                    {type === 'date' && (
                        <DatePicker<Date>
                            disableCloseOnSelect={false}
                            value={formatStringToDate(newValue as Date)}
                            onChange={(date: Date | null) => (date ? handleDateChange(date) : null)}
                            renderInput={(params) => <TextField variant="standard" {...params} />}
                        />
                    )}
                </label>
                <ErrorMessage error={error} />
            </div>
            <div className="update-orderlines__actions">
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" disabled={updating}>
                    {getSubmitButtonText()}
                    {updating && <Loader width={12} />}
                </Button>
            </div>
        </form>
    );
}

export default UpdateOrderlinesForm;
