import React from 'react';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Tab } from 'react-tabs';
import { Channel, UserProfile, ChannelRole } from 'buyplan-common';
import './UserForm.scss';
import useIsSuperUser from '../../selectors/useIsSuperUser';

interface Props {
    user: UserProfile;
    displayedChannel: Channel;
    switchChannel(): void;
}

function PermissionTab({ user, displayedChannel, switchChannel, ...otherProps }: Props) {
    const findUserChannel = (lookupChannel: Channel) => find(user.channels, (channel) => channel.id === lookupChannel.id);
    const isSuperUser = useIsSuperUser();

    const onClickTabCallback = () => switchChannel();

    return (
        <Tab {...otherProps} onClick={onClickTabCallback} key={displayedChannel.id}>
            <h3>
                {displayedChannel.name}
                {/* Channel access icon */}
                {(findUserChannel(displayedChannel) || isSuperUser) && (
                    <FontAwesomeIcon size="sm" style={{ color: '#ccc', marginLeft: '5px' }} icon={faDoorOpen as IconProp} />
                )}
                {/* Channel Admin icon */}
                {(findUserChannel(displayedChannel)?.channelRole === ChannelRole.admin || isSuperUser) && (
                    <FontAwesomeIcon size="sm" style={{ color: '#ccc', marginLeft: '5px' }} icon={faUser as IconProp} />
                )}
            </h3>
        </Tab>
    );
}

PermissionTab.tabsRole = 'Tab';

export default PermissionTab;
