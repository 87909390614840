import React, { useState, useEffect, useRef } from 'react';
import { sum } from 'lodash';
import {
    FamilyModelBreakdownReport,
    FamilyModelBreakdownReportTotals,
    FamilyModelReportType,
    familyModelRows,
    FamilyModelRowConfig,
} from 'buyplan-common';
import { List, CellMeasurer, CellMeasurerCache, ListRowProps, ScrollSync } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from '../../../store/reducers';
import { getColumnRangeWidth, getColumnWidth } from '../../../helpers/tableTools';
import FamilyModelRow from './FamilyModelRow';
import FamilyModelHeaderRow from './FamilyModelHeaderRow';
import FamilyModelSubHeaderRow from './FamilyModelSubheader';
import './FamilyModelTable.scss';

const ROW_HEIGHT = 38;

interface Props {
    results: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals };
    reportType: FamilyModelReportType;
    channelId: number;
}

function FamilyModelTable({ results, reportType, channelId }: Props) {
    const visibleFamilyReportColumnKeys = useSelector((state) => state.user.settings.visibleFamilyReportColumnKeys);
    const fixedListRef = useRef(null);
    const scrollableListRef = useRef(null);
    const { totals, report } = results;

    // Each List needs a separate cache
    const columnsCache = {
        fixed: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
        scrollable: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
    };

    const [columns, setColumns] = useState([] as FamilyModelRowConfig[]);

    const rowCount = report.length;
    useEffect(() => {
        const familyModelColumns = familyModelRows(channelId, reportType).filter(
            (familyReportColumn) =>
                visibleFamilyReportColumnKeys.includes(familyReportColumn.key) || familyReportColumn.isFixed
        );
        setColumns(familyModelColumns);
    }, [channelId, visibleFamilyReportColumnKeys, reportType]);

    const calculateColumnWidths = (columnType: string) => {
        const totalColumnWidth = getColumnRangeWidth(columns);

        const fixedColumnsWidth =
            sum(columns.filter(({ type }) => type === 'fixed').map(({ key }) => getColumnWidth(columns, key))) + 20;
        const scrollableColumnsWidth = totalColumnWidth - fixedColumnsWidth + 80; // Needs extra buffer for padding and scrollbar

        if (columnType === 'fixed') {
            return fixedColumnsWidth;
        }
        return scrollableColumnsWidth;
    };

    const fixedWidth = calculateColumnWidths('fixed');

    const renderColumns = ({ index, key, parent, style }: ListRowProps, type: 'fixed' | 'scrollable') => {
        const row = report[index];

        return (
            <CellMeasurer cache={columnsCache[type]} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <FamilyModelRow style={style} columnType={type} key={key} row={row} columns={columns} />
            </CellMeasurer>
        );
    };

    return (
        <ScrollSync>
            {({ onScroll, scrollTop }) => (
                <div className="infinite-scroll-list">
                    <div className="scroll-smoother" /> {/* Needed to keep AutoSizer in sync while scrolling */}
                    <AutoSizer>
                        {({ height, width }) => (
                            <div className="FamilyModelTable__container">
                                <div className="FamilyModelTable__fixed-container">
                                    <div
                                        className="FamilyModelTable__header"
                                        style={{ width: calculateColumnWidths('fixed') }}
                                    >
                                        <FamilyModelHeaderRow
                                            scrollableColumnsWidth={calculateColumnWidths('fixed')}
                                            columns={columns}
                                            headerType="fixed"
                                            allColumns={familyModelRows(channelId, reportType)}
                                        />
                                    </div>
                                    <div
                                        className="FamilyModelTable__header"
                                        style={{ width: calculateColumnWidths('fixed') }}
                                    >
                                        <FamilyModelSubHeaderRow headerType="fixed" columns={columns} />
                                    </div>
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={columnsCache.fixed}
                                        width={fixedWidth}
                                        height={!rowCount ? 0 : height}
                                        rowCount={rowCount}
                                        rowHeight={columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) => renderColumns(listRowProps, 'fixed')}
                                        className="FamilyModelTable__item"
                                        ref={fixedListRef}
                                        overscanRowCount={0}
                                    />
                                </div>
                                <div
                                    className="FamilyModelTable__scrollable-container"
                                    style={{ width: width - calculateColumnWidths('fixed') }}
                                >
                                    <div
                                        className="FamilyModelTable__header"
                                        style={{ width: calculateColumnWidths('scrollable') }}
                                    >
                                        <FamilyModelHeaderRow
                                            scrollableColumnsWidth={calculateColumnWidths('scrollable')}
                                            columns={columns}
                                            headerType="scrollable"
                                            allColumns={familyModelRows(channelId, reportType)}
                                        />
                                    </div>
                                    <div
                                        className="FamilyModelTable__header"
                                        style={{ width: calculateColumnWidths('scrollable') }}
                                    >
                                        <FamilyModelSubHeaderRow headerType="scrollable" columns={columns} totals={totals} />
                                    </div>
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={columnsCache.scrollable}
                                        width={calculateColumnWidths('scrollable')}
                                        height={height}
                                        rowCount={rowCount}
                                        rowHeight={columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) =>
                                            renderColumns(listRowProps, 'scrollable')
                                        }
                                        className="FamilyModelTable__item"
                                        ref={scrollableListRef}
                                        overscanRowCount={0}
                                    />
                                </div>
                            </div>
                        )}
                    </AutoSizer>
                </div>
            )}
        </ScrollSync>
    );
}

export default FamilyModelTable;
