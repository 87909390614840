import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faTimes, faCheck, faExclamationTriangle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StatusCode } from 'buyplan-common';
import Loader from '../../Loader/Loader';

interface Props {
    statusCode: StatusCode;
}

export const fileStatus = {
    PROCESSING: {
        icon: <Loader strokeWidth={8} width={18} style={{ display: 'inline-block', marginRight: '10px' }} />,
        message: 'Processing...',
        type: 'loading',
    },
    UPLOADING: {
        icon: <FontAwesomeIcon icon={faFileUpload as IconProp} />,
        message: 'Uploading...',
        type: 'loading',
    },
    FAILED: {
        icon: <FontAwesomeIcon icon={faTimes as IconProp} />,
        message: 'Failed',
        type: 'error',
    },
    COMPLETED: {
        icon: <FontAwesomeIcon icon={faCheck as IconProp} />,
        message: 'Completed',
        type: 'success',
    },
    SHEET_VALIDATION_FAILED: {
        icon: <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />,
        message: 'Validation failed',
        type: 'error',
    },
    HEADER_VALIDATION_FAILED: {
        icon: <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />,
        message: 'Validation failed',
        type: 'error',
    },
    DATA_VALIDATION_FAILED: {
        icon: <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />,
        message: 'Validation failed',
        type: 'error',
    },
};

export const getStatus = (statusCode: StatusCode) => {
    const status = fileStatus[statusCode];
    return status || { icon: <FontAwesomeIcon icon={faQuestionCircle as IconProp} />, message: '', type: 'default' };
};

function StatusColumn({ statusCode }: Props) {
    const status = getStatus(statusCode);
    const { icon, message, type } = status;
    return (
        <span className={`file-status-table__item-status--${type}`}>
            {message && <span className="file-status-table__item-status-message">{message}</span>} {icon}
        </span>
    );
}

export default memo(StatusColumn);
