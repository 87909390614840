import { useMemo, useState } from 'react';
import { ProcessIdentifiers, ProcessStatus, ProcessLoadStatus } from 'buyplan-common';
import { ResponseError, request } from '../api/api';

export const getProcessStatus = (name: ProcessIdentifiers, useChannel?: boolean) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/process/status/${name}`,
        queryParams: { useChannel },
    };

    return request<{ data: ProcessStatus }>(requestOptions, true);
};

const timeoutPromise = (time: number) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(1);
        }, time);
    });

type ProcessStatusFunc = () => Promise<{ data: ProcessStatus }>;
type RecursionGetStatusFunc = (fetchStatus: ProcessStatusFunc) => Promise<{ data: ProcessStatus }>;

export const recursionGetStatus: RecursionGetStatusFunc = async (fetchStatus) => {
    let res;
    try {
        res = await fetchStatus();
        if (!res.data) {
            return res;
        }
        if ([ProcessLoadStatus.progress, ProcessLoadStatus.deleting].includes(res.data.status)) {
            await timeoutPromise(5000);
            res = await recursionGetStatus(fetchStatus);
        }
        return res;
    } catch (err: unknown) {
        const er = err as ResponseError;
        if (er?.response?.status === 502 || er?.message === 'Failed to fetch') {
            await timeoutPromise(5000);
            res = await recursionGetStatus(fetchStatus);
            return res;
        }
        throw err;
    }
};

export const useProcessStatus = (
    name: ProcessIdentifiers,
    useChannel?: boolean
): [boolean, Error | undefined, ProcessStatus | undefined, () => Promise<void>] => {
    const [status, setStatus] = useState<ProcessStatus | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>();

    const fetchFunction = useMemo(
        () => async () => {
            setError(undefined);
            setLoading(true);
            try {
                let res = await getProcessStatus(name, useChannel);
                setLoading(false);
                if (!res.data) {
                    setStatus(res.data);
                }
                if ([ProcessLoadStatus.progress, ProcessLoadStatus.deleting].includes(res.data.status)) {
                    setStatus(res.data);
                    await timeoutPromise(5000);
                    res = await recursionGetStatus(() => getProcessStatus(name, useChannel));
                }
                setStatus(res.data);
            } catch (err: unknown) {
                const er = err as ResponseError;
                if (er?.response?.status === 502) {
                    await timeoutPromise(5000);
                    const res = await recursionGetStatus(() => getProcessStatus(name, useChannel));
                    setLoading(false);
                    setStatus(res.data);
                }
                if (er.name !== 'AbortError') {
                    setError(er);
                    setLoading(false);
                }
            }
        },
        [name, useChannel]
    );

    return [loading, error, status, fetchFunction];
};
