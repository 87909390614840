import React, { memo, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HindsightYearType, NewRelicEventName, NewRelicEventStatus } from 'buyplan-common';
import { isEqual } from 'lodash';
import { useSelector } from '../../../store/reducers';
import { useRequestFunction } from '../../../api/useRequest';
import { getThisYearTopStylesReport, getHindsightTopStylesReport } from '../../../services/buyPlanReportsService';
import { getMissingMainFilterKeys } from '../../../helpers/utils';
import useMetaData from '../../../selectors/useMetaData';
import useActiveFilters from '../../../selectors/useActiveFilters';
import usePrevious from '../../../helpers/usePrevious';
import { combineWords } from '../../../helpers/language';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { PageView } from '../../../constants/appConfig';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { sendCustomNewRelicEvent } from '../../../actions/user';
import { createReport, TopStylesReportTableData } from './calculations';
import TopStyleFilters from './TopStylesFilters';
import TopStylesTable from './TopStylesTable';
import './TopStylesReport.scss';

let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

export enum ReportYearType {
    TY = 'TY',
    LY = 'LY',
    LLY = 'LLY',
}

interface ReportProps {
    missingFiltersKeys: string[];
    isLoading: boolean;
    thisYearReport: TopStylesReportTableData | null;
    lastYearReport: TopStylesReportTableData | null;
    lastLastYearReport: TopStylesReportTableData | null;
    onSortColumn(columnKey: string, orderDirection: 'ASC' | 'DESC', reportYearType: ReportYearType): void;
    orderDirection?: string;
    orderLastYearDirection?: string;
    orderLastLastYearDirection?: string;
    orderColumn?: string;
    orderLastYearColumn?: string;
    orderLastLastYearColumn?: string;
    filtersHaveChanged: boolean;
    isError: boolean;
}

const renderReport = ({
    missingFiltersKeys,
    isLoading,
    thisYearReport,
    lastYearReport,
    lastLastYearReport,
    onSortColumn,
    orderDirection,
    orderLastYearDirection,
    orderLastLastYearDirection,
    orderColumn,
    orderLastYearColumn,
    orderLastLastYearColumn,
    filtersHaveChanged,
    isError,
}: ReportProps) => {
    if (missingFiltersKeys.length > 0) {
        return (
            <div className="top-styles-report--error-message">
                Please select a filter for {combineWords(missingFiltersKeys)}
            </div>
        );
    }
    if (isLoading) return <Loader />;
    if (isError) {
        return (
            <div className="top-styles-report--error-message">
                <ErrorMessage error="There was an error with your request" />
            </div>
        );
    }
    if (!thisYearReport || filtersHaveChanged)
        return <div className="top-styles-report--error-message">Click to load report</div>;
    if (thisYearReport !== null && lastYearReport !== null && lastLastYearReport !== null) {
        return (
            <div className="top-styles-report__table-container">
                <div className="top-styles-report__table">
                    <h3>THIS YEAR</h3>
                    <TopStylesTable
                        report={thisYearReport}
                        previousYear={lastYearReport}
                        reportYearType={ReportYearType.TY}
                        onSortColumn={onSortColumn}
                        orderColumn={orderColumn}
                        orderDirection={orderDirection}
                    />
                </div>
                <div className="top-styles-report__table--last-year">
                    <h3>LAST YEAR</h3>
                    <TopStylesTable
                        report={lastYearReport}
                        reportYearType={ReportYearType.LY}
                        onSortColumn={onSortColumn}
                        orderLastYearColumn={orderLastYearColumn}
                        orderLastYearDirection={orderLastYearDirection}
                    />
                </div>
                <div className="top-styles-report__table--last-year">
                    <h3>LAST LAST YEAR</h3>
                    <TopStylesTable
                        report={lastLastYearReport}
                        reportYearType={ReportYearType.LLY}
                        onSortColumn={onSortColumn}
                        orderLastLastYearColumn={orderLastLastYearColumn}
                        orderLastLastYearDirection={orderLastLastYearDirection}
                    />
                </div>
            </div>
        );
    }
    return null;
};

function TopStylesReport() {
    const dispatch = useDispatch();
    const { activeChannelId: channelId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.topStylesReport);
    const { metaData, isLoading: isMetaDataLoading } = useMetaData(PageView.topStylesReport);
    const prevMainFilters = usePrevious(mainFilters);
    const [isButtonPristine, setIsButtonPristine] = useState(true);
    const missingFiltersKeys = useMemo(
        () => getMissingMainFilterKeys(mainFilters, reportsFiltersConfig[PageView.topStylesReport].requiredFilters),
        [mainFilters]
    );
    const [orderColumn, setOrderColumn] = useState<string>('Revenue');
    const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [orderLastYearColumn, setOrderLastYearColumn] = useState<string>('Revenue');
    const [orderLastYearDirection, setOrderLastYearDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [orderLastLastYearColumn, setOrderLastLastYearColumn] = useState<string>('Revenue');
    const [orderLastLastYearDirection, setOrderLastLastYearDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [loadingThisYearReportData, errorThisYearReport, thisYearReportData, fetchThisYearTopStyles] =
        useRequestFunction(getThisYearTopStylesReport);
    const [loadingLastYearReportData, errorLastYearReport, lastYearReportData, fetchLastYearTopStyles] =
        useRequestFunction(getHindsightTopStylesReport);
    const [loadingLastLastYearReportData, errorLastLastYearReport, lastLastYearReportData, fetchLastLastYearTopStyles] =
        useRequestFunction(getHindsightTopStylesReport);
    const isLoading = loadingThisYearReportData || loadingLastYearReportData || loadingLastLastYearReportData;
    const isError = !!errorThisYearReport || !!errorLastYearReport || !!errorLastLastYearReport;

    useEffect(() => {
        if (missingFiltersKeys.length === 0 && !isEqual(mainFilters, prevMainFilters)) {
            resetAbortController();
            setIsButtonPristine(true);
        }
    }, [
        mainFilters,
        prevMainFilters,
        missingFiltersKeys,
        channelId,
        thisYearReportData,
        lastYearReportData,
        lastLastYearReportData,
    ]);

    useEffect(() => {
        // Send a second event to New Relic to mark when the loading has stopped due to an error
        if (isError) {
            dispatch(sendCustomNewRelicEvent(NewRelicEventName.loadTopTenReport, NewRelicEventStatus.error));
        }
    }, [dispatch, isError]);

    useEffect(() => {
        // Send a second event to New Relic to mark when the report data is available and loading is complete
        if (thisYearReportData !== null && lastYearReportData !== null && lastLastYearReportData !== null) {
            dispatch(sendCustomNewRelicEvent(NewRelicEventName.loadTopTenReport, NewRelicEventStatus.end));
        }
    }, [dispatch, thisYearReportData, lastYearReportData, lastLastYearReportData]);

    const onSortColumn = (orderCol: string, orderDir: 'ASC' | 'DESC', reportYearType: ReportYearType) => {
        if (reportYearType === ReportYearType.TY) {
            setOrderColumn(orderCol);
            setOrderDirection(orderDir);
            fetchThisYearTopStyles(mainFilters, orderCol, orderDir, abortController.signal);
        }

        if (reportYearType === ReportYearType.LY) {
            setOrderLastYearColumn(orderCol);
            setOrderLastYearDirection(orderDir);
            fetchLastYearTopStyles(mainFilters, HindsightYearType.LY, orderCol, orderDir, abortController.signal);
        }

        if (reportYearType === ReportYearType.LLY) {
            setOrderLastLastYearColumn(orderCol);
            setOrderLastLastYearDirection(orderDir);
            fetchLastLastYearTopStyles(mainFilters, HindsightYearType.LLY, orderCol, orderDir, abortController.signal);
        }
    };

    const { thisYearReport, lastYearReport, lastLastYearReport } = useMemo(() => {
        if (missingFiltersKeys.length !== 0 || !thisYearReportData || !lastYearReportData || !lastLastYearReportData) {
            return { thisYearReport: null, lastYearReport: null, lastLastYearReport: null };
        }
        return createReport(thisYearReportData, lastYearReportData, lastLastYearReportData);
    }, [thisYearReportData, lastYearReportData, lastLastYearReportData, missingFiltersKeys]);

    const loadReport = () => {
        // send an event to new relic to mark the start of the report load
        dispatch(sendCustomNewRelicEvent(NewRelicEventName.loadTopTenReport, NewRelicEventStatus.start));
        resetAbortController();
        setIsButtonPristine(false);
        fetchThisYearTopStyles(mainFilters, orderColumn, orderDirection, abortController.signal);
        fetchLastYearTopStyles(
            mainFilters,
            HindsightYearType.LY,
            orderLastYearColumn,
            orderLastYearDirection,
            abortController.signal
        );
        fetchLastLastYearTopStyles(
            mainFilters,
            HindsightYearType.LLY,
            orderLastLastYearColumn,
            orderLastLastYearDirection,
            abortController.signal
        );
    };

    return (
        <div className="top-styles-report">
            <div className="top-styles-report--filter-container">
                <h1>TOP 15 STYLES REPORT</h1>
                <TopStyleFilters metaData={metaData} isLoading={isMetaDataLoading} />
                <Button
                    className="Button top-styles-report--load-report-button"
                    disabled={!isButtonPristine || isMetaDataLoading || missingFiltersKeys.length > 0}
                    onClick={loadReport}
                >
                    LOAD REPORT
                </Button>
            </div>
            {renderReport({
                missingFiltersKeys,
                isLoading: isLoading || isMetaDataLoading,
                isError,
                thisYearReport,
                lastYearReport,
                lastLastYearReport,
                onSortColumn,
                orderDirection,
                orderLastYearDirection,
                orderLastLastYearDirection,
                orderColumn,
                orderLastYearColumn,
                orderLastLastYearColumn,
                filtersHaveChanged: isButtonPristine,
            })}
        </div>
    );
}

export default memo(TopStylesReport);
