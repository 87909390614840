import React, { PureComponent } from 'react';
import qs from 'qs';
import { LoadingStatus, FilterValue, DropdownOption } from 'buyplan-common';
import cn from 'classnames';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
import Filter from './Filter';
import DeleteButton from './DeleteButton';
import './FilterGroup.scss';

interface FilterType {
    field: string;
    filterData: DropdownOption[];
    filterType: string;
    label: string;
}

interface Props {
    filters: FilterType[];
    activeFilterAmount: number;
    accessToken: string | undefined;
    loadingStatusMetaData: LoadingStatus;
    rows: number;
    activeFilters?: { [key: string]: FilterValue };
    getOverviewMetaData(): void;
    setFilter(values: { [key: string]: FilterValue }): void;
    clearAllFilters(): void;
    channelId: number;
}

class FilterGroup extends PureComponent<Props> {
    componentDidMount() {
        const { getOverviewMetaData } = this.props;
        getOverviewMetaData();
    }

    handleClearFilter = () => {
        const { clearAllFilters } = this.props;
        clearAllFilters();
    };

    handleSetFilter(field: string, value: FilterValue) {
        const { setFilter } = this.props;
        return setFilter({ [field]: value });
    }

    render() {
        const {
            filters = [],
            activeFilters = {},
            activeFilterAmount,
            loadingStatusMetaData,
            rows,
            accessToken,
            channelId,
        } = this.props;

        const qsParams = { filter: activeFilters, channelId, Authorization: `Bearer ${accessToken}` };
        const url = `${process.env.REACT_APP_API_ENDPOINT}/mancala/inspectdata?${qs.stringify(qsParams)}`;
        const downloadLabel = `Download ${rows} item${rows > 1 ? 's' : ''}`;

        return (
            <div
                className={cn('filter-group-wrapper', {
                    'filter-group-wrapper--loading': loadingStatusMetaData === LoadingStatus.loading,
                })}
            >
                {loadingStatusMetaData === LoadingStatus.loading && <Loader width={120} />}

                <div className="filter-group">
                    {Boolean(rows) && (
                        <a href={url} className="Button" download>
                            {downloadLabel}
                        </a>
                    )}

                    {activeFilterAmount > 0 && (
                        <Button className="Button-fullWidth Button__inverted" onClick={this.handleClearFilter}>
                            remove all filters ({activeFilterAmount})
                        </Button>
                    )}

                    {filters && filters.length > 0 ? (
                        filters.map((filter) => (
                            <Filter
                                key={filter.field}
                                setFilter={(value: FilterValue) => this.handleSetFilter(filter.field, value)}
                                activeFilter={activeFilters[filter.field]}
                                {...filter}
                            />
                        ))
                    ) : (
                        <div className="filter-group-spinner">
                            <Loader />
                        </div>
                    )}

                    <DeleteButton />
                </div>
            </div>
        );
    }
}

export default FilterGroup;
