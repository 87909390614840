import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import Routes from './routes/Routes';
import { store, persistor } from './store/createStore';
import { setStore } from './api/api';
import detectBrowser from './helpers/detect-browser';
import Logo from './components/Logo/Logo';
import './components/core.scss';
import './components/mui-overrides.scss';
import './components/Header/Header.scss';
import './components/no-support.scss';
import SuccessfulNotification from './components/Notifications/Notification';

const browser = detectBrowser();

setStore(store);

const MOUNT_NODE = document.getElementById('root');

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                Components={{
                    info: SuccessfulNotification,
                }}
            >
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {browser === 'Chrome' || browser === 'Cypress' ? (
                            <Routes store={store} />
                        ) : (
                            <div className="no-support">
                                <Logo
                                    to="https://www.google.com/chrome/"
                                    WrapperComponent={({ to, children, ...props }) => (
                                        <a href={to} {...props}>
                                            {children}
                                        </a>
                                    )}
                                    app="buyplan"
                                />
                                <p>
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                    <br />
                                    {browser} is currently not supported for this application.
                                    <br />
                                    Please use <a href="https://www.google.com/chrome/">Chrome</a> instead.
                                </p>
                            </div>
                        )}
                    </LocalizationProvider>
                </PersistGate>
            </SnackbarProvider>
        </Provider>,
        MOUNT_NODE
    );
};

Modal.setAppElement(MOUNT_NODE);

render();
