import React, { memo, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import qs from 'qs';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from 'react-bootstrap';
import { CategoryLevelTypeOfOrderBreakdownReportData, TypeOfOrderBreakdownReport } from 'buyplan-common';
import useActiveFilters from '../../../selectors/useActiveFilters';
import { useSelector } from '../../../store/reducers';
import usePrevious from '../../../helpers/usePrevious';
import useMetaData from '../../../selectors/useMetaData';
import { syncTableHorizontalScrolls } from '../../../helpers/tableTools';
import { useRequestFunction } from '../../../api/useRequest';
import { getBuyPlanMetaData } from '../../../actions/buyplan';
import { combineWords } from '../../../helpers/language';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Loader from '../../Loader/Loader';
import { getTypeOfOrderReport } from '../../../services/buyPlanReportsService';
import { PageView } from '../../../constants/appConfig';
import MainFilters from '../../Filters/MainFilters/MainFilters';
import './TypeOfOrderReport.scss';
import { getMissingMainFilterKeys } from '../../../helpers/utils';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { shouldUpdateBuyPlanMeta } from '../../../helpers/filters';
import TypeOfOrderReportTable from './TypeOfOrderReportTable';

let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

interface ReportProps {
    missingFiltersKeys: string[];
    error?: Error;
    loading: boolean;
    typeOfOrderReportTotal: TypeOfOrderBreakdownReport[] | null;
    typeOfOrderReportCategories: CategoryLevelTypeOfOrderBreakdownReportData[] | null;
    filtersHaveChanged: boolean;
}

const getDynamicTopMargin = () => {
    // This offset is determined the height from top of the page till bottom of the filter element.
    const offset = window.innerWidth < 1450 ? 380 : 292;
    const tableElement = document.getElementsByClassName('typeOfOrderReport__table-totals');
    if (tableElement.length > 0) {
        const totalsTableHeight = tableElement[0].scrollHeight;
        return totalsTableHeight + offset;
    }
    return offset;
};

const renderReport = ({
    missingFiltersKeys,
    error,
    loading,
    typeOfOrderReportTotal,
    typeOfOrderReportCategories,
    filtersHaveChanged,
}: ReportProps) => {
    if (missingFiltersKeys.length > 0) {
        return (
            <div className="typeOfOrderReport--error-message">
                Please select a filter for {combineWords(missingFiltersKeys)}
            </div>
        );
    }
    if (loading) return <Loader />;
    if (error) {
        return (
            <div className="typeOfOrderReport--error-message">
                <ErrorMessage error={error} />
            </div>
        );
    }
    if (typeOfOrderReportTotal && typeOfOrderReportTotal.length === 0) {
        return <div className="typeOfOrderReport--error-message">No report data found, try changing filters</div>;
    }
    if (typeOfOrderReportTotal && !filtersHaveChanged) {
        return (
            <div className="typeOfOrderReporttable-container">
                {typeOfOrderReportCategories && typeOfOrderReportCategories.length > 0 && (
                    <div className="typeOfOrderReport__table-categories" style={{ marginTop: `${getDynamicTopMargin()}px` }}>
                        {typeOfOrderReportCategories.map((report) => (
                            <div key={report.category} className="typeOfOrderReport__table-categories--category">
                                <h2>{`${report.category}`}</h2>
                                <TypeOfOrderReportTable
                                    report={report.reportData}
                                    tableId={report.category}
                                    syncTableHorizontalScrolls={syncTableHorizontalScrolls}
                                />
                            </div>
                        ))}
                        <p className="typeOfOrderReport__table-categories__endOfList">
                            END OF TYPE OF ORDER LIST, SCROLL UP
                        </p>
                    </div>
                )}
            </div>
        );
    }
    return <div className="typeOfOrderReport--error-message">Click to load report data.</div>;
};

function TypeOfOrderReport() {
    const dispatch = useDispatch();
    const { activeChannelId: channelId, activeSeasonId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.typeOfOrderReport);
    const { accessToken } = useSelector(({ user }) => user);
    const { metaData, isLoading } = useMetaData(PageView.typeOfOrderReport);
    const { partner, category } = mainFilters;
    const prevMainFilters = usePrevious(mainFilters);
    const previousPartner = usePrevious(partner);
    const previousCategory = usePrevious(category);
    const [isButtonPristine, setIsButtonPristine] = useState(true);
    const missingFiltersKeys = getMissingMainFilterKeys(
        mainFilters,
        reportsFiltersConfig[PageView.typeOfOrderReport].requiredFilters
    );
    const [loading, error, reportData, fetchTypeOfOrderReport] = useRequestFunction(getTypeOfOrderReport);

    useEffect(() => {
        dispatch(
            getBuyPlanMetaData(
                channelId,
                false,
                reportsFiltersConfig[PageView.typeOfOrderReport].allFilters,
                PageView.typeOfOrderReport
            )
        );
    }, [dispatch, channelId]);

    useEffect(() => {
        // If the required filters (partner / category) change, refresh the data in the other filters
        if (!partner || !category) return;
        if (shouldUpdateBuyPlanMeta([partner, previousPartner], [category, previousCategory])) {
            dispatch(
                getBuyPlanMetaData(
                    channelId,
                    false,
                    reportsFiltersConfig[PageView.typeOfOrderReport].allFilters,
                    PageView.typeOfOrderReport
                )
            );
        }
    }, [dispatch, channelId, partner, previousPartner, category, previousCategory]);

    const { typeOfOrderReportTotal, typeOfOrderReportCategories } = useMemo(() => {
        if (!reportData) {
            return { typeOfOrderReportTotal: null, typeOfOrderReportCategories: null };
        }
        return reportData;
    }, [reportData]);

    const loadReport = () => {
        resetAbortController();
        setIsButtonPristine(false);
        fetchTypeOfOrderReport(mainFilters, abortController.signal);
    };

    // If filters are missing or if there are no results
    const enableDownload = missingFiltersKeys.length === 0 || (!loading && !!typeOfOrderReportTotal?.length);
    useEffect(() => {
        if (missingFiltersKeys.length === 0 && !isEqual(mainFilters, prevMainFilters)) {
            resetAbortController();
            setIsButtonPristine(true);
        }
    }, [mainFilters, prevMainFilters, channelId, missingFiltersKeys, reportData]);

    let disabledFilterKeys: string[] = [];

    // Disable supplementary filters if required filters are missing
    if (!partner || !category) {
        disabledFilterKeys = [...reportsFiltersConfig[PageView.typeOfOrderReport].supplementaryFilters];
    }

    const isMetaLoading = isLoading && isEmpty(metaData);
    return (
        <div className={cn('typeOfOrderReport', { 'typeOfOrderReport--loading': isLoading || loading })}>
            <div className="typeOfOrderReport__top-section">
                <h1>TYPE OF ORDER REPORT</h1>
                {!isMetaLoading && (
                    <div
                        className={cn({
                            'typeOfOrderReport--filter-container': !!typeOfOrderReportTotal?.length && !isButtonPristine,
                        })}
                    >
                        <MainFilters
                            filters={metaData.mainFilters}
                            activeMainFilters={mainFilters}
                            activeChannelId={channelId}
                            disabledFilterKeys={disabledFilterKeys}
                            view={PageView.typeOfOrderReport}
                            isFiltersLoading={isLoading}
                        />
                        <Button
                            className="Button typeOfOrderReport--load-report-button"
                            disabled={!isButtonPristine || isLoading || missingFiltersKeys.length > 0}
                            onClick={loadReport}
                        >
                            LOAD REPORT
                        </Button>
                    </div>
                )}
                {typeOfOrderReportTotal &&
                    typeOfOrderReportTotal &&
                    typeOfOrderReportTotal.length > 0 &&
                    !isButtonPristine && (
                        <>
                            <a
                                href={`${
                                    process.env.REACT_APP_API_ENDPOINT
                                }/buyplan/reports/typeOfOrder-report-export/?${qs.stringify({
                                    filter: mainFilters,
                                    activeSeason: activeSeasonId,
                                    channelId,
                                    Authorization: `Bearer ${accessToken}`,
                                })}`}
                                className={cn('Button', 'typeOfOrderReport__download-button', {
                                    'typeOfOrderReport__download-button--disabled': !enableDownload,
                                })}
                                download
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    className="typeOfOrderReport__download-button-icon"
                                    icon={faDownload as IconProp}
                                />
                                Download report in Excel
                            </a>
                            <div className="typeOfOrderReport__table-totals">
                                {typeOfOrderReportTotal.length !== 0 && (
                                    <>
                                        <h2>TOTALS</h2>
                                        <TypeOfOrderReportTable
                                            report={typeOfOrderReportTotal}
                                            tableId="totals"
                                            syncTableHorizontalScrolls={syncTableHorizontalScrolls}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
            </div>
            {renderReport({
                missingFiltersKeys,
                error,
                loading: isLoading || loading,
                typeOfOrderReportTotal,
                typeOfOrderReportCategories,
                filtersHaveChanged: isButtonPristine,
            })}
        </div>
    );
}

export default memo(TypeOfOrderReport);
