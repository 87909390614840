import React from 'react';
import { round } from 'lodash';
import { TopStylesReportRow, formatUSDOrDash, formatNumberOrDash, formatPercentageOrDash } from 'buyplan-common';
import './TopStylesRow.scss';
import MaterialImage from '../../MaterialImage/MaterialImage';
import { ReportYearType } from './TopStylesReport';

interface Props {
    row: TopStylesReportRow;
    reportYearType: ReportYearType;
}

function TopStylesRow({ row, reportYearType }: Props) {
    return (
        <tr className="topStyles-row">
            <td className="topStyles-row__image">
                <MaterialImage material={row} />
            </td>
            <td className="topStyles-row__text">{row.materialCode}</td>
            <td className="topStyles-row__text" title={row.description}>
                {row.description}
            </td>
            <td className="topStyles-row__numbers">{formatUSDOrDash(row.revenue)}</td>
            <td className="topStyles-row__numbers">{formatNumberOrDash(row.unitSales)}</td>
            <td className="topStyles-row__numbers">{formatPercentageOrDash(row.ttlRevenue)}</td>
            <td className="topStyles-row__numbers">{formatPercentageOrDash(row.ttlUnitSales)}</td>
            <td className="topStyles-row__numbers">{formatUSDOrDash(row.asp, false, false)}</td>
            <td className="topStyles-row__numbers">{formatNumberOrDash(row.storeCount)}</td>
            <td className="topStyles-row__numbers">{formatNumberOrDash(round(row.weeksOnSale))}</td>
            <td className="topStyles-row__numbers">{formatNumberOrDash(round(row.rateOfSale))}</td>
            {reportYearType === ReportYearType.TY && (
                <>
                    <td className="topStyles-row__divider">{formatNumberOrDash(row.presentationStocks ?? null)}</td>
                    <td className="topStyles-row__numbers">{formatNumberOrDash(row.openToBuyUnits ?? null)}</td>
                </>
            )}
            {reportYearType !== ReportYearType.TY && (
                <>
                    <td className="topStyles-row__divider">{formatNumberOrDash(row.investUnits)}</td>
                    <td className="topStyles-row__numbers">{formatPercentageOrDash(row.ttlInvestUnits)}</td>
                </>
            )}

            <td className="topStyles-row__numbers">{formatPercentageOrDash(row.sellThrough)}</td>
        </tr>
    );
}

export default TopStylesRow;
