import { createTheme } from '@material-ui/core/styles';

export const materialDropdownTheme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                fontSize: 14,
                color: '#222222',
                marginBottom: 10,
            },
        },
        MuiInputBase: {
            root: {
                width: '100%',
            },
        },
        MuiSelect: {
            root: {
                textTransform: 'uppercase',
            },
            icon: {
                color: '#222222',
            },
            select: {
                '&:focus': {
                    backgroundColor: 'none',
                },
            },
        },
        MuiButtonBase: {
            root: {
                textTransform: 'uppercase',
            },
        },
    },
});

export const materialDatePickerTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            root: {
                backgroundColor: '#000000',
            },
        },
        MuiPickersDay: {
            root: {
                color: '#111111',
                '&$disabled': {
                    color: '#CCCCCC',
                },
                '&$selected': {
                    backgroundColor: '#111111 !important',
                    color: '#fff',
                },
            },
            today: {
                color: '#fff',
                backgroundColor: '#111111 !important',
            },
        },
        MuiPickersModalDialog: {
            dialogAction: {
                color: 'yellow',
            },
        },
    },
});
