import React, { memo, useState } from 'react';
import cn from 'classnames';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isEmpty } from 'lodash';
import { ChannelRole } from 'buyplan-common';
import Modal from '../Modal/Modal';
import './Notes.scss';
import useHasChannelRole from '../../selectors/useHasChannelRole';

interface Props {
    subtitle: string;
    bodyText?: string;
    onSave(notes: string): void;
}

function Notes({ subtitle, bodyText, onSave }: Props) {
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    const [isOpen, setIsOpen] = useState(false);
    const [notes, setNotes] = useState<string | undefined>(undefined);
    // Detects if notes changed and enable the Save button: notes is not enough because they can be righteously deleted
    const [isNotesChanged, setIsNotesChanged] = useState<boolean>(false);

    const saveNote = () => {
        // If the user enters only spaces or newlines, then we consider it an empty string
        const formattedNotes = notes?.trim();
        onSave(formattedNotes || '');
    };

    const cancelAction = () => {
        setIsNotesChanged(false);
        setIsOpen(false);
    };

    return (
        <>
            <button
                type="button"
                onClick={() => setIsOpen(true)}
                className={cn('Notes__icon', { 'Notes__icon--empty': isEmpty(bodyText) })}
            >
                <FontAwesomeIcon icon={faStickyNote as IconProp} />
            </button>
            <Modal
                isOpen={isOpen}
                closeAction={cancelAction}
                style={{ content: { overflow: 'visible', minWidth: 485 } }}
                title="Notes"
                subtitle={subtitle}
                okAction={isReadOnlyUser ? undefined : saveNote}
                okButtonText="Save"
                cancelButtonText="Cancel"
                cancelAction={cancelAction}
                autoCloseAfterAction={true}
                okButtonDisabled={!isNotesChanged}
            >
                {isReadOnlyUser ? (
                    <p>{bodyText}</p>
                ) : (
                    <textarea
                        defaultValue={bodyText || undefined}
                        className="Notes__textarea"
                        onChange={(event) => {
                            setIsNotesChanged(true);
                            setNotes(event.target.value);
                        }}
                    />
                )}
            </Modal>
        </>
    );
}

export default memo(Notes);
