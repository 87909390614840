import React from 'react';
import { ChannelRole, viewBuyplanColumns } from 'buyplan-common';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SettingsModal from '../SettingsModal/SettingsModal';
import SettingsDisplayColumns from '../UserSettings/SettingsDisplayColumns';
import SettingsFavoriteStores from '../UserSettings/SettingsFavoriteStores';
import useHasChannelRole from '../../selectors/useHasChannelRole';

interface Props {
    onClose(): void;
}

function BuyPlanSettings({ onClose }: Props) {
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);
    return (
        <SettingsModal onClose={onClose}>
            <Tabs>
                <TabList>
                    <Tab>
                        <h3 data-e2e="display-columns-tab">DISPLAY COLUMNS</h3>
                    </Tab>
                    {!isReadOnlyUser && (
                        <Tab>
                            <h3 data-e2e="favorite-stores-tab">FAVORITE STORES</h3>
                        </Tab>
                    )}
                </TabList>

                <TabPanel>
                    <SettingsDisplayColumns columns={viewBuyplanColumns} columnSetting="visibleBuyPlanColumnKeys" />
                </TabPanel>
                {!isReadOnlyUser && (
                    <TabPanel>
                        <SettingsFavoriteStores />
                    </TabPanel>
                )}
            </Tabs>
        </SettingsModal>
    );
}

export default BuyPlanSettings;
