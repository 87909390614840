"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storeLevelRows = exports.storeLevelRowsConfig = void 0;
const formatters_1 = require("../helpers/formatters");
/**
 * Returns the rows of the store level report.
 * - isGroupStart marks the start of a group
 * - hasNegativeValues marks the values have the ability to be negative
 * - getTotalValues is used for the subheaders of the report
 */
exports.storeLevelRowsConfig = [
    // Revenue
    {
        key: 'revenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Revenue',
        headerColSpan: 7,
        headerColWidth: 700,
    },
    {
        key: 'lastYearRevenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearRevenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'revenueGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'revenueGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'revenueVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
    },
    {
        key: 'revenueVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
    },
    // % TOTAL REVENUE
    {
        key: 'revenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: '% Total Revenue',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'lastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
    },
    // SEASONAL OPTION COUNTS
    {
        key: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Seasonal Option Counts',
        headerColSpan: 4,
        headerColWidth: 400,
    },
    {
        key: 'seasonalOptionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'lastYearSeasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearSeasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    // TRADED OPTION COUNTS
    {
        key: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Traded Option Counts',
        headerColSpan: 4,
        headerColWidth: 400,
    },
    {
        key: 'tradedOptionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'lastYearTradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearTradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    // SALES UNITS
    {
        key: 'salesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Sales Units',
        headerColSpan: 7,
        headerColWidth: 710,
    },
    {
        key: 'lastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'salesUnitsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'salesUnitsGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'salesUnitsVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
    },
    {
        key: 'salesUnitsVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
    },
    // SALES ASP (AVERAGE SELLING PRICE)
    {
        key: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'TY',
        groupStartHeader: 'Sales ASP',
        headerColSpan: 5,
        headerColWidth: 425,
    },
    {
        key: 'lastYearAverageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LY',
    },
    {
        key: 'lastLastYearAverageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LLY',
    },
    {
        key: 'averageSellingPriceGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'averageSellingPriceGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    // ST (SELL THROUGH)
    {
        key: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Sell Through %',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'lastYearSellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearSellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
    },
    // BOP
    {
        key: 'beginningOfPeriodUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'BOP',
        headerColSpan: 1,
        headerColWidth: 100,
    },
    // Open To Buy Units
    {
        key: 'openToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'OTB Units',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'aaOpenToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AA',
    },
    {
        key: 'afOpenToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AF',
    },
    // BUY RETAIL VALUE $
    {
        key: 'buyRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Buy Retail Value $',
        headerColSpan: 3,
        headerColWidth: 300,
    },
    {
        key: 'aaBuyRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AA',
    },
    {
        key: 'afBuyRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AF',
    },
    // BUY WHOLESALE VALUE $
    {
        key: 'buyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Buy Wholesale Value $',
        headerColSpan: 3,
        headerColWidth: 300,
    },
    {
        key: 'aaBuyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AA',
    },
    {
        key: 'afBuyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AF',
    },
    // Presentation Stocks
    {
        key: 'presentationStocks',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Presentation Stocks',
        headerColSpan: 7,
        headerColWidth: 710,
    },
    {
        key: 'lastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'investUnitToPresentationStockGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'investUnitToPresentationStockGrowthLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'investUnitsToPresentationStockVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
    },
    {
        key: 'investUnitsToPresentationStockVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
    },
];
const storeLevelRows = (channelId) => {
    if (!channelId) {
        return exports.storeLevelRowsConfig;
    }
    return exports.storeLevelRowsConfig.filter((rowConfig) => !rowConfig.applyToChannels || rowConfig.applyToChannels.includes(channelId));
};
exports.storeLevelRows = storeLevelRows;
