"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typeOfOrderRowsConfig = void 0;
const formatters_1 = require("../helpers/formatters");
/**
 * Returns the rows of the Type Of Order report.
 * - isGroupStart marks the start of a group
 * - hasNegativeValues marks the values have the ability to be negative
 * - getTotalValues is used for the subheaders of the report
 */
exports.typeOfOrderRowsConfig = [
    // Revenue
    {
        key: 'revenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Revenue',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'lastYearRevenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'revenueGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    // % TOTAL REVENUE
    {
        key: 'revenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: '% Total Revenue',
        headerColSpan: 2,
        headerColWidth: 200,
    },
    {
        key: 'lastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    // SALES UNITS
    {
        key: 'salesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Sales Units',
        headerColSpan: 2,
        headerColWidth: 200,
    },
    {
        key: 'lastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    // ST (SELL THROUGH)
    {
        key: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Sell Through %',
        headerColSpan: 2,
        headerColWidth: 200,
    },
    {
        key: 'lastYearSellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    // OTB Units
    {
        key: 'openToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'OTB Units',
        headerColWidth: 100,
    },
    // BUY WHOLESALE VALUE $
    {
        key: 'buyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Buy Wholesale Value $',
        headerColWidth: 150,
    },
    // Presentation Stocks
    {
        key: 'presentationStocks',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Presentation Stocks',
        headerColSpan: 2,
        headerColWidth: 200,
    },
    {
        key: 'lastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
];
