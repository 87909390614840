import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LateAddMaterial as LateAddMaterialType } from 'buyplan-common';
import Modal from '../Modal/Modal';
import { deleteMaterial } from '../../services/materialService';
import Loader from '../Loader/Loader';
import './LateAddMaterial.scss';
import MaterialImage from '../MaterialImage/MaterialImage';

export interface LateAddMaterialProps {
    material: LateAddMaterialType;
    onDelete(materialCode: string): void;
}

export interface LateAddMaterialState {
    confirming: boolean;
    confirmed: boolean;
    deleting: boolean;
}

export class LateAddMaterial extends PureComponent<LateAddMaterialProps, LateAddMaterialState> {
    state = {
        confirming: false,
        confirmed: false,
        deleting: false,
    };

    handleRemove = () => {
        const {
            material: { isModified },
        } = this.props;
        const { deleting, confirmed } = this.state;
        if (deleting) {
            return;
        }
        if (isModified && !confirmed) {
            this.setState({ confirming: true });
        } else {
            this.removeMaterial();
        }
    };

    removeMaterial = async () => {
        const { deleting } = this.state;
        const {
            onDelete,
            material: { materialCode },
        } = this.props;

        if (deleting) {
            return;
        }

        this.setState({ deleting: true });
        await deleteMaterial(materialCode);
        onDelete(materialCode);
    };

    render() {
        const { confirming, deleting } = this.state;
        const { material } = this.props;
        const { materialCode, category, division, description, isModified } = material;
        return (
            <tr className="LateAddMaterial">
                <td className="LateAddMaterial__image LateAddMaterial--noPadding">
                    <MaterialImage material={material} />
                </td>
                <td>{materialCode}</td>
                <td>{description}</td>
                <td>{category}</td>
                <td>{division}</td>
                <td>
                    <span className="LateAdds__remove">
                        <span role="button" tabIndex={0} onClick={this.handleRemove}>
                            <FontAwesomeIcon icon={faTrash as IconProp} />
                        </span>
                        {isModified && !deleting && <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />}
                        {deleting && <Loader width={12} />}
                    </span>

                    {confirming && (
                        <Modal
                            closeAction={() => this.setState({ confirming: false, deleting: false })}
                            cancelAction={() => this.setState({ confirming: false, deleting: false })}
                            okAction={() => {
                                this.setState({ confirming: false, confirmed: true }, this.removeMaterial);
                            }}
                            title="Changes Detected"
                            subtitle="A retail planner has added modifications"
                            text="Do you still wish to remove these material?"
                            isOpen={confirming}
                            okButtonText="Continue"
                            okButtonType="button"
                            cancelButtonText="Cancel"
                        />
                    )}
                </td>
            </tr>
        );
    }
}

export default LateAddMaterial;
