import { round } from 'lodash';
import React from 'react';
import { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';

interface Props {
    value: number;
    onChange(value: number): void;
    clearBehaviour: ClearBehaviour.RESET_LAST_VALUE | ClearBehaviour.SET_NULL;
    onBlur?: () => void;
    className?: string;
    minimum: number;
    maximum: number;
    autoFocus?: boolean;
}

function PercentageInput({
    value,
    minimum,
    maximum,
    onChange,
    clearBehaviour,
    onBlur,
    className,
    ...props
}: Omit<NumberFormatProps, 'onChange'> & Props) {
    return (
        <EnhancedNumberFormat
            {...props}
            value={round(value * 100, 1)}
            suffix="%"
            decimalScale={0}
            step={1}
            minimum={minimum}
            maximum={maximum}
            clearBehaviour={clearBehaviour}
            onChange={(newValue) => {
                if (!newValue) {
                    onChange(0);
                }
                if (newValue !== null && !Number.isNaN(newValue)) {
                    onChange(round(newValue / 100, 2));
                }
            }}
            isAllowed={(values: NumberFormatValues) => {
                const { floatValue } = values;
                if (floatValue !== undefined) {
                    return floatValue >= minimum && floatValue <= maximum;
                }
                return true;
            }}
            onBlur={() => onBlur && onBlur()}
            className={className}
        />
    );
}

export default PercentageInput;
