import { PartnerMaterialReviewedStatus } from 'buyplan-common';
import { request } from '../api/api';

export const updateLastYearReference = async (
    aggregatedMaterialId: string,
    materialId: string,
    lastYearReferenceManualMaterialCode: string
) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/aggregatedMaterials/${aggregatedMaterialId}/setLastYearReferenceManualMaterialCode`,
        data: { lastYearReferenceManualMaterialCode, materialId },
    };

    await request(requestOptions);
};

export const setAggregatedMaterialNotes = async (aggregatedMaterialId: string, materialId: string, notes: string) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/aggregatedMaterials/${aggregatedMaterialId}`,
        data: { notes, materialId },
    };

    await request(requestOptions);
};

export const setMaterialReviewedStatus = async (
    materialId: string,
    partnerId: string,
    reviewedStatus: PartnerMaterialReviewedStatus
) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/partnerMaterials/${materialId}/partner/${partnerId}`,
        data: { reviewedStatus },
    };

    await request(requestOptions);
};
