import { HindsightFile, HindsightYearType, UploadHindsightBuy, UploadHindsightMaterial } from 'buyplan-common';
import { request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { uploadToStorage } from './uploadFileService';

export const addHindsightMaterials = (data: UploadHindsightMaterial[], fileId: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles/${fileId}/materials`,
        data,
    };

    return request(requestOptions, true);
};

export const addHindsightBuys = (data: { buys: UploadHindsightBuy[]; yearType: HindsightYearType }, fileId: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles/${fileId}/buys`,
        data,
    };

    return request(requestOptions, true);
};

export const uploadHindsightFile = async (file: File, uuid: string) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${uuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `hindsight-files/${uniqueFileName}`;

    try {
        await uploadToStorage(file, destinationKey, file.type, file.name.includes('no_upload'));
    } catch (err: unknown) {
        throw Error('File upload failed.');
    }

    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
            uuid,
        },
    };

    return request<HindsightFile>(requestOptions);
};

export const deleteHindsightFile = (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles/${uuid}`,
    };

    return request(requestOptions);
};

export const searchHindsight = (partnerId: string, searchText: string) => {
    const endPoint = `${process.env.REACT_APP_API_ENDPOINT}/hindsight/${partnerId}/search`;
    const requestOptions = {
        method: 'GET',
        url: endPoint,
        queryParams: { searchText },
    };

    return request<{ data: { materialCode: string; description: string; colorDescription: string }[] }>(requestOptions);
};
