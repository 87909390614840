import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    date: number;
    filename: string;
}

function TitleColumn({ date, filename }: Props) {
    const dateObject = new Date(date);
    return (
        <>
            <FontAwesomeIcon icon={faTable as IconProp} />
            {filename}
            <span className="title-column-datetime">
                ({dateObject.toLocaleDateString()} {dateObject.toLocaleTimeString()})
            </span>
        </>
    );
}

export default memo(TitleColumn);
