import { request } from '../api/api';

export const getExternalImages = () => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/external-images/sync`,
    };

    return request(requestOptions, true);
};
