"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.channels = void 0;
exports.channels = {
    // NSP (1) and NSO (2) are now deprecated
    digital: {
        id: 3,
        name: 'DIGITAL',
    },
    wholesale: {
        id: 4,
        name: 'WHOLESALE',
    },
    nds: {
        id: 5,
        name: 'NDS',
    },
    nvs: {
        id: 6,
        name: 'NVS',
    },
};
