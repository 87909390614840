import { MainFiltersExtended as MainFiltersType } from 'buyplan-common';

const areArraysEqual = (currVal?: string[], prevVal?: string[]) => {
    if (!currVal || !prevVal) {
        return currVal === prevVal;
    }
    if (currVal.length !== prevVal.length) {
        return false;
    }
    currVal.sort();
    prevVal.sort();
    for (let i = 0; i < currVal.length; i++) {
        if (currVal[i] !== prevVal[i]) {
            return false;
        }
    }
    return true;
};

/*
    shouldUpdateBuyPlanMeta will accept an unknown number of arrays, representing the required filters for a given report.
    Each array should include two arrays to compare:
    1 - the current filter values
    2 - the previous filter values
    e.g. [partner, previousPartner], [category, previousCategory]
    The function will then check and see if there are changes between any of the pairs
*/

export const shouldUpdateBuyPlanMeta = (...args: (string[] | undefined)[][]): boolean => {
    for (const [currentValue, previousValue] of args) {
        // Do not update if there is no current value for a required filter
        if (!currentValue || !currentValue.length) return false;
        if (!areArraysEqual(previousValue, currentValue)) {
            // If there are changes to the required filters, we should update
            return true;
        }
    }
    // If all values are equal, no need to update
    return false;
};

/*
    shouldClearFilters compares changes to disabledFilterKeys and will return true if these keys are currently
    active in the main filters and need to be cleared.
*/

export const shouldClearFilters = (
    disabledFilterKeys: string[] | undefined,
    previousDisabledFilterKeys: string[] | undefined,
    activeMainFilters: MainFiltersType
) => {
    if (!disabledFilterKeys || !disabledFilterKeys.length) return false;
    if (
        !areArraysEqual(disabledFilterKeys, previousDisabledFilterKeys) &&
        disabledFilterKeys.some((key) => key in activeMainFilters)
    )
        return true;
    return false;
};
