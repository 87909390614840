import { request } from '../api/api';

export interface PurchaseOrder {
    purchaseOrderNumber: string;
    quantity: string;
}

export interface SoldTo {
    soldToNumber: string;
    quantity: string;
}

export interface Summary {
    purchaseOrders?: PurchaseOrder[];
    soldTo?: SoldTo[];
    meta?: {
        total: string;
    };
}

export const triggerSubmit = () => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orders/submit-to-pega`,
    };

    return request<{ message: string; summary: Summary }>(requestOptions, true);
};

export const getSummary = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orders/submit-to-pega/summary`,
    };

    return request<Summary>(requestOptions, true);
};
