import React from 'react';
import { FileValidationErrors } from 'buyplan-common';
import SourceFileError from './SourceFileError';
import formatValidationError from './formatValidationError';

interface Props {
    errors: FileValidationErrors;
}

function FileValidationErrors({ errors }: Props) {
    const { validationErrors } = errors;

    return validationErrors ? (
        <SourceFileError title="The following errors occurred:" items={validationErrors.map(formatValidationError)} />
    ) : null;
}

export default FileValidationErrors;
