import React from 'react';
import { MerchandiseFileErrors as MerchandiseFileErrorsType } from 'buyplan-common';
import SourceFileError from './SourceFileError';
import formatValidationError from './formatValidationError';

interface Props {
    errors: MerchandiseFileErrorsType;
}

function MerchandiseFileErrors({ errors }: Props) {
    const { alreadyExistingMaterials, duplicatesInFile, duplicateMaterialStores, fileIsEmpty, validationErrors } = errors;
    return (
        <>
            {fileIsEmpty && <SourceFileError title="No materials found in the file" items={[]} itemType="header" />}
            {alreadyExistingMaterials && (
                <SourceFileError
                    title="The following materials are already imported by another file:"
                    items={alreadyExistingMaterials}
                    itemType="material code"
                    grid
                />
            )}
            {duplicatesInFile && (
                <SourceFileError
                    title="This file contains these duplicate material codes:"
                    items={duplicatesInFile}
                    itemType="material code"
                    grid
                />
            )}
            {duplicateMaterialStores && (
                <SourceFileError
                    title="This file contains these duplicate material/store combinations:"
                    items={duplicateMaterialStores}
                    itemType="material code"
                    grid
                />
            )}
            {validationErrors && (
                <SourceFileError
                    title="The following rows are invalid:"
                    items={validationErrors.map(formatValidationError)}
                    itemType="material code"
                />
            )}
        </>
    );
}

export default MerchandiseFileErrors;
