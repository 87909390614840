import React, { memo } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TopStylesReportTableData } from './calculations';
import TopStylesSummary from './TopStylesSummary';
import TopStylesRow from './TopStylesRow';
import './TopStylesTable.scss';
import { ReportYearType } from './TopStylesReport';

interface Props {
    report: TopStylesReportTableData;
    previousYear?: TopStylesReportTableData;
    reportYearType: ReportYearType;
    onSortColumn(columnKey: string, orderDirection: 'ASC' | 'DESC', reportYearType: ReportYearType): void;
    orderDirection?: string;
    orderLastYearDirection?: string;
    orderLastLastYearDirection?: string;
    orderColumn?: string;
    orderLastYearColumn?: string;
    orderLastLastYearColumn?: string;
}

const MAX_ROWS = 15;

function TopStylesTable({
    report,
    previousYear,
    reportYearType,
    onSortColumn,
    orderDirection,
    orderLastYearDirection,
    orderLastLastYearDirection,
    orderColumn,
    orderLastYearColumn,
    orderLastLastYearColumn,
}: Props) {
    const investmentColSpan = reportYearType === ReportYearType.TY ? 3 : 3;

    const getSortOrderIcon = (key: string) => {
        if (reportYearType === ReportYearType.TY) {
            return orderColumn === `${key}` && orderDirection === 'DESC' ? faSortDown : faSortUp;
        }
        if (reportYearType === ReportYearType.LY) {
            return orderLastYearColumn === `${key}` && orderLastYearDirection === 'DESC' ? faSortDown : faSortUp;
        }
        if (reportYearType === ReportYearType.LLY) {
            return orderLastLastYearColumn === `${key}` && orderLastLastYearDirection === 'DESC' ? faSortDown : faSortUp;
        }
        return faSortUp;
    };

    const getSortOrderDirection = (key: string) => {
        if (reportYearType === ReportYearType.TY) {
            return orderColumn === `${key}` && orderDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        if (reportYearType === ReportYearType.LY) {
            return orderLastYearColumn === `${key}` && orderLastYearDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        if (reportYearType === ReportYearType.LLY) {
            return orderLastLastYearColumn === `${key}` && orderLastLastYearDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        return 'DESC';
    };

    const shouldActivateSortButton = (key: string) => {
        if (reportYearType === ReportYearType.TY) {
            return orderColumn === `${key}`;
        }
        if (reportYearType === ReportYearType.LY) {
            return orderLastYearColumn === `${key}`;
        }
        if (reportYearType === ReportYearType.LLY) {
            return orderLastLastYearColumn === `${key}`;
        }
        return 'DESC';
    };

    return (
        <div className="TopStylesTable TopStylesTable__container">
            <table cellPadding={0} cellSpacing={0} className="TopStylesTable__table table">
                <colgroup>
                    <col className="TopStylesTable__columns-image" />
                    <col className="TopStylesTable__columns-material" />
                    <col className="TopStylesTable__columns-styleDescription" />
                    <col className="TopStylesTable__columns-revenue" />
                    <col className="TopStylesTable__columns-unitSalesTtlAsp" />
                    <col className="TopStylesTable__columns-unitSalesTtlAsp" />
                    <col className="TopStylesTable__columns-unitSalesTtlAsp" />
                    <col className="TopStylesTable__columns-unitSalesTtlAsp" />
                    <col className="TopStylesTable__columns-StoresWosRos" />
                    <col className="TopStylesTable__columns-StoresWosRos" />
                    <col className="TopStylesTable__columns-StoresWosRos" />
                    <col span={investmentColSpan} className="TopStylesTable__columns" />
                </colgroup>
                <thead>
                    <tr className="TopStylesTable__header">
                        <th />
                        <th>Material</th>
                        <th>Description</th>
                        <th className="TopStylesTable__header-data" colSpan={8}>
                            Sales
                        </th>
                        <th className="TopStylesTable__header-data" colSpan={investmentColSpan}>
                            Investments
                        </th>
                    </tr>
                    <tr className="TopStylesTable__subheader">
                        <th />
                        <th />
                        <th />
                        <th>
                            <div title="Sort Revenue">
                                <div
                                    tabIndex={0}
                                    className={cn(
                                        'SortableColumn__sortable SortableColumn__button SortableColumn__button--icon',
                                        shouldActivateSortButton('Revenue') && 'SortableColumn__button--active'
                                    )}
                                    role="button"
                                    onClick={() => {
                                        onSortColumn(`Revenue`, getSortOrderDirection(`Revenue`), reportYearType);
                                    }}
                                >
                                    <FontAwesomeIcon icon={getSortOrderIcon(`Revenue`) as IconProp} />
                                </div>
                                Revenue
                            </div>
                        </th>
                        <th>
                            <div title="Sort SalesUnits">
                                <div
                                    tabIndex={0}
                                    className={cn(
                                        'SortableColumn__sortable SortableColumn__button SortableColumn__button--icon',
                                        shouldActivateSortButton('SalesUnits') && 'SortableColumn__button--active'
                                    )}
                                    role="button"
                                    onClick={() => {
                                        onSortColumn(`SalesUnits`, getSortOrderDirection(`SalesUnits`), reportYearType);
                                    }}
                                >
                                    <FontAwesomeIcon icon={getSortOrderIcon(`SalesUnits`) as IconProp} />
                                </div>
                                Sales Units
                            </div>
                        </th>
                        <th>% Ttl revenue</th>
                        <th>% Ttl sales units</th>
                        <th>Sales asp</th>
                        <th>Stores</th>
                        <th>Wos</th>
                        <th>Ros</th>
                        {reportYearType === ReportYearType.TY && (
                            <>
                                <th>Presentation Stocks</th>
                                <th>Open To Buy Units</th>
                            </>
                        )}
                        {reportYearType !== ReportYearType.TY && (
                            <>
                                <th>Invest Units</th>
                                <th>% Ttl Invest units</th>
                            </>
                        )}
                        <th>St %</th>
                    </tr>
                </thead>
                {report.rows.length === 0 ? (
                    <tbody>
                        <tr>
                            <td colSpan={11}>
                                <span className="TopStylesTable__noResults">No report data found, try changing filters</span>
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <>
                        <tbody>
                            {report.rows.slice(0, MAX_ROWS).map((row) => (
                                <TopStylesRow key={row.materialCode} row={row} reportYearType={reportYearType} />
                            ))}
                        </tbody>
                        <tfoot className="TopStylesTable__footer">
                            <TopStylesSummary
                                summaryHeader="TOP 5"
                                report={report}
                                previousYear={previousYear}
                                totalRows={5}
                                reportYearType={reportYearType}
                            />
                            <TopStylesSummary
                                summaryHeader="TOP 10"
                                report={report}
                                previousYear={previousYear}
                                totalRows={10}
                                reportYearType={reportYearType}
                            />
                            <TopStylesSummary
                                summaryHeader="TOP 15"
                                report={report}
                                previousYear={previousYear}
                                totalRows={15}
                                reportYearType={reportYearType}
                            />
                            <TopStylesSummary
                                summaryHeader="TOTAL"
                                report={report}
                                previousYear={previousYear}
                                reportYearType={reportYearType}
                            />
                        </tfoot>
                    </>
                )}
            </table>
        </div>
    );
}

export default memo(TopStylesTable);
