import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MimeTypes } from 'buyplan-common';
import { v4 } from 'uuid';
import { getOverviewItems } from '../../actions/overview';
import usePrevious from '../../helpers/usePrevious';
import * as updateQuantitiesService from '../../services/updateQuantitiesService';
import { useSelector } from '../../store/reducers';
import FileDrop from '../FileDrop/FileDrop';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import UpdateQuantitiesFeedback from './UpdateQuantitiesFeedback';
import './UpdateQuantities.scss';

const acceptedMimeTypes = MimeTypes.excel;

export const UPDATE_LIMIT = 100000;

interface Props {
    onClose: () => void;
    isOpen?: boolean;
}

function UpdateQuantities({ onClose, isOpen = false }: Props) {
    const dispatch = useDispatch();
    const { activeFilters, rows } = useSelector((state) => state.overview);
    const [success, setSuccess] = useState(false);
    const [pending, setPending] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const resetTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const previousSuccess = usePrevious(success);

    const reset = useCallback(() => {
        setSuccess(false);
        setPending(false);
        setMessage('');
        setErrorMessage('');
        setDownloaded(false);
        if (resetTimer.current) clearTimeout(resetTimer.current);
    }, []);

    const handleCloseModal = useCallback(() => {
        reset();
        onClose();
    }, [reset, onClose]);

    useEffect(
        () => () => {
            if (resetTimer.current) clearTimeout(resetTimer.current);
        },
        []
    );

    const resetOnTimeout = useCallback(
        (callback?: () => void) => {
            resetTimer.current = setTimeout(() => {
                reset();
                if (callback) {
                    callback();
                }
            }, 5000);
        },
        [reset]
    );

    useEffect(() => {
        if (!previousSuccess && success) {
            dispatch(getOverviewItems());
            resetOnTimeout(handleCloseModal);
        }
    }, [dispatch, handleCloseModal, previousSuccess, resetOnTimeout, success]);

    const handleDownload = () => {
        updateQuantitiesService.getQuantitiesUpdateExport(activeFilters);
        setDownloaded(true);
    };

    const handleDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 1) {
            setErrorMessage('Please upload only 1 file at the time');
            return;
        }

        if (acceptedFiles.length < 1) {
            setErrorMessage("Please upload a file that's downloaded from this batch quantity update window");
            return;
        }

        setErrorMessage('');
        setPending(true);

        try {
            const file = acceptedFiles[0];
            const response = await updateQuantitiesService.importQuantities(file, v4());
            setSuccess(true);
            setMessage(response.message);
        } catch (err: unknown) {
            const error = err as Error;
            setSuccess(false);
            setErrorMessage(error.message);
        } finally {
            setPending(false);
        }
    };

    const showFeedback = pending || message !== '';
    const showFileDrop = !showFeedback && downloaded;

    if (rows >= UPDATE_LIMIT) {
        return (
            <div className="update-quantities">
                <Modal isOpen={isOpen} closeAction={handleCloseModal} title="Batch update quantities">
                    <p>
                        This functionality only supports up to <strong>{UPDATE_LIMIT.toLocaleString()}</strong> records. You
                        are currently trying to batch update <strong>{(rows as number).toLocaleString()}</strong> records.
                        Please narrow your selection with the filters on the left.
                    </p>
                </Modal>
            </div>
        );
    }

    return (
        <div className="update-quantities">
            <Modal
                isOpen={isOpen}
                closeAction={handleCloseModal}
                title="Batch update quantities"
                subtitle={` for ${rows} ${rows > 1 ? 'records' : 'record'}`}
            >
                <div className="update-quantities__modal">
                    <p>
                        If you want to batch edit quantities you need to export the selected records here, modify them in
                        excel and upload the file below
                    </p>

                    {!downloaded && (
                        <div className="update-quantities__actions">
                            <Button
                                onClick={() => setDownloaded(true)}
                                className="update-quantities__skip-download Button__inverted"
                            >
                                I already have a file to upload
                            </Button>
                            <Button className="update-quantities__download" onClick={handleDownload}>
                                Download {rows} records
                            </Button>
                        </div>
                    )}
                    {showFeedback && <UpdateQuantitiesFeedback message={message} pending={pending} success={success} />}

                    {showFileDrop && (
                        <FileDrop
                            title="Upload file to update records"
                            acceptedMimeTypes={acceptedMimeTypes}
                            onDrop={handleDrop}
                            multiple={true}
                        />
                    )}

                    {errorMessage && <p className="update-quantities__message--error">{errorMessage}</p>}
                </div>
            </Modal>
        </div>
    );
}

export default UpdateQuantities;
