import AutoSizer from 'react-virtualized-auto-sizer';
import React, { ReactNode } from 'react';
import { ScrollSync, InfiniteLoader, IndexRange } from 'react-virtualized';
import { OnScrollParams } from 'react-virtualized/dist/es/ScrollSync';
import { BuyPlanAggregatedMaterial, Material, PagingMetadata } from 'buyplan-common';
import './InfiniteScrollList.scss';

interface InfiniteScrollListRenderProps {
    height: number;
    width: number;
    onScroll(params: OnScrollParams): void;
    scrollTop: number;
    onRowsRendered(params: IndexRange): void;
}

interface Props<T> {
    loading: boolean;
    pagination?: PagingMetadata & { totalFetched: number };
    rows: BuyPlanAggregatedMaterial[] | Material[];
    fetchPage(page: number): Promise<T>;
    children(props: InfiniteScrollListRenderProps): ReactNode;
}

function InfiniteScrollList<T>({ loading, children, rows, pagination, fetchPage }: Props<T>) {
    const hasNextPage = !loading && pagination && pagination.totalFetched === pagination.pageSize;
    const rowCount = hasNextPage ? rows.length + 1 : rows.length;
    const isRowLoaded = ({ index }: { index: number }) => !hasNextPage || index < rows.length;

    const loadMoreRows = async () => {
        if (!loading && pagination) {
            return fetchPage(pagination.page + 1);
        }
        return {}; // The plain empty object returned is required by InfiniteLoader to avoid resolving the loadMore promises
    };

    return (
        <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCount}>
            {({ onRowsRendered }) => (
                <ScrollSync>
                    {({ onScroll, scrollTop }) => (
                        <div className="infinite-scroll-list">
                            <div className="scroll-smoother" /> {/* Needed to keep AutoSizer in sync while scrolling */}
                            <AutoSizer>
                                {({ height, width }) => children({ height, width, onScroll, scrollTop, onRowsRendered })}
                            </AutoSizer>
                        </div>
                    )}
                </ScrollSync>
            )}
        </InfiniteLoader>
    );
}

export default InfiniteScrollList;
