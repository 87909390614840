import { PricesSyncResult, RequiredCountryPrices } from 'buyplan-common';
import { request } from '../api/api';

export const syncPrices = () => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/prices/sync`,
    };

    return request<{ data: PricesSyncResult }>(requestOptions, true);
};

export const syncPricesByMaterial = (materialCode: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/prices/sync/${materialCode}`,
    };

    return request<{ data: RequiredCountryPrices }>(requestOptions);
};
