type GlobalWindow = Window & typeof globalThis;

type WindowExtended = GlobalWindow & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opr: { addons: any };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HTMLElement: any;
    safari: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pushNotification: any;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chrome: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
};

type DocumentExtended = Document & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    documentMode?: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const InstallTrigger: any;

// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769#9851769
export default () => {
    const isOpera =
        (!!(window as WindowExtended).opr && !!(window as WindowExtended).opr.addons) ||
        !!(window as WindowExtended).opera ||
        navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera) {
        return 'Opera';
    }

    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
        return 'Firefox';
    }

    const isSafari =
        /constructor/i.test((window as WindowExtended).HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(
            !(window as WindowExtended).safari ||
                (typeof (window as WindowExtended).safari !== 'undefined' &&
                    (window as WindowExtended).safari.pushNotification)
        );
    if (isSafari) {
        return 'Safari';
    }

    // eslint-disable-next-line spaced-comment
    const isIE = /*@cc_on!@*/ false || !!(document as DocumentExtended).documentMode;
    if (isIE) {
        return 'Internet Explorer';
    }

    const isEdge = !isIE && window.navigator.userAgent.indexOf('Edge') > -1;
    if (isEdge) {
        return 'Microsoft Edge';
    }

    const isChrome = !!(window as WindowExtended).chrome;
    if (isChrome) {
        return 'Chrome';
    }

    const isCypress = !!(window as WindowExtended).Cypress;
    if (isCypress) {
        return 'Cypress';
    }

    return 'This browser';
};
