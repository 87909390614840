import React, { memo } from 'react';
import 'react-tabs/style/react-tabs.css';
import cn from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FamilyModelBreakdownReport, FamilyModelBreakdownReportTotals, FamilyModelReportType } from 'buyplan-common';
import Loader from '../../Loader/Loader';
import './FamilyModelTabs.scss';
import { combineWords } from '../../../helpers/language';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import FamilyModelTable from './FamilyModelTable';
import ModelDashboardContainer from './ModelDashboardContainer';

export enum FamilyModelType {
    Model = 'Model',
    Family = 'Family',
}

interface Props {
    onChangeTab(reportType: FamilyModelReportType): void;
    loadingFamily: boolean;
    loadingModel: boolean;
    loadingModelWithMaterials: boolean;
    missingFilters: string[];
    familyReport?: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals };
    modelReport?: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals };
    modelWithMaterialsReport?: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals };
    errorFamilyReport?: Error;
    errorModelReport?: Error;
    errorModelWithMaterialsReport?: Error;
    showFamily: boolean;
    showModel: boolean;
    showModelMaterial: boolean;
    channelId: number;
    filtersHaveChanged: boolean;
    isMetaLoading: boolean;
}

interface TablePanelReportProps {
    loading: boolean;
    missingFilters: string[];
    results?: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals };
    error?: Error;
    reportType: FamilyModelReportType;
    channelId: number;
    filtersHaveChanged: boolean;
}

function TabPanelReport({
    loading,
    missingFilters,
    error,
    results,
    reportType,
    channelId,
    filtersHaveChanged,
}: TablePanelReportProps) {
    if (missingFilters.length > 0) {
        return <div className="family-model-tabs__no-data">Please select a filter for {combineWords(missingFilters)}</div>;
    }
    if (error) {
        return (
            <div className="family-model-tabs__no-data">
                <ErrorMessage error={error} />
            </div>
        );
    }
    if (loading) return <Loader />;
    if (!results || results.report.length === 0) {
        // if there are no results, report has not been loaded yet
        if (!results) return <div className="family-model-tabs__no-data">Click to load report data.</div>;
        // if results are empty but there is no data found
        return <div className="family-model-tabs__no-data">No report data found, try changing filters</div>;
    }
    // if there is data already, but the filters have been updated, do not display out-of-date report
    if (filtersHaveChanged) {
        return <div className="family-model-tabs__no-data">Click to load report data.</div>;
    }
    if (reportType === 'modelDashboard') {
        return <ModelDashboardContainer results={results} />;
    }
    return <FamilyModelTable reportType={reportType} results={results} channelId={channelId} />;
}

function FamilyModelTabs({
    familyReport,
    modelReport,
    modelWithMaterialsReport,
    loadingFamily,
    loadingModel,
    loadingModelWithMaterials,
    onChangeTab,
    missingFilters,
    errorFamilyReport,
    errorModelReport,
    errorModelWithMaterialsReport,
    showFamily,
    showModel,
    showModelMaterial,
    channelId,
    filtersHaveChanged,
    isMetaLoading,
}: Props) {
    return (
        <Tabs
            className={cn('family-model-tabs', {
                'family-model-tabs--loading': isMetaLoading || loadingFamily || loadingModel || loadingModelWithMaterials,
            })}
            onSelect={(index) => {
                if (index === 0) onChangeTab('family');
                if (index === 1) onChangeTab('model');
                if (index === 2) onChangeTab('modelDashboard');
            }}
        >
            <TabList>
                {showFamily && (
                    <Tab>
                        <h3>FAMILY</h3>
                    </Tab>
                )}
                {showModel && (
                    <Tab>
                        <h3>MODEL</h3>
                    </Tab>
                )}
                {showModelMaterial && (
                    <Tab>
                        <h3>MODEL W/ MATERIALS</h3>
                    </Tab>
                )}
            </TabList>

            {showFamily && (
                <TabPanel>
                    <TabPanelReport
                        loading={isMetaLoading || loadingFamily}
                        results={familyReport}
                        error={errorFamilyReport}
                        reportType="family"
                        missingFilters={missingFilters}
                        channelId={channelId}
                        filtersHaveChanged={filtersHaveChanged}
                    />{' '}
                </TabPanel>
            )}
            {showModel && (
                <TabPanel>
                    <TabPanelReport
                        loading={isMetaLoading || loadingModel}
                        results={modelReport}
                        error={errorModelReport}
                        reportType="model"
                        missingFilters={missingFilters}
                        channelId={channelId}
                        filtersHaveChanged={filtersHaveChanged}
                    />
                </TabPanel>
            )}
            {showModelMaterial && (
                <TabPanel>
                    <TabPanelReport
                        loading={isMetaLoading || loadingModelWithMaterials}
                        results={modelWithMaterialsReport}
                        error={errorModelWithMaterialsReport}
                        reportType="modelDashboard"
                        missingFilters={missingFilters}
                        channelId={channelId}
                        filtersHaveChanged={filtersHaveChanged}
                    />
                </TabPanel>
            )}
        </Tabs>
    );
}

export default memo(FamilyModelTabs);
