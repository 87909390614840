import {
    FamilyModelBreakdownReport,
    FamilyModelBreakdownReportTotals,
    HindsightYearType,
    MainFiltersExtended,
    StoreLevelBreakdownReport,
    TopStylesReportData,
    FamilyModelReportType,
    StoreStoreLevelBreakdownReportData,
    ModelDashboardListItem,
    TypeOfOrderBreakdownReport,
    CategoryLevelTypeOfOrderBreakdownReportData,
    SilhouetteBreakdownReport,
    SilhouetteBreakdownReportTotals,
    CategoryDivisionBreakdownReport,
    CategoryLevelCategoryDivisionBreakDownReportData,
} from 'buyplan-common';
import { pick, omit } from 'lodash';
import { request } from '../api/api';

const getFamilyModelBreakdownReport = (type: FamilyModelReportType, filter: MainFiltersExtended, signal: AbortSignal) => {
    const activeFilters = pick(filter, [
        'partner',
        'category',
        'division',
        'gender',
        'age',
        'storeId',
        'typeOfOrder',
        'segment',
        'silhouette',
        'family',
        'model',
    ]);
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buylan/reports/partner-category-division-${type}`;
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        signal,
        queryParams: { filter: activeFilters },
    };

    return request<{ data: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals } }>(
        requestOptions,
        true
    );
};

export const getModelBreakdownReport = (filter: MainFiltersExtended, signal: AbortSignal) =>
    getFamilyModelBreakdownReport('model', filter, signal);

export const getFamilyBreakdownReport = (filter: MainFiltersExtended, signal: AbortSignal) =>
    getFamilyModelBreakdownReport('family', filter, signal);

export const getModelWMaterialsBreakdownReport = (filter: MainFiltersExtended, signal: AbortSignal) =>
    getFamilyModelBreakdownReport('modelDashboard', filter, signal);

export const getThisYearTopStylesReport = (
    activeFilters: MainFiltersExtended,
    orderColumn: string,
    orderDirection: 'ASC' | 'DESC',
    signal?: AbortSignal
) => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/this-year-top-styles`;
    const queryParams = {
        filter: pick(activeFilters, [
            'partner',
            'category',
            'division',
            'typeOfOrder',
            'gender',
            'age',
            'segment',
            'silhouette',
            'family',
            'model',
        ]),
        orderColumn,
        orderDirection,
    };
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        signal,
        queryParams,
    };
    return request<{ data: TopStylesReportData }>(requestOptions, true);
};

export const getHindsightTopStylesReport = (
    activeFilters: MainFiltersExtended,
    yearType: HindsightYearType,
    orderColumn: string,
    orderDirection: 'ASC' | 'DESC',
    signal?: AbortSignal
) => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/hindsight-top-styles`;
    const queryParams = {
        filter: pick(activeFilters, [
            'partner',
            'category',
            'division',
            'typeOfOrder',
            'gender',
            'age',
            'segment',
            'silhouette',
            'family',
            'model',
        ]),
        yearType,
        orderColumn,
        orderDirection,
    };
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        signal,
        queryParams,
    };
    return request<{ data: TopStylesReportData }>(requestOptions, true);
};

export const getStoreLevelReport = (activeFilters: MainFiltersExtended, signal?: AbortSignal) => {
    const queryParams = { filter: omit(activeFilters, 'division') };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/store-level`,
        signal,
        queryParams,
    };
    return request<{
        data: {
            storeLevelReportTotal: StoreLevelBreakdownReport[];
            storeLevelReportStores: StoreStoreLevelBreakdownReportData[];
        };
    }>(requestOptions, true);
};

export const getModelDashboardMaterials = (activeFilters: MainFiltersExtended, model?: string) => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buylan/reports/partner-category-division-model/materials`;
    const mainFilters = pick(activeFilters, ['partner', 'category', 'division', 'gender', 'age']);
    const filter = model
        ? {
              ...mainFilters,
              model,
          }
        : mainFilters;
    const queryParams = { filter };
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        queryParams,
    };
    return request<{ data: ModelDashboardListItem[] }>(requestOptions, true);
};

export const getTypeOfOrderReport = (activeFilters: MainFiltersExtended, signal?: AbortSignal) => {
    const queryParams = { filter: activeFilters };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/type-of-order`,
        signal,
        queryParams,
    };
    return request<{
        data: {
            typeOfOrderReportTotal: TypeOfOrderBreakdownReport[];
            typeOfOrderReportCategories: CategoryLevelTypeOfOrderBreakdownReportData[];
        };
    }>(requestOptions, true);
};

export const getSilhouetteBreakdownReport = (filter: MainFiltersExtended, signal: AbortSignal) => {
    const activeFilters = pick(filter, [
        'partner',
        'category',
        'division',
        'silhouette',
        'typeOfOrder',
        'gender',
        'age',
        'segment',
        'model',
        'family',
    ]);
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/silhouette`;
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        signal,
        queryParams: { filter: activeFilters },
    };

    return request<{ data: { report: SilhouetteBreakdownReport[]; totals: SilhouetteBreakdownReportTotals } }>(
        requestOptions,
        true
    );
};

export const getCategoryDivisionReport = (activeFilters: MainFiltersExtended, signal?: AbortSignal) => {
    const queryParams = { filter: omit(activeFilters, 'division') };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/category-division`,
        signal,
        queryParams,
    };
    return request<{
        data: {
            categoryDivisionReportTotal: CategoryDivisionBreakdownReport[];
            categoryDivisionReportCategories: CategoryLevelCategoryDivisionBreakDownReportData[];
        };
    }>(requestOptions, true);
};
