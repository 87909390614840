"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = exports.ChannelRole = void 0;
var ChannelRole;
(function (ChannelRole) {
    ChannelRole["admin"] = "admin";
    ChannelRole["readOnly"] = "readOnly";
    // TODO: ACCESS MANAGEMENT remove below users. after adjusting existing user in DB.
    ChannelRole["regular"] = "regular";
    ChannelRole["quickStrike"] = "quickStrike";
})(ChannelRole || (exports.ChannelRole = ChannelRole = {}));
var UserRole;
(function (UserRole) {
    UserRole["superUser"] = "superUser";
    // TODO: ACCESS MANAGEMENT remove below users. after adjusting existing user in DB.
    UserRole["regular"] = "regular";
    UserRole["readOnly"] = "readOnly";
})(UserRole || (exports.UserRole = UserRole = {}));
