import React from 'react';
import './FilterInput.scss';

interface Props {
    value: string;
    field: string;
    onChange(event: React.ChangeEvent<HTMLInputElement>): void;
    handleKeyboardEvent?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

function FilterInput({ onChange, handleKeyboardEvent, value, field, ...rest }: Props) {
    return (
        <div className="filter-input-inner-wrapper">
            <input
                {...rest}
                onChange={onChange}
                onKeyDown={handleKeyboardEvent}
                value={value}
                placeholder={`filter by ${field.split('_').join(' ')}`}
            />
            <hr aria-hidden="true" />
        </div>
    );
}

export default FilterInput;
