import { UserRole } from 'buyplan-common';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useIsChannelAdmin from '../../../selectors/useIsChannelAdmin';
import { useSelector } from '../../../store/reducers';

export default function () {
    const role = useSelector(({ user }) => user.profile?.role);
    const isChannelAdmin = useIsChannelAdmin();
    return (
        <ul className="buyplan-navigation__settings-dropdown">
            {(role === UserRole.superUser || isChannelAdmin) && (
                <li>
                    <NavLink to="/buyplan/manage-users">Users</NavLink>
                </li>
            )}
            <li>
                <NavLink to="/buyplan/exchange-rates">Exchange Rates</NavLink>
            </li>
        </ul>
    );
}
