import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { FamilyModelBreakdownReport, FamilyModelBreakdownReportTotals } from 'buyplan-common';
import usePrevious from '../../../helpers/usePrevious';
import ModelDashboard from './ModelDashboard';

interface Props {
    results: { report: FamilyModelBreakdownReport[]; totals: FamilyModelBreakdownReportTotals };
}

function ModelDashboardContainer({ results }: Props) {
    const { report } = results;
    const previousReport = usePrevious(report);

    // Needed to correctly update the table (used as a key)
    const [updateValue, setUpdateValue] = useState(0);

    useEffect(() => {
        if (isEqual(previousReport, report)) {
            setUpdateValue(updateValue + 1);
        }
        // We don't want the exhaustive depts here, updating when updateValue updates would loop infinitely
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report, previousReport]);

    return (
        <div className="ModelDashboard">
            {report && report.length > 0 && (
                <div className="ModelDashboard__container">
                    {report.map((modelData) => (
                        <div key={`${modelData.category}-${modelData.model}`} className="ModelDashboard__dashboard">
                            <ModelDashboard modelData={modelData} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ModelDashboardContainer;
