/**
 * Returns a comparer function that orders the collection in the same order as specified storeNumbers.
 * Unknown store numbers are ordered to the end of the array.
 */
export function sortStores<T extends { number: string }>(storeNumberOrder: string[]) {
    return (a: T, b: T) => {
        const indexA = storeNumberOrder.indexOf(a.number);
        const indexB = storeNumberOrder.indexOf(b.number);
        if (indexB === -1 && indexA > -1) {
            return -1;
        }
        if (indexA === -1 && indexB > -1) {
            return 1;
        }
        if (indexA === indexB) {
            return 0;
        }
        return indexA > indexB ? 1 : -1;
    };
}

/**
 * Returns a shallow copy of stores ordered by specified storeNumberOrder.
 */
export function getSortedStores<T extends { number: string }>(stores: T[], storeNumberOrder: string[] = []) {
    return [...stores].sort(sortStores(storeNumberOrder ?? []));
}
