import { request } from '../api/api';

export const getUploadStatus = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/filestatus`,
    };

    return request(requestOptions);
};
