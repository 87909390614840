/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { shape, func, string, arrayOf, number } from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { channels, formatPercentageOrDash } from 'buyplan-common';
import { getOptionCountsSummary, getOptionCounts, getOptionCountFilterData } from '../../../services/optionCountsService';
import Loader from '../../Loader/Loader';
import PageWarning from '../../PageWarning/PageWarning';
import OptionCountsSummary from './OptionCountsSummary';
import './OptionCounts.scss';

function SelectFilter({ filter, onChange, options }) {
    return (
        <select onChange={(event) => onChange(event.target.value)} value={filter ? filter.value : undefined}>
            <option value="" />
            {options.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.name}
                </option>
            ))}
        </select>
    );
}

SelectFilter.propTypes = {
    filter: shape({ value: string }),
    onChange: func,
    options: arrayOf(string),
};

export const getOptionCountsColumns = ({ navigateToAssortment, optionCountFilterData, totals = {} } = {}) => [
    {
        Header: '',
        accessor: '',
        maxWidth: 30,
        filterable: false,
        Cell: (row) => (
            <button
                type="button"
                className="OptionCounts__link"
                onClick={() => navigateToAssortment(row.original.partner, row.original.division, row.original.category)}
            >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
        ),
    },
    {
        Header: 'Partner',
        accessor: 'partner',
        Footer: <span className="OptionCounts__totals">Total:</span>,
        filterable: true,
        Filter: (props) => <SelectFilter {...props} options={optionCountFilterData.partners} />,
    },
    {
        Header: 'Store',
        accessor: 'store',
        filterable: true,
        Filter: (props) => <SelectFilter {...props} options={optionCountFilterData.storeIds} />,
    },
    {
        Header: 'Division',
        accessor: 'division',
        filterable: true,
        Filter: (props) => <SelectFilter {...props} options={optionCountFilterData.divisions} />,
    },
    {
        Header: 'Category',
        accessor: 'category',
        filterable: true,
        Filter: (props) => <SelectFilter {...props} options={optionCountFilterData.categories} />,
    },
    {
        Header: 'Actual OC',
        accessor: 'actualOptionCount',
        Footer: (
            <span className="OptionCounts__totals">
                {totals.actualOptionCount && totals.actualOptionCount.toLocaleString()}
            </span>
        ),
    },
    {
        Header: 'Target OC',
        accessor: 'targetOptionCount',
        Footer: (
            <span className="OptionCounts__totals">
                {totals.targetOptionCount && totals.targetOptionCount.toLocaleString()}
            </span>
        ),
    },
    {
        Header: 'Variance (%)',
        accessor: 'variancePercentage',
        Cell: (row) => (row.value === null ? <span>-</span> : <span>{row.value}%</span>),
        Footer: <span className="OptionCounts__totals">{formatPercentageOrDash(totals.variancePercentage)}</span>,
    },
    {
        Header: 'Variance (units)',
        accessor: 'varianceUnits',
        Footer: (
            <span className="OptionCounts__totals">{totals.varianceUnits && totals.varianceUnits.toLocaleString()}</span>
        ),
    },
];

export default class OptionCounts extends Component {
    static propTypes = {
        setActiveMainFilters: func.isRequired,
        history: shape({ push: func }).isRequired,
        channelId: number,
    };

    state = {
        summary: null,
        loadingSummary: true,
        summaryError: null,
        optionCounts: null,
        optionCountsMeta: undefined,
        optionCountFilterData: null,
        loadingOptionCounts: false,
        optionCountsError: null,
        page: 0,
        optionCountFilter: [],
    };

    componentDidMount() {
        this.getSummary();
        this.getOptionCounts();
        this.getOptionCountFilterData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.channelId !== prevProps.channelId) {
            this.getSummary();
            this.getOptionCounts();
            this.getOptionCountFilterData();
        }
    }

    getSummary = async () => {
        try {
            const { data: summary } = await getOptionCountsSummary(this.props.channelId);
            this.setState({ summary, loadingSummary: false });
        } catch (err) {
            this.setState({ loadingSummary: false, summaryError: err.message });
        }
    };

    getOptionCounts = async (nextPage, nextFilters) => {
        const { page: previousPage, optionCountFilter: previousFilters } = this.state;
        const page = nextPage === null || nextPage === undefined ? previousPage : nextPage;
        const optionCountFilter = nextFilters || previousFilters;
        this.setState({ page, optionCountFilter, loadingOptionCounts: true });
        try {
            const { data: optionCounts, meta: optionCountsMeta } = await getOptionCounts(
                this.props.channelId,
                page,
                optionCountFilter.reduce(
                    (finalResult, filter) => ({
                        ...finalResult,
                        [filter.id === 'store' ? 'storeId' : filter.id]: [filter.value],
                    }),
                    {}
                )
            );
            this.setState({ optionCounts, optionCountsMeta, loadingOptionCounts: false });
        } catch (err) {
            this.setState({ loadingOptionCounts: false, optionCountsError: err.message });
        }
    };

    getOptionCountFilterData = async (nextFilters = null) => {
        try {
            let partner = [];
            const partners = this.state.optionCountFilterData ? this.state.optionCountFilterData.partners : undefined;
            if (nextFilters !== null) {
                const selectedPartner = nextFilters.find(({ id }) => id === 'partner');
                partner = selectedPartner !== undefined ? selectedPartner.value : [];
            }
            const { data: optionCountFilterData } = await getOptionCountFilterData(this.props.channelId, partner);
            const initialPartners = partners !== undefined ? partners : optionCountFilterData.partners;
            this.setState({
                optionCountFilterData: {
                    ...optionCountFilterData,
                    partners: initialPartners,
                },
            });
        } catch (err) {
            this.setState({ optionCountsError: err.message });
        }
    };

    navigateToAssortment = (partner, division, category) => {
        this.props.setActiveMainFilters({ partner: [partner], division: [division], category: [category] });
        this.props.history.push('/buyplan/manage-assortment');
    };

    render() {
        const {
            summary,
            loadingSummary,
            summaryError,
            optionCounts,
            optionCountsMeta,
            optionCountFilterData,
            loadingOptionCounts,
            optionCountsError,
        } = this.state;
        if (this.props.channelId === channels.digital.id) {
            return (
                <PageWarning>
                    <h1>Option Count</h1>
                    <h2>Not available</h2>
                    <p>Option count report is not available for Digital channel.</p>
                </PageWarning>
            );
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="OptionCounts">
                            <h1>OPTION COUNTS REPORT</h1>
                            <h2>By Category/Division {loadingSummary && <Loader width={16} />}</h2>
                            <p>Measures per category and division on 65% accuracy for option counts.</p>
                            {summary && <OptionCountsSummary categories={summary} />}
                            {summaryError && (
                                <div className="error">
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {summaryError}
                                </div>
                            )}
                            <h2>By Highest Variance (Units) {loadingOptionCounts && <Loader width={16} />}</h2>
                            {optionCounts && optionCountFilterData && (
                                <ReactTable
                                    manual
                                    showPageJump={false}
                                    page={optionCountsMeta.page}
                                    noDataText="No results found"
                                    data={optionCounts}
                                    pages={optionCountsMeta.totalPages}
                                    sortable={false}
                                    loading={loadingOptionCounts}
                                    showPagination={true}
                                    showPaginationTop={false}
                                    paginationBottom={true}
                                    defaultPageSize={optionCountsMeta.pageSize}
                                    onFilteredChange={(filtered) => {
                                        this.getOptionCountFilterData(filtered);
                                        this.getOptionCounts(0, filtered);
                                    }}
                                    onPageChange={this.getOptionCounts}
                                    columns={getOptionCountsColumns({
                                        navigateToAssortment: this.navigateToAssortment,
                                        optionCountFilterData,
                                        totals: optionCountsMeta.totals,
                                    })}
                                    className="-striped -highlight"
                                    showPageSizeOptions={false}
                                />
                            )}
                            {optionCountsError && (
                                <div className="error">
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> {optionCountsError}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
