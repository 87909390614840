import { ChannelRole } from 'buyplan-common';
import { useSelector } from '../store/reducers';
/**
 * Returns true if the user is an admin for the channel ID that is passed
 * If no channelId is passed, it will check if the user is a channel admin for ANY channel
 */
const useIsChannelAdmin = (channelId?: number): boolean => {
    const { profile } = useSelector(({ user }) => user);
    if (!profile) return false;
    let isChannelAdmin = false;

    if (!channelId) {
        isChannelAdmin = profile?.channels.some(({ channelRole }) => channelRole === ChannelRole.admin);
    } else {
        isChannelAdmin = profile?.channels.some(
            ({ id, channelRole }) => channelRole === ChannelRole.admin && id === channelId
        );
    }
    return isChannelAdmin;
};

export default useIsChannelAdmin;
