import React from 'react';
import { HindsightFileErrors as HindsightFileErrorsType } from 'buyplan-common';
import SourceFileError from './SourceFileError';
import formatValidationError from './formatValidationError';

interface Props {
    errors: HindsightFileErrorsType;
}

function HindsightFileErrors({ errors }: Props) {
    const { validationErrors } = errors;

    return validationErrors ? (
        <SourceFileError
            title="The following rows are invalid:"
            items={validationErrors.map(formatValidationError)}
            itemType="size curve"
        />
    ) : null;
}

export default HindsightFileErrors;
