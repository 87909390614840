import React from 'react';
import TopStylesReport from './TopStylesReport';
import './TopStylesReportPage.scss';

function TopStylesReportPage() {
    return (
        <div className="top-styles-report-page">
            <TopStylesReport />
        </div>
    );
}

export default TopStylesReportPage;
