import React from 'react';
import { BuyPlanAggregatedMaterial, formatPercentage } from 'buyplan-common';
import { calculateMaxSellThrough } from '../../helpers/aggregatedMaterial';
import Dot, { DotType } from '../Dot/Dot';

interface Props {
    aggregatedMaterial: BuyPlanAggregatedMaterial;
}

function ClusterOpenToBuyUnitsDot({ aggregatedMaterial: { materialCode, minimumBuyTooLow, rateOfSale, stores } }: Props) {
    if (minimumBuyTooLow > 0) {
        const maxSellThrough = calculateMaxSellThrough(stores);
        const storesEmptyROS = stores.filter((store) => !store.rateOfSale);
        const tooltip = (
            <div>
                Minimum buy missed in{' '}
                <strong>
                    {minimumBuyTooLow} {minimumBuyTooLow > 1 ? 'stores' : 'store'}
                </strong>
                . <br />
                {storesEmptyROS.length === 0 && ( // All stores with a low minimum buy have an ROS different from 0
                    <span>
                        {maxSellThrough === 0 ? (
                            'Adjust the ROS and ST to reach minimums.'
                        ) : (
                            <>
                                Based on ROS of {rateOfSale}, set ST to{' '}
                                <strong>{formatPercentage(maxSellThrough, true)}</strong> to reach minimums.
                            </>
                        )}
                    </span>
                )}
            </div>
        );
        return (
            <Dot data-e2e={`minimum-buy-error-${materialCode}`} type={DotType.error} tooltip={tooltip} placement="right" />
        );
    }

    const hasMissingMinimumBuy = stores.some((store) => store.minimumBuyMaterial === null);

    if (hasMissingMinimumBuy) {
        return (
            <Dot
                data-e2e={`minimum-buy-warning-${materialCode}`}
                type={DotType.warning}
                tooltip="Unable to determine minimum buy for all stores"
                placement="right"
            />
        );
    }
    return <div className="dot" style={{ background: 'none' }} />;
}

export default ClusterOpenToBuyUnitsDot;
