import React, { KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';
import { round } from 'lodash';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import usePrevious from '../../helpers/usePrevious';

export enum ClearBehaviour {
    // Updates the value to `null` immediately upon clearing the input
    SET_NULL = 'SET_NULL',

    // When clearing displays the input as empty, does not call the onChange handler
    // and resets the input to the last known value when focus is lost.
    RESET_LAST_VALUE = 'RESET_LAST_VALUE',
}

interface Props {
    step: number; // Amount to increase/decrease when pressing arrow up or down
    stepUp?: number; // Amount to increase when pressing arrow up (overrides "step" when both are defined)
    stepDown?: number; // Amount to increase when pressing arrow down (overrides "step" when both are defined)
    onChange(value: number | null): void;
    minimum?: number;
    maximum?: number;
    clearBehaviour?: ClearBehaviour;
}

type CombinedProps = Omit<NumberFormatProps, 'step' | 'onChange'> & Props;

// string | number | null are legitimate values. An empty string represents a cleared input.
type ValueType = string | number | null;

/**
 * Provides several enhancements on top of react-number-format.
 */
function EnhancedNumberFormat({
    onChange,
    step,
    stepUp,
    stepDown,
    minimum,
    maximum,
    clearBehaviour = ClearBehaviour.SET_NULL,
    value,
    decimalScale,
    disabled,
    ...props
}: CombinedProps) {
    const [originalValue, setOriginalValue] = useState<ValueType>(value);
    const [componentValue, setComponentValue] = useState<ValueType>(value);
    const previousValue = usePrevious(componentValue);

    // This forces a re-render after the onChange handler is finished which prevents the cursor position from being updated
    // incorrectly.
    useEffect(() => {
        setComponentValue(value);
    }, [value]);

    // react-number-format removes the default up/down key options you'd get normally when using <input type="number" />.
    // This function re-creates that and uses the "step"-value as it's increment/decrement.
    const handlePressKey = useCallback(
        (ev: KeyboardEvent<HTMLInputElement>) => {
            if (!ev.currentTarget) {
                return;
            }

            const floatValue = parseFloat(ev.currentTarget.value);
            if (Number.isNaN(floatValue) || floatValue === undefined) {
                return;
            }

            switch (ev.key) {
                case 'ArrowUp': {
                    let newValue = floatValue + (stepUp ?? step);
                    if (decimalScale) newValue = round(newValue, decimalScale);
                    if (maximum !== undefined) newValue = Math.min(newValue, maximum);
                    onChange(newValue);
                    break;
                }
                case 'ArrowDown': {
                    let newValue = floatValue - (stepDown ?? step);
                    if (decimalScale) newValue = round(newValue, decimalScale);
                    if (minimum !== undefined) newValue = Math.max(newValue, minimum);
                    onChange(newValue);
                    break;
                }
                default:
                    // do nothing
                    break;
            }
        },
        [stepUp, step, decimalScale, maximum, onChange, stepDown, minimum]
    );

    // See https://github.com/s-yadav/react-number-format/issues/264#issuecomment-478446062
    // An empty string represents "empty"
    let changedValue: ValueType = '';

    return (
        <NumberFormat
            {...props}
            onKeyDown={handlePressKey}
            decimalScale={decimalScale}
            autoComplete="off"
            onValueChange={(values: NumberFormatValues) => {
                // Avoid using react-number-format's "isAllowed" and "allowNegative" options:
                // - isAllowed simply blocks certain changes, but with minimum/maximum we'd want to alter that input
                // - allowNegative crudely removes the negation symbol, so if you copy+paste -300 it becomes 300
                const { floatValue } = values;
                if (floatValue === undefined) {
                    switch (clearBehaviour) {
                        case ClearBehaviour.SET_NULL:
                            changedValue = null;
                            break;
                        case ClearBehaviour.RESET_LAST_VALUE:
                            if (componentValue !== '') {
                                changedValue = '';
                                setComponentValue(''); // Update component value to ensure it's displayed as empty
                            }
                            break;
                        default:
                            throw new Error(`Unknown clearBehaviour ${clearBehaviour}, use one of ${ClearBehaviour}`);
                    }
                } else if (minimum !== undefined && floatValue < minimum) {
                    changedValue = minimum;
                } else if (maximum !== undefined && floatValue > maximum) {
                    changedValue = maximum;
                } else {
                    changedValue = floatValue;
                }
            }}
            onChange={() => {
                // onChange triggers when the user actually typed something, onValueChange triggers on every single value
                // change. onValueChange is guaranteed to always run before onChange.
                //
                // However, if the current value is null or undefined it won't trigger the onValueChange-handler since no
                // value should change, but it will trigger onChange because the user typed something. To fix this we check
                // if changedValue !== '' to verify the value is actually changed at all.
                if (changedValue !== '') {
                    onChange(typeof changedValue === 'string' ? Number.parseFloat(changedValue) : changedValue);
                    // Only update the component after we're all done to prevent the caret position from being updated incorrectly.
                    setComponentValue(changedValue);
                }
            }}
            onBlur={(ev) => {
                if (clearBehaviour === ClearBehaviour.RESET_LAST_VALUE && componentValue === '') {
                    setComponentValue(previousValue ?? '');
                }
                if (props.onBlur && originalValue?.toString() !== ev.target.value) props.onBlur(ev);
            }}
            onFocus={(ev) => {
                setOriginalValue(ev.target.value);
            }}
            value={componentValue ?? ''}
            disabled={disabled}
        />
    );
}

export default memo(EnhancedNumberFormat); // Use memo to avoid re-renders updating usePrevious value unnecessarily
