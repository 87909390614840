import { OnProgress, request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { upload } from './uploadFileService';

export const uploadSeasonalHindsightFile = async (file: File, id: string, onProgress: OnProgress) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${id}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `seasonal-hindsight-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasonal-hindsight/files`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
            id,
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
    });
};

export const reuploadSeasonalHindsightFile = async (file: File, id: string, onProgress: OnProgress) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${id}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `seasonal-hindsight-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasonal-hindsight/files/${id}`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
    });
};

export const deleteSeasonalHindsightFile = async (id: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasonal-hindsight/files/${id}`,
    };

    await request(requestOptions);
};
