import React, { ReactNode } from 'react';
import { round } from 'lodash';
import cn from 'classnames';
import Tooltip from '../Tooltip/Tooltip';
import './BuyplanRateOfSaleTooltip.scss';

interface Props {
    salesRetailValueGrowth: number;
    hasLastYearMatch: boolean;
    children: ReactNode;
}

function BuyplanRateOfSaleTooltip({ salesRetailValueGrowth, hasLastYearMatch, children }: Props) {
    let growth = round(salesRetailValueGrowth - 1, 2);
    if (growth === 0) growth = 0; // Fix signed zero

    return (
        <Tooltip
            placement="left"
            tooltip={
                hasLastYearMatch ? (
                    <>
                        Expected growth vs LY:{' '}
                        <span
                            className={cn({
                                'BuyplanRateOfSaleTooltip--positive': growth > 0,
                                'BuyplanRateOfSaleTooltip--negative': growth < 0,
                            })}
                        >
                            {growth > 0 ? '+' : ''}
                            {new Intl.NumberFormat('en-EN', { style: 'percent' }).format(growth)}
                        </span>
                    </>
                ) : (
                    'No last year match found. Check hindsight data and/or like-4-like store.'
                )
            }
        >
            {children}
        </Tooltip>
    );
}

export default BuyplanRateOfSaleTooltip;
