import React, { useEffect } from 'react';
import { CurrencyType } from 'buyplan-common';
import { isEqual } from 'lodash';
import { useSelector } from '../../store/reducers';
import { useRequestFunction } from '../../api/useRequest';
import { getAttributedSalesTargets } from '../../services/salesTargetService';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';
import usePrevious from '../../helpers/usePrevious';
import TargetsTable from './TargetsTable';
import './StoreAttributedSalesTargets.scss';

interface Props {
    loadingCanEditBuyPlan?: boolean;
    errorCanEditBuyPlan?: Error;
}

function AttributedSalesTargets({ loadingCanEditBuyPlan, errorCanEditBuyPlan }: Props) {
    const activeChannelId = useSelector(({ user }) => user.settings.activeChannelId);
    const previousActiveChannelId = usePrevious(activeChannelId);

    // Segment/Category/Division/Consumer-level sales targets
    const [loadingTargets, errorTargets, salesTargets, fetchAttributedSalesTargets] =
        useRequestFunction(getAttributedSalesTargets);

    useEffect(() => {
        const shouldRefresh = !isEqual(previousActiveChannelId, activeChannelId);
        if (shouldRefresh) {
            fetchAttributedSalesTargets();
        }
    }, [activeChannelId, previousActiveChannelId, fetchAttributedSalesTargets]);

    if (loadingTargets || loadingCanEditBuyPlan) {
        return <Loader />;
    }

    const error: Error | undefined = errorTargets || errorCanEditBuyPlan;

    return (
        <div className="AttributedSalesTargets">
            {error ? (
                <ErrorMessage error={error} />
            ) : (
                <TargetsTable
                    targetsType="segment-category-division-consumer"
                    headers={[
                        { key: 'partner', label: 'Partner' },
                        { key: 'segment', label: 'Segment' },
                        { key: 'category', label: 'Category' },
                        { key: 'division', label: 'Division' },
                        { key: 'consumer', label: 'Consumer' },
                        { key: 'target', label: 'Retail sales target' },
                    ]}
                    rows={salesTargets || []}
                    currencyType={CurrencyType.USD}
                />
            )}
        </div>
    );
}

export default AttributedSalesTargets;
