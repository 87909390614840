import React, { ReactNode } from 'react';
import { BuyPlanAggregatedMaterial, BuyplanViewColumnConfig } from 'buyplan-common';
import { identity } from 'lodash';
import { getColumn, generateCellStyle } from '../../helpers/tableTools';
import { hasKey } from '../../helpers/typeGuards';

interface Props {
    columnKey: string;
    data: BuyPlanAggregatedMaterial;
    columns: BuyplanViewColumnConfig[];
    channelId: number;
    children?: ReactNode[];
}

const getTitle = (columnKey: string, data: BuyPlanAggregatedMaterial, hasChildren: boolean | undefined) => {
    if (columnKey === 'stores') return data[columnKey].map((store) => store.storeName).join(', ');
    return !hasChildren && hasKey(data, columnKey) ? data[columnKey] : '';
};

function ViewBuyplanCell({ columns, columnKey, data, channelId, children }: Props) {
    const column = getColumn(columns, columnKey);
    const hasChildren = children && children.some(identity);
    const title = getTitle(columnKey, data, hasChildren);

    const renderedValue =
        column.renderValue &&
        (columnKey === 'buyWholesale' || columnKey === 'buyRetail'
            ? column.renderValue(data, channelId)
            : column.renderValue(data));

    return (
        <div {...generateCellStyle('ViewBuyplanAggregatedMaterial', columnKey, columns)} title={`${title}`}>
            {!hasChildren && (column.renderValue ? renderedValue : title)}
            {children}
        </div>
    );
}

export default ViewBuyplanCell;
