import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ExchangeRatesOverride, ExchangeRatesOverrideUpdate } from 'buyplan-common';
import {
    ExchangeRate,
    ExchangeRateUpdate,
    getExchangeRates,
    updateExchangeRates,
    getIsUpdateAllowedForExchangeRates,
} from '../../services/exchangeRatesService';
import { useRequest, useRequestFunction } from '../../api/useRequest';
import Loader from '../Loader/Loader';
import EnhancedNumberFormat from '../EnhancedNumberFormat/EnhancedNumberFormat';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import { validateExchangeRates } from './helpers';
import './ExchangeRates.scss';

function ExchangeRatesEdit() {
    const [loading, error, exchangeRatesData] = useRequest(getExchangeRates);
    const plannedInRetailYear = get(exchangeRatesData?.exchangeRates, [0, 'plannedInRetailYear'], '');
    const [newExchangeRates, setNewExchangeRates] = useState({});
    const [newExchangeRatesOverrideDigital, setNewExchangeRatesOverrideDigital] = useState({} as ExchangeRatesOverride);
    const [sendExchangeRatesError, setSendExchangeRatesError] = useState();
    const [isUpdateAllowedLoading, isUpdateAllowedError, exchangeRatesStatus, fetchIsUpdateAllowedForExchangeRates] =
        useRequestFunction(getIsUpdateAllowedForExchangeRates);
    const [errors, setErrors] = useState({ exchangeRatesErrors: {}, exchangeRatesOverrideDigitalError: '' });
    const isSuperUser = useIsSuperUser();
    const history = useHistory();

    useEffect(() => {
        if (!plannedInRetailYear) return;
        fetchIsUpdateAllowedForExchangeRates(plannedInRetailYear);
    }, [fetchIsUpdateAllowedForExchangeRates, plannedInRetailYear]);

    useEffect(() => {
        setNewExchangeRates(
            (exchangeRatesData?.exchangeRates || []).reduce(
                (obj: { [key: string]: ExchangeRate }, exchangeRate) => ({
                    ...obj,
                    [exchangeRate.id]: exchangeRate,
                }),
                {}
            )
        );
        setNewExchangeRatesOverrideDigital(exchangeRatesData?.exchangeRatesOverride || ({} as ExchangeRatesOverride));
    }, [exchangeRatesData, plannedInRetailYear]);

    if (!isSuperUser || (exchangeRatesStatus && !exchangeRatesStatus?.isEditingFXRatesAllowed)) {
        return <Redirect to="/buyplan/exchange-rates" />;
    }

    const saveExchangeRates = () => {
        const validationErrors = validateExchangeRates(newExchangeRates, newExchangeRatesOverrideDigital.fxRateAdjustment);
        setErrors(validationErrors);
        if (Object.keys(validationErrors.exchangeRatesErrors).length || !!validationErrors.exchangeRatesOverrideDigitalError)
            return;

        updateExchangeRates(
            (Object.values(newExchangeRates) as ExchangeRate[]).map(
                (exchangeRate: ExchangeRate) =>
                    ({
                        currency: exchangeRate.currency,
                        USDConversionRate: exchangeRate.rate,
                        id: exchangeRate.id,
                    } as unknown as ExchangeRateUpdate)
            ),
            {
                id: newExchangeRatesOverrideDigital.id,
                fxRateAdjustment: newExchangeRatesOverrideDigital.fxRateAdjustment,
                channelId: newExchangeRatesOverrideDigital.channelId,
            } as unknown as ExchangeRatesOverrideUpdate,
            plannedInRetailYear
        )
            .then(() => {
                history.push('/buyplan/exchange-rates');
            })
            .catch((saveError) => {
                setSendExchangeRatesError(saveError);
            });
    };

    return (
        <div className="ExchangeRates">
            <h1>
                Exchange rates for {plannedInRetailYear}
                {(loading || isUpdateAllowedLoading) && <Loader width={16} />}
            </h1>
            <p>
                Here you can update the exchange rates that can be applied to the seasons in the above Planned in Retail Year
                (PRY)
            </p>
            {error && (
                <div className="ExchangeRates__error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}
            {isUpdateAllowedError && (
                <div className="ExchangeRates__error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {isUpdateAllowedError.message}
                </div>
            )}
            {exchangeRatesData && exchangeRatesData.exchangeRates.length > 0 && (
                <Container>
                    <Row className="ExchangeRates__header">
                        <Col>Currency</Col>
                        <Col>Rate</Col>
                        <Col />
                    </Row>
                    {(Object.values(newExchangeRates) as ExchangeRate[]).map((exchangeRate) => (
                        <Row key={exchangeRate.id} className="ExchangeRates__rate">
                            <Col className="ExchangeRates__label">{exchangeRate.currency}</Col>
                            <Col>
                                <EnhancedNumberFormat
                                    step={0.0001}
                                    decimalScale={4}
                                    value={exchangeRate.rate}
                                    onChange={(newRate: number) => {
                                        setNewExchangeRates({
                                            ...newExchangeRates,
                                            [exchangeRate.id]: {
                                                ...exchangeRate,
                                                rate: newRate,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                {(errors.exchangeRatesErrors as { [key: string]: string })[exchangeRate.currency] && (
                                    <span className="ExchangeRates__error">
                                        <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                        {(errors.exchangeRatesErrors as { [key: string]: string })[exchangeRate.currency]}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    ))}
                    <Row className="ExchangeRates__hindsightValueContainer">
                        <Col className="ExchangeRates__label">Hindsight Value Adjustment (Digital)</Col>
                        <Col>
                            <EnhancedNumberFormat
                                step={0.0001}
                                decimalScale={4}
                                value={newExchangeRatesOverrideDigital.fxRateAdjustment}
                                onChange={(newRate: number) => {
                                    setNewExchangeRatesOverrideDigital({
                                        ...newExchangeRatesOverrideDigital,
                                        fxRateAdjustment: newRate,
                                    });
                                }}
                            />
                        </Col>
                        <Col>
                            {errors.exchangeRatesOverrideDigitalError && (
                                <span className="ExchangeRates__error">
                                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                    {errors.exchangeRatesOverrideDigitalError}
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="Button" onClick={saveExchangeRates}>
                                Save
                            </Button>
                        </Col>
                        <Col>
                            {sendExchangeRatesError && (
                                <span className="ExchangeRates__error">
                                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                    {(sendExchangeRatesError as { message: string }).message}
                                </span>
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
}

export default ExchangeRatesEdit;
