"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Size = void 0;
var Size;
(function (Size) {
    Size["XXXS"] = "XXXS";
    Size["XXS"] = "XXS";
    Size["XS"] = "XS";
    Size["S"] = "S";
    Size["M"] = "M";
    Size["L"] = "L";
    Size["XL"] = "XL";
    Size["XXL"] = "XXL";
    Size["XXXL"] = "XXXL";
})(Size || (exports.Size = Size = {}));
