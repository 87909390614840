import { connect } from 'react-redux';
import { getTotalLenghtOfAllArraysWithinObject } from '../../../helpers/utils';
import { getOverviewMetaData, setFilter, clearAllFilters } from '../../../actions/overview';
import { UserState } from '../../../reducers/user';
import { OverviewState, FilterValue } from '../../../reducers/overview';
import FilterGroup from './FilterGroup';

const countActiveFilterAmount = (activeFilters: { [key: string]: FilterValue }) =>
    getTotalLenghtOfAllArraysWithinObject(activeFilters);

const mapDispatchToProps = {
    getOverviewMetaData,
    setFilter,
    clearAllFilters,
};

const mapStateToProps = ({ overview, user }: { overview: OverviewState; user: UserState }) => ({
    activeFilterAmount: countActiveFilterAmount(overview.activeFilters),
    loadingStatusMetaData: overview.loadingStatus.metaData,
    activeFilters: overview.activeFilters,
    filters: overview.metaData,
    rows: overview.rows,
    accessToken: user.accessToken,
    channelId: user.settings.activeChannelId,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterGroup);
