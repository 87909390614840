import React from 'react';

export default function () {
    return (
        <div>
            <h2>Calculation of Weeks and Flows</h2>
            <h3>Start Week</h3>
            <p>
                The start week for a given material is calculated based on the following factors:
                <ul>
                    <li>
                        If a <strong>Membership Launch date</strong>, or a <strong>Launch date</strong> is provided in the
                        Assortment file, the start date will be calculated based on the number of weeks following the season
                        start date until that date is reached. If the launch date falls before the season start date (up to 2
                        weeks before is permitted), the start week will be 1.
                    </li>
                    <li>
                        If neither of the above are provided, the start date will be calculated based on the assigned flow.
                        Digital will reference <strong>Digital Flow</strong> and stores will reference the{' '}
                        <strong>Stores Flow</strong>
                        <ul>
                            <li>Flow1_FlowX - Start week will be 1</li>
                            <li>Flow2_FlowX - Start week will be 5</li>
                            <li>Flow3_FlowX - Start week will be 9</li>
                        </ul>
                    </li>
                </ul>
            </p>
            <h3>End Week</h3>
            <p>
                The end week for a given material is calculated based on assigned flow. Digital will reference{' '}
                <strong>Digital Flow</strong> and stores will reference the <strong>Stores Flow</strong>
                <ul>
                    <li>FlowX_Flow1 - End week will be 4</li>
                    <li>FlowX_Flow2 - End week will be 8</li>
                    <li>FlowX_Flow3 - End week will be 13</li>
                </ul>
            </p>
        </div>
    );
}
