import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import qs from 'qs';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MainFiltersExtended, SilhouetteBreakdownReport, SilhouetteBreakdownReportTotals } from 'buyplan-common';
import { useSelector } from '../../../store/reducers';
import { getBuyPlanMetaData } from '../../../actions/buyplan';
import MainFilters from '../../Filters/MainFilters/MainFilters';
import usePrevious from '../../../helpers/usePrevious';
import useActiveFilters from '../../../selectors/useActiveFilters';
import useMetaData from '../../../selectors/useMetaData';
import Loader from '../../Loader/Loader';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Button from '../../Button/Button';
import { getSilhouetteBreakdownReport } from '../../../services/buyPlanReportsService';
import { combineWords } from '../../../helpers/language';
import { useRequestFunction } from '../../../api/useRequest';
import { PageView } from '../../../constants/appConfig';
import './SilhouetteReport.scss';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { getMissingMainFilterKeys } from '../../../helpers/utils';
import { shouldUpdateBuyPlanMeta } from '../../../helpers/filters';
import SilhouetteReportTable from './SilhouetteReportTable';

// Manage a singleton abort controller outside component; we don't want it to cause a re-render
let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

interface ReportProps {
    missingFiltersKeys: string[];
    error?: Error;
    loading: boolean;
    reportData?: { report: SilhouetteBreakdownReport[]; totals: SilhouetteBreakdownReportTotals };
    filtersHaveChanged: boolean;
    channelId: number;
    mainFilters: MainFiltersExtended;
    activeSeasonId: number;
    accessToken?: string;
    enableDownload?: boolean;
}

const renderReport = ({
    missingFiltersKeys,
    error,
    loading,
    reportData,
    filtersHaveChanged,
    mainFilters,
    channelId,
    activeSeasonId,
    accessToken,
    enableDownload,
}: ReportProps) => {
    if (missingFiltersKeys.length > 0) {
        return (
            <div className="SilhouetteReport--no-filters">Please select a filter for {combineWords(missingFiltersKeys)}</div>
        );
    }
    if (error) {
        return (
            <div className="SilhouetteReport--no-data">
                <ErrorMessage error={error} />
            </div>
        );
    }
    if (loading) return <Loader />;
    if (!reportData || !reportData.report.length) {
        if (!reportData) return <div className="SilhouetteReport--no-data">Click to load report data.</div>;
        return <div className="SilhouetteReport--no-data">No report data found, try changing filters.</div>;
    }
    if (filtersHaveChanged) {
        return <div className="SilhouetteReport--no-data">Click to load report data.</div>;
    }
    return (
        <>
            <div className="SilhouetteReport--downloadButton-container">
                <a
                    href={`${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/silhouette-report-export/?${qs.stringify({
                        filter: mainFilters,
                        activeSeason: activeSeasonId,
                        channelId,
                        Authorization: `Bearer ${accessToken}`,
                    })}`}
                    className={cn('Button', 'SilhouetteReport__download-button', {
                        'SilhouetteReport__download-button--disabled': !enableDownload,
                    })}
                    download
                >
                    <FontAwesomeIcon
                        size="lg"
                        className="SilhouetteReport__download-button-icon"
                        icon={faDownload as IconProp}
                    />
                    Download report in Excel
                </a>
            </div>
            <SilhouetteReportTable results={reportData} channelId={channelId} />
        </>
    );
};

function SilhouetteReport() {
    const dispatch = useDispatch();
    const { activeChannelId: channelId, activeSeasonId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.silhouetteReport);
    const { metaData, isLoading } = useMetaData(PageView.silhouetteReport);
    const { accessToken } = useSelector(({ user }) => user);
    const { partner, division, category } = mainFilters;
    const previousPartner = usePrevious(partner);
    const previousCategory = usePrevious(category);
    const previousDivision = usePrevious(division);
    const previousMainFilters = usePrevious(mainFilters);
    const previousChannelId = usePrevious(channelId);
    const [isButtonPristine, setIsButtonPristine] = useState(true);
    const missingFiltersKeys = getMissingMainFilterKeys(
        mainFilters,
        reportsFiltersConfig[PageView.silhouetteReport].requiredFilters
    );

    const [loading, error, reportData, fetchSilhouetteReport] = useRequestFunction(getSilhouetteBreakdownReport);

    useEffect(() => {
        dispatch(
            getBuyPlanMetaData(
                channelId,
                false,
                reportsFiltersConfig[PageView.silhouetteReport].allFilters,
                PageView.silhouetteReport
            )
        );
    }, [dispatch, channelId]);

    useEffect(() => {
        // If the required filters (partner / category / division) change, refresh the data in the other filters
        if (!partner || !category || !division) return;
        if (
            shouldUpdateBuyPlanMeta([partner, previousPartner], [category, previousCategory], [division, previousDivision])
        ) {
            dispatch(
                getBuyPlanMetaData(
                    channelId,
                    false,
                    reportsFiltersConfig[PageView.silhouetteReport].allFilters,
                    PageView.silhouetteReport
                )
            );
        }
    }, [
        dispatch,
        channelId,
        category,
        division,
        previousCategory,
        previousDivision,
        previousChannelId,
        partner,
        previousPartner,
    ]);

    // If filters are missing or if there are no results
    const enableDownload = missingFiltersKeys.length === 0 || (!loading && reportData && reportData.report.length > 0);

    useEffect(() => {
        if (missingFiltersKeys.length === 0 && !isEqual(previousMainFilters, mainFilters)) {
            resetAbortController();
            setIsButtonPristine(true);
        }
    }, [mainFilters, previousMainFilters, missingFiltersKeys, channelId, metaData, reportData]);

    const loadReport = () => {
        resetAbortController();
        setIsButtonPristine(false);
        fetchSilhouetteReport(mainFilters, abortController.signal);
    };

    let disabledFilterKeys: string[] = [];

    // Disable supplementary filters if required filters are missing
    if (!partner || !category || !division) {
        disabledFilterKeys = [...reportsFiltersConfig[PageView.silhouetteReport].supplementaryFilters];
    }

    const isMetaLoading = isLoading && isEmpty(metaData);
    return (
        <div className="SilhouetteReport">
            <div className="SilhouetteReport--top-section">
                <h1>SILHOUETTE REPORT</h1>
                {!isMetaLoading && (
                    <div
                        className={cn({
                            'SilhouetteReport--filter-container': !!reportData?.report.length,
                        })}
                    >
                        <MainFilters
                            filters={metaData.mainFilters}
                            activeMainFilters={mainFilters}
                            activeChannelId={channelId}
                            disabledFilterKeys={disabledFilterKeys}
                            view={PageView.silhouetteReport}
                            isFiltersLoading={isLoading}
                        />
                        <Button
                            className="Button SilhouetteReport--load-report-button"
                            disabled={!isButtonPristine || isLoading}
                            onClick={loadReport}
                        >
                            LOAD REPORT
                        </Button>
                    </div>
                )}
            </div>
            {renderReport({
                missingFiltersKeys,
                error,
                loading: isLoading || loading,
                reportData,
                filtersHaveChanged: isButtonPristine,
                channelId,
                mainFilters,
                activeSeasonId,
                accessToken,
                enableDownload,
            })}
        </div>
    );
}

export default SilhouetteReport;
