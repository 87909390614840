import { request, download } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { uploadToStorage } from './uploadFileService';

export const generateFilename = () => `update-quantities-${new Date().getTime()}`;

export const getQuantitiesUpdateExport = (activeFilters) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/quantities`,
        filename: generateFilename(),
        queryParams: { filter: activeFilters },
    };

    return download(requestOptions);
};

export const importQuantities = async (file, id) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${id}-${sanitizedFileName}`;
    const destinationKey = `quantities-files/${uniqueFileName}`;

    await uploadToStorage(file, destinationKey, file.type, file.name.includes('no_upload'));

    return request({
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/quantities`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
        },
    });
};
