import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SourceFileErrors as SourceFileErrorsType } from 'buyplan-common';
import Button from '../Button/Button';
import MerchandiseFileErrors from './MerchandiseFileErrors';
import MinimumBuyFileErrors from './MinimumBuyFileErrors';
import OptionCountsFileErrors from './OptionCountsFileErrors';
import HindsightFileErrors from './HindsightFileErrors';
import FileValidationErrors from './FileValidationErrors';
import './SourceFileErrors.scss';

interface Props {
    errors: SourceFileErrorsType;
    onCancel: () => void;
}

function SourceFileErrors({ errors, onCancel }: Props) {
    const { system } = errors;
    const errorCount = Object.keys(errors).length;
    const title = system ? 'System error' : `Validation error${errorCount > 1 ? 's' : ''}`;

    return (
        <div className="SourceFileErrors">
            <h3>
                <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />
                {` `}
                {title}
            </h3>
            {system && <p>{system}</p>}
            {errors.type === 'MerchandiseValidationError' && <MerchandiseFileErrors errors={errors} />}
            {errors.type === 'MinimumBuyValidationError' && <MinimumBuyFileErrors errors={errors} />}
            {errors.type === 'OptionCountsValidationError' && <OptionCountsFileErrors errors={errors} />}
            {errors.type === 'HindsightValidationError' && <HindsightFileErrors errors={errors} />}
            {errors.type === 'FileValidationError' && <FileValidationErrors errors={errors} />}

            <div className="SourceFileErrors__actions">
                <Button className="SourceFileErrors__cancel" onClick={onCancel} type="button">
                    dismiss
                </Button>
            </div>
        </div>
    );
}

export default SourceFileErrors;
