import React from 'react';
import './TopStylesVariance.scss';
import {
    calculateTotalVariance,
    calculateAggregateTotalVariance,
    calculateAverageSellingPriceVariance,
    TopStylesReportTableData,
} from './calculations';

interface Props {
    report: TopStylesReportTableData;
    previousYear?: TopStylesReportTableData;
    totalRows?: number;
}

interface TotalVarianceProps extends Props {
    rowType: 'unitSales' | 'revenue' | 'sellThrough' | 'investUnits';
}

interface AggregateTotalVarianceProps extends Props {
    rowType: 'unitSales' | 'revenue' | 'investUnits';
}

export function TopStylesTotalVariance({ report, previousYear, rowType, totalRows }: TotalVarianceProps) {
    const variance = previousYear ? calculateTotalVariance(report, previousYear, rowType, totalRows) : 0;

    return <strong className={`topStylesVariance${variance > 0 ? '__positive' : '__negative'}`}>{` ${variance}%`}</strong>;
}

export function TopStylesAggregateTotalVariance({ report, previousYear, rowType, totalRows }: AggregateTotalVarianceProps) {
    const variance = previousYear ? calculateAggregateTotalVariance(report, previousYear, rowType, totalRows) : 0;

    return <strong className={`topStylesVariance${variance > 0 ? '__positive' : '__negative'}`}>{` ${variance}%`}</strong>;
}

export function TopStylesAverageSellingPriceVariance({ report, previousYear, totalRows }: Props) {
    const variance = previousYear ? calculateAverageSellingPriceVariance(report, previousYear, totalRows) : 0;

    return <strong className={`topStylesVariance${variance > 0 ? '__positive' : '__negative'}`}>{` ${variance}%`}</strong>;
}
