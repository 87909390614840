import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './ErrorMessage.scss';

interface Props {
    error?: string | Error;
}

function ErrorMessage({ error }: Props) {
    if (!error) return null;

    return (
        <div className="ErrorMessage">
            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
            <span className="ErrorMessage__body">{typeof error === 'string' ? error : error.message}</span>
        </div>
    );
}

export default ErrorMessage;
