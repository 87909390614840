import { request } from '../api/api';

export const getOverviewItems = (action, signal) => {
    const { pageSize, pageNr, activeFilters } = action;
    const queryParams = { pagesize: pageSize, pagenr: pageNr, filter: activeFilters };
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orderlines`,
        signal,
        queryParams,
    };

    return request(requestOptions);
};

export const getOverviewData = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/overview/getmetadata`,
    };

    return request(requestOptions);
};

export const getOverviewColumns = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/overview/getcolumns`,
    };

    return request(requestOptions);
};

export const getPageCount = (action) => {
    const { pageSize, activeFilters } = action;
    const queryParams = { pagesize: pageSize, filter: activeFilters };

    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orderlines`,
        queryParams,
    };

    return request(requestOptions);
};

export const updateOrderlines = (column, value, activeFilters) => {
    const requestOptions = {
        method: 'PATCH',
        data: [
            {
                op: 'replace',
                path: `/${column}`,
                value,
            },
        ],
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orderlines`,
        queryParams: { filter: activeFilters },
    };

    return request(requestOptions, true);
};

export const deleteByFilter = (activeFilters) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/orderlines`,
        queryParams: { filter: activeFilters },
    };

    return request(requestOptions);
};
