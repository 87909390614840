import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar, VariantType } from 'notistack';
import { useSelector } from '../../store/reducers';
import { closeNotification } from '../../actions/notifications';

let displayed: string[] = [];

function Notifier() {
    const dispatch = useDispatch();
    const notificationState = useSelector((state) => state.notification);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const storeDisplayed = (id: string) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: string) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    // Filter dismissed notifications and display the rest. Also, keep track of displayed notifications.
    useEffect(() => {
        notificationState.notifications.forEach(({ key, message, dismissed = false, notificationType }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                onClose: () => {
                    dispatch(closeNotification(key));
                    removeDisplayed(key);
                },
                variant: notificationType as VariantType,
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notificationState, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
}

export default Notifier;
