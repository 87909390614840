import React, { useEffect, useState } from 'react';
import { BuyplanFiles } from 'buyplan-common';
import { getBuyPlanFiles, updateFilteredMaterials } from '../../services/buyplanService';
import * as merchConfig from '../../constants/merchConfig';
import { bulkUpdateFilteredMaterials } from '../../services/assortmentService';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';
import SellThroughInput from '../SellThroughInput/SellThroughInput';
import {
    MAX_WEEKS_ON_SALE,
    MIN_WEEKS_ON_SALE,
    STEP_WEEKS_ON_SALE,
} from '../BuyplanAggregatedMaterialDetail/BuyPlanWeeksOnSaleInput';
import Modal from '../Modal/Modal';
import Dropdown from '../Dropdown/Dropdown';
import './BulkUpdateModal.scss';
import { ActiveFiltersType } from '../../reducers/user';
import PercentageInput from '../PercentageInput/PercentageInput';
import TextInput from '../TextInput/TextInput';
import { PageView } from '../../constants/appConfig';

interface Props {
    open: boolean;
    onClose: () => void;
    type: string;
    activeFilters: ActiveFiltersType;
    channelId: number;
    orderBy: { columnKey: string; direction: 'ASC' | 'DESC' };
    view: PageView;
    getLatest(page: number, orderColumn?: string, orderDirection?: 'ASC' | 'DESC'): Promise<void>;
    makeTotalsOutOfDate?: () => void;
}

type BulkUpdateOption = {
    label: string;
    value: string;
    explanation?: string;
    note?: string;
    min?: number;
    max?: number;
};

type BulkUpdateConfig = {
    label: string;
    disableMethod?: boolean;
    options: BulkUpdateOption[];
};

export const modalConfig: { [key: string]: BulkUpdateConfig } = {
    marginImpact: {
        label: 'Margin Impact',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    ukSalesPercentage: {
        label: 'UK Sales %',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    rateOfSale: {
        label: 'ROS',
        disableMethod: true,
        options: [
            {
                label: 'adjust by percentage',
                value: 'adjustByPercentage',
                explanation: `"Adjust by percentage" applies the percentage change to the ROS of each store, rounding to the nearest whole number.
                This can be a positive or a negative percentage change.`,
            },
        ],
    },
    weeksOnSale: {
        label: 'WOS',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    sellThrough: {
        label: 'ST%',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
                min: 0.01,
                max: 1,
            },
        ],
    },
    afPercentage: {
        label: 'AF%',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
                min: 0.01,
                max: 1,
                explanation: `AF OTB and AA OTB values are calculated based on AF % and must be whole numbers.
                Thats why when you change AF %, it will be related to AF OTB and AA OTB.
                In the case when units can't be whole numbers, AA OTB will be rounded up and AF OTB will be
                rounded down and based on this units you will have new AF %.`,
            },
        ],
    },
    openToBuyUnits: {
        label: 'Open To Buy Units',
        options: [
            {
                label: 'overwrite total',
                value: 'overwriteTotal',
                explanation: `"Overwrite total" makes the total OTB units of the selection match the input value.
                A percentage change is calculated between the previous and new total, and applied to the OTB units of each store, rounding down.
                The ST% for each store is updated to get to this new OTB units value.
                When a store gets 0 (<0.5) OTB units, the ST% will remain the same and the ROS will be set to 0.
                Buys marked "No Seasonal Buy" or where BOP >= presentation stocks - sales units will be ignored.`,
            },
            {
                label: 'adjust by percentage',
                value: 'adjustByPercentage',
                explanation: `"Adjust by percentage" applies the percentage change to the OTB units of each store, rounding down.
                    This can be a positive or a negative percentage change.
                    The ST% for each store is updated to get to this new OTB units value.
                    When a store has 0 (<0.5) OTB units, the ST% will remain the same and the ROS will be set to 0.
                    Buys marked "No Seasonal Buy" or where BOP >= presentation stocks - sales units will be ignored.`,
            },
            {
                label: 'set to product grade minimum buy',
                value: 'setToProductGradeMinBuy',
                explanation: `"Set to product grade minimum buy" will adjust the ST% to set OTB units based on the product grade minimum buy.
                If the OTB units are higher than the product grade minimum buy, ST% will not be updated.`,
                note: 'This method can only be applied if the Minimum Buy file is uploaded.',
            },
            {
                label: 'set to C grade minimum buy',
                value: 'setToCGradeMinBuy',
                explanation: `"Set to C grade minimum buy" will adjust the ST% to set OTB units based on the C grade minimum buy.
                If the OTB units are higher than the C grade minimum buy, ST% will not be updated.`,
                note: 'This method can only be applied if the Minimum Buy file is uploaded.',
            },
        ],
    },
    vat: {
        label: 'VAT %',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
                min: 0.01,
                max: 1,
            },
        ],
    },
    ukVat: {
        label: 'UK VAT %',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
                min: 0.01,
                max: 1,
            },
        ],
    },
    gender: {
        label: 'GENDER',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    allocated: {
        label: 'ALLOCATED',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    family: {
        label: 'FAMILY',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    model: {
        label: 'MODEL',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
    silhouette: {
        label: 'SILHOUETTE',
        disableMethod: true,
        options: [
            {
                label: 'overwrite',
                value: 'overwrite',
            },
        ],
    },
};

function BulkUpdateModal({
    open,
    onClose,
    type,
    activeFilters,
    channelId,
    orderBy,
    view,
    getLatest,
    makeTotalsOutOfDate,
}: Props) {
    const [valueToUpdate, setValueToUpdate] = useState(0);
    const [textValueToUpdate, setTextValueToUpdate] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [method, setMethod] = useState('');
    const [message, setMessage] = useState<string | undefined>();
    const [selectedOption, setSelectedOption] = useState<BulkUpdateOption | undefined>();
    const [isValid, setIsValid] = useState(false);
    const [files, setFiles] = useState<BuyplanFiles>();

    useEffect(() => {
        setMethod(modalConfig[type]?.options[0].value);
    }, [type]);
    useEffect(() => {
        setSelectedOption(modalConfig[type]?.options.find(({ value }) => value === method));
        setIsValid(false);
    }, [type, method]);

    useEffect(() => {
        if (selectedOption === undefined) {
            setIsValid(false);
        } else if (selectedOption.min !== undefined && selectedOption.min > valueToUpdate) {
            setIsValid(false);
        } else if (selectedOption.max !== undefined && selectedOption.max < valueToUpdate) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }, [selectedOption, valueToUpdate]);
    const handleClose = () => {
        setValueToUpdate(0);
        setTextValueToUpdate('');
        setMethod('');
        setLoading(false);
        onClose();
        setMessage(undefined);
    };
    const onChangeHandler = (inputValue: number) => {
        setValueToUpdate(inputValue);
    };
    const onTextChangeHandler = (inputValue: string) => {
        setTextValueToUpdate(inputValue.toUpperCase());
    };
    const handleBulkSave = async () => {
        const { mainFilters, subFilters } = activeFilters;
        const { columnKey, direction } = orderBy;
        setLoading(true);
        try {
            let correctedValueToUpdate;
            if (method === 'adjustByPercentage') {
                correctedValueToUpdate = valueToUpdate / 100;
            } else if (type === 'family' || type === 'model' || type === 'silhouette') {
                correctedValueToUpdate = textValueToUpdate;
            } else {
                correctedValueToUpdate = valueToUpdate;
            }
            const updateMaterialsMethod =
                view === PageView.assortment ? bulkUpdateFilteredMaterials : updateFilteredMaterials;
            const response = await updateMaterialsMethod(
                { ...mainFilters, ...subFilters },
                type,
                correctedValueToUpdate,
                method
            );

            getLatest(0, columnKey, direction);
            makeTotalsOutOfDate?.();

            if (response.message) {
                setMessage(response.message);
            } else {
                handleClose();
            }
        } catch (e) {
            // handled by global error
            setLoading(false);
        }
    };

    const getSubtitle = (label: string) => {
        if (view === PageView.buyplan) return `Update ${label} on all materials for given filters`;
        return `Update ${label} for all materials for the given filters.
            The change will be applied to all partners and channels.`;
    };

    useEffect(() => {
        async function fetchFiles() {
            const { data } = await getBuyPlanFiles();
            setFiles(data);
        }
        if (type === 'openToBuyUnits') {
            fetchFiles();
        }
    }, [channelId, type]);

    if (message !== undefined) {
        return (
            <Modal
                isOpen={open}
                style={{ content: { width: 485 } }}
                title="Bulk Update"
                subtitle={getSubtitle(modalConfig[type]?.label || '')}
                closeAction={handleClose}
                okAction={handleClose}
                okButtonText="OK"
            >
                <div className="bulkUpdate__message">{message}</div>
            </Modal>
        );
    }
    const isMinimumBuyFileMissing =
        type === 'openToBuyUnits' &&
        (method === 'setToProductGradeMinBuy' || method === 'setToCGradeMinBuy') &&
        (!files?.minimumBuyFiles || (files?.minimumBuyFiles && !files.minimumBuyFiles.length));
    return (
        <Modal
            isOpen={open}
            style={{ content: { width: 485 } }}
            title="Bulk Update"
            subtitle={getSubtitle(modalConfig[type]?.label || '')}
            closeAction={handleClose}
            okAction={handleBulkSave}
            okButtonText="Save"
            cancelAction={handleClose}
            okButtonDisabled={isLoading || !isValid || isMinimumBuyFileMissing}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    if (isValid) {
                        handleBulkSave();
                    }
                }}
            >
                <input type="submit" hidden />
                <div className="bulkUpdate__inputs">
                    <div className="bulkUpdate__valueInput">
                        <div className="bulkUpdate__valueInput--label">Value</div>
                        {type === 'vat' && (
                            <PercentageInput
                                clearBehaviour={ClearBehaviour.SET_NULL}
                                minimum={0}
                                maximum={100}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                            />
                        )}
                        {type === 'ukVat' && (
                            <PercentageInput
                                clearBehaviour={ClearBehaviour.SET_NULL}
                                minimum={0}
                                maximum={100}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                            />
                        )}
                        {type === 'gender' && (
                            <Dropdown
                                id={`bulkUpdate-select-${type}`}
                                options={merchConfig.gender}
                                onChange={onChangeHandler}
                                value={valueToUpdate}
                            />
                        )}
                        {type === 'allocated' && (
                            <Dropdown
                                id={`bulkUpdate-select-${type}`}
                                options={merchConfig.allocated}
                                onChange={onChangeHandler}
                                value={valueToUpdate}
                            />
                        )}
                        {['family', 'model', 'silhouette'].includes(type) && (
                            <TextInput onChange={onTextChangeHandler} value={textValueToUpdate} />
                        )}
                        {type === 'marginImpact' && (
                            <PercentageInput
                                clearBehaviour={ClearBehaviour.SET_NULL}
                                minimum={-100}
                                maximum={100}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                            />
                        )}
                        {['ukSalesPercentage', 'afPercentage'].includes(type) && (
                            <PercentageInput
                                clearBehaviour={ClearBehaviour.SET_NULL}
                                minimum={0}
                                maximum={100}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                            />
                        )}
                        {type === 'weeksOnSale' && (
                            <EnhancedNumberFormat
                                autoFocus
                                name="weeksOnSale"
                                minimum={MIN_WEEKS_ON_SALE}
                                maximum={MAX_WEEKS_ON_SALE}
                                step={STEP_WEEKS_ON_SALE}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                                decimalScale={0}
                            />
                        )}
                        {type === 'sellThrough' && <SellThroughInput value={valueToUpdate} onChange={onChangeHandler} />}
                        {method === 'adjustByPercentage' && (
                            <EnhancedNumberFormat
                                autoFocus
                                name="percent"
                                minimum={-100}
                                maximum={100}
                                step={1}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                                decimalScale={1}
                                suffix="%"
                            />
                        )}
                        {method !== 'adjustByPercentage' && type === 'openToBuyUnits' && (
                            <EnhancedNumberFormat
                                autoFocus
                                name="openToBuyUnits"
                                minimum={1}
                                decimalScale={0}
                                step={1}
                                value={valueToUpdate}
                                onChange={onChangeHandler}
                                disabled={method === 'setToProductGradeMinBuy' || method === 'setToCGradeMinBuy'}
                            />
                        )}
                    </div>
                    <div className="bulkUpdate__methodInput">
                        <Dropdown
                            id={`bulkUpdate-select-${type}`}
                            options={modalConfig[type]?.options || []}
                            onChange={(value) => setMethod(value as string)}
                            value={method}
                            disabled={modalConfig[type]?.disableMethod}
                            label="Method"
                        />
                    </div>
                </div>
                <p className="bulkUpdate__warning">
                    Be careful: everything in the current selection will be overwritten with the new values and there is no
                    undo.
                </p>
                {selectedOption?.explanation && <p className="bulkUpdate__explanation">{selectedOption?.explanation}</p>}
                {selectedOption?.note && <p className="bulkUpdate__note">{selectedOption?.note}</p>}
            </form>
        </Modal>
    );
}

export default BulkUpdateModal;
