import React, { memo, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import {
    MainFiltersExtended as MainFiltersType,
    FilterOptions,
    DropdownOption,
    isStringArray,
    isNumberArray,
} from 'buyplan-common';
import { useDispatch } from 'react-redux';
import usePrevious from '../../../helpers/usePrevious';
import Loader from '../../Loader/Loader';
import './MainFilters.scss';
import { setActiveMainFilters } from '../../../actions/user';
import MultiSelect from '../../Dropdown/MultiSelect';
import { PageView } from '../../../constants/appConfig';
import { shouldClearFilters } from '../../../helpers/filters';

interface Props {
    activeMainFilters: MainFiltersType;
    activeChannelId: number;
    filters?: FilterOptions[];
    disabledFilterKeys?: string[];
    placeholder?: string;
    view: PageView;
    isFiltersLoading?: boolean;
}

const getOptions = (options: string[] | number[] | DropdownOption[]): DropdownOption[] => {
    if (isStringArray(options) || isNumberArray(options)) {
        return (options as (string | number)[]).map((option) => ({
            label: `${option}`,
            value: option,
        }));
    }
    return options;
};

function MainFilters({
    activeMainFilters,
    activeChannelId,
    filters,
    disabledFilterKeys,
    placeholder,
    view,
    isFiltersLoading,
}: Props) {
    const dispatch = useDispatch();
    const [currentMainFilters, setCurrentMainFilters] = useState(activeMainFilters);
    const [currentChannelId, setCurrentChannelId] = useState(activeChannelId);
    const [newMainFilter, setNewMainFilter] = useState({});
    const previousValues = usePrevious(currentMainFilters);
    const previousDisabledFilterKeys = usePrevious(disabledFilterKeys);

    useEffect(() => {
        if (activeChannelId !== currentChannelId && !isEqual(currentMainFilters, activeMainFilters)) {
            setCurrentChannelId(activeChannelId);
            setCurrentMainFilters(activeMainFilters);
            setNewMainFilter({});
        }
    }, [activeChannelId, currentChannelId, activeMainFilters, currentMainFilters]);

    useEffect(() => {
        // if a field becomes disabled, clear any active main filter selections for that field
        if (shouldClearFilters(disabledFilterKeys, previousDisabledFilterKeys, activeMainFilters)) {
            const activeKeys = Object.keys(activeMainFilters);
            const keysToClear = disabledFilterKeys?.filter((disabledKey) => activeKeys.includes(disabledKey));
            const clearedFilters = keysToClear ? keysToClear.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}) : {};

            // clear from local/checked state and Redux state
            setCurrentMainFilters({ ...currentMainFilters, ...clearedFilters });
            dispatch(setActiveMainFilters(clearedFilters, activeChannelId, view));
        }
    }, [
        activeChannelId,
        activeMainFilters,
        currentMainFilters,
        disabledFilterKeys,
        dispatch,
        newMainFilter,
        previousDisabledFilterKeys,
        view,
    ]);

    if (!filters) {
        return <Loader />;
    }

    return (
        <div className="main-filters">
            {filters.map(({ field, label, options = [] }) => {
                const disabled = isFiltersLoading || (disabledFilterKeys && disabledFilterKeys.includes(field));
                return (
                    <div key={field} className="main-filters__filter">
                        {options.length === 1 && (
                            <div className="main-filters__filter-single">
                                <label className="main-filters__filter-single-label">{label}</label>
                                <div className="main-filters__filter-single-option">
                                    {isStringArray(options) || isNumberArray(options) ? options[0] : options[0]?.label}
                                </div>
                            </div>
                        )}
                        {options.length !== 1 && (
                            <MultiSelect
                                label={label}
                                disabled={disabled || !options.length}
                                onChange={(values: string[]) => {
                                    const newFilter = {
                                        [field]: values.length > 0 ? values : undefined,
                                    };
                                    setNewMainFilter(newFilter);
                                    setCurrentMainFilters({ ...currentMainFilters, ...newFilter });
                                }}
                                onClose={() => {
                                    if (currentMainFilters && !isEqual(previousValues, currentMainFilters)) {
                                        dispatch(setActiveMainFilters(newMainFilter, activeChannelId, view));
                                    }
                                }}
                                value={currentMainFilters[field as keyof typeof currentMainFilters] ?? []}
                                options={getOptions(options)}
                                placeholder={placeholder}
                                selectAll={true}
                                id={field}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default memo(MainFilters);
