"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modelDashboardMaterialRowConfig = void 0;
const formatters_1 = require("../helpers/formatters");
exports.modelDashboardMaterialRowConfig = [
    {
        key: 'materialCode',
        label: 'Material Code',
    },
    {
        key: 'description',
        label: 'Description',
    },
    {
        key: 'storeCount',
        label: 'Stores',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'revenue',
        label: 'Revenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
    },
    {
        key: 'unitSales',
        label: 'Sales Units',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'presentationStocks',
        label: 'Presentation Stocks',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'openToBuyUnits',
        label: 'OTB Units',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'investUnits',
        label: 'Invest Units',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'weeksOnSale',
        label: 'WOS',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'rateOfSale',
        label: 'ROS',
        getValue: (value) => {
            if (value) {
                return Math.round(value * 100) / 100;
            }
            return 0;
        },
    },
    {
        key: 'sellThrough',
        label: 'ST',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
    },
    {
        key: 'aaAllocatedUnits',
        label: 'AA Allocated Units',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
    {
        key: 'afQSAllocatedUnits',
        label: 'AFQS Allocated Units',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
    },
];
