"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportFilters = void 0;
var ReportFilters;
(function (ReportFilters) {
    ReportFilters["partner"] = "partner";
    ReportFilters["category"] = "category";
    ReportFilters["division"] = "division";
    ReportFilters["storeId"] = "storeId";
    ReportFilters["typeOfOrder"] = "typeOfOrder";
    ReportFilters["gender"] = "gender";
    ReportFilters["age"] = "age";
    ReportFilters["segment"] = "segment";
    ReportFilters["silhouette"] = "silhouette";
    ReportFilters["family"] = "family";
    ReportFilters["model"] = "model";
    ReportFilters["launchFlag"] = "launchFlag";
})(ReportFilters || (exports.ReportFilters = ReportFilters = {}));
