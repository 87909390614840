import {
    BuyPlanAggregatedMaterial,
    FilteringMetaData,
    canUpdateDigitalBuyplan,
    BuyPlanStore,
    PartnerMaterialReviewedStatus,
} from 'buyplan-common';
import * as buyplanService from '../services/buyplanService';
import * as materialAggregatedMaterialService from '../services/materialAggregatedMaterialService';
import { getActiveFiltersDefaultFromMeta } from '../services/filterOptions';
import { PageView } from '../constants/appConfig';
import { setActiveMainFilters } from './user';
import {
    GET_BUYPLAN_AGGREGATED_MATERIAL,
    GET_BUYPLAN_AGGREGATED_MATERIAL_FAILURE,
    GET_BUYPLAN_AGGREGATED_MATERIAL_SUCCESS,
    GET_BUYPLAN_META,
    GET_BUYPLAN_META_FAILURE,
    GET_BUYPLAN_META_SUCCESS,
    SET_BUYPLAN_AGGREGATED_MATERIALS,
    REMOVE_BUYPLAN_AGGREGATED_MATERIAL,
    UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES,
    UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_SUCCESS,
    UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_FAILURE,
    SET_PARTNER_REVIEWED_STATUS,
    SET_PARTNER_REVIEWED_STATUS_SUCCESS,
    SET_PARTNER_REVIEWED_STATUS_FAILURE,
    SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES,
    SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_SUCCESS,
    SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_FAILURE,
} from './actionTypes';
import { AnyDispatch, GetRootState } from './types';

const getBuyPlanAggregatedMaterialFailure = (error: Error) =>
    ({
        type: GET_BUYPLAN_AGGREGATED_MATERIAL_FAILURE,
        error,
    } as const);

export type GetBuyPlanAggregatedMaterialFailureAction = ReturnType<typeof getBuyPlanAggregatedMaterialFailure>;

const getBuyPlanAggregatedMaterialSuccess = (aggregatedMaterial: BuyPlanAggregatedMaterial) =>
    ({
        type: GET_BUYPLAN_AGGREGATED_MATERIAL_SUCCESS,
        aggregatedMaterial,
    } as const);

export type GetBuyPlanAggregatedMaterialSuccessAction = ReturnType<typeof getBuyPlanAggregatedMaterialSuccess>;

export const getBuyPlanAggregatedMaterial =
    (materialId: string) => async (dispatch: AnyDispatch, getState: GetRootState) => {
        try {
            const { activeFilters, activeChannelId } = getState().user.settings;
            const { subFilters, mainFilters } = activeFilters[`${activeChannelId}|${PageView.buyplan}`] ?? {
                mainFilters: {},
                subFilters: {},
            };
            dispatch({ type: GET_BUYPLAN_AGGREGATED_MATERIAL });
            const response = await buyplanService.getBuyPlanAggregatedMaterial(materialId, {
                ...subFilters,
                partner: mainFilters.partner,
            });
            dispatch(getBuyPlanAggregatedMaterialSuccess(response.data));
        } catch (err: unknown) {
            const error = err as Error;
            dispatch(getBuyPlanAggregatedMaterialFailure(error));
        }
    };

export type GetBuyPlanAggregatedMaterialAction = { type: typeof GET_BUYPLAN_AGGREGATED_MATERIAL };

export const getBuyPlanMetaSuccess = (metaData: FilteringMetaData, channelId: number, view: PageView) =>
    ({ type: GET_BUYPLAN_META_SUCCESS, metaData, channelId, view } as const);

export type GetBuyPlanMetaSuccessAction = ReturnType<typeof getBuyPlanMetaSuccess>;

const getBuyPlanMetaFailure = (error: Error, channelId: number, view: PageView) =>
    ({ type: GET_BUYPLAN_META_FAILURE, error, channelId, view } as const);

export type GetBuyPlanMetaFailureAction = ReturnType<typeof getBuyPlanMetaFailure>;

export const getBuyPlanMetaData =
    (
        channelId: number,
        includeSubFilters = true,
        filterTypes = ['partner', 'category', 'division'],
        view: PageView = PageView.buyplan,
        signal?: AbortSignal
    ) =>
    async (dispatch: AnyDispatch, getState: GetRootState) => {
        const { activeFilters } = getState().user.settings;
        const { mainFilters, subFilters } = activeFilters[`${channelId}|${view}`] ?? {
            mainFilters: {},
            subFilters: {},
        };
        try {
            dispatch({ type: GET_BUYPLAN_META, view, channelId });
            const { data: metaData } = await buyplanService.getMetaData(
                subFilters,
                signal,
                filterTypes,
                { ...mainFilters },
                includeSubFilters
            );
            dispatch(getBuyPlanMetaSuccess(metaData, channelId, view));
            dispatch(setActiveMainFilters(getActiveFiltersDefaultFromMeta(mainFilters, metaData), channelId, view));
        } catch (err: unknown) {
            const error = err as Error;
            dispatch(getBuyPlanMetaFailure(error, channelId, view));
        }
    };
export type GetBuyPlanMetaAction = { type: typeof GET_BUYPLAN_META; channelId: number; view: PageView };

export const setBuyPlanAggregatedMaterials = (aggregatedMaterials: BuyPlanAggregatedMaterial[], page: number) =>
    ({
        type: SET_BUYPLAN_AGGREGATED_MATERIALS,
        aggregatedMaterials,
        page,
    } as const);

export type SetBuyPlanAggregatedMaterialsAction = ReturnType<typeof setBuyPlanAggregatedMaterials>;

export const removeBuyPlanAggregatedMaterial = (aggregatedMaterialId: string) =>
    ({
        type: REMOVE_BUYPLAN_AGGREGATED_MATERIAL,
        aggregatedMaterialId,
    } as const);

export type RemoveBuyPlanAggregatedMaterialAction = ReturnType<typeof removeBuyPlanAggregatedMaterial>;

const setBuyPlanPartnerReviewedStatusSuccess = (
    materialId: string,
    partnerIds: string[],
    reviewedStatus: PartnerMaterialReviewedStatus
) =>
    ({
        type: SET_PARTNER_REVIEWED_STATUS_SUCCESS,
        materialId,
        partnerIds,
        reviewedStatus,
    } as const);

export type SetBuyPlanPartnerReviewedStatusSuccessAction = ReturnType<typeof setBuyPlanPartnerReviewedStatusSuccess>;

const setBuyPlanPartnerReviewedStatusFailure = (
    error: Error,
    materialId: string,
    partnerIds: string[],
    reviewedStatus: PartnerMaterialReviewedStatus
) =>
    ({
        type: SET_PARTNER_REVIEWED_STATUS_FAILURE,
        error,
        materialId,
        partnerIds,
        reviewedStatus,
    } as const);

export type SetBuyPlanPartnerReviewedStatusFailureAction = ReturnType<typeof setBuyPlanPartnerReviewedStatusFailure>;

export const setBuyPlanPartnerReviewedStatus =
    (materialId: string, partnerIds: string[], reviewedStatus: PartnerMaterialReviewedStatus) =>
    async (dispatch: AnyDispatch) => {
        try {
            dispatch({ type: SET_PARTNER_REVIEWED_STATUS, materialId, partnerIds, reviewedStatus });
            await Promise.all(
                partnerIds.map((partnerId) =>
                    materialAggregatedMaterialService.setMaterialReviewedStatus(materialId, partnerId, reviewedStatus)
                )
            );

            dispatch(setBuyPlanPartnerReviewedStatusSuccess(materialId, partnerIds, reviewedStatus));
        } catch (err: unknown) {
            const error = err as Error;
            dispatch(setBuyPlanPartnerReviewedStatusFailure(error, materialId, partnerIds, reviewedStatus));
        }
    };

export type SetBuyPlanPartnerReviewedStatusAction = {
    type: typeof SET_PARTNER_REVIEWED_STATUS;
    materialId: string;
    partnerIds: string[];
    reviewedStatus: PartnerMaterialReviewedStatus;
};

const setBuyPlanAggregatedMaterialNotesSuccess = (aggregatedMaterialId: string, notes: string) =>
    ({
        type: SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_SUCCESS,
        aggregatedMaterialId,
        notes,
    } as const);

export type SetBuyPlanAggregatedMaterialNotesSuccessAction = ReturnType<typeof setBuyPlanAggregatedMaterialNotesSuccess>;

const setBuyPlanAggregatedMaterialNotesFailure = (error: Error, aggregatedMaterialId: string, notes: string) =>
    ({
        type: SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_FAILURE,
        error,
        aggregatedMaterialId,
        notes,
    } as const);

export type SetBuyPlanAggregatedMaterialNotesFailureAction = ReturnType<typeof setBuyPlanAggregatedMaterialNotesFailure>;

export const setBuyPlanAggregatedMaterialNotes =
    (aggregatedMaterialId: string, materialId: string, notes: string) => async (dispatch: AnyDispatch) => {
        try {
            dispatch({ type: SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES, aggregatedMaterialId, notes });
            await materialAggregatedMaterialService.setAggregatedMaterialNotes(aggregatedMaterialId, materialId, notes);

            dispatch(setBuyPlanAggregatedMaterialNotesSuccess(aggregatedMaterialId, notes));
        } catch (err: unknown) {
            const error = err as Error;
            dispatch(setBuyPlanAggregatedMaterialNotesFailure(error, aggregatedMaterialId, notes));
        }
    };

export type SetBuyPlanAggregatedMaterialNotesAction = {
    type: typeof SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES;
    aggregatedMaterialId: string;
    notes: string;
};

export type UpdateBuyPlanAggregatedMaterialStoresAction = {
    type: typeof UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES;
    channelId: number;
    view: PageView;
};

const updateBuyPlanAggregatedMaterialStoresSuccess = (
    aggregatedMaterial: BuyPlanAggregatedMaterial,
    channelId: number,
    view: PageView
) =>
    ({
        type: UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_SUCCESS,
        aggregatedMaterial,
        channelId,
        view,
    } as const);

const updateBuyPlanAggregatedMaterialStoresFailure = (error: Error) =>
    ({
        type: UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_FAILURE,
        error,
    } as const);

export type UpdateBuyPlanAggregatedMaterialStoresSuccessAction = ReturnType<
    typeof updateBuyPlanAggregatedMaterialStoresSuccess
>;

export type UpdateBuyPlanAggregatedMaterialStoresFailureAction = ReturnType<
    typeof updateBuyPlanAggregatedMaterialStoresFailure
>;

/*
 - updateReviewedStatus defaulted to false cause updating of reviewed status to be 'manually updated'
   should be triggered only for ROS change for the stores.
 */
export const updateBuyPlanAggregatedMaterialStores =
    (aggregatedMaterial: BuyPlanAggregatedMaterial, channelId: number, updateReviewedStatus = false) =>
    async (dispatch: AnyDispatch, getState: GetRootState) => {
        try {
            const { activeFilters, activeChannelId } = getState().user.settings;
            const { subFilters, mainFilters } = activeFilters[`${activeChannelId}|${PageView.buyplan}`] ?? {
                mainFilters: {},
                subFilters: {},
            };

            const { typeOfOrder } = aggregatedMaterial;
            const storesToUpdate = aggregatedMaterial.stores
                // should not update for Digital IPP stores with material with Type of order NBY.
                .filter(({ storeNumber }: BuyPlanStore) => canUpdateDigitalBuyplan(channelId, storeNumber, typeOfOrder))
                .map(
                    ({
                        storeNumber,
                        rateOfSale,
                        weeksOnSale,
                        sellThrough,
                        afPercentage,
                        beginningOfPeriodUnits,
                        noSeasonalBuy,
                        marginImpact,
                        ukSalesPercentage,
                    }: BuyPlanStore) => ({
                        materialId: aggregatedMaterial.materialId,
                        materialCode: aggregatedMaterial.materialCode,
                        beginningOfPeriodUnits,
                        storeNumber,
                        rateOfSale,
                        weeksOnSale,
                        sellThrough,
                        afPercentage,
                        noSeasonalBuy,
                        marginImpact,
                        ukSalesPercentage,
                    })
                );
            dispatch({
                type: UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES,
                channelId,
                view: PageView.buyplan,
            } as const);

            const response = await buyplanService.updateBuyPlanAggregatedMaterialStores(
                { ...subFilters, partner: mainFilters.partner },
                aggregatedMaterial.id,
                storesToUpdate
            );
            dispatch(updateBuyPlanAggregatedMaterialStoresSuccess(response.data, channelId, PageView.buyplan));

            if (updateReviewedStatus) {
                const partnerIds = [
                    ...new Set(
                        aggregatedMaterial.stores
                            .filter(({ storeNumber }: BuyPlanStore) =>
                                canUpdateDigitalBuyplan(channelId, storeNumber, typeOfOrder)
                            )
                            .map(({ partnerId }: BuyPlanStore) => partnerId)
                    ),
                ] as string[];

                const { materialId } = aggregatedMaterial;

                dispatch(
                    setBuyPlanPartnerReviewedStatus(materialId, partnerIds, PartnerMaterialReviewedStatus.manuallyUpdated)
                );
            }
        } catch (err: unknown) {
            const error = err as Error;
            dispatch(updateBuyPlanAggregatedMaterialStoresFailure(error));
        }
    };

export type BuyPlanActions =
    | GetBuyPlanAggregatedMaterialAction
    | GetBuyPlanAggregatedMaterialFailureAction
    | GetBuyPlanAggregatedMaterialSuccessAction
    | GetBuyPlanMetaAction
    | GetBuyPlanMetaSuccessAction
    | GetBuyPlanMetaFailureAction
    | SetBuyPlanAggregatedMaterialsAction
    | RemoveBuyPlanAggregatedMaterialAction
    | UpdateBuyPlanAggregatedMaterialStoresAction
    | UpdateBuyPlanAggregatedMaterialStoresSuccessAction
    | UpdateBuyPlanAggregatedMaterialStoresFailureAction
    | SetBuyPlanPartnerReviewedStatusAction
    | SetBuyPlanPartnerReviewedStatusSuccessAction
    | SetBuyPlanPartnerReviewedStatusFailureAction
    | SetBuyPlanAggregatedMaterialNotesAction
    | SetBuyPlanAggregatedMaterialNotesSuccessAction
    | SetBuyPlanAggregatedMaterialNotesFailureAction;
