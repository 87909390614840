/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { func, object, shape, string } from 'prop-types';

export function withQueryParams(WrappedComponent) {
    return class extends PureComponent {
        static propTypes = {
            activeFilters: object,
            setFilter: func,
            history: shape({
                replace: func.isRequired,
            }),
            location: shape({
                search: string,
            }),
        };

        constructor(props) {
            super(props);
            this.setParamsFromState = this.setParamsFromState.bind(this);
        }

        componentDidMount() {
            const { activeFilters } = this.props;

            if (!isEmpty(activeFilters)) {
                this.setParamsFromState();
            } else {
                this.setStateFromParams();
            }
        }

        componentDidUpdate(prevProps) {
            const { activeFilters } = this.props;
            if (!isEqual(prevProps.activeFilters, activeFilters)) {
                this.setParamsFromState();
            }
        }

        setParamsFromState() {
            const {
                activeFilters,
                history: { replace },
            } = this.props;

            const search = qs.stringify(activeFilters);

            replace({
                search,
            });
        }

        setStateFromParams() {
            const {
                location: { search },
                setFilter,
            } = this.props;
            const queryString = search.substr(1);
            const filters = qs.parse(queryString);

            setFilter(filters);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default compose(withRouter, withQueryParams);
