import { useSelector } from '../store/reducers';
import { ChannelSettings } from '../reducers/user';

/**
 * Returns the channel settings for the active channel. If the channel is new returns an empty ChannelSettings object.
 */
const useActiveChannelSettings = (): ChannelSettings => {
    const { activeChannelId, channels } = useSelector(({ user }) => user.settings);
    const channelSettings = channels.find(({ channelId }) => channelId === activeChannelId);

    if (channelSettings) {
        return channelSettings;
    }

    return {
        channelId: activeChannelId,
        storeNumberOrder: [],
        favoriteStores: [],
    };
};

export default useActiveChannelSettings;
