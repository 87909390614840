import React, { useState } from 'react';
import cn from 'classnames';
import {
    LastYearMatchReason,
    LastYearData as LastYearDataAPI,
    LastYearReference,
    ChannelRole,
    lastYearColumns,
} from 'buyplan-common';
import { ValueType } from 'react-select';
import { searchHindsight } from '../../services/hindsightService';
import { generateCellStyle } from '../../helpers/tableTools';
import Dot, { DotSize, DotType } from '../Dot/Dot';
import Loader from '../Loader/Loader';
import { renderLastYearStyle, renderPercentage } from '../ViewBuyplan/buyplanHelpers';
import './LastYearData.scss';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import LastYearRefEditingModal from './LYReferenceEditingModal';

interface Props {
    onBuyPlanAggregatedMaterialLastYearReferenceChange?: (materialCode: string | null) => Promise<void>;
    level: 'aggregatedMaterial' | 'store';
    partnerId?: string | null;
    lastYearData: LastYearDataAPI;
    channelId?: number;
}

type OptionType = {
    label: string;
    value: string | null;
};

const generateLastYearCellStyle = (columnKey: string) => generateCellStyle('LastYearData', columnKey, lastYearColumns);

const formatValue = (value?: number | string | null) => value ?? '-';

const formatLastYearMaterialCount = (value?: number | null) => {
    if (value === undefined || value === null) {
        return '-';
    }
    const unit = value === 1 ? 'material' : 'materials';
    return `(${value} ${unit})`;
};

const getMatchReasonExplanation = (
    lastYearMatchReason: LastYearMatchReason | null,
    lastYearMatchReference: LastYearReference | null
): string => {
    if (!lastYearMatchReference || !lastYearMatchReason) {
        return 'No match';
    }
    return {
        directMatch: 'direct match',
        directMatchWithStoreLike4Like: 'direct match (like4like store)',
        style: 'style',
        styleWithStoreLike4Like: 'style (like4like store)',
        like4likeFile: 'like4like file',
        like4likeFileWithStoreLike4Like: 'like4like file (like4like store)',
        like4likeManual: 'like4like manual',
        like4likeManualWithStoreLike4Like: 'like4like manual (like4like store)',
        categoryDivisionFamilyModel: 'category - division - family - model',
        categoryDivisionFamilyModelWithStoreLike4Like: 'category - division - family - model (like4like store)',
        categoryDivisionFamily: 'category - division - family',
        categoryDivisionFamilyWithStoreLike4Like: 'category - division - family (like4like store)',
        categoryDivisionModel: 'category - division - model',
        categoryDivisionModelWithStoreLike4Like: 'category - division - model (like4like store)',
        categoryDivision: 'category - division',
        categoryDivisionWithStoreLike4Like: 'category - division (like4like store)',
    }[lastYearMatchReason];
};

const getDotType = (lastYearMatchReason: LastYearMatchReason | null, lastYearMatchReference: LastYearReference | null) => {
    if (!lastYearMatchReference || !lastYearMatchReason) {
        return DotType.default;
    }
    const successTypeReasons: LastYearMatchReason[] = [
        'directMatch',
        'directMatchWithStoreLike4Like',
        'like4likeFile',
        'like4likeFileWithStoreLike4Like',
        'like4likeManual',
        'like4likeManualWithStoreLike4Like',
    ];
    return successTypeReasons.includes(lastYearMatchReason) ? DotType.success : DotType.default;
};

function LastYearData({ lastYearData, onBuyPlanAggregatedMaterialLastYearReferenceChange, level, partnerId }: Props) {
    const {
        lastYearRateOfSale,
        lastYearWeeksOnSale,
        lastYearSellThrough,
        lastYearMarginPercentage,
        lastYearMatchReference,
        lastYearMatchReason,
        lastYearStoreCount,
        lastYearMaterialCount,
        lastYearTotalUnits,
    } = lastYearData;
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hindsightMaterialCode, setHindsightMaterialCode] = useState<string | undefined>();

    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    const loadOptions = async (inputValue: string) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await searchHindsight(partnerId!, inputValue);
        const options = response.data.map((i) => ({
            value: i.materialCode,
            label: `${i.materialCode}\n${i.description}\n${i.colorDescription}\n`,
        }));
        return [{ label: '- No Reference -', value: null }, ...options];
    };

    const handleInputChange = async (newValue: ValueType<OptionType>) => {
        setHindsightMaterialCode((newValue as OptionType).value as string | undefined);
    };

    const clearReference = () => {
        setHindsightMaterialCode(undefined);
        setIsEditing(false);
    };

    const save = async () => {
        setIsLoading(true);
        if (onBuyPlanAggregatedMaterialLastYearReferenceChange) {
            await onBuyPlanAggregatedMaterialLastYearReferenceChange(hindsightMaterialCode ?? null);
        }
        clearReference();
        setIsLoading(false);
    };

    return (
        <div className="LastYearData">
            <span {...generateLastYearCellStyle('lastYearRateOfSale')}>{formatValue(lastYearRateOfSale)}</span>
            <span {...generateLastYearCellStyle('lastYearWeeksOnSale')}>{formatValue(lastYearWeeksOnSale)}</span>
            <span {...generateLastYearCellStyle('lastYearSellThrough')}>
                {lastYearSellThrough && renderPercentage(lastYearSellThrough)}
            </span>
            <span {...generateLastYearCellStyle('lastYearMarginPercentage')}>
                {lastYearMarginPercentage && renderPercentage(lastYearMarginPercentage)}
            </span>
            <span {...generateLastYearCellStyle('lastYearSalesUnits')}>{formatValue(lastYearTotalUnits)}</span>
            <span {...generateLastYearCellStyle('lastYearStoresMaterials')}>
                {level === 'aggregatedMaterial' && formatValue(lastYearStoreCount)}
                {level === 'store' && formatLastYearMaterialCount(lastYearMaterialCount)}
            </span>
            {level === 'aggregatedMaterial' && (
                <button
                    type="button"
                    className={cn(
                        'LastYearData__cell',
                        'LastYearData__lastYearReferenceType',
                        'LastYearData__lastYearReferenceType--button',
                        {
                            'LastYearData__lastYearReferenceType--button--clickable': !isLoading && !isReadOnlyUser,
                        }
                    )}
                    style={generateLastYearCellStyle('lastYearReferenceType').style}
                    onClick={() => setIsEditing(true)}
                    tabIndex={-1}
                    disabled={isLoading || isReadOnlyUser}
                >
                    {isLoading ? (
                        <Loader width={16} />
                    ) : (
                        <Dot
                            type={getDotType(lastYearMatchReason, lastYearMatchReference)}
                            tooltip={getMatchReasonExplanation(lastYearMatchReason, lastYearMatchReference)}
                            size={DotSize.large}
                        />
                    )}
                </button>
            )}
            {level === 'store' && (
                <span
                    className="LastYearData__cell LastYearData__lastYearReferenceType"
                    style={generateLastYearCellStyle('lastYearReferenceType').style}
                >
                    <Dot
                        type={getDotType(lastYearMatchReason, lastYearMatchReference)}
                        tooltip={getMatchReasonExplanation(lastYearMatchReason, lastYearMatchReference)}
                    />
                </span>
            )}
            <span {...generateLastYearCellStyle('lastYearStyle')}>{renderLastYearStyle(lastYearMatchReference)}</span>
            <span {...generateLastYearCellStyle('lastYearColor')}>
                {formatValue(lastYearMatchReference?.colorDescription)}
            </span>
            {level === 'aggregatedMaterial' && isEditing && (
                <LastYearRefEditingModal
                    lastYearMatchReason={lastYearMatchReason}
                    lastYearMatchReference={lastYearMatchReference}
                    isOpen={isEditing}
                    onCancel={() => setHindsightMaterialCode(undefined)}
                    onConfirm={save}
                    onClose={clearReference}
                    onLoadOptions={(inputValue) => loadOptions(inputValue)}
                    onChangeSelectValue={(value) => handleInputChange(value)}
                />
            )}
        </div>
    );
}

export default LastYearData;
