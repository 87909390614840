"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FxRatesTemplates = exports.DBSnapshotTemplates = void 0;
var DBSnapshotTemplates;
(function (DBSnapshotTemplates) {
    DBSnapshotTemplates["started"] = "DB_SNAPSHOT_STARTED";
    DBSnapshotTemplates["completed"] = "DB_SNAPSHOT_COMPLETED";
    DBSnapshotTemplates["failed"] = "DB_SNAPSHOT_FAILED";
    DBSnapshotTemplates["rollbackStarted"] = "DB_SNAPSHOT_ROLLBACK";
    DBSnapshotTemplates["rollbackCompleted"] = "DB_SNAPSHOT_ROLLBACK_COMPLETED";
    DBSnapshotTemplates["rollbackFailed"] = "DB_SNAPSHOT_ROLLBACK_FAILED";
})(DBSnapshotTemplates || (exports.DBSnapshotTemplates = DBSnapshotTemplates = {}));
var FxRatesTemplates;
(function (FxRatesTemplates) {
    FxRatesTemplates["started"] = "FX_STARTED";
    FxRatesTemplates["completed"] = "FX_COMPLETED";
    FxRatesTemplates["failed"] = "FX_FAILED";
    FxRatesTemplates["rollback"] = "FX_ROLLBACK";
    FxRatesTemplates["hindsight"] = "FX_HINDSIGHT";
    FxRatesTemplates["plan"] = "FX_PLAN";
    FxRatesTemplates["hindsightSeasonError"] = "FX_HINDSIGHT_SEASON_ERROR";
    FxRatesTemplates["hindsightError"] = "FX_HINDSIGHT_ERROR";
    FxRatesTemplates["planSeasonError"] = "FX_PLAN_SEASON_ERROR";
    FxRatesTemplates["planError"] = "FX_PLAN_ERROR";
})(FxRatesTemplates || (exports.FxRatesTemplates = FxRatesTemplates = {}));
