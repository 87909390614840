"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LastYearReferenceReason = void 0;
var LastYearReferenceReason;
(function (LastYearReferenceReason) {
    LastYearReferenceReason["like4likeManual"] = "like4likeManual";
    LastYearReferenceReason["like4likeManualWithStoreLike4Like"] = "like4likeManualWithStoreLike4Like";
    LastYearReferenceReason["directMatch"] = "directMatch";
    LastYearReferenceReason["directMatchWithStoreLike4Like"] = "directMatchWithStoreLike4Like";
    LastYearReferenceReason["like4likeFile"] = "like4likeFile";
    LastYearReferenceReason["like4likeFileWithStoreLike4Like"] = "like4likeFileWithStoreLike4Like";
    LastYearReferenceReason["style"] = "style";
    LastYearReferenceReason["styleWithStoreLike4Like"] = "styleWithStoreLike4Like";
    LastYearReferenceReason["categoryDivisionFamilyModel"] = "categoryDivisionFamilyModel";
    LastYearReferenceReason["categoryDivisionFamilyModelWithStoreLike4Like"] = "categoryDivisionFamilyModelWithStoreLike4Like";
    LastYearReferenceReason["categoryDivisionFamily"] = "categoryDivisionFamily";
    LastYearReferenceReason["categoryDivisionFamilyWithStoreLike4Like"] = "categoryDivisionFamilyWithStoreLike4Like";
    LastYearReferenceReason["categoryDivisionModel"] = "categoryDivisionModel";
    LastYearReferenceReason["categoryDivisionModelWithStoreLike4Like"] = "categoryDivisionModelWithStoreLike4Like";
    LastYearReferenceReason["categoryDivision"] = "categoryDivision";
    LastYearReferenceReason["categoryDivisionWithStoreLike4Like"] = "categoryDivisionWithStoreLike4Like";
})(LastYearReferenceReason || (exports.LastYearReferenceReason = LastYearReferenceReason = {}));
