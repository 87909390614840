import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MancalaImportFile, StatusCode } from 'buyplan-common';
import { getUploadStatus } from '../../services/fileStatusService';
import { processOrderlines } from '../../services/orderService';
import FileStatus from '../FileStatus/FileStatus';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import ImportOrdersDropzone from './ImportOrdersDropzone';

export interface ImportOrdersState {
    loading: boolean;
    loadingFileDrop: boolean;
    fileCount: number;
    historyUploadEnabled: boolean;
    items?: MancalaImportFile[];
    errorMessage?: string;
    uploadStatus?: StatusCode;
}

class ImportOrders extends PureComponent<unknown, ImportOrdersState> {
    state: ImportOrdersState = {
        loading: false,
        loadingFileDrop: false,
        fileCount: 0,
        historyUploadEnabled: true,
    };

    timeoutId: NodeJS.Timeout | undefined;

    timeout: NodeJS.Timeout | undefined = undefined; // Used to clear the timeout on unmount

    componentDidMount() {
        this.setState({ loading: true });
        try {
            this.checkUploadStatus().then(() => {
                this.setState({ loading: false });
            });
        } catch (err: unknown) {
            const error = err as Error;
            this.setState({ errorMessage: error.message, loading: false });
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleDrop = async (acceptedFiles: File[]) => {
        this.setState({ loadingFileDrop: true, uploadStatus: undefined, fileCount: acceptedFiles.length });
        const newItems: MancalaImportFile[] = [];
        try {
            let uploadStatus: StatusCode = 'COMPLETED';
            for (const acceptedFile of acceptedFiles) {
                // Upload the file to S3 and process the orderlines entries
                const response = await processOrderlines(acceptedFile, !this.state.historyUploadEnabled);
                // If file is uploaded as expected it should be added to the list of files in the UI where we can visualize the status
                const result = response?.files.find(({ FileUuid }: MancalaImportFile) => FileUuid === response.newFileUuid);
                if (!result || result.Status === 'FAILED') {
                    uploadStatus = 'FAILED';
                }
                if (result) {
                    newItems.push(result);
                    if (result.Status === 'PROCESSING') {
                        this.checkUploadStatus();
                    }
                }
            }
            this.setState((state) => ({
                loadingFileDrop: false,
                uploadStatus,
                items: [...newItems, ...(state.items || [])],
            }));
        } catch (error) {
            // Let the global error handler handle it
        } finally {
            this.timeout = setTimeout(() => {
                this.setState({
                    loadingFileDrop: false,
                    uploadStatus: undefined,
                });
            }, 3000);
        }
    };

    handleHistoryToggle = () => {
        this.setState((i) => ({ historyUploadEnabled: !i.historyUploadEnabled }));
    };

    checkUploadStatus = async () => {
        const items: MancalaImportFile[] = await getUploadStatus();
        this.setState({ items });
        const processingItems = items?.filter((item) => item.Status === 'PROCESSING');
        // As file processing can take minutes, check if there are any files that are still processing
        if (processingItems && processingItems.length > 0) {
            // If there are, check again in 10 seconds
            this.timeoutId = setTimeout(async () => {
                await this.checkUploadStatus();
            }, 10000);
        } else {
            // Once all files have been processed, clear the timeout
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = undefined;
        }
    };

    render() {
        const { loading, items, errorMessage, loadingFileDrop, uploadStatus, fileCount, historyUploadEnabled } = this.state;
        return (
            <ContentWrapper>
                <Container>
                    <Row>
                        <Col sm={{ span: 12 }}>
                            <h1>IMPORT ORDERS</h1>
                            <ImportOrdersDropzone
                                handleDrop={this.handleDrop}
                                loading={loadingFileDrop}
                                uploadStatus={uploadStatus}
                                fileCount={fileCount}
                                historyUploadEnabled={historyUploadEnabled}
                                handleHistoryToggle={this.handleHistoryToggle}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ span: 12 }}>
                            <FileStatus loading={loading} items={items} errorMessage={errorMessage} />
                        </Col>
                    </Row>
                </Container>
            </ContentWrapper>
        );
    }
}

export default ImportOrders;
