import React from 'react';
import { AssortmentDetails, AssortmentRecord, AssortmentStoreOptionCounts, Material } from 'buyplan-common';
import AssortmentDetailCheckbox from './AssortmentDetailCheckbox';

interface Props {
    clusterId: string;
    assortmentRecords: AssortmentDetails[];
    storeOptionCount: AssortmentStoreOptionCounts;
    material: Material;
    isMaterialLockedForPartnerAssortment: boolean;
    enabledForPartnerAssortment: boolean;
    updateAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    addToAssortment(assortmentRecord: AssortmentRecord, clusterId: string): Promise<void>;
    assortAllLoading: boolean;
    isDisabled: boolean;
}

function AssortmentStore({
    clusterId,
    assortmentRecords,
    storeOptionCount,
    material,
    isMaterialLockedForPartnerAssortment,
    enabledForPartnerAssortment,
    addToAssortment,
    updateAssortment,
    assortAllLoading,
    isDisabled,
}: Props) {
    const record = assortmentRecords.find(
        (rec) =>
            rec.clusterId === clusterId &&
            rec.storeId === storeOptionCount.storeId &&
            rec.materialCode === material.materialCode
    );

    const handleChange = record ? updateAssortment : addToAssortment;

    return (
        <AssortmentDetailCheckbox
            clusterId={clusterId}
            assortAllLoading={assortAllLoading}
            key={`${storeOptionCount.number}_${material.materialCode}`}
            storeOptionCount={storeOptionCount}
            material={material}
            record={record}
            onChange={handleChange}
            isMaterialLockedForPartnerAssortment={isMaterialLockedForPartnerAssortment}
            enabledForPartnerAssortment={enabledForPartnerAssortment}
            isDisabled={isDisabled}
        />
    );
}

export default AssortmentStore;
