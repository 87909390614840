import React, { memo } from 'react';
import { find } from 'lodash';
import { UserProfile, Channel, ChannelMeta, ChannelProfileKeys } from 'buyplan-common';
import Loader from '../Loader/Loader';
import PermissionList from './PermissionList';
import './UserForm.scss';
import './ChannelPermissions.scss';

interface Props {
    currentProfileChannel: Channel;
    user: UserProfile;
    options: ChannelMeta;
    onChannelPermissionChange(key: ChannelProfileKeys, values: string[] | undefined, channelId: number): void;
    isLoading: boolean;
}

function ChannelPermissions({ currentProfileChannel, user, options, onChannelPermissionChange, isLoading }: Props) {
    const { partner, category, division } = options;

    // The data of the channel we display could be the user data or an empty channel if the user has no access yet
    const displayedChannel = find(user.channels, (channel) => channel.id === currentProfileChannel.id);

    // It could be that a user does not have a role assigned, if there was a conflict between NSO/NSP channel roles before migration.
    const channelRole = currentProfileChannel.channelRole
        ? currentProfileChannel.channelRole.toUpperCase()
        : 'USER MUST LOG IN TO VERIFY ROLE';

    return (
        <>
            <p className="UserForm__channelAccess">
                Channel Access: <span>{channelRole}</span>
            </p>
            <div className="ChannelPermissions__permission-lists">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <PermissionList
                            title="Partners"
                            options={partner}
                            active={displayedChannel?.partner}
                            onChange={(newPartners) =>
                                onChannelPermissionChange('partner', newPartners, currentProfileChannel.id)
                            }
                        />
                        <PermissionList
                            title="Categories"
                            options={category}
                            active={displayedChannel?.category}
                            onChange={(newCategories) =>
                                onChannelPermissionChange('category', newCategories, currentProfileChannel.id)
                            }
                        />
                        <PermissionList
                            title="Divisions"
                            options={division}
                            active={displayedChannel?.division}
                            onChange={(newDivisions) =>
                                onChannelPermissionChange('division', newDivisions, currentProfileChannel.id)
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
}

export default memo(ChannelPermissions);
