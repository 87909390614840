import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { channels, getChannelAudience } from 'buyplan-common';
import { useSelector } from '../../store/reducers';
import { setChannel, setSeason } from '../../actions/user';
import Dropdown from '../Dropdown/Dropdown';
import { getSeasonData } from '../../actions/season';

function ChannelSeasonSelection() {
    const dispatch = useDispatch();
    const { profile, settings } = useSelector((state) => state.user);
    const { allSeason } = useSelector((state) => state.season);

    const handleChannelChange = async (newChannelId: number, previousChannelId: number) => {
        // If the channel audience has changed, we need to do a full fetch of the associated season data
        const newChannelAudience = getChannelAudience(newChannelId);
        const prevChannelAudience = getChannelAudience(previousChannelId);
        if (newChannelAudience !== prevChannelAudience) {
            await dispatch(getSeasonData(newChannelId));
        } else {
            dispatch(setChannel(newChannelId));
        }
    };

    useEffect(() => {
        dispatch(getSeasonData());
    }, [dispatch]);

    // The user profile determines which channels the user can access
    if (!profile || !allSeason) {
        return null;
    }

    const channelOptions = Object.values(profile.channels)
        .filter((channel) => profile.channels.find((profileChannel) => profileChannel.id === channel.id))
        // Remove wholesale channel from the dropdown until it is supported
        .filter((channel) => channel.id !== channels.wholesale.id)
        .map((channel) => ({ value: channel.id, label: channel.name }));

    const seasonOptions = allSeason.map((season) => ({ value: season.id, label: season.name }));

    return (
        <>
            <Dropdown
                options={channelOptions}
                disabled={false}
                id="channel-selector"
                data-e2e="channel-selector"
                onChange={(value) => handleChannelChange(value as number, settings.activeChannelId)}
                value={settings.activeChannelId}
                disableUnderline
            />
            <Dropdown
                options={seasonOptions}
                disabled={false}
                id="season-selector"
                data-e2e="season-selector"
                onChange={(value) => {
                    dispatch(setSeason(value as number));
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }}
                value={settings.activeSeasonId}
                disableUnderline
            />
        </>
    );
}

export default ChannelSeasonSelection;
