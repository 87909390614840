import React from 'react';
import MarginImpactInputs from './images/MarginImpactInputs.png';
import MarginImpactCluster from './images/MarginImpactCluster.png';
import MarginImpactBulkUpdate from './images/MarginImpactBulkUpdate.png';

export default function () {
    return (
        <div>
            <h2>Margin Impact</h2>
            <p>
                Channel administrators may set initial values for Margin Impact and UK Sales % at the
                Category/Division/Gender level via the Margin Impact Screen. The values set on this screen will be reflected
                in Buyplan at the cluster level, once Buyplan is active. Note that these initial values must be set
                <strong> before Buyplan is active</strong>. After Buyplan is active, these fields will be read only for
                reference purposes. All changes beyond this point must be done via Buyplan view.
            </p>
            <p>
                <img src={MarginImpactInputs} alt="Margin Impact Input Screen" />
            </p>
            <hr />
            <h3>Cluster Level Overrides</h3>
            <p>
                Once the Buyplan is active, any user with the correct channel access rights can update Margin Impact and UK
                Sales % at the cluster level in the Buyplan screen. This can be done manually using the Margin Impact and UK
                Sales % inputs, or it can be done using bulk update. If users would like to do a bulk update for either
                Margin Impact or UK Sales % they should use the filters to select which clusters to apply changes to, and
                then activate the Bulk Update modal and perform and override.
            </p>
            <h4>Margin Impact and UK Sales % inputs at cluster level:</h4>
            <p>
                <img src={MarginImpactCluster} alt="Margin Impact Inputs at Cluster Level" />
            </p>
            <h4>Bulk Update:</h4>
            <p>
                <img src={MarginImpactBulkUpdate} alt="Margin Impact Bulk Update" />
            </p>
        </div>
    );
}
