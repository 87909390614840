import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ExchangeRatesOverrideInsert, channels } from 'buyplan-common';
import {
    ExchangeRate,
    ExchangeRateInsert,
    getExchangeRates,
    setExchangeRates,
    getIsUpdateAllowedForExchangeRates,
} from '../../services/exchangeRatesService';
import { useRequest, useRequestFunction } from '../../api/useRequest';
import Loader from '../Loader/Loader';
import EnhancedNumberFormat from '../EnhancedNumberFormat/EnhancedNumberFormat';
import useIsSuperUser from '../../selectors/useIsSuperUser';
import { validateExchangeRates } from './helpers';
import './ExchangeRates.scss';

type NewExchangeRate = Pick<ExchangeRate, 'plannedInRetailYear' | 'currency'> & { rate?: number };

function ExchangeRatesAdd() {
    const [loading, error, exchangeRatesData] = useRequest(getExchangeRates);
    const plannedInRetailYear = get(exchangeRatesData?.exchangeRates, [0, 'plannedInRetailYear'], '');
    const nextPlannedInRetailYear = `PRY${Number(plannedInRetailYear?.slice(3)) + 1}`;
    const [newExchangeRates, setNewExchangeRates] = useState({});
    const [newExchangeRatesOverrideDigital, setNewExchangeRatesOverrideDigital] = useState({});
    const [sendExchangeRatesError, setSendExchangeRatesError] = useState();
    const [errors, setErrors] = useState({ exchangeRatesErrors: {}, exchangeRatesOverrideDigitalError: '' });
    const [, isUpdateAllowedError, exchangeRatesStatus, fetchIsUpdateAllowedForExchangeRates] = useRequestFunction(
        getIsUpdateAllowedForExchangeRates
    );
    const isSuperUser = useIsSuperUser();
    const history = useHistory();

    useEffect(() => {
        if (!plannedInRetailYear) return;
        fetchIsUpdateAllowedForExchangeRates(plannedInRetailYear);
    }, [fetchIsUpdateAllowedForExchangeRates, plannedInRetailYear]);

    useEffect(() => {
        setNewExchangeRates(
            (exchangeRatesData?.exchangeRates || []).reduce(
                (
                    obj: {
                        [key: string]: NewExchangeRate;
                    },
                    exchangeRate
                ) => ({
                    ...obj,
                    [exchangeRate.currency]: {
                        currency: exchangeRate.currency,
                        plannedInRetailYear: nextPlannedInRetailYear,
                    },
                }),
                {}
            )
        );
        setNewExchangeRatesOverrideDigital({
            plannedInRetailYear: nextPlannedInRetailYear,
            channelId: exchangeRatesData?.exchangeRatesOverride?.channelId || channels.digital.id,
        });
    }, [exchangeRatesData, nextPlannedInRetailYear]);

    if (!isSuperUser || (exchangeRatesStatus && !exchangeRatesStatus.isAddingFXRatesAllowed)) {
        return <Redirect to="/buyplan/exchange-rates" />;
    }

    const saveExchangeRates = () => {
        const validationErrors = validateExchangeRates(
            newExchangeRates,
            (newExchangeRatesOverrideDigital as ExchangeRatesOverrideInsert)?.fxRateAdjustment
        );
        setErrors(validationErrors);
        if (Object.keys(validationErrors.exchangeRatesErrors).length || !!validationErrors.exchangeRatesOverrideDigitalError)
            return;
        setExchangeRates(
            (Object.values(newExchangeRates) as ExchangeRate[]).map(
                (exchangeRate: ExchangeRate) =>
                    ({
                        currency: exchangeRate.currency,
                        USDConversionRate: exchangeRate.rate,
                        plannedInRetailYear: exchangeRate.plannedInRetailYear,
                    } as unknown as ExchangeRateInsert)
            ),
            {
                plannedInRetailYear: (newExchangeRatesOverrideDigital as ExchangeRatesOverrideInsert).plannedInRetailYear,
                fxRateAdjustment: (newExchangeRatesOverrideDigital as ExchangeRatesOverrideInsert).fxRateAdjustment,
                channelId: (newExchangeRatesOverrideDigital as ExchangeRatesOverrideInsert).channelId,
            }
        )
            .then(() => {
                history.push('/buyplan/exchange-rates');
            })
            .catch((saveError) => {
                setSendExchangeRatesError(saveError);
            });
    };

    return (
        <div className="ExchangeRates">
            <h1>
                Exchange rates for {nextPlannedInRetailYear}
                {loading && <Loader width={16} />}
            </h1>
            <p>Here you can add exchange rates that can be applied to the seasons in the above Planned Retail Year (PRY)</p>
            {error && (
                <div className="ExchangeRates__error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}
            {isUpdateAllowedError && (
                <div className="ExchangeRates__error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {isUpdateAllowedError.message}
                </div>
            )}
            {exchangeRatesData?.exchangeRates && exchangeRatesData.exchangeRates.length && (
                <Container>
                    <Row className="ExchangeRates__header">
                        <Col>Currency</Col>
                        <Col>Rate</Col>
                        <Col />
                    </Row>
                    {(Object.values(newExchangeRates) as NewExchangeRate[]).map((exchangeRate) => (
                        <Row key={exchangeRate.currency} className="ExchangeRates__rate">
                            <Col className="ExchangeRates__label">{exchangeRate.currency}</Col>
                            <Col>
                                <EnhancedNumberFormat
                                    step={0.0001}
                                    decimalScale={4}
                                    value={exchangeRate.rate}
                                    onChange={(newRate: number) => {
                                        setNewExchangeRates({
                                            ...newExchangeRates,
                                            [exchangeRate.currency]: {
                                                ...exchangeRate,
                                                rate: newRate,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                {(errors.exchangeRatesErrors as { [key: string]: string })[exchangeRate.currency] && (
                                    <span className="ExchangeRates__error">
                                        <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                        {(errors.exchangeRatesErrors as { [key: string]: string })[exchangeRate.currency]}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    ))}
                    {exchangeRatesData && exchangeRatesData.exchangeRatesOverride && (
                        <Row className="ExchangeRates__hindsightValueContainer">
                            <Col className="ExchangeRates__label">Hindsight Value Adjustment (Digital)</Col>
                            <Col>
                                <EnhancedNumberFormat
                                    step={0.0001}
                                    decimalScale={4}
                                    value={(newExchangeRatesOverrideDigital as ExchangeRatesOverrideInsert).fxRateAdjustment}
                                    onChange={(newRate: number) => {
                                        setNewExchangeRatesOverrideDigital({
                                            ...newExchangeRatesOverrideDigital,
                                            fxRateAdjustment: newRate,
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                {errors.exchangeRatesOverrideDigitalError && (
                                    <span className="ExchangeRates__error">
                                        <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                        {errors.exchangeRatesOverrideDigitalError}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <Button className="Button" onClick={saveExchangeRates}>
                                Add
                            </Button>
                        </Col>
                        <Col>
                            {sendExchangeRatesError && (
                                <span className="ExchangeRates__error">
                                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{' '}
                                    {(sendExchangeRatesError as { message: string }).message}
                                </span>
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
}

export default ExchangeRatesAdd;
