import { FilteringMetaData, LoadingStatus } from 'buyplan-common';
import { useSelector } from '../store/reducers';
import { PageView } from '../constants/appConfig';

/**
 * Returns metaData for the current channel/view
 */
const useMetaData = (view: PageView): { metaData: FilteringMetaData; isLoading: boolean } => {
    const { settings } = useSelector(({ user }) => user);
    const { metaData } = useSelector(({ buyplan }) => buyplan);
    const { activeChannelId } = settings;

    const { data, loadingStatus } = metaData?.[`${activeChannelId}|${view}`] ?? {
        data: {},
        loadingStatus: LoadingStatus.initial,
    };

    return {
        metaData: data,
        isLoading: !loadingStatus || !!(loadingStatus === LoadingStatus.initial || loadingStatus === LoadingStatus.loading),
    };
};

export default useMetaData;
