"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categoryDivisionRows = exports.categoryDivisionRowsConfig = void 0;
const formatters_1 = require("../helpers/formatters");
const typeOfOrder_1 = require("./typeOfOrder");
/**
 * Returns the rows of the category/division report.
 * - isGroupStart marks the start of a group
 * - hasNegativeValues marks the values have the ability to be negative
 * - getTotalValues is used for the subheaders of the report
 */
exports.categoryDivisionRowsConfig = [
    // Revenue
    {
        key: 'revenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Revenue',
        headerColSpan: 7,
        headerColWidth: 700,
    },
    {
        key: 'lastYearRevenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearRevenue',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'revenueGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'revenueGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'revenueVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
    },
    {
        key: 'revenueVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
    },
    // PRODUCTIVITY
    {
        key: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        groupStartHeader: 'PRODUCTIVITY',
        label: 'TY',
        headerColSpan: 4,
        headerColWidth: 360,
    },
    {
        key: 'productivityGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY VS LY % Δ',
    },
    {
        key: 'lastYearProductivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearProductivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
    },
    // OPTION COUNTS
    {
        key: 'optionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'OPTION COUNTS',
        headerColSpan: 4,
        headerColWidth: 360,
    },
    {
        key: 'optionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'lastYearOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    // % TOTAL REVENUE
    {
        key: 'revenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: '% Total Revenue',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'lastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearRevenuePercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
    },
    // SEASONAL OPTION COUNTS
    {
        key: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Seasonal Option Counts',
        headerColSpan: 4,
        headerColWidth: 400,
    },
    {
        key: 'seasonalOptionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'lastYearSeasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearSeasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    // TRADED OPTION COUNTS
    {
        key: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Traded Option Counts',
        headerColSpan: 4,
        headerColWidth: 400,
    },
    {
        key: 'tradedOptionCountsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'lastYearTradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearTradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    // SALES UNITS
    {
        key: 'salesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Sales Units',
        headerColSpan: 7,
        headerColWidth: 710,
    },
    {
        key: 'lastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearSalesUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'salesUnitsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'salesUnitsGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'salesUnitsVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
    },
    {
        key: 'salesUnitsVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
    },
    // SALES ASP (AVERAGE SELLING PRICE)
    {
        key: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'TY',
        groupStartHeader: 'Sales ASP',
        headerColSpan: 5,
        headerColWidth: 425,
    },
    {
        key: 'lastYearAverageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LY',
    },
    {
        key: 'lastLastYearAverageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LLY',
    },
    {
        key: 'averageSellingPriceGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'averageSellingPriceGrowthPercentageLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    // ST (SELL THROUGH)
    {
        key: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Sell Through %',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'lastYearSellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearSellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
    },
    // BOP
    {
        key: 'beginningOfPeriodUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'BOP',
        headerColSpan: 1,
        headerColWidth: 100,
    },
    // Open To Buy Units
    {
        key: 'openToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'OTB Units',
        headerColSpan: 3,
        headerColWidth: 250,
    },
    {
        key: 'aaOpenToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AA',
    },
    {
        key: 'afOpenToBuyUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AF',
    },
    // BUY RETAIL VALUE $
    {
        key: 'buyRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Buy Retail Value $',
        headerColSpan: 3,
        headerColWidth: 300,
    },
    {
        key: 'aaBuyRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AA',
    },
    {
        key: 'afBuyRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AF',
    },
    // BUY WHOLESALE VALUE $
    {
        key: 'buyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Buy Wholesale Value $',
        headerColSpan: 3,
        headerColWidth: 300,
    },
    {
        key: 'aaBuyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AA',
    },
    {
        key: 'afBuyWholesaleValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AF',
    },
    // Presentation Stocks
    {
        key: 'presentationStocks',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Presentation Stocks',
        headerColSpan: 7,
        headerColWidth: 710,
    },
    {
        key: 'lastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearInvestUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'investUnitToPresentationStockGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
    },
    {
        key: 'investUnitToPresentationStockGrowthLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
    },
    {
        key: 'investUnitsToPresentationStockVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
    },
    {
        key: 'investUnitsToPresentationStockVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
    },
    // AA
    {
        groupStartHeader: typeOfOrder_1.typeOfOrderOptions.alwaysAvailable,
        headerColSpan: 4,
        headerColWidth: 405,
        key: 'aaSalesUnitsGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
    },
    {
        key: 'aaRevenueGrowthPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
    },
    {
        key: 'aaPresentationStocks',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY Presentation Stocks',
    },
    {
        key: 'aaPresentationRetailValueUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'Presentation',
    },
    // Margin USD
    {
        key: 'marginUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Margin $',
        headerColSpan: 5,
        headerColWidth: 500,
    },
    {
        key: 'lastYearMarginUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearMarginUSD',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
    },
    {
        key: 'marginUSDVariance',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
    },
    {
        key: 'marginUSDVarianceLLY',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
    },
    // Margin %
    {
        key: 'marginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Margin %',
        headerColSpan: 3,
        headerColWidth: 300,
    },
    {
        key: 'lastYearMarginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
    },
    {
        key: 'lastLastYearMarginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
    },
];
const categoryDivisionRows = (channelId) => {
    if (!channelId) {
        return exports.categoryDivisionRowsConfig;
    }
    return exports.categoryDivisionRowsConfig.filter((rowConfig) => !rowConfig.applyToChannels || rowConfig.applyToChannels.includes(channelId));
};
exports.categoryDivisionRows = categoryDivisionRows;
