"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hindsightBuySchema = void 0;
exports.hindsightBuySchema = {
    $id: 'https://buyplan.nike.com/hindsightBuySchema.json',
    $schema: 'http://json-schema.org/schema#',
    type: 'object',
    additionalProperties: false,
    properties: {
        storeNumber: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        partner: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        salesUnits: {
            type: 'integer',
        },
        weeksOnSale: {
            type: 'integer',
        },
        rateOfSale: {
            type: 'number',
        },
        sellThrough: {
            type: 'number',
        },
        salesNetRevenue: {
            type: 'number',
        },
        investUnits: {
            type: 'integer',
        },
        hindsightMaterialId: {
            type: 'string',
            format: 'uuid',
        },
        yearType: {
            type: 'integer',
            minimum: 0,
            maximum: 1,
        },
        marginUsd: {
            type: 'number',
        },
        marginPercentage: {
            type: 'number',
        },
    },
    required: [
        'storeNumber',
        'partner',
        'salesUnits',
        'weeksOnSale',
        'rateOfSale',
        'sellThrough',
        'salesNetRevenue',
        'investUnits',
        'hindsightMaterialId',
        'yearType',
        'marginUsd',
        'marginPercentage',
    ],
};
