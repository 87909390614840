import React from 'react';
import lastYearReference from './images/LastYearReferenceBadge.png';
import changeLastYearReference from './images/ChangeLastYearReference.png';
import getPricing from './images/GetPricing.png';
import growthCalculation from './images/GrowthCalculation.png';

export default function () {
    return (
        <div>
            <h2>Clustering</h2>
            <p>A cluster is defined as a group of stores per a partner that will receive a specific product</p>
            <h3>Changing a cluster</h3>
            <p>
                Adding the product to a store&#39;s assortment would automatically result in adding the stores to the
                cluster.
            </p>
            <ul>
                <li>Cluster adjustments will ONLY be available once all store sales targets have been entered.</li>
            </ul>
            <h3>Prices</h3>
            <p>
                For pricing to show up during the planning of your cluster you must first retrieve prices within the home
                screen.
            </p>
            <img src={getPricing} alt="Grab material pricing" />
            <p>
                These prices call out to our MMX api, the same one used for Okuru, and pull as many materials as it can find.
                If a material does not exist in MMX, we will default back to what was entered into the Merchandise file as
                its retail and wholesale price.
            </p>
            <h3 id="rate-of-sale-calculation">Rate of sale calculation &amp; distribution</h3>
            <p>
                The rate of sale is pre-populated for you every season by taking your stores last year ROS closest match +
                estimated growth.
            </p>
            <p>
                The <b>closest match</b> is determined based on a pyramid that is calculated for each material in the buy
                plan.
            </p>
            <h5>Pyramid:</h5>
            <table className="table table-bordered" style={{ width: '40%' }}>
                <tbody>
                    <tr>
                        <th scope="row">Exact Match of Material</th>
                    </tr>
                    <tr>
                        <th scope="row">L4L Material Specified in Merch File</th>
                    </tr>
                    <tr>
                        <th scope="row">Style</th>
                    </tr>
                    <tr>
                        <th scope="row">Family/Model</th>
                    </tr>
                    <tr>
                        <th scope="row">Family</th>
                    </tr>
                    <tr>
                        <th scope="row">Model</th>
                    </tr>
                    <tr>
                        <th scope="row">Category/Division</th>
                    </tr>
                </tbody>
            </table>
            <h5>Growth calculation</h5>
            <p>TY Sales Target / LY Total Sales Revenue</p>
            <p>
                On the store level if you expand your cluster you will see the percent of growth vs. last year as it pertains
                to your ROS calculation.
            </p>
            <img style={{ width: '60%' }} src={growthCalculation} alt="Growth Calculation" />
            <h5>Distribution</h5>
            <p>
                Changing the rate of sale for a cluster results in an update of all store ROS values within that cluster. The
                calculation is as follows:
            </p>
            <ul>
                <li>Take the difference between new cluster ROS and old cluster ROS.</li>
                <li>Distribute that difference evenly amond the stores.</li>
            </ul>
            <h3>Distribution example</h3>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Material</th>
                        <th scope="col"># Stores</th>
                        <th scope="col">ROS</th>
                        <th scope="col">Old ROS</th>
                        <th scope="col">Difference</th>
                        <th>WOS</th>
                        <th>Sales</th>
                    </tr>
                    <tr>
                        <th>ABC123-123</th>
                        <th style={{ textAlign: 'center' }}>3</th>
                        <th>3.00</th>
                        <th>3.34</th>
                        <th>-10%</th>
                        <th style={{ textAlign: 'center' }}>13</th>
                        <th>155</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td />
                        <th scope="row">Utrecht</th>
                        <td>4.49</td>
                        <td>5.00</td>
                        <td />
                        <td>13</td>
                        <td>58</td>
                    </tr>
                    <tr>
                        <td />
                        <th scope="row">Amsterdam</th>
                        <td>5.48</td>
                        <td>6.10</td>
                        <td />
                        <td>13</td>
                        <td>71</td>
                    </tr>

                    <tr>
                        <td />
                        <th scope="row">Antwerp Meir</th>
                        <td>2.02</td>
                        <td>2.25</td>
                        <td />
                        <td>13</td>
                        <td>26</td>
                    </tr>
                </tbody>
            </table>
            <h3>Last year&apos;s data</h3>
            <p>
                Using the data from the <strong>hindsight file</strong>, the RoS, WoS and units from last year will be
                displayed. If the material and/or the store is new, a match will be made on a higher level. How the match was
                made and how many stores that are included in the match are both visible here.
            </p>
            <img src={lastYearReference} alt="Last year data" />
            <p>
                It&apos;s possible to <strong>modify the Last Year match reference</strong>. The modification is only
                possible at a <strong>cluster level</strong> and it will be applied to all the stores of the cluster,
                equally. To change the reference, you can click on the <strong>cluster badge</strong>: the action will open a{' '}
                <strong>modal with a search&amp;select option</strong>, where you can select the new reference. You can also
                decide to reset the reference to its <strong>previous value</strong> by selecting{' '}
                <strong>&quot;- No reference -&quot;</strong> in the select options.
            </p>
            <img src={changeLastYearReference} alt="Modal to change the last year reference" />
        </div>
    );
}
