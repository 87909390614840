import React from 'react';
import { ChannelRole, PartnerMaterialReviewedStatus } from 'buyplan-common';
import Dot, { DotType } from '../Dot/Dot';
import useHasChannelRole from '../../selectors/useHasChannelRole';

interface Props {
    reviewedStatus?: PartnerMaterialReviewedStatus;
    onClick: () => void;
}

function ClusterReviewedStatusDot({ reviewedStatus, onClick }: Props) {
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    let type: DotType;
    switch (reviewedStatus) {
        case PartnerMaterialReviewedStatus.manuallyUpdated:
            type = DotType.success; // Green
            break;
        case PartnerMaterialReviewedStatus.bulkUpdated:
            type = DotType.warning; // Orange
            break;
        default:
            type = DotType.default; // Gray
            break;
    }
    return isReadOnlyUser ? (
        <Dot type={type} tooltip={reviewedStatus} placement="right" />
    ) : (
        <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            className="ViewBuyplanAggregatedMaterial__AggregatedMaterialReviewedStatus__icon"
        >
            <Dot type={type} tooltip={reviewedStatus} placement="right" />
        </div>
    );
}

export default ClusterReviewedStatusDot;
