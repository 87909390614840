import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './AssortmentSettings.scss';
import { assortmentViewColumns } from 'buyplan-common';
import SettingsModal from '../SettingsModal/SettingsModal';
import SettingsDisplayColumns from '../UserSettings/SettingsDisplayColumns';

interface Props {
    onClose(): void;
}

function AssortmentSettings({ onClose }: Props) {
    return (
        <SettingsModal onClose={onClose}>
            <Tabs>
                <TabList>
                    <Tab>
                        <h3>DISPLAY COLUMNS</h3>
                    </Tab>
                </TabList>

                <TabPanel>
                    <SettingsDisplayColumns columns={assortmentViewColumns} columnSetting="visibleAssortmentColumnKeys" />
                </TabPanel>
            </Tabs>
        </SettingsModal>
    );
}

export default AssortmentSettings;
