import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { Overlay, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { MainFilters, AssortmentStoreOptionCounts, channels } from 'buyplan-common';
import { useSelector } from '../../store/reducers';
import './AssortmentStoreHeader.scss';

export interface AssortmetStoreHeaderProps {
    storeOptionCount: AssortmentStoreOptionCounts;
    className?: string;
    activeMainFilters: MainFilters;
}

function AssortmentStoreHeader({
    className,
    activeMainFilters: { division, category },
    storeOptionCount: { actualOptions, targetOptions, name },
}: AssortmetStoreHeaderProps) {
    const { activeChannelId } = useSelector((state) => state.user.settings);

    const [showTooltip, setShowTooltip] = useState(false);
    const headerColumnRef = useRef<HTMLDivElement>(null);

    /**
     * Returns a classname that renders the store option target red, green or black.
     *
     * - Green: the actual option count divided by the target option count is within 35% variance
     *   (either above or below).
     * - Red: the actual option count divided by the target option count is more than 35% variance
     *   (either above or below).
     * - Black: more than one category and/or division has been selected, coloring the target
     *   doesn't make any sense. For example: a store has 2 option count targets of 5 for two
     *   different categories; when one category has been selected in the buyplan 10 times it would
     *   perfectly match the total option count target (10/10) while in fact neither individual
     *   option count target is within 35% variance (10/5 and 0/5), both more than 35% off.
     */
    const optionCountVarianceClass = () => {
        if (
            (division && division.length !== 1) ||
            (category && category.length !== 1) ||
            activeChannelId === channels.digital.id
        ) {
            return '';
        }

        const optionCountVariance = (actualOptions / targetOptions) * 100 - 100;
        return Math.abs(optionCountVariance) > 35 ? 'out-of-variance' : 'within-variance';
    };

    const toolTiptext = `${name} has ${actualOptions} assorted option${actualOptions !== 1 ? 's' : ''} ${
        activeChannelId !== channels.digital.id ? `vs. ${targetOptions} target option${targetOptions !== 1 ? 's' : ''}` : ''
    }`;

    const storeName = activeChannelId !== channels.digital.id ? ` | ${name}` : name;

    return (
        <>
            <div
                ref={headerColumnRef}
                className={cn('assortment-store-header', className)}
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
                onFocus={() => undefined}
                onBlur={() => undefined}
            >
                <span className="assortment-store-header__option-counts">
                    <span className={optionCountVarianceClass()}>{`${actualOptions}${
                        activeChannelId !== channels.digital.id ? `/${targetOptions}` : ' '
                    }`}</span>
                </span>
                {storeName}
            </div>
            <Overlay target={headerColumnRef} show={showTooltip} placement="bottom">
                {(props: OverlayInjectedProps) => (
                    <Tooltip
                        id="assortment-store-header__tooltip" // required
                        {...props}
                        arrowProps={{
                            ref: props.arrowProps && props.arrowProps.ref,
                            style: { display: 'none' },
                        }}
                    >
                        {toolTiptext}
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
}

export default AssortmentStoreHeader;
