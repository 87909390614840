import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Placement } from 'react-bootstrap/Overlay';
import Tooltip, { TooltipType } from '../Tooltip/Tooltip';
import './Dot.scss';

export enum DotType {
    default = 'default',
    info = 'info',
    success = 'success',
    error = 'error',
    warning = 'warning',
}

export enum DotSize {
    default = 'default',
    large = 'large',
}

export interface DotProps {
    tooltip: ReactNode;
    type?: DotType;
    size?: DotSize;
    placement?: Placement;
}

function Dot({ placement, tooltip, size = DotSize.default, type = DotType.default, ...props }: DotProps) {
    let tooltipType;
    switch (type) {
        case DotType.info:
            tooltipType = TooltipType.info;
            break;
        case DotType.error:
            tooltipType = TooltipType.error;
            break;
        case DotType.warning:
            tooltipType = TooltipType.warning;
            break;
        case DotType.success:
            tooltipType = TooltipType.success;
            break;
        default:
            tooltipType = TooltipType.default;
    }

    return (
        <Tooltip tooltip={tooltip} placement={placement} type={tooltipType}>
            <div className={cn('dot', `dot--${type}`, { 'dot--large': size === DotSize.large })} {...props}>
                {' '}
            </div>
        </Tooltip>
    );
}

export default Dot;
