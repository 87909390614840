"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.digitalFlowOptions = exports.storesFlowOptions = void 0;
const materialTypes_1 = require("../types/materialTypes");
exports.storesFlowOptions = {
    flow1flow1: materialTypes_1.FlowRange.Flow1Flow1,
    flow1flow2: materialTypes_1.FlowRange.Flow1Flow2,
    flow1flow3: materialTypes_1.FlowRange.Flow1Flow3,
    flow2flow2: materialTypes_1.FlowRange.Flow2Flow2,
    flow2flow3: materialTypes_1.FlowRange.Flow2Flow3,
    flow3flow3: materialTypes_1.FlowRange.Flow3Flow3,
};
exports.digitalFlowOptions = {
    flow1flow1: materialTypes_1.FlowRange.Flow1Flow1,
    flow1flow2: materialTypes_1.FlowRange.Flow1Flow2,
    flow1flow3: materialTypes_1.FlowRange.Flow1Flow3,
    flow2flow2: materialTypes_1.FlowRange.Flow2Flow2,
    flow2flow3: materialTypes_1.FlowRange.Flow2Flow3,
    flow3flow3: materialTypes_1.FlowRange.Flow3Flow3,
    none: 'NONE',
};
