import { Link } from 'react-router-dom';
import React from 'react';

export default function () {
    return (
        <div>
            <h2>Late adds</h2>
            <p>
                If one or more materials should be added to the assortment after all merchandise files are uploaded, you can
                use the <Link to="/buyplan/manage-assortment/late-adds">Late adds</Link> page
            </p>
            <h3>File format</h3>
            <p>
                Late adds use the same file structure and validation as{' '}
                <Link to="/buyplan/documentation/import-assortment-files">Merchandise files</Link>. After you upload one or
                more files, all materials will be displayed seperately. They can also be deleted one by one.
            </p>
        </div>
    );
}
