"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerMaterialReviewedStatus = void 0;
var PartnerMaterialReviewedStatus;
(function (PartnerMaterialReviewedStatus) {
    PartnerMaterialReviewedStatus["notReviewed"] = "NOT REVIEWED";
    PartnerMaterialReviewedStatus["manuallyUpdated"] = "MANUALLY UPDATED";
    PartnerMaterialReviewedStatus["bulkUpdated"] = "BULK UPDATED";
    PartnerMaterialReviewedStatus["uploadUpdated"] = "UPDATED BY FILE UPLOAD";
})(PartnerMaterialReviewedStatus || (exports.PartnerMaterialReviewedStatus = PartnerMaterialReviewedStatus = {}));
