import { UserProfile } from 'buyplan-common';
import { useSelector } from '../store/reducers';

/**
 * Returns the profile of the active user (if available)
 */
const useUserProfile = (): UserProfile | undefined => {
    const { profile } = useSelector(({ user }) => user);

    return profile;
};

export default useUserProfile;
