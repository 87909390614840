import React from 'react';

export default function () {
    return (
        <div>
            <h2>Type Of Order</h2>
            <p>This report allows you to see the information for each Type of Order at different levels.</p>
            <h3>Summary</h3>
            <p>
                In order to get to this report, <code>CLICK</code> on <strong>Type Of Order</strong> under the{' '}
                <strong>Reports</strong> tab. In this view, you can find one report which provides an overview of the total
                revenue, sales units, sales asp, sell through, presentation stocks, etc. per division for the selected type
                of order. The summary shows the data for this and last year with their difference and the percentage of
                increase/decrease for this year. A percentage of the total revenue can be found in the summary and last last
                year&apos;s data is for obtaining more insights.
            </p>
            <p>
                The data is shown at the category totals level and also at the catgeory level grouped by the type of order.
            </p>
            <p>
                The report can be filtered by Partner, Category, Division, Stores, Gender, Age and Segment. <br />
                <br />
                From the filters, Partner, Store, and Category are mandatory without which you would not be able to see the
                reports. All other fields are optional and the data is filtered as you go on selecting the filter conditions.
            </p>
            <p>
                <em>
                    NOTE:
                    <ul>
                        <li>This report is available for NDS and DIGITAL</li>
                    </ul>
                </em>
            </p>
        </div>
    );
}
