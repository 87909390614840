import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './Warning.scss';

interface WarningProps {
    children?: ReactNode;
}

function Warning({ children }: WarningProps) {
    return (
        <div className="Warning">
            <FontAwesomeIcon size="3x" icon={faExclamationTriangle as IconProp} /> {children}
        </div>
    );
}

export default Warning;
