import React, { useEffect, useMemo, useState } from 'react';
import { isEqual, isEmpty } from 'lodash';
import cn from 'classnames';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CategoryDivisionBreakdownReport, CategoryLevelCategoryDivisionBreakDownReportData } from 'buyplan-common';
import { getBuyPlanMetaData } from '../../../actions/buyplan';
import { syncTableHorizontalScrolls } from '../../../helpers/tableTools';
import { useSelector } from '../../../store/reducers';
import { useRequestFunction } from '../../../api/useRequest';
import useActiveFilters from '../../../selectors/useActiveFilters';
import { getCategoryDivisionReport } from '../../../services/buyPlanReportsService';
import { combineWords } from '../../../helpers/language';
import usePrevious from '../../../helpers/usePrevious';
import useMetaData from '../../../selectors/useMetaData';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
import MainFilters from '../../Filters/MainFilters/MainFilters';
import './CategoryDivisionReport.scss';
import { PageView } from '../../../constants/appConfig';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import { getMissingMainFilterKeys } from '../../../helpers/utils';
import { shouldUpdateBuyPlanMeta } from '../../../helpers/filters';
import CategoryDivisionTable from './CategoryDivisionTable';

let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

interface ReportProps {
    missingFiltersKeys: string[];
    error?: Error;
    loading: boolean;
    categoryDivisionReportTotal: CategoryDivisionBreakdownReport[] | null;
    categoryDivisionReportCategories: CategoryLevelCategoryDivisionBreakDownReportData[] | null;
    filtersHaveChanged: boolean;
    channelId: number;
}

const renderReport = ({
    missingFiltersKeys,
    error,
    loading,
    categoryDivisionReportTotal,
    categoryDivisionReportCategories,
    filtersHaveChanged,
    channelId,
}: ReportProps) => {
    if (missingFiltersKeys.length > 0) {
        return (
            <div className="CategoryDivisionReport--error-message">
                Please select a filter for {combineWords(missingFiltersKeys)}
            </div>
        );
    }
    if (loading) return <Loader />;
    if (error) {
        return (
            <div className="CategoryDivisionReport--error-message">
                <ErrorMessage error={error} />
            </div>
        );
    }
    if (!categoryDivisionReportTotal || !categoryDivisionReportTotal.length) {
        if (!categoryDivisionReportTotal)
            return <div className="CategoryDivisionReport--error-message">Click to load report data.</div>;
        return <div className="CategoryDivisionReport--error-message">No report data found, try changing filters</div>;
    }
    if (filtersHaveChanged) return <div className="CategoryDivisionReport--error-message">Click to load report data.</div>;
    return (
        <div className="CategoryDivisionReport--table-container">
            {categoryDivisionReportCategories && categoryDivisionReportCategories.length > 0 && (
                <div className="CategoryDivisionReport--table-categories">
                    {categoryDivisionReportCategories.map((cat) => (
                        <div key={cat.category} className="CategoryDivisionReport--table-categories--category">
                            <h2>{`${cat.category}`}</h2>
                            <CategoryDivisionTable
                                report={cat.reportData}
                                tableId={cat.category}
                                syncTableHorizontalScrolls={syncTableHorizontalScrolls}
                                channelId={channelId}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

function CategoryDivisionReport() {
    const dispatch = useDispatch();
    const { activeChannelId: channelId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.categoryDivisionReport);
    const { accessToken } = useSelector(({ user }) => user);
    const activeSeasonId = useSelector((state) => state.user.settings.activeSeasonId);
    const { metaData, isLoading } = useMetaData(PageView.categoryDivisionReport);
    const { partner, category } = mainFilters;
    const previousPartner = usePrevious(partner);
    const previousCategory = usePrevious(category);
    const previousChannelId = usePrevious(channelId);
    const [isButtonPristine, setIsButtonPristine] = useState(true);
    const missingFiltersKeys = getMissingMainFilterKeys(
        mainFilters,
        reportsFiltersConfig[PageView.categoryDivisionReport].requiredFilters
    );
    const previousMainFilters = usePrevious(mainFilters);

    const [loading, error, reportData, fetchCategoryDivisionReport] = useRequestFunction(getCategoryDivisionReport);

    useEffect(() => {
        dispatch(
            getBuyPlanMetaData(
                channelId,
                false,
                reportsFiltersConfig[PageView.categoryDivisionReport].allFilters,
                PageView.categoryDivisionReport
            )
        );
    }, [dispatch, channelId]);

    useEffect(() => {
        // If the required filters (partner / category) change, refresh the data in the other filters
        if (!partner || !category) return;
        if (shouldUpdateBuyPlanMeta([partner, previousPartner], [category, previousCategory])) {
            dispatch(
                getBuyPlanMetaData(
                    channelId,
                    false,
                    reportsFiltersConfig[PageView.categoryDivisionReport].allFilters,
                    PageView.categoryDivisionReport
                )
            );
        }
    }, [dispatch, channelId, partner, previousPartner, previousChannelId, category, previousCategory]);

    let disabledFilterKeys: string[] = [];

    // Disable supplementary filters if required filters are missing
    if (!partner || !category) {
        disabledFilterKeys = [...reportsFiltersConfig[PageView.categoryDivisionReport].supplementaryFilters];
    }

    const { categoryDivisionReportTotal, categoryDivisionReportCategories } = useMemo(() => {
        if (!reportData) {
            return { categoryDivisionReportTotal: null, categoryDivisionReportCategories: null };
        }
        return reportData;
    }, [reportData]);

    const loadReport = () => {
        resetAbortController();
        setIsButtonPristine(false);
        fetchCategoryDivisionReport(mainFilters, abortController.signal);
    };

    // If filters are missing or if there are no results
    const enableDownload = missingFiltersKeys.length === 0 || (!loading && !!categoryDivisionReportTotal?.length);
    useEffect(() => {
        if (missingFiltersKeys.length === 0 && !isEqual(previousMainFilters, mainFilters)) {
            resetAbortController();
            setIsButtonPristine(true);
        }
    }, [mainFilters, previousMainFilters, missingFiltersKeys, channelId, metaData, reportData]);

    const isMetaLoading = isLoading && isEmpty(metaData);
    const isLoadButtonDisabled = !isButtonPristine || isLoading || missingFiltersKeys.length > 0;

    return (
        <div className={cn('CategoryDivisionReport', { 'CategoryDivisionReport--loading': isLoading || loading })}>
            <div className="CategoryDivisionReport--top-section">
                <h1>CATEGORY/DIVISION REPORT</h1>
                {!isMetaLoading && (
                    <div
                        className={cn({
                            'CategoryDivisionReport--filter-container':
                                !!categoryDivisionReportTotal?.length && !isButtonPristine,
                        })}
                    >
                        <MainFilters
                            filters={metaData.mainFilters}
                            activeMainFilters={mainFilters}
                            activeChannelId={channelId}
                            view={PageView.categoryDivisionReport}
                            isFiltersLoading={isLoading}
                            disabledFilterKeys={disabledFilterKeys}
                        />
                        <Button
                            className="Button CategoryDivisionReport--load-report-button"
                            disabled={isLoadButtonDisabled}
                            onClick={loadReport}
                        >
                            LOAD REPORT
                        </Button>
                    </div>
                )}
                {missingFiltersKeys.length === 0 &&
                    categoryDivisionReportTotal &&
                    categoryDivisionReportTotal.length > 0 &&
                    !isButtonPristine && (
                        <>
                            <a
                                href={`${
                                    process.env.REACT_APP_API_ENDPOINT
                                }/buyplan/reports/category-division-report-export/?${qs.stringify({
                                    filter: mainFilters,
                                    activeSeason: activeSeasonId,
                                    channelId,
                                    Authorization: `Bearer ${accessToken}`,
                                })}`}
                                className={cn('Button', 'CategoryDivisionReport__download-button', {
                                    'CategoryDivisionReport__download-button--disabled': !enableDownload,
                                })}
                                download
                            >
                                <FontAwesomeIcon
                                    size="lg"
                                    className="CategoryDivisionReport__download-button-icon"
                                    icon={faDownload as IconProp}
                                />
                                Download report in Excel
                            </a>
                            <div className="CategoryDivisionReport--table-totals">
                                {categoryDivisionReportTotal.length !== 0 && (
                                    <>
                                        <h2>TOTALS</h2>
                                        <CategoryDivisionTable
                                            report={categoryDivisionReportTotal}
                                            tableId="categoryDivisionTotals"
                                            syncTableHorizontalScrolls={syncTableHorizontalScrolls}
                                            channelId={channelId}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
            </div>
            {renderReport({
                missingFiltersKeys,
                error,
                loading: isLoading || loading,
                categoryDivisionReportTotal,
                categoryDivisionReportCategories,
                filtersHaveChanged: isButtonPristine,
                channelId,
            })}
        </div>
    );
}
export default CategoryDivisionReport;
