import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {
    BuyPlanAggregatedMaterial,
    FilteringMetaData,
    Store,
    PartnerTotal,
    PagingMetadata,
    MainFiltersExtended,
    BuyPlanTotals,
    PartnerTotalsSum,
    LoadingStatus,
    NewRelicEventName,
    NewRelicEventStatus,
} from 'buyplan-common';
import { Link } from 'react-router-dom';
import { ActiveFiltersType, InProgressEvents, UserState } from '../../reducers/user';
import { getBuyPlan } from '../../services/buyplanService';
import { getPartnerTotals } from '../../services/partnersService';
import { getActiveSeasonStatus } from '../../services/activeSeasonStatusService';
import { getMissingMainFilterKeys } from '../../helpers/utils';
import { getStores } from '../../services/storeService';
import {
    getBuyPlanMetaData as getBuyPlanMetaDataAction,
    setBuyPlanAggregatedMaterials as setBuyPlanAggregatedMaterialsAction,
} from '../../actions/buyplan';
import PageWarning from '../PageWarning/PageWarning';
import MainFilters from '../Filters/MainFilters/MainFilters';
import Loader from '../Loader/Loader';
import { PageView } from '../../constants/appConfig';
import { sendCustomNewRelicEvent } from '../../actions/user';
import ViewBuyplanList from './ViewBuyplanList';
import './ViewBuyplan.scss';

const ITEMS_PER_PAGE = 50;

// Manage singleton abort controllers outside component to not to cause a re-render
let abortBuyplanController: AbortController;
let abortMetaDataController: AbortController;

const resetAbortController = (type: 'buyplan' | 'metaData') => {
    if (type === 'buyplan') {
        if (abortBuyplanController) {
            abortBuyplanController.abort();
        }
        abortBuyplanController = new AbortController();
    } else {
        if (abortMetaDataController) {
            abortMetaDataController.abort();
        }
        abortMetaDataController = new AbortController();
    }
};

type Pagination = PagingMetadata & { totalFetched: number }; // totalFetched is a syntetic pagination field created in this component

interface Props {
    activeFilters: ActiveFiltersType;
    metaData: FilteringMetaData;
    isLoading: boolean;
    getBuyPlanMetaData(
        channelId: number,
        includeSubFilters?: boolean,
        filterTypes?: (keyof MainFiltersExtended)[],
        view?: PageView,
        abortSignal?: AbortSignal
    ): void;
    setBuyPlanAggregatedMaterials(aggregatedMaterials: BuyPlanAggregatedMaterial[], page: number): void;
    channelId: number;
    sendCustomNewRelicEvent(event: NewRelicEventName, status: NewRelicEventStatus): void;
    events: InProgressEvents;
}

interface State {
    partnerTotals: PartnerTotal[];
    partnerTotalsSum: PartnerTotalsSum;
    stores: Store[];
    loadingBuyplan: boolean;
    loadingStores: boolean;
    hasHindsightFile: boolean;
    hasMissingStoreTargets: boolean;
    pagination?: Pagination;
    orderBy: { columnKey: string; direction: 'ASC' | 'DESC' };
    buyPlanTotals: BuyPlanTotals;
    totalsOpened: boolean;
    totalsLoading: boolean;
    totalsOutOfDate: boolean;
}

export class ViewBuyplan extends PureComponent<Props, State> {
    state: State = {
        partnerTotals: [],
        partnerTotalsSum: {
            filterRetailSalesTargetUSDTotal: 0,
            salesRetailValueUSD: 0,
            openToBuyTargetUSDTotal: 0,
        },
        stores: [],
        loadingBuyplan: false,
        loadingStores: false,
        hasHindsightFile: true,
        hasMissingStoreTargets: false,
        totalsOpened: false,
        totalsLoading: false,
        totalsOutOfDate: true,
        orderBy: { columnKey: 'materialCode', direction: 'ASC' },
        buyPlanTotals: {
            salesUnits: 0,
            aaSalesUnits: 0,
            afSalesUnits: 0,
            aaBuyWholesaleValueUSD: 0,
            afBuyWholesaleValueUSD: 0,
            buyWholesaleValueUSD: 0,
            aaSalesRetailValueUSD: 0,
            afSalesRetailValueUSD: 0,
            salesRetailValueUSD: 0,
            presentationStocks: 0,
            aaPresentationStocks: 0,
            afPresentationStocks: 0,
            openToBuyUnits: 0,
            aaOpenToBuyUnits: 0,
            afOpenToBuyUnits: 0,
            lastYearTotalUnits: 0,
        },
    };

    componentDidMount() {
        // Here we send an event to track the amount of time it takes to load data on the page
        this.props.sendCustomNewRelicEvent(NewRelicEventName.buyplanInitialLoad, NewRelicEventStatus.start);
        this.getCanEditBuyPlan();
        this.getBuyPlan();
        this.getBuyPlanMetaData();
        this.getStores();
    }

    componentDidUpdate(prevProps: Props) {
        const { activeFilters } = this.props;
        const hasActiveChannelChanged = prevProps.channelId !== this.props.channelId;
        if (hasActiveChannelChanged) {
            this.getCanEditBuyPlan();
            this.getBuyPlan();
            this.clearTotals();
            this.getBuyPlanMetaData();
            this.getStores();
        } else if (
            // Do not update unless there is an update to filters and all required filters are present
            !isEqual(prevProps.activeFilters, activeFilters) &&
            !!activeFilters.mainFilters.partner &&
            !!activeFilters.mainFilters.category &&
            !!activeFilters.mainFilters.division
        ) {
            // Here we send an event to track the amount of time it takes to load buyplan after applying main or subfilters
            this.props.sendCustomNewRelicEvent(NewRelicEventName.applyBuyplanFilters, NewRelicEventStatus.start);
            this.getCanEditBuyPlan();
            this.getBuyPlan();
            this.clearTotals();
            this.getBuyPlanMetaData();
        }
    }

    componentWillUnmount() {
        if (abortBuyplanController) abortBuyplanController.abort();
        if (abortMetaDataController) abortMetaDataController.abort();
    }

    getCanEditBuyPlan = async () => {
        const { data } = await getActiveSeasonStatus();
        this.setState({
            hasHindsightFile: data.hasHindsightFile,
            hasMissingStoreTargets: data.hasMissingStoreTargets,
        });
    };

    getBuyPlan = async (page = 0, orderColumn?: string, orderDirection?: 'ASC' | 'DESC') => {
        const { activeFilters, setBuyPlanAggregatedMaterials } = this.props;
        const { orderBy } = this.state;
        const { mainFilters, subFilters } = activeFilters;

        if (getMissingMainFilterKeys(mainFilters).length > 0) {
            return;
        }

        resetAbortController('buyplan');
        this.setState((state) => ({
            loadingBuyplan: true,
            pagination: { ...state.pagination, page } as Pagination,
        }));

        const trackingEvent =
            this.props.events && this.props.events[NewRelicEventName.applyBuyplanFilters]
                ? NewRelicEventName.applyBuyplanFilters
                : NewRelicEventName.buyplanInitialLoad;

        try {
            const { data: aggregatedMaterials, meta: pagination } = await getBuyPlan(
                {
                    ...mainFilters,
                    ...subFilters,
                },
                page,
                ITEMS_PER_PAGE,
                // If we don't have an orderColumn we use the one currently used in the state
                orderColumn || orderBy.columnKey,
                orderDirection || orderBy.direction,
                abortBuyplanController.signal
            );
            setBuyPlanAggregatedMaterials(aggregatedMaterials, page);
            this.setState({
                loadingBuyplan: false,
                pagination: { ...(pagination as PagingMetadata), totalFetched: aggregatedMaterials.length },
            });
            // Send an event to mark the end of data loading (either initial load or after filters are applied)
            this.props.sendCustomNewRelicEvent(trackingEvent, NewRelicEventStatus.end);
            // If the ordering has been specified and the call has been a success, we can save the ordering options
            if (orderColumn && orderDirection) {
                this.setState({ orderBy: { columnKey: orderColumn, direction: orderDirection } });
            }
        } catch (error: unknown) {
            const err = error as Error;
            if (err.name === 'AbortError') {
                return; // another request started
            }
            this.setState({ loadingBuyplan: false });
            // Send an event to mark loading end due to error
            this.props.sendCustomNewRelicEvent(trackingEvent, NewRelicEventStatus.error);
        }
    };

    getStores = async () => {
        this.setState({ loadingStores: true });
        const { data: stores } = await getStores();
        this.setState({ loadingStores: false, stores });
    };

    getTotals = async () => {
        const { activeFilters } = this.props;
        const { mainFilters, subFilters } = activeFilters;

        const filters = { ...mainFilters, ...subFilters };
        this.setState({ totalsLoading: true });
        const {
            data: { partnerTotals, buyPlanTotals, partnerTotalsSum },
        } = await getPartnerTotals(filters);
        this.setState({
            partnerTotals,
            buyPlanTotals,
            partnerTotalsSum,
            totalsLoading: false,
            totalsOpened: true,
            totalsOutOfDate: false,
        });
    };

    getBuyPlanMetaData = () => {
        resetAbortController('metaData');
        this.props.getBuyPlanMetaData(
            this.props.channelId,
            true,
            ['partner', 'category', 'division'],
            PageView.buyplan,
            abortMetaDataController.signal
        );
    };

    makeTotalsOutOfDate = () => {
        this.setState({
            totalsOutOfDate: true,
        });
    };

    clearTotals = () => {
        this.setState({
            totalsOpened: false,
            totalsOutOfDate: true,
            partnerTotals: [],
            partnerTotalsSum: {
                filterRetailSalesTargetUSDTotal: 0,
                salesRetailValueUSD: 0,
                openToBuyTargetUSDTotal: 0,
            },
            buyPlanTotals: {
                salesUnits: 0,
                aaSalesUnits: 0,
                afSalesUnits: 0,
                aaBuyWholesaleValueUSD: 0,
                afBuyWholesaleValueUSD: 0,
                buyWholesaleValueUSD: 0,
                aaSalesRetailValueUSD: 0,
                afSalesRetailValueUSD: 0,
                salesRetailValueUSD: 0,
                presentationStocks: 0,
                aaPresentationStocks: 0,
                afPresentationStocks: 0,
                openToBuyUnits: 0,
                aaOpenToBuyUnits: 0,
                afOpenToBuyUnits: 0,
                lastYearTotalUnits: 0,
            },
        });
    };

    render() {
        const { metaData, activeFilters, channelId, isLoading } = this.props;
        const {
            loadingBuyplan,
            loadingStores,
            stores,
            hasHindsightFile,
            hasMissingStoreTargets,
            partnerTotals,
            partnerTotalsSum,
            pagination,
            orderBy,
            buyPlanTotals,
            totalsLoading,
            totalsOpened,
            totalsOutOfDate,
        } = this.state;

        if (!loadingStores && stores.length === 0) {
            return (
                <PageWarning>
                    <h1>BUY PLAN</h1>
                    <h2>Not yet available</h2>
                    <p>
                        The Buy Plan is not available until the{' '}
                        <Link to="/buyplan/manage-assortment/import">option counts file</Link> has been uploaded.
                    </p>
                </PageWarning>
            );
        }

        if (!hasHindsightFile) {
            return (
                <PageWarning>
                    <h1>BUY PLAN</h1>
                    <h2>Not yet available</h2>
                    <p>
                        Upload a <Link to="/buyplan/import-files">hindsight file</Link> before working on the Buy Plan.
                    </p>
                </PageWarning>
            );
        }

        if (hasMissingStoreTargets) {
            return (
                <PageWarning>
                    <h1>BUY PLAN</h1>
                    <h2>Not yet available</h2>
                    <p>
                        The Buy Plan is not available until all <Link to="/buyplan/sales-targets">sales targets</Link> have
                        been set on a store level been set.
                    </p>
                </PageWarning>
            );
        }

        if ((isLoading && !metaData?.mainFilters) || loadingStores) {
            return <Loader />;
        }

        const { mainFilters } = metaData;

        return (
            <div className="ViewBuyplan">
                <MainFilters
                    filters={mainFilters}
                    activeChannelId={channelId}
                    activeMainFilters={activeFilters.mainFilters}
                    view={PageView.buyplan}
                />
                <ViewBuyplanList
                    stores={stores}
                    loading={loadingBuyplan || isLoading}
                    partnerTotals={partnerTotals}
                    partnerTotalsSum={partnerTotalsSum}
                    buyPlanTotals={buyPlanTotals}
                    getPartnerTotals={this.getTotals}
                    makeTotalsOutOfDate={this.makeTotalsOutOfDate}
                    pagination={pagination}
                    getBuyplan={this.getBuyPlan}
                    orderBy={orderBy}
                    totalsLoading={totalsLoading}
                    totalsOpened={totalsOpened}
                    totalsOutOfDate={totalsOutOfDate}
                />
            </div>
        );
    }
}

const mapActionCreators = {
    getBuyPlanMetaData: getBuyPlanMetaDataAction,
    setBuyPlanAggregatedMaterials: setBuyPlanAggregatedMaterialsAction,
    sendCustomNewRelicEvent,
};

const mapStateToProps = ({
    buyplan: { metaData },
    user: {
        settings: { activeFilters, activeChannelId: channelId },
        events,
    },
}: {
    buyplan: {
        metaData: {
            [key: string]: { data: FilteringMetaData; loadingStatus: LoadingStatus };
        };
    };
    user: UserState;
}) => {
    const selector = `${channelId}|${PageView.buyplan}`;
    const { data, loadingStatus } = metaData?.[selector] ?? {
        data: {},
        loadingStatus: LoadingStatus.initial,
    };

    return {
        metaData: data,
        isLoading: !loadingStatus || loadingStatus === LoadingStatus.loading,
        activeFilters: {
            mainFilters: activeFilters[selector]?.mainFilters ?? {},
            subFilters: activeFilters[selector]?.subFilters ?? {},
        },
        channelId,
        events,
    };
};

export default connect(mapStateToProps, mapActionCreators)(ViewBuyplan);
