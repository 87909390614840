import React from 'react';
import cn from 'classnames';
import { CategoryDivisionBreakdownReport, categoryDivisionRows, RowConfig } from 'buyplan-common';
import './CategoryDivisionRow.scss';

interface Props {
    row: CategoryDivisionBreakdownReport;
    channelId: number;
}

function CategoryDivisionRow({ row, channelId }: Props) {
    return (
        <tr className="category-division-row">
            <td className="category-division-row__text">{row.division}</td>
            {categoryDivisionRows(channelId).map((rowConfig: RowConfig) => {
                const { key, groupStartHeader } = rowConfig;
                const rowValue = row[key as keyof CategoryDivisionBreakdownReport] as number | null;
                const rowFormattedValue = rowConfig.getValue(rowValue, false);

                return (
                    <td
                        key={key}
                        className={cn({
                            'category-division-row__divider': !!groupStartHeader,
                            'category-division-row--negative': (rowValue ?? 0) < 0,
                        })}
                    >
                        {rowFormattedValue}
                    </td>
                );
            })}
        </tr>
    );
}

export default CategoryDivisionRow;
