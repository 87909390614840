import { OnProgress, request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { upload } from './uploadFileService';

export const uploadAllocationFile = async (file: File, id: string, onProgress: OnProgress) => {
    const { name, size, type } = file;
    const uniqueFileName = `${id}_${sanitizeS3FileName(file.name)}`;

    return upload({
        file,
        destinationKey: `allocation-files/${uniqueFileName}`,
        mimeType: type,
        dryRun: name.includes('no_upload'),
        onProgress,
        uploadPostProcessWith: {
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/allocations/files`,
            data: {
                file: {
                    originalname: name,
                    filename: uniqueFileName,
                    size,
                    mimetype: type,
                },
                id,
            },
        },
    });
};

export const reuploadAllocationFile = async (file: File, id: string, onProgress: OnProgress) => {
    const { name, size, type } = file;
    const uniqueFileName = `${id}_${name}`;

    return upload({
        file,
        destinationKey: `allocation-files/${uniqueFileName}`,
        mimeType: type,
        dryRun: name.includes('no_upload'),
        onProgress,
        uploadPostProcessWith: {
            method: 'PUT',
            url: `${process.env.REACT_APP_API_ENDPOINT}/allocations/files/${id}`,
            data: {
                file: {
                    originalname: name,
                    filename: uniqueFileName,
                    size,
                    mimetype: type,
                },
            },
        },
    });
};

export const deleteAllocationFile = async (id: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/allocations/files/${id}`,
    };

    await request(requestOptions);
};
