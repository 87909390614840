import * as overviewService from '../services/overviewService';
import * as appConfig from '../constants/appConfig';
import * as types from './actionTypes';

export const getOverviewItemsSuccess = (response, pageNr) => ({
    type: types.GET_OVERVIEW_ITEMS_SUCCESS,
    response,
    pageNr,
});

export const getOverviewItemsFailure = (error) => ({
    type: types.GET_OVERVIEW_ITEMS_FAILURE,
    error,
});

export const getMetaDataSuccess = (response) => ({
    type: types.GET_OVERVIEW_METADATA_SUCCESS,
    response,
});

export const getMetaDataFailure = (error) => ({
    type: types.GET_OVERVIEW_METADATA_FAILURE,
    error,
});

export const getOverviewColumnsSuccess = (response) => ({
    type: types.GET_OVERVIEW_COLUMNS_SUCCESS,
    response,
});

export const getOverviewColumnsFailure = (error) => ({
    type: types.GET_OVERVIEW_COLUMNS_FAILURE,
    error,
});

export const getOverviewMetaData = () => async (dispatch) => {
    const action = { type: types.GET_OVERVIEW_METADATA };
    try {
        dispatch(action);
        const response = await overviewService.getOverviewData(action);
        dispatch(getMetaDataSuccess(response));
    } catch (err) {
        dispatch(getMetaDataFailure(err));
    }
};

export const getOverviewColumns = () => async (dispatch) => {
    const action = { type: types.GET_OVERVIEW_COLUMNS };
    try {
        dispatch(action);
        const response = await overviewService.getOverviewColumns(action);
        dispatch(getOverviewColumnsSuccess(response));
    } catch (err) {
        dispatch(getOverviewColumnsFailure(err));
    }
};

export const getOverviewItems =
    (signal, pageNr = 0) =>
    async (dispatch, getState) => {
        const { activeFilters } = getState().overview;
        const action = { type: types.GET_OVERVIEW_ITEMS, pageSize: appConfig.DEFAULT_PAGE_SIZE, pageNr, activeFilters };

        try {
            dispatch(action);
            const response = await overviewService.getOverviewItems(action, signal);
            dispatch(getOverviewItemsSuccess(response));
        } catch (err) {
            if (err.name !== 'AbortError') {
                dispatch(getOverviewItemsFailure(err));
            }
        }
    };

export const setFilter = (filter) => (dispatch) => {
    const action = { type: types.SET_OVERVIEW_FILTER, filter };
    dispatch(action);
    return dispatch(getOverviewItems());
};

export const setFilterFromQuery = (filter) => (dispatch) => {
    const action = { type: types.SET_OVERVIEW_FILTER_FROM_QUERY, filter };
    dispatch(action);
    return dispatch(getOverviewItems());
};

export const clearAllFilters = () => (dispatch) => {
    const action = { type: types.CLEAR_ALL_OVERVIEW_FILTERS };
    dispatch(action);
    return dispatch(getOverviewItems());
};

export const clearFilter = (columnName) => (dispatch) => {
    dispatch({ type: types.CLEAR_OVERVIEW_FILTER, columnName });
};
