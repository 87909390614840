import React, { PureComponent } from 'react';
import './Pagination.scss';

interface Props {
    onChangePage(page: number): void;
    totalRecords: number;
    page?: number;
    loading?: boolean;
    totalPages?: number;
}

interface State {
    previousPageValue: number;
    currentPage?: number;
}

export class Pagination extends PureComponent<Props, State> {
    state: State = {
        currentPage: 0,
        previousPageValue: 0,
    };

    componentDidUpdate() {
        if (this.props.page !== undefined && this.props.page !== this.state.currentPage) {
            this.setState({ currentPage: this.props.page });
        }
    }

    onChangePageHandler = (page?: number) => {
        const { totalPages } = this.props;
        const { previousPageValue } = this.state;
        if (page !== undefined) {
            if (totalPages && page <= totalPages) {
                this.setState({ currentPage: page });
            }
        } else {
            this.setState({ currentPage: undefined, previousPageValue });
        }
    };

    onPageSlide = (page: number) => {
        const { onChangePage } = this.props;
        this.onChangePageHandler(page);
        this.setState({ previousPageValue: page });
        onChangePage(page);
    };

    render() {
        const { totalRecords, totalPages, loading, onChangePage } = this.props;
        const { currentPage, previousPageValue } = this.state;

        if (!totalRecords) return null;

        return (
            <div className="pagination">
                <div className="pagination__total-items-info">
                    {loading ? 'Loading items' : `Total items: ${totalRecords}`}
                </div>
                <div className="pagination__page-slider">
                    <button
                        type="button"
                        className="Button Button--size-s pagination__page-slider-item"
                        id="previousPageButton"
                        disabled={loading || currentPage === 0}
                        onClick={() => this.onPageSlide(currentPage ? currentPage - 1 : 0)}
                    >
                        Previous Page
                    </button>
                    <label className="pagination__page-slider-item">
                        Page
                        <input
                            disabled={loading}
                            className="pagination__page-slider-item pagination__page-input"
                            value={currentPage !== undefined ? currentPage + 1 : ''}
                            name="paginationCurrentPage"
                            id="paginationCurrentPageInput"
                            min={1}
                            max={totalPages}
                            step={1}
                            type="number"
                            onChange={({ target: { valueAsNumber } }) => {
                                if (!Number.isNaN(valueAsNumber)) {
                                    // The user will always type the exact page, but we start from page 0
                                    this.onChangePageHandler(valueAsNumber <= 0 ? 0 : valueAsNumber - 1);
                                } else {
                                    this.onChangePageHandler(undefined);
                                }
                            }}
                            onKeyUp={(e) => {
                                if (e.keyCode === 13 && currentPage !== undefined) {
                                    this.setState({ previousPageValue: currentPage });
                                    onChangePage(currentPage);
                                }
                            }}
                            onBlur={() => {
                                this.setState({ currentPage: previousPageValue });
                            }}
                        />
                    </label>
                    <span className="pagination__page-slider-item">{`/ ${loading ? '##' : totalPages}`}</span>
                    <button
                        type="button"
                        className="Button Button--size-s pagination__page-slider-item"
                        id="nextPageButton"
                        disabled={loading || (currentPage !== undefined && currentPage + 1 === totalPages)}
                        onClick={() => this.onPageSlide(currentPage ? currentPage + 1 : 1)}
                    >
                        Next Page
                    </button>
                </div>
            </div>
        );
    }
}

export default Pagination;
