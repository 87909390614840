import React from 'react';
import { Link } from 'react-router-dom';
import minimumBuyValues from './images/MinimumBuyValues.png';
import minimumBuyValuesNoMatch from './images/MinimumBuyValuesNoMatch.png';
import minimumBuyError from './images/MinimumBuyError.png';

export default function () {
    return (
        <div>
            <h2>Minimum Buys</h2>
            <p>The minimum buy value for a material is determined by:</p>
            <ul>
                <li>the material&apos;s size curve, and</li>
                <li>the minimum buy of a material type in a particular store.</li>
            </ul>
            <p>You can find these values by hovering over the buy input field:</p>
            <img src={minimumBuyValues} alt="Minimum buy values" />
            <p>
                The material type is shown in the format <code>division/category/grade</code>.
            </p>
            <p>
                If a material is not assigned a size curve yet, or if the store has no minimum buy for that material type it
                will appear as a warning:
            </p>
            <img src={minimumBuyValuesNoMatch} alt="No match minimum buy" />
            <p>
                For Digital we will NOT show a warning on size curve due to them not using the order entry part of the
                application.
            </p>
            <p>
                If the buy is lower than either of these two minimum values a red dot appears on the cluster and the store
                input field will be clearly marked:
            </p>
            <img src={minimumBuyError} alt="Minimum buy error" />

            <h3>Minimum Buy Category/Division/Grade</h3>
            <p>
                For each store a minimum number of units can be specified per material category/division/grade-combination.
            </p>
            <p>
                These values can be uploaded in a minimum buy file, see the{' '}
                <Link to="/buyplan/documentation/import-buyplan-files">Import Buy Plan Files</Link> section for more details.
            </p>

            <h3>Buy Plan Download</h3>
            <p>You can download the buy plan even when some buys have not met the minimum buy threshold.</p>
        </div>
    );
}
