import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { faExclamationTriangle, faStar } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRequest } from '../../api/useRequest';
import { getStores } from '../../services/storeService';
import Loader from '../Loader/Loader';
import SettingsStoreItem from '../SettingsModal/SettingsStoreItem';
import './SettingsFavoriteStores.scss';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { clearFavoriteStores, toggleFavoriteStore } from '../../actions/user';
import useActiveChannelSettings from '../../selectors/useActiveChannelSettings';

function SettingsFavoriteStores() {
    const dispatch = useDispatch();
    const { favoriteStores, channelId } = useActiveChannelSettings();

    const fetchStores = useCallback(() => getStores(), []);
    const [loading, error, stores] = useRequest(fetchStores);

    return (
        <>
            {loading && <Loader />}
            {error && (
                <div className="error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}
            {!loading && stores && (
                <div className="SettingsFavoriteStores__stores-list">
                    <p>There are {stores.length} stores. Scroll to see all the stores.</p>
                    <ConfirmButton
                        className="SettingsFavoriteStores__reset-favorites"
                        disabled={favoriteStores.length === 0}
                        label={`Reset ${favoriteStores.length && favoriteStores.length} ${
                            favoriteStores.length === 1 ? 'favorite' : 'favorites'
                        }`}
                        confirmLabel="Really reset?"
                        onClick={() => dispatch(clearFavoriteStores(channelId))}
                    />
                    <ul>
                        {stores.map((store) => (
                            <li key={store.id}>
                                <button
                                    type="button"
                                    onClick={() => dispatch(toggleFavoriteStore(channelId, store.id))}
                                    className={cn(
                                        {
                                            'SettingsFavoriteStores__favorite-link--selected': favoriteStores.includes(
                                                store.id
                                            ),
                                        },
                                        'SettingsFavoriteStores__favorite-link'
                                    )}
                                >
                                    <FontAwesomeIcon icon={faStar as IconProp} className="SettingsFavoriteStores__star" />
                                    <SettingsStoreItem store={store} />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default SettingsFavoriteStores;
