import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { getSeasonsForNDF, sendSeasonToNDF } from '../../services/sendToNDFService';
import Loader from '../Loader/Loader';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import './SendToNDF.scss';
import ContentWrapper from '../ContentWrapper/ContentWrapper';

export interface NDFSeason {
    season: string;
    status: string;
    username?: string;
    uploaddate?: number;
}

interface State {
    seasons: NDFSeason[];
    loading: boolean;
    sending?: string;
    errorMessage?: string;
    postErrorMessage?: string;
}

class SendToNDF extends Component {
    state: State = {
        loading: false,
        seasons: [],
    };

    componentDidMount() {
        this.getSeasonsForNDF();
    }

    getSeasonsForNDF = async () => {
        try {
            const { seasons, errorMessage } = await getSeasonsForNDF();
            this.setState({
                seasons,
                loading: false,
                errorMessage,
                postErrorMessage: undefined,
                sending: undefined,
            });
        } catch (error: unknown) {
            const err = error as Error;
            this.setState({
                seasons: undefined,
                loading: false,
                errorMessage: err.message,
                postErrorMessage: undefined,
                sending: undefined,
            });
        }
    };

    sendSeasonToNDF = async (season: string) => {
        try {
            this.setState({ sending: season });
            const responseSeason = await sendSeasonToNDF(season);
            const { seasons } = this.state;
            const updatedSeason = seasons?.map((s) => (s.season === responseSeason.season ? responseSeason : s));
            this.setState({
                seasons: updatedSeason,
                loading: false,
                errorMessage: undefined,
                postErrorMessage: undefined,
                sending: undefined,
            });
        } catch (error: unknown) {
            const err = error as Error;
            this.setState({
                seasons: undefined,
                loading: false,
                errorMessage: undefined,
                postErrorMessage: err.message,
                sending: undefined,
            });
        }
    };

    render() {
        const { seasons, loading, errorMessage, sending, postErrorMessage } = this.state;
        const seasonsToSend = seasons.filter(({ status }) => status === 'NOT UPLOADED');
        const seasonsAlreadySent = seasons.filter(({ status }) => status === 'COMPLETED');
        return (
            <ContentWrapper>
                <Container>
                    <Row>
                        <Col sm={{ span: 7, offset: 3 }}>
                            <div className="send-to-ndf">
                                <h1>SEND SEASON TO NIKE DATA FOUNDATION {loading && <Loader width={16} />}</h1>
                                <p>
                                    Select a season below to send to the Nike Data Foundation. After a season has been sent,
                                    further updates within Mancala will not be automatically synchronized with the Nike Data
                                    Foundation. To synchronize these updates, click the &quot;SEND TO NDF AGAIN&quot; button
                                    for the season you want to synchronize.
                                </p>
                                {errorMessage && (
                                    <p className="send-to-ndf__error">
                                        <FontAwesomeIcon icon={faExclamationCircle as IconProp} />
                                        {` `}
                                        {errorMessage}
                                    </p>
                                )}
                                <h3>Seasons not yet in NDF</h3>
                                {!errorMessage && (
                                    <>
                                        <ul className="send-to-ndf__list">
                                            {seasonsToSend.length > 0 ? (
                                                seasonsToSend.map(({ season }) => (
                                                    <li key={season}>
                                                        <ConfirmButton
                                                            label={`SEND "${season}" TO NDF`}
                                                            confirmLabel={`REALLY SEND "${season}" TO NDF?`}
                                                            onClick={() => this.sendSeasonToNDF(season)}
                                                            loading={sending === season}
                                                            disabled={!!sending}
                                                        />
                                                    </li>
                                                ))
                                            ) : (
                                                <li>
                                                    <em>All seasons in Mancala have been sent to NDF</em>
                                                </li>
                                            )}
                                        </ul>
                                        {postErrorMessage && (
                                            <p className="send-to-ndf__error">
                                                <FontAwesomeIcon icon={faExclamationCircle as IconProp} />
                                                {` `}
                                                {postErrorMessage}
                                            </p>
                                        )}
                                    </>
                                )}

                                <h3>Seasons in NDF</h3>
                                <ul className="send-to-ndf__archived-list">
                                    {seasonsAlreadySent.length > 0 ? (
                                        seasonsAlreadySent.map(({ season, uploaddate, username }) => (
                                            <li key={season}>
                                                <FontAwesomeIcon icon={faCheckCircle as IconProp} /> {season}{' '}
                                                <span>
                                                    - sent on {moment(uploaddate).format('YYYY-MM-DD HH:mm')} by {username}
                                                    {` - `}
                                                    <ConfirmButton
                                                        label="SEND TO NDF AGAIN"
                                                        confirmLabel={`REALLY SEND "${season}" TO NDF?`}
                                                        onClick={() => this.sendSeasonToNDF(season)}
                                                        loading={sending === season}
                                                        disabled={!!sending}
                                                    />
                                                </span>
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            <em>No seasons sent to NDF yet</em>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ContentWrapper>
        );
    }
}

export default SendToNDF;
