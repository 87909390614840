import { BuyPlanStore } from 'buyplan-common';
import React, { useCallback } from 'react';
import { NumberFormatProps } from 'react-number-format';
import Tooltip from '../Tooltip/Tooltip';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';
import BuyplanRateOfSaleTooltip from './BuyplanRateOfSaleTooltip';

interface Props {
    store: BuyPlanStore;
    onChange(store: BuyPlanStore): void;
    isNBYIPPStore?: boolean;
}

type InputProps = Omit<NumberFormatProps, 'onChange'>;

export const MIN_RATE_OF_SALE = 0;
export const MAX_RATE_OF_SALE = 99999;
export const STEP_RATE_OF_SALE = 0.01;

function BuyPlanRateOfSaleInput({ onChange, store, isNBYIPPStore, ...props }: InputProps & Props) {
    const { rateOfSale } = store;

    // useCallback prevents caret position to be incorrectly updated in react-number-format
    const onChangeStoreROS = useCallback(
        (newValue: number) => onChange({ ...store, rateOfSale: newValue }),
        [store, onChange]
    );

    return isNBYIPPStore ? (
        <Tooltip placement="left" tooltip={`NBY can't be allocated to IPP`}>
            <EnhancedNumberFormat
                {...props}
                name="rateOfSale"
                minimum={MIN_RATE_OF_SALE}
                maximum={MAX_RATE_OF_SALE}
                step={STEP_RATE_OF_SALE}
                clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                value={rateOfSale}
                onChange={onChangeStoreROS}
                decimalScale={2}
                disabled={true}
            />
        </Tooltip>
    ) : (
        <BuyplanRateOfSaleTooltip
            salesRetailValueGrowth={store.salesRetailValueGrowth}
            hasLastYearMatch={store.lastYearMatchReference !== null}
        >
            <EnhancedNumberFormat
                {...props}
                name="rateOfSale"
                minimum={MIN_RATE_OF_SALE}
                maximum={MAX_RATE_OF_SALE}
                step={STEP_RATE_OF_SALE}
                clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
                value={rateOfSale}
                onChange={onChangeStoreROS}
                decimalScale={2}
            />
        </BuyplanRateOfSaleTooltip>
    );
}

export default BuyPlanRateOfSaleInput;
