import React, { memo, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { FilteringMetaData } from 'buyplan-common';
import { useSelector } from '../../../store/reducers';
import { getBuyPlanMetaData } from '../../../actions/buyplan';
import useActiveFilters from '../../../selectors/useActiveFilters';
import MainFilters from '../../Filters/MainFilters/MainFilters';
import './TopStylesFilters.scss';
import { PageView } from '../../../constants/appConfig';
import { reportsFiltersConfig } from '../../../constants/reportsConfig';
import usePrevious from '../../../helpers/usePrevious';
import { shouldUpdateBuyPlanMeta } from '../../../helpers/filters';

interface TopStyleFiltersProps {
    metaData: FilteringMetaData;
    isLoading: boolean;
}

function TopStyleFilters({ metaData, isLoading }: TopStyleFiltersProps) {
    const dispatch = useDispatch();
    const { activeChannelId: channelId } = useSelector(({ user }) => user.settings);
    const { mainFilters } = useActiveFilters(PageView.topStylesReport);
    const { partner, category } = mainFilters;
    const previousPartner = usePrevious(partner);
    const previousCategory = usePrevious(category);
    const isMetaLoading = isLoading && isEmpty(metaData);
    useEffect(() => {
        dispatch(
            getBuyPlanMetaData(
                channelId,
                false,
                reportsFiltersConfig[PageView.topStylesReport].allFilters,
                PageView.topStylesReport
            )
        );
    }, [dispatch, channelId]);

    useEffect(() => {
        // If the required filters (partner / category) change, refresh the data in the other filters
        if (!partner || !category) return;
        if (shouldUpdateBuyPlanMeta([partner, previousPartner], [category, previousCategory])) {
            dispatch(
                getBuyPlanMetaData(
                    channelId,
                    false,
                    reportsFiltersConfig[PageView.topStylesReport].allFilters,
                    PageView.topStylesReport
                )
            );
        }
    }, [dispatch, channelId, category, previousCategory, previousPartner, partner]);

    let disabledFilterKeys: string[] = [];

    // Disable supplementary filters if required filters are missing
    if (!partner || !category) {
        disabledFilterKeys = [...reportsFiltersConfig[PageView.topStylesReport].supplementaryFilters];
    }

    return (
        <div className="top-styles-filters">
            {!isMetaLoading && (
                <MainFilters
                    activeChannelId={channelId}
                    filters={metaData.mainFilters}
                    activeMainFilters={mainFilters}
                    view={PageView.topStylesReport}
                    disabledFilterKeys={disabledFilterKeys}
                    isFiltersLoading={isLoading}
                />
            )}
        </div>
    );
}

export default memo(TopStyleFilters);
