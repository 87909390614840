import React, { Component } from 'react';
import ReactTable from 'react-table';
import { LoadingStatus } from 'buyplan-common';
import * as appConfig from '../../constants/appConfig';
import { OverviewColumn } from '../../reducers/overview';
import FilterGroupContainer from '../Filters/Filter/FilterGroupContainer';
import UpdateOrderlines from '../UpdateOrderlines/UpdateOrderlines';
import UpdateQuantities from '../UpdateQuanties/UpdateQuantities';
import HeaderColumn from '../HeaderColumn/HeaderColumn';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import UpdatableCell from './UpdatableCell';
import 'react-table/react-table.css';
import './Overview.scss';

let abortController: AbortController;

const resetAbortController = () => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
};

interface ColumnSettingsOptions {
    Header: JSX.Element;
    sortable: boolean;
    id: string;
    accessor: (item: Item) => string;
    Cell?(row: OverviewRow): JSX.Element;
    width?: number;
}

interface OverviewRow {
    value: string | number;
    original: { id: number };
}

type Item = { [key: string]: string };

export interface OverviewProps {
    getOverviewItems(page: number, signal: AbortSignal): void;
    getOverviewColumns(): void;
    columns: OverviewColumn[];
    itemsLoadingStatus: LoadingStatus;
    pageNr: number;
    items: Item[];
    pages: number;
}

export interface OverviewState {
    editingColumn: OverviewColumn | undefined;
    showUpdateQuantitiesModal: boolean;
}

class Overview extends Component<OverviewProps, OverviewState> {
    state = {
        editingColumn: undefined,
        showUpdateQuantitiesModal: false,
    };

    componentDidMount() {
        const { getOverviewColumns } = this.props;
        getOverviewColumns();
    }

    componentWillUnmount() {
        if (abortController) abortController.abort();
    }

    handleColumnEdit = (column: OverviewColumn) => {
        this.setState({ editingColumn: column });
    };

    handleColumnEditClose = () => {
        this.setState({ editingColumn: undefined });
    };

    handleQuantitiesEdit = () => {
        this.setState({ showUpdateQuantitiesModal: true });
    };

    handleQuantitiesClose = () => {
        this.setState({ showUpdateQuantitiesModal: false });
    };

    getOverviewItems = (page: number) => {
        const { getOverviewItems } = this.props;
        resetAbortController();

        getOverviewItems(page, abortController.signal);
    };

    renderUpdatableCell = (row: OverviewRow, column: OverviewColumn) => (
        <UpdatableCell row={row} {...column} onEdit={this.handleColumnEdit} />
    );

    getColumnSetting = (column: OverviewColumn) => {
        const options: ColumnSettingsOptions = {
            Header: <span>{column.label}</span>,
            sortable: false,
            id: column.name,
            accessor: (item: { [key: string]: string }) => item[column.name],
        };
        switch (column.type) {
            case 'date':
            case 'text':
            case 'type':
                options.Header = <HeaderColumn label={column.label} onEdit={() => this.handleColumnEdit(column)} />;
                break;
            default:
                break;
        }
        switch (column.name) {
            case 'Purchase_Order_Number': {
                options.width = 200;
                break;
            }
            case 'QuantityLoadIn':
            case 'QuantityReplen':
                options.Cell = (row: OverviewRow) => this.renderUpdatableCell(row, column);
            case 'Quantity': // eslint-disable-line no-fallthrough
            case 'calculated_quantity': // Remove if the backend replaced this with Quantity
                options.Header = <HeaderColumn label={column.label} onEdit={() => this.handleQuantitiesEdit()} />;
                break;
            default:
                break;
        }
        return options;
    };

    render() {
        const { items, itemsLoadingStatus, pages, columns = [], pageNr } = this.props;
        const { editingColumn, showUpdateQuantitiesModal } = this.state;

        return (
            <ContentWrapper>
                <div className="overview-container">
                    <UpdateOrderlines editingColumn={editingColumn} onClose={this.handleColumnEditClose} />
                    <UpdateQuantities isOpen={showUpdateQuantitiesModal} onClose={this.handleQuantitiesClose} />
                    <div className="leftbox">
                        <FilterGroupContainer />
                    </div>
                    <div className="rightbox">
                        {columns.length > 0 && (
                            <ReactTable
                                manual
                                showPageJump={false}
                                page={pageNr}
                                noDataText="No results found"
                                data={items}
                                pages={pages}
                                loading={itemsLoadingStatus === LoadingStatus.loading}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                defaultPageSize={appConfig.DEFAULT_PAGE_SIZE}
                                onPageChange={this.getOverviewItems}
                                style={{
                                    height: 'calc(100vh - 60px)',
                                }}
                                columns={[
                                    {
                                        columns: columns.map(this.getColumnSetting),
                                    },
                                ]}
                                className="-striped -highlight"
                                showPageSizeOptions={false}
                            />
                        )}
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Overview;
