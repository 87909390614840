import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFilter } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { FilterOptions, SubFilters, DropdownOption } from 'buyplan-common';
import { useDispatch } from 'react-redux';
import { clearActiveSubFilters, setActiveSubFilters } from '../../../actions/user';
import Filter from '../Filter/Filter';
import './FilterControl.scss';
import { getAppliedFiltersCount } from '../../../helpers/utils';
import Tooltip, { TooltipType } from '../../Tooltip/Tooltip';
import { PageView } from '../../../constants/appConfig';

interface Props {
    subFilters: FilterOptions[] | undefined;
    activeSubFilters?: SubFilters;
    activeChannelId: number;
    view: PageView.assortment | PageView.buyplan;
}

function FilterControl({ subFilters, activeSubFilters, view, activeChannelId }: Props) {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | undefined>();
    const dispatch = useDispatch();

    const handlePopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, isOpen: boolean) => {
        setAnchorEl(event?.currentTarget);
        setPopoverOpen(isOpen);
    };

    const totalActiveFilters = getAppliedFiltersCount(activeSubFilters);

    const filters = subFilters?.filter((filter) =>
        view === PageView.assortment ? ['options', 'lateAdds'].includes(filter.field) : ['lateAdds'].includes(filter.field)
    );

    return (
        <>
            <div className="FilterControl__buttonContainer">
                {totalActiveFilters > 0 && (
                    <Tooltip tooltip={`Clear all filters: ${totalActiveFilters}`} placement="top" type={TooltipType.active}>
                        <FontAwesomeIcon
                            icon={faTimes as IconProp}
                            size="xs"
                            onClick={() => dispatch(clearActiveSubFilters(activeChannelId, view))}
                            className="FilterControl__clearButton"
                        />
                    </Tooltip>
                )}
                <div
                    className="FilterControl__button"
                    tabIndex={0}
                    role="button"
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        handlePopover(e, true);
                    }}
                >
                    <FontAwesomeIcon icon={faFilter as IconProp} size="lg" />
                </div>
            </div>
            <div>
                <Popover
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={() => handlePopover(undefined, false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    className="FilterControl__popover"
                >
                    <div className="FilterControl__container">
                        {filters &&
                            filters.map((filter) => (
                                <Filter
                                    key={`filterControl-${filter.field}`}
                                    setFilter={(values: string[]) => {
                                        dispatch(setActiveSubFilters({ [filter.field]: values }, activeChannelId, view));
                                    }}
                                    activeFilter={activeSubFilters && activeSubFilters[filter.field]}
                                    filterType="singleSelect"
                                    filterData={filter.options as DropdownOption[]}
                                    label={filter.label}
                                    field={filter.field}
                                    autoCollapse={false}
                                />
                            ))}
                    </div>
                </Popover>
            </div>
        </>
    );
}

export default FilterControl;
