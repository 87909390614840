import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import salesTargetStoreConfirmation from './images/SalesTargets_Store.png';

export default function () {
    return (
        <div>
            <h2>Sales Targets</h2>
            <p>
                Sales targets can be uploaded as described <Link to="/buyplan/documentation/import-buyplan-files">here</Link>
                . On upload, these targets are used to calculate an initial rate of sale for your materials. If you want to
                make further adjustments to Sales Targets after uploading the Sales Targets file, that can be done through
                the <Link to="/buyplan/sales-targets">Sales Targets page</Link>.
            </p>
            <p>
                <em>
                    NOTE: For all channels except digital, you will need to first upload an option counts file before
                    uploading your sales targets file.
                </em>
            </p>
            <p>
                Sales Targets are set at the Store Level, and at the Segment/Category/Division/Gender level. For a
                description about each target type, see the chart below:
            </p>
            <table className="table table__salesTargetsDescription">
                <thead>
                    <tr>
                        <th scope="col">Type of target</th>
                        <th scope="col">Description</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Uploading a target</th>
                        <th scope="col">Editing a target</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Store Sales Targets</th>
                        <td>
                            <div>
                                Used to highlight which stores have reached their respective targets given the latest buy
                                values.
                            </div>
                            <br />
                            <div>Retail sales targets are retail prices, OTB targets are wholesale.</div>
                        </td>
                        <td>
                            Can be specified in either local currency or USD A conversion is applied automatically. For more
                            information, visit the{' '}
                            <Link to="/buyplan/documentation/exchange-rates">Exchange rates page</Link>
                        </td>
                        <td>Calculates rate of sale. The target will also be distributed across all stores.</td>
                        <td>
                            Will not re-calculate rate of sale, unless the target is changed from 0 to a positive number.
                            Updates to store sales targets will be proportionally distributed over the attributed sales
                            targets and vice versa.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Attributed Sales Targets (Segment/Category/Division/Gender)</th>
                        <td>
                            Used to highlight which attributes have reached their respective targets in the Buy Plan view.
                        </td>
                        <td>USD</td>
                        <td>Calculates rate of sale</td>
                        <td>Will not re-calculate rate of sale</td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <br />
            <h4>Validation (Store Sales Targets)</h4>
            <p>
                At the end of each row a <FontAwesomeIcon icon={faCheck as IconProp} /> will appear if all of the following
                conditions are met:
            </p>
            <p>
                <ul>
                    <li>A retail sales target has been set</li>
                    <li>An open to buy sales target has been set</li>
                    <li>
                        A store has sales hindsight data available <em>OR</em> a like-4-like store has been selected
                    </li>
                </ul>
                <p>
                    This validation is purely an aid. If any target values are omitted, the buy plan will simply not show
                    those targets.
                </p>
                <img src={salesTargetStoreConfirmation} alt="Sales target for store confirmation" />
            </p>
            <h4>Like-4-like Stores</h4>
            <p>
                If a store does not have hindsight data available (for example if the store is new) you can select a
                &quot;like-4-like store&quot; as a reference point. This like-4-like store will then be used in matching up
                last year rate of sale calculations to provide one for this year.
            </p>
            <p>
                You may also choose to select a like-4-like store for stores that have hindsight data. This can be used to
                provide a closer match for the rate of sale pyramid. See the{' '}
                <Link to="/buyplan/documentation/clustering">clustering page</Link> for more details on pyramid logic.
            </p>
        </div>
    );
}
