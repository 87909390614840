import React from 'react';
import { kebabCase } from 'lodash';
import cn from 'classnames';
import {
    BuyPlanTotals,
    formatUSD,
    formatNumber,
    viewBuyplanColumns,
    favoriteStoreColumns,
    BuyplanViewColumnConfig,
} from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Dot from '../Dot/Dot';
import { findColumn, getColumn, getColumnWidth, getVisibleColumnWidth } from '../../helpers/tableTools';
import './SubheaderRow.scss';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
    scrollableColumnsWidth: number;
    columns: BuyplanViewColumnConfig[];
    buyPlanTotals: BuyPlanTotals;
    getPartnerTotals(): void;
    totalsOutOfDate: boolean;
    totalsLoading: boolean;
}

const getToolTipForAAAF = (aaValueOrUnits: string, afValueOrUnits: string) => (
    <div className="subheader-row__aa-af-tooltip">
        <strong>AA:</strong> {aaValueOrUnits}
        <br />
        <strong>AF:</strong> {afValueOrUnits}
    </div>
);

function SubheaderRow({
    buyPlanTotals,
    totalsOutOfDate,
    scrollableColumnsWidth,
    columns,
    getPartnerTotals,
    totalsLoading,
}: Props) {
    const isSalesRetailVisible = !!findColumn(columns, 'salesRetail');
    const isSalesUnitsVisible = !!findColumn(columns, 'salesUnits');
    const isBuyWholeSaleVisible = !!findColumn(columns, 'buyWholesale');
    const favoriteStores = columns.filter(({ key }) => key === 'favoriteStore');

    const marginLeft = getVisibleColumnWidth(
        viewBuyplanColumns.filter(({ type }) => type === 'scrollable'),
        columns.filter(({ type }) => type === 'scrollable'),
        undefined,
        'afPercentage'
    );
    const refreshTotals = (
        <Tooltip tooltip={totalsOutOfDate ? 'Refresh totals' : 'Totals are up to date'}>
            <FontAwesomeIcon
                icon={faArrowsRotate as IconProp}
                onClick={totalsLoading || !totalsOutOfDate ? undefined : getPartnerTotals}
                className={`
                    ${totalsLoading ? 'fa-spin' : ''}
                    ${!totalsOutOfDate ? 'subheader-row__disabled' : 'subheader-row__totals-update'}`}
            />
        </Tooltip>
    );
    return (
        <div
            className={`subheader-row ${totalsOutOfDate ? 'subheader-row__disabled' : ''}`}
            style={{ width: scrollableColumnsWidth }}
        >
            <div style={{ marginLeft }}>
                <div
                    className="subheader-row__item subheader-row__item--align-right subheader-row__presentationStocks"
                    style={{ width: getColumnWidth(columns, 'presentationStocks') }}
                >
                    <div className="subheader-row__presentationStocks--item-value">
                        {formatNumber(buyPlanTotals.presentationStocks)} {refreshTotals}
                    </div>
                </div>
                <div
                    className="subheader-row__item subheader-row__item--align-right subheader-row__openToBuyUnits"
                    style={{ width: getColumnWidth(columns, 'openToBuyUnits') }}
                >
                    <div className="subheader-row__openToBuyUnits--item-value">
                        {formatNumber(buyPlanTotals.openToBuyUnits)}
                    </div>
                    <div className="subheader-row__openToBuyUnits--item-tooltip">&nbsp;{refreshTotals}</div>
                </div>
                <div
                    className="subheader-row__item subheader-row__item--align-right subheader-row__aaOpenToBuyUnits"
                    style={{ width: getColumnWidth(columns, 'aaOpenToBuyUnits') }}
                >
                    <div className="subheader-row__aaOpenToBuyUnits--item-value">
                        {formatNumber(buyPlanTotals.aaOpenToBuyUnits)}
                    </div>
                    <div className="subheader-row__aaOpenToBuyUnits--item-tooltip">&nbsp;{refreshTotals}</div>
                </div>
                <div
                    className="subheader-row__item subheader-row__item--align-right subheader-row__afOpenToBuyUnits"
                    style={{ width: getColumnWidth(columns, 'afOpenToBuyUnits') }}
                >
                    <div className="subheader-row__afOpenToBuyUnits--item-value">
                        {formatNumber(buyPlanTotals.afOpenToBuyUnits)}
                    </div>
                    <div className="subheader-row__afOpenToBuyUnits--item-tooltip"> {refreshTotals}</div>
                </div>
                {isSalesUnitsVisible && (
                    <div
                        className="subheader-row__item subheader-row__item--align-right subheader-row__salesUnits"
                        style={{ width: getColumnWidth(columns, 'salesUnits') }}
                    >
                        <div className="subheader-row__salesUnits--item-value">{formatNumber(buyPlanTotals.salesUnits)}</div>
                        <div className="subheader-row__salesUnits--item-tooltip">
                            <Dot
                                placement="right"
                                tooltip={getToolTipForAAAF(
                                    formatNumber(buyPlanTotals.aaSalesUnits),
                                    formatNumber(buyPlanTotals.afSalesUnits)
                                )}
                            />{' '}
                            {refreshTotals}
                        </div>
                    </div>
                )}
                {isBuyWholeSaleVisible && (
                    <div
                        className="subheader-row__item subheader-row__item--align-right subheader-row__buyWholesale"
                        style={{ width: getColumnWidth(columns, 'buyWholesale') }}
                    >
                        <div className="subheader-row__buyWholesale--item-value">
                            {formatUSD(buyPlanTotals.buyWholesaleValueUSD)}
                        </div>
                        <div className="subheader-row__buyWholesale--item-tooltip">
                            <Dot
                                placement="right"
                                tooltip={getToolTipForAAAF(
                                    formatUSD(buyPlanTotals.aaBuyWholesaleValueUSD),
                                    formatUSD(buyPlanTotals.afBuyWholesaleValueUSD)
                                )}
                            />{' '}
                            {refreshTotals}
                        </div>
                    </div>
                )}
                {isSalesRetailVisible && (
                    <div
                        className="subheader-row__item subheader-row__item--align-right subheader-row__salesRetail"
                        style={{ width: getColumnWidth(columns, 'salesRetail') }}
                    >
                        <div className="subheader-row__salesRetail--item-value">
                            {formatUSD(buyPlanTotals.salesRetailValueUSD)}
                        </div>
                        <div className="subheader-row__salesRetail--item-tooltip">
                            <Dot
                                placement="right"
                                tooltip={getToolTipForAAAF(
                                    formatUSD(buyPlanTotals.aaSalesRetailValueUSD),
                                    formatUSD(buyPlanTotals.afSalesRetailValueUSD)
                                )}
                            />{' '}
                            {refreshTotals}
                        </div>
                    </div>
                )}
                {favoriteStores.map(({ label }) => (
                    <div
                        key={`favorite-store-${kebabCase(label)}`}
                        className={cn('subheader-row__item', 'subheader-row__enabled', 'subheader-row__item-favoriteStore')}
                        style={{ width: getColumnWidth(columns, 'favoriteStore') }}
                    >
                        <div
                            className="subheader-row__item subheader-row__item--align-right"
                            style={{ width: getColumnWidth(favoriteStoreColumns, 'rateOfSale') }}
                        >
                            {getColumn(favoriteStoreColumns, 'rateOfSale').label}
                        </div>
                        <div
                            className="subheader-row__item subheader-row__item--align-right"
                            style={{ width: getColumnWidth(favoriteStoreColumns, 'sellThrough') }}
                        >
                            {getColumn(favoriteStoreColumns, 'sellThrough').label}
                        </div>
                        <div
                            className="subheader-row__item subheader-row__item--align-right"
                            style={{ width: getColumnWidth(favoriteStoreColumns, 'openToBuyUnits') }}
                        >
                            {getColumn(favoriteStoreColumns, 'openToBuyUnits').label}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SubheaderRow;
