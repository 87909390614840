import { ExchangeRate } from '../../services/exchangeRatesService';
import { useSelector } from '../../store/reducers';

export const validateExchangeRates = (
    newExchangeRates: { [key: string]: ExchangeRate },
    fxRateAdjustmentDigital: number
) => {
    const exchangeRatesOverrideDigitalError = !fxRateAdjustmentDigital
        ? 'A valid hindsight value adjustment (digital) is required'
        : '';

    const exchangeRatesErrors = (Object.values(newExchangeRates) as ExchangeRate[]).reduce((obj, exchangeRate) => {
        if (!exchangeRate.rate) {
            return {
                ...obj,
                [exchangeRate.currency]: `A valid rate for ${exchangeRate.currency} is required`,
            };
        }
        return obj;
    }, {});

    return {
        exchangeRatesErrors,
        exchangeRatesOverrideDigitalError,
    };
};

export const useFxRatesStatus = () => useSelector((state) => state.notification.processLoadStatus.fxRates.status);
