import { DropdownOption } from 'buyplan-common';

// Returns an array of strings containing the term entered
export const autoCompleteFilter = (sentences: DropdownOption[], term: string): DropdownOption[] => {
    if (!term) return sentences;

    const searchTerms = term
        .split(',') // split into multiple search terms
        .map((searchTerm) => searchTerm.trim().toLowerCase())
        .filter((item) => item); // remove empty searches

    let foundDropdownOptions = [] as DropdownOption[];
    let foundStringsValues = [] as string[];

    searchTerms.forEach((searchTerm) => {
        const regex = new RegExp(`(.*${searchTerm.replace(/ /g, '.*) (.*')}.*)`, 'i');
        if (!foundStringsValues.length) {
            foundDropdownOptions = sentences.filter((sentence) => regex.test(sentence.label));
            foundStringsValues = foundDropdownOptions.map((sentence) => sentence.label as string);
        }
        // add all found strings that have not been found already as a result of your other search terms
        const filteredSentences = sentences.filter(
            (sentence) => regex.test(sentence.label) && !foundStringsValues.includes(sentence.label as string)
        );

        foundDropdownOptions = [...foundDropdownOptions, ...filteredSentences];
    });

    return foundDropdownOptions;
};
