import { BuyPlanStore } from 'buyplan-common';
import React, { useCallback } from 'react';
import { NumberFormatProps } from 'react-number-format';
import EnhancedNumberFormat, { ClearBehaviour } from '../EnhancedNumberFormat/EnhancedNumberFormat';

interface Props {
    store: BuyPlanStore;
    onChange(store: BuyPlanStore): void;
}

type InputProps = Omit<NumberFormatProps, 'onChange'>;

export const MIN_WEEKS_ON_SALE = 0;
export const MAX_WEEKS_ON_SALE = 53;
export const STEP_WEEKS_ON_SALE = 1;

function BuyPlanWeeksOnSaleInput({ onChange, store, ...props }: InputProps & Props) {
    const { weeksOnSale } = store;

    // useCallback prevents caret position to be incorrectly updated in react-number-format
    const onChangeStoreWOS = useCallback(
        (newValue: number) => onChange({ ...store, weeksOnSale: newValue }),
        [store, onChange]
    );

    return (
        <EnhancedNumberFormat
            {...props}
            name="weeksOnSale"
            minimum={MIN_WEEKS_ON_SALE}
            maximum={MAX_WEEKS_ON_SALE}
            step={STEP_WEEKS_ON_SALE}
            clearBehaviour={ClearBehaviour.RESET_LAST_VALUE}
            value={weeksOnSale}
            onChange={onChangeStoreWOS}
            decimalScale={0}
        />
    );
}

export default BuyPlanWeeksOnSaleInput;
