import React, { useEffect, useState, ReactNode } from 'react';
import { Material } from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';
import './EditableCell.scss';
import TextInput from '../TextInput/TextInput';
import { useOutsideClick } from '../../helpers/useOutsideClick';

interface Props {
    columnKey: string;
    value: string | number | boolean | string[] | null | undefined;
    disabled?: boolean;
    onChange?: (attributes: Partial<Material>) => void;
    materialCode: string;
    children?: ReactNode;
}

function EditableCell({ columnKey, value, disabled, onChange, materialCode, children }: Props) {
    const [editableMode, setEditableMode] = useState(false);
    const [currentValue, setEditableValue] = useState(value);

    useEffect(() => {
        if (value) {
            setEditableValue(value);
        }
    }, [value]);

    const onSave = () => {
        if (editableMode) {
            setEditableMode(false);
            if (!currentValue) {
                setEditableValue(value);
            }

            if (value !== currentValue) {
                onChange?.({ [columnKey]: currentValue as string });
            }
        }
    };

    const ref = useOutsideClick<HTMLInputElement>(onSave);

    const onEditClick = () => setEditableMode(true);

    if (disabled) {
        return (
            <div className="EditableCell">
                <span>{currentValue}</span>
                {children}
            </div>
        );
    }

    return (
        <div
            ref={ref}
            className={cn('EditableCell', {
                'EditableCell--editable': !!editableMode,
            })}
        >
            {editableMode ? (
                <TextInput
                    key={`EditableCell-${materialCode}-${columnKey}`}
                    value={currentValue as string}
                    onChange={(val: string) => {
                        setEditableValue(val);
                    }}
                    onKeyDown={onSave}
                />
            ) : (
                <>
                    <span>{currentValue}</span>
                    {children}
                </>
            )}

            {!editableMode && (
                <button type="button" onClick={onEditClick} className="EditableCell__edit-button-icon">
                    <FontAwesomeIcon icon={faPen as IconProp} size="xs" />
                </button>
            )}
        </div>
    );
}

export default EditableCell;
