import { CreateSeasonData, EditSeasonData, Season } from 'buyplan-common';
import {
    getCurrentSeason,
    postSeason,
    patchSeason,
    getAllSeason,
    getCurrentSeasonForUpdatedChannel,
    getAllSeasonForUpdatedChannel,
} from '../services/seasonService';
import {
    EDIT_SEASON,
    EDIT_SEASON_FAILURE,
    EDIT_SEASON_SUCCESS,
    GET_SEASON_DATA,
    NEW_SEASON,
    NEW_SEASON_FAILURE,
    NEW_SEASON_SUCCESS,
    GET_SEASON_DATA_SUCCESS,
} from './actionTypes';
import { AnyDispatch, GetRootState } from './types';
import { setSeason } from './user';

export type SeasonData = {
    currentSeason: Season;
    allSeason: Season[];
    newActiveSeasonId?: number;
    newActiveChannelId?: number;
};

export const getSeasonDataSuccess = (seasonData: SeasonData) => ({ type: GET_SEASON_DATA_SUCCESS, seasonData } as const);

export type GetSeasonDataSuccessAction = ReturnType<typeof getSeasonDataSuccess>;

export const getSeasonData = (newActiveChannelId?: number) => async (dispatch: AnyDispatch, getState: GetRootState) => {
    const action = { type: GET_SEASON_DATA };
    dispatch(action);

    const [currentSeason, allSeason] = newActiveChannelId
        ? await Promise.all([
              getCurrentSeasonForUpdatedChannel(newActiveChannelId),
              getAllSeasonForUpdatedChannel(newActiveChannelId),
          ])
        : await Promise.all([getCurrentSeason(), getAllSeason()]);

    // It can be the case that the user is trying to view a season associated with a new channel audience
    // If so, the activeSeasonId will no longer be valid, so we set it to be the currentSeason for this updated channel.
    const { activeSeasonId } = getState().user.settings;
    const availableSeasonsForChannel = allSeason.data.map((season) => season.id);
    if (!availableSeasonsForChannel?.includes(activeSeasonId)) {
        dispatch(
            getSeasonDataSuccess({
                currentSeason: currentSeason.data,
                allSeason: allSeason.data,
                newActiveSeasonId: currentSeason.data.id,
                newActiveChannelId,
            })
        );
    } else {
        dispatch(
            getSeasonDataSuccess({
                currentSeason: currentSeason.data,
                allSeason: allSeason.data,
                newActiveChannelId,
            })
        );
    }
};

export type GetSeasonDataAction = { type: typeof GET_SEASON_DATA };

export const newSeasonSuccess = (season: Season) => ({ type: NEW_SEASON_SUCCESS, season } as const);

export type NewSeasonSuccessAction = ReturnType<typeof newSeasonSuccess>;

export const newSeasonFailure = (error: string) => ({ type: NEW_SEASON_FAILURE, error } as const);

export type NewSeasonFailureAction = ReturnType<typeof newSeasonFailure>;

export const newSeason = (data: CreateSeasonData) => async (dispatch: AnyDispatch) => {
    const action = { type: NEW_SEASON };
    try {
        dispatch(action);
        const response = await postSeason(data);
        dispatch(newSeasonSuccess(response.data));
        dispatch(setSeason(response.data.id));
    } catch (err: unknown) {
        const error = err as Error;
        dispatch(newSeasonFailure(error.message));
        throw err;
    }
};

export type NewSeasonAction = { type: typeof NEW_SEASON };

export const editSeasonSuccess = (season: Season) => ({ type: EDIT_SEASON_SUCCESS, season } as const);

export type EditSeasonSuccessAction = ReturnType<typeof editSeasonSuccess>;

export const editSeasonFailure = (error: string) => ({ type: EDIT_SEASON_FAILURE, error } as const);

export type EditSeasonFailureAction = ReturnType<typeof editSeasonFailure>;

export const editSeason = (season: EditSeasonData) => async (dispatch: AnyDispatch) => {
    const action = { type: EDIT_SEASON };
    try {
        dispatch(action);
        const response = await patchSeason(season);
        dispatch(editSeasonSuccess(response.data));
    } catch (err: unknown) {
        const error = err as Error;
        dispatch(editSeasonFailure(error.message));
        throw err;
    }
};
export type EditSeasonAction = { type: typeof EDIT_SEASON };

export type SeasonActions =
    | GetSeasonDataAction
    | GetSeasonDataSuccessAction
    | NewSeasonAction
    | NewSeasonSuccessAction
    | NewSeasonFailureAction
    | EditSeasonAction
    | EditSeasonSuccessAction
    | EditSeasonFailureAction;
