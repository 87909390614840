import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link, Redirect } from 'react-router-dom';
import { Tabs, TabPanel, TabList } from 'react-tabs';
import { UserProfile, Channel, ChannelMeta, ChannelRole, ChannelProfileKeys, channels } from 'buyplan-common';
import { getUser, editUser, getUserMeta } from '../../services/usersService';
import useUserProfile from '../../selectors/useUserProfile';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Loader from '../Loader/Loader';
import ChannelPermissions from './ChannelPermissions';
import PermissionTab from './PermissionTab';
import './UserForm.scss';

interface Props {
    match: { params: { email: string } };
}
/*
    modifyUserChannelSettings creates an updated user profile with changed values for partner/category/division in the selected channel
    If a key is missing (for example, for a newly-created user), this function will add it
*/

export const modifyUserChannelSettings = (
    existingProfile: UserProfile,
    key: ChannelProfileKeys,
    values: string[] | undefined,
    channelId: number
) => {
    const updatedChannels = existingProfile.channels.map((channel) => {
        if (channel.id === channelId) {
            const updatedChannel = { ...channel };
            updatedChannel[key] = values;
            return updatedChannel;
        }
        return channel;
    });

    const updatedProfile = { ...existingProfile };
    updatedProfile.channels = updatedChannels;
    return updatedProfile;
};

function UserForm({ match }: Props) {
    const { email } = match.params;
    const currentUserProfile = useUserProfile();

    const [userProfileToEdit, setUserProfile] = useState<UserProfile>();
    const [channelMeta, setChannelMeta] = useState<ChannelMeta>();
    const [loading, setIsLoading] = useState(false);
    const [channelMetaLoading, setChannelMetaLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [displayChannels, setDisplayChannels] = useState<Channel[]>();
    const [redirect, setRedirect] = useState(false);
    const [success, setSuccess] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const fetchChannelMeta = async (chanWithPermissions: number) => {
        try {
            setChannelMetaLoading(true);
            const { data: meta } = await getUserMeta(chanWithPermissions);
            setChannelMeta(meta);
            setChannelMetaLoading(false);
        } catch (error) {
            setChannelMetaLoading(false);
            setHasError(true);
        }
    };

    const fetchUserData = useCallback(
        async (userEmail: string) => {
            try {
                const response = await getUser(userEmail);
                setUserProfile(response.data);

                // The admin who is viewing the user should only be able to view/edit the channels they are admin for
                const channelsToDisplay = response.data?.channels.reduce((acc: Channel[], curr: Channel) => {
                    const adminChannelMatch = currentUserProfile?.channels
                        // Do not display wholesale channel until it is supported in Buyplan
                        .filter((channel) => channel.id !== channels.wholesale.id)
                        .find(({ name, channelRole }) => name === curr.name && channelRole === ChannelRole.admin);
                    if (adminChannelMatch) {
                        return [...acc, curr];
                    }
                    return acc;
                }, []);
                setDisplayChannels(channelsToDisplay);

                // Fetch the channel meta for the currently-displayed tab
                fetchChannelMeta(channelsToDisplay[0].id);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setHasError(true);
            }
        },
        [currentUserProfile?.channels]
    );

    useEffect(() => {
        fetchUserData(email);
    }, [email, fetchUserData]);

    const handleSubmit = async () => {
        if (!userProfileToEdit) return;
        try {
            await editUser(userProfileToEdit);
            setSuccess(true);
            // redirect to main screen after successful changes have been applied
            setTimeout(() => {
                setRedirect(true);
            }, 3000);
        } catch (err) {
            setHasError(true);
        }
    };

    const modifyChannel = (key: ChannelProfileKeys, values: string[] | undefined, channelId: number) => {
        if (!userProfileToEdit) return;
        const updatedProfile = modifyUserChannelSettings(userProfileToEdit, key, values, channelId);
        setUserProfile(updatedProfile);
    };

    if (hasError) {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="UserForm__error-message error">
                            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> An error occurred
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (loading || !channelMeta) {
        return <Loader />;
    }

    if (redirect) {
        return <Redirect to="/buyplan/manage-users" />;
    }

    // If the user is newly added, partner/category/division will not be assigned, but need to be in order to save.
    const missingReqdData = !!userProfileToEdit?.channels.find(
        (channelObj) => !channelObj.partner || !channelObj.category || !channelObj.division
    );

    // Following NSO/NSP > NDS migration, there were cases where the channel role could not be defined for NDS.
    // This was the case if the channel role differed between NSO and NSP.
    // In this case there will be a missing channel role until the user logs in / AD group is verified for NDS.
    const missingChannelRole = !!userProfileToEdit?.channels.find((channelObj) => !channelObj.channelRole);

    return (
        <Container className="UserForm">
            <Row>
                {userProfileToEdit && displayChannels && (
                    <Col>
                        {success && (
                            <div className="UserForm__success-message">
                                {`You successfully edited permissions for ${userProfileToEdit.email}`}
                            </div>
                        )}
                        <fieldset className="UserForm__fields-container">
                            <legend>UPDATE USER SETTINGS</legend>
                            <p className="UserForm__email">
                                Email: <span>{`${userProfileToEdit.email}`}</span>
                            </p>
                            <Tabs selectedIndex={currentTab} onSelect={(index) => setCurrentTab(index)}>
                                <TabList>
                                    {displayChannels.map((displayedChannel) => (
                                        <PermissionTab
                                            key={displayedChannel.id}
                                            user={userProfileToEdit}
                                            displayedChannel={displayedChannel}
                                            switchChannel={() => fetchChannelMeta(displayedChannel.id)}
                                        />
                                    ))}
                                </TabList>
                                {displayChannels.map((displayedChannel) => (
                                    <TabPanel key={displayedChannel.id}>
                                        <ChannelPermissions
                                            key={currentTab}
                                            user={userProfileToEdit}
                                            currentProfileChannel={displayedChannel}
                                            options={channelMeta}
                                            onChannelPermissionChange={(
                                                key: ChannelProfileKeys,
                                                values: string[] | undefined,
                                                channelId: number
                                            ) => modifyChannel(key, values, channelId)}
                                            isLoading={channelMetaLoading}
                                        />
                                    </TabPanel>
                                ))}
                            </Tabs>
                            {!channelMetaLoading && (
                                <div className="UserForm__submit-buttons">
                                    {missingReqdData && (
                                        <div>
                                            <p className="UserForm__submit-error-message">
                                                Please assign at least one partner, category, and division for each channel
                                                the user can access.
                                            </p>
                                        </div>
                                    )}
                                    {missingChannelRole && (
                                        <div>
                                            <p className="UserForm__submit-error-message">
                                                User must log in before you can make further changes to this profile.
                                            </p>
                                        </div>
                                    )}
                                    <ConfirmButton
                                        label="SAVE SETTINGS"
                                        confirmLabel="Really save?"
                                        onClick={handleSubmit}
                                        disabled={missingReqdData || missingChannelRole}
                                    />
                                    &nbsp;
                                    <Link to="/buyplan/manage-users" className="Button Button__inverted">
                                        <FontAwesomeIcon icon={faTimesCircle as IconProp} />
                                        &nbsp;cancel
                                    </Link>
                                </div>
                            )}
                        </fieldset>
                    </Col>
                )}
            </Row>
        </Container>
    );
}

export default UserForm;
