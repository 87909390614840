import React from 'react';
import Loader from '../Loader/Loader';
import SuccessIcon from '../Icons/SuccessIcon';
import ErrorIcon from '../Icons/ErrorIcon';

interface Props {
    message: string;
    success: boolean;
    pending: boolean;
}

function UpdateQuantitiesFeedback({ message, success, pending }: Props) {
    return (
        <div className="update-quantities__feedback">
            {pending && <Loader />}

            {message && (
                <div className={`update-quantities__message${success ? '--success' : '--error'}`}>
                    {success ? <SuccessIcon /> : <ErrorIcon />}
                    <p>{message}</p>
                </div>
            )}
        </div>
    );
}

export default UpdateQuantitiesFeedback;
