import { request } from '../api/api';
import { NDFSeason } from '../components/SendToNDF/SendToNDF';

export const sendSeasonToNDF = (season: string) =>
    request<NDFSeason>({
        method: 'POST',
        data: { season },
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/ndf`,
    });
interface GetSeasonsResponse {
    seasons: NDFSeason[];
    errorMessage?: string;
}

export const getSeasonsForNDF = () =>
    request<GetSeasonsResponse>({
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/mancala/ndf`,
    });
