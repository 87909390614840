import React from 'react';
import { channels } from 'buyplan-common';
import { Redirect } from 'react-router-dom';
import Logo from '../Logo/Logo';
import homeOpening from '../../assets/img/home_opening.jpg';
import './Home.scss';
import useUserProfile from '../../selectors/useUserProfile';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import PublicHome from './PublicHome';

export function Home() {
    const profile = useUserProfile();
    const isNDSAdmin = useIsChannelAdmin(channels.nds.id);

    if (!profile) {
        return <PublicHome />;
    }

    if (!isNDSAdmin) {
        return <Redirect to="/buyplan" />;
    }

    return (
        <div className="Home">
            <div className="Home__body">
                <div className="Home__message">
                    <h1 className="Home__header">WELCOME</h1>
                    <h2 className="Home__subheader">What application would you like to use?</h2>
                    <div className="Home__logos">
                        <div className="Home__logo">
                            <Logo app="mancala" to="/import" black={false} />
                        </div>
                        <div className="Home__logo">
                            <Logo app="buyplan" to="/buyplan" black={false} />
                        </div>
                    </div>
                </div>
            </div>
            <img src={homeOpening} className="Home__opening" alt="Welcome" />
        </div>
    );
}

export default Home;
