import { OnProgress, request } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { upload } from './uploadFileService';

export interface SalesTarget {
    localCurrency: string;
    retailSalesTargetLocal: number | null;
    retailSalesTargetUSD: number | null;
    openToBuyTargetLocal: number | null;
    openToBuyTargetUSD: number | null;
}

export interface StoreSalesTarget extends SalesTarget {
    id: string;
    storeId: string;
    seasonId: number;
    partner: string;
}

export interface AttributedSalesTarget {
    id: string;
    category: string;
    division: string;
    consumer: string;
    segment: string;
    partner: string;
    retailSalesTargetUSD: number | null;
}

export const getStoreTargets = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/store`,
    };

    return request<{ data: StoreSalesTarget[] }>(requestOptions, true);
};

export const putStoreTarget = (storeId: string, data: Partial<SalesTarget>) => {
    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/store/${storeId}`,
        data,
    };

    return request<{ data: StoreSalesTarget }>(requestOptions);
};

export const getAttributedSalesTargets = () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/attributed`,
    };

    return request<{ data: AttributedSalesTarget[] }>(requestOptions, true);
};

export const putAttributedSalesTarget = (storeId: string, data: Partial<AttributedSalesTarget>) => {
    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/attributed/${storeId}`,
        data,
    };

    return request<{ data: AttributedSalesTarget }>(requestOptions);
};

export const uploadSalesTargetsFile = async (file: File, id: string, onProgress: OnProgress) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${id}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `salesTargets-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/files`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
            id,
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
    });
};

export const reuploadSalesTargetsFile = async (file: File, id: string, onProgress: OnProgress) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${id}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `salesTargets-files/${uniqueFileName}`;

    const requestOptions = {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/files/${id}`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
    });
};

export const deleteSalesTargetsFile = async (id: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/sales-targets/files/${id}`,
    };

    await request(requestOptions);
};
