import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ChannelAudience, getChannelAudience } from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SeasonForm, { SeasonFormType } from '../SeasonForm/SeasonForm';
import { useSelector } from '../../store/reducers';
import './NewSeason.scss';

function NewSeason() {
    const { settings } = useSelector((state) => state.user);
    const channelAudience = getChannelAudience(settings.activeChannelId);
    const titleMessage = () => {
        if (channelAudience === ChannelAudience.ndsDigital) {
            return 'NEW SEASON: NDS / DIGITAL';
        }
        if (channelAudience === ChannelAudience.nvs) {
            return 'NEW SEASON: NVS';
        }
        return 'NEW SEASON';
    };

    return (
        <Container>
            <Row>
                <Col xs={{ span: 8, offset: 2 }}>
                    <h1>{titleMessage()}</h1>
                    <div className="NewSeason__warningDialog">
                        <h3>
                            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> CAUTION
                        </h3>
                        <p>
                            This process will create a new season for the channel(s) listed above. This action is
                            irreversible.
                        </p>
                    </div>
                    <SeasonForm type={SeasonFormType.new} />
                </Col>
            </Row>
        </Container>
    );
}

export default NewSeason;
