import React from 'react';
import { PartnerTotal, formatUSD, formatPercentage, PartnerTotalsSum } from 'buyplan-common';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import './BuyplanPartnerTotals.scss';

interface Props {
    partnerTotals: PartnerTotal[];
    partnerTotalsSum: PartnerTotalsSum;
    getPartnerTotals(): void;
    totalsOpened: boolean;
    totalsLoading: boolean;
    totalsOutOfDate: boolean;
}

function BuyPlanPartnerTotals({
    partnerTotals,
    partnerTotalsSum,
    getPartnerTotals,
    totalsOpened,
    totalsLoading,
    totalsOutOfDate,
}: Props) {
    return (
        <div className="buyplan-partners-total__container">
            <div className="buyplan-partners-total__title-container">
                <div className="buyplan-partners-total__button">
                    <Button
                        disabled={totalsLoading || !totalsOutOfDate}
                        className="Button__inverted"
                        onClick={getPartnerTotals}
                    >
                        Get Totals {totalsLoading && <Loader width={16} />}
                    </Button>
                </div>
                <h5 className="buyplan-partners-total__title">
                    Targets per partner for current categories/divisions filter
                    {totalsOutOfDate ? ' (Out of date)' : ''}
                </h5>
            </div>
            {totalsOpened && (
                <table
                    cellPadding={0}
                    cellSpacing={0}
                    className={`buyplan-partners-total__table table table-bordered table-sm ${
                        totalsOutOfDate ? 'buyplan-partners-total__out-of-date' : 'table-hover'
                    }`}
                >
                    <thead className="buyplan-partners-total__header thead-light">
                        <tr>
                            <th className="buyplan-partners-total__header-item">Partner</th>
                            <th className="buyplan-partners-total__header-item">Cat/div sales target</th>
                            <th className="buyplan-partners-total__header-item">Revenue</th>
                            <th className="buyplan-partners-total__header-item">Revenue vs Target</th>
                            <th className="buyplan-partners-total__header-item">Total OTB target</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partnerTotals?.length > 1 && (
                            <tr key="total" className="buyplan-partners-total__table-row">
                                <td className="buyplan-partners-total__data-item">TOTAL</td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {formatUSD(partnerTotalsSum.filterRetailSalesTargetUSDTotal)}
                                </td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {formatUSD(partnerTotalsSum.salesRetailValueUSD)}
                                </td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {/* If we try to format percentage of revenue vs target and either value is 0, it will result in NaN or ∞ */}
                                    {!partnerTotalsSum.salesRetailValueUSD ||
                                    !partnerTotalsSum.filterRetailSalesTargetUSDTotal
                                        ? '-'
                                        : formatPercentage(
                                              partnerTotalsSum.salesRetailValueUSD /
                                                  partnerTotalsSum.filterRetailSalesTargetUSDTotal -
                                                  1
                                          )}
                                </td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {formatUSD(partnerTotalsSum.openToBuyTargetUSDTotal)}
                                </td>
                            </tr>
                        )}
                        {partnerTotals?.map((total) => (
                            <tr key={total.partnerName} className="buyplan-partners-total__table-row">
                                <td className="buyplan-partners-total__data-item">{total.partnerName}</td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {formatUSD(total.filterRetailSalesTargetUSDTotal)}
                                </td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {formatUSD(total.salesRetailValueUSD)}
                                </td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {/* If we try to format percentage of revenue vs target and either value is 0, it will result in NaN or ∞ */}
                                    {!total.salesRetailValueUSD || !total.filterRetailSalesTargetUSDTotal
                                        ? '-'
                                        : formatPercentage(
                                              total.salesRetailValueUSD / total.filterRetailSalesTargetUSDTotal - 1
                                          )}
                                </td>
                                <td className="buyplan-partners-total__data-item--number">
                                    {formatUSD(total.openToBuyTargetUSDTotal)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default BuyPlanPartnerTotals;
