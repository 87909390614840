import { CreateSeasonData, EditSeasonData, Season } from 'buyplan-common';
import { request } from '../api/api';

export const getAllSeason = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/all`,
    };

    return request<{ data: Season[] }>(requestOptions);
};

export const getAllSeasonForUpdatedChannel = async (updatedChannel?: number) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/all/${updatedChannel}`,
    };

    return request<{ data: Season[] }>(requestOptions);
};

export const getCurrentSeason = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current`,
    };

    return request<{ data: Season }>(requestOptions);
};

export const getCurrentSeasonForUpdatedChannel = async (updatedChannel?: number) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/current/${updatedChannel}`,
    };

    return request<{ data: Season }>(requestOptions);
};

export const patchSeason = async (data: EditSeasonData) => {
    const requestOptions = {
        method: 'PATCH',
        data,
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons/${data.id}`,
    };

    return request<{ data: Season }>(requestOptions, true);
};

export const postSeason = async (data: CreateSeasonData) => {
    const requestOptions = {
        method: 'POST',
        data,
        url: `${process.env.REACT_APP_API_ENDPOINT}/seasons`,
    };

    return request<{ data: Season }>(requestOptions, true);
};
