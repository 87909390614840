import React, { useState } from 'react';
import cn from 'classnames';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import './ConfirmButton.scss';

interface Props {
    confirmLabel: string;
    label: string;
    onClick(e?: React.MouseEvent): void;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    size?: 's' | 'm' | 'l';
}

function ConfirmButton({ className, confirmLabel, disabled, label, loading, onClick, size, ...otherProps }: Props) {
    const [confirmed, setConfirmed] = useState(false);
    const text = confirmed ? confirmLabel : label;

    const handleClick = (e?: React.MouseEvent) => {
        if (confirmed && onClick) {
            onClick(e);
        }
        setConfirmed(!confirmed);
    };

    return (
        <Button
            {...otherProps}
            size={size}
            disabled={disabled || loading}
            onClick={handleClick}
            className={cn({ ConfirmButton__confirm: confirmed }, className)}
        >
            {text} {loading && <Loader width={16} />}
        </Button>
    );
}

export default ConfirmButton;
