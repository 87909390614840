import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CurrencyType } from 'buyplan-common';
import { getStores } from '../../services/storeService';
import { getStoreTargets } from '../../services/salesTargetService';
import { getExchangeRates } from '../../services/exchangeRatesService';
import { useSelector } from '../../store/reducers';
import { useRequest, useRequestFunction } from '../../api/useRequest';
import usePrevious from '../../helpers/usePrevious';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';
import Toggle from '../Toggle/Toggle';
import { SelectOptionType } from '../ReactSelect/ReactSelect';
import TargetsTable from './TargetsTable';
import './StoreAttributedSalesTargets.scss';

const unitTypes = [
    {
        icon: <FontAwesomeIcon icon={faDollarSign as IconProp} />,
        title: 'Show in USD',
        value: CurrencyType.USD,
    },
    {
        icon: 'Local',
        title: 'Show in local currency',
        value: CurrencyType.LOCAL,
    },
];

interface Props {
    loadingCanEditBuyPlan?: boolean;
    errorCanEditBuyPlan?: Error;
}

function StoreTargets({ loadingCanEditBuyPlan, errorCanEditBuyPlan }: Props) {
    const [currencyType, setCurrencyType] = useState(CurrencyType.USD);
    const activeChannelId = useSelector(({ user }) => user.settings.activeChannelId);
    const [loadingExchangeRates, errorExchangeRates, exchangeRatesData] = useRequest(getExchangeRates);
    const previousActiveChannelId = usePrevious(activeChannelId);

    const [loadingStores, errorStores, stores, fetchStores] = useRequestFunction(getStores);
    // Store-level sales targets
    const [loadingTargets, errorTargets, salesTargets, fetchStoreTargets] = useRequestFunction(getStoreTargets);

    useEffect(() => {
        const shouldRefresh = !isEqual(previousActiveChannelId, activeChannelId);
        if (shouldRefresh) {
            fetchStoreTargets();
            fetchStores();
        }
    }, [activeChannelId, previousActiveChannelId, fetchStoreTargets, fetchStores]);

    const like4likeStores = useMemo(() => {
        if (!stores) return [];
        return stores
            .filter((store) => store.hasHindsightData)
            .map(({ number, name, id }) => ({ label: `${number}: ${name}`, value: id }))
            .sort((a: SelectOptionType, b: SelectOptionType) => a.label.localeCompare(b.label));
    }, [stores]);

    if (loadingStores || loadingExchangeRates || loadingTargets || loadingCanEditBuyPlan) {
        return <Loader />;
    }

    let error: Error | string | undefined = errorStores || errorTargets || errorExchangeRates || errorCanEditBuyPlan;

    if (!error && isEmpty(exchangeRatesData?.exchangeRates)) {
        error = 'No exchange rates found';
    }

    return (
        <div className="StoreTargets">
            {error ? (
                <ErrorMessage error={error} />
            ) : (
                <>
                    <div className="StoreTargets__header">
                        <div>
                            <p>
                                Store sales targets are retail price, <em>not</em> wholesale price. Open-to-buy targets are
                                in wholesale price.
                            </p>
                        </div>
                        <div className="StoreTargets__options">
                            <Toggle value={currencyType} items={unitTypes} onChange={setCurrencyType} />
                        </div>
                    </div>
                    <TargetsTable
                        targetsType="store"
                        headers={[
                            { key: 'partner', label: 'Partner' },
                            { key: 'store', label: 'Store' },
                            { key: 'name', label: 'Store name' },
                            { key: 'retail', label: 'Retail sales target' },
                            { key: 'otb', label: 'Open to buy' },
                            { key: 'like4like', label: 'Like-4-like store' },
                            { key: 'status', label: '' },
                        ]}
                        rows={stores ?? []}
                        like4likeStores={like4likeStores}
                        exchangeRates={exchangeRatesData?.exchangeRates ?? []}
                        storeSalesTargets={salesTargets}
                        currencyType={currencyType}
                    />
                </>
            )}
        </div>
    );
}

export default StoreTargets;
